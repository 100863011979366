import React, { forwardRef, useEffect, useState } from 'react';
import { gridLoad } from '../../Services/UserMappingServices';
import { ExportToCsv } from 'export-to-csv';
import * as XLSX from 'xlsx'
import { config } from 'process';
import ReactTooltip from 'react-tooltip';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';


export default function GridPage() {
  let GridLoadarray: any[] = [];
  let GridLoadarray2: any[] = [];
  let [gridData, setGridData] = useState(GridLoadarray);
  let [gridData2, setGridData2] = useState(GridLoadarray2);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [totalItemsCount1, setTotalItemsCount1] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [searchValue1, setSearchvalue1] = useState("")
  const [columnOrder, setColumnOrder] = useState("asc");
  const [targetcolumnOrder, settargetColumnOrder] = useState("");
  const [columnName, setColumnName] = useState("SourceUserName");
  const [targetcolumnName, setTargetColumnName] = useState("");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("usersList");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [countPerPage, setCoutPerPage] = useState(10);
  let [workspaceURL, setWorkspaceURL] = useState('')
  let [TenantID, setTenantID] = useState('')
  let [alertstatus, setalertstatus] = useState('')
  const [exportbtn, setexportbtn] = useState(false)
  let [isUsersListInProgress, setIsUsersListInProgress] = useState(false);
  let clearinv: any;
  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  let clientID: any;
  let clientToken: any;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }

  useEffect(() => {
    resetSessionTimer();
    let footercomponent: any = document.getElementById("MainContainerMFE");
    footercomponent.classList.add("container-bg-withoutFooter")

    loadGridPage();
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage, targetcolumnName, targetcolumnOrder])

  let checkUserListInProgressStatus = () => {
    if (isUsersListInProgress == true) {
      handleRefresh1();
    }
    else {
      let loader = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      clearInterval(clearinv);
    }
  }

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertstatus = "Your session has expired. Please log in again to continue using the application."
      setalertstatus(alertstatus);

      //#region Show PopUp
      let popup = document.getElementById("wrongalert")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      setalertstatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      setalertstatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion




  /*Refresh button for the slack users grid */
  const handleRefresh = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        if (workspaceURL != '' && workspaceURL != undefined) {
          setSearchValue("");
          let config = {
            url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/getUsersList`,
            token: clientToken,
            data: {
              "clientID": clientID,
              "type": "slack"
            }
          }
          let loader = document.getElementById("loader");
          loader?.removeAttribute('hidden');
          let data: any = await gridLoad(config);
          if (data.data.data == "Invalid authentication token provided") {
            loader?.setAttribute('hidden', 'hidden');
            enableSessionExpiryPopup();
          }
          else {
            loader?.setAttribute('hidden', 'hidden');
            setGridData(data.data.data[0])
            let totalCount = data.data.data[0].length;
            let count = totalCount.toLocaleString("en-US");
            setTotalItemsCount(count)
          }
        }
        else {
          setalertstatus('Please connect Slack.')
          enablealertpopup();
        }
      }
      else {
        localStorage.clear();
      }

    }

    catch (error) {
    }
  }

  /*refresh method for the target grid */
  const handleRefresh1 = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        if (TenantID != undefined && TenantID != '') {
          setSearchvalue1("");
          let config = {
            url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/getUserListFromAPI`,
            token: clientToken,
            data: {
              "clientID": clientID,
              "type": "teams"
            }
          }
          let loader = document.getElementById("loader");
          loader?.removeAttribute('hidden');
          let data: any = await gridLoad(config);
          if (data.data.data == "Invalid authentication token provided") {
            clearInterval(clearinv)
            loader?.setAttribute('hidden', 'hidden');
            enableSessionExpiryPopup();
          }
          else if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
            clearInterval(clearinv)
            setalertstatus('Smoething went wrong please reached avamigratron Team')
            enablealertpopup();
          }
          else {
            if (data.data.data.isCompleted == false) {
              loader?.removeAttribute('hidden');
              isUsersListInProgress = true;
              setIsUsersListInProgress(isUsersListInProgress);
              clearTimeout(clearinv);
              clearinv = setTimeout(() => {
                checkUserListInProgressStatus();
              }, 5000);
            }
            else {
              loader?.setAttribute('hidden', 'hidden');
              isUsersListInProgress = false;
              setIsUsersListInProgress(isUsersListInProgress);
              clearInterval(clearinv);
              // loader?.setAttribute('hidden', 'hidden');
              setGridData2(data.data.data[1])
              let totalCount1 = data.data.data[1].length;
              let count1 = totalCount1.toLocaleString("en-US");
              setTotalItemsCount1(count1)
            }
          }
        }
        else {
          setalertstatus('Please connect MSTeams.')
          enablealertpopup();
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      clearInterval(clearinv)
      setalertstatus('Smoething went wrong please reached avamigratron Team')
      enablealertpopup();
    }
  }

  const enablealertpopup = () => {
    try {
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");

    }
    catch (error) {
    }
  }

  const loadGridPage = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/getUserMappingGrid`,
          token: clientToken,
          data: {
            "clientID": clientID,
            "type": `${type}`,
            "offsetValue": indexPerPage,
            "columnName": `${columnName}`,
            "columnType": `${columnOrder}`,
            "searchString": `${searchValue}`,
            "trgtColumnName": `${targetcolumnName}`,
            "trgtColumnType": `${targetcolumnOrder}`,
            "trgtSearchString": `${searchValue1}`
          }
        }
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data[0].length == 0 || data.data.data[1].length == 0) {

          if (data.data.data[0].length == 0 && data.data.data[1].length != 0) {
            setGridData(["No Records Found"]);
            setGridData2(data.data.data[1]);
            TenantID = (data.data.data[5].length > 0 && data.data.data[5][0].TenantID != undefined) ? data.data.data[5][0].TenantID : ""
            setTenantID(TenantID)
          }
          else if (data.data.data[0].length != 0 && data.data.data[1].length == 0) {
            setGridData2(["No Records Found"]);
            setGridData(data.data.data[0]);
            workspaceURL = (data.data.data[4].length > 0 && data.data.data[4][0].SlackWorkspaceName != undefined) ? data.data.data[4][0].SlackWorkspaceName : ""
            setWorkspaceURL(workspaceURL);

          }
          else {
            setGridData(["No Records Found"]);
            setGridData2(["No Records Found"]);
            TenantID = (data.data.data[5].length > 0 && data.data.data[5][0].TenantID != undefined) ? data.data.data[5][0].TenantID : ""
            setTenantID(TenantID)
            workspaceURL = (data.data.data[4].length > 0 && data.data.data[4][0].SlackWorkspaceName != undefined) ? data.data.data[4][0].SlackWorkspaceName : ""
            setWorkspaceURL(workspaceURL);

          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.data[0].length != 0 || data.data.data[1].length != 0) {
          if (data.data.data[0].length != 0 && data.data.data[1].length == 0) {
            setGridData(data.data.data[0]);
            let totalCount = data.data.data[2][0].SourceUserCount;
            let count = totalCount.toLocaleString("en-US");
            setTotalItemsCount(count)

            workspaceURL = (data.data.data[4].length > 0 && data.data.data[4][0].SlackWorkspaceName != undefined) ? data.data.data[4][0].SlackWorkspaceName : ""
            setWorkspaceURL(workspaceURL);
          }
          else if (data.data.data[0].length == 0 && data.data.data[1].length != 0) {
            setGridData2(data.data.data[1])
            let totalCount1 = data.data.data[3][0].TargetUserCount;
            let count1 = totalCount1.toLocaleString("en-US");
            setTotalItemsCount1(count1)
            TenantID = (data.data.data[5].length > 0 && data.data.data[5][0].TenantID != undefined) ? data.data.data[5][0].TenantID : ""
            setTenantID(TenantID)
          }
          else {

            gridData = data.data.data[0]
            setGridData(gridData);
            gridData2 = data.data.data[1];
            setGridData2(gridData2);
            let totalCount = data.data.data[2][0].SourceUserCount;
            let count = totalCount.toLocaleString("en-US");
            setTotalItemsCount(count);
            let totalCount1 = data.data.data[3][0].TargetUserCount;
            let count1 = totalCount1.toLocaleString("en-US");
            setTotalItemsCount1(count1)
            workspaceURL = data.data.data[4][0].SlackWorkspaceName
            setWorkspaceURL(workspaceURL);
            TenantID = data.data.data[5][0].TenantID
            setTenantID(TenantID)
          }
        }
        else {
          setalertstatus('Something went wrong!Please try again or reach AVAMIGRATRON Support.')
          setGridData2(data.data.data)
        }
        if (data.data.data !== "Invalid authentication token provided") {
          let totalCount = data.data.data[2][0].SourceUserCount;
          let count = totalCount.toLocaleString("en-US")
          setTotalItemsCount(count);
          let totalCount1 = data.data.data[3][0].TargetUserCount;
          let count1 = totalCount1.toLocaleString("en-US");
          setTotalItemsCount1(count1)
        }
        if (data.data.data[6][0].Status == "InProgress") {
          loader?.removeAttribute('hidden');
          isUsersListInProgress = true;
          setIsUsersListInProgress(isUsersListInProgress);
          handleRefresh1();
        }
        else {
          loader?.setAttribute('hidden', 'hidden');
          clearInterval(clearinv);
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      let loader = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      clearInterval(clearinv);
      setalertstatus('Something went wrong!Please try again or reach AVAMIGRATRON Support.')
      enablealertpopup();
    }
  }

  const browsealertokclick = () => {
    try {
      if (isSessionEpired === false) {

        document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
        document.getElementById("wrongalert")?.setAttribute("style", "display:none");
        document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
        document.getElementById("wrongalert")?.removeAttribute("aria-modal");
        document.getElementById("wrongalert")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";

      }
    }
    catch (error) {
    }
  }



  /*method for the source grid*/
  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        let a: any = document.getElementById("GridWithDataForSlack");
        a.classList.add("fixed-head");
        // a.className = "fixed-head";
        return gridData.map((obj, index) => {

          let rowindex = (index % 2) == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          return (

            <tr className={rowindex} >
              {(obj.SourceUserName).length > 30 ?
                <td className="py-3 customtabel-data-font-level1  " title={obj.SourceUserName} >{obj.SourceUserName.substring(0, 27)}...</td> :
                <td className='py-3  customtabel-data-font-level1' title="">{obj.SourceUserName}</td>}

              {(obj.SourceUserMailID).length > 35 ?
                <td className="py-3 customtabel-data-font-level1 " title={obj.SourceUserMailID}>{obj.SourceUserMailID.substring(0, 32)}...</td> :
                <td className='py-3 customtabel-data-font-level1 ' title="">{obj.SourceUserMailID}</td>}
            </tr>
          )


        }
        )
      }
      else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
        return (<tr> <td className="aligncenter" colSpan={2}></td></tr>)
      }
      else {

        return (
          <tr><td className="aligncenter" colSpan={2}><img className="mt-3" src='Images/NoRecordsFound.svg'></img>
            <p className="mt-2">No records found</p></td></tr>)
      }
    }
    catch (error: any) {
    }

  }


  /*method for target grid*/

  const gridBodyData2 = () => {
    try {
      if (gridData2.length > 0 && gridData2[0] != "No Records Found" && gridData2[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        let a: any = document.getElementById("GridWithDataForTeams");
        a.classList.add("fixed-head");
        return gridData2.map((obj, index) => {
          let rowindex = (index % 2) == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          return (
            <tr className={rowindex} >
              {(obj.TargetUserName).length > 30 ?
                <td className="py-3 customtabel-data-font-level1" title={obj.TargetUserName}>{obj.TargetUserName.substring(0, 27)}...</td> :
                <td data-tip="" className="py-3 customtabel-data-font-level1" title="">{obj.TargetUserName}</td>
              }
              {(obj.TargetUserMailID).length > 35 ?
                <td className="py-3 customtabel-data-font-level1" title={obj.TargetUserMailID}>{obj.TargetUserMailID.substring(0, 32)}...</td> :
                <td className="py-3 customtabel-data-font-level1" title="">{obj.TargetUserMailID}</td>
              }
            </tr>
          )
        }
        )
      }
      else if (gridData2[0] == undefined && gridData2[0].TargetUserName == undefined) {
        return (<tr> <td className="centertext" colSpan={2}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={2}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
              <p className="mt-2">No records found</p>
            </td>
          </tr>)
      }

    }
    catch (error: any) {
    }
  }
  /*sort method for the source grid */
  const sortClicked = (e: any) => {
    try {

      let sortID = e.target.id;
      let sortval = sortID.split('@');
      setColumnName(sortval[0])
      setColumnOrder(sortval[1])
      setsorthide(sortID)
    }
    catch (error: any) {
    }
  }

  const sortClicked1 = (e: any) => {
    try {

      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setTargetColumnName(sortval[0])
      settargetColumnOrder(sortval[1])
      setsorthide(sortID)
    }
    catch (error: any) {
    }
  }

  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value)
    }
    catch (error: any) {
    }
  }

  /*search method for target list when icon is clicked*/

  const handleSearch1 = (e: any) => {
    try {
      setSearchvalue1(e.target.value)
    }
    catch (error: any) {
    }
  }

  const handleDownKey = (e: any) => {
    try {
      if (e.keyCode == 13) {
        setSearchValue(e.target.value);
        loadGridPage();
      }
    }
    catch (error: any) {
    }
  }
  /*method for target search while enter is pressed*/
  const handleDownKey1 = (e: any) => {
    try {
      if (e.keyCode == 13) {
        setSearchvalue1(e.target.value);
        loadGridPage();
      }
    }
    catch (error: any) {
    }
  }

  return (


    <body className="body-color">
      <div className="usermapping-container">
        <div className="row justify-content-center  card-container">
          <div className="col-md-12 mb-5">
            <div className="row justify-content-center">
              <div className="tab-content">
                <div id="mapped-user" className="tab-pane fade">
                </div>
                <div id="user-list" className="tab-pane active">
                  <div className="col-md-12 mb-4">
                    <label className="font-16 font-semibold my-2 me-5">Total # of Slack Users :<span className="font-regular ms-2">{totalItemsCount}</span></label>
                    <label className="font-16 font-semibold my-2">Total # of MS Teams Users:<span className="font-regular ms-2">{totalItemsCount1}</span></label>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 margin-btm-userlist" >
                        <div className='mb-3'>
                          <label className="font-18 mb-2 font-semibold">Slack Users</label>
                          <button type="button" className="btn mb-2 margin-btm-userlist-refresh btn-refresh btn-sm float-end d-inline-flex font-14 font-semibold refresh-textcolor btn-scondary-custom" onClick={handleRefresh}>
                            <span className="pe-2"><img src='/Images/refresh-black.svg' alt="refresh" /></span><span className="refresh-textcolor"> Refresh</span></button>
                          <div className="input-group margin-rgt-search-usermapping mb-2  input-group-sm custom-search float-end d-inline-flex">
                            <input type="text" className="form-control form-control-style-2" id="serachslack" placeholder="Search" value={searchValue} onKeyDown={handleDownKey} onChange={handleSearch} aria-describedby="button-addon1" />
                            <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={() => loadGridPage()}><img src="/Images/search.svg" alt="search-icon" />
                            </button>
                          </div>
                        </div>
                        <div className="table-responsive userlist-tbl-brd col-12 col-md-12 float-start   rounded-3 bg-white" id='GridWithDataForSlack' >
                          {/* <div className='mt-2 mx-0'> */}
                          <table className="table mb-0 ">
                            <thead className="tabel-head-bg sticky-top bg-white">
                              <tr>
                                <th className="table-head mb-2 font-14 font-bold">Slack User Name
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="SourceUserName@desc" onClick={e => sortClicked(e)}
                                    hidden={sorthide == "SourceUserName@desc" ? true : false} /></a>
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserName@asc" onClick={e => sortClicked(e)}
                                    hidden={sorthide == "SourceUserName@desc" ? false : true} /></a>
                                </th>
                                <th className="table-head font-14 font-bold">Slack User Email Address
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="SourceUserEmail@desc" onClick={e => sortClicked(e)}
                                    hidden={sorthide == "SourceUserEmail@desc" ? true : false} /></a>
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserEmail@asc" onClick={e => sortClicked(e)}
                                    hidden={sorthide == "SourceUserEmail@desc" ? false : true} /></a>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {gridBodyData()}
                            </tbody>
                          </table>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                        <div className='mb-3'>
                          <label className="font-18 mb-2 font-semibold">MS Teams Users</label>
                          <button type="button" className="btn btn-refresh margin-btm-userlist-refresh mb-2  btn-sm float-end d-inline-flex font-14 font-semibold refresh-textcolor btn-scondary-custom" onClick={handleRefresh1}><span className="pe-2"><img src='/Images/refresh-black.svg' alt="refresh" /></span><span className="refresh-textcolor"> Refresh</span></button>
                          <div className="input-group margin-rgt-search-usermapping mb-2  input-group-sm custom-search float-end d-inline-flex">
                            <input type="text" className="form-control form-control-style-2" id="searchteams" placeholder="Search" value={searchValue1} onKeyDown={handleDownKey1} onChange={handleSearch1} aria-describedby="button-addon1" />
                            <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={() => loadGridPage()}><img src="/Images/search.svg" alt="search-icon" />
                            </button>
                          </div>
                        </div>
                        <div className="table-responsive userlist-tbl-brd col-12 col-md-12 float-start rounded-3 bg-white" id='GridWithDataForTeams' >
                          <table className="table mb-0 ">
                            <thead className="tabel-head-bg sticky-top bg-white">
                              <tr>
                                <th className="table-head font-14 font-bold">MS Teams User Name
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="TargetUserName@desc" onClick={e => sortClicked1(e)}
                                    hidden={sorthide == "TargetUserName@desc" ? true : false} /></a>
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="TargetUserName@asc" onClick={e => sortClicked1(e)}
                                    hidden={sorthide == "TargetUserName@desc" ? false : true} /></a>
                                </th>
                                <th className="table-head font-14 font-bold">MS Teams User Email Address
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="TargetUserEmail@desc" onClick={e => sortClicked1(e)}
                                    hidden={sorthide == "TargetUserEmail@desc" ? true : false} /></a>
                                  <a ><img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="TargetUserEmail@asc" onClick={e => sortClicked1(e)}
                                    hidden={sorthide == "TargetUserEmail@desc" ? false : true} /></a>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {gridBodyData2()}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-md">
                          <div className="modal-content content-margin">
                            <div className="modal-body">
                              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                              <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertstatus}</p>
                                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
                      <div className="overlay" id='loader' hidden={true}>
                        <div className="Loader-Content-position">
                          <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                            <span className="visually-hidden"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

