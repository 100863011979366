import { useState, useEffect } from "react";
import { gridLoad,formLoad } from "../../Services/AdminServices";
import { ExportToCsv } from 'export-to-csv'
import dateFormat from 'dateformat';
import { useHistory } from 'react-router-dom';
import objCryptoJS from 'crypto-js'
import ReactTooltip from 'react-tooltip';
import jwt from 'jwt-decode'
import constants  from '../../Common/Constants'


export default function ManageClientGrid() {

    const [isServiceAdmin, setIsServiceAdmin] = useState<any>();
    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    let local: any = localStorage.getItem("aid_t");
    let a_t_key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, a_t_key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    }

    let lstEnvrionmental = {
        NodeAdminUrl: baseURL,
        AdminToken: a_t_key,
        ClientToken: c_t_Key
    };

    let GridLoadArray: any[] = [];
    let tempAccountStatus: string = "All"
    const history = useHistory()
    let [tempAccStatus, setTempAccStatus] = useState("All")
    const [gridData, setGridData] = useState(GridLoadArray);
    const [totalItemsCount, setTotalItemsCount] = useState<any>();
    const [searchValue, setSearchValue] = useState("");
    const [tmpsearchValue, settmpSearchValue] = useState("");
    const [columnOrder, setColumnOrder] = useState("desc");
    const [columnName, setColumnName] = useState("RegisteredAt");
    const [sorthide, setsorthide] = useState("");
    const [FromDate, setFromDate] = useState("")
    const [Currentdate, setCurrentDate] = useState(new Date().toISOString().split('T')[0])
    const [ToDate, setToDate] = useState("")
    const [active, setActive] = useState(false);
    const [inactive, setInactive] = useState(false);
    let [currentPlan, setCurrentPlan] = useState("All");
    let [boolVal, setBoolVal] = useState(0);
    let divVal: any;
    const [hideFilterPopUp, sethideFilterPopUp] = useState(true);
    const [countPerPage, setCoutPerPage] = useState(10);
    const [indexPerPage, setIndexPerPage] = useState(1);
    const [exportBtn, setExportBtn] = useState(false);
    let [alertMessage, setAlertMessage] = useState("");
    let [successMessage, setSuccessMessage] = useState("");
    let [clientID, setclientID] = useState(0)
    let [exportsearchValue, setexportsearchValue] = useState("");
    const [commentValue, setCommentValue] = useState("");


    let [serverDown, setServerDown] = useState<string>("");

    
    let OTPVerificationTime = 45;
    let OTPObj = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
    let [OTPState, setOTPState] = useState(OTPObj);
    let [otpVerificationLimit, setotpVerificationLimit] = useState(OTPVerificationTime);
    let [bool, setbool] = useState(false);
    let [OTPBtnName, setOTPBtnName] = useState("Verify");
    let [otpFrontEndData, setotpFrontEndData] = useState("");
    let [otpData, setotpData] = useState("");
    // let [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const reactNode_en_K: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    const [failedPopUp, setFailedPopUp] = useState("");

    useEffect(() => {

        if(objClient?.Role == "Service Admin"){
            setIsServiceAdmin(true)
        }

        let submitButton = document.getElementById("submitButton")
        submitButton?.setAttribute("disabled", "disabled")
        const script = document.createElement("script");
        script.async = true;
        script.src = "/JS/jquery.min.js";
        divVal.appendChild(script);

        if (bool == true) {
            if (otpVerificationLimit > 0) {
                const timer = setTimeout(() => {
                    setotpVerificationLimit((prevSeconds) => prevSeconds - 1);
                }, 1000);

                return () => {
                    clearTimeout(timer);
                }
            }
            if (otpVerificationLimit === 0) {
                document.getElementById("Verifybtn")?.removeAttribute("disabled");
                setOTPBtnName("Resend");
                setotpFrontEndData("");
                let invalidOTP = document.getElementById("invalidOTP");
                invalidOTP?.setAttribute('hidden', 'hidden');

            }
            if (otpVerificationLimit !== 0) {
                let button = document.getElementById("ServerDown");
                button?.setAttribute("disabled", "disabled");
            }
        }


        // let applyFilter = document.getElementById("ApplyFilter");
        // applyFilter?.setAttribute("disabled", "disabled");
        getServerStatus();
        loadGridPage();
    }, [columnName, columnOrder, indexPerPage, bool, otpVerificationLimit])

    let getServerStatus = async ()=>{
        try{
            let config = {
            token: clientToken,
            url: `${baseURL}/admin/serverDown`,
            data:{"type":"getServer"}
        }
        let data: any = await gridLoad(config)
        setServerDown(data.data.data.serverStatus)
        if(data.data.statusCode==200 && data.data.data.serverStatus=='true'){
            let serverDownCheckbox = document.getElementById("ServerDown")
            serverDownCheckbox?.setAttribute("checked","checked"); 
        }
    }
    catch(error:any){
        let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
    }

    let setServerStatus = async ()=>{
        try{

            let config = {
                url: `${baseURL}/admin/serverDown`,
                token: clientToken,
                data:{}
            }
                {(serverDown=="false")? config.data={
                      "type":"setServer",
                      "value":"true"
                    }:config.data={
                      "type":"setServer",
                      "value":"false"
                    }
                    }
            let data: any = await gridLoad(config);
            OTPPopUp(false)
            let loaderElement = document.getElementById("loader")
            loaderElement?.setAttribute("hidden", "true")
            setOTPBtnName("Verify")
            let OTPObj = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
            setOTPState(OTPObj);
            if(data.data.statusCode==200){
                setServerDown(data.data.data.serverStatus)
            }

        }
        catch(error:any){
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }


    let handleEditClick = (e: any) => {
        let id = e.target.id
        let objState = JSON.parse(id)
        history.push({
            pathname: "/Admin/ManageClients/EditClient",
            state: objState
        })
    }

    let handleCompanyNameClick = async (clientDetails: any) => {
        try {

            if (clientToken != undefined) {
                let loader = document.getElementById("loader")
                loader?.removeAttribute('hidden');
                if (clientDetails.DBName != null) {
                    let config = ({
                        method: "POST",
                        token: clientToken,
                        url: `${baseURL}/admin/getClientSessionDetails`,
                        data: {
                            "mailID": clientDetails.MailID
                        },
                    }
                    )
                    let data: any = await gridLoad(config);

                    loader?.setAttribute('hidden', 'hidden');
                    if (data.data.statusCode == 200) {
                        //#region Update and Frame Client Session
                        localStorage.removeItem("cid_t");
                        let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                        var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                        let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                        localStorage.setItem("cid_t", enPass);
                        //#endregion
                        window.location.pathname = "/Settings";
                    }
                    else {
                        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                        let alertPopUp = document.getElementById("alertPopUp");
                        alertPopUp?.removeAttribute("aria-hidden");
                        alertPopUp?.setAttribute("aria-modal", "true");
                        alertPopUp?.setAttribute("role", "dialog");
                        alertPopUp?.setAttribute("class", "modal fade show");
                        alertPopUp?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
                        document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    }
                }
                else {
                    loader?.setAttribute('hidden', 'hidden');
                    setAlertMessage("The user has not choosen any plan yet. To get access continue by adding license.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

        }
    }

    const loadGridPage = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientToken != undefined) {

                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/manageClientDetails`,
                    data: {
                        "offsetValue": indexPerPage,
                        "columnName": `${columnName}`,
                        "columnType": `${columnOrder}`,
                        "fromDate": `${FromDate}`,
                        "toDate": `${ToDate}`,
                        "accountStatus": `${tempAccStatus}`,
                        "searchString": `${searchValue}`,
                        "currentPlan": `${currentPlan}`
                    },
                }
                )

                let data: any = await gridLoad(config);
                document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");

                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    setGridData([data.data.data]);
                    setExportBtn(true)
                }
                else if(data.data.data == "Invalid authentication token provided"){
                    localStorage.clear();
                    window.location.pathname = "/Admin/Login"
                }
                else {
                    setGridData(data.data.data[0]);
                    setExportBtn(false)
                }
                if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    setGridData([data.data.data]);
                }
                setTotalItemsCount(data.data.data[1][0].ClinetsCount);
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

        }
    }

    const openConfirmationPopup = async (e: any) => {
        try {
            ;
            setclientID(e.ID);
            if (e.TrailRequestStatus == "Pending") {
                let alertPopUp = document.getElementById("confirmationPopUp");
                alertPopUp?.removeAttribute("aria-hidden");
                alertPopUp?.setAttribute("aria-modal", "true");
                alertPopUp?.setAttribute("role", "dialog");
                alertPopUp?.setAttribute("class", "modal fade show");
                alertPopUp?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            }

        } catch (error: any) {

        }
    }

    const onDeclineClick = async () => {
        try {
            ;
            let popup = document.getElementById("confirmationPopUp");
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            let alertPopUp = document.getElementById("CommentsPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

        } catch (error) {

        }
    }

    const closePopUpClick = async () => {
        try {
            ;
            let popup = document.getElementById("CommentsPopUp");
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        } catch (error) {

        }
    }

    const handleComment = (e: any) => {
        try {

            setCommentValue(e.target.value);
            let submitButton = document.getElementById("submitButton");
            if (e.target.value != "") {
                submitButton?.removeAttribute('disabled');
            }
            else {
                submitButton?.setAttribute('disabled', 'disabled');
            }
        }
        catch (error: any) {

        }
    }

    const confirmationPopUpClick = async (type: string) => {
        try {

            let loader = document.getElementById("loader")
            //#region Hide PopUp
            if (type == "Approved") {
                let popup = document.getElementById("confirmationPopUp");
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                let popup = document.getElementById("CommentsPopUp");
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            }
            //#endregion
            loader?.removeAttribute('hidden');

            // let loader = document.getElementById("loader")
            //loader?.removeAttribute('hidden');
            if (clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/updateTrailLicenseStatus`,
                    data: {
                        "clientID": Number(clientID),
                        "status": type,
                        "LicenseType": 'Trail',
                        "channelCount": 1,
                        "chatCount": 1,
                        "declineReason": commentValue
                    },
                }
                )

                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');
                if (data.data.statusCode == 200 && type == "Approved") {
                    let alertPopUp = document.getElementById("sourceSuccessPopUp");
                    setSuccessMessage('Trail License Updated Successfully!');
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else if (data.data.statusCode == 200 && type == "Declined") {
                    setSuccessMessage("Trail License Request Declined successfully!");
                    let alertPopUp = document.getElementById("sourceSuccessPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');

        }
    }

    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
                return gridData.map((obj, index) => {
                    let rowClass = index % 2 == 0 ? "tabel-body-bg " : "tabel-body bgsecondary"
                    let activeClass = obj.IsActive == true ? "status success-status font-14 font-semibold" : "status fail-status font-14 font-semibold"
                    return (
                        <tr className={rowClass} >
                            {
                                (obj.CompanyName).length > 25 ?
                                    <td className="py-3 manage-tabel-data-font-level1" data-tip={obj.CompanyName} onClick={(e) => handleCompanyNameClick(obj)}>
                                        <a className="underline-remove hyperlink-color anchorAsPointer ">
                                            {(obj.CompanyName).substring(0, 25)}...
                                        </a>
                                    </td>
                                    :
                                    <td className="py-3 manage-tabel-data-font-level1" data-tip={''} onClick={(e) => handleCompanyNameClick(obj)} >
                                        <a className="underline-remove hyperlink-color anchorAsPointer ">
                                            {obj.CompanyName}
                                        </a>
                                    </td>
                            }
                            <td className="py-3 manage-tabel-data-font-level1 " ><div className={activeClass} >{obj.IsActive == true ? "Active" : "InActive"}</div></td>
                            <td className="py-3 manage-tabel-data-font-level1 ">{obj.LicenseType}</td>
                            {
                                (obj.MailID).length > 45 ?
                                    <td className="py-3 manage-tabel-data-font-level1" data-tip={obj.MailID} >{(obj.MailID).substring(0, 45)}...</td>
                                    :
                                    <td className="py-3 manage-tabel-data-font-level1" data-tip={''}  >{obj.MailID}</td>
                            }
                            <td className="py-3 manage-tabel-data-font-level1 ">{dateFormat(obj.RegisteredAt, "mm/dd/yyyy")}</td>
                            {/* Inventory Progress Status */}
                            {obj.InventoryProgress == "Yet to Start" ?
                            <td className=" py-3 manage-tabel-data-font-level1 text-center"><span className="yettostart-status status-block font-14">Yet to Start</span></td>
                            :
                            <></>
                            }
                            {obj.InventoryProgress == "In Progress" ?
                            <td className="py-3 manage-tabel-data-font-level1 text-center"> <span className="inprogress-status status-block font-14">In progress</span></td>
                            :
                            <></>
                            }
                            {obj.InventoryProgress == "Completed" ?
                            <td className=" py-3 manage-tabel-data-font-level1 text-center"><span className="completed-status status-block font-14">Completed</span></td>
                            :
                            <></>
                            }
                            {/* Migration Progress Status */}
                            {obj.MigrationProgress == "Yet to Start" ?
                            <td className=" py-3 manage-tabel-data-font-level1 text-center"><span className="yettostart-status status-block font-14">Yet to Start</span></td>
                            :
                            <></>
                            }
                            {obj.MigrationProgress == "In Progress" ?
                            <td className="py-3 manage-tabel-data-font-level1 text-center"> <span className="inprogress-status status-block font-14">In progress</span></td>
                            :
                            <></>
                            }
                            {obj.MigrationProgress == "Completed" ?
                            <td className=" py-3 manage-tabel-data-font-level1 text-center"><span className="completed-status status-block font-14">Completed</span></td>
                            :
                            <></>
                            }
                            <td className="py-3 manage-tabel-data-font-level1 "  >
                                <img className="ms-2 anchorAsPointer" title="Edit" id={JSON.stringify(obj)} src='/Images/edit-icon.svg' onClick={e => handleEditClick(e)} alt="edit" />
                            </td>
                            <span>
                                <ReactTooltip className='mySepecialClass' />
                            </span>
                        </tr>)
                })
            }
            else if (gridData[0] == undefined && gridData[0].SourceChatName == undefined) {
                return (<tr> <td className="aligncenter" colSpan={6}></td>  </tr>)
            }
            else {
                return (<tr> <td className="aligncenter" colSpan={6}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
                    <p className="mt-2">No records found</p>
                </td>  </tr>)
            }
        }
        catch (error: any) {


        }
    }

    const sortClicked = (e: any) => {
        try {
            ;
            let sortID = e.target.id;
            setsorthide(sortID);
            let sortval = sortID.split('@');
            setColumnName(sortval[0]);
            setColumnOrder(sortval[1]);
            setIndexPerPage(indexPerPage ? indexPerPage : 1);
            setCoutPerPage(countPerPage ? countPerPage : 10);
        }
        catch (error: any) {

        }
    }

    const handleSearch = (e: any) => {
        try {

            setSearchValue(e.target.value);
        }
        catch (error: any) {

        }
    }

    const handleOTPKeyDown = (e:any) => {
        try{
          if(e.key === "Enter"){
            if(otpFrontEndData != "" && otpFrontEndData != undefined && otpFrontEndData != null){
              verifyButtonClick(OTPBtnName)  
             }
          }
        }
        catch(error){
        }
        
      }

    const handleSearchIcon = (e: any) => {
        try {
            if (searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    setIndexPerPage(indexPerPage ? indexPerPage : 1);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {

        }
    }

    const handleApplyFilter = () => {
        try {
            ;
            getFilterStatus();
            // let applyFilter = document.getElementById("ApplyFilter");
            // applyFilter?.setAttribute("disabled", "disabled");
            loadGridPage();
        }
        catch (error: any) {

        }
    }

    const handleKeyDown = (e: any) => {
        try {

            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    setIndexPerPage(indexPerPage ? indexPerPage : 1);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    exportsearchValue = searchValue;

                    setexportsearchValue(exportsearchValue);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {

        }
    }

    const filterClicked = async () => {
        ;
        try {
            sethideFilterPopUp(hideFilterPopUp == true ? false : true);
        }
        catch (error: any) {

        }
    }

    const filterClearClicked = () => {
        try {
            ;
            setFromDate("");
            setToDate("");
            setActive(false);
            setInactive(false);
            let dropdown: any = document.getElementById("dropdown")
            dropdown.value = "ALL"
            setCurrentPlan(dropdown.value)

            let fromDate: any = document.getElementById("from-date")
            fromDate.value = ""
            let toDate: any = document.getElementById("to-date")
            toDate.value = ""
            let active: any = document.getElementById("Check6")
            active.checked = false;
            let inactive: any = document.getElementById("Check7")
            inactive.checked = false;
            let dropdownValue: any = document.getElementById("dropdown")
            dropdownValue.value = "ALL"

            // let applyFilter = document.getElementById("ApplyFilter");
            // applyFilter?.removeAttribute("disabled");
        }
        catch (error: any) {

        }
    }

    const filterCloseClicked = () => {
        try {
            ;
            // document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
            setFromDate("");
            setToDate("");
            setActive(false);
            setInactive(false);
            let dropdown: any = document.getElementById("dropdown")
            dropdown.value = "ALL"
            setCurrentPlan(dropdown.value)

            let fromDate: any = document.getElementById("from-date")
            fromDate.value = ""
            let toDate: any = document.getElementById("to-date")
            toDate.value = ""
            let active: any = document.getElementById("Check6")
            active.checked = false;
            let inactive: any = document.getElementById("Check7")
            inactive.checked = false;
            let dropdownValue: any = document.getElementById("dropdown")
            dropdownValue.value = "ALL"
            // let applyFilter = document.getElementById("ApplyFilter");
            // applyFilter?.setAttribute("disabled", "disabled");
            document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
        }
        catch (error: any) {

        }
    }

    const handleFilterInput = (e: any) => {
        try {
            ;
            if (e.target.name == "FromDate") {
                if (e.target.value != '') {
                    boolVal = boolVal + 1;
                    setBoolVal(boolVal)
                    let fromDate: any = document.getElementById("from-date")
                    fromDate.value = e.target.value
                    setFromDate(e.target.value)
                }
                else {
                    boolVal = boolVal - 1;
                    setBoolVal(boolVal)
                    setFromDate("")
                }
            }
            if (e.target.name == "ToDate") {
                if (e.target.value != '') {
                    boolVal = boolVal + 1;
                    setBoolVal(boolVal)
                    let toDate: any = document.getElementById("to-date")
                    toDate.value = e.target.value
                    setToDate(e.target.value)
                }
                else {
                    boolVal = boolVal - 1;
                    setBoolVal(boolVal)
                    setToDate("")
                }
            }
            if (e.target.name == "Active") {
                if (e.target.checked) {
                    boolVal = boolVal + 1;
                    setBoolVal(boolVal);
                    setActive(e.target.checked)
                }
                else {
                    boolVal = boolVal - 1;
                    setBoolVal(boolVal)
                    setActive(false)
                }
            }
            if (e.target.name == "Inactive") {
                if (e.target.checked) {
                    boolVal = boolVal + 1;
                    setBoolVal(boolVal);

                    setInactive(e.target.checked)
                }
                else {
                    boolVal = boolVal - 1;
                    setBoolVal(boolVal)
                    setInactive(false)
                }
            }
            if (e.target.id == "dropdown") {
                if (e.target.value != "") {
                    boolVal = boolVal + 1;
                    setBoolVal(boolVal);
                    let dropdownValue: any = document.getElementById("dropdown")
                    dropdownValue.value = (e.target.value)
                    setCurrentPlan(e.target.value)
                }
                else {
                    boolVal = boolVal - 1;
                    setBoolVal(boolVal)
                    setCurrentPlan("All")
                }
            }
            if (boolVal == 0) {
                // let applyFilter = document.getElementById("ApplyFilter");
                // applyFilter?.setAttribute("disabled", "disabled");
            }
            else {
                // let applyFilter = document.getElementById("ApplyFilter");
                // applyFilter?.removeAttribute("disabled");
            }
        }
        catch (error: any) {

        }
    }

    const handledropdown = (e: any) => {
        try {
            ;
            setCurrentPlan(e.target.value);
        }
        catch (error: any) {

        }
    }
    const handleLoadMore = () => {
        ;
        try {
            let oindexPerPage = indexPerPage + 1;
            let ocountPerPage = countPerPage + 10;
            setIndexPerPage(oindexPerPage);
            setCoutPerPage(ocountPerPage);
        }
        catch (error: any) {

        }
    }

    const getFilterStatus = () => {
        ;
        try {
            let tempActiveStatus = active
            let tempInactiveStatus = inactive
            if ((tempActiveStatus == true && tempInactiveStatus == true) || (tempActiveStatus == false && tempInactiveStatus == false)) {
                tempAccountStatus = 'All'
                tempAccStatus = 'All'
                setTempAccStatus(tempAccStatus)
            }
            else {
                if (tempActiveStatus == true) {
                    tempAccountStatus = 'true'
                    tempAccStatus = 'true'
                    setTempAccStatus(tempAccStatus)
                }
                else if (tempInactiveStatus == true) {
                    tempAccountStatus = 'false'
                    tempAccStatus = 'false'
                    setTempAccStatus(tempAccStatus)
                }
            }
        }
        catch (error: any) {

        }
    }

    const handleExportBtn = async () => {

        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/exportManageClientData`,
                    data: {

                        "accountStatus": `${tempAccStatus}`,
                        "fromDate": `${FromDate}`,
                        "toDate": `${ToDate}`,
                        "searchString": `${exportsearchValue}`,
                        "currentPlan": `${currentPlan}`

                    },
                }
                )
                let data: any = await gridLoad(config);
                ;
                if (data.data.statusCode == 200) {
                    if (data.data.data != "No Records Found") {
                        let exportData = data.data.data[0]

                        const options = {
                            fieldSeparator: ',',
                            quoteStrings: '"',
                            decimalSeparator: '.',
                            showLabels: true,
                            showTitle: false,
                            title: 'Client Details',
                            useTextFile: false,
                            useBom: true,
                            useKeysAsHeaders: true,
                            filename: "ClientDetails"
                        };
                        const csvExporter = new ExportToCsv(options);
                        loader?.setAttribute('hidden', 'hidden');
                        csvExporter.generateCsv(exportData);
                    }
                    else {
                        loader?.setAttribute('hidden', 'hidden');
                        setAlertMessage("No Records Found to Export!");
                        let alertPopUp = document.getElementById("alertPopUp");
                        alertPopUp?.removeAttribute("aria-hidden");
                        alertPopUp?.setAttribute("aria-modal", "true");
                        alertPopUp?.setAttribute("role", "dialog");
                        alertPopUp?.setAttribute("class", "modal fade show");
                        alertPopUp?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
                        document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    }
                }
                else {
                    loader?.setAttribute('hidden', 'hidden');
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const popUpOkClick = (type: string) => {
        try {
                        //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            //#endregion
            loadGridPage();
        } catch (error) {
        }
    }

    const OTPValidation = async (e: any) => {
        try {

            if (e.target.value.length <= 1) {
                if (OTPState[e.target.id].length < 1 || OTPState[e.target.id].length == 1) {
                    OTPState = { ...OTPState, [e.target.id]: e.target.value }
                    setOTPState(OTPState)
                }
                const [fieldIndex] = e.target.id.split("_")[1];

                let fieldIntIndex = parseInt(fieldIndex, 10);
                let nxtFieldIntIndex = (e.target.value != "") ? fieldIntIndex + 1 : fieldIntIndex - 1;
                let elementID = 'OTPValue_' + nxtFieldIntIndex;
                if (nxtFieldIntIndex < 8) {
                    const timer = setTimeout(() => {
                        const currentfield = document.getElementById(e.target.id);
                        if (e.target.value != "") {
                            currentfield?.setAttribute("type", "password");
                        }
                        else {
                            currentfield?.setAttribute("type", "text");
                        }
                    }, 1000);
                    const nextfield = document.getElementById(elementID);
                    if (nextfield !== null) {
                        nextfield?.focus();
                    }
                }
            }
            else if (e.target.value.length >= 1 || e.target.value.length <= 6) {
                const OTPChars = e.target.value.split('');
                let fieldIndex = e.target.id.split("_")[1];
                let charLength = OTPChars.length > 6 ? 6 : OTPChars.length;
                for (let i = 0; i < charLength; i++) {
                    OTPState = { ...OTPState, [`OTPValue_${fieldIndex}`]: OTPChars[i] }
                    setOTPState(OTPState);
                    const currentfield = document.getElementById(`OTPValue_${fieldIndex}`);
                    const timer = setTimeout(() => {
                        if (e.target.value != "") {
                            currentfield?.setAttribute("type", "password");
                        }
                        else {
                            currentfield?.setAttribute("type", "text");
                        }
                    }, 1000);
                    fieldIndex = Number(fieldIndex) + 1;
                }
            }
            let Verifybtn = document.getElementById("Verifybtn");
            let invalidOTP = document.getElementById("invalidOTP");
            invalidOTP?.setAttribute('hidden', 'hidden');
            if (OTPState.OTPValue_1 != '' && OTPState.OTPValue_2 != '' && OTPState.OTPValue_3 != '' && OTPState.OTPValue_4 != '' && OTPState.OTPValue_5 != '' && OTPState.OTPValue_6 != '') {
                if (OTPBtnName == "Verify") {
                Verifybtn?.removeAttribute('disabled');
                }
                otpFrontEndData = `${OTPState.OTPValue_1}${OTPState.OTPValue_2}${OTPState.OTPValue_3}${OTPState.OTPValue_4}${OTPState.OTPValue_5}${OTPState.OTPValue_6}`
                setotpFrontEndData(otpFrontEndData)

            }
            else {
                if (OTPBtnName == "Verify") {
                Verifybtn?.setAttribute("disabled", "disabled");
                }
            }
        }
        catch (error) {

        }
    }

    const verifyButtonClick = (data: any) => {
        try {
            //#region Hide PopUp
            let submitButton = document.getElementById("Verifybtn");
            let invalidOTP = document.getElementById("invalidOTP");
            if (data !== "Resend") {
                if (otpData === otpFrontEndData) {
                    // setIsPopUpOpen(false)
                    document.getElementById("OTPAlertPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("OTPAlertPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("OTPAlertPopUp")?.removeAttribute("role");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("hidden", "true");

                    setServerStatus()
                    setbool(false)


                }
                else {
                    invalidOTP?.removeAttribute("hidden");
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                setOTPBtnName("Verify");
                let OTPObj = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
                setOTPState(OTPObj);
                document.getElementById("Verifybtn")?.setAttribute("disabled", "true");
                invalidOTP?.setAttribute('hidden', 'hidden');
                setotpVerificationLimit(OTPVerificationTime);
                handleServerDownToggleButton(data);
            }
            //#endregion
        }
        catch (error) {

        }
    }

    const handleServerDownToggleButton = async (e: any) => {
        try {
            let loaderElement = document.getElementById("loader")
            loaderElement?.removeAttribute("hidden");

            let config = {
                method: "POST",
                token: clientToken,
                url: `${baseURL}/AdminAuthentication/Login`,
                data: {
                    "sendServerDownOTP":true
                },
            }
            // setIsPopUpOpen(true)
            let data: any = await formLoad(config);
            if (OTPBtnName === "Verify") {
                let y = document.getElementById("ServerDown")
                y?.removeAttribute("disabled")
            }

            if (data.data.statusText.toString().toLowerCase() == "ok") {
                let encPswd = objCryptoJS.AES.decrypt(data.data.data.SecretKey, reactNode_en_K);
                var actPswd = encPswd.toString(objCryptoJS.enc.Utf8);

                setotpData(actPswd);
                setotpVerificationLimit(OTPVerificationTime)
                OTPPopUp(true);
                setbool(true);

            }
            else {

                let loaderElement = document.getElementById("loader")
                loaderElement?.setAttribute("hidden", "true")


                setFailedPopUp('Primary Admin Not Found');

                //#region Show PopUp
                // setIsPopUpOpen(true)
                document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
                document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
                document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
                document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
                document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
                document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
                //#endregion

            }
        } catch (error: any) {
            let loaderElement = document.getElementById("loader")
            loaderElement?.setAttribute("hidden", "true")


            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
            //#endregion
        }
    }

    const OTPPopUp = (type: any) => {
        try {
            let Verifybtn = document.getElementById("Verifybtn");
            Verifybtn?.setAttribute("disabled", "disabled");
            let OTPPopUp = document.getElementById("OtpPopUp");
            if (type) {
                OTPPopUp?.removeAttribute("aria-hidden");
                OTPPopUp?.setAttribute("aria-modal", "true");
                OTPPopUp?.setAttribute("role", "dialog");
                OTPPopUp?.setAttribute("class", "modal fade show");
                OTPPopUp?.setAttribute("style", "display:block");
                document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
            }
            else {
                OTPPopUp?.setAttribute("class", "modal fade");
                OTPPopUp?.setAttribute("style", "display:none");
                OTPPopUp?.setAttribute("aria-hidden", "true");
                OTPPopUp?.removeAttribute("aria-modal");
                OTPPopUp?.removeAttribute("role");
                document.getElementById("targetFadeDiv")?.setAttribute("hidden", "true");
            }
        }
        catch (error) {

        }
    }


    return (
        <div className="container-fluid" id="mail-Con" ref={value => (divVal = value)}>
            <div className="row justify-content-center my-4 card-container mx-0 ms-0 px-2">
                <div className="col-md-11 w-100">
                    {/* <!--Slack channel section starts here--> */}
                    <div className="col-md-12 float-start">
                        {/* <h5 className="channel-header font-18 font-bold">Manage Clients</h5> */}
                        {/* <!--Slack channel section ends here--> */}
                        <div className="tab-content">
                            <div id="high-level-inventory" className="tab-pane active w-100">
                                {/* <!-- channel list table starts here --> */}
                                <div className="w-100">
                                    <div className="col-md-12 float-start">
                                        <div className="float-start mt-4 w-100 ">
                                            <div className="float-start ">
                                                <label className=" channel-font font-24 font-bold">Manage Clients</label>
                                            </div >
                                            <div className="float-end">
                                                {!isServiceAdmin?
                                                <div className="form-check form-switch float-start mt-2 me-5">
                                                    <input className="form-check-input" type="checkbox" id="ServerDown" onClick={handleServerDownToggleButton}></input>
                                                    <label className="form-check-label font-semibold font-15" htmlFor="ServerDown">Server down</label>
                                                </div>
                                                :
                                                null}
                                                
                                                <p className='mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3'># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {gridData[0] != "No Records Found" ? totalItemsCount:0}</p>
                                                <div className="input-group box-shodow-filter search-container float-start">
                                                    <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={searchValue} onKeyDown={handleKeyDown} onChange={handleSearch} />
                                                    <button className="btn btn-outline-secondary" type="button" onClick={handleSearchIcon} id="button-addon1"><img src='/Images/search.svg' alt="search-icon" />
                                                    </button>
                                                </div>
                                                {/* <!-- advanced-filter --> */}
                                                <div className="dropdown filter float-start mx-2">
                                                    <button type="button" className="btn float-start box-shodow-filter p-0 btn-scondary-custom" title="Advanced Filter" onClick={() => { document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden") }}>
                                                        <img src='/Images/filter.svg' alt="filter" />
                                                    </button>
                                                    <div className="dropdown-menu filter-dropdown show" id="AdvancedFilterDiv" hidden>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12 pb-2 border-0 mb-1">
                                                                    <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                                                                    <a className="btn float-end closeFilter anchorAsPointer advancedfilter_close_padding" id="close-filter" data-bs-dismiss="advanced-filter"
                                                                        aria-label="Close">
                                                                        <span aria-hidden="true"><img src='/Images/filter-close-icon.svg' alt="filter-close-icon" onClick={filterCloseClicked} /></span>
                                                                    </a>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="my-2">
                                                                        <label className="channeltype-font font-16 font-semibold" htmlFor="from-date">Created On</label>
                                                                        <div className="row mt-2">
                                                                            <div className="col-md-12">
                                                                                <div className="col-md-6 float-start px-2">
                                                                                    <label
                                                                                        className="date-created w-100 float-start channel-check font-14 font-regular">From</label>
                                                                                    <input type="date" className="form-control inventory-date-form-control custom-date-dropdown-border" max={ToDate != "" ? ToDate : Currentdate} name="FromDate" onChange={handleFilterInput} id="from-date" />
                                                                                </div>

                                                                                <div className="col-md-6 float-start px-2">
                                                                                    <label
                                                                                        className="date-created w-100 float-start channel-check font-14 font-regular">To</label>
                                                                                    <input type="date" placeholder="select date" className="form-control inventory-date-to-control custom-date-dropdown-border" name="ToDate" min={FromDate != "" ? FromDate : ""} max={Currentdate} onChange={handleFilterInput} id="to-date" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <div className="row mt-2">
                                                                        <div className="col-md-12">
                                                                            <div className="col-md-6 float-start px-2">

                                                                                <label className="channeltype-font font-16 font-semibold" htmlFor="from-date">Account Status</label>
                                                                                <div className="col-md-12">
                                                                                    <label
                                                                                        className="manage-client-date-font channel-check font-14 font-regular me-3"><span className="pe-1"><input
                                                                                            className="form-check-input" type="checkbox" name="Active" id="Check6" onChange={handleFilterInput} /></span>
                                                                                        Active</label>
                                                                                    <label
                                                                                        className="manage-client-date-fontfont-14 channel-check font-regular"><span className="pe-1"><input
                                                                                            className="form-check-input" type="checkbox" name="Inactive" id="Check7" onChange={handleFilterInput} /></span>
                                                                                        Inactive</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 float-start px-2">
                                                                                <label className="channeltype-font font-16 font-semibold" htmlFor="from-date">Current Plan</label>
                                                                                <select className="form-control form-select form-select-sm custom-date-dropdown-border" id="dropdown" aria-label=".form-select-sm example" onChange={handleFilterInput}>
                                                                                    <option value="ALL">ALL</option>
                                                                                    <option value="Registered">Registered</option>
                                                                                    <option value="Free">Free</option>
                                                                                    <option value="Lite">Lite</option>
                                                                                    <option value="Elite">Elite</option>
                                                                                    <option value="Plus">Plus</option>
                                                                                    <option value="Premium">Premium</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mt-5 mb-3">
                                                                    <div className="float-end">
                                                                        <button className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-clear font-16 font-semibold px-4 me-3 btn-scondary-custom" onClick={() => filterClearClicked()}>Clear</button>
                                                                        <button className="common-btn-class btn-primary-theme btn btn-apply font-16 font-semibold text-light" id="ApplyFilter" onClick={handleApplyFilter}>Apply Filter</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- advanced-filter ends--> */}
                                                <button className="btn btn-inventory-export float-start text- me-2 px-3 float-start font-semibold font-16 btn-scondary-custom" onClick={() => handleExportBtn()} ><img src='/Images/export-excel.svg' alt="export-excel" /> Export</button>
                                                {isServiceAdmin?
                                                    <button className="btn-inventory-export btn text- px-3 float-start font-semibold admin-newClient-btn btn-primary-theme" ><img src='/Images/plus-icon.svg' alt="add-client" /> New Client</button>
                                                :
                                                <a href="/Admin/ManageClients/NewClient" className="anchorAsPointer float-start">
                                                    <button className="btn-inventory-export btn text- px-3 float-start font-semibold admin-newClient-btn btn-primary-theme" ><img src='/Images/plus-icon.svg' alt="add-client" /> New Client</button>
                                                </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 float-start mt-2">
                                    <div className="table-responsive manage-table">
                                    <table className="table table-border">
                                        <thead className="tabel-head-bg">
                                            <tr>
                                                <th className="manage-table-head font-14 font-bold">Company Name<span>
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="CompanyName" id="CompanyName@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "CompanyName@Desc" ? true : false} />
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="CompanyName" id="CompanyName@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "CompanyName@Desc" ? false : true} /></span>
                                                </th>
                                                <th className="manage-table-head font-14 font-bold">Account Status<span>
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="AccountStatus" id="AccountStatus@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "AccountStatus@Desc" ? true : false} />
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="AccountStatus" id="AccountStatus@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "AccountStatus@Desc" ? false : true} /></span>
                                                </th>
                                                <th className="manage-table-head font-14 font-bold">Current Plan<span>
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="CurrentPlan" id="CurrentPlan@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "CurrentPlan@Desc" ? true : false} />
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="CurrentPlan" id="CurrentPlan@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "CurrentPlan@Desc" ? false : true} /></span>
                                                </th>
                                                <th className="manage-table-head font-14 font-bold">Business User Mail Address<span>
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="MailID" id="MailID@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "MailID@Desc" ? true : false} />
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="MailID" id="MailID@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "MailID@Desc" ? false : true} /></span>
                                                </th>
                                                <th className="manage-table-head font-14 font-bold">Created On<span>
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="RegisteredAt" id="RegisteredAt@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "RegisteredAt@Desc" ? true : false} />
                                                    <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="RegisteredAt" id="RegisteredAt@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "RegisteredAt@Desc" ? false : true} /></span>
                                                </th>
                                                <th className="manage-table-head font-14 font-bold text-center">Inventory</th>
                                                <th className="manage-table-head font-14 font-bold text-center">Migration</th>
                                                <th className="manage-table-head font-14 font-bold text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {gridBodyData()}
                                        </tbody>
                                    </table>
                                    </div>
                                   
                                    {/* <!-- channel list table ends here --> */}

                                    {/* <!-- loadmore button section starts here --> */}
                                    <div className="col-md-12 float-start mt-3 mb-5 text-center">
                                        {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={() => handleLoadMore()}>Load More</button> : ""}
                                    </div>
                                    {/* <!-- loadmore button section ends here --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Admin-Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
            {/* <!--Confirm Pop-up--> */}
            <div className="modal fade" id="confirmationPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Require Confirmation!</h5>
                                <p className="success-text w-100 text-center mb-4">Approving the request will provide trail license for Channel and Chat to the respective client.</p>
                                <div className="text-center w-100 d-inline-block">
                                    <button type="button" className="btn-primary-theme mb-2 me-5 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={(e) => confirmationPopUpClick('Approved')}>Approve</button>
                                    <button type="button" className="btn-primary-theme mb-2 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={(e) => onDeclineClick()}>Decline</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Confirm pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">{successMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            {/* <!--Comments Pop-up--> */}
            <div className="modal fade" id="CommentsPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Provide the reason for decline!</h5>
                                <textarea className="form-control textarea-style-resize_none font-14" placeholder="Enter your comments" id="exampleFormControlTextarea1" onChange={(e) => handleComment(e)} rows={4}></textarea>
                                <button type="button" className="btn-primary-theme mt-3 mb-2 me-5 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={(e) => closePopUpClick()}>Cancel</button>
                                <button id="submitButton" type="button" className="btn-primary-theme mt-3 mb-2 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={(e) => confirmationPopUpClick('Declined')}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Comments pop-up--> */}
            {/* Confirmation OTP Popup */}
            <div className="modal fade" id="OtpPopUp" tabIndex={-1}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/otp-img.svg' className="success-icon" alt="success" />
                            <div className=" success-content w-100 mt-5 text-center">
                                <h5 className="font-semibold font-18">Enter OTP!</h5>
                                <p className="success-text w-100  mb-4">Please enter the 6-digit OTP that was send to your email</p>
                                <div className="d-flex justify-content-center mb-2 w-100 ">
                                    <div className="d-flex justify-content-between w-75">
                                        <input id="OTPValue_1" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_1} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_2" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_2} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_3" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_3} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_4" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_4} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_5" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_5} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_6" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_6} onKeyDown={handleOTPKeyDown} onChange={(e) => OTPValidation(e)}></input>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center mb-2 w-100 ">
                                    <p className="font-12 font-semibold mb-3 text-end w-75">Resend OTP in <span>00:{(otpVerificationLimit < 10) ? (0 + otpVerificationLimit) : otpVerificationLimit}</span></p>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <button type="button" id='Verifybtn' className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => verifyButtonClick(OTPBtnName)}>{OTPBtnName}</button>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <label className="float-start font-semibold pb-3 font-14 required text-center" id="invalidOTP" hidden>Invalid OTP provided</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Confirmation OTP Popup */}
            <div id="targetFadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    )
}