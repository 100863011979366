import React, { useEffect, useState } from 'react';

export default function GridPage() {
    let InActiveTimeout: NodeJS.Timeout;
    let [statusText, setStatus] = useState("")

    useEffect(() => {
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.add("container-bg-withoutFooter")
        resetSessionTimer();
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    })

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            statusText = "Your session has expired. Please log in again to continue using the application."
            setStatus(statusText)

            //#region Show PopUp
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            //#endregion
            localStorage.removeItem("cid_t");
        } catch (error) {

        }
    }
    //#endregion


    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));

        }
        catch (ex: any) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion


    const disablePopUp = () => {

        try {

            window.location.pathname = "/Login";

        } catch (error) {

        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center my-5 px-3">
                <div className="col-md-11 mt-4">
                    <h4 className="font-26 font-bold mb-4">
                        Channel Migration
                    </h4>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-11 mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12 col-lg-6 ">
                            <div className="">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-18 font-bold my-3">Service Account Migration</h5>
                                    <img src="/Images/service_account_migration.png" alt="service-account-migration" className="my-3" />
                                    <p className="px-5 card-text font-14  font-regular">Migrate channels from Slack on behalf of service account as MS Teams public, private or shared channels.</p>
                                    <a href='/ChannelMigration/SA/TeamChannelMapping'><button type="button" className="btn-primary-theme common-btn-class btn anchorAsPointer btn-migrate-channel font-16 font-regular">Migrate Channels</button></a>
                                    <p className="card-text font-12 font-regular my-4">Click <a className='anchorAsPointer' href="https://www.avamigratron.com/Product/how-easy-is-slack-channel-migration-using-a-service-account-user/" target="_blank">here</a> to know more about Service Account Migration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-6">
                            <div className="">
                                <div className="card-body text-center">
                                    <h5 className="card-title font-18 font-bold my-3">Actual User Migration</h5>
                                    <img src="/Images/actual_user_migration.png" alt="service-account-migration" className="customMarginBottomForActualUserImage" />
                                    <p className="px-5 card-text font-14  font-regular">Migrate channels from Slack on behalf of actual users as MS Teams public channels.</p>
                                    <a className='anchorAsPointer' href="/ChannelMigration/AU/TeamChannelMapping"><button type="button" className="btn-primary-theme common-btn-class btn btn-migrate-channel anchorAsPointer font-16 font-regular">Migrate Channels</button></a><p className="card-text my-4 font-12 font-regular">Click <a className='anchorAsPointer' href="https://www.avamigratron.com/Product/migrate-the-slack-conversation-to-ms-teams-as-original-as-slack-using-user-mapping/" target="_blank">here</a> to know more about Actual User Migration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="failedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{statusText}</p>
                                <div id="dynamicData"></div>
                                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" onClick={disablePopUp} data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

            {/* <!--Items in cart popup--> */}
        </div>


    );
}