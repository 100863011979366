import { useHistory } from 'react-router-dom';
import { callBackEnd, sendRequestToAPI } from "../../Services/GroupChatMigrationServices";
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import WizardNavigation from './Wizard';
import BackNavigation from './BackNavigation'
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import { debug } from 'console';





export default function MSTeamsUserVerification(): JSX.Element {

    let history = useHistory();
    const [statusCount, setStatusCount] = useState({ linkMailedCount: 0, verifiedCount: 0, yetToVerifyCount: 0 });
    const [importedFileName, setimportedFileName] = useState("No File Chosen");
    const [failedPopUp, setfailedPopUp] = useState("");
    const [successPopUp, setsuccessPopUp] = useState("");
    const [importedUsersList, setimportedUsersList] = useState({});
    const [tempUsersList, setTempUsersList] = useState([{ Status: "" }]);
    const [isUseEffectTrigger, setisUseEffectTrigger] = useState(false);
    let [clientID, setClientID] = useState(0);
    let [clientToken, setClientToken] = useState("");
    let [adminToken, setadminToken] = useState("");
    let [csvData, setCsvData] = useState([{ Status: "" }]);
    let [csvName, setCsvName] = useState("");
    let [isSettingsConnected, setIsSettingsConnected] = useState(0);
    let [nonGifLoaderContent, setNonGifLoaderContent] = useState("");
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout : NodeJS.Timeout;  
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

    let [clientObj,setClientObj] = useState({
        isChatServiceAccountMigration:false,
        isChatHTMLMigration:false
    })


    useEffect(() => {
        resetSessionTimer();
        let client_Token: any;
        if (local != undefined) {
            let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
            decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
            objClient = jwt(decryptedValue);
            setClientObj(objClient)
            SessionEpiredDateTime = objClient.CreatedAt;
            clientID = Math.random();
            setClientID(clientID);
            clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
            setClientToken(clientToken);

            if(adminLocal != undefined){
                let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
                let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
                adminObjClient = jwt(adminDecryptedValue);
                adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
                setadminToken(adminToken);
              }
        } 
    
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.remove("container-bg-withFooter");
        removeHeight.classList.add("container-bg-withoutFooter-chat");

        pageLoad();
        if (importedFileName == undefined || importedFileName == "No File Chosen") {
            document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
        }
        else {
            document.getElementById("sendVerification")?.removeAttribute("Disabled");
        }

        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };

        
    }, [isUseEffectTrigger])

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
             
            setfailedPopUp("Your session has expired. Please log in again to continue using the application.")

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion


    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    endpoint: "/ClientSession",
                    token: clientToken
                }

                let data: any = await sendRequestToAPI(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion
                    //#region Update state variables
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                     let updatedobjClient: any = jwt(dePass);
                     SessionEpiredDateTime = updatedobjClient.CreatedAt;
                     setSessionEpiredDateTime(SessionEpiredDateTime);
                     clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);

          //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
        
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support");

            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset");
            clearTimeout(InActiveTimeout);  
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support");
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    

    const pageLoad = async () => {
        let loader;
        try {
            if (clientID != undefined && clientToken != undefined) {

                let config = {
                    endpoint: "GetVerifiedUserStatus",
                    token: clientToken,
                    data: {
                        clientID: clientID,
                        type: "Teams",
                        requestType: "Status"
                    },
                }
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                }

                await triggerPopUpLoader({ isLoaderTrigger: true, isContentLoader: false });
                let nodeResponse = await sendRequestToAPI(config);
                await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false });
                
                if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                    let val = {
                        linkMailedCount: ((nodeResponse.data.data[0][0].UsersSetForVerification == 0) ? 0 : ((nodeResponse.data.data[0][0].UsersSetForVerification) - 1)),
                        verifiedCount: (nodeResponse.data.data[0][0].TargetUserPassword),
                        yetToVerifyCount: ((nodeResponse.data.data[0][0].UsersSetForVerification - 1) - (nodeResponse.data.data[0][0].TargetUserPassword - 1)),
                    }
                    setStatusCount(val);

                    if (nodeResponse.data.data[1].length == 0 || nodeResponse.data.data[1][0] == undefined || nodeResponse.data.data[1][0].length == 0 || nodeResponse.data.data[1][0].MSTeamsUserMailID == undefined || nodeResponse.data.data[1][0].MSTeamsUserMailID == null || nodeResponse.data.data[1][0].MSTeamsUserMailID == "") {
                        isSettingsConnected = 0;
                        setIsSettingsConnected(isSettingsConnected);
                    }
                    else {
                        isSettingsConnected = 1;
                        setIsSettingsConnected(isSettingsConnected);
                    }
                }
                else if(nodeResponse.data.data == "Invalid authentication token provided")
                {
                    enableSessionExpiryPopup();
                }
                else {
                    let val = {
                        linkMailedCount: 0,
                        verifiedCount: 0,
                        yetToVerifyCount: 0,
                    }
                    setStatusCount(val);
                    setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true, isContentLoader: false });
                }
            }
            else {
                localStorage.clear();
            }
        } catch (error) {
            
            let val = {
                linkMailedCount: 0,
                verifiedCount: 0,
                yetToVerifyCount: 0,
            }
            setStatusCount(val);
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true, isContentLoader: false });

        }
    }

    const triggerPopUpLoader = async (control: any) => {
        try {

            if (control.isLoaderTrigger != undefined) {
                let loaderElement = control.isContentLoader ? document.getElementById("sourceLoader")  : document.getElementById("loaderWithoutContent")

                if (control.isLoaderTrigger) {
                    loaderElement?.removeAttribute("hidden")
                }
                else {
                    loaderElement?.setAttribute("hidden", "true")
                }
            }
            if (control.isSuccessTrigger != undefined) {
                if (control.isSuccessTrigger) {
                    //#region Show PopUp
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }
                else {
                    //#region Hide PopUp
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                    //#endregion
                }
            }
            if (control.isFailedTrigger != undefined) {
                if (control.isFailedTrigger) {
                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }
                else {
                    //#region Hide PopUp
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

                    //#endregion
                }
            }

        } catch (error) {

            ////console.log("Trigger Loader:", error);
        }
    }

    const getUsersListClick = async () => {
        try {

            if (clientID != undefined && clientToken != undefined) {
                
                if (isSettingsConnected) {

                    document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
                    await triggerPopUpLoader({ isLoaderTrigger: true, isContentLoader: false });

                    let config = {
                        endpoint: "GetUserProfile",
                        token: clientToken,
                        data: {
                            clientID: clientID,
                            type: "Teams"
                        }
                    }
                    if(adminLocal !=undefined && adminLocal !== null){
                        (config.data as any).token = adminToken
                    }
                    let nodeResponse = await sendRequestToAPI(config);

                    if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                        setCsvData(nodeResponse.data.data[1]);
                        setCsvName("MS-TeamsUsersList");
                        setsuccessPopUp("MS Teams Users List Downloaded Successfully");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isSuccessTrigger: true });
                    }
                    else if(nodeResponse.data.data == "Invalid authentication token provided")
                    {
                        enableSessionExpiryPopup();
                    }
                    else {
                        setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isFailedTrigger: true });
                    }
                }
                else {
                    setfailedPopUp("Please Connect the MS Teams Tenant!");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isFailedTrigger: true });
                }
            }
            else {
                localStorage.clear();
            }
        } catch (error) {

            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isFailedTrigger: true });
        }
        finally {
            setimportedFileName("No File Chosen");
            setimportedUsersList({});
        }
    }

    const downloadCSVFromJson = async (arrayOfJson: any, fileName: string) => {
        try {
           
            const workSheet = XLSX.utils.json_to_sheet(arrayOfJson);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, fileName)
            XLSX.writeFile(workBook, `${fileName}.csv`)

        } catch (error) {

            ////console.log("Download File Method:", error);
        }
    };

    const btnImportClick = async (e: any) => {
        try {

            document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
            
            const file = e.target.files[0];
            e.target.value = null;
            if (file != undefined) {
                setimportedFileName(file.name)
                const reader = new FileReader();
                reader.onload = async (evt: any) => {
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    let csvDetails = ['MSTeamsUserName', 'MSTeamsUserMailID', 'IsMSTeamAccess', 'IsRemoved']
                    const data: any = XLSX.utils.sheet_to_json(ws, { header: ['MSTeamsUserName', 'MSTeamsUserMailID', 'IsMSTeamAccess', 'IsRemoved', 'InValidHeader'], raw: true, defval: "" });/* Remove Empty Rows and Convert Array to JSON */
                    

                    if (data.length >= 2 && csvDetails.includes(data[0].MSTeamsUserName) && csvDetails.includes(data[0].MSTeamsUserMailID) && csvDetails.includes(data[0].IsMSTeamAccess) && csvDetails.includes(data[0].IsRemoved) && data[0].InValidHeader == undefined && file.name.includes('.csv')) {
                        var ChatDetails = data
                        ChatDetails.shift()
                        document.getElementById("sendVerification")?.removeAttribute("Disabled");
                        setimportedUsersList(ChatDetails)
                        setTempUsersList(ChatDetails);
                    }
                    else {
                        setfailedPopUp("Please provide valid template!");
                        await triggerPopUpLoader({ isFailedTrigger: true });
                        setimportedUsersList({});
                        setimportedFileName("No File Chosen");
                    }
                };
                reader.readAsBinaryString(file);
            }
            else {
                setimportedUsersList({});
                setimportedFileName("No File Chosen");
            }
        } catch (error) {

            ////console.log("Import Click:", error);
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            
            setimportedFileName("No File Chosen");
            setimportedUsersList({});
        }
    }

    const btnSendVerificationClick = async () => {
        try {
            if (clientID != undefined && clientToken != undefined) {
                setimportedFileName("No File Chosen");
                document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
                if (isSettingsConnected) {
                    await triggerPopUpLoader({ isLoaderTrigger: true, isContentLoader: true }  );
                    
                    // let loader = document.getElementById("loaderWitoutContent");
                    // loader?.removeAttribute('hidden');
                    
                    let config = {
                        endpoint: "ValidateUserDetails",
                        token: clientToken,
                        data: {
                            clientID: clientID,
                            type: "Teams",
                            users: importedUsersList
                        }
                    }
                    if(adminLocal !=undefined && adminLocal !== null){
                        (config.data as any).token = adminToken
                    }

                    let nodeResponse = await sendRequestToAPI(config);


                    // loader?.setAttribute('hidden', 'hidden')

                    if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                        setsuccessPopUp("Verification link sent to users");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isSuccessTrigger: true, isContentLoader: true });
                        isUseEffectTrigger==false ? setisUseEffectTrigger(true) : setisUseEffectTrigger(false);
                    }
                    else if (typeof (nodeResponse.data.data) != 'string') {
                        setfailedPopUp("Please provide valid MSTeams User Details");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true, isContentLoader: true  });
                        ////console.log(nodeResponse.data.data);
                        ////console.log(tempUsersList);
                        for (let i = 0; i < nodeResponse.data.data.length; i++) {
                            tempUsersList[i].Status = nodeResponse.data.data[i].Status
                        }
                        //setCsvData(nodeResponse.data.data);
                        setCsvData(tempUsersList)
                        setCsvName("Invalid Users List");
                    }
                    else if(nodeResponse.data.data == "Invalid authentication token provided")
                    {
                        enableSessionExpiryPopup();
                    }
                    else {
                        setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true, isContentLoader: true  });
                        isUseEffectTrigger==false ? setisUseEffectTrigger(true) : setisUseEffectTrigger(false);
                    }
                }
                else {
                    setfailedPopUp("Please Connect the MS Teams Tenant!");
                    await triggerPopUpLoader({ isFailedTrigger: true });
                }
            }
            else {
                localStorage.clear();
            }
        } catch (error) {

            ////console.log("Send Verification Click:", error);
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: true, isFailedTrigger: true });
            isUseEffectTrigger==false ? setisUseEffectTrigger(true) : setisUseEffectTrigger(false);
        }
    }

    const btnRefreshClick = async () => {
        try {
            
            document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
            if (isSettingsConnected) {
                try {
                    pageLoad();
                } catch (error) {
                    setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isFailedTrigger: true });
                }
            }
            else {
                setfailedPopUp("Please Connect the MS Teams Tenant!");
                await triggerPopUpLoader({ isLoaderTrigger: false,  isContentLoader: false, isFailedTrigger: true });
            }
        } catch (error) {

            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
            await triggerPopUpLoader({ isLoaderTrigger: false, isContentLoader: false, isFailedTrigger: true });
        }
        finally {
            setimportedUsersList({});
            setimportedFileName("No File Chosen");
        }
    }

    const btnDownloadVerifiedUsersClick = async () => {
        try {
            
            if (clientID != undefined && clientToken != undefined) {
                document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
                //await triggerPopUpLoader({ isLoaderTrigger: true });
                if (isSettingsConnected) {
                    let config = {
                        endpoint: "GetVerifiedUserStatus",
                        token: clientToken,
                        data: {
                            clientID: clientID,
                            type: "Teams",
                            requestType: "Details"
                        }
                    }

                    if(adminLocal !=undefined && adminLocal !== null){
                        (config.data as any).token = adminToken
                    }

                    //await triggerPopUpLoader({ isLoaderTrigger: true,  isContentLoader: false});
                    let nodeResponse = await sendRequestToAPI(config);
                    //await triggerPopUpLoader({ isLoaderTrigger: false,  isContentLoader: false });
                    
                    if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                        await downloadCSVFromJson(nodeResponse.data.data[0], "VerifiedMSTeamsUsers");
                    }
                    else if(nodeResponse.data.data == "Invalid authentication token provided")
                    {
                        enableSessionExpiryPopup();
                    }
                    else {
                        setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                        await triggerPopUpLoader({ isFailedTrigger: true });
                        // setisUseEffectTrigger(true);
                    }
                    
                }
                else {
                    setfailedPopUp("Please Connect the MS Teams Tenant!");
                    await triggerPopUpLoader({ isFailedTrigger: true });
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {

            ////console.log("Download Verified Users Click:", error);
            setfailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            await triggerPopUpLoader({isFailedTrigger: true });
        }
    }

    const step1HideShowClick = async () => {
        try {
            if (document.getElementById("panelsStayOpen-collapseOne")?.hidden == true) {
                document.getElementById("panelsStayOpen-collapseOne")?.removeAttribute("hidden");
                document.getElementById("step1Arrow")?.setAttribute("class", "close-dropDown");
            }
            else {
                document.getElementById("panelsStayOpen-collapseOne")?.setAttribute("hidden", "true");
                //document.getElementById("step1Arrow")?.setAttribute("class", "open-dropDown");  
                document.getElementById("step1Arrow")?.setAttribute("class", "open-dropDown-cm");
            }
        } catch (error) {
            ////console.log("Step1HideShowClick Error:", error);
        }
    }

    const step2HideShowClick = async () => {
        try {
            if (document.getElementById("panelsStayOpen-collapseTwo")?.hidden == true) {
                document.getElementById("panelsStayOpen-collapseTwo")?.removeAttribute("hidden");
                document.getElementById("step2Arrow")?.setAttribute("class", "close-dropDown");
            }
            else {
                document.getElementById("panelsStayOpen-collapseTwo")?.setAttribute("hidden", "true");
                //document.getElementById("step2Arrow")?.setAttribute("class", "open-dropDown");
                document.getElementById("step2Arrow")?.setAttribute("class", "open-dropDown-cm");
            }
        } catch (error) {
            ////console.log("Step2HideShowClick Error:", error);
        }
    }

    const popUpOkClick = async (isSuccess: any) => {
        try {
            if (isSessionEpired === false) {
            if (csvName != "" && csvData != []) {
                await downloadCSVFromJson(csvData, csvName);
            }
            isSuccess == true ? await triggerPopUpLoader({ isSuccessTrigger: false }) : await triggerPopUpLoader({ isFailedTrigger: false })
        }
        else {

            window.location.pathname = "/Login";

        }

        } catch (error) {
            ////console.log("popupOkClickError: " + error);
        }
        finally {
            csvData = [];
            setCsvData(csvData);
            csvName = "";
            setCsvName(csvName);
        }
    }

    return (
        <div className="row justify-content-center card-container mx-0 ms-0 overall-res-padding">
            {(clientObj.isChatServiceAccountMigration || clientObj.isChatHTMLMigration) ? (
                <BackNavigation backNav=" Migration using Actual User  " link='/Conversation' />
            ) : (
                <h4 className="font-26 font-bold">Conversation Migration</h4>
            )}           
            <WizardNavigation wizActive={"step1InActive"} />
            <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>User Verification</p>
            <div className="col-md-12 col-lg-12 col-sm-10 theme-color-bg py-2 p-3 rounded-2 mb-4 info-background-color width-98 font-14">
                <label className="font-14"><span className="me-2"><img src="/Images/info-icon.svg" alt="info_small" /></span><span>Complete </span><a href="/UserMapping" className="theme-color  font-bold anchorAsPointer">User Mapping </a><span className="font-regular">& then proceed with MS Teams user verification to migrate direct & multi-party chats.</span></label>
            </div>
            <div className="col-md-12">
                {/*accordian starts here*/}
                <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item mb-2 float-start w-100">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <div className="accordion-open " id="usersListDiv" >
                                <label className="accordine-font-size-res font-semibold accordion-stepstyle anchorAsPointer" onClick={step1HideShowClick}>
                                    <img id="step1Arrow" src="/Images/accordian-open.svg" className="close-dropDown" />Step 1 :&nbsp;
                                </label>
                                <label className="accordine-font-size-res font-bold accordion-stepstyle2 anchorAsPointer" onClick={step1HideShowClick}> Get MS-Teams Users List</label>
                            </div>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse mb-3 show">
                            <div className="accordion-body">
                                <div className="col-md-12 mt-2 ">
                                    <label className=" slack-details font-14 font-regular me-2">Fetch the list of users from MS-Teams here!</label>
                                    <button type="button" className="common-btn-class btn-primary-theme btn btn-user-list font-16 get-users-btn font-semibold" onClick={getUsersListClick}>Get Users List</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item pb-5 float-start w-100">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <div className="accordion-open ">
                                <label className="font-18 font-semibold accordion-stepstyle anchorAsPointer" onClick={step2HideShowClick}>
                                    <img id="step2Arrow" src="/Images/accordian-open.svg" className="close-dropDown" />Step 2 :&nbsp;
                                </label>
                                <label className="font-18 font-bold accordion-stepstyle2 anchorAsPointer" onClick={step2HideShowClick}>{` User Verification`}</label>
                            </div>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show">
                            <div className="accordion-body col-md-10">
                                <div className="col-md-12 mt-2">
                                    <label className=" customize-details font-14 font-regular mb-3">Customize the downloaded users list and
                                        import the sheet to verify users. Users will receive the verification link in their emails!</label>
                                    <div className="w-100 float-start mb-5">
                                        <div className="input-group">
                                            <div className="fileUpload btn btn-browse px-4 my-3 anchorAsPointer">
                                                <span className="font-17" >Browse</span>
                                                <input id="uploadBtnSlack" type="file" accept=".csv" className="upload" onChange={btnImportClick} />
                                            </div>
                                            <label id="uploadFileSlack" className="file-choosen-alignment formcontrol-browse-uv1 me-3 mb-3 pt-3 ps-2">{importedFileName}</label>

                                            <button type="button" className="common-btn-class  btn-primary-theme btn btn-user-list font-16 font-semibold my-3 sendVerificationButton border-radius-left" id="sendVerification" onClick={btnSendVerificationClick}>Send Verification Link</button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-between margin-bottom-responsive-uv">
                                        <div className="col-sm-12 col-md-12 col-lg-12 d-lg-flex align-items-center  mb-2">
                                          <div className="col-md-12 col-lg-4 col-sm-12">
                                            <label className="user-status font-18 my-2 font-semibold">User Verification Status</label>
                                          </div>
                                        <div className="col-md-12 col-lg-8 col-sm-12 text-lg-end text-sm-start PreMigrationDownloadRefresh ps-0">
                                            <button className="btn btn-download-verification my-2 me-3 verification-btn-responsive font-semibold font-16" onClick={btnDownloadVerifiedUsersClick}>
                                                <span><img src="/Images/download.svg" alt="sort-icon" /></span> Download Verification
                                                Status</button>
                                            <button className="common-btn-class my-2 ms-0 btn-primary-theme btn btn-refresh-userverification font-semibold font-16 margin-right-10" onClick={btnRefreshClick}>
                                                <span><img className="refresh-img me-2" src="/Images/refresh.svg" alt="refresh-icon" /></span>Refresh</button>
                                        </div>
                                    </div>
                                    <div className="row d-lg-flex">
                                        <div className="col-lg-12 col-sm-12 col-md-12 px-0 d-lg-flex">
                                            <div className='col-sm-12 col-md-12 col-lg-4 mb-2'>
                                            <div className="card ms-3 me-0 card-margin-responsive-uv">
                                                <div className="card-body">
                                                    <label className="card-text mb-3">Link sent for User verification</label>
                                                    <div className="w-100 float-start">
                                                        <div className="float-start">
                                                            {/* <span className="user-num font-semibold font-20">{statusCount.linkMailedCount}</span> */}
                                                            <span className="user-num font-semibold font-20">{(statusCount.linkMailedCount).toString().length > 3 ? (statusCount.linkMailedCount).toLocaleString("en-US") : statusCount.linkMailedCount }</span>
                                                        </div>
                                                        <div className="float-end">
                                                            <img className="mail-icon" src="/Images/mail-icon.svg" alt="mail-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                        <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                                            <div className="card ms-3 me-0 card-margin-responsive-uv">
                                                <div className="card-body">
                                                    <label className="card-text mb-3">Verified Users</label>
                                                    <div className="w-100 float-start">
                                                        <div className="float-start">
                                                            {/* <span className="user-num font-semibold font-20">{statusCount.verifiedCount}</span> */}
                                                            <span className="user-num font-semibold font-20">{(statusCount.verifiedCount).toString().length > 3 ? (statusCount.verifiedCount).toLocaleString("en-US") : statusCount.verifiedCount }</span>
                                                        </div>
                                                        <div className="float-end">
                                                            <img className="mail-icon" src="/Images/user-icon.svg" alt="user-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                                            <div className="card ms-3 me-0 card-margin-responsive-uv">
                                                <div className="card-body">
                                                    <label className="card-text mb-3">Users yet to verify</label>
                                                    <div className="w-100 float-start">
                                                        <div className="float-start">
                                                            {/* <span className="user-num font-semibold font-20">{statusCount.yetToVerifyCount}</span> */}
                                                            <span className="user-num font-semibold font-20">{(statusCount.yetToVerifyCount).toString().length > 3 ? (statusCount.yetToVerifyCount).toLocaleString("en-US") : statusCount.yetToVerifyCount }</span>
                                                        </div>
                                                        <div className="float-end">
                                                            <img className="mail-icon" src="/Images/user-time.svg" alt="usertime-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/success.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">{successPopUp}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={() => { popUpOkClick(true) }}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}

            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="sourceFailedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={() => { popUpOkClick(false) }}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}

            {/* Loader with content starts here */}
            <div className="overlay" id='sourceLoader' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="text-center ">Please wait. We are sending verification link to the specified Teams Users</p>
                </div>
            </div>
            {/* loader with content ends here */}

            {/* loader without content starts here  */}
            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
            {/* loader without content ends here */}


            {/* <!--Loader Screen--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            </div>
        </div>
    )
}

 