import React, { useEffect, useState } from 'react';
import Warning from './Warning';
import Success from './Success'
import All from './All'
import { ExportToCsv } from 'export-to-csv';
import { gridLoad } from '../../Services/UserMappingServices';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import dateFormat from "dateformat"
import constants from '../../Common/Constants';

export default function GridComponent() {
  let a: any[] = [];
  let GridLoadarray: any[] = [];
  const activeTab = "common-report-navTab py-2 nav-link font-15 active nav-tab-custom font-bold anchorAsPointer";
  const inActiveTab = "common-report-navTab py-2 nav-link font-15 nav-tab-custom font-bold anchorAsPointer";
  const state = useLocation().state as any;
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(3);
  const [searchValue, setSearchValue] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("total");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("Grid");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [chatType, setChatType] = useState(state.ChatType)
  const [WorkspaceName, setWorkspaceName] = useState("");
  const [TenantName, setTenantName] = useState("");
  const [countPerPage, setCoutPerPage] = useState(3);
  const [MigratedDateTime, setMigratedDateTim] = useState(state.MigratedDateTime)
  let [checkString, setString] = useState("");
  let history = useHistory();
  let clientID = state.clientID;
  let clientToken = state.clientToken;
  checkString = state.type;

  useEffect(() => {
    setString(checkString)
    let addActiveTab = (state.type == "success") ? document.getElementById("success") : (state.type == "warning") ? document.getElementById("warning") : document.getElementById("All")
    addActiveTab?.setAttribute("classname", activeTab);

    //loadGridPage();
    
  }, [columnName, columnOrder, countPerPage])

  const browsealertokclick = () => {
    try {
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
      document.getElementById("wrongalert")?.setAttribute("style", "display:none");
      document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
      document.getElementById("wrongalert")?.removeAttribute("aria-modal");
      document.getElementById("wrongalert")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    }
    catch (error) {
    }
  }

  const tabSwitchAll = (e: any) => {
    try {
      state.type = "All";
      setString(state.type);
      document.getElementById("All")?.setAttribute("className", activeTab)
      document.getElementById("warning")?.setAttribute("className", inActiveTab)
      document.getElementById("success")?.setAttribute("className", inActiveTab)
    }
    catch (error) {
    }
  }

  const tabSwitchwarning = (e: any) => {
    try {
      state.type = "warning";
      setString(state.type);
      document.getElementById("All")?.setAttribute("className", inActiveTab)
      document.getElementById("warning")?.setAttribute("className", activeTab)
      document.getElementById("success")?.setAttribute("className", inActiveTab)
    }
    catch (error) {
    }
  }

  const tabSwitchsuccess = (e: any) => {
    try {
      state.type = "success";
      setString(state.type);
      document.getElementById("All")?.setAttribute("className", inActiveTab)
      document.getElementById("warning")?.setAttribute("className", inActiveTab)
      document.getElementById("success")?.setAttribute("className", activeTab)
    }
    catch (error) {
    }
  }

  const handleExportBtn = async () => {
    if (chatType != null) {
      let config = ({
        method: "POST",
        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getIndividualReport`,
        data:
        {
          "clientID": 1,
          "type": "Export",
          "chatType": `${chatType}`,
          "sortColumn": "",
          "searchText": `${searchValue}`,
          "pageIndex": 1,
          "MigratedDateTime": `${MigratedDateTime}`,
          "status": ""
        }
      })
      let data: any = await gridLoad(config);
      setWorkspaceName(data.data.data[0][0].WorkspaceName);
      setTenantName(data.data.data[0][0].TenantName);
      let exportData = data.data.data[0];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Export Details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Individual Report Details"
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportData);
    }
    else {
      let config = ({
        method: "POST",
        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getIndividualReport`,
        data:
        {
          "clientID": 1,
          "type": "Export",
          "chatType": null,
          "sortColumn": "",
          "searchText": `${searchValue}`,
          "pageIndex": 1,
          "MigratedDateTime": `${MigratedDateTime}`,
          "status": ""
        }
      })
      let data: any = await gridLoad(config);
      setWorkspaceName(data.data.data[0][0].WorkspaceName);
      setTenantName(data.data.data[0][0].TenantName);
      let exportData = data.data.data[0];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Export Details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Individual Report Details"
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(exportData);
    }
  }

  const gridBodyData = () => {

    try {
      return gridData.map((obj, index) => {
        if (obj.SourceChatName.length > 15) {
          obj.SourceChatName1 = obj.SourceChatName.substring(0, 15) + "..."
        }
        else {
          obj.SourceChatName = obj.SourceChatName;
        }
        if (obj.ChatType != "Channel") {
          return (
            <div className="col-12 mt-4 float-start">
              <div className="row">
                <div className="col-md-1">
                  <div className="row">
                    <div className="col-12 mt-4 p-0">
                      <label className=" setting-heading3 float-end font-16 font-semibold">{dateFormat(obj.MigratedDateTime, "mm/dd/yyyy")}</label>
                    </div>
                    <div className="col-12 ps-2 p-0 ">
                      <label className="pt-3 setting-subhead float-end font-16 font-regular">{dateFormat(obj.MigratedDateTime, "HH:MM:ss")}</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-11 mt-3">
                  <div className={obj.MigratedStatus == "Success" || "success" ? 'bd-callout bd-callout-success' : 'bd-callout bd-callout-error'}>
                    <div className="row pb-3">
                      <div className="col-4">
                        <label data-tip={obj.SourceChatName} className="slack-style font-16 font-regular">Slack Chat :</label> <label className="font-16 font-regular user-name">{obj.SourceChatName1}</label>
                      </div>
                      <div className="col-4">
                        <label className="slack-style font-16 font-regular">Slack Chat Type :</label> <label className="font-16 font-regular user-name">{obj.ChatType}</label>
                      </div>
                      <div className="col-4">
                        <label className="slack-style font-16 font-regular">Team  :</label> <label className="font-16 font-regular user-name">{obj.TargetChannelName}</label>
                      </div>
                      <div className="col-4">
                        <label className="slack-style font-16 font-regular">Team Channel :</label> <label className="font-16 font-regular user-name">{obj.TargetChannelName}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="font-16 font-regular user-name">{obj.ErrorMessage} </label>
                      <ReactTooltip globalEventOff="click" multiline={true} backgroundColor='white' textColor='black' border={true} borderColor='black' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        else {
          return (
            <div className="col-12 mt-4 float-start">
              <div className="row">
                <div className="col-md-1">
                  <div className="row">
                    <div className="col-12 mt-4 p-0">
                      <label className=" setting-heading3 float-end font-16 font-semibold">{dateFormat(obj.MigratedDateTime, "mm/dd/yyyy")}</label>
                    </div>
                    <div className="col-12 ps-2 p-0 ">
                      <label className="pt-3 setting-subhead float-end font-16 font-regular">{dateFormat(obj.MigratedDateTime, "HH:MM:ss")}</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-11 mt-3">
                  <div className={obj.MigratedStatus == "Success" || "success" ? 'bd-callout bd-callout-success' : 'bd-callout bd-callout-error'}>
                    <div className="row pb-3">
                      <div className="col-4">
                        <label data-tip={obj.SourceChatName} className="slack-style font-16 font-regular">Slack Chat :</label> <label className="font-16 font-regular user-name">{obj.SourceChatName1}</label>
                      </div>
                      <div className="col-4">
                        <label className="slack-style font-16 font-regular">Slack Chat Type :</label> <label className="font-16 font-regular user-name">{obj.ChatType}</label>
                      </div>
                      <div className="col-4">
                        <label className="slack-style font-16 font-regular">MS Team  :</label> <label className="font-16 font-regular user-name">{obj.TargetChannelName}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <label className="font-16 font-regular user-name">{obj.ErrorMessage} </label>
                      <ReactTooltip globalEventOff="click" multiline={true} backgroundColor='white' textColor='black' border={true} borderColor='black' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }
      )
    }
    catch (error: any) {
      ////console.log("error",error);
    }
  }

  const handleBackButton = (e: any) => {
    try {
      let val: any = { tab: state.reportLandingtab, WorkspaceName: state.WorkspaceName, TenantName: state.TenantName }
      history.push({
        pathname: "/Report",
        state: val
      }
      )
    }
    catch (error: any) {
    }
  }


  return (
    <div className="row justify-content-center card-container mx-0 ms-0 overall-res-padding ">
      <div className="col-md-12  float-start px-3">
        <div className="row my-3">
          <div className="col-md-12 float-start my-4">
            <label className=" font-26 font-bold anchorAsPointer">
              <a className='me-2'>
                <img title="Back" src="/Images/backarrow.svg" alt="Back arrow" onClick={(e) => handleBackButton(e)} />
              </a>
              Individual Report</label>
          </div>
          <div className="col-md-11 float-start">
            <div className="float-start w-100">
              <div className="float-start">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">

                    <a className={(checkString == "All") ? activeTab : inActiveTab} id='All' data-bs-toggle="tab" onClick={(e) => tabSwitchAll(e)}>All  ({state.Total})</a>

                  </li>
                  <li className="nav-item">
                    <a className={(checkString == "warning") ? activeTab : inActiveTab} id='warning' data-bs-toggle="tab" onClick={(e) => tabSwitchwarning(e)}>Warning  ({state.failed})</a>
                  </li>
                  <li className="nav-item">
                    <a className={(checkString == "success") ? activeTab : inActiveTab} id='success' data-bs-toggle="tab" onClick={(e) => tabSwitchsuccess(e)}>Success  ({state.success})</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 float-start mt-2">
            {(checkString == "warning")
              ? <Warning />
              : (checkString == "success")
                ? <Success />
                : <All />}

          </div>
        </div>
        <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
          <div className="modal-dialog modal-md">
            <div className="modal-content content-margin">
              <div className="modal-body">
                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                <div className="text-center success-content w-100 mt-5">
                  <h5 className="font-semibold font-18">Alert!</h5>
                  <p className="success-text w-100 text-center mb-4">"Something went wrong! Please try again or reach AVAMIGRATRON Support."</p>
                  <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

        <div className="overlay" id='loader' hidden={true}>
          <div className="Loader-Content-position">
            <div className="spinner-border loaderImg text-primary align-center  Loader-Img-position" role="status">
              <span className="visually-hidden"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
