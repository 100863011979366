import React, { useEffect, useState } from 'react';
import { GridLoad } from '../../Services/ChannelMigrationServices';
import { useHistory, useLocation } from 'react-router-dom';
import WizardNavigation from './Wizard'
import BackNavigation from './BackNavigation'
import ReactTooltip from "react-tooltip";
import CryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';
import '../../styles/channel_migration_landing.css'


export default function ChannelCompletion() {
    let GridLoadarray: any[] = [];
    let [statusText, setStatus] = useState("")
    const state: any = useLocation().state;
    const [gridData, setGridData] = useState(GridLoadarray);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [columnOrder, setColumnOrder] = useState("asc");
    const [columnName, setColumnName] = useState("ChannelName");
    const [sorthide, setsorthide] = useState("");
    const [countPerPage, setCoutPerPage] = useState(10);
    const [indexPerPage, setIndexPerPage] = useState(1);
    const [tmpsearchValue, settmpSearchValue] = useState("");
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;


    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientIDValdidate: any;
    let clientToken: any;
    let adminToken: any;

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientIDValdidate = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }

    let history = useHistory();

    useEffect(() => {
        let x = document.getElementById("loader")
        x?.removeAttribute("hidden")
        resetSessionTimer();
        loadGridPage();
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.remove("container-bg-withoutFooter")
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion

        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };

    }, [columnName, columnOrder, indexPerPage])


    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            statusText = "Your session has expired. Please log in again to continue using the application."
            setStatus(statusText)

            //#region Show PopUp
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            document.getElementById("loader")?.setAttribute("hidden", "true")
            // //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion


    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientIDValdidate != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await GridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {

            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            document.getElementById("loader")?.setAttribute("hidden", "true")
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            document.getElementById("loader")?.setAttribute("hidden", "true")
        }
    }
    //#endregion


    const loadGridPage = async () => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/TeamChannelCompletion/GetMigratedTeamChannel`,
                    token: clientToken,
                    data: {
                        "clientID": parseInt(state.clientID),
                        "type": "Channel",
                        "sortType": `${columnOrder}`,
                        "sortColumn": `${columnName}`,
                        "searchText": `${searchValue}`,
                        "teamID": `${state.id}`,
                        "pageIndex": indexPerPage
                    },
                }
                )

                let x = document.getElementById("loader")
                x?.removeAttribute("hidden")
                let data: any = await GridLoad(config);
                if (data.data.statusCode == 200) {
                    x?.setAttribute("hidden", "true")
                    data.data.data == "No Records Found" ? setGridData(["No Records Found"]) : setGridData(data.data.data[0]);
                    let ototalItemsCount = data.data.data == "No Records Found" ? 0 : data.data.data[0].length;
                    setTotalItemsCount(ototalItemsCount);
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden")
                    x?.setAttribute("hidden", "true")
                    data.data.data == "No Records Found" ? setGridData(["No Records Found"]) : setGridData(data.data.data[0]);
                }

            }
            else {
                localStorage.clear()
            }
        }
        catch (error: any) {
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            document.getElementById("loader")?.setAttribute("hidden", "true")
            ////console.log("error:", error);

        }
    }

    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong! Please try again or reach AVAMIGRATRON Support" && typeof gridData != 'string') {

                return gridData.map((obj, index) => {
                    let rowClass: any = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let cssClass = ""
                    if (obj.Status == "Completed") {
                        cssClass = "status status-styling4 font-14 font-semibold text-center"
                    }
                    else if (obj.Status == "Failed") {
                        cssClass = "status status-styling3 font-14 font-semibold text-center"
                    }
                    else {
                        cssClass = "status status-styling2 font-14 font-semibold text-center"
                    }

                    return (

                        <tr className={rowClass} key={obj.ChannelCount}>
                            {
                                (obj.ChannelName).length > 43 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.ChannelName} >
                                        {(obj.ChannelName).substring(0, 43)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td className="py-3 tabel-data-font-level1" >{obj.ChannelName}</td>
                            }
                            {obj.Status === 'Queue' ? (
                                <td className="py-3 tabel-data-font-level1 text-center"> <span className="queue-status status-block font-14">Queue</span></td>
                            ) :
                                obj.Status === 'In Progress' ? (
                                    <td className="py-3 tabel-data-font-level1 text-center"> <span className="inprogress-status status-block font-14">In progress</span></td>
                                )
                                    :
                                    obj.Status === 'Completed' ? (
                                        <td className=" py-3 tabel-data-font-level1 text-center"><span className="completed-status status-block font-14">Completed</span></td>
                                    )
                                        :
                                        obj.Status === null ? (
                                            <td className=" py-3 tabel-data-font-level1 text-center"><span className="yettostart-status status-block font-14">Yet to Start</span></td>

                                        )
                                            :
                                            obj.Status === 'Scheduled' ? (
                                                <td className="py-3 tabel-data-font-level1 text-center"><span className="scheduled-status status-block font-14">Scheduled</span></td>
                                            )
                                                :
                                                (
                                                    <td className="py-3 tabel-data-font-level1"> <span className="status status-styling font-14 font-semibold">{obj.Status}</span></td>
                                                )
                            }

                            <td className="py-3 tabel-data-font-level1 text-center">{obj.ChannelType}</td>
                        </tr>


                    )

                })
            }
            else if (gridData[0] == undefined) {

                return (<tr> <td className="aligncenter" colSpan={6}></td></tr>)

            }
            else {
                return (

                    <tr>

                        <td className="aligncenter" colSpan={7}><img src="/Images/NoRecordsFound.svg"></img>
                            <p className="mt-2">No records found</p></td>

                    </tr>)
            }
        }
        catch (error: any) {
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            document.getElementById("loader")?.setAttribute("hidden", "true")
            ////console.log("error:", error);

        }
    }

    const sortClicked = (e: any) => {
        try {
            let sortID = e.target.id;
            setsorthide(sortID);
            let sortval = sortID.split('@');
            setColumnName(sortval[0]);
            setColumnOrder(sortval[1].toLowerCase())
            loadGridPage();

        }
        catch (error: any) {
            ////console.log(error);
        }
    }

    const handleSearch = (e: any) => {
        try {
            setSearchValue(e.target.value)
        }
        catch (error: any) {
            ////console.log(error);
        }
    }

    const handleSearchIcon = (e: any) => {
        try {
            if (searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {
            ////console.log(error);
        }
    }

    const handleKeyDown = (e: any) => {
        try {

            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    settmpSearchValue(searchValue);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {
            ////console.log(error);
        }
    }

    const disablePopUp = () => {
        try {
            if (isSessionEpired === false) {
                document.getElementById("failedPopUp")?.setAttribute("class", "modal fade");
                document.getElementById("failedPopUp")?.setAttribute("style", "display:none");
                document.getElementById("failedPopUp")?.setAttribute("aria-hidden", "true");
                document.getElementById("failedPopUp")?.removeAttribute("aria-modal");
                document.getElementById("failedPopUp")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            }
            else {
                window.location.pathname = "/Login";

            }
        } catch (error) {
            ////console.log(error);

        }
    }

    const handleLoadMore = () => {
        try {
            let oindexPerPage = indexPerPage + 1;
            let ocountPerPage = countPerPage + 10;
            setIndexPerPage(oindexPerPage);
            setCoutPerPage(ocountPerPage);
            ////console.log(totalItemsCount)
        }
        catch (error: any) {

        }

    }


    return (

        <div className="row justify-content-center my-5 card-container mx-0 ms-0 px-3">
            <BackNavigation backNav={" Channel Completion Mode "} link={"/ChannelMigration/AU/TeamCompletion"} />
            <div className="overlay" id='loader' hidden={true}>
                <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="failedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{statusText}</p>
                                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" onClick={disablePopUp} data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='row'>
                {/* <div className="col-md-11"> */}
                <div
                    id="flush-collapseThree"
                    className="accordion-collapse px-0 collapse  show"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlush"
                >
                    <div className="accordion-body1">
                        <label className="font-14 font-bold mt-2 mb-3 ">Team Name : {state.teamName.length > 43 ?
                            <span data-tip={state.teamName} className="font-regular font-14">{state.teamName.substring(0, 43)}...
                                <ReactTooltip />
                            </span> :
                            <span data-tip="" className="font-regular font-14">{state.teamName}</span>}
                        </label>
                        {/* {state.teamName.length > 43 ? (state.teamName).substring(0, 43):state.teamName}</label> */}
                        <div className="row channel-comple-search mb-2">
                            <div className="col-md-12">
                                <div className="input-group box-shodow-filter">
                                    <input
                                        type="text"
                                        className="form-control search-box font-regular font-14"
                                        placeholder="Search"
                                        aria-describedby="button-addon1"
                                        value={searchValue} onKeyDown={handleKeyDown} onChange={handleSearch}
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        id="button-addon1"
                                        onClick={handleSearchIcon}
                                    >
                                        <img src="/Images/search.svg" alt="search-icon" onClick={handleSearchIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 float-start mt-2 table-responsive p-1">
                    {/* <div className='table-responsive'> */}
                    <table className="table table-border mb-4">
                        <thead className="tabel-head-bg">

                            <tr>
                                <th className="table-head font-14 font-bold">Channel Name
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="ChannelName@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelName@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="ChannelName@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelName@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 font-bold text-center">Status

                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="Status@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="Status@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 font-bold text-center">Channel Type
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="ChannelType@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelType@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="ChannelType@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelType@Desc" ? false : true} />
                                </th>

                            </tr>

                        </thead>
                        <tbody>
                            {gridBodyData()}
                        </tbody>
                    </table>
                    {/* </div> */}
                </div>
                <div className="col-md-12 float-start mt-3 mb-5 text-center">
                    {totalItemsCount >= countPerPage ?
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore}>Load More</button>
                        : ""}
                </div>
                {/* </div> */}
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>

    );
}


