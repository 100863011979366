import React, { useEffect, useState } from 'react';
import { gridLoad } from "../../Services/ProfileServices";
import constants from "../../Common/Constants";

import intlTelInput from 'intl-tel-input';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'

export default function AccountInformation() {
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any ;
    let adminToken: any;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }

    let formObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "", CountryCode: "" }
    let [formState, setFormState] = useState(formObj);
    let [alertMessage, setAlertMessage] = useState("")
    // const [clientID, setclientID] = useState(1);

    useEffect(() => {
        resetSessionTimer();
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.remove("container-bg-withoutFooter")
        let submitButton = document.getElementById("saveButton")
        submitButton?.setAttribute("disabled", "disabled")
        const input: any = document.querySelector("#phone");
        intlTelInput(input, {
            preferredCountries: ['us'],
            separateDialCode: true,
        });
        loadAccountInfo()
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])



    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                     let updatedobjClient: any = jwt(dePass);
                     SessionEpiredDateTime = updatedobjClient.CreatedAt;
                     setSessionEpiredDateTime(SessionEpiredDateTime);
                     clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong. Please reach AVAMIGRATRON team")
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setAlertMessage("Something went wrong. Please reach AVAMIGRATRON team")
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

  


    const loadAccountInfo = async () => {
        try {
            
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientProfileDetails`,
                    data: {
                        "clientID": clientID
                    },
                }
                )

                let data: any = await gridLoad(config);


                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong. Please reach AVAMIGRATRON team")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }

                else {
                    accountInfo(data.data.data)
                }

            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const handleSubmitClick = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let submitButton = document.getElementById("saveButton")
                submitButton?.setAttribute("disabled", "disabled")
                countrycode()
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/UpdateClientProfile`,
                    data: {
                        "clientID": clientID,
                        "userName": `${formState.UserName}`,
                        "companyName": `${formState.CompanyName}`,
                        "phoneNumber": `${formState.PhoneNumber}`,
                        "countryCode": `${formState.CountryCode}`,
                    },
                }
                )
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');

                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Failed to Update Account Information!");
                }

                else {
                    accountInfo(data.data.data)
                    //#region Update and Frame Client Session

                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion


                }
                let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            if (isSessionEpired === false) {
                //#region Hide PopUp
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                let submitButton = document.getElementById("saveButton")
                submitButton?.setAttribute("disabled", "disabled")
                if (type != "alert") {
                    window.location.reload();
                }
            }
            else {
                window.location.pathname = "/Login";
            }
            //#endregion
        } catch (error) {

        }
    }

    const accountInfo = async (accDetails: any) => {
        try {

            //#region new method
            let storedCountry = accDetails.CountryCode.split('~');
            let countrycode = storedCountry[0]
            var selectedCountry: any = document.getElementsByClassName("iti__selected-dial-code")
            selectedCountry[0].innerHTML = countrycode //CountryCode
            selectedCountry[0].previousSibling.className = storedCountry[1] //CountryFlag
            let title: any = document.getElementsByClassName('iti__selected-flag');
            // title[0].title = storedCountry[2] //CountryTitle
            let paddingclass = (countrycode.length == 2) ? "countryCodePadding1" : (countrycode.length == 3) ? "countryCodePadding2" : "countryCodePadding3"
            let addClass: any = document.getElementById("phone")
            addClass?.classList.add(paddingclass);
            //#endregion
            formState = { UserName: accDetails.Name, CompanyName: accDetails.CompanyName, EmailAddress: accDetails.MailID, PhoneNumber: accDetails.Phone, CountryCode: accDetails.CountryCode }
            setFormState(formState)

        }
        catch (error: any) {

        }
    }

    const countrycode = async () => {
        try {
            var selectedflag: any = document.getElementsByClassName("iti__flag")

            var selectedCountryCode: any = document.getElementsByClassName("iti__selected-dial-code")
            var title: any = document.getElementsByClassName('iti__selected-flag')

            formState.CountryCode = `${selectedCountryCode[0].innerText}~${selectedflag[0].className}`;
            // formState.CountryCode = selectedCountryCode[0].innerText;

            // let storedCountry = formState.CountryCode.split('~');
            // let countrycode = storedCountry[0]
            // var selectedCountry: any = document.getElementsByClassName("iti__selected-dial-code")
            // selectedCountry[0].innerHTML = countrycode //CountryCode
            // selectedCountry[0].previousSibling.className = storedCountry[1] //CountryFlag
            // let title1: any = document.getElementsByClassName('iti__selected-flag');
            // title1[0].title = storedCountry[2] //CountryTitle
            // let paddingclass = (countrycode.length == 2) ? "countryCodePadding1" : (countrycode.length == 3) ? "countryCodePadding2" : "countryCodePadding3"
            // let addClass: any = document.getElementById("phone")
            // addClass?.classList.add(paddingclass);
        }
        catch (error: any) {


        }
    }

    const OnChangeName =  (e: any) => {
        try {
            formState = { ...formState, [e.target.name]: e.target.value }
            setFormState(formState)
        }
        catch (error: any) {


        }
    }

    const handleCountryPadding = () => {
        try {
            let storedCountry = formState.CountryCode.split('~');
            let countrycode = storedCountry[0]
            let paddingclass = (countrycode.length == 2) ? "countryCodePadding1" : (countrycode.length == 3) ? "countryCodePadding2" : "countryCodePadding3"
            let addClass: any = document.getElementById("phone")
            addClass?.classList.remove(paddingclass);
        }
        catch (error: any) {


        }
    }

    const regexValidation = (e: any) => {
        try {
             OnChangeName(e)

            let submitButton = document.getElementById("saveButton");

            let userName = document.getElementById("userName")
            let companyName = document.getElementById("companyName")
            let email = document.getElementById("Email")
            let phone = document.getElementById("Phone")

            let emptyUserName = document.getElementById("emptyuserName");
            let emptyCompanyName = document.getElementById("emptycompanyName")
            let emptyEmail = document.getElementById("emptyEmail")
            let emptyPhone = document.getElementById("emptyPhone")

            if (e.target.id == "user-name") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "companyname") {
                if (formState.CompanyName == "") {
                    emptyCompanyName?.removeAttribute('hidden')
                    companyName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyCompanyName?.setAttribute('hidden', 'hidden')

                    let spaceRemoved = formState.CompanyName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[0-9a-zA-Z ]{1,80}$/.test(trimmed)

                    if (result == false) {
                        companyName?.removeAttribute('hidden')
                    }
                    else {
                        companyName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "email") {
                if (formState.EmailAddress == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)

                    if (result == false && formState.EmailAddress.length <= 320) {
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "phone") {
                if (formState.PhoneNumber == "") {
                    emptyPhone?.removeAttribute('hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyPhone?.setAttribute('hidden', 'hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    let result = /^[0-9]{5,15}$/.test(formState.PhoneNumber)
                    if (result == false) {
                        phone?.removeAttribute('hidden')
                    }
                    else {
                        phone?.setAttribute('hidden', 'hidden')

                    }
                }
            }
            if (formState.UserName != "" && formState.CompanyName != "" && formState.EmailAddress != "" && formState.EmailAddress.length <= 320 && formState.PhoneNumber != "") {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let removeSpace = formState.CompanyName.replace(/\s\s+/g, ' ')
                let trim = removeSpace.trim().replaceAll(" +", " ");
                let Check2 = /^[0-9a-zA-Z ]{1,80}$/.test(trim)
                let Check3 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)
                let Check4 = /^[0-9]{5,15}$/.test(formState.PhoneNumber)

                if (Check1 && Check2 && Check3 && Check4 == true) {
                    submitButton?.removeAttribute('disabled');
                }
                else {
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                submitButton?.setAttribute('disabled', 'disabled');
            }
        }
        catch (error: any) {

        }
    }

    return (
        <div id="account-information" className="tab-pane active pt-3 pb-5">

            <div className="col-md-12 col-sm-12 col-lg-12 pu-border-none">
                <div className="row">
                    <div className="col-md-12 my-2 col-sm-12 col-lg-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="user-name">User Name<span className="required">*</span></label>
                        <input type="text" className="form-control custom-form" id="user-name" name="UserName" value={formState.UserName} onChange={(e) => regexValidation(e)} />
                        <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                    </div>
                    <div className="col-md-12 mt-2 mb-3 col-sm-12 col-lg-4">
                        <label className="font-16 font-semibold form-label" htmlFor="companyname">Company Name<span className="required">*</span></label>
                        <input type="text" className="form-control custom-form" id="companyname" name="CompanyName" value={formState.CompanyName} onChange={(e) => regexValidation(e)} />
                        <label id="companyName" className="required" hidden={true} >Please enter Valid Company Name</label>
                        <label id="emptycompanyName" className="required" hidden={true} >Company Name cannot be empty</label>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 my-2 col-sm-12 col-lg-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="email">Email Address<span className="required">*</span></label>
                        <input type="email" className="form-control custom-form" id="email" name="EmailAddress" disabled value={formState.EmailAddress} />
                    </div>
                    <div className="col-md-12 my-2 col-sm-12 col-lg-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="phone">Phone Number<span className="required">*</span></label>
                        <div id='countryFlag' onClick={(e) => handleCountryPadding()}>
                            <input type="text" className="form-control custom-form" id="phone" name="PhoneNumber" value={formState.PhoneNumber} onChange={(e) => regexValidation(e)} />
                        </div>
                        <label id="Phone" className="required" hidden={true}> Please enter Valid Phone number </label>
                        <label id="emptyPhone" className="required" hidden={true}>Phone number cannot be empty </label>
                    </div>
                </div>
            </div>
            {/* <!-- footer starts --> */}
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                <div className=" mx-3 d-flex justify-content-end">
                    <button type="button" id="saveButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={(e) => handleSubmitClick()} >Submit</button>
                </div>
            </footer>
            {/* <!-- footer ends -->  */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4"> Account Information Updated Successfully!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>

    );
}