import { APIService } from '../Client/Client';
export const GridLoad = (loadGridPostData: any) => {

    
    return APIService(loadGridPostData.url, { method: 'POST', body: loadGridPostData.data }, loadGridPostData.token);

}

export const DeleteLoadData = (deleteData: any) => {
    
    return APIService(deleteData.url, { method: 'POST', body: deleteData.data }, deleteData.token)
}

export const AddLoadData = (addData: any) => {
    return APIService(addData.url, { method: 'POST', body: addData.data }, addData.token)
}


export const CompleteTeamChannel = (Data: any) => {

    
    return APIService(Data.url, { method: 'POST', body: Data.data }, Data.token);

}

export const saveMapping = (MappingData: any) => {
    
    return APIService(MappingData.url, { method: 'POST', body: MappingData.data }, MappingData.token)
}

export const SubmitValidation = (SourceTargetDetails: any) => {
    return APIService(SourceTargetDetails.url, { method: 'POST', body: SourceTargetDetails.data }, SourceTargetDetails.token)
}
export const CreateTeamChannel = (TargetDetails: any) => {
    try {
        return APIService(TargetDetails.url, { method: 'POST', body: TargetDetails.data }, TargetDetails.token)
    } catch (error) {
        throw(error)
        
    }
    
}