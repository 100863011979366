import { stat } from "fs";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Insert_Details } from "../../Services/ResgistrationServices";
import { useHistory } from 'react-router-dom';
import constants from "../../Common/Constants";


export default function VerifyMail() {
    let dataObj: any = [];
    const history = useHistory()

    const objState = useLocation().state as any;
    const [state, setState] = useState(dataObj);
    const bool = (objState == true) ? true : false;
    let [alertMessage, setAlertMessage] = useState("")
    let [isForgotPasswordPage, setisForgotPasswordPage] = useState(bool)
    const [failedPopUp, setFailedPopUp] = useState("");
    let baseURL = `${process.env.REACT_APP_REGISTRATION_BASEURL}`;
    let baseURLForgotpassword = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}`;


    useEffect(() => {
        let submitButton = document.getElementById("UpdatePassword");
        submitButton?.setAttribute('disabled', 'true');

    }, [])

    const onTextBoxValueChange = (e: any) => {
        try {
            let ostate = state;
            ostate[e.target.name] = e.target.value;
            setState({ ...state, [e.target.name]: e.target.value });
            onBlurValidation(e);
        } catch (error) {

        }
    }

    const onBlurValidation = (e: any) => {
        try {

            // let button = document.getElementById("targetButton");
            let invalidTargetEmailElement = document.getElementById("invalidTargetEmail");
            let invalidTargetPasswordElement = document.getElementById("invalidTargetPassword");
            let emptyTargetEmailElement = document.getElementById("emptyTargetEmail");
            let emptyTargetPasswordElement = document.getElementById("emptyTargetPassword");


            if (e.target.name == "targetEmail") {
                if (state.targetEmail == "") {
                    invalidTargetEmailElement?.setAttribute("hidden", "true");
                    emptyTargetEmailElement?.removeAttribute("hidden");
                }
                else if (!((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))) {
                    emptyTargetEmailElement?.setAttribute("hidden", "true");
                    invalidTargetEmailElement?.removeAttribute("hidden");
                }
                else {
                    invalidTargetEmailElement?.setAttribute("hidden", "true");
                    emptyTargetEmailElement?.setAttribute("hidden", "true");
                }
            }




            if (state.targetEmail != undefined && state.targetEmail != "" && state.targetEmail.length <= 320) {
                let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
                if (Check == true) {
                    let submitButton = document.getElementById("UpdatePassword");
                    submitButton?.removeAttribute('disabled');
                }
                else {
                    let submitButton = document.getElementById("UpdatePassword");
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                let submitButton = document.getElementById("UpdatePassword");
                submitButton?.setAttribute("disabled", "disabled");
            }
        }
        catch (error: any) {
            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }

    const onForgotPasswordClick = async () => {
        try {

            if (state.targetEmail != undefined && state.targetEmail != "") {

                let loaderElement = document.getElementById("targetLoader")
                loaderElement?.removeAttribute("hidden")

                // let faderElement = document.getElementById("targetFadeDiv")
                // faderElement?.removeAttribute("hidden");

                // let button = document.getElementById("targetButton");
                // button?.setAttribute("disabled", "disabled");

                let config = {
                    method: "POST",
                    url: `${baseURLForgotpassword}/UserAuthentication/ForgotPassword`,
                    data: {
                        "UserEmail": `${state.targetEmail}`
                    }
                }

                let data: any = await Insert_Details(config);

                if (data.data.statusText == "ok") {

                    document.getElementById("success")?.removeAttribute("aria-hidden");
                    document.getElementById("success")?.setAttribute("aria-modal", "true");
                    document.getElementById("success")?.setAttribute("role", "dialog");
                    document.getElementById("success")?.setAttribute("class", "modal fade show");
                    document.getElementById("success")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");

                    let loaderElement = document.getElementById("targetLoader")
                    loaderElement?.setAttribute("hidden", "true")

                }
                else if (data.data.data == "Kindly use Microsoft to Login") {
                    let loaderElement = document.getElementById("targetLoader")
                    loaderElement?.setAttribute("hidden", "true")
                    if (data.data.data == "Kindly use Microsoft to Login") {
                        setFailedPopUp(data.data.data);
                    }
                    else if (data.data.data == "Your request has reached its limit today. Please try again tomorrow or reach us through Migration.support@avasoft.com.") {
                        setFailedPopUp(data.data.data);
                    }
                    else {
                        setFailedPopUp("Please provide Registered Email Address");
                    }

                    //#region Show PopUp
                    document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");

                    //#endregion
                }
                else {

                    let loaderElement = document.getElementById("targetLoader")
                    loaderElement?.setAttribute("hidden", "true")
                    if (data.data.data == "Your account is inactive. Please login to an active account or contact AVAMIGRATRON Team.") {
                        setFailedPopUp(data.data.data);
                    }
                    else if (data.data.data == "Your request has reached its limit today. Please try again tomorrow or reach us through Migration.support@avasoft.com.") {
                        setFailedPopUp(data.data.data);
                    }
                    else {
                        setFailedPopUp("Please provide Registered Email Address");
                    }

                    //#region Show PopUp
                    document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");

                    //#endregion

                }
            }
            else {

                setFailedPopUp("")
                let loaderElement = document.getElementById("targetLoader")
                loaderElement?.setAttribute("hidden", "true")
                setFailedPopUp("Email Address cannot be empty");

                //#region Show PopUp
                document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
                document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
                document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
                document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
                document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }

        } catch (error: any) {

            let loaderElement = document.getElementById("targetLoader")
            loaderElement?.setAttribute("hidden", "true")

            let y = document.getElementById("UpdatePassword")
            y?.removeAttribute("disabled")

            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion

        }
    }

    const failedPopUpOkClick = () => {
        try {
            //#region Hide PopUp
            let submitButton = document.getElementById("UpdatePassword");
            submitButton?.setAttribute("disabled", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:none");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-hidden", "true");
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-modal");
            document.getElementById("targetFailedPopUp")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "false");
            //#endregion
        } catch (error) {
        }
    }

    const SuccessPopUpOkClick = () => {
        try {
            //#region Hide PopUp
            let loaderElement = document.getElementById("targetLoader")
            loaderElement?.setAttribute("hidden", "true")
            document.getElementById("success")?.setAttribute("class", "modal fade");
            document.getElementById("success")?.setAttribute("style", "display:none");
            document.getElementById("success")?.setAttribute("aria-hidden", "true");
            document.getElementById("success")?.removeAttribute("aria-modal");
            document.getElementById("success")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "false");
            //#endregion
            let targetEmail = {
                targetEmail: state.targetEmail
            }

            history.push({
                pathname: "/Login",
                state: targetEmail
            })

        } catch (error) {
        }
    }

    const onPasswordKeyDown = async (e: any) => {
        try {
            if (e.keyCode === 13) {
                var btnPassword = document.getElementById("UpdatePassword") as HTMLButtonElement;
                let mailRegex = ((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))
                let pswdRegex = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(state.targetPassword))
                let btnPass
                if (mailRegex == true && !btnPassword.disabled) {
                    await onForgotPasswordClick();
                }
            }
        } catch (error) {
        }
    }
    return (
        <div className="row justify-content-center  width100 ms-0">
            {/* <!-- ava logo starts here --> */}
            <div className="col-lg-12 col-md-12 col-sm-12  my-3">
                <a href="/Login" className='anchorAsPointer'>
                    <img src='/Images/brand_logo.svg' className="ava-logo" alt="logo" />
                </a>
            </div>
            {/* <!-- ava logo ends here --> */}

            {/* <!-- verify email background img starts here --> */}
            <div className="col-12 Verify-email-bg px-0">
                <img src='/Images/verify_email_bg.svg' className="Verify-email" alt="logo" />
            </div>
            {/* <!-- verify email background img ends here --> */}
            <div>
                <div className="col-12  mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-5 text-center">
                            <h5 className="font-24 font-bold">
                                Forgot Password?
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="col-12  mt-3">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-4 text-center">
                            <label className="font-18 font-regular verify-email-font2">
                                Please enter the registered email address. We'll send you a new password.
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-3">
                            <label className="font-16 font-semibold  pt-3 pb-2" htmlFor="Email-AD">Email Address</label>
                            <input type="text" className="form-control registration_form_control" id="targetEmail"
                                placeholder="Enter the Email Address" name="targetEmail" value={state.targetEmail} onKeyDown={onPasswordKeyDown} onChange={onTextBoxValueChange} />
                            <label className="float-start font-semibold pb-3 font-14 required" id="invalidTargetEmail" hidden>Invalid Email Address provided</label>
                            <label className="float-start font-semibold pb-3 font-14 required" id="emptyTargetEmail" hidden>Email Address cannot be empty</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 ">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-3">
                            <button className="btn  float-end btn btn-primary-theme loginButton registration_form_button my-4" id="UpdatePassword" onClick={onForgotPasswordClick}>SEND NEW PASSWORD</button>
                        </div>
                    </div>
                </div>
                <div className="col-12  mt-2 mb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-3 text-center  mb-5">
                            <a href="/login" className="resend-text font-semibold">Back to Login</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Success Pop-up--> */}
            {/* <!--Success Pop-up starts here--> */}
            <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/success.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Email sent successfully </h5>
                                <p className="success-text w-100 text-center mb-4">
                                    We have sent a new password to your email,
                                    <span className="font-semibold"> {state.targetEmail}</span>
                                </p>
                                <button
                                    type="button"
                                    className="btn common-btn-class btn-primary-theme btn-ok mb-2 text-center"
                                    data-bs-dismiss="modal" onClick={SuccessPopUpOkClick}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up ends here--> */}
            {/* <!--Loader Screen--> */}
            <div className="overlay" id='targetLoader' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
            {/* <!--Loader Screen--> */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="targetFailedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    )
}