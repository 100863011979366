import React, { useEffect, useState } from "react";
import { GridLoad } from "../../Services/ChannelMigrationServices";
import { DeleteLoadData } from "../../Services/ChannelMigrationServices";
import { AddLoadData } from "../../Services/ChannelMigrationServices";
import CryptoJS from "crypto-js";
import jwt from "jwt-decode";
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';



export default function ServiceAccounts() {
  let GridLoadarray: any[] = [];
  let [statusText, setStatus] = useState("");

  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  let [clientID, setclientID] = useState(0);
  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("");
  const [sorthide, setsorthide] = useState("");
  const [indexPerPage, setIndexPerPage] = useState(2);
  let [mailID, setmailID] = useState("");
  let [password, setpassword] = useState("");
  const [enableBtn, setenableBtn] = useState(false);
  const [MSPasswordIDEyeOpen, setMSPasswordIDEyeOpen] = useState(false);
  const [countPerPage, setCoutPerPage] = useState(10);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let [msClientID, setmsClientID] = useState("");
  let InActiveTimeout: NodeJS.Timeout;
  let isPoupClosedAfterSuccess = false;
  let isPopupClosedByUser = false; 
   // let tempMsTeamClientID = "";

  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientIDValdidate: any;
  let clientToken: any;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientIDValdidate = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;
  }

  useEffect(() => {
    resetSessionTimer();
    deleteChild();
    clientID = clientIDValdidate;
    setclientID(clientID);
    loadGridPage();
    // setmsClientID(tempMsTeamClientID);
    // //console.log(msClientID);
    //console.log(clientID);
    //#region Event handlers Keypad click and mouse click
    window.addEventListener("keydown", resetSessionTimer);
    window.addEventListener("click", resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener("click", resetSessionTimer);
      window.removeEventListener("keydown", resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage]);

  useEffect(() => {
    const responseRedirect = async (event: any) => {
      try {
        let x = document.getElementById("loader");
        x?.removeAttribute("hidden");
        isPoupClosedAfterSuccess = true;
        if (event.data.authorization ==
            `${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams/Redirect` &&
          event.origin == `${constants.URL.REACT_APP_MAIN_URL_POPUP}`
        ) {
          let a = await validateServiceAccount(event);
        }
      } catch (error) {
        
      }
    };
    window.addEventListener("message", responseRedirect);
    return () => window.removeEventListener("message", responseRedirect);
  }, []);
  //#region Session Expiry POP-UP enable and Session Clear method


  const enableSessionExpiryPopup = () => {
    try {
      statusText =
        "Your session has expired. Please log in again to continue using the application.";
      setStatus(statusText);

      //#region Show PopUp
      document
        .getElementById("sourceSuccessPopUp")
        ?.removeAttribute("aria-hidden");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("aria-modal", "true");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("role", "dialog");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("class", "modal fade show");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {}
  };

  //#endregion

  const validateServiceAccount = async (event: { data: any }) => {
    try {
      isPoupClosedAfterSuccess = true;
       let x = document.getElementById("loader");
       document.getElementById("loader")?.removeAttribute("hidden");
      let refreshToken = "";
      let emailField = document.getElementById("email") as HTMLInputElement;
      let emailIDofUser = emailField.value;
      let config = {
        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigrationWebApp/FetchUserToken`,
        token: clientToken,
        data: {
          userEmailID: emailIDofUser,
          appClientID: msClientID,
          authCode: event.data.authorizationCode,
          type: "teams",
          isReponseRequired: true,
          clientID: clientID,
        },
      };
      document.getElementById("loader")?.removeAttribute("hidden");
      let response = await GridLoad(config);
      document.getElementById("loader")?.removeAttribute("hidden");
      if(response.data.statusCode == 200){

      refreshToken = response.data.data;
      let configForSA = {
        token: clientToken,
        method: "POST",
        url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ServiceAccount/addServiceAccounts`,
        data: {
          clientID: clientID,
          MailID: emailIDofUser,
          refreshToken: `${await encryptBL(refreshToken, r_n_Key)}`,
        },
      };

      let data= await GridLoad(configForSA);

      if (data.data.statusCode == 200 && gridData.length < 10) {
        loadGridPage();
        isPoupClosedAfterSuccess = true;
      } else if (data.data.data == "Invalid authentication token provided") {
        x?.setAttribute("hidden", "true");
        enableSessionExpiryPopup();
      } else if (data.data.statusCode != 200) {
        x?.setAttribute("hidden", "true");
        clear();
        createDynamicListItem(
          "Service account addition failed due to any of the below mentioned reasons",
          [
            "Invalid user credentials provided",
            "Invalid user role provided(Global/Teams Administrator)",
            "Service account already exists",
          ]
        );
        document
          .getElementById("sourceSuccessPopUp")
          ?.removeAttribute("aria-hidden");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("aria-modal", "true");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("role", "dialog");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("class", "modal fade show");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      } else {
        localStorage.clear();
      }
    }
    else{
      statusText = "Failed to authenticate the service account";
      setStatus(statusText);

      //#region Show PopUp
      document
        .getElementById("sourceSuccessPopUp")
        ?.removeAttribute("aria-hidden");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("aria-modal", "true");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("role", "dialog");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("class", "modal fade show");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      
    }
    } catch (error) {
      // //console.log(error);
      // alert(error);
    }
  };

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {
      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken,
        };

        let data: any = await GridLoad(config);
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(
            data.data.data.clientToken,
            r_n_Key
          );
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          ////console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          ////console.log(SessionEpiredDateTime);
          //#endregion
        } else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        } else {
          enableSessionExpiryPopup();
        }
      } else {
        localStorage.clear();
      }
    } catch (error) {
      statusText =
        "Something went wrong! Please try again or reach AVAMIGRATRON Support";
      setStatus(statusText);

      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("class", "modal fade");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("style", "display:none");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("aria-hidden", "true");
      document
        .getElementById("sourceSuccessPopUp")
        ?.removeAttribute("aria-modal");
      document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    }
  };
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      ////console.log("eventhit")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(
        new Date(SessionEpiredDateTime).getTime() -
          new Date(currentDateTime).getTime()
      );
      let minutes = Math.floor(
        (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    } catch (ex: any) {
      statusText =
        "Something went wrong! Please try again or reach AVAMIGRATRON Support";
      setStatus(statusText);

      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("class", "modal fade");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("style", "display:none");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("aria-hidden", "true");
      document
        .getElementById("sourceSuccessPopUp")
        ?.removeAttribute("aria-modal");
      document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    }
  };
  //#endregion

  const loadGridPage = async () => {
    try {
      clear();
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let x = document.getElementById("loader");
        x?.removeAttribute("hidden");
        let config = {
          token: clientToken,
          method: "POST",
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ServiceAccount/getServiceAccounts`,
          data: {
            clientID: clientID,
            offsetValue: indexPerPage,
            columnName: `${columnName}`,
            columnType: `${columnOrder}`,
          },
        };

        let data: any = await GridLoad(config);

        if (data.data.statusCode == 200) {
          setGridData(data.data.data[0]);
          // tempMsTeamClientID = data.data.data[0].MSTeamsAppClientID;
          msClientID = data.data.data[2][0].MSTeamsAppClientID;
          setmsClientID(msClientID);
          x?.setAttribute("hidden", "true");

          //console.log(msClientID);
          //console.log(clientID);
        } else if (data.data.data == "Invalid authentication token provided") {
          clear();
          x?.setAttribute("hidden", "true");
          enableSessionExpiryPopup();
        } else if (data.data.statusCode != 200) {
          clear();
          setStatus(
            "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          );
          enableAlertPopUp();
          x?.setAttribute("hidden", "true");
        }
      } else {
        localStorage.clear();
      }
    } catch (error: any) {
      clear();
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const deleteChild = () => {
    try {
      var e: any = document.getElementById("dynamicData");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    } catch (error) {
      clear();
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const enableAlertPopUp = () => {
    try {
      document
        .getElementById("sourceSuccessPopUp")
        ?.removeAttribute("aria-hidden");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("aria-modal", "true");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("role", "dialog");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("class", "modal fade show");
      document
        .getElementById("sourceSuccessPopUp")
        ?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    } catch (error) {
      clear();
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const gridBodyData = () => {
    try {
      return gridData.map((obj, index) => {
        let rowClass =
          index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
        return (
          <tr className={rowClass}>
            <td className="py-3 tabel-data-font-level1">
              {obj.TargetUserMailID}
            </td>
            {/* <td className="py-3 tabel-data-font-level1">********</td> */}
            <td
              className={
                obj.PrimaryServiceAccount == null
                  ? "py-3 tabel-data-font-level1 anchorAsPointer text-center"
                  : "py-3 tabel-data-font-level1 text-center"
              }
            >
              {/* <img className="icon" src={deleteIcon} alt="DeleteIcon" id="DeleteIcon" onClick={e => sortClicked(e)} /> */}
              {obj.PrimaryServiceAccount == null ? (
                <img
                  className="icons"
                  src="/Images/delete-icon.svg"
                  id={"delete" + obj.TargetUserMailID}
                  onClick={(e) => deleteClicked(e)}
                />
              ) : (
                <div className="disableDiv">
                  {" "}
                  <img
                    className="icons anchorAsPointer"
                    src="/Images/delete_icon.svg"
                  />{" "}
                </div>
              )}
            </td>
          </tr>
        );
      });
    } catch (error: any) {
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const deleteClicked = async (e: any) => {
    try {
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let id = e.target.id.replace("delete", "");
        let config = {
          token: clientToken,
          method: "POST",
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ServiceAccount/deleteServiceAccounts`,
          data: {
            clientID: clientID,
            MailID: `${id}`,
          },
        };
        let x = document.getElementById("loader");
        x?.removeAttribute("hidden");
        let data: any = await DeleteLoadData(config);
        if (data.data.statusCode == 200) {
          loadGridPage();
        } else if (data.data.data == "Invalid authentication token provided") {
          clear();
          x?.setAttribute("hidden", "true");
          enableSessionExpiryPopup();
        } else if (data.data.statusCode != 200) {
          clear();
          setStatus(
            "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          );
          enableAlertPopUp();
          x?.setAttribute("hidden", "true");
        }
      } else {
        localStorage.clear();
      }
    } catch (error: any) {
      clear();
      document.getElementById("loader")?.setAttribute("hidden", "true");
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
    }
  };

  let encryptBL = async (content: any, secretKey: any) => {
    try {
      var encryptedText = CryptoJS.AES.encrypt(content, secretKey).toString();
      return encryptedText;
    } catch (error) {
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };
  const addClicked = async () => {
    try {
      let x = document.getElementById("loader");
      x?.removeAttribute("hidden");
      if (gridData.length <= 0) {
        setStatus("Please Connect the MS Teams Tenant!");
        enableAlertPopUp();
      } else {
        Validation();
        if (gridData.length >= 10) {
          clear();
          setStatus("Only none Service accounts can be added");
          document.getElementById("addbtn")?.setAttribute("disabled", "true");
          document
            .getElementById("sourceSuccessPopUp")
            ?.removeAttribute("aria-hidden");
          document
            .getElementById("sourceSuccessPopUp")
            ?.setAttribute("aria-modal", "true");
          document
            .getElementById("sourceSuccessPopUp")
            ?.setAttribute("role", "dialog");
          document
            .getElementById("sourceSuccessPopUp")
            ?.setAttribute("class", "modal fade show");
          document
            .getElementById("sourceSuccessPopUp")
            ?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        } else {
          const microsoftLoginUrlTemmplate =
            constants.URL.REACT_APP_MICROSOFT_AUTH_URL;
          let microsoftLoginUrl = microsoftLoginUrlTemmplate
            ? microsoftLoginUrlTemmplate
                .replace("{0}", encodeURIComponent(msClientID))
                .replace(
                  "{1}",
                  encodeURIComponent(
                    `${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams/Redirect`
                  )
                )
            : "";

          const width = 500;
          const height = 600;
          const left = (window.innerWidth - width) / 2;
          const top = (window.innerHeight - height) / 2;

          const newWindow = window.open(
            microsoftLoginUrl,
            "_blank",
            `width=${width},height=${height},left=${left},top=${top}`
          );
          if (!newWindow) {
            setStatus(
              "Popup has been blocked, enable it to authetnicate your account"
            );
            enableAlertPopUp();
          } else {
            let x = document.getElementById("loader");
            x?.removeAttribute("hidden");
            newWindow?.postMessage(
              "message",
              `${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams`
            );
            const checkWindowClosed = setInterval(() => {
              if (newWindow.closed && !isPoupClosedAfterSuccess) {
                clearInterval(checkWindowClosed);
                document
                  .getElementById("loader")
                  ?.setAttribute("hidden", "true");
              }

            }, 200); // Check every second

          
          }
        }
      }
    } catch (error: any) {
      clear();
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  let clear = () => {
    try {
      const clearappID: any = document.getElementById("email");
      // const clearAppSecret: any = document.getElementById("targetPassword");
      clearappID.value = "";
      // clearAppSecret.value = "";
      setenableBtn(false);
    } catch (error) {
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const createDynamicListItem = async (popupText: any, arrReasons: any) => {
    try {
      setStatus(popupText);
      let element = document.getElementById("dynamicData");
      let ulTag = document.createElement("ol");
      ulTag.classList.add("listedAlertPopupBG");
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      });
      element?.appendChild(ulTag);
    } catch (error) {
      throw error;
    }
  };

  let Validation = () => {
    try {
      let result =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mailID
        );
      if (mailID == "" || result == false) {
        let x = document.getElementById("Email");
        x?.removeAttribute("hidden");
      } else if (mailID != "") {
        setenableBtn(true);
        //////console.log(enableBtn);
      }
    } catch (error) {
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };
  let onChangeValue = (e: any) => {
    try {
      mailID = e.target.value;
      setmailID(mailID);
    } catch (error) {}
  };
  const regexValidation = (e: any) => {
    try {
      onChangeValue(e);
      let result =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mailID
        );

      if (mailID.length == 0 || mailID == "") {
        document
          .getElementById("EmailInvalid")
          ?.setAttribute("hidden", "hidden");
        let x = document.getElementById("EmailEmpty");
        x?.removeAttribute("hidden");
        setenableBtn(false);
      } else if (result == false || mailID.length > 320) {
        document.getElementById("EmailEmpty")?.setAttribute("hidden", "hidden");
        let x = document.getElementById("EmailInvalid");
        x?.removeAttribute("hidden");
        setenableBtn(false);
      } else {
        document
          .getElementById("EmailInvalid")
          ?.setAttribute("hidden", "hidden");
        document.getElementById("EmailEmpty")?.setAttribute("hidden", "hidden");

        Validation();
      }
    } catch (error) {
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const handlePassword = (e: any) => {
    try {
      password = e.target.value;
      setpassword(password);
      if (password == "") {
        document
          .getElementById("PasswordInvalid")
          ?.setAttribute("hidden", "hidden");
        let x = document.getElementById("PasswordEmpty");
        x?.removeAttribute("hidden");
        setenableBtn(false);
        setMSPasswordIDEyeOpen(false);
      } else if (password.length < 8 || password.length > 256) {
        document
          .getElementById("PasswordEmpty")
          ?.setAttribute("hidden", "hidden");
        let x = document.getElementById("PasswordInvalid");
        x?.removeAttribute("hidden");
        setenableBtn(false);
        setMSPasswordIDEyeOpen(false);
      } else {
        let x = document.getElementById("PasswordEmpty");
        x?.setAttribute("hidden", "hidden");
        let y = document.getElementById("PasswordInvalid");
        y?.setAttribute("hidden", "hidden");
        setenableBtn(true);
        Validation();
      }
    } catch (error) {
      setStatus(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      );
      enableAlertPopUp();
      //////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true");
    }
  };

  const alertPopUpOkClick = () => {
    try {
      //#region Hide PopUp
      if (isSessionEpired === false) {
        deleteChild();
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("class", "modal fade");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("style", "display:none");
        document
          .getElementById("sourceSuccessPopUp")
          ?.setAttribute("aria-hidden", "true");
        document
          .getElementById("sourceSuccessPopUp")
          ?.removeAttribute("aria-modal");
        document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        //#endregion
      } else {
        window.location.pathname = "/Login";
      }
    } catch (error) {
      //////console.log("Issue Faced");
    }
  };

  return (
    <div className="row justify-content-center mt-5 card-container mx-0 ms-0 px-3">
      <div className="overlay" id="loader" hidden={true}>
        <div className=" Loader-Content-position ">
          <div
            className="spinner-border loaderImg Loader-Img-position text-primary align-center"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 col-12 col-lg-9 col-xl-8 py-5">
        <div className="row mb-2">
          <div className="col-md-12 mb-3">
            <label
              className="user-head-font au-heading-font font-bold"
              data-bs-toggle="modal"
            >
              <a
                className="me-2 anchorAsPointer"
                href="/ChannelMigration/SA/ConversationMigration"
                title="Back"
              >
                <img src="/Images/backarrow.svg" alt="Back arrow" />
              </a>
              Add Service Accounts
            </label>
            <div className="w-100 float-start">
              <label className=" inventory-details-font margin-top-one-rem font-14 font-regular me-2">
                A maximum of nine service accounts can be added to perform
                parallel migration
              </label>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-12 float-start mt-2">
          <div className="table-responsive px-1">
            <table className="table table-border">
              <thead className="tabel-head-bg">
                <tr>
                  <th className="table-head font-14 font-bold">
                    MS Teams User Mail Address
                  </th>
                  {/* <th className="table-head font-14 font-bold">MS Teams User Password</th> */}
                  <th className="table-head font-14 font-bold text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className="tabel-body-bg"
                  hidden={gridData.length >= 9 ? true : false}
                >
                  <td className=" py-3 tabel-data-font-level1 vertical-align">
                    <input
                      type="text"
                      placeholder="Enter Mail ID"
                      id="email"
                      className="form-control  font-14"
                      onChange={(e) => regexValidation(e)}
                    />
                    <label id="EmailEmpty" className="color-red " hidden={true}>
                      Email Address cannot be empty
                    </label>
                    <label
                      id="EmailInvalid"
                      className="color-red "
                      hidden={true}
                    >
                      Invalid Email Address provided
                    </label>
                  </td>
                  {/* <td className=" py-3 tabel-data-font-level1 vertical-align">

                    <div>
                      <div className="eyeiconDiv input-group box-shodow-filter">
                        <input type={MSPasswordIDEyeOpen ? "text" : "password"} className="form-control border-right-none font-14" id="targetPassword"
                          placeholder="Enter Password" name="targetPassword" onChange={(e) => handlePassword(e)} />
                        <button className="btn btn-outline-secondary-cm">
                          <img src={MSPasswordIDEyeOpen == false && password != undefined && password != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"} alt="eye-open-icon" className={(MSPasswordIDEyeOpen == false && password != undefined && password != "") ? "eyeopeniconImg-cm" : "eyecloseiconImg-cm"} onClick={() => (password != undefined && password != "" ? setMSPasswordIDEyeOpen(MSPasswordIDEyeOpen ? false : true) : null)} />
                        </button>
                      </div>
                    </div>
                    <label id="PasswordEmpty" className='color-red float-start' hidden={true}>Password cannot be empty</label>
                    <label id="PasswordInvalid" className='color-red float-start' hidden={true}>Invalid Password Provided</label>

                  </td> */}
                  <td
                    className={
                      enableBtn == false
                        ? " py-3 tabel-data-font-level1 vertical-align text-center "
                        : " py-3 tabel-data-font-level1 vertical-align anchorAsPointer text-center"
                    }
                  >
                    {enableBtn == false ? (
                      <div className="disableDiv">
                        {" "}
                        <img
                          className="icons"
                          src="/Images/Add-icon.svg"
                        />{" "}
                      </div>
                    ) : (
                      <img
                        className="anchorAsPointer"
                        id="addbtn"
                        src="/Images/Add-icon.svg"
                        alt="add"
                        data-bs-toggle="modal"
                        data-bs-target="#success"
                        onClick={addClicked}
                      />
                    )}

                    {/* <img className={enableBtn == false ? 'icon-disable' : 'anchorAsPointer'} id="addbtn" src="/Images/Add-icon.svg" alt="add" data-bs-toggle="modal" data-bs-target="#success" onClick={addClicked} /></a> */}
                  </td>
                </tr>
                {gridBodyData()}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="sourceSuccessPopUp"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img
                src="/Images/Alert.svg"
                className="success-icon"
                alt="success"
              />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">
                  {statusText}
                </p>
                <div id="dynamicData"></div>
                <button
                  type="button"
                  className="btn-primary-theme common-btn-class btn btn-ok  text-center"
                  data-bs-dismiss="modal"
                  onClick={alertPopUpOkClick}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>
  );
}
