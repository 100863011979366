import { useEffect, useState } from "react";
import { sendRequestToNodeServicesApiTS, sendRequestToUpdateUsersList } from '../../Services/SettingsServices';
import objCryptoJS from "crypto-js";
import ReactTooltip from "react-tooltip";
import jwt from 'jwt-decode';
import { PublicClientApplication, Configuration, BrowserCacheLocation, AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { json } from "stream/consumers";
import { parse } from "path";
import constants from "../../Common/Constants";


export function Target(): JSX.Element {
  let dataObj: any = [];
  const [state, setState] = useState(dataObj);
  const [isServiceAdmin, setisServiceAdmin] = useState<any>();
  const [failedPopUp, setFailedPopUp] = useState("");
  const [isMSTeamsSecretIDEyeOpen, setIsMSTeamsSecretIDEyeOpen] = useState(false);
  const [isMSTeamsPasswordEyeOpen, setIsMSTeamsPasswordEyeOpen] = useState(false);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  let [loaderContent, setloaderContent] = useState("");
  const [isSessionEpired, setisSessionEpired] = useState(false);
  const [targetEmailId, settargetEmailId] = useState("");
  let InActiveTimeout: NodeJS.Timeout;
  const reactNode_en_K: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let local: any = localStorage.getItem("cid_t");
  let adminLocal: any = localStorage.getItem("aid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let objClient: any;
  let adminObjClient: any;
  let clientID: any;
  let clientToken: any;
  let adminToken: any;
  let isPoupClosedAfterSuccess = false;
  let isUserBasedLicense = false;

  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    var decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;
    isUserBasedLicense = objClient.isUserBasedLicense;

    // Admin Token Decryption 
      if(adminLocal != undefined){
          let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
          let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
          adminObjClient = jwt(adminDecryptedValue);
          adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
      }

  }

  useEffect(() => {

    // Validate the Service Admin
    if(adminObjClient?.Role == "Service Admin"){
        setisServiceAdmin(true)
    }

    let loaderElement = document.getElementById("teamsLoader")
    loaderElement?.removeAttribute("hidden")
    resetSessionTimer();
    LoadData().then(() => {
      // document.getElementById("targetButton")?.setAttribute("Disabled", "Disabled");
      loaderElement?.setAttribute("hidden", "true")
    });
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion

    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };

  }, [])

    //#region Session Expiry POP-UP enable and Session Clear method
    const enableSessionExpiryPopup = () => {
      try {
        setFailedPopUp("Your session has expired. Please log in again to continue using the application.");
        //#region Show PopUp
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        //#endregion
        localStorage.removeItem("cid_t");
        setisSessionEpired(true);
      } catch (error) {
  
      }
    }
    //#endregion
  
    //#region  To fetch the updated sess
    const getClientSessionDetails = async () => {
      try {
        if (clientID != undefined) {
          let config = {
            endpoint: "/ClientSession",
            token: clientToken
          }
  
          let data: any = await sendRequestToNodeServicesApiTS(config)
  
          if (data.data.statusCode == 200) {
            //#region Update and Frame Client Session
            localStorage.removeItem("cid_t");
            let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
            var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
            let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
            localStorage.setItem("cid_t", enPass);
            //#endregion
  
            //#region Update state variables
            let updatedobjClient: any = jwt(dePass);
            SessionEpiredDateTime = updatedobjClient.CreatedAt;
            setSessionEpiredDateTime(SessionEpiredDateTime);
            clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
            //#endregiona
          }
          else if (data.data.data == "Invalid authentication token provided") {
            enableSessionExpiryPopup();
          }
          else {
            enableSessionExpiryPopup();
          }
        }
        else {
          localStorage.clear();
        }
  
      } catch (error) {
        setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
  
        //#region Show PopUp
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        //#endregion
      }
    }
    //#endregion
  
    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
      try {
        clearTimeout(InActiveTimeout);
        InActiveTimeout = setTimeout(() => {
          enableSessionExpiryPopup();
  
        }, Number(process.env.REACT_APP_TABIDLESCENARIO));
        let currentDateTime: any = new Date().toISOString();
        let diffInMilliseconds: number;
        diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
          getClientSessionDetails();
        }
      }
      catch (ex: any) {
        setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
  
        //#region Show PopUp
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        //#endregion
      }
  
    }
    //#endregion
  

  const onTextBoxValueChange = (e: any) => {
    try {
      let ostate = state;
      ostate[e.target.name] = e.target.value;
      setState({ ...state, [e.target.name]: e.target.value })
      onBlurValidation(e);
    } catch (error) {

    }
  }

   const openMicrosoftAuthWindow =()=> {
    try {
      loaderContent = "Please wait. We are connecting the Teams settings";
      setloaderContent(loaderContent);
    const microsoftLoginUrlTemmplate = constants.URL.REACT_APP_MICROSOFT_AUTH_URL;
    let microsoftLoginUrl = microsoftLoginUrlTemmplate
    ? microsoftLoginUrlTemmplate
        .replace("{0}", encodeURIComponent(state.targetAppID ))
        .replace("{1}", encodeURIComponent(`${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams/Redirect`))
    : "";
  
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    
    const newWindow = window.open(microsoftLoginUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    
    if (!newWindow) {
      setFailedPopUp("Popup has been blocked, enable it to authetnicate your account");
      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    } else {
      let loaderElement = document.getElementById("teamsLoader")
        loaderElement?.removeAttribute("hidden")
        let button = document.getElementById("targetButton");
        button?.setAttribute("disabled", "disabled");
      newWindow?.postMessage("message",`${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams`)
      const checkWindowClosed = setInterval(() => {
        if (newWindow.closed && !isPoupClosedAfterSuccess) {
          clearInterval(checkWindowClosed);
          loaderElement?.setAttribute("hidden", "true")
          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")
        }
      }, 1000); // Check every second
        
      
    }
  } catch (error) {
    let loaderElement = document.getElementById("teamsLoader")
        loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")
    setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
    //#region Show PopUp
    document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
    document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
    document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
    document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
    document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
    document.getElementById("fadeDiv")?.removeAttribute("hidden");
  }
  }
  useEffect(() => {
    const responseRedirect = (event : any)=>{    
      if(event.data.authorization == `${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/MSTeams/Redirect` && event.origin==`${constants.URL.REACT_APP_MAIN_URL_POPUP}`){
      let loaderElement = document.getElementById("teamsLoader")
      loaderElement?.removeAttribute("hidden")
      isPoupClosedAfterSuccess = true;
       validateTeams(event);
      }
      
    };
    window.addEventListener("message", responseRedirect);
    return() => window.removeEventListener("message", responseRedirect)
  }, [state])
  
  const onBlurValidation = (e: any) => {
    try {

      let button = document.getElementById("targetButton");
      let invalidTenantNameElement = document.getElementById("invalidTenantName");
      let invalidTargetEmailElement = document.getElementById("invalidTargetEmail");
      let invalidTargetPasswordElement = document.getElementById("invalidTargetPassword");
      let emptyTenantNameElement = document.getElementById("emptyTenantName");
      let emptyTargetEmailElement = document.getElementById("emptyTargetEmail");
      let emptyTargetPasswordElement = document.getElementById("emptyTargetPassword");
      let emptyTargetAppIDElement = document.getElementById("emptyTargetAppID");
      let emptyTargetAppSecretIDElement = document.getElementById("emptyTargetAppSecretID");

      // if (e.target.name == "tenantName") {
      //   if (state.tenantName == "") {
      //     emptyTenantNameElement?.removeAttribute("hidden");
      //     invalidTenantNameElement?.setAttribute("hidden", "true");
      //   }
      //   else if (!(state.tenantName.length <= 27)) {
      //     invalidTenantNameElement?.removeAttribute("hidden");
      //     emptyTenantNameElement?.setAttribute("hidden", "true");
      //   }
      //   else {
      //     invalidTenantNameElement?.setAttribute("hidden", "true");
      //     emptyTenantNameElement?.setAttribute("hidden", "true");
      //   }
      // }
      // else if (e.target.name == "targetEmail") {
      //   if (state.targetEmail == "") {
      //     invalidTargetEmailElement?.setAttribute("hidden", "true");
      //     emptyTargetEmailElement?.removeAttribute("hidden");
      //   }
      //   else if (!((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))) {
      //     emptyTargetEmailElement?.setAttribute("hidden", "true");
      //     invalidTargetEmailElement?.removeAttribute("hidden");
      //   }
      //   else {
      //     invalidTargetEmailElement?.setAttribute("hidden", "true");
      //     emptyTargetEmailElement?.setAttribute("hidden", "true");
      //   }
      // }
      if (e.target.name == "targetAppID") {
        if (state.targetAppID != "") {
          emptyTargetAppIDElement?.setAttribute("hidden", "true");
        }
        else {
          emptyTargetAppIDElement?.removeAttribute("hidden");
        }
      }
      else if (e.target.name == "targetAppSecretID") {
        if (state.targetAppSecretID != "") {
          emptyTargetAppSecretIDElement?.setAttribute("hidden", "true");
        }
        else {
          emptyTargetAppSecretIDElement?.removeAttribute("hidden");
          setIsMSTeamsSecretIDEyeOpen(false);
        }
      }
      else if (e.target.name == "targetPassword") {
        if (state.targetPassword == "") {
          emptyTargetPasswordElement?.removeAttribute("hidden");
          invalidTargetPasswordElement?.setAttribute("hidden", "true");
          setIsMSTeamsPasswordEyeOpen(false);
        }
        else if (!(state.targetPassword.length >= 8 && state.targetPassword.length <= 256)) {
          invalidTargetPasswordElement?.removeAttribute("hidden");
          emptyTargetPasswordElement?.setAttribute("hidden", "true");
        }
        else {
          invalidTargetPasswordElement?.setAttribute("hidden", "true");
          emptyTargetPasswordElement?.setAttribute("hidden", "true");
        }
      }

      if (state.targetAppID != undefined && state.targetAppID != "" && state.targetAppSecretID != undefined && state.targetAppSecretID != "" ) {
        // let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
        let submitButton = document.getElementById("targetButton");
          submitButton?.removeAttribute('disabled');
      }
      else {
        let submitButton = document.getElementById("targetButton");
        submitButton?.setAttribute("disabled", "disabled");
      }
    }
    catch (error: any) {
    }
  }

  const LoadData = async () => {

    try {

      if (clientID != undefined && clientToken != undefined) {
        let config = {
          endpoint: "getSettingsDetails",
          token: clientToken,
          data: {
            clientID: clientID,
            type: "Teams",
          }
        }

        if(adminLocal !=undefined && adminLocal !== null){
          (config.data as any).token = adminToken
      }

        let responsedata = await sendRequestToNodeServicesApiTS(config)
        if (responsedata?.data.statusCode == 200 && responsedata.data.data != undefined && responsedata.data.data.MSTeamsAppClientID != undefined) {

          let byteValue = objCryptoJS.AES.decrypt(responsedata.data.data.MSTeamsUserRefreshToken, reactNode_en_K);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

          setState({ ...state, tenantName: responsedata.data.data.TenantName, targetAppID: responsedata.data.data.MSTeamsAppClientID, targetAppSecretID: responsedata.data.data.MSTeamsAppSecretID, targetEmail: responsedata.data.data.MSTeamsUserMailID, targetPassword: dePass })
          let targetUserMailId = responsedata.data.data.MSTeamsUserMailID;
          settargetEmailId(targetUserMailId);
           //checking need to be removed
          let x = document.getElementById("tenantName")
          x?.setAttribute("disabled", "disabled")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

        }
        else if (responsedata.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {

          if (state.tenantName != undefined && state.tenantName !== "" && state.tenantName.length <= 27 && state.targetAppID != undefined && state.targetAppID != "" && state.targetAppSecretID != undefined && state.targetAppSecretID != "" && state.targetEmail != undefined && state.targetEmail != "" && state.targetEmail.length <= 320 && state.targetPassword != undefined && state.targetPassword != "" && state.targetPassword.length >= 8 && state.targetPassword.length <= 256) {
            let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
            if (Check == true) {
              let submitButton = document.getElementById("targetButton");
              submitButton?.removeAttribute('disabled');
            }
            else {
              let submitButton = document.getElementById("targetButton");
              submitButton?.setAttribute('disabled', 'disabled');

            }
          }
          else {
            let submitButton = document.getElementById("targetButton");
            submitButton?.setAttribute("disabled", "disabled");
          }

          setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if (responsedata?.data.statusCode == 200 && responsedata.data.data != undefined && responsedata.data.data.MSTeamsAppClientID == undefined) {
          if (state.tenantName != undefined && state.tenantName !== "" && state.tenantName.length <= 27 && state.targetAppID != undefined && state.targetAppID != "" && state.targetAppSecretID != undefined && state.targetAppSecretID != "" && state.targetEmail != undefined && state.targetEmail != "" && state.targetEmail.length <= 320 && state.targetPassword != undefined && state.targetPassword != "" && state.targetPassword.length >= 8 && state.targetPassword.length <= 256) {
            let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
            if (Check == true) {
              let submitButton = document.getElementById("targetButton");
              submitButton?.removeAttribute('disabled');
            }
            else {
              let submitButton = document.getElementById("targetButton");
              submitButton?.setAttribute('disabled', 'disabled');

            }
          }
          else {
            let submitButton = document.getElementById("targetButton");
            submitButton?.setAttribute("disabled", "disabled");
          }
        }
        else {

          if (state.tenantName != undefined && state.tenantName !== "" && state.tenantName.length <= 27 && state.targetAppID != undefined && state.targetAppID != "" && state.targetAppSecretID != undefined && state.targetAppSecretID != "" && state.targetEmail != undefined && state.targetEmail != "" && state.targetEmail.length <= 320 && state.targetPassword != undefined && state.targetPassword != "" && state.targetPassword.length >= 8 && state.targetPassword.length <= 256) {
            let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
            if (Check == true) {
              let submitButton = document.getElementById("targetButton");
              submitButton?.removeAttribute('disabled');
            }
            else {
              let submitButton = document.getElementById("targetButton");
              submitButton?.setAttribute('disabled', 'disabled');

            }
          }
          else {
            let submitButton = document.getElementById("targetButton");
            submitButton?.setAttribute("disabled", "disabled");
          }

          setFailedPopUp("Please provide valid MS Teams Details");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      let y = document.getElementById("targetButton")
      y?.setAttribute("disabled", "disabled")

      setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion

    }
  }



  const validateTeams = async (event: { data: any; }) => {
    try { 
      //Generate Refresh Token
      let loaderElement = document.getElementById("teamsLoader")
      let refreshToken = "";
      let config = {
        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigrationWebApp/FetchUserToken`,
        token: clientToken,
        data: {
            userEmailID: "",
            appClientID: `${state.targetAppID.trim()}`,
            authCode: event.data.authorizationCode,
            type: "teams",
            isReponseRequired : true,
            clientID : clientID
        }
    }
      let response = await sendRequestToUpdateUsersList(config); //get the refresh token.
      
      if (clientID != undefined && clientToken != undefined && response?.data != undefined && response.data.statusCode == 200) {
        refreshToken = response.data.data;
      
       

        let enPass = objCryptoJS.AES.encrypt(refreshToken, reactNode_en_K).toString();

        let config = {
          endpoint: "validateTeamsDetails",
          token: clientToken,
          data: {
            clientID: clientID,
            azureAppID: `${state.targetAppID.trim()}`,
            azureAppSecretID: `${state.targetAppSecretID.trim()}`,
            userRefreshToken: enPass.trim(),
          }
        }
        if(adminLocal !=undefined && adminLocal !== null){
          (config.data as any).token = adminToken
      }

        let responsedata: any = await sendRequestToNodeServicesApiTS(config);
        if (responsedata.data.statusText == "ok") {

          let byteValue = objCryptoJS.AES.decrypt(responsedata.data.data.MSTeamsUserRefreshToken, reactNode_en_K);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

          setState({ ...state, tenantName: responsedata.data.data.TenantName, targetAppID: responsedata.data.data.MSTeamsAppClientID, targetAppSecretID: responsedata.data.data.MSTeamsAppSecretID, targetEmail: responsedata.data.data.MSTeamsUserMailID, targetPassword: dePass })

          let targetUserMailId = responsedata.data.data.MSTeamsUserMailID;
          settargetEmailId(targetUserMailId);

          loaderElement?.setAttribute("hidden", "true")

          let x = document.getElementById("tenantName")
          x?.setAttribute("disabled", "disabled")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          //#region Show PopUp
          document.getElementById("targetSuccessPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetSuccessPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetSuccessPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetSuccessPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetSuccessPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion

        }
        else if (responsedata.data.data == "Invalid authentication token provided") {
          loaderElement?.setAttribute("hidden", "true")
          enableSessionExpiryPopup();
        }
        else if (responsedata.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if(responsedata.data.data == "Invalid User Role"){
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Please enter a valid user mail id");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if (responsedata.data.data == "Invalid Details Provided"){
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Please check your Azure app Secret ID or Azure App ID");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if (responsedata.data.data == "Invalid Client Secret") {
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Invalid Azure App Secret ID Provided! Please refer to the Azure App configuration guide");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if (responsedata.data.data == "Insufficient Scopes Provided") {
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Missing scopes! Please refer to the Azure App configuration Guide");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else if (responsedata.data.data == "Invalid License") {
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Please provide Teams & SharePoint license!");
           //#region Show PopUp
           document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
           document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
           document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
           document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
           document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
           document.getElementById("fadeDiv")?.removeAttribute("hidden");
           //#endregion
        }

        else if (responsedata.data.data == "Sorry, we couldn't connect different M365 tenant"){
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Sorry, we couldn't connect different M365 tenant.");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
        }
        else {

            loaderElement?.setAttribute("hidden", "true")

            let y = document.getElementById("targetButton")
            y?.removeAttribute("disabled")

            setFailedPopUp("Please provide valid MS Teams Details");

            //#region Show PopUp
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
        }
      }
      else if(response.data.data =="Please connect the account you provided in the settings")
      {
        let loaderElement = document.getElementById("teamsLoader")
        loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Please connect the account you provided in the settings");

          //#region Show PopUp
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          //#endregion
      }
      else {
        let loaderElement = document.getElementById("teamsLoader")
        loaderElement?.setAttribute("hidden", "true")
  
        let y = document.getElementById("targetButton")
        y?.removeAttribute("disabled")
        setFailedPopUp("Something went wrong. Please reach AVAMIGRATRON team");

        //#region Show PopUp
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        // localStorage.clear();
      }


    } catch (error) {

      let loaderElement = document.getElementById("teamsLoader")
      loaderElement?.setAttribute("hidden", "true")

      let y = document.getElementById("targetButton")
      y?.removeAttribute("disabled")

      setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
    }
  }

  const proceedWithInventoryClick = () => {
    try {
      if(!isUserBasedLicense){
        window.location.pathname = "/Inventory/HighLevel";
      }
      else{
        window.location.pathname = "/LicensedUsers";
      }
     
    } catch (error) {
    }
  }

  const failedPopUpOkClick = () => {
    try {
      if (isSessionEpired === false) {
        //#region Hide PopUp
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:none");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-hidden", "true");
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-modal");
        document.getElementById("targetFailedPopUp")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        //#endregion
      }
      else {
        window.location.pathname = '/Login';
      }
    } catch (error) {
    }
  }

  const downloadRefrenceGuide = async () => {
    try {
      var link = document.createElement('a');
      link.href = "/Office365_Azure_App_Configuration_Guide.pdf";
      link.download = 'Office365_Azure_App_Configuration_Guide.pdf';
      link.dispatchEvent(new MouseEvent('click'));

    } catch (error) {

    }
  }

  return (
    // {/* <!-- Msteams container starts here --> */ }
    <div className="col-md-12 col-sm-12 col-lg-12 float-start mt-4">

      {/* <!-- info container--> */}
      <div className="p-3 rounded-2 mb-4 info-background-color font-14">
        <div>
          <img src="/Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />Connect to MS Teams.
          Refer<a className="underline-remove hyperlink-color font-semibold anchorAsPointer" onClick={downloadRefrenceGuide}> Azure app configuration guide
          </a> to fetch the app details. Migration will be proceeded based on the connected O365 Tenant details.
        </div>
        <div>
          <img src="/Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />You are connected using this Mail ID: <strong>{targetEmailId}</strong>
        </div>
      </div>

      {/* <!--end of info container--> */}

      {/* <div className="row pu-border-none">
        <div className="col-lg-6 col-md-12 col-sm-12 my-2 pu-form-space ">
          <label className="float-start font-semibold pb-3 font-16" htmlFor="tenantName">Tenant Name <span
            className="required">*</span></label>
          <input type="text" className="form-control form-control-style font-14" id="tenantName"
            placeholder="Enter Tenant Name" name="tenantName" value={state.tenantName} onChange={onTextBoxValueChange} />
          <label className="float-start font-semibold font-16 required" id="invalidTenantName" hidden>Invalid Tenant Name provided</label>
          <label className="float-start font-semibold font-16 required" id="emptyTenantName" hidden>Tenant Name cannot be empty</label>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 my-2 ">
          <label className="float-start font-semibold pb-3 font-16" htmlFor="targetEmail">Email Address </label><span
            className="required">*</span>
            <span>
              <img src="/Images/info-icon.svg" className="slack-icon pe-2" alt="info-icon" data-tip="Enter O365 Global or Teams & SharePoint Administrator email address" data-for='toolTipTeams1' data-place='right' />
              <ReactTooltip id="toolTipTeams1" class='mySepecialClass' />
            </span>
          
          <input type="email" className="form-control form-control-style font-14 " id="targetEmail"
            placeholder="Enter the Email Address" name="targetEmail" value={state.targetEmail} onChange={onTextBoxValueChange} />
          <label className="float-start font-semibold font-16 required" id="invalidTargetEmail" hidden>Invalid Email Address provided</label>
          <label className="float-start font-semibold font-16 required" id="emptyTargetEmail" hidden>Email Address cannot be empty</label>
        </div>
      </div> */}

      <div className="row pu-border-none">
        {/* <div className="col-lg-6 col-md-12 col-sm-12 my-2 pu-form-space ">
          <label className="float-start font-semibold pb-3 font-16" htmlFor="targetPassword">Password </label><span
            className="required">*</span>
            <span>
              <img src="/Images/info-icon.svg" className="slack-icon pe-2" alt="info-icon" data-tip="Enter O365 Global or Teams & SharePoint Administrator Password (without SSO & MFA)" data-for='toolTipTeams2' data-place='right' />
              <ReactTooltip id="toolTipTeams2" class='mySepecialClass' />
            </span>
          
          <div className="eyeiconDiv">
            <input type={isMSTeamsPasswordEyeOpen ? "text" : "password"} className="form-control form-control-style font-14 eyeiconInputElement" id="targetPassword"
              placeholder="Enter the Password" value={state.targetPassword} name="targetPassword" onChange={onTextBoxValueChange} />
            <img src={isMSTeamsPasswordEyeOpen == false && state.targetPassword != undefined && state.targetPassword != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"}
              className={isMSTeamsPasswordEyeOpen == false && state.targetPassword != undefined && state.targetPassword != "" ? "eyeiconOpenImg anchorAsPointer" : "eyeiconCloseImg anchorAsPointer"} alt="eye-open-icon"
              onClick={() => (state.targetPassword != undefined && state.targetPassword != "") ? setIsMSTeamsPasswordEyeOpen(isMSTeamsPasswordEyeOpen ? false : true) : null} />
          </div>
          <label className="float-start font-semibold font-16 required" id="invalidTargetPassword" hidden>Invalid Password provided</label>
          <label className="float-start font-semibold font-16 required" id="emptyTargetPassword" hidden>Password cannot be empty</label>
        </div> */}


        <div className="col-lg-6 col-md-12 col-sm-12 my-2 pu-form-space ">
          <label className="float-start font-semibold  pb-3 font-16" htmlFor="targetAppID">Azure App ID <span
            className="required">*</span></label>
          <input type="text" className="form-control form-control-style font-14" id="targetAppID"
            placeholder="Enter the Azure App ID" value={state.targetAppID} name="targetAppID" onChange={onTextBoxValueChange} />
          <label className="float-start font-semibold font-16 required" id="invalidTargetAppID" hidden>Invalid App ID provided</label>
          <label className="float-start font-semibold font-16 required" id="emptyTargetAppID" hidden>App ID cannot be empty</label>
        </div>
       
          {isServiceAdmin == true?
          <></>
          :
          <div className="col-lg-6 col-md-12 col-sm-12 my-2 pu-form-space">
            <label className="float-start font-semibold  pb-3 font-16" htmlFor="targetAppSecretID">Azure App secret ID <span
            className="required">*</span></label>
          <div className="eyeiconDiv">
            <input type={isMSTeamsSecretIDEyeOpen ? "text" : "password"} className="form-control form-control-style font-14 eyeiconInputElement" id="targetAppSecretID"
              placeholder="Enter the Azure App Secret ID" value={state.targetAppSecretID} name="targetAppSecretID" onChange={onTextBoxValueChange} />
            <img src={isMSTeamsSecretIDEyeOpen == false && state.targetAppSecretID != undefined && state.targetAppSecretID != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"}
              className={isMSTeamsSecretIDEyeOpen == false && state.targetAppSecretID != undefined && state.targetAppSecretID != "" ? "eyeiconOpenImg anchorAsPointer" : "eyeiconCloseImg anchorAsPointer"} alt="eye-open-icon"
              onClick={() => (state.targetAppSecretID != undefined && state.targetAppSecretID != "") ? setIsMSTeamsSecretIDEyeOpen(isMSTeamsSecretIDEyeOpen ? false : true) : null} />
          </div>
          <label className="float-start font-semibold font-16 required" id="invalidTargetAppSecretID" hidden>Invalid App Secret ID provided</label>
          <label className="float-start font-semibold font-16 required" id="emptyTargetAppSecretID" hidden>App Secret ID cannot be empty</label>
          </div>
          }
          
      </div>

      {/* <div className="row pu-border-none">
      
      </div> */}

      <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
        <div className=" mx-3 d-flex justify-content-end">
          <button type="button" className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular" id="targetButton" onClick={openMicrosoftAuthWindow}>Connect MS Teams</button>
        </div>
      </footer>

      {/* <!--Success Pop-up--> */}
      <div className="modal fade" id="targetSuccessPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/success.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Success!</h5>
                {isUserBasedLicense ?
                <p className="success-text w-100 text-center mb-3 px-4">You have successfully connected MS Teams. <br /> You can now proceed to assign license for the required users</p>
                :
                <p className="success-text w-100 text-center mb-3 px-4">MS Teams has been connected successfully!</p>
                }
              </div>
              <div className="text-center w-100 mt-3 d-inline-block goToInventoryTeamDiv">
                <button type="button" className="btn-primary-theme mb-2 common-btn-class btn btn-ok" data-bs-dismiss="modal" onClick={proceedWithInventoryClick}>{isUserBasedLicense ? "Go to License Assignment" : "Go to Inventory"}</button>
                {/* <a href="/Inventory/HighLevel" className="font-semibold font-14 me-2 settingsSuccessPopUp_Secondary mb-2 goToInventoryTeams" data-bs-dismiss="modal">Go to Inventory</a> */}
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--success pop-up--> */}

      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="targetFailedPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--Alert pop-up--> */}
      <div className="overlay settingLoaderMainDiv" id='teamsLoader' hidden={true}>
                <div className="settingLoaderSubDiv">
                    <div className="spinner-border loaderImg text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="text-center">{loaderContent}</p>
                </div>
            </div>
    </div>
    // {/* <!-- Msteams container ends here --> */ }
  )

}