import { useState, useEffect } from "react";
import { gridLoad } from "../../Services/AdminServices";
import objCryptoJS from 'crypto-js'
import intlTelInput from 'intl-tel-input';
import jwt from 'jwt-decode'
import constants  from '../../Common/Constants'


export default function NewClient() {

    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;

    let local: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    }

    let formObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "", CountryCode: "" }
    let [formState, setFormState] = useState(formObj);
    let [isSSOAccount, setIsSSOAccount] = useState(false);
    let licenseObj = { ChannelCount: 0, ChannelCost: 0, ChatCount: 0, ChatCost: 0, UserCount: 0, UserCost: 0, resCost: 0, TotalCost: 0, parallelCount: 0, validTill: 0 }
    let [license, setLicense] = useState(licenseObj);
    const [alertMessage, setAlertMessage] = useState("Failed to Add New Client Account Information!")
    let [regexPass, setRegexPass] = useState(false)
    let [licensePass, setLicensePass]= useState(true)
    let licenseRegexObj = { ChannelCount: false, ChannelCost: false, ChatCount: false, ChatCost: false, UserCount: false, UserCost: false, resCost: false }
    let [licenseRegex, setLicensseRegex] = useState(licenseRegexObj)
    let [loaderText, setLoaderText] = useState("")
    let [isUserBased, setIsUserBased] = useState(false);
    let [isClientActive , setIsClientActive] = useState(true);

    useEffect(() => {
        let submitButton = document.getElementById("submitButton")
        submitButton?.setAttribute("disabled", "disabled")
        const input: any = document.querySelector("#phone");
        intlTelInput(input, {
            preferredCountries: ['us'],
            separateDialCode: true,
        });
    }, [])

    const OnChangeName = (e: any) => {

        formState = { ...formState, [e.target.name]: e.target.value }
        setFormState(formState)
    }

    const countrycode = async () => {
        try {

            var selectedflag: any = document.getElementsByClassName("iti__flag")
            var selectedCountryCode: any = document.getElementsByClassName("iti__selected-dial-code")
            var title: any = document.getElementsByClassName('iti__selected-flag')
            formState.CountryCode = `${selectedCountryCode[0].innerText}~${selectedflag[0].className}`;
        }
        catch (error: any) {


        }
    }

    const regexValidation = (e: any) => {
        try {

            OnChangeName(e)


            let submitButton = document.getElementById("submitButton");

            let userName = document.getElementById("userName")
            let companyName = document.getElementById("companyName")
            let email = document.getElementById("Email")
            let phone = document.getElementById("Phone")

            let emptyUserName = document.getElementById("emptyuserName");
            let emptyCompanyName = document.getElementById("emptycompanyName")
            let emptyEmail = document.getElementById("emptyEmail")
            let emptyPhone = document.getElementById("emptyPhone")

            if (e.target.id == "user-name") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "companyname") {
                if (formState.CompanyName == "") {
                    emptyCompanyName?.removeAttribute('hidden')
                    companyName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyCompanyName?.setAttribute('hidden', 'hidden')

                    let spaceRemoved = formState.CompanyName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[0-9a-zA-Z ]{1,80}$/.test(trimmed)

                    if (result == false) {
                        companyName?.removeAttribute('hidden')
                    }
                    else {
                        companyName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "email") {

                if (formState.EmailAddress == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)

                    if (result == false || formState.EmailAddress.length > 320) {
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "phone") {
                if (formState.PhoneNumber == "") {
                    emptyPhone?.removeAttribute('hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyPhone?.setAttribute('hidden', 'hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    let result = /^[0-9]{5,15}$/.test(formState.PhoneNumber)
                    if (result == false) {
                        phone?.removeAttribute('hidden')
                    }
                    else {
                        phone?.setAttribute('hidden', 'hidden')

                    }
                }
            }
            if (formState.UserName != "" && formState.CompanyName != "" && formState.EmailAddress != "" && formState.PhoneNumber != "") {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let removeSpace = formState.CompanyName.replace(/\s\s+/g, ' ')
                let trim = removeSpace.trim().replaceAll(" +", " ");
                let Check2 = /^[0-9a-zA-Z ]{1,80}$/.test(trim)
                let Check3 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)
                let Check4 = /^[0-9]{5,15}$/.test(formState.PhoneNumber)

                if (Check1 && Check2 && Check3 && formState.EmailAddress.length <= 320 && Check4 == true) {
                    setRegexPass(true)
                    if(licensePass==true || (license.ChannelCount == 0 && license.ChannelCost && license.ChatCount==0 && license.ChatCost && license.UserCount==0 && license.UserCost && license.resCost==0 && (license.parallelCount == 0 || isNaN(license.parallelCount)) && (license.validTill == 0 || isNaN(license.validTill)) && license.TotalCost)){
                        submitButton?.removeAttribute('disabled');
                    }
                }
                else {
                    submitButton?.setAttribute('disabled', 'disabled');
                    setRegexPass(false)
                }
            }
            else {
                submitButton?.setAttribute('disabled', 'disabled');
                setRegexPass(false)
            }
        }
        catch (error: any) {

        }
    }

    const storeClientDetails = async () => {
        try {

            if (clientToken != undefined) {
                let submitButton = document.getElementById("submitButton")
                submitButton?.setAttribute("disabled", "disabled")
                countrycode()
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/storeClientDetails`,
                    data: {
                        "name": `${formState.UserName}`,
                        "mailID": `${formState.EmailAddress}`,
                        "phone": `${formState.PhoneNumber}`,
                        "countryCode": `${formState.CountryCode}`,
                        "companyName": `${formState.CompanyName}`,
                        "registeredAt": `${new Date().toISOString().split('T')[0]}`,
                        "channelCount": Number(license.ChannelCount),
                        "chatCount": Number(license.ChatCount),
                        "chatCost": Number(license.ChatCost),
                        "channelCost": Number(license.ChannelCost),
                        "userCount": Number(license.UserCount),
                        "userCost": Number(license.UserCost),
                        "parallelCount": Number(license.parallelCount),
                        "validTill": Number(license.validTill),
                        "resCost": Number(license.resCost),
                        "isUserBased": isUserBased,
                        "isSSOAccount": isSSOAccount,
                        "isRevokeLicense" : false,
                        "isActive" : isClientActive
                    },
                }
                )
                    ;
                let loader = document.getElementById("loader")
                loader?.removeAttribute('hidden');
                let responsedata: any = await gridLoad(config);
                responsedata.data.statusText == "Mail ID already present" ?
                    setAlertMessage("Email Address Already in use!")
                    : setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                loader?.setAttribute('hidden', 'hidden');
                setLoaderText("")
                let popup = (responsedata.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            setLoaderText("")
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            //#endregion
        } catch (error) {
        }
    }

    const removeState = (e: any) => {

        license = { ...license, [e.target.name]: "" }
        setLicense(license)
    }

    const OnChangeLicense = (e: any) => {
        try {
            // e.target.value = e.target.value == "" ? 0 : e.target.value
            if ((e.target.id == "parallelCount") || (e.target.id == "validTill")){
                license = { ...license, [e.target.name]: Number(e.target.value) }
            }
            else{
                license = { ...license, [e.target.name]: e.target.value }
            }
            
            setLicense(license)
        }
        catch (error: any) {


        }
    }

    const licenseValidation = (e: any) => {
        try {
            e.target.value = e.target.value != "" ? e.target.value : 0
            OnChangeLicense(e)

            let parallelInstance = document.getElementById('invalidparallelInstance');
            let validTill = document.getElementById('invalidExpirationDate');
            if (e.target.id == "parallelCount") {
                if (e.target.value == "Select Parallel Instance") {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    parallelInstance?.removeAttribute('hidden')
                    setLicensePass(false)
                    totalCost()
                }
                else {
                    parallelInstance?.setAttribute('hidden', 'hidden')
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: false }
                    totalCost();
                }
            }
            else if (e.target.id == "validTill") {
                if (e.target.value == "Select Valid Till") {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    validTill?.removeAttribute('hidden')
                    setLicensePass(false)
                    totalCost()
                }
                else {
                    validTill?.setAttribute('hidden', 'hidden')
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: false }
                    totalCost();
                }
            }
            else {

                let result = /^[0-9]{1,8}$/.test(e.target.value)
                let validation = document.getElementById(e.target.name)
                if (result == false) {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    setLicensseRegex(licenseRegexObj)
                    validation?.removeAttribute('hidden')
                    license = { ...license, TotalCost: 0 }
                    setLicense(license)
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.setAttribute('disabled', 'disabled');
                    setLicensePass(false)
                }
                else {
                    validation?.setAttribute('hidden', 'hidden')
                    totalCost()
                }
            }
        }
        catch (error: any) {

        }
    }

    const totalCost = () => {
                try {
            let licenseCost = isUserBased ? (license.UserCost * license.UserCount) : (license.ChannelCount * license.ChannelCost) + (license.ChatCount * license.ChatCost);
            let resourceValidation = (license.resCost != 0 && licenseRegexObj.resCost == false && ((license.parallelCount!= 0 &&  !isNaN(license.parallelCount) )  || (license.validTill != 0 && !isNaN(license.validTill)))) ? true : false;
            licenseCost = (isNaN(licenseCost) == true || licenseRegexObj.ChannelCost == true || licenseRegexObj.ChannelCount == true || licenseRegexObj.ChatCost == true || licenseRegexObj.ChatCount == true || licenseRegexObj.UserCost == true || licenseRegexObj.UserCount == true)
                ? 0 : licenseCost;
            let totalcost = licenseCost != 0 ? licenseCost : resourceValidation == true ? license.resCost : 0;
            license = { ...license, TotalCost: totalcost }
            setLicense(license)
            let costTotal = (license.ChannelCost != 0 && license.ChannelCount == 0) || (license.ChannelCost == 0 && license.ChannelCount != 0) || (license.ChatCost != 0 && license.ChatCount == 0) || (license.ChatCost == 0 && license.ChatCount != 0) || (license.UserCount != 0 && license.UserCost == 0) || (license.UserCount == 0 && license.UserCost != 0) ? false : true
            if (totalcost != 0 && costTotal != false) {
                setLicensePass(true)
                if(regexPass==true){
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.removeAttribute('disabled');
                    loaderText = "Please wait while we set up your Migration Environment..."
                    setLoaderText(loaderText)
                }
            }
            else {
                
                if(regexPass==true && license.ChannelCount == 0 && license.ChannelCost==0 && license.ChatCount==0 && license.ChatCost==0 && license.UserCount==0 && license.UserCost==0 && (license.parallelCount == 0 || isNaN(license.parallelCount)) && (license.validTill==0 || isNaN(license.validTill)) && license.TotalCost==0 && license.resCost==0){
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.removeAttribute('disabled');
                    setLicensePass(true)
                }
                else {
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.setAttribute('disabled', 'disabled');
                    setLicensePass(false)
                }
            }

        }
        catch (error: any) {

        }
    }


    const handleRadioBtn = (e: any) => {
        try {
            if(e.target.name == "planType"){
            isUserBased = (e.target.id == "userBased") ? true : false;
            setIsUserBased(isUserBased)
            license = { ChannelCount: 0, ChannelCost: 0, ChatCount: 0, ChatCost: 0, UserCount: 0, UserCost: 0, resCost: 0, TotalCost: 0, parallelCount: 0, validTill: 0 }
            setLicense(license)
            let parallelInstance: any = document.getElementById('parallelCount');
            parallelInstance.value = "Select Parallel Instance";
            let validDate: any = document.getElementById('validTill');
            validDate.value = "Select Valid Till";
            }
            else if (e.target.name == "status"){
                isClientActive = (e.target.id == "active") ? true : false;
                setIsClientActive(isClientActive);
               
             }
             else if (e.target.name == "regType"){
                 isSSOAccount = (e.target.id =="sso") ? true : false;
                 setIsSSOAccount(isSSOAccount);
             }
             regexValidation(e);

        }
        catch (error: any) {

        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center my-4 card-container mx-0 profle-update">
                <div className="col-md-12 mt-4">
                    <h4 className="font-26 font-bold mb-4 ">
                        <a href="/Admin/ManageClients" className="anchorAsPointer"><span><img src='/Images/backarrow.svg' className="p-1" alt="left-arrow" title="Back" /></span></a>
                        New Client
                    </h4>
                </div>
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div id="account-information" className="tab-pane active  pt-3 pb-5">
                            {/* <!-- Registration Starts --> */}
                            <div className="col-md-12 mb-4">
                                <h5 className="font-20 font-bold mb-4">General Information</h5>
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="font-14 font-semibold   form-label" htmlFor="user-name">User Name<span className="required">*</span></label>
                                        <input type="text" className="form-control custom-form" id="user-name" placeholder="Enter User Name" name="UserName" value={formState.UserName} onChange={(e) => regexValidation(e)} />
                                        <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="font-14 font-semibold   form-label" htmlFor="companyname">Company Name<span className="required">*</span></label>
                                        <input type="text" className="form-control custom-form" id="companyname" placeholder="Enter Company Name" name="CompanyName" value={formState.CompanyName} onChange={(e) => regexValidation(e)} />
                                        <label id="companyName" className="required" hidden={true} >Please enter Valid Company Name</label>
                                        <label id="emptycompanyName" className="required" hidden={true} >Company Name cannot be empty</label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="font-14 font-semibold   form-label" htmlFor="email">Email Address<span className="required">*</span></label>
                                        <input type="email" className="form-control custom-form" id="email" placeholder="Enter Email Address" name="EmailAddress" value={formState.EmailAddress} onChange={(e) => regexValidation(e)} />
                                        <label id="Email" className="required" hidden={true}>Please enter Valid Mail Address</label>
                                        <label id="emptyEmail" className="required" hidden={true}>Mail Address cannot be empty</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-5">
                                <div className="row">
                                 
                                    <div className="col-md-3">
                                        <label className="font-14 font-semibold   form-label" htmlFor="phone">Phone Number<span className="required">*</span></label>
                                        <div>
                                            <input type="text" className="form-control custom-form" id="phone" placeholder="Enter Phone Number" name="PhoneNumber" value={formState.PhoneNumber} onChange={(e) => regexValidation(e)} />
                                        </div>
                                        <label id="Phone" className="required" hidden={true}> Please enter Valid Phone number </label>
                                        <label id="emptyPhone" className="required" hidden={true}>Phone number cannot be empty </label>
                                    </div>

                                    <div className="col-md-3">
                                    <label className="font-14 font-semibold  form-label mb-3">Status<span className="required">*</span></label>
                                    <div className="d-flex mb-4">
                                    <div className="form-check">
                                            <input className="form-check-input" type="radio" name="status" id="active" checked ={isClientActive} onClick={(e) => handleRadioBtn(e)}/>
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="active">
                                               Active
                                            </label>
                                        </div>
                                        <div className="form-check me-5">
                                            <input className="form-check-input ms-1 me-1" type="radio" name="status" id="inactive" checked = {!(isClientActive)} onClick={(e) => handleRadioBtn(e)}  />
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="inactive">
                                               Inactive
                                            </label>
                                        </div>
                                    
                                    </div>
                                    </div>
                                    <div className="col-md-3">
                                    <label className="font-14 font-semibold  form-label mb-3 ">Registration Type<span className="required">*</span></label>
                                    <div className="d-flex mb-4">
                                    <div className="form-check">
                                            <input className="form-check-input" type="radio" name="regType" id="sso" checked ={isSSOAccount} onClick={(e) => handleRadioBtn(e)} />
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="sso">
                                                SSO
                                            </label>
                                        </div>
                                        <div className="form-check me-5">
                                            <input className="form-check-input ms-1 me-1" type="radio" name="regType" id="regEmail" checked ={!(isSSOAccount)} onClick={(e) => handleRadioBtn(e)}/>
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="regEmail">
                                                Email
                                            </label>
                                        </div>
                                    
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h5 className="font-20 font-bold mb-4">Plan Information</h5>
                                <div className="row">
                                    <label className="font-16 font-semibold mb-3 ">Plan Type</label>
                                    <div className="d-flex mb-4">
                                    <div className="form-check">
                                            <input className="form-check-input" type="radio" name="planType" checked={!(isUserBased)} id="chatChannel" onClick={(e) => handleRadioBtn(e)} />
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="chatChannel">
                                                Chat & Channel
                                            </label>
                                        </div>
                                        <div className="form-check me-5">
                                            <input className="form-check-input ms-1 me-1" type="radio" name="planType" checked={isUserBased} id="userBased" onClick={(e) => handleRadioBtn(e)} />
                                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="userBased">
                                                User-based
                                            </label>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className="row">
                                    {isUserBased ?
                                        <div className="col-md-3">
                                            <label className="font-16 font-semibold mb-3 ">User License</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="channelcount">License Count</label>
                                                    <input type="text" className="form-control custom-form" id="channelcount" name="UserCount" value={license.UserCount == 0 ? "" : license.UserCount} placeholder="License Count" onChange={(e) => licenseValidation(e)} />
                                                    <label id="UserCount" className="required" hidden={true}>Enter Valid License Count</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="userCost">License Cost</label>
                                                    <div className="input-group custom-form mb-3">
                                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                                        <input type="text" className="form-control border-start-0 text-end" id="UserLicenseCost" aria-label="Amount (to the nearest dollar)" name="UserCost" value={license.UserCost == 0 ? "" : license.UserCost} placeholder="Enter Cost" onChange={(e) => licenseValidation(e)} />
                                                        <label id="UserCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {isUserBased == false ?
                                        <div className="col-md-3">
                                            <label className="font-16 font-semibold mb-3 ">Channel Migration</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="channelcount">Channel Count</label>
                                                    <input type="text" className="form-control custom-form" id="channelcount" name="ChannelCount" value={license.ChannelCount == 0 ? "" : license.ChannelCount} placeholder="License Count" onChange={(e) => licenseValidation(e)} />
                                                    <label id="ChannelCount" className="required" hidden={true}>Enter Valid License Count</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="channelcost">Channel Cost</label>
                                                    <div className="input-group custom-form mb-3">
                                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                                        <input type="text" className="form-control border-start-0 text-end" id="channelcost" aria-label="Amount (to the nearest dollar)" name="ChannelCost" value={license.ChannelCost == 0 ? "" : license.ChannelCost} placeholder="Enter Cost" onChange={(e) => licenseValidation(e)} />
                                                        <label id="ChannelCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {isUserBased == false ?
                                        <div className="col-md-3">
                                            <label className="font-16 font-semibold mb-3 ">Conversation Migration</label>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="Conversationcount">Conversation Count</label>
                                                    <input type="text" className="form-control custom-form" id="Conversationcount" name="ChatCount" value={license.ChatCount == 0 ? "" : license.ChatCount} placeholder="License Count" onChange={(e) => licenseValidation(e)} />
                                                    <label id="ChatCount" className="required" hidden={true}>Enter Valid License Count</label>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="font-14 font-regular   form-label" htmlFor="Conversationcost">Conversation Cost</label>
                                                    <div className="input-group custom-form mb-3">
                                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                                        <input type="text" className="form-control border-start-0 text-end" id="Conversationcost" aria-label="Amount (to the nearest dollar)" name="ChatCost" value={license.ChatCost == 0 ? "" : license.ChatCost} placeholder="Enter Cost" onChange={(e) => licenseValidation(e)} />
                                                        <label id="ChatCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-md-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="font-16 font-semibold mb-3 d-block">Parallel Migration</label>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label className="font-14 font-regular form-label" htmlFor="months">Migration Count</label>
                                                                <select className="form-select custom-form" id="parallelCount" name="parallelCount" onChange={(e) => licenseValidation(e)}>
                                                                    <option selected value="Select Parallel Instance">Select</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                </select>
                                                                <label id="invalidparallelInstance" className="required" hidden={true}>Select any option</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label className="font-16 font-semibold mb-3 d-block">Valid Till</label>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label className="font-14 font-regular   form-label" htmlFor="months"># of Months</label>
                                                                <select className="form-select custom-form" id="validTill" name="validTill" onChange={(e) => licenseValidation(e)}>
                                                                    <option selected value="Select Valid Till">Select</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                </select>
                                                                <label id="invalidExpirationDate" className="required" hidden={true}>Select any option</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="font-14 font-regular   form-label">Res. Utilization Cost</label>
                                                                <div className="input-group custom-form mb-3">
                                                                    <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                                                    <input type="text" className="form-control border-start-0 text-end" id="Conversationcost" aria-label="Amount (to the nearest dollar)" name="resCost" value={license.resCost == 0 ? "" : license.resCost} placeholder="Enter Cost" onChange={(e) => licenseValidation(e)} />
                                                                    <label id="ChatCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-4">
                                            <label className="font-14 font-regular  text-nowrap form-label" htmlFor="channelcount "><span className="">Total Cost</span>
                                                <span className="font-24 font-bold pt-2 ms-2">
                                                    <span className="font-20">$</span>{license.TotalCost.toLocaleString("en-US")}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            {/* <!-- Registration Ends --> */}
                            {/* <!-- footer starts --> */}
                            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                                <div className=" mx-3 d-flex justify-content-end">
                                    <a href="/Admin/ManageClients" className="anchorAsPointer">
                                        <button type="button" className=" btn-cancel-border btn-scondary-custom common-btn-class  btn btn-admin-second font-16 font-regular me-2 btn-scondary-custom" >Cancel</button>
                                    </a>
                                    <button type="button" id="submitButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={storeClientDetails}>Submit</button>
                                </div>
                            </footer>
                            {/* <!-- footer ends -->  */}
                        </div>
                    </div>
                </div>
            </div >
            {/* <!--Alert Pop-up--> */}
            < div className="modal fade" id="alertPopUp" tabIndex={- 1} aria-hidden="true" >
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4"> Account Information Updated Successfully!</p>
                                <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Admin-Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="aligncenter">{loaderText}</p>
                </div>
            </div>
            {/* <!--loader section--> */}

        </div>
    )
}