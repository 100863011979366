import React, { useEffect, useState } from 'react';
import AllReport from './AllReport';
import ChannelReport from './ChannelReport';
import MMReport from './MMReport';
import DMReport from './DMReport';
import { ExportToCsv } from 'export-to-csv';
import { gridLoad } from '../../Services/UserMappingServices';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import dateFormat from "dateformat"
import constants from '../../Common/Constants';



export default function ReportLanding() {
  let a: any[] = [];
  let GridLoadarray: any[] = [];
  const activeTab = "common-navTab-reports reporttab nav-link active font-15 nav-tab-custom font-bold anchorAsPointer";
  const inActiveTab = "common-navTab-reports reporttab nav-link nav-tab-custom font-15  font-bold anchorAsPointer";
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [columnOrder, setColumnOrder] = useState("desc");
  const [columnName, setColumnName] = useState("MigratedDate");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("Grid");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [chatType, setChatType] = useState(null)
  const [countPerPage, setCoutPerPage] = useState(10);
  let [SettingsWorkspace, setSettingsWorkspacename] = useState("");
  let [SettingsTenantname, setSettingsTenantname] = useState("");
  let [alertStatus, setalertStatus] = useState("")
  let [isConversationMigrated, setisConversationMigrated] = useState();
  let state = useLocation().state as any;
  let ostate = state != undefined && state.tab != undefined ? state.tab : "All";
  let [checkString, setString] = useState(ostate);
  let [WorkspaceName, setWorkspaceName] = useState("");
  let [TenantName, setTenantName] = useState("");


  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  const date = new Date();
  const offset = date.getTimezoneOffset();
  let [offSet, setoffSet] = useState(offset)
  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;

  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }

  useEffect(() => {
    loadGridPage();
   
  }, [])



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertStatus = "Your session has expired. Please log in again to continue using the application."
      setalertStatus(alertStatus);

      //#region Show PopUp
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  

  const loadGridPage = async () => {

    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getReportLanding`,
          token: clientToken,
          data:
          {
            "type": `${type}`,
            "clientID": clientID,
            "chatType": chatType,
            "sortColumn": `${columnName}`,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage,
            "offset": offSet
          }
        })
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data[2].length != 0 && data.data.data[3].length == 0) {
          if (data.data.data[2][0].SlackWorkspaceName != null && data.data.data[2][0].TeanantName == null) {
            setSettingsTenantname("NA");
            setSettingsWorkspacename(data.data.data[2][0].SlackWorkspaceName);
          }
          else if (data.data.data[2][0].SlackWorkspaceName == null && data.data.data[2][0].TeanantName != null) {
            setSettingsWorkspacename("NA");
            setSettingsTenantname(data.data.data[2][0].TeanantName);
          }
          else {
            setSettingsWorkspacename(data.data.data[2][0].SlackWorkspaceName);
            setSettingsTenantname(data.data.data[2][0].TeanantName);
          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.data[2].length == 0 && data.data.data[3].length == 0) {
          setSettingsWorkspacename("NA");
          setSettingsTenantname("NA");
        }

        else {
          setSettingsWorkspacename(data.data.data[2][0].SlackWorkspaceName);
          setSettingsTenantname(data.data.data[2][0].TeanantName)
          setisConversationMigrated(data.data.data[3][0].IsConversationMigrated);
        }
      }
      else {
        localStorage.clear()
      }
    }

    catch (error: any) {
      setalertStatus("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
      enablebrowsealertpopup();
    }
  }

  const enablebrowsealertpopup = () => {
    try {
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const handleExportBtn = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        if (isConversationMigrated == true) {
          if (checkString != "All") {
            let config = ({
              method: "POST",
              url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getReportLanding`,
              token: clientToken,
              data:
              {
                "type": "Export",
                "clientID": clientID,
                "chatType": checkString,
                "sortColumn": `${columnName}`,
                "sortType": `${columnOrder}`,
                "pageIndex": indexPerPage,
                "offset": offSet
              }

            })
            let loader = document.getElementById("loader");
            loader?.removeAttribute('hidden');
            let data: any = await gridLoad(config);
            loader?.setAttribute('hidden', 'hidden');
            if (data.data.data[0].length == 0) {
              setalertStatus("No Records Found in the Grid")
              enablebrowsealertpopup();
            }
            else if (data.data.data == "Invalid authentication token provided") {
              enableSessionExpiryPopup();
            }
            else {
              let exportData1 = data.data.data[0];
              exportData1.forEach((details: any) => {

                let DateTime = details.MigratedDateTime;
                delete details.navTime;
                let migratedTime: any = DateTime.split(' ')[1];
                let migratedDate: any = DateTime.split(' ')[0];
                let millisecond = migratedTime.split('.')[0];
                let finalDateTime = migratedDate + ' ' + millisecond;
                details.MigratedDateTime = finalDateTime;
              });
              const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Export Details',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: "Report Details"
              };
              const csvExporter = new ExportToCsv(options);
              csvExporter.generateCsv(exportData1);
            }
          }

          else {
            let config = ({
              method: "POST",
              url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getReportLanding`,
              token: clientToken,
              data:
              {
                "type": "Export",
                "clientID": clientID,
                "chatType": null,
                "sortColumn": `${columnName}`,
                "sortType": `${columnOrder}`,
                "pageIndex": indexPerPage,
                "offset": offSet

              }
            })
            let loader = document.getElementById("loader");
            loader?.removeAttribute('hidden');
            let data: any = await gridLoad(config);
            loader?.setAttribute('hidden', 'hidden');
            if (data.data.data[0].length == 0) {
              setalertStatus("No Records Found in the Grid")
              enablebrowsealertpopup();
            }
            else if (data.data.data == "Invalid authentication token provided") {
              enableSessionExpiryPopup();
            }
            else {
              let exportData1: any = data.data.data[0];
              exportData1.forEach((details: any) => {

                let DateTime = details.MigratedDateTime;
                delete details.navTime;
                let migratedTime: any = DateTime.split(' ')[1];
                let migratedDate: any = DateTime.split(' ')[0];
                let millisecond = migratedTime.split('.')[0];
                let finalDateTime = migratedDate + ' ' + millisecond;
                details.MigratedDateTime = finalDateTime;

              });

              const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Export Details',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: "Report Details"
              };
              const csvExporter = new ExportToCsv(options);
              csvExporter.generateCsv(exportData1);
            }
          }
        }
        else {
          setalertStatus("Please perform Migration");
          enablebrowsealertpopup();

        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      setalertStatus("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
      enablebrowsealertpopup();
    }
  }

  const browsealertokclick = () => {
    try {
      if (isSessionEpired === false) {

        document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
        document.getElementById("wrongalert")?.setAttribute("style", "display:none");
        document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
        document.getElementById("wrongalert")?.removeAttribute("aria-modal");
        document.getElementById("wrongalert")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";

      }
    }
    catch (error) {
    }
  }

  const browsealertokclickAllReport = () => {
    try {
      window.location.pathname = "/Login";

    }
    catch (error) {
    }
  }

  return (
    <div className="row justify-content-center card-container mx-0 ms-0 overall-res-padding">
      {objClient != undefined && objClient.IsChannel != 0 && objClient.IsChat == 0 ?
        <div className="col-md-12  float-start px-3">
          <div className="row">
            <div className="col-md-12 float-start my-4 mt4">
              <label className=" font-26 font-bold">Report</label>
            </div>

            <div className="row status-border">
              <div className="report-resolution-css4 float-start">
                <div className="col-md-12 float-start mb-4">
                  <div className="float-start">
                    <div className="float-start">
                      <ul className="nav nav-pills" role="tablist">
                        <li className="nav-item">
                          <a className={(checkString == "Channel") ? activeTab : activeTab} data-bs-toggle="tab" id='Channel' onClick={() => setString("Channel")}>Channels</a>
                        </li>
                      </ul>
                    </div >
                  </div></div></div>
              <div className="report-resolution-css8 float-start ">
                <div className="float-end">
                  <div className="float-start space-high-res  my-2 mt-2">
                    <label className="float-start setting-heading3 font-bold font-16">Workspace :
                      {SettingsWorkspace.length > 15 ?
                        <span data-tip={SettingsWorkspace} className="ms-2 font-regular font-16">{SettingsWorkspace.substring(0, 15)}...</span> :
                        <span data-tip="" className="ms-2 font-regular font-16">{SettingsWorkspace}</span>
                      }
                    </label>
                  </div>
                  <div className=" float-start space-high-res  my-2 mt-2">
                    <label className="float-start setting-heading3 margin-space-expo font-bold font-16">Tenant Name :
                      {SettingsTenantname.length > 15 ?
                        <span data-tip={SettingsTenantname} className="ms-2 font-regular font-16">{SettingsTenantname.substring(0, 15)}...</span> :
                        <span data-tip="" className="ms-2 font-regular font-16">{SettingsTenantname}</span>
                      }
                      <span>
                        <ReactTooltip />
                      </span>
                    </label>
                  </div>
                  <div className=" float-start me-2">
                  </div>
                  <div className=" float-start">
                    <button type="button" className="btn mb-2 btn-inventory-export text-  float-end font-semibold font-16 btn-scondary-custom" onClick={handleExportBtn}><img src="Images/export-excel.svg" alt="export-excel" /> Export</button>
                  </div>
                </div>

              </div>

              <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                  <div className="modal-content content-margin">
                    <div className="modal-body">
                      <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                      <div className="text-center success-content w-100 mt-5">
                        <h5 className="font-semibold font-18">Alert!</h5>
                        <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                        <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
              <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position">
                  <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>
            </div>
            <div >
              {checkString == "Channel" ? <ChannelReport /> : <ChannelReport />}
            </div>
          </div>
        </div>

        : objClient != undefined && objClient.IsChannel == 0 && objClient.IsChat != 0 ?


          <div className="col-md-12  float-start px-3">
            <div className="row ">
              <div className="col-md-12 float-start my-4 mt-4">
                <label className=" font-26 font-bold">Report</label>
              </div>
              <div className="row status-border">

                <div className="col-md-6 float-start">
                  <div className="col-md-12 float-start mb-4">
                    <div className="float-start">
                      <div className="float-start">
                        <ul className="nav nav-pills" role="tablist">
                          <li className="nav-item">
                            <a className={(checkString == "All") ? activeTab : inActiveTab} id='All' data-bs-toggle="tab" onClick={() => setString("All")}>All</a>
                          </li>
                          <li className="nav-item">
                            <a className={(checkString == "Multi-Party Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Multi-Party Chats' onClick={() => setString("Multi-Party Chats")}>Multi-party Chats</a>
                          </li>
                          <li className="nav-item">
                            <a className={(checkString == "Direct Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Direct Chats' onClick={() => setString("Direct Chats")}>Direct Chats</a>
                          </li>
                        </ul>
                      </div >
                    </div></div></div>
                <div className="col-md-6 float-start ">
                  <div className="float-end">
                    <div className="float-start space-high-res my-2 mt-1">
                      <label className="float-start setting-heading3 font-bold font-16">Workspace :
                        {SettingsWorkspace.length > 15 ?
                          <span data-tip={SettingsWorkspace} className="ms-2 font-regular font-16">{SettingsWorkspace.substring(0, 15)}...</span> :
                          <span data-tip="" className="ms-2 font-regular font-16">{SettingsWorkspace}</span>
                        }
                      </label>
                    </div>
                    <div className=" float-start space-high-res my-2 mt-1">
                      <label className="float-start setting-heading3 margin-space-expo font-bold font-16">Tenant Name :
                        {SettingsTenantname.length > 15 ?
                          <span data-tip={SettingsTenantname} className="ms-2 font-regular font-16">{SettingsTenantname.substring(0, 15)}...</span> :
                          <span data-tip="" className="ms-2 font-regular font-16">{SettingsTenantname}</span>
                        }
                        <span>
                          <ReactTooltip />
                        </span>
                      </label>
                    </div>
                    <div className=" float-start me-2">
                    </div>
                    <div className=" float-start">
                      <button type="button" className="btn mb-2 btn-inventory-export export-margin-top-report text-  float-end font-semibold font-16 btn-scondary-custom" onClick={handleExportBtn}><img src="Images/export-excel.svg" alt="export-excel" /> Export</button>
                    </div>
                  </div>

                </div>

                <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
                  <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                      <div className="modal-body">
                        <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                        <div className="text-center success-content w-100 mt-5">
                          <h5 className="font-semibold font-18">Alert!</h5>
                          <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                          <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
                <div className="overlay" id='loader' hidden={true}>
                  <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div >
                {(checkString == "All" ? <AllReport />
                  :
                  (checkString == "Multi-Party Chats" ? <MMReport /> : <DMReport />))}
              </div>
            </div>
          </div>
          :
          objClient != undefined && objClient.IsChannel != 0 && objClient.IsChat != 0 ?

            <div className="col-md-12  float-start px-3">
              <div className="row">
                <div className="col-md-12 float-start my-4 mt-4">
                  <label className=" font-26 font-bold">Report</label>
                </div>
                <div className="row status-border">

                  <div className="col-md-12 col-xl-6 col-lg-12 col-sm-12 float-start">
                    <div className="col-md-12 float-start mb-4">
                      <div className="float-start">
                        <div className="float-start">
                          <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                              <a className={(checkString == "All") ? activeTab : inActiveTab} id='All' data-bs-toggle="tab" onClick={() => setString("All")}>All</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Channel") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Channel' onClick={() => setString("Channel")}>Channels</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Multi-Party Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Multi-Party Chats' onClick={() => setString("Multi-Party Chats")}>Multi-party Chats</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Direct Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Direct Chats' onClick={() => setString("Direct Chats")}>Direct Chats</a>
                            </li>
                          </ul>
                        </div >
                      </div></div></div>
                  <div className="col-md-12 col-xl-6 col-lg-12 col-sm-12 col-12 float-start ">
                    <div className="report-flex-proper">
                      <div className="float-start space-high-res  my-2 mt-1">
                        <label className="float-start setting-heading3 font-bold font-16">Workspace :
                          {SettingsWorkspace.length > 15 ?
                            <span data-tip={SettingsWorkspace} className="ms-2 font-regular font-16">{SettingsWorkspace.substring(0, 15)}...</span> :
                            <span data-tip="" className="ms-2 font-regular font-16">{SettingsWorkspace}</span>
                          }
                        </label>
                      </div>
                      <div className=" float-start space-high-res  my-2 mt-1">
                        <label className="float-start setting-heading3 margin-space-expo font-bold font-16">Tenant Name :
                          {SettingsTenantname.length > 15 ?
                            <span data-tip={SettingsTenantname} className="ms-2 font-regular font-16">{SettingsTenantname.substring(0, 15)}...</span> :
                            <span data-tip="" className="ms-2 font-regular font-16">{SettingsTenantname}</span>
                          }
                          <label>
                            <ReactTooltip />
                          </label>
                        </label>
                      </div>
                      <div className=" float-start">
                        <button type="button" className="btn mb-2 btn-inventory-export text-  float-end font-semibold font-16 btn-scondary-custom" onClick={handleExportBtn}><img src="Images/export-excel.svg" alt="export-excel" /> Export</button>
                      </div>
                    </div>
                  </div>


                  <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content content-margin">
                        <div className="modal-body">
                          <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                          <div className="text-center success-content w-100 mt-5">
                            <h5 className="font-semibold font-18">Alert!</h5>
                            <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                            <button type="button" className="btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal fade" id="wrongalertAllReport" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content content-margin">
                        <div className="modal-body">
                          <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                          <div className="text-center success-content w-100 mt-5">
                            <h5 className="font-semibold font-18">Alert!</h5>
                            <p className="success-text w-100 text-center mb-4">{"Your session has expired. Please log in again to continue using the application."}</p>
                            <button type="button" className="btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclickAllReport}>Ok</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
                  <div className="overlay" id='loader' hidden={true}>
                    <div className="Loader-Content-position">
                      <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                        <span className="visually-hidden"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div >
                  {
                    (checkString == "All") ?
                      <AllReport />
                      : (checkString == "Channel"
                        ? <ChannelReport />
                        : (checkString == "Multi-Party Chats"
                          ? <MMReport />
                          : <DMReport />
                        )
                      )
                  }
                </div>
              </div>
            </div>
            :
            <div className="col-md-12  float-start px-3">
              <div className="row">
                <div className="col-md-12 float-start my-4 mt-4">
                  <label className=" font-26 font-bold">Report</label>
                </div>
                <div className="row status-border">

                  <div className="report-resolution-css4 float-start">
                    <div className="col-md-12 float-start mb-4">
                      <div className="float-start">
                        <div className="float-start">
                          <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                              <a className={(checkString == "All") ? activeTab : inActiveTab} id='All' data-bs-toggle="tab" onClick={() => setString("All")}>All</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Channel") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Channel' onClick={() => setString("Channel")}>Channels</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Multi-Party Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Multi-Party Chats' onClick={() => setString("Multi-Party Chats")}>Multi-party Chats</a>
                            </li>
                            <li className="nav-item">
                              <a className={(checkString == "Direct Chats") ? activeTab : inActiveTab} data-bs-toggle="tab" id='Direct Chats' onClick={() => setString("Direct Chats")}>Direct Chats</a>
                            </li>
                          </ul>
                        </div >
                      </div></div></div>
                  <div className="report-resolution-css8 float-start ">
                    <div className="float-end">
                      <div className="float-start space-high-res my-2 mt-1">
                        <label className="float-start setting-heading3 font-bold font-16">Workspace :
                          {SettingsWorkspace.length > 15 ?
                            <span data-tip={SettingsWorkspace} className="ms-2 font-regular font-16">{SettingsWorkspace.substring(0, 15)}...</span> :
                            <span data-tip="" className="ms-2 font-regular font-16">{SettingsWorkspace}</span>
                          }
                        </label>
                      </div>
                      <div className=" float-start space-high-res  my-2 mt-1">
                        <label className="float-start setting-heading3 margin-space-expo font-bold font-16">Tenant Name :
                          {SettingsTenantname.length > 15 ?
                            <span data-tip={SettingsTenantname} className="ms-2 font-regular font-16">{SettingsTenantname.substring(0, 15)}...</span> :
                            <span data-tip="" className="ms-2 font-regular font-16">{SettingsTenantname}</span>
                          }
                          <span>
                            <ReactTooltip />
                          </span>
                        </label>
                      </div>
                      <div className=" float-start me-2">
                      </div>
                      <div className=" float-start">
                        <button type="button" className="btn mb-2 btn-inventory-export text-  float-end font-semibold font-16 btn-scondary-custom" onClick={handleExportBtn}><img src="Images/export-excel.svg" alt="export-excel" /> Export</button>
                      </div>
                    </div>

                  </div>

                  <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content content-margin">
                        <div className="modal-body">
                          <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                          <div className="text-center success-content w-100 mt-5">
                            <h5 className="font-semibold font-18">Alert!</h5>
                            <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                            <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
                  <div className="overlay" id='loader' hidden={true}>
                    <div className="Loader-Content-position">
                      <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                        <span className="visually-hidden"></span>
                      </div>
                    </div>
                  </div>
                  <ReactTooltip globalEventOff="click" multiline={true} backgroundColor='black' textColor='white' border={true} borderColor='black' />
                </div>
                <div >
                  {
                    (checkString == "All") ?
                      <AllReport />
                      : (checkString == "Channel"
                        ? <ChannelReport />
                        : (checkString == "Multi-Party Chats"
                          ? <MMReport />
                          : <DMReport />
                        )
                      )
                  }
                </div>
              </div>
            </div>}
    </div>

  );
}
