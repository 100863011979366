import React, { useEffect, useRef, useState } from 'react';
import { ExportToCsv } from 'export-to-csv';
import { config } from 'process';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { gridLoad } from '../../Services/UserMappingServices';
import ReactTooltip from "react-tooltip";
import * as XLSX from 'xlsx';
import Enumerable, { from } from "node-enumerable";
import constants from '../../Common/Constants';




import objCryptoJS from 'crypto-js'
import JWT from 'jwt-decode'

export function LicensedUsers() {
    const inputFile: any = useRef(null);
    let GridLoadarray: any[] = [];
    const [gridData, setGridData] = useState(GridLoadarray);
    const [tmpsearchValue, settmpSearchValue] = useState("");
    let [searchValue, setSearchValue] = useState("");
    let [exportsearchValue, setexportsearchValue] = useState("");
    let [LicenseStatus, setLicenseStatus] = useState(null);


    const [columnOrder, setColumnOrder] = useState("asc");
    const [columnName, setColumnName] = useState("SourceUserName");
    const [sorthide, setsorthide] = useState("");
    let [token, settoken] = useState("");
    let [ErrorMessage, setErrorMessage] = useState("");

    let selectedCheckbox: any = [];
    const [selectAll, setSelectAll] = useState(false);
    let [selectedCheckBoxArray, setSelectedCheckBoxArray] = useState(selectedCheckbox);
    let [status, setstatus] = useState("Get");
    const [TotalSlackUserCount, setTotalSlackUserCount] = useState(0);
    const [AssignedLicenseCount, setAssignedLicenseCount] = useState(0);
    const [UnAssignedLicenseCount, setUnAssignedLicenseCount] = useState(0);
    let [checkAssignlicensetypeselection, setcheckAssignlicensetypeselection] = useState(false);
    let [checkUnassignlicensetypeselection, setcheckUnassignlicensetypeselection] = useState(false);
    let [usersLimit, setusersLimit] = useState(0);
    let array: any = [];
    let [Chatdetails, setChatdetails] = useState(array);
    let [tempGridData, settempGridData] = useState(array);
    let local: any = localStorage.getItem("cid_t");

    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    if (local != undefined) {

        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = JWT(decryptedValue);
        token = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;


    }


    useEffect(() => {
        resetSessionTimer();
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);

        let removeFooter: any = document.getElementById("MainContainerMFE");
        removeFooter.classList.add("container-bg-withoutFooter")
        let AssignLicense = document.getElementById("AssignLicense")
        AssignLicense?.setAttribute('disabled', 'disabled');
        let UnassignLicense = document.getElementById("UnassignLicense")
        UnassignLicense?.setAttribute('disabled', 'disabled');
        loadGridPage();

        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [columnName, columnOrder])


    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            ErrorMessage = "Your session has expired. Please log in again to continue using the application."
            setErrorMessage(ErrorMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (token != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: token
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = JWT(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    token = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")

            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    const exportLicensedUsersbtn = async () => {
        try {
            let a: any = document.getElementById("loader");
            a.removeAttribute("hidden");
            let config = (
                {
                    method: "POST",
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/LicenseUser/ExportLicensedUsers`,
                    token: token,

                    data:
                    {
                        "type": "Export"
                    }
                }
            )
            let data: any = await gridLoad(config);
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")

                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }
            else if (data.data.data == "no records were fetched") {
                ErrorMessage = "No Records Found in the Grid";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
            else {
                let exportData = data.data.data;
                const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: false,
                    title: 'Slack User Details',
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true,
                    filename: "Slack User Details"
                    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                };
                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(exportData);
            }
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
        }
        catch (error: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const loadGridPage = async () => {
        try {
            let loader: any = document.getElementById("loader");
            loader.removeAttribute("hidden");
            exportsearchValue = searchValue;
            setexportsearchValue(exportsearchValue);
            let config = (
                {
                    method: "POST",
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/LicenseUser/getLicensedUsers`,
                    token: token,

                    data:
                    {
                        "type": status,
                        "sortColumn": `${columnName}`,
                        "searchText": `${exportsearchValue}`,
                        "sortType": `${columnOrder}`,
                        "LicenseStatus": LicenseStatus,
                        "sourceUsersList": selectedCheckBoxArray
                    }
                }
            )

            let data: any = await gridLoad(config);
            setSelectAll(false);
            loader?.setAttribute('hidden', 'hidden');
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")

                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }
            else {
                if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data == "no records found") {
                    setGridData(["No Records Found"])
                }
                else if (data.data.data === "License Updated Successfully") {
                    selectedCheckBoxArray = [];
                    setSelectedCheckBoxArray(selectedCheckBoxArray);
                    enablesuccesspopup();
                    setSelectAll(false);
                }
                else if (data.data.data === "License Revoked Successfully") {
                    selectedCheckBoxArray = [];
                    setSelectedCheckBoxArray(selectedCheckBoxArray);
                    status = "Get";
                    setstatus(status);
                    setSelectAll(false);
                    loadGridPage();
                    
                }
                else if (data.data.data == "Users Count Exceeds the License Limit") {
                    // createDynamicListItem1("Failed to proceed complete User Mapping due to the below reasons.", ["Invalid Slack or MS Teams User Details provided.", "Duplicate Slack User Mapping Found."])
                    // enableinvalidrecordspopup();
                    //#region Show PopUp
                    setusersLimit(data.data.remainingLicenseCount)
                    document.getElementById("licenseExceedAlertPopup")?.removeAttribute("aria-hidden");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("aria-modal", "true");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("role", "dialog");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("class", "modal fade show");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden")
                    //#endregion


                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {

                    setGridData(data.data.data[0]);
                    // setGridData((gridData) =>
                    //     gridData.map((row) => ({ ...row, checked: selectAll }))
                    // );
                    setTotalSlackUserCount(data.data.data[1][0].SlackUsersCount);
                    setAssignedLicenseCount(data.data.data[2][0].AssignedCount);
                    setUnAssignedLicenseCount(data.data.data[3][0].UnassignedCount);

                }
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.setAttribute('disabled', 'disabled');
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.setAttribute('disabled', 'disabled');

            }
        }
        catch (error: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const UpdateLicensedandUnLicensedUsersbtn = (e: any) => {
        try {
            if (e.target.id == "RevokeLicense") {
                status = 'RevokeLicense';
                document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("class", "modal fade");
                document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("style", "display:none");
                document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("aria-hidden", "true");
                document.getElementById("RevokeLicenseConfirmationAlert")?.removeAttribute("aria-modal");
                document.getElementById("RevokeLicenseConfirmationAlert")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                status = 'statusUpdate';
            }
            setstatus(status);
            loadGridPage();
        } catch (c) {

        }
    }
    const handleHeaderCheckboxChange = (e: any) => {
        try {
            if (selectAll == false) {
                setSelectAll(true);
            }
            else {
                setSelectAll(false);
            }
            setGridData((gridData) =>
                gridData.map((row) => ({ ...row, checked: (row.LicensedStatus !== "Restricted") ? !selectAll : false }))
            );
            // setGridData((selectedCheckBoxArray) =>
            //     selectedCheckBoxArray.map((row) => ({ ...row, SourceUserID: !selectAll }))
            // );
            if (e.target.checked) {
                let validCheckedArrayData = Enumerable.from(gridData).where(a => a.LicensedStatus !== "Restricted").toArray();

                selectedCheckBoxArray = validCheckedArrayData.map((obj) => ({ SourceUserID: obj.SourceUserID, LicensedStatus: obj.LicensedStatus }));
                setSelectedCheckBoxArray(selectedCheckBoxArray);
            }
            else {
                selectedCheckBoxArray = [];
                setSelectedCheckBoxArray(selectedCheckBoxArray);
            }

            checkAssignlicensetypeselection = Enumerable.from(selectedCheckBoxArray).where(a =>
                'LicensedStatus' in a && (a.LicensedStatus === "Assigned")).any();

            checkUnassignlicensetypeselection = Enumerable.from(selectedCheckBoxArray).where(a =>
                'LicensedStatus' in a && (a.LicensedStatus === "Unassigned" || a.LicensedStatus === null)).any();

            if ((checkAssignlicensetypeselection && checkUnassignlicensetypeselection) || (!checkAssignlicensetypeselection && !checkUnassignlicensetypeselection)) {
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.setAttribute('disabled', 'disabled');
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.setAttribute('disabled', 'disabled');
            }
            else if (checkAssignlicensetypeselection) {
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.removeAttribute('disabled');
            }
            else if (checkUnassignlicensetypeselection) {
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.removeAttribute('disabled');
            }
        } catch (error: any) {

        }
    };
    const handlecheckbox = async (e: any) => {


        try {
            let id = e.target.id
            let objState = JSON.parse(id);
            setSelectAll(false);


            setGridData((gridData) =>
                gridData.map((row) =>
                    row.SourceUserName === e.target.name ? { ...row, checked: !row.checked } : row
                )
            );
            if (e.target.checked) {
                let mappingObject = {
                    "SourceUserID": objState.SourceUserID,
                    "LicensedStatus": objState.LicensedStatus
                };
                let tempArr: any = [...selectedCheckBoxArray, mappingObject]
                selectedCheckBoxArray = tempArr
                setSelectedCheckBoxArray(selectedCheckBoxArray)
            }


            else {
                let ind = selectedCheckBoxArray.findIndex((obj: any) => obj.SourceUserID == e.target.value)
                selectedCheckBoxArray.splice(ind, 1);


            }

            checkAssignlicensetypeselection = Enumerable.from(selectedCheckBoxArray).where(a =>
                'LicensedStatus' in a && (a.LicensedStatus === "Assigned")).any();

            checkUnassignlicensetypeselection = Enumerable.from(selectedCheckBoxArray).where(a =>
                'LicensedStatus' in a && (a.LicensedStatus === "Unassigned" || a.LicensedStatus === null)).any();

            if ((checkAssignlicensetypeselection && checkUnassignlicensetypeselection) || (!checkAssignlicensetypeselection && !checkUnassignlicensetypeselection)) {
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.setAttribute('disabled', 'disabled');
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.setAttribute('disabled', 'disabled');
            }
            else if (checkAssignlicensetypeselection) {
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.removeAttribute('disabled');
            }
            else if (checkUnassignlicensetypeselection) {
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.removeAttribute('disabled');
            }

        }
        catch (error: any) {

            // console.log("error", error);
        }
    }
    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
                return gridData.map((obj, index) => {
                    let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let objlicenseStatus: any = (obj.LicensedStatus === 'Restricted' || obj.LicensedStatus === 'Assigned') ? 'Assigned' : 'Unassigned';
                    // console.log(objlicenseStatus)
                    return (
                        <tr className={rowClass}>
                            {(obj.SourceUserName).length > 12 ?
                                <td data-tip={obj.SourceUserName} className="customTablepadding"><input type="checkbox" className="form-check-input me-2" disabled={obj.LicensedStatus === 'Restricted' ? true : false} name={obj.SourceUserName} checked={obj.checked} id={JSON.stringify(obj)} value={obj.SourceUserID} onChange={(e) => handlecheckbox(e)}></input>{obj.SourceUserName.substring(0, 12)}...</td> :
                                <td className=" customTablepadding"><input type="checkbox" className="form-check-input me-2" disabled={obj.LicensedStatus === 'Restricted' ? true : false} name={obj.SourceUserName} checked={obj.checked} id={JSON.stringify(obj)} value={obj.SourceUserID} onChange={(e) => handlecheckbox(e)}></input>{obj.SourceUserName}</td>}
                            {(obj.SourceUserMailID).length > 18 ?
                                <td data-tip={obj.SourceUserMailID} className="customTablepadding">{obj.SourceUserMailID.substring(0, 18)}...</td> :
                                <td className=" customTablepadding">{obj.SourceUserMailID}</td>}
                            <td className=" customTablepadding text-center">
                                <span className={(obj.LicensedStatus == "Unassigned" || obj.LicensedStatus == null) ? "status-unassigned status-block font-14" : "success-status status-block font-14"}>{objlicenseStatus}</span></td>
                            <span>
                                <ReactTooltip />
                            </span>
                        </tr>
                    )
                }
                )
            }
            else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
                return (<tr> <td className="centertext" colSpan={3}></td>  </tr>)
            }
            else {
                return (
                    <tr>
                        <td className="aligncenter" colSpan={3}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
                            <p className="mt-2">No records found</p></td>
                    </tr>)
            }
        }
        catch (error: any) {
            // console.log(error)
        }
    }
    const handleSearch = (e: any) => {
        try {
            setSearchValue(e.target.value)
        }
        catch (error: any) {
        }
    }
    const sortClicked = (e: any) => {
        try {
            let sortID = e.target.id;
            let sortval = sortID.split('@');
            setColumnName(sortval[0])
            setColumnOrder(sortval[1])
            setsorthide(sortID)
            if (Chatdetails.length > 0) {
                let arr: any[];
                if (columnOrder == "desc") {

                    arr = gridData.sort(function (a: any, b: any) {

                        var textA = a[columnName].toUpperCase();

                        var textB = b[columnName].toUpperCase();

                        return textA == textB ? 0 : textA < textB ? 1 : -1;

                    });

                }

                else {

                    arr = gridData.sort(function (a: any, b: any) {

                        var textA = a[columnName].toUpperCase();

                        var textB = b[columnName].toUpperCase();

                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

                    });

                }
                setGridData([]);
                if (arr.length > 0) {
                    setGridData(arr);
                }
                else {
                    setGridData(["No Records Found"])

                }
            }
        }
        catch (error: any) {
        }
    }
    const licenseTypeSelectBtn = (e: any) => {
        try {
            LicenseStatus = (e.target.value === "All") ? null : e.target.value;
            setLicenseStatus(LicenseStatus);
            if (Chatdetails.length == 0) {
                loadGridPage();
            }
            else {
                let newarr = tempGridData;
                if (e.target.value != "All") {
                    newarr = tempGridData.filter((x: any) => x.LicensedStatus == e.target.value);
                }
                setGridData([]);
                if (newarr.length > 0) {
                    setGridData(newarr);
                }
                else {
                    setGridData(["No Records Found"])
                }
            }
            setSelectAll(false);
        } catch (error) {

        }
    }
    const handleKeyDown = (e: any) => {
        try {
            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {

                if (searchValue !== tmpsearchValue) {
                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    settmpSearchValue(searchValue);
                    if (Chatdetails.length == 0) {
                        gridData.length = 0;
                        loadGridPage();
                    }
                    else {
                        handleSearchinFrontEnd();
                    }
                }
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const handleSearchinFrontEnd = () => {
        try {
            let newarr: any = tempGridData.filter((x: any) => x.SourceUserName.toLowerCase().includes(exportsearchValue.toLowerCase()) ||
                x.SourceUserMailID.toLowerCase().includes(exportsearchValue.toLowerCase()));

            setGridData([]);
            if (newarr.length > 0) {
                setGridData(newarr);
            }
            else {
                setGridData(["No Records Found"])

            }

        } catch (error) {

        }
    }
    const handleSearchIcon = (e: any) => {
        try {
            if (searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    settmpSearchValue(searchValue);
                    if (Chatdetails.length == 0) {
                        gridData.length = 0;
                        loadGridPage();
                    }
                    else {
                        handleSearchinFrontEnd();
                    }
                }
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const handleFileUpload = async (e: any) => {
        try {
            const { files } = e.target;
            if (files && files.length) {
                const filename = files[0].name;
                var parts = filename.split(".");
                const fileType = parts[parts.length - 1];
            }
            //const file = image;
            if (files[0] != undefined && (files[0].name.includes('.csv'))) {
                const reader = new FileReader();
                reader.onload = (evt: any) => {
                    /* Parse data */
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary', raw: true });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];

                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header:
                            ["SlackUserName", "SlackUserMailID", "********"], defval: ""
                    });
                    
                    Chatdetails = removeDuplicates(data, "SlackUserMailID")
                    
                    if (Chatdetails[0].SlackUserName == "SlackUserName" || Chatdetails[0].SlackUserMailID == "SlackUserMailID" ||
                        Object.keys(Chatdetails[0]).length > 2 || Object.keys(Chatdetails[0]).length < 2) {
                        // enablealertpopup();
                        // setalertstatus('Please provide valid template!')
                        enableAlerPopup()

                    }
                    else if (Chatdetails.length == 1) {
                        // setalertstatus('Empty Template provided!');
                        // enablealertpopup();
                        enableAlerPopup()
                    }
                    else {
                        Chatdetails.shift();
                        setChatdetails(Chatdetails);
                        validatecsv()
                    }
                };
                reader.readAsBinaryString(files[0]);
            }
            else {

                // setalertstatus("Please provide valid template!")
                // enablealertpopup();
            }
        }
        catch (error) {
        }
    }

    // function to Remove Duplicates
  function removeDuplicates(array:any, key:any) {
    const uniqueMap = new Map();
  
    array.forEach((item: { [x: string]: any; }) => {
      const keyValue = item[key];
      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, item);
      }
    });
  
    const uniqueArray = Array.from(uniqueMap.values());
  
    return uniqueArray;
  }

    const validatecsv = async () => {
        try {
            if (token != undefined) {
                let AssignLicense = document.getElementById("AssignLicense")
                AssignLicense?.setAttribute('disabled', 'disabled');
                let UnassignLicense = document.getElementById("UnassignLicense")
                UnassignLicense?.setAttribute('disabled', 'disabled');
                let config = ({
                    token: token,
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/LicenseUser/ValidateSlackUsers`,
                    data: {

                        "csv": JSON.stringify(Chatdetails),
                        "type": "ValidateUsers"
                    }
                })
                let a: any = document.getElementById("loader");
                a.removeAttribute("hidden");
                let data: any = await gridLoad(config);
                setSelectAll(false);
                a?.setAttribute('hidden', 'hidden');
                // setMigrationGridData(data.data.data) 
                // setString("2");
                if (data.data.statusCode == 200) {
                    setGridData(data.data.data);
                    // setGridData((gridData) =>
                    //     gridData.map((row) => ({ ...row, checked: selectAll }))
                    // );
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.data == "Users Count Exceeds the License Limit") {
                    // createDynamicListItem1("Failed to proceed complete User Mapping due to the below reasons.", ["Invalid Slack or MS Teams User Details provided.", "Duplicate Slack User Mapping Found."])
                    // enableinvalidrecordspopup();
                    //#region Show PopUp
                    document.getElementById("licenseExceedAlertPopup")?.removeAttribute("aria-hidden");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("aria-modal", "true");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("role", "dialog");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("class", "modal fade show");
                    document.getElementById("licenseExceedAlertPopup")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden")
                    //#endregion


                }
                else if ((data.data.data).length > 0 && data.data.statusText == "Bad Request") {
                    // createDynamicListItem1("Failed to proceed complete User Mapping due to the below reasons.", ["Invalid Slack or MS Teams User Details provided.", "Duplicate Slack User Mapping Found."])
                    // enableinvalidrecordspopup();
                    ErrorMessage = "Please provide valid Slack user details."
                    setErrorMessage(ErrorMessage);
                    //#region Show PopUp
                    document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden")
                    //#endregion


                }
                tempGridData = data.data.data;
                settempGridData(tempGridData);
                selectedCheckBoxArray = [];
                setSelectedCheckBoxArray(selectedCheckBoxArray);
            }
            else {
                localStorage.clear();
            }
        }
        catch (error) {
            //   setalertstatus('Something went wrong!Please try again or reach AVAMIGRATRON Support.')
            //   enablealertpopup();

        }
    }
    const licenseRevokeConfirmationPopup = () => {
        try {
            //#region Show PopUp
            document.getElementById("RevokeLicenseConfirmationAlert")?.removeAttribute("aria-hidden");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("aria-modal", "true");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("role", "dialog");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("class", "modal fade show");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            //#endregion
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const hideRevokeConfirmationPopup = () => {
        try {
            //#region hide PopUp
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("class", "modal fade");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("style", "display:none");
            document.getElementById("RevokeLicenseConfirmationAlert")?.setAttribute("aria-hidden", "true");
            document.getElementById("RevokeLicenseConfirmationAlert")?.removeAttribute("aria-modal");
            document.getElementById("RevokeLicenseConfirmationAlert")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            //#endregion
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const enablesuccesspopup = () => {
        try {
            document.getElementById("success")?.removeAttribute("aria-hidden");
            document.getElementById("success")?.setAttribute("aria-modal", "true");
            document.getElementById("success")?.setAttribute("role", "dialog");
            document.getElementById("success")?.setAttribute("class", "modal fade show");
            document.getElementById("success")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        catch (error) {
        }
    }
    //#region to enable alert popup
    const enableAlerPopup = () => {
        try {

            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("please provide valid template!");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");

        }
        catch (error) {
        }
    }
    //#endregion
    const hidePopup = () => {
        try {
            document.getElementById("licenseExceedAlertPopup")?.setAttribute("class", "modal fade");
            document.getElementById("licenseExceedAlertPopup")?.setAttribute("style", "display:none");
            document.getElementById("licenseExceedAlertPopup")?.setAttribute("aria-hidden", "true");
            document.getElementById("licenseExceedAlertPopup")?.removeAttribute("aria-modal");
            document.getElementById("licenseExceedAlertPopup")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        }
        catch (error) {
        }
    }
    const hideAlertPopup = () => {
        try {
            if (isSessionEpired === false) {
                if (ErrorMessage == "Please provide valid Slack user details.") {
                    let exportData = tempGridData;
                    const options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        showTitle: false,
                        title: ' Invalid Slack User Details',
                        useTextFile: false,
                        useBom: true,
                        useKeysAsHeaders: true,
                        filename: "Invalid Slack User Details"
                        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                    };
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(exportData);
                }


                document.getElementById("alertPopUp")?.setAttribute("class", "modal fade");
                document.getElementById("alertPopUp")?.setAttribute("style", "display:none");
                document.getElementById("alertPopUp")?.setAttribute("aria-hidden", "true");
                document.getElementById("alertPopUp")?.removeAttribute("aria-modal");
                document.getElementById("alertPopUp")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            }
            else {
                window.location.pathname = "/Login";
            }
        }
        catch (error) {
        }
    }
    const navigateToInventory = () => {
        try {
            window.location.pathname = "/Inventory/HighLevel";
        } catch (error) {

        }
    }
    const navigateToUpgradeplan = () => {
        try {
            window.location.pathname = "/UpgradePlan";
        } catch (error) {

        }
    }
    const onButtonClick = async () => {
        try {


            await inputFile.current.click();

        } catch (error) {
            //   setalertstatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
            //   enablealertpopup();
        }

    };
    return (
        <div className="container-fluid">
            <div className="row justify-content-center my-5">
                <div className="custom-col-md-11">
                    <h4 className="font-24 font-bold mb-4 mt-5">License Assignment</h4>
                    <p className="p-3 rounded-2 mb-4 info-background-color font-14 font-regular d-flex align-items-center">
                        <img
                            src="/Images/info-icon.svg"
                            alt="info-icon"
                            className="slack-icon pe-2"
                        />
                        If you have initiated the migration for assigned users, you cannot
                        reassign the license to other users.
                    </p>
                    <div className="d-flex justify-content-between mb-3 flex-lg-col ">
                        <div className="d-flex align-items-center mb-2 mb-xl-0">
                            <label className="font-14 font-semibold  me-4  text-nowrap">
                                Assigned Users :{""}
                                <span className="font-14 font-regular ms-2 ">{AssignedLicenseCount}</span>
                            </label>
                            <label className="font-14 font-semibold text-nowrap">
                                Unassigned Users :{""}
                                <span className="font-14 font-regular ms-2 ">{UnAssignedLicenseCount}</span>
                            </label>
                        </div>
                        <div className="d-md-flex d-grid gap-2 flex-md-col ms-0 ms-xl-3">
                            <div className="me-lg-2 me-md-0 mb-2 mb-lg-0 d-flex align-items-center ">
                                <label className="font-14 font-semibold text-nowrap me-2">
                                    License Status
                                </label>
                                <select className="form-select py-2 me-0 me-lg-2 custom-selectLicAssign " onChange={(e) => licenseTypeSelectBtn(e)}>
                                    <option id='All'>All</option>
                                    <option id='Assigned'>Assigned</option>
                                    <option id='Unassigned'>Unassigned</option>
                                </select>
                            </div>
                            <div className="input-group box-shodow-filter w-auto me-lg-2 me-md-0 mb-2 mb-lg-0 d-flex">
                                <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                                <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={handleSearchIcon}><img src='/Images/search.svg' alt="search-icon" />
                                </button>
                            </div>
                            <button type="button" className="common-btn-class btn btn-user-mapping btn-primary-theme my-0 font-semibold" onClick={onButtonClick}  >
                                <span className="me-2" >
                                    <img src="/Images/import.svg" alt="import" />
                                </span>Import Users list
                                <input style={{ display: "none" }} ref={inputFile} onChange={(e) => handleFileUpload(e)} id="uploadbtn" type="file" ></input>
                            </button>
                            <button onClick={exportLicensedUsersbtn}
                                type="button"
                                className="btn btn-prime font-14 font-semibold text-nowrap "
                            >
                                <span className="me-2">
                                    <img src="/Images/export-excel-white.svg" alt="export-excel" />
                                </span>
                                Export Users list{" "}
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2 ">
                        <div className="table-responsive table-border license-grid">
                            <table className="table">
                                <thead className="tabel-head-bg sticky-top bg-white">
                                    <tr>

                                        <th className="table-headlicense font-14 font-bold text-nowrap" ><input
                                            className="form-check-input me-2" type="checkbox" checked={selectAll}
                                            onChange={(e) => handleHeaderCheckboxChange(e)} /><span className='font-16'>Slack User Name</span>
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="sort-arrow" id="SourceUserName@desc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "SourceUserName@desc" ? true : false} />
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserName@asc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "SourceUserName@desc" ? false : true} />
                                        </th>
                                        <th className="table-headlicense font-14 font-bold text-nowrap"> Slack User Email Address
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="sort-arrow" id="SourceUserMailID@desc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "SourceUserMailID@desc" ? true : false} />
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserMailID@asc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "SourceUserMailID@desc" ? false : true} />
                                        </th>
                                        <th className="table-headlicense font-14 font-bold text-center text-nowrap"> License Status
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="sort-arrow" id="LicensedStatus@desc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "LicensedStatus@desc" ? true : false} />
                                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="LicensedStatus@asc" onClick={e => sortClicked(e)}
                                                hidden={sorthide == "LicensedStatus@desc" ? false : true} />
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {gridBodyData()}
                                </tbody>
                            </table>
                        </div>
                        <p className="font-16 font-semibold mt-4 mb-5">
                            Total # of slack users :{" "}<span className="font-regular">{TotalSlackUserCount}</span>
                        </p>
                        {/* channel list table ends here */}
                    </div>
                </div>
            </div>


            <div className="modal fade" id="RevokeLicenseConfirmationAlert" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 pt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">Are you sure want to revoke license for the selected users?</p>
                                <div id="dynamicData"></div>
                                <button type="button" className="Revokenocustomcss common-btn-class btn-scondary-custom  btn btn-admin-second font-16 font-regular me-2 btn-scondary-custom" data-bs-dismiss="modal" id="RevokeLicense" onClick={hideRevokeConfirmationPopup}>No</button>
                                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme " data-bs-dismiss="modal" id="RevokeLicense" onClick={(e) => UpdateLicensedandUnLicensedUsersbtn(e)}>yes</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/success.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 pt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">License has been assigned successfully You may now proceed with inventory.</p>
                                <button type="button" id="okbtn" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={navigateToInventory}>Go to Inventory</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="licenseExceedAlertPopup" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 pt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">You've selected more than {usersLimit} users which exceeds the limit allowed by your current plan. Please upgrade your plan</p>
                                <div id="dynamicData"></div>
                                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme canclcustomcss" data-bs-dismiss="modal" id="RevokeLicense" onClick={hidePopup}>Cancel</button>
                                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" id="RevokeLicense" onClick={navigateToUpgradeplan}>Upgrade Plan</button>                                </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 pt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                                <div id="dynamicData"></div>
                                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme canclcustomcss" data-bs-dismiss="modal" id="RevokeLicense" onClick={hideAlertPopup}>ok</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">

                <div className=" mx-3 d-flex justify-content-end">

                    <button type="button" className="Revokenocustomcss btn-cancel-border common-btn-class btn-scondary-custom  btn btn-admin-second font-16 font-regular me-2 btn-scondary-custom" id="UnassignLicense" onClick={licenseRevokeConfirmationPopup}>Revoke License </button>
                    <button type="button" className="btn-primary-theme common-btn-class btn btn-licensefooter font-16 font-regular" id="AssignLicense" onClick={(e) => UpdateLicensedandUnLicensedUsersbtn(e)}>Assign License </button>
                    {/* <button type="button" id="btnScheduleMigration" className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular me-2" onClick={enableReschedulePopup}>Schedule Migration</button> */}
                    {/* <button type="button" id="btnStartMigration" className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular" onClick={startMigration}>Start Migration</button> */}

                </div>

            </footer>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                    <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                    {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
        </div>

    );
}   