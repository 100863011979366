import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/InventoryServices';
import { HighLevelInventory } from './HighLevelInventory';
import constants  from '../../Common/Constants';
import objCryptoJS from 'crypto-js';
import jwt from 'jwt-decode';


export function LicenseVal() {

    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let clientID = 0;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientToken: any;
    // let boolInvalidSheet: any = false;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    if (local != undefined) {

        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);

        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);

        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

         

    }


    let [alertMessage1, setAlertMessage1] = useState("");
    let [alertMessage, setAlertMessage] = useState("Failed to submit request please try again later.");
    let [loaderText, setLoaderText] = useState("");


    useEffect(() => {
        resetSessionTimer();

        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };

    }, [])

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage1 = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage1(alertMessage1);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            alertMessage1 = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setAlertMessage1(alertMessage1);
            let popup = document.getElementById("alertPopUp")
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")

            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            alertMessage1 = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setAlertMessage1(alertMessage1);
            let popup = document.getElementById("alertPopUp")
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion



    const popUpOkClick = async (type: string) => {
        try {
            if (isSessionEpired === false) {
                //#region Hide PopUp
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : (type == "success") ? document.getElementById("successPopUp") : document.getElementById("confirmationPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                //#endregion

                //#region Send Request to Node
                if (type == "sendRequest") {
                    let loader = document.getElementById("loader")
                    loader?.removeAttribute('hidden');
                    if (clientID != undefined && clientToken != undefined) {
                        let config = ({
                            method: "POST",
                            token: clientToken,
                            url: `${baseURL}/admin/submitTrailRequest`,
                            data: {
                                "clientID": Number(clientID),
                                "type": "Trail"
                            },
                        }
                        )
                        let data: any = await gridLoad(config);
                        ////console.log(data);
                        loader?.setAttribute('hidden', 'hidden');
                        if (data == undefined || data.data.statusCode != 200) {
                            let alertPopUp = document.getElementById("alertPopUp");
                            alertPopUp?.removeAttribute("aria-hidden");
                            alertPopUp?.setAttribute("aria-modal", "true");
                            alertPopUp?.setAttribute("role", "dialog");
                            alertPopUp?.setAttribute("class", "modal fade show");
                            alertPopUp?.setAttribute("style", "display:block");
                            document.getElementById("fadeDiv")?.removeAttribute("hidden");
                            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                        }
                        else if (data.data.data == "Invalid authentication token provided") {
                            enableSessionExpiryPopup();
                        }
                        else {
                            let alertPopUp = document.getElementById("successPopUp");
                            alertPopUp?.removeAttribute("aria-hidden");
                            alertPopUp?.setAttribute("aria-modal", "true");
                            alertPopUp?.setAttribute("role", "dialog");
                            alertPopUp?.setAttribute("class", "modal fade show");
                            alertPopUp?.setAttribute("style", "display:block");
                            document.getElementById("fadeDiv")?.removeAttribute("hidden");
                            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                        }
                        //debugger
                        ////console.log(formState);
                    }
                    else {
                        localStorage.clear();
                    }
                }
            }
            else {
                window.location.pathname = "/Login";
            }
            //#endregion
        } catch (error) {
            //console.log("Issue Faced");
        }
    }

    const openPopUpClick = (type: string) => {
        try {
            setAlertMessage("Please confirm to get the trial license.");
            let alertPopUp = document.getElementById("confirmationPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        } catch (error) {
            //console.log("Issue Faced");
        }
    }

    return (
        < div className="container-fluid p-2 bg-white card-bg-img" >
            <div className='centerallign text-center mt-1'>
                <img className="my-4 access-denid-img" src="/Images/access-denied.svg" />
                <h4 className="text-center contact-us font-bold font-24 mb-4">You don't have access to this feature in free plan. Please upgrade your plan!</h4>
                <p className="text-center contact-us-para font-18 font-regular">Click <a href="/UpgradePlan">here</a> to upgrade your plan.</p>
                <p className="text-center contact-us-para font-18 font-regular">or</p>
                <p className="text-center contact-us-para font-18 font-regular">Click <u className='hyperlink anchorAsPointer' onClick={(e) => openPopUpClick('request')}>here</u> to request for the trail license.</p>
            </div>
            {/* <!--Confirm Pop-up--> */}
            <div className="modal fade" id="confirmationPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Require Confirmation!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="btn-primary-theme mb-2 me-5 common-btn-class btn btn-ok text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('confirmationPopUp')}>Cancel</button>
                                <button type="button" className="btn-primary-theme mb-2 common-btn-class btn btn-ok text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('sendRequest')}>Send Request</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Confirm pop-up--> */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage1}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="successPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">AVAMIGRATRON team will approve your trial license after verification!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Admin-Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="aligncenter">{loaderText}</p>
                </div>
            </div>
            {/* <!--loader section--> */}
        </div >

    );
}