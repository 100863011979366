import { useState, useEffect } from "react";
import { callBackEnd } from "../../Services/GroupChatMigrationServices";
import constants from '../../Common/Constants';
import $ from 'jquery';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode';
import ReactTooltip from "react-tooltip";


export default function ManageClientGridHtml() {
  let GridLoadarray: any[] = [];
  let [clientID, setClientID] = useState(0);
  let [clientToken, setClientToken] = useState("");
  let [adminToken, setadminToken] = useState("");
  let [pageIndex, setpageIndex] = useState(1);
  const [sortColumn, setsortColumn] = useState("ScheduledAt");
  const [sortType, setsortType] = useState("desc");
  const [searchString, setsearchString] = useState("");
  let [fromDate, setFromDate] = useState("");
  let [toDate, setToDate] = useState("");
  let [migrationStatus, setMigrationStatus] = useState("");

  //update scheduled date and time
  const [hideEditPopup, sethideEditPopup] = useState("none");
  let [updatedDate, setUpdatedDate] = useState("");
  const [minFromDate, setminFromDate] = useState(new Date().toISOString().split('T')[0]);
  let [updatedTime, setUpdatedTime] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const [datePopUp, setDate] = useState("")
  const [schedulerID, setSchedulerID] = useState(0);
  const [migrationSummaryID, setmigrationSummaryID] = useState(0);
  const [inventorySummaryID, setinventorySummaryID] = useState(0);
  const [arrTime, setArrTime] = useState([{ UI: "00:00", value: 0 }, { UI: "01:00", value: 1 }, { UI: "02:00", value: 2 }, { UI: "03:00", value: 3 }, { UI: "04:00", value: 4 }, { UI: "05:00", value: 5 }, { UI: "06:00", value: 6 }, { UI: "07:00", value: 7 }, { UI: "08:00", value: 8 }, { UI: "09:00", value: 9 }, { UI: "10:00", value: 10 },
  { UI: "11:00", value: 11 }, { UI: "12:00", value: 12 }, { UI: "13:00", value: 13 }, { UI: "14:00", value: 14 }, { UI: "15:00", value: 15 }, { UI: "16:00", value: 16 }, { UI: "17:00", value: 17 }, { UI: "18:00", value: 18 }, { UI: "19:00", value: 19 }, { UI: "20:00", value: 20 },
  { UI: "21:00", value: 21 }, { UI: "22:00", value: 22 }, { UI: "23:00", value: 23 }]);

  const [maxMigrationFromDate, setMaxMigrationFromDate] = useState(new Date().toISOString().split('T')[0]);


  const [gridData, setGridData] = useState(GridLoadarray);
  const [sorthide, setsorthide] = useState("");
  let [countPerPage, setCoutPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  let [statusText, setStatusText] = useState("");
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout : NodeJS.Timeout;  
  let local: any = localStorage.getItem("cid_t");
  let adminLocal: any = localStorage.getItem("aid_t");
  ////console.log(process.env);
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  let adminObjClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;



  useEffect(() => {
    resetSessionTimer();
    document.getElementById('saveBtn')?.setAttribute("disabled", "true");
    //document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
    let client_Token: any;
    if (local != undefined) {
      let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
      decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
      objClient = jwt(decryptedValue);
      SessionEpiredDateTime = objClient.CreatedAt;
      clientID = Math.random();
      setClientID(clientID);
      clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
      setClientToken(clientToken);

      if(adminLocal != undefined){
        let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
        let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
        adminObjClient = jwt(adminDecryptedValue);
        adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
        setadminToken(adminToken);
      }
    }

    let removeHeight: any = document.getElementById("MainContainerMFE");
    removeHeight.classList.remove("container-bg-withFooter");
    removeHeight.classList.remove("container-bg-withoutFooter-chat");
    removeHeight.classList.remove("container-bg-withFooter-chat");
    removeHeight.classList.add("container-bg-withoutFooter-chat");

    loadGridPage();
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
  //#endregion
    return () => {
    window.removeEventListener('click', resetSessionTimer);
    window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [sortColumn, sortType, countPerPage])

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      statusText = "Your session has expired. Please log in again to continue using the application."
      setStatusText(statusText);

      //#region Show PopUp
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion


  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await callBackEnd(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                     let updatedobjClient: any = jwt(dePass);
                     SessionEpiredDateTime = updatedobjClient.CreatedAt;
                     setSessionEpiredDateTime(SessionEpiredDateTime);
                     clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {

      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText);
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    
      ////console.log("error", error);
     
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset");
      clearTimeout(InActiveTimeout);
      InActiveTimeout =  setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText)
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
      
    }
  }
  //#endregion



  $(function () {
    ////console.log("min From date", minFromDate);

    $('#from-date').attr('min', minFromDate);
  });


  const loadGridPage = async () => {
    let loader;
    try {

      if (clientID != undefined && clientToken != undefined) {
        let loadGCScheduledData = (
          {
            method: "POST",
            url: constants.URL.getScheduledGroupChatDataURL,
            data:
            {
              "clientID": clientID,
              "pageIndex": pageIndex,
              "sortColumn": `${sortColumn}`,
              "sortType": `${sortType}`,
              "searchString": `${searchString}`,
              "fromDate": fromDate,
              "toDate": toDate,
              "migrationStatus": migrationStatus,
              "type" : "Standard"
            },
            token: clientToken
          }
        );

        loader = document.getElementById("loaderWithoutContent");
        loader?.removeAttribute('hidden');

        let data: any = await callBackEnd(loadGCScheduledData);
        
        if (data.data.data == "No Records Found") {
          setGridData([data.data.data]);
        }
        else if (typeof (data.data.data) != "string") {
          setGridData(data.data.data[0]);
        }
        else if(data.data.data == "Invalid authentication token provided")
        {
            enableSessionExpiryPopup();
        }
        else {
          setGridData([data.data.data]);
          setStatusText(data.data.data);
          enableAlertPopup();
        }
        
        setTotalItemsCount(data.data.data[1][0].ScheduledChatCount);
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
        //document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
        loader?.setAttribute('hidden', 'hidden')
      }
      else {
        localStorage.clear();
      }

    }
    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden')
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      enableAlertPopup();
    }
  }

  const enableReschedulePopup = async () => {
    try {
      const element: any = document.getElementById("cardDetails");
      let errorlabletime = document.getElementById("errorlabelTime")
      errorlabletime?.setAttribute('hidden', 'true');
      document.getElementById('saveBtn')?.setAttribute("disabled", "true");
      if (element.hasAttribute("aria-modal")) {
        //#region Hide PopUp
        document.getElementById("cardDetails")?.setAttribute("class", "modal fade");
        document.getElementById("cardDetails")?.setAttribute("style", "display:none");
        document.getElementById("cardDetails")?.setAttribute("aria-hidden", "true");
        document.getElementById("cardDetails")?.removeAttribute("aria-modal");
        document.getElementById("cardDetails")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        document.getElementById("timeDD")?.setAttribute("hidden", "true");
        //#endregion
      }
      else {
        document.getElementById("cardDetails")?.removeAttribute("aria-hidden");
        document.getElementById("cardDetails")?.setAttribute("aria-modal", "true");
        document.getElementById("cardDetails")?.setAttribute("role", "dialog");
        document.getElementById("cardDetails")?.setAttribute("class", "modal fade show");
        document.getElementById("cardDetails")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const updatePopupValues = async (date: any, time: any) => {
    updatedDate = date;
    updatedTime = time;
    setUpdatedTime(updatedTime);
    setUpdatedDate(updatedDate);
  }

  const editClicked = async (obj: any) => {
    try {
      //updatedcode 
      setmigrationSummaryID(parseInt(obj.MigrationSummaryID));
      setinventorySummaryID(parseInt(obj.InventorySummaryID));
      setSchedulerID(parseInt(obj.ID));
      //////console.log(`${new Date(obj.ScheduledAt).getDate()}/${new Date(obj.ScheduledAt).getMonth()}/${new Date(obj.ScheduledAt).getFullYear()}`)
      let timeSplit: any = obj.ScheduledAt.replace(/-/g, "/").substring(0, 16).replace("T", " ")
      let datePopulate = timeSplit.split(" ")[0].replace(/\//g, "-")
      updatePopupValues(`${datePopulate}`, `${timeSplit.split(" ")[1]}`)
      enableReschedulePopup();
    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const deleteClicked = async (obj: any) => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let deleteScheduledData = (
          {
            method: "POST",
            url: constants.URL.deleteScheduledGroupChatDataURL,
            data:
            {
              "clientID": clientID,
              "schedulerID": parseInt(obj.ID),
              "migrationSummaryID": parseInt(obj.MigrationSummaryID),
              "inventorySummaryID": parseInt(obj.InventorySummaryID),
              "type": "Standard"
            },
            token: clientToken
          }
        );
        if(adminLocal !=undefined && adminLocal !== null){
          (deleteScheduledData.data as any).token = adminToken
        }

        let data: any = await callBackEnd(deleteScheduledData);
        if (data.data.statusCode == 200) {
          loadGridPage();
        }
        else if(data.data.data == "Invalid authentication token provided")
        {
            enableSessionExpiryPopup();
        }
        else {
          setStatusText(data.data.data);
          enableAlertPopup();
        }

        //document.getElementById("popup").style.display = "block";
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      enableAlertPopup();
    }
  }


  const gridBodyData = () => {
    try {

      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return gridData.map((obj, index) => {

          //////console.log("scheduled time", obj.ScheduledAt);
          let scheduledTime: any = obj.ScheduledAt.replace(/-/g, "/").substring(0, 16).replace("T", " ");
          let scheduleSplit = scheduledTime.split("/")
          ////console.log(scheduleSplit);
          let timeSplit = scheduleSplit[2].split(" ")
          scheduledTime = `${scheduleSplit[1]}/${timeSplit[0]}/${scheduleSplit[0]} ${timeSplit[1]}`
          let currentDate: any = new Date();
          let scheduledDate: any = new Date(obj.ScheduledAt);
          let isEditDisabled: any = (scheduledDate - currentDate) <= 300000 ? true : false;
          ////console.log(isEditDisabled, Math.abs(currentDate - scheduledDate));

          let i: number = index
          let trclassName = i % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          ////console.log(trclassName);
          return (
            <tr className={trclassName} >
              <td className="py-3 tabel-data-font-level1">{scheduledTime}</td>
              {obj.MigrationStatus === 'Queue' ? (
                <td className="py-3 tabel-data-font-level1 text-center"> <span className="queue-status ms-0  status-block font-14">Queue</span></td>
              ) :
                obj.MigrationStatus === 'In progress' ? (
                  <td className="py-3 tabel-data-font-level1 text-center"> <span className="inprogress-status ms-0  status-block font-14">In progress</span></td>
                )
                  :
                  obj.MigrationStatus === 'Completed' ? (
                    <td className=" py-3 tabel-data-font-level1 text-center"><span className="completed-status ms-0  status-block font-14">Completed</span></td>
                  )
                    :
                    obj.MigrationStatus === null ? (
                      <td className=" py-3 tabel-data-font-level1 text-center"><span className="yettostart-status ms-0  status-block font-14">Yet to Start</span></td>
                    )
                      :
                      obj.MigrationStatus === 'Scheduled' ? (
                        <td className="py-3 tabel-data-font-level1 text-center"><span className="scheduled-status ms-0 status-block font-14">Scheduled</span></td>
                      )
                        :
                        obj.MigrationStatus === 'Warning' ? (
                          <td className="py-3 tabel-data-font-level1 text-center"><span className="warning-status ms-0  status-block font-14">Warning</span></td>
                        )
                          :
                          (
                            <td className="py-3 tabel-data-font-level1"> <span className="status status-styling ms-0  font-14 font-semibold">{obj.MigrationStatus}</span></td>
                          )
              }
              {
                (obj.SourceChatName).length > 26 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.SourceChatName}>{obj.SourceChatName.substring(0, 23)}...</td> :
                  <td className="py-3 tabel-data-font-level1" data-tip="">{obj.SourceChatName}</td>
              }
              {
                (obj.TargetChannelName).length > 26 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetChannelName}>{obj.TargetChannelName.substring(0, 23)}...</td> :
                  <td className="py-3 tabel-data-font-level1" data-tip="">{obj.TargetChannelName}</td>
              }
              {
                (obj.InventoryName).length > 26 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.InventoryName}>{obj.InventoryName.substring(0, 23)}...</td> :
                  <td className="py-3 tabel-data-font-level1" data-tip="">{obj.InventoryName}</td>
              }
              <td className="py-3 tabel-data-font-level1">
                {obj.MigrationStatus === 'Scheduled' ?
                  isEditDisabled === true ?
                    <div> <span className='icon-disable'> <img className="icons me-2" src='/Images/edit-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} /> </span> <img className="icons anchorAsPointer" title="Delete" src='/Images/delete-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} /> </div>
                    : <div><img className="icons anchorAsPointer me-2" title="Edit" src='/Images/edit-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => editClicked(obj)} /> <img className="icons anchorAsPointer" title="Delete" src='/Images/delete-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} /> </div>
                  : <div className='icon-disable'> <img className="icons me-2" src='/Images/edit-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} /> <img className="icons" src='/Images/delete-icon.svg' id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} />  </div>
                }
              </td>
              <span>
                <ReactTooltip />
              </span>
            </tr>)
        })
      }

      else if (gridData[0] == undefined && gridData[0].SourceChatName == undefined) {
        return (<tr> <td className="aligncenter" colSpan={6}></td></tr>)
      }//empty binding
      else {
        return (<tr> <td className="aligncenter" colSpan={6}><img className="mt-3" src="/Images/NoRecordsFound.svg"> 
          </img><p className="mt-2">No records found</p></td></tr>)
      }//No records found

    }
    catch (error: any) {
      ////console.log(error);
    }
  }





  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setsortColumn(sortval[0])
      setsortType(sortval[1])
      setsorthide(sortID)

    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleSearch = (e: any) => {
    try {
      setsearchString(e.target.value)
    }
    catch (error: any) {
    }
  }



  const handleFromDate = (e: any) => {
    try {
      setFromDate(e.target.value);
      $(function () {
        $('#txtboxFilterToDate').attr('min', e.target.value);
        $('#txtboxFilterFromDate').attr('max', toDate);
      });
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleToDate = (e: any) => {
    try {
      setToDate(e.target.value);
      $(function () {
        $('#txtboxFilterToDate').attr('min', fromDate);
        $('#txtboxFilterFromDate').attr('max', e.target.value);
      });
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const handleMigrationStatus = (e: any) => {
    try {
      setMigrationStatus(e.target.value);
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
    }
    catch (error: any) {
    }
  }


  const handleErrorLabelPopup = () => {
    try {
      var currentTime = parseInt(new Date().getUTCHours().toString());
      var currentDate: any = new Date().toISOString().split('T')[0];
      let chosenTime = parseInt((updatedTime).substring(0, 2));

      let objCurrentDateCheck = new Date(currentDate);
      let objScheduledDateCheck = new Date(updatedDate);
      let scheduledDate: any = new Date(updatedDate + "T" + updatedTime + "Z");
      let objCurrentDate: any = new Date();
      let isEditDisabled: any = (scheduledDate - objCurrentDate) <= 300000; //1,800,000
      
      
      if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
        let x = document.getElementById("errorlabelTime")
        x?.removeAttribute('hidden');
        document.getElementById('saveBtn')?.setAttribute("disabled", "true");
      }
      else {
        document.getElementById('errorlabelTime')?.setAttribute("hidden", "true");
        if (updatedDate != "" && updatedTime != "") {
          document.getElementById('saveBtn')?.removeAttribute("disabled");
        }
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }


  const handleDate = (e: any) => {
    try {

      updatedDate = e.target.value;
      setUpdatedDate(updatedDate);
      setDate(updatedDate)
      handleErrorLabelPopup();
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleTime = async (e: any) => {
    try {
      
      ////console.log(e.target.value);
      document.getElementById("timeDD")?.setAttribute("hidden", "true");
      let x = document.getElementById("picker")
      x?.setAttribute("value", `${e.target.id}`);
      updatedTime = e.target.id + ""
      setUpdatedTime(updatedTime)
      setTimeVal(e.target.id)
      x?.setAttribute("value", `${e.target.id}`)
      handleErrorLabelPopup();
    }
    catch (error: any) {
      ////console.log(error);
    }
  }



  const saveScheduleClick = async (e: any) => {
    let loader;
    try {
      if (clientID != undefined && clientToken != undefined) {

        var currentTime = parseInt(new Date().getUTCHours().toString());
        var currentDate: any = new Date().toISOString().split('T')[0];
        let chosenTime = parseInt((updatedTime).substring(0, 2));


        let objCurrentDateCheck = new Date(currentDate);
        let objScheduledDateCheck = new Date(updatedDate);
        let scheduledDate: any = new Date(updatedDate + "T" + updatedTime + "Z");
        let objCurrentDate: any = new Date();
       
        let isEditDisabled: any = (scheduledDate - objCurrentDate) <= 300000; //1800000
        
        if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
          let x = document.getElementById("errorlabelTime")
          x?.removeAttribute('hidden');
          document.getElementById('saveBtn')?.setAttribute("disabled", "true");
        }
        else {
          let updatedDateandTime = updatedDate + " " + updatedTime;
          let updateScheduledData = (
            {
              method: "POST",
              url: constants.URL.updateScheduledGroupChatDataURL,
              data:
              {
                "clientID": clientID,
                "schedulerID": schedulerID,
                "scheduledAt": updatedDateandTime,
                "migrationSummaryID": migrationSummaryID,
                "inventorySummaryID": inventorySummaryID,
                "type" : "Standard"
              },
              token: clientToken
            }
          );
          if(adminLocal !=undefined && adminLocal !== null){
            (updateScheduledData.data as any).token = adminToken
          }
          enableReschedulePopup();
          loader = document.getElementById("loaderWithoutContent");
          loader?.removeAttribute('hidden');
          let data: any = await callBackEnd(updateScheduledData);
          loader?.setAttribute('hidden', 'hidden')
          loadGridPage();
        }
      }
      else {
        enableReschedulePopup();
        setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
        enableAlertPopup();
      }
    }
    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden');
      enableReschedulePopup();
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      enableAlertPopup();
      ////console.log(error);
    }
  }

  const updatePageIndexCountPerPage = () => {
    try {
      setpageIndex(pageIndex + 1);
      setCoutPerPage(countPerPage + 10);
    }
    catch (error) {
      ////console.log(error)
    }
  }

  const keyPressSearch = (e: any) => {
    try {
      if (e.keyCode == 13) {
        setsearchString(e.target.value);
        loadGridPage();
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleLoadMore = () => {
    try {
      pageIndex = pageIndex + 1;
      countPerPage = countPerPage + 10;
      setpageIndex(pageIndex);
      setCoutPerPage(countPerPage);
      loadGridPage();
      //updatePageIndexCountPerPage();
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const btnClearFilterClick = () => {
    try {

      const migrationStatusElement: any = document.getElementById("ddFilterMigrationStatus");
      const fromDateElement: any = document.getElementById("txtboxFilterFromDate");
      const toDateElement: any = document.getElementById("txtboxFilterToDate");
      migrationStatusElement.value = '';
      fromDateElement.value = '';
      toDateElement.value = '';
      fromDate = "";
      toDate = "";
      migrationStatus = "";
      setFromDate(fromDate);
      setToDate(toDate);
      setMigrationStatus(migrationStatus);
      $(function () {
        $('#txtboxFilterToDate').attr('min', fromDate);
        $('#txtboxFilterFromDate').attr('max', toDate);
      });
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
      //loadGridPage();

    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const btnCloseFilterClick = () => {
    try {

      const migrationStatusElement: any = document.getElementById("ddFilterMigrationStatus");
      const fromDateElement: any = document.getElementById("txtboxFilterFromDate");
      const toDateElement: any = document.getElementById("txtboxFilterToDate");
      migrationStatusElement.value = '';
      fromDateElement.value = '';
      toDateElement.value = '';
      fromDate = "";
      toDate = "";
      migrationStatus = "";
      setFromDate(fromDate);
      setToDate(toDate);
      setMigrationStatus(migrationStatus);
      $(function () {
        $('#txtboxFilterToDate').attr('min', fromDate);
        $('#txtboxFilterFromDate').attr('max', toDate);
      });
      //loadGridPage();
      document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      //document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");

    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const changeDropDownSize = (sizeofDD: any) => {
    const ddElement: any = document.getElementById("ddUpdatedTime");
    ddElement.size = 5;
  }

  const changeDropDownSizetoZero = () => {
    const ddElement: any = document.getElementById("ddUpdatedTime");
    ddElement.size = 0;
  }

  const popUpOkClick = () => {
    try {
      if (isSessionEpired === false) {

      const element: any = document.getElementById("alertPopUp");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:none");
      document.getElementById("alertPopUp")?.setAttribute("aria-hidden", "true");
      document.getElementById("alertPopUp")?.removeAttribute("aria-modal");
      document.getElementById("alertPopUp")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";
    }

    } catch (error) {
      ////console.log("Issue Faced");
    }
  }

  const enableAlertPopup = () => {
    const element: any = document.getElementById("alertPopUp");
    document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
    document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
    document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
    document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
    document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
    document.getElementById("fadeDiv")?.removeAttribute("hidden");
    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
  }


  return (
    <div>

      {/* <BackNavigation backNav={"Conversation Migration"} />
              <WizardNavigation wizActive={"step2InActive"} /> */}

      {/* <div className="col-md-12 mt-5"> */}
      <div className="col-md-12">
        {/* <!--Slack channel section starts here--> */}
        <div className="col-md-12 col-12 float-start mt-2">

          {/* <h5 className="channel-header font-18 font-bold">Manage Clients</h5> */}


          {/* <!--Slack channel section ends here--> */}
          <div className="tab-content">
            <div id="high-level-inventory" className="tab-pane active w-100">
              {/* <!-- channel list table starts here --> */}
              <div className="row">

                <div className="float-start mb-1 w-100">
                  {/* <div className="float-start mt-5 w-100 "> */}
                  <div className="phn-btn-float-s">

                    <div className=" float-start ">
                      <p>Here is your scheduled chats for migration. Click on Edit icon to reschedule.</p>
                    </div >
                  </div>
                {/*
                    <div className="col-md-6 float-start">
                      <button type="button" className="btn btn-refresh btn-refresh-cm  px-3 btn-sm float-end d-inline-flex font-16 font-semibold padding-six-px" onClick={loadGridPage}   ><span className="pe-2"><img src="../Images/refresh-black.svg" alt="refresh" /></span> <span className="refreshTextColor">Refresh</span></button>
                      <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header border-0">
                              <h5 className="inventory-title font-22 font-bold">Reschedule Migration</h5>
                              <button type="button" className="common-btn-class btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={enableReschedulePopup}></button>
                            </div>
                            <div className="modal-body pt-0">
                              <div className="col-md-12 mb-4">
                                <div className="row">
                                  <label className="form-label popup-label font-14 font-regular">If any migration is in progress, the scheduled channels will be in queue.</label>
                                </div>
                              </div>

                              <div className="col-md-12 float-start mb-5">
                                <div className="row">
                                  <div className="col-md-6 float-start">
                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Date</label>
                                    <input type="date" className="inventory-date-form-control date-style w-100 date-style-cm " min={maxMigrationFromDate} id="from-date" value={updatedDate} onKeyDown={(e) => { e.preventDefault() }} onChange={(e) => handleDate(e)} />
                                  </div>
                                  <div className="col-md-6 float-start fake-input">
                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Time (UTC)</label>
                                    <input type="text" className="inventory-date-form-control date-style w-100 input date-style-cm" onClick={(e) => { document.getElementById("timeDD")?.removeAttribute("hidden") }} onChange={async () => { await handleTime }} value={updatedTime} id="picker" />
                                    <img src="..\Images\Clock-icon.svg" className="ms-2" onClick={(e) => { document.getElementById("timeDD")?.removeAttribute("hidden") }} alt="clockicon" />
                                    <ul className="editClickdropdown" id="timeDD" hidden>
                                      {arrTime.map((obj, index) => {
                                        return (<li className="dropdown-item" id={obj.UI} onClick={(e) => handleTime(e)}>{obj.UI}</li>)
                                      })
                                      }
                                    </ul>
                                    <label id="errorlabelTime" className="required" hidden={true} >Please choose future time</label>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="col-md-12 d-flex justify-content-end mb-4">
                              <button type="button" className="btn-cancel-border common-btn-class btn btn-second me-2 font-16 font-semibold float-start ps-4 pe-4" onClick={enableReschedulePopup}>Cancel</button>
                              <button type="button" className="common-btn-class btn btn-prime font-16 font-semibold float-start me-3 ps-4 pe-4" id="saveBtn" onClick={saveScheduleClick}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dropdown filter">
                        <button type="button" className="btn float-end mx-2 box-shodow-filter p-0 custom-height" title="Advanced Filter" onClick={() => { document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden") }} >
                          <img src='/Images/filter.svg' alt="filter" />
                        </button>
                        <div className="manageScheduleMigrationFilter dropdown-menu advancedFilterPopUp show" id="AdvancedFilterDiv" hidden>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 pb-2 border-0 mb-1">
                                <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                                <a className="btn float-end anchorAsPointer" id="close-filter" data-bs-dismiss="advanced-filter" aria-label="Close" onClick={btnCloseFilterClick}>
                                  <span aria-hidden="true"><img src='/Images/filter-close-icon.svg' alt="filter-close-icon" /></span>
                                </a>
                              </div>

                              <div className="col-md-12 rowTopSpace">
                                <div className="col-md-6 float-start">
                                  <label className="date-created w-100 float-start channel-check font-14 font-regular">From</label>
                                  
                                  <input type="date" placeholder="select date" className="dateFilter" onChange={handleFromDate} id="txtboxFilterFromDate" onKeyDown={(e) => e.preventDefault()} />

                                </div>

                                <div className="col-md-6 float-start">
                                  <label className="date-created w-100 float-start channel-check font-14 font-regular">To</label>
                                 
                                  <input type="date" placeholder="select date" className="dateFilter" onChange={handleToDate} id="txtboxFilterToDate" onKeyDown={(e) => e.preventDefault()} />
                                </div>
                              </div>

                              <div className="col-md-12 rowTopSpace">
                                <div className="col-md-6 float-start">
                                  <label className="date-created w-100 float-start channel-check font-14 font-regular">Migration Status</label>
                                  <select id="ddFilterMigrationStatus" className="dateFilter dropdownWidth form-select" onChange={handleMigrationStatus}>
                                    <option value="">All</option>
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Queue">Queue</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Warning">Warning</option>
                                    <option value="Completed">Completed</option>
                                  </select>
                                </div>
                              </div>



                              <div className="col-md-12 mt-5 mb-3">
                                <div className="float-end">
                                  <button className="btn-cancel-border common-btn-class btn btn-clear font-16 font-semibold px-4 me-3" onClick={btnClearFilterClick} >Clear</button>
                                  <button className="common-btn-class btn btn-apply font-16 font-semibold text-light" id="ApplyFilter" onClick={loadGridPage}>Apply Filter</button>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      

                      <div className="row float-end">
                        <div className="col-md-12 ">
                          <div className="input-group box-shodow-filter">
                            <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={searchString} onKeyDown={(e) => keyPressSearch(e)} onChange={handleSearch} />
                            <button className="common-btn-class btn btn-outline-secondary" type="button" id="button-addon1"><img src='/Images/search.svg' alt="search-icon" onClick={() => loadGridPage()} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    */}
                    <div className="phn-btn-float-e">
                      <div className="search-container-mm cm-refresh-margin input-group box-shodow-filter float-start">
                        <input type="text" className="form-control z-value-unset form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={searchString} onKeyDown={(e) => keyPressSearch(e)} onChange={handleSearch} />
                        <button className="btn btn-outline-secondary z-value-unset" type="button" id="button-addon1" onClick={() => loadGridPage()}><img src="/Images/search.svg" alt="search-icon" />
                        </button>
                      </div>

                      {/* <!-- advanced-filter --> */}
                      <div className="dropdown filter float-start position-dropdown">
                        <button type="button" className="btn btn-scondary-custom filter-shadow float-start hli-filter-margin12 box-shodow-filter p-0" title="Advanced Filter" onClick={() => { document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden") }}>
                          <img src="/Images/filter.svg" alt="filter" />
                        </button>

                        {/* <div className="dropdown-menu advance-filter-inventory"> */}
                        <div className="manageschMigrationFilter-position-res GroupChatSchedulerdrop-filter-sty dropdown-menu show" id="AdvancedFilterDiv" hidden>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 pb-2 padding-filter-none border-0 mb-1">
                                <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                                <a className="advancedfilter_close_padding btn float-end anchorAsPointer" id="close-filter" data-bs-dismiss="advanced-filter" aria-label="Close" onClick={btnCloseFilterClick}>
                                  <span aria-hidden="true"><img src="/Images/filter-close-icon.svg" alt="filter-close-icon" /></span>
                                </a>
                              </div>
                              <div className="col-md-12 padding-filter-none">
                                <label className="semi-bold-popup font-16 ">Scheduled On</label>
                              </div>
                              <div className="col-md-12 padding-filter-none rowTopSpace">
                                <div className="row">
                                <div className="col-md-6 my-2">
                                  <label className="date-created w-100 float-start channel-check font-14 font-regular">From</label>
                                  {/* <input type="date" placeholder="select date" className="inventory-date-to-control date-style" onChange={handleToDate} id="txtboxFilterFromDate"/> */}
                                  <input type="date" placeholder="select date" className="custom-date-dropdown-border form-control" onKeyDown={(e) => { e.preventDefault() }} onChange={handleFromDate} id="txtboxFilterFromDate" />
                                </div>

                                <div className="col-md-6 my-2 ">
                                  <label className="date-created w-100 float-start channel-check font-14 font-regular">To</label>
                                  {/* <input type="date" placeholder="select date" className="inventory-date-to-control date-style" onChange={handleToDate} id="txtboxFilterToDate"/> */}
                                  <input type="date" placeholder="select date" className="custom-date-dropdown-border form-control" onChange={handleToDate} onKeyDown={(e) => { e.preventDefault() }} id="txtboxFilterToDate" />
                                </div>
                                </div>
                              </div>
                              <div className="col-md-12 padding-filter-none  mt-3">
                                <label className="semi-bold-popup font-16 ">Migration Status</label>
                              </div>
                              <div className="col-md-12 padding-filter-none rowTopSpace">
                                <div className="col-md-6 float-start px-2">
                                  {/* <label className="semi-bold-popup date-created w-100 float-start channel-check font-16 font-regular py-2">Migration Status</label> */}
                                  <select id="ddFilterMigrationStatus" className="custom-date-dropdown-border form-select form-control" placeholder="Status" onChange={handleMigrationStatus} value={migrationStatus}>
                                    <option value="" >All</option>
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Queue">Queue</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Warning">Warning</option>
                                    <option value="Completed">Completed</option>
                                  </select>
                                </div>
                              </div>



                              <div className="col-md-12 mt-5 mb-3">
                                <div className="float-end">
                                  <button className="btn-cancel-border btn-scondary-custom common-btn-class  btn btn-second clear-btn-padding font-16 font-semibold px-4 me-3" onClick={btnClearFilterClick} >Clear</button>
                                  <button className="common-btn-class btn-primary-theme btn btn-prime font-16 font-semibold text-light" onClick={loadGridPage}>Apply Filter</button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      {/* <!-- advanced-filter ends--> */}

                      <button type="button" className="btn btn-refresh btn-refresh-cm cm-refresh-margin btn-scondary-custom   px-3 btn-sm float-start d-inline-flex font-16 font-semibold padding-six-px" onClick={loadGridPage}   ><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> <span className="refreshTextColor">Refresh</span></button>
                      {/* <!-- ReSchedule Migration Popup --> */}
                      {/* Modal starts */}
                      <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header border-0">
                              <h5 className="inventory-title font-22 font-bold">Reschedule Migration</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={enableReschedulePopup}></button>
                            </div>
                            <div className="modal-body pt-0">
                              <div className="col-md-12 mb-4">
                                <div className="row">
                                  <label className="form-label popup-label font-14 font-regular">If any migration is in progress, the scheduled channels will be in queue.</label>
                                </div>
                              </div>

                              <div className="col-md-12  mb-5">
                                <div className="row">
                                  <div className="col-md-6 my-2 float-start">
                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Date</label>
                                    <input type="date" className="form-control inventory-date-form-control date-style-cm date-style w-100" min={maxMigrationFromDate} id="from-date" value={updatedDate} onKeyDown={(e) => { e.preventDefault() }} onChange={(e) => handleDate(e)} />
                                  </div>
                                  <div className="col-md-6 my-2 float-start clk-icon-fix-chat clock-icon-channel">
                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Time (UTC)</label>
                                    <img src="\Images\Clock-icon.svg" className="ms-2" onClick={(e) => { document.getElementById("timeDD")?.removeAttribute("hidden") }} alt="clockicon" />
                                    <input type="text" className="inventory-date-form-control date-style-cm date-style w-100 input" onClick={(e) => { document.getElementById("timeDD")?.removeAttribute("hidden") }} onChange={async () => { await handleTime }} value={updatedTime} id="picker" />
                                    {/* <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">Dropdown <span className="caret"></span></button> */}
                                    {/* <ul className="time-dropdown-menu" id="timeDD"> */}
                                    <ul className="editClickdropdown" id="timeDD" hidden>
                                      {arrTime.map((obj, index) => {
                                        return (<li className="dropdown-item" id={obj.UI} onClick={(e) => handleTime(e)}>{obj.UI}</li>)
                                      })
                                      }
                                    </ul>
                                    <label id="errorlabelTime" className="required" hidden={true} >Please choose future time</label>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className="col-md-12 d-flex justify-content-end mb-4">
                              <button type="button" className="btn-cancel-border btn-scondary-custom btn-scondary-custom common-btn-class  btn btn-second me-2 font-16 font-semibold float-start ps-4 pe-4" onClick={enableReschedulePopup}>Cancel</button>
                              <button type="button" className="common-btn-class btn-primary-theme btn btn-prime font-16 font-semibold float-start me-3 ps-4 pe-4" id="saveBtn" onClick={saveScheduleClick}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Modal ends */}
                      {/* <!-- advanced-filter ends--> */}
                    </div>
                  
                </div>
              </div>

              <div className="col-md-12 col-12 float-start mt-2 table-responsive p-1">
                <table className="table table-border wraptd">
                  <thead className="tabel-head-bg">
                    <tr>

                      <th className="table-head font-14 font-bold">Scheduled On
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="ScheduledAt" id="ScheduledAt@desc" onClick={e => sortClicked(e)} hidden={sorthide === "ScheduledAt@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="ScheduledAt" id="ScheduledAt@asc" onClick={e => sortClicked(e)} hidden={sorthide === "ScheduledAt@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">Migration Status
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="MigrationStatus" id="MigrationStatus@desc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="MigrationStatus" id="MigrationStatus@asc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">Slack Chat Name
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="SourceChatName" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="SourceChatName" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">MS teams Chat Name
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="TargetChannelName" id="TargetChannelName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="TargetChannelName" id="TargetChannelName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? false : true} />
                      </th>

                      <th className="table-head font-14 font-bold">Inventory Name
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_arrow.svg' alt="InventoryName" id="InventoryName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "InventoryName@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="InventoryName" id="InventoryName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "InventoryName@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {gridBodyData()}
                  </tbody>
                </table>
                {/* <!-- channel list table ends here --> */}

              </div>
              {/* <!-- loadmore button section starts here --> */}
              <div className="col-md-12 mt-3 mb-5 text-center">
                  {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={() => handleLoadMore()}>Load More</button> : ""}
                </div>
                {/* <!-- loadmore button section ends here --> */}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{statusText}</p>

                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={popUpOkClick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>



  )
}