import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type ActiveStep = 'step1InActive' | 'step2InActive';

export default function WizardNavigation({ wizActive }: { wizActive: any }): JSX.Element {
    const [activeStep, setActiveStep] = useState<ActiveStep>(wizActive as ActiveStep);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // Update activeStep based on the current route
        if (location.pathname.includes('ConversationMigration')) {
            setActiveStep('step2InActive');
        } else if (location.pathname.includes('ChatMapping')) {
            setActiveStep('step1InActive');
        }
    }, [location.pathname]);

    const handleWizard = (step: ActiveStep): void => {
        setActiveStep(step);
        if (step === 'step2InActive') {
            history.push('/Conversation/SA/ConversationMigration');
        } else {
            history.push('/Conversation/SA/ChatMapping');
        }
    }

    return (
        <div className="col-md-12 my-3">
            <div className="row justify-content-center">
                <div className="col-12 py-3">
                    <ul className="nav migration-wizard justify-content-center ">
                        <li>
                            <a 
                                className={`nav-link font-18 font-bold anchorAsPointer ${activeStep === 'step1InActive' ? 'active' : ''}`} 
                                onClick={() => handleWizard('step1InActive')}
                            >
                                <span className="px-2">
                                    <img 
                                        src={activeStep === 'step1InActive' ? "/Images/step-1-active.svg" : "/Images/step-1-inActive.svg"} 
                                        alt="step-1" 
                                    />
                                </span>
                                <span className={activeStep === 'step1InActive' ? 'activeTabColour wizard-res-none' : 'inactiveTabColour wizard-res-none'}>
                                MS Team Chat Creation and Mapping
                                </span>
                            </a>
                        </li>
                        <li>
                            <a 
                                className={`nav-link font-18 font-bold anchorAsPointer ${activeStep === 'step2InActive' ? 'active' : ''}`} 
                                onClick={() => handleWizard('step2InActive')}
                            >
                                <span className="px-2">
                                    <img 
                                        src={activeStep === 'step2InActive' ? "/Images/step-2-active.svg" : "/Images/step-2-inActive.svg"} 
                                        alt="step-2" 
                                    />
                                </span>
                                <span className={activeStep === 'step2InActive' ? 'activeTabColour wizard-res-none' : 'inactiveTabColour wizard-res-none'}>
                                    Content Migration
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
        </div>
    );
}