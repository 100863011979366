import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';



import { useEffect, useState } from 'react';
import '../App.css';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import { PageNotFound } from './PageNotFound';
import ReactTooltip from "react-tooltip";
import { AccessDenied } from './AccessDenied';
import { gridLoad } from '../Services/MainContainer';
import { sendRequestToNodeServicesApiTS } from "../Services/MainContainer";
import { InventoryMasterComponent } from '../components/Inventory/MasterComponent';

import  InventoryRouting  from '../components/Inventory/InventoryRouter';
import   RegistrationRouting from '../components/Registration/RegistrationRouter'

import constants from "../Common/Constants";

import AdminRouting from '../components/Admin/AdminRouter'

import  ChannelRouting  from '../components/ChannelMigration/ChannelRouter'


import MemberMigrationRouting from '../components/MemberMigration/MemberMigrationRouter'



import GroupChatRouting from '../components/GroupChatMigration/GroupChatRouter'

import MSTeamsUserVerification from '../components/GroupChatMigration/UserVerification';
import GridPage from '../components/GroupChatMigration/ChatLandingPage';



import { SettingMasterComponent } from '../components/Settings/MasterComponent';
import {RedirectPage} from '../components/Settings/RedirectPage';



import ReportLanding from '../components/UserMapping/MasterComponentReportLanding'
import GridComponent from '../components/UserMapping/MasterComponentIndividualReport'
import UserMappingMasterComponent from '../components/UserMapping/UserMappingMastercomponent';
import {LicensedUsers} from '../components/UserMapping/LicensedUser';





import ResgistrationRouter from '../components/Registration/RegistrationRouting'

import ProfileUpdate from '../components/Profile/ProfileUpdate'
import ContactUs from '../components/Profile/ContactUs';
import UpgradePlan from '../components/Profile/UpgradePlan';



export function MasterComponent(): JSX.Element {
    let history = useHistory();
    const state = useLocation().state as any;
    let activeClass = "nav-link active-header-font font-14 nav-header anchorAsPointer ";
    let inActiveClass = "nav-link font-semibold font-14 color-black nav-header anchorAsPointer ";
    const activeMig = `migration-dropdown-img-nav ${activeClass}`;
    const inActiveMig = `migration-dropdown-img-nav ${inActiveClass}`;
    let locationPath = window.location.pathname;
    let c_local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let objClient: any;
    let clientToken: any;
    let isMigOpen: any = false;
    let isProfileOpen: any = false;
    let a_local: any = localStorage.getItem("aid_t");
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let Node_URL = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let objAdmin: any;
    let AvaMigrationSlackWebSiteLink: any = constants.URL.REACT_APP_AVAMIGRATRON_LINK;
    if (c_local != undefined) {
        let c_byteValue = objCryptoJS.AES.decrypt(c_local, c_t_Key);
        var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(c_decryptedValue);
        clientToken = objCryptoJS.AES.encrypt(c_decryptedValue, r_n_Key).toString();

    }
    if (a_local != undefined) {
        let a_byteValue = objCryptoJS.AES.decrypt(a_local, a_t_Key);
        var a_decryptedValue = a_byteValue.toString(objCryptoJS.enc.Utf8);
        objAdmin = jwt(a_decryptedValue);
    }
    let clientMail: any = [];
    let [wrapppedClientMail, setwrapppedClientMail] = useState("");
    let a: any = 0;
    let b: any = 30;
    let c: any = 0;
    let [activeMenu, setActiveMenu] = useState(false)
    useEffect(() => {
        validateSession();
        //#region client mailID tooltip scenario Handling
        if (objClient != undefined && objClient.CMail != undefined) {
            for (let i = 0; i < objClient.CMail.length; i++) {
                clientMail.push(objClient.CMail.slice(a, b));
                c += clientMail[i].length;
                a += 30;
                b += 30;
                if (objClient.CMail.length == c) {
                    break;
                }
            }
            clientMail.forEach((element: any) => {
                wrapppedClientMail = wrapppedClientMail + element + "<br/>";
            });
            setwrapppedClientMail(wrapppedClientMail);
        }
        //#endregion
        // document.getElementById("MigrationMenu")?.setAttribute("hidden", "true");
        // document.getElementById("ProfileMenu")?.setAttribute("hidden", "true");
        document.getElementsByTagName('video')[0]?.setAttribute("src", "");
        if (locationPath.toString().toLowerCase().includes("/settings")) {
            if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == false) {
                document.getElementById("Settings")?.setAttribute("class", activeClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)

            }
            else if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == true) {
                window.location.pathname = "/UpgradePlan";

            }
            else {
                window.location.pathname = "/ChoosePlan";
            }
        }
        else if (locationPath.toString().toLowerCase().includes("/inventory")) {
            if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == true) {
                window.location.pathname = "/UpgradePlan";

            }
            else {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", activeClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)
            }
        }
        else if (locationPath.toString().toLowerCase().includes("/channelmigration") || locationPath.toString().toLowerCase().includes("/conversation") ||
            locationPath.toString().toLowerCase().includes("/usermapping") || locationPath.toString().toLowerCase().includes("/report") ||
            locationPath.toString().toLowerCase().includes("/membermigration")) {
            if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == true) {
                window.location.pathname = "/UpgradePlan";

            }
            else {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)

                activeMenu = true
                setActiveMenu(activeMenu)
            }
        }
        else if (locationPath.toString().toLowerCase().includes("/contactus")) {

                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("ContactUs")?.setAttribute("class", activeClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)

        }
        else if (locationPath.toString().toLowerCase().includes("/chooseplan")) {
            document.getElementById("Settings")?.setAttribute("class", inActiveClass);
            document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
            document.getElementById("Migration")?.setAttribute("class", inActiveMig)
            document.getElementById("Admin")?.setAttribute("class", inActiveClass)
            document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
            document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
            document.getElementById("ChoosePlan")?.setAttribute("class", activeClass)
            document.getElementById("profile")?.setAttribute("class", inActiveClass)
            document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)

        }
        else if (locationPath.toString().toLowerCase().includes("/upgradeplan")) {
            document.getElementById("Settings")?.setAttribute("class", inActiveClass);
            document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
            document.getElementById("Migration")?.setAttribute("class", inActiveMig)
            document.getElementById("Admin")?.setAttribute("class", inActiveClass)
            document.getElementById("Admin")?.setAttribute("class", inActiveClass)
            document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
            document.getElementById("UpgradePlan")?.setAttribute("class", activeClass)
            document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
            document.getElementById("profile")?.setAttribute("class", inActiveClass)
            document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)
        }
        else if (locationPath.toString().toLowerCase().includes("/profileupdate")) {

                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", activeClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)
        }
        else if (locationPath.toString().toLowerCase().includes("/admin")) {

                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", activeClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)
        }
        else if (locationPath.toString().toLowerCase().includes("/licensedusers")) {
            if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == true) {
                window.location.pathname = "/UpgradePlan";

            }
            else {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
                document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
                document.getElementById("profile")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", activeClass)
            }
        }
        else if (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == true) {
            window.location.pathname = "/UpgradePlan";

        }
        else {
            document.getElementById("Settings")?.setAttribute("class", inActiveClass);
            document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
            document.getElementById("Migration")?.setAttribute("class", inActiveMig)
            document.getElementById("Admin")?.setAttribute("class", inActiveClass)
            document.getElementById("ContactUs")?.setAttribute("class", inActiveClass)
            document.getElementById("UpgradePlan")?.setAttribute("class", inActiveClass)
            document.getElementById("ChoosePlan")?.setAttribute("class", inActiveClass)
            document.getElementById("profile")?.setAttribute("class", inActiveClass)
            document.getElementById("LicensedUsers")?.setAttribute("class", inActiveClass)

        }
    }, [])


    const validateSession = async () => {
        try {
            //#region Update and Frame Client Session
            if (objClient != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${Node_URL}/UserAuthentication/removeSession`,
                    data: {
                        "type": "remove"
                    },
                }
                )
                let data: any = await gridLoad(config);
                if (data.data.data == true) {
                    localStorage.removeItem("cid_t");
                    config = ({
                        method: "POST",
                        token: clientToken,
                        url: `${Node_URL}/UserAuthentication/removeSession`,
                        data: {
                            "type": "update"
                        },
                    }
                    )
                    let data: any = await gridLoad(config);

                    if (data.data.statusCode == 200 && data.data.data == false) {
                        window.location.pathname = '/Login';
                    }
                }
            }
            //#endregion
        }
        catch (error) {
        }
    }

    const menuClick = async (e: any) => {
        try {
            if (e.target.id == "Settings") {
                document.getElementById("Settings")?.setAttribute("class", activeClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/Settings/Slack",
                    state: { isSettings: true, isInventory: false, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/Settings/Slack';
            }
            else if (e.target.id == "LicensedUsers") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("LicensedUsers")?.setAttribute("class", activeClass)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/LicensedUsers",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false, isLicensedUser: true }
                });
                window.location.pathname = '/Inventory/HighLevel';
            }
            else if (e.target.id == "Inventory") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", activeClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/Inventory/HighLevel",
                    state: { isSettings: false, isInventory: true, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/Inventory/HighLevel';
            }
            else if (e.target.id == "UserMapping") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                history.push({
                    pathname: "/UserMapping",
                    state: { isSettings: false, isInventory: false, isMigration: true, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/UserMapping';
            }
            else if (e.target.id == "ChannelMigration") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                history.push({
                    pathname: "/ChannelMigration",
                    state: { isSettings: false, isInventory: false, isMigration: true, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/ChannelMigration';
            }
            else if (e.target.id == "MemberMigration") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                history.push({
                    pathname: "/MemberMigration",
                    state: { isSettings: false, isInventory: false, isMigration: true, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/MemberMigration';
            }
            else if (e.target.id == "ConversationMigration") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                history.push({
                    pathname: "/Conversation",
                    state: { isSettings: false, isInventory: false, isMigration: true, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/Conversation';
            }
            else if (e.target.id == "Reports") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", activeMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                document.getElementById("accordian-img")?.setAttribute("class", "migration-open-dropDown")
                history.push({
                    pathname: "/Report",
                    state: { isSettings: false, isInventory: false, isMigration: true, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/Report';
            }
            else if (e.target.id == "Admin") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", activeClass)
                localStorage.removeItem("cid_t");
                history.push({
                    pathname: "/Admin/ManageClients",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: true, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/Admin/ManageClients';
            }
            else if (e.target.id == "ContactUs") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/ContactUs",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: false, isContactUs: true, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/ContactUs';
            }
            else if (e.target.id == "UpgradePlan") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/UpgradePlan",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: true }
                });
                window.location.pathname = '/UpgradePlan';
            }
            else if (e.target.id == "ProfileUpdate") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/ProfileUpdate",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: true, isUpgradePlan: false }
                });
                window.location.pathname = '/ProfileUpdate';
            }
            else if (e.target.id == "ChoosePlan") {
                document.getElementById("Settings")?.setAttribute("class", inActiveClass);
                document.getElementById("Inventory")?.setAttribute("class", inActiveClass)
                document.getElementById("Migration")?.setAttribute("class", inActiveMig)
                document.getElementById("Admin")?.setAttribute("class", inActiveClass)
                history.push({
                    pathname: "/ChoosePlan",
                    state: { isSettings: false, isInventory: false, isMigration: false, isAdmin: false, isContactUs: false, isProfileUpdate: false, isUpgradePlan: false }
                });
                window.location.pathname = '/ChoosePlan';
            }
        } catch (error) {
        }
    }
    const clientLogOutClick = async () => {
        try {
            localStorage.clear();
            window.location.pathname = '/Login';
        } catch (error) {

        }
    }
    const adminLogOutClick = async () => {
        try {
            localStorage.clear();
            window.location.pathname = '/Admin/Login';
        } catch (error) {

        }
    }
    const tourGuideMenuClick = async () => {
        try {
            //#region Show PopUp
            document.getElementsByClassName('videoTagMNC')[0]?.setAttribute("src", "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Without%20servcie%20acc%20(con)%20tool%20demo%20video.mp4?sp=r&st=2024-08-21T10:04:48Z&se=2025-05-15T18:04:48Z&spr=https&sv=2022-11-02&sr=b&sig=5WMRTKM69jfdsq1coUj%2BWmyT8D3Sd7vB9%2FoTH1Lkg8o%3D");
            document.getElementById("tourVideo")?.removeAttribute("aria-hidden");
            document.getElementById("tourVideo")?.setAttribute("aria-modal", "true");
            document.getElementById("tourVideo")?.setAttribute("role", "dialog");
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade show");
            document.getElementById("tourVideo")?.setAttribute("style", "display:block");
            document.getElementById("tourFadeDiv")?.removeAttribute("hidden");
            //#endregion
        } catch (error) {
        }
    }
    const tourGuideOkClick = async () => {
        try {
            //#region Hide PopUp
            document.getElementsByClassName('videoTagMNC')[0]?.setAttribute("src", "");
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade");
            document.getElementById("tourVideo")?.setAttribute("style", "display:none");
            document.getElementById("tourVideo")?.setAttribute("aria-hidden", "true");
            document.getElementById("tourVideo")?.removeAttribute("aria-modal");
            document.getElementById("tourVideo")?.removeAttribute("role");
            document.getElementById("tourFadeDiv")?.setAttribute("hidden", "true");
            //#endregion
        } catch (error) {
        }
    }
    const hamburger = () => {
        try {
            let divElement: any = document.getElementById("navbarNavDropdown");

            divElement.hidden = !(divElement.hidden)

            let eleMigration: any = document.getElementById("ProfileMenu1");

            eleMigration.hidden = true;

            isProfileOpen = false;
        }
        catch (error: any) {

        }
    }
    const profileNav = async () => {
        try {
            let divElement: any = document.getElementById("navbarNavDropdown");

            divElement.hidden = true;



            isProfileOpen = isProfileOpen ? false : true;

            let eleMigration: any = document.getElementById("ProfileMenu1");

            eleMigration.hidden = !(isProfileOpen);
        } catch (error: any) {
        }
    }
    const migrationnav = () => {
        try {
            isMigOpen = isMigOpen ? false : true;
            let eleMigration: any = document.getElementById("MigrationMenu");
            eleMigration.hidden = isMigOpen;
            eleMigration.hasAttribute("hidden") ? eleMigration.removeAttribute("hidden") : eleMigration.setAttribute("hidden", "hidden");
        } catch (error: any) {
        }
    }

    return (
        <Router>
            {/* Navigation Menu Start */}
            <nav className="navbar navbar-expand-lg navbar-light navbar-color shadow-sm fixed-top">
                <div className="container-fluid ">
                    <a className="navbar-brand anchorAsPointer" href="/Settings/Slack">
                        <img src="/Images/brand_logo.svg" className="brandLogo" alt="Avamigratron" />
                    </a>
                    <div className='d-flex'>
                        <div className="nav-item profile-drop-down float-end font-14 d-lg-none d-block">
                            <a className="nav-link font-semibold font-14 py-0 my-2 color-black nav-header anchorAsPointer" id="profile" role="button">
                                <span onClick={() => profileNav()}>
                                    <img className="mobile-profile-sty" src="/Images/user_icon.svg" alt="user" />
                                </span>
                            </a>
                            <ul className="dropdown-menu profile-menu show" id="ProfileMenu1" hidden={true}>
                                <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                    <span data-tip={(objClient && objClient.CName) != undefined ? objClient.CName : ""} data-for='toolTip2' data-place='right'>{(objClient && objClient.CName) != undefined ? objClient.CName.slice(0, 20) : ""}
                                        <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                    </span>
                                    <span>
                                        <span className="d-block font-14 font-regular profile-mail" data-tip={(objClient && objClient.CMail) != undefined ? wrapppedClientMail : ""} data-for='toolTip1' data-place='right'>{(objClient && objClient.CMail) != undefined ? objClient.CMail.slice(0, 24) : ""}</span>
                                        <ReactTooltip id="toolTip1" class='mySepecialClass' multiline={true} />
                                    </span>
                                </li>
                                {/* <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3">{objClient.CName}
                                                    <span className="d-block font-14 font-regular profile-mail">{objClient.AMail}</span>
                                                </li> */}
                                <li className="py-2 font-14"><a className="dropdown-item profile-bg font-regular px-4" id="ProfileUpdate" href="/ProfileUpdate">Profile Update</a></li>
                                <li className="pb-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" onClick={tourGuideMenuClick}>Watch Demo Video</a></li>
                                <li className="logout-bg text-center font-14"><a className=" bkgColorLogout dropdown-item anchorAsPointer" onClick={clientLogOutClick}>Logout</a></li>
                            </ul>
                        </div>
                        <button className="navbar-toggler py-1 my-3 collapsed float-end" type="button" onClick={() => hamburger()} >
                            <span className="navbar-toggler-icon" />
                        </button>
                    </div>
                    <div className="navbar-collapse floatLeft width100" hidden={true} id="navbarNavDropdown">
                        {
                            (objClient != undefined && objAdmin != undefined) ?
                                //#region  Admin in Client View Start
                                <div className="navbar-collapse floatLeft width100 ">
                                    <ul className="navbar-nav">
                                        <li className="nav-item responsive-margin-none font-14">
                                            <a className={(state == undefined || state.isSettings == undefined || (state.isSettings != undefined && state.isSettings)) ? activeClass : inActiveClass} onClick={menuClick} id="Settings" href="/Settings/Slack">Settings</a>
                                        </li>
                                        {
                                            (objClient.isUserBasedLicense === true) ?
                                                <li className="nav-item responsive-margin-none font-14">
                                                    <a className={(state != undefined && state.isLicensedUser != undefined && state.isLicensedUser) ? activeClass : inActiveClass} onClick={menuClick} id="LicensedUsers" href="/LicensedUsers">License Assignment</a>
                                                </li>
                                                : ""}
                                        <li className="nav-item responsive-margin-none font-14">
                                            <a className={(state != undefined && state.isInventory != undefined && state.isInventory) ? activeClass : inActiveClass} aria-current="page" onClick={menuClick} id="Inventory" href="/Inventory/HighLevel">Inventory</a>
                                        </li>
                                        <li onClick={() => migrationnav()} className="nav-item dropdown dropdown-migration responsive-margin-none font-14 " onMouseEnter={() => document.getElementById("MigrationMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("MigrationMenu")?.setAttribute("hidden", "true")} >
                                            <a className={(state != undefined && state.isMigration != undefined && state.isMigration) ? activeMig : inActiveMig} id="Migration" role="button">
                                                Migration</a>
                                            {/* <img onClick={() => migrationnav()} src="/Images/accordian-open.svg" id='accordian-img' className={((state != undefined && state.isMigration != undefined && state.isMigration) || (activeMenu)) ? "migration-open-dropDown" : "inactive-migration-close-dropDown"} /> */}
                                            <ul className="dropdown-menu migration-menu show " id="MigrationMenu" hidden={true}>
                                                <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="UserMapping" onClick={menuClick} href="/UserMapping">User Mapping</a></li>
                                                <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="ChannelMigration" onClick={menuClick} href='/ChannelMigration'>Channel Migration</a></li>
                                                <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="MemberMigration" onClick={menuClick} href='/MemberMigration'>Member Migration</a></li>
                                                <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="ConversationMigration" onClick={menuClick} href='/Conversation'>Conversation Migration</a></li>
                                                <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="Reports" onClick={menuClick} href='/Report'>Reports</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown responsive-margin-none font-14">
                                            <a className={(state != undefined && state.isAdmin != undefined && state.isAdmin) ? activeClass : inActiveClass} onClick={menuClick} id="Admin" role="button" href='/Admin/ManageClients'>
                                                Admin
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                        <li className="nav-item responsive-margin-none font-14">
                                            <a className={(state != undefined && state.isContactUs != undefined && state.isContactUs) ? activeClass : inActiveClass} id="ContactUs" onClick={menuClick} href="/ContactUs">Contact Us</a>
                                        </li>
                                        <li className="nav-item responsive-margin-none font-14">
                                            <a className={(state != undefined && state.isUpgradePlan != undefined && state.isUpgradePlan) ? activeClass : inActiveClass} id="UpgradePlan" onClick={menuClick} href="/UpgradePlan">Upgrade Plan</a>
                                        </li>
                                        {/* profile starts here */}
                                        <li className="nav-item responsive-margin-none d-lg-block d-none profile-drop-down font-14" onMouseEnter={() => document.getElementById("ProfileMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("ProfileMenu")?.setAttribute("hidden", "true")} >
                                            <a className="nav-link font-semibold font-14 color-black nav-header anchorAsPointer" id="profile" role="button">
                                                <span>
                                                    <img className="px-2" src="/Images/user_icon.svg" alt="user" data-tip={objClient.CCompanyName} data-for='toolTip3' data-place='right' />{objClient.CCompanyName.slice(0, 20)}
                                                    <ReactTooltip id="toolTip3" class='mySepecialClass' />
                                                    <img src="/Images/accordian-open.svg" className="profile-open-dropDown" />
                                                </span>
                                            </a>
                                            <ul className="dropdown-menu profile-menu show" id="ProfileMenu" hidden>
                                                <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                                    <span data-tip={objClient.CName} data-for='toolTip2' data-place='right'>{objClient.CName.slice(0, 20)}
                                                        <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                                    </span>
                                                    <span>
                                                        <span className="d-block font-14 font-regular profile-mail" data-tip={objClient.CMail} data-for='toolTip1' data-place='right'>{objClient.CMail.slice(0, 24)}</span>
                                                        <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                    </span>
                                                </li>
                                                {/* <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3">{objClient.CName}
                                                    <span className="d-block font-14 font-regular profile-mail">{objClient.AMail}</span>
                                                </li> */}
                                                <li className="py-2 font-14"><a className="dropdown-item profile-bg font-regular px-4" id="ProfileUpdate" href="/ProfileUpdate">Profile Update</a></li>
                                            </ul>
                                        </li>
                                        {/* profile ends here */}
                                    </ul>
                                </div>
                                //#endregion
                                :
                                // <div className="navbar-collapse floatLeft width100 collapse" id="navbarNavDropdown">
                                <div className="floatLeft width100">
                                    {
                                        (objAdmin != undefined) ?
                                            // #region Admin Only Start
                                            <div className="floatLeft width100">
                                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 floatRight">
                                                    <li className="nav-item profile-drop-down d-lg-block d-none" onMouseEnter={() => document.getElementById("ProfileMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("ProfileMenu")?.setAttribute("hidden", "true")} >
                                                        <a className="nav-link font-semibold font-16 color-black nav-header anchorAsPointer" id="profile" role="button">
                                                            <span>
                                                                <img className="px-2" src="/Images/user_icon.svg" alt="user" data-tip={objAdmin.AName} data-for='toolTip3' data-place='right' /> {objAdmin.AName.slice(0, 20)}
                                                                <ReactTooltip id="toolTip3" class='mySepecialClass' />
                                                                <img src="/Images/accordian-open.svg" className="profile-open-dropDown" />
                                                            </span>
                                                        </a>
                                                        <ul className="dropdown-menu profile-menu show" id="ProfileMenu" hidden>
                                                            <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                                                <span data-tip={objAdmin.AName} data-for='toolTip2' data-place='right'>{objAdmin.AName.slice(0, 20)}
                                                                    <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                                                </span>
                                                                <span>
                                                                    <span className="d-block font-14 font-regular profile-mail" data-tip={objAdmin.AMail} data-for='toolTip1' data-place='right'>{objAdmin.AMail.slice(0, 20)}</span>
                                                                    <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                                </span>
                                                            </li>
                                                            {/* <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" data-tip={objAdmin.AMail} data-for='toolTip1' data-place='right'>{objAdmin.AName.slice(0, 20)}
                                                                <span className="d-block font-14 font-regular profile-mail" data-tip={objAdmin.AMail} data-for='toolTip1' data-place='right'>{objAdmin.AMail.slice(0, 20)}</span>
                                                                <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                            </li> */}
                                                            <li className="logout-bg text-center"><a className="dropdown-item anchorAsPointer" onClick={adminLogOutClick}>Logout</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                            //#endregion
                                            :
                                            //#region Client Only Start
                                            <div className="floatLeft width100">
                                                {
                                                    (objClient != undefined && objClient.IsInitialLogin == false && objClient.isClientExpired == false) ?

                                                        //#region Client After Plan Selection
                                                        <div className="navbar-collapse floatLeft width100">
                                                            <ul className="navbar-nav">
                                                                <li className="nav-item responsive-margin-none font-14">
                                                                    <a className={(state == undefined || state.isSettings == undefined || (state.isSettings != undefined && state.isSettings)) ? activeClass : inActiveClass} onClick={menuClick} id="Settings" href="/Settings/Slack">Settings</a>
                                                                </li>
                                                                {
                                                                    (objClient.isUserBasedLicense === true) ?
                                                                        <li className="nav-item responsive-margin-none font-14">
                                                                            <a className={(state != undefined && state.isLicensedUser != undefined && state.isLicensedUser) ? activeClass : inActiveClass} onClick={menuClick} id="LicensedUsers" href="/LicensedUsers">License Assignment</a>
                                                                        </li>
                                                                        : ""}
                                                                <li className="nav-item responsive-margin-none font-14">
                                                                    <a className={(state != undefined && state.isInventory != undefined && state.isInventory) ? activeClass : inActiveClass} aria-current="page" onClick={menuClick} id="Inventory" href="/Inventory/HighLevel">Inventory</a>
                                                                </li>
                                                                <li onClick={() => migrationnav()} className="nav-item dropdown dropdown-migration responsive-margin-none font-14" onMouseEnter={() => document.getElementById("MigrationMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("MigrationMenu")?.setAttribute("hidden", "true")} >
                                                                    <a className={(state != undefined && state.isMigration != undefined && state.isMigration) ? activeMig : inActiveMig} id="Migration" role="button">
                                                                        Migration</a>
                                                                    {/* <img onClick={() => migrationnav()} src="/Images/accordian-open.svg" className={((state != undefined && state.isMigration != undefined && state.isMigration) || (activeMenu)) ? "migration-open-dropDown" : "inactive-migration-close-dropDown"} /> */}
                                                                    <ul className="dropdown-menu migration-menu show" id="MigrationMenu" hidden={true}>
                                                                        <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="UserMapping" onClick={menuClick} href="/UserMapping" >User Mapping</a></li>
                                                                        <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="ChannelMigration" onClick={menuClick} href='/ChannelMigration'>Channel Migration</a></li>
                                                                        <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="MemberMigration" onClick={menuClick} href='/MemberMigration'>Member Migration</a></li>
                                                                        <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="ConversationMigration" onClick={menuClick} href='/Conversation'>Conversation Migration</a></li>
                                                                        <li><a className="dropdown-item font-14 font-regular anchorAsPointer" id="Reports" onClick={menuClick} href='/Report' >Reports</a></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                                                <li className="nav-item  responsive-margin-none  font-14">
                                                                    <a className={inActiveClass/*(state != undefined && state.isContactUs != undefined && state.isContactUs) ? activeClass : inActiveClass*/} id="ContactUs" onClick={menuClick} href="/ContactUs">Contact Us</a>
                                                                </li>
                                                                <li className="nav-item responsive-margin-none font-14">
                                                                    <a className={inActiveClass/*(state != undefined && state.isUpgradePlan != undefined && state.isUpgradePlan) ? activeClass : inActiveClass*/} id="UpgradePlan" onClick={menuClick} href="/UpgradePlan">Upgrade Plan</a>
                                                                </li>
                                                                {/* profile starts here */}
                                                                <li className="nav-item responsive-margin-none d-lg-block d-none profile-drop-down font-14" onMouseEnter={() => document.getElementById("ProfileMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("ProfileMenu")?.setAttribute("hidden", "true")} >
                                                                    <a className="nav-link font-semibold font-14 color-black nav-header anchorAsPointer" id="profile" role="button">
                                                                        <span>
                                                                            <img className="px-2" src="/Images/user_icon.svg" alt="user" data-tip={objClient.CCompanyName} data-for='toolTip3' data-place='right' />{objClient.CCompanyName.slice(0, 20)}
                                                                            <ReactTooltip id="toolTip3" class='mySepecialClass' />
                                                                            <img src="/Images/accordian-open.svg" className="profile-open-dropDown" />
                                                                        </span>
                                                                    </a>
                                                                    {/* <a className="nav-link dropdown-toggle font-semibold font-16 color-black nav-header" onClick={profileMenuClick} id="profile" role="button">
                                                                        <img className="px-2" src="/Images/user_icon.svg" alt="user" /> {objClient.CCompanyName}
                                                                    </a> */}
                                                                    <ul className="dropdown-menu profile-menu show" id="ProfileMenu" hidden>
                                                                        <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                                                            <span data-tip={objClient.CName} data-for='toolTip2' data-place='right'>{objClient.CName.slice(0, 20)}
                                                                                <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                                                            </span>
                                                                            <span>
                                                                                <span className="d-block font-14 font-regular profile-mail" data-tip={objClient.CMail} data-for='toolTip1' data-place='right'>{objClient.CMail.slice(0, 24)}</span>
                                                                                <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                                            </span>
                                                                        </li>
                                                                        <li className="py-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" id="ProfileUpdate" href="/ProfileUpdate">Profile Update</a></li>
                                                                        <li className="pb-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" onClick={tourGuideMenuClick}>Watch Demo Video</a></li>
                                                                        <li className="logout-bg text-center font-14"><a className="bkgColorLogout dropdown-item anchorAsPointer" onClick={clientLogOutClick}>Logout</a></li>
                                                                    </ul>
                                                                </li>
                                                                {/* profile ends here */}
                                                            </ul>
                                                        </div>
                                                        //#endregion
                                                        : (objClient != undefined && objClient.IsInitialLogin == true && objClient.isClientExpired == false) ?

                                                            //#region Client Before Plan Selection
                                                            <div className="navbar-collapse floatLeft width100">
                                                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 floatRight">
                                                                    <li className="nav-item  responsive-margin-none  font-14">
                                                                        <a className={inActiveClass/*(state != undefined && state.isContactUs != undefined && state.isContactUs) ? activeClass : inActiveClass*/} id="ContactUs" onClick={menuClick} href="/ContactUs">Contact Us</a>
                                                                    </li>
                                                                    <li className="nav-item responsive-margin-none font-14">
                                                                        <a className={inActiveClass/*(state != undefined && state.isUpgradePlan != undefined && state.isUpgradePlan) ? activeClass : inActiveClass*/} id="ChoosePlan" onClick={menuClick} href="/ChoosePlan">Choose Plan</a>
                                                                    </li>
                                                                    {/* profile starts here */}
                                                                    <li className="nav-item responsive-margin-none d-lg-block d-none profile-drop-down font-14" onMouseEnter={() => document.getElementById("ProfileMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("ProfileMenu")?.setAttribute("hidden", "true")} >
                                                                        <a className="nav-link font-semibold font-14 color-black nav-header anchorAsPointer" id="profile" role="button">
                                                                            <span>
                                                                                <img className="px-2" src="/Images/user_icon.svg" alt="user" data-tip={objClient.CCompanyName} data-for='toolTip3' data-place='right' />{objClient.CCompanyName.slice(0, 20)}
                                                                                <ReactTooltip id="toolTip3" class='mySepecialClass' />
                                                                                <img src="/Images/accordian-open.svg" className="profile-open-dropDown" />
                                                                            </span>
                                                                        </a>
                                                                        {/* <a className="nav-link dropdown-toggle font-semibold font-16 color-black nav-header" onClick={profileMenuClick} id="profile" role="button">
                                                                        <img className="px-2" src="/Images/user_icon.svg" alt="user" /> {objClient.CCompanyName}
                                                                    </a> */}
                                                                        <ul className="dropdown-menu profile-menu show" id="ProfileMenu" hidden>
                                                                            <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                                                                <span data-tip={objClient.CName} data-for='toolTip2' data-place='right'>{objClient.CName.slice(0, 20)}
                                                                                    <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                                                                </span>
                                                                                <span>
                                                                                    <span className="d-block font-14 font-regular profile-mail" data-tip={objClient.CMail} data-for='toolTip1' data-place='right'>{objClient.CMail.slice(0, 24)}</span>
                                                                                    <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                                                </span>
                                                                            </li>
                                                                            {/* <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3">{objClient.CName}<span className="d-block font-14 font-regular profile-mail">{objClient.CMail}</span></li> */}
                                                                            <li className="py-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" id="ProfileUpdate" href="/ProfileUpdate">Profile Update</a></li>
                                                                            <li className="pb-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" onClick={tourGuideMenuClick}>Watch Demo Video</a></li>
                                                                            <li className="logout-bg text-center font-14"><a className=" bkgColorLogout dropdown-item anchorAsPointer" onClick={clientLogOutClick}>Logout</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    {/* profile ends here */}
                                                                </ul>
                                                            </div>
                                                            //#endregion
                                                            :
                                                            //#region Client Plan Expiry Selection
                                                            <div className="navbar-collapse floatLeft width100">
                                                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 floatRight">
                                                                    <li className="nav-item  responsive-margin-none  font-14">
                                                                        <a className={inActiveClass/*(state != undefined && state.isContactUs != undefined && state.isContactUs) ? activeClass : inActiveClass*/} id="ContactUs" onClick={menuClick} href="/ContactUs">Contact Us</a>
                                                                    </li>
                                                                    <li className="nav-item responsive-margin-none font-14">
                                                                        <a className={inActiveClass/*(state != undefined && state.isUpgradePlan != undefined && state.isUpgradePlan) ? activeClass : inActiveClass*/} id="UpgradePlan" onClick={menuClick} href="/UpgradePlan">Upgrade Plan</a>
                                                                    </li>
                                                                    {/* profile starts here */}
                                                                    <li className="nav-item responsive-margin-none d-lg-block d-none profile-drop-down font-14" onMouseEnter={() => document.getElementById("ProfileMenu")?.removeAttribute("hidden")} onMouseLeave={() => document.getElementById("ProfileMenu")?.setAttribute("hidden", "true")} >
                                                                        <a className="nav-link font-semibold font-14 color-black nav-header anchorAsPointer" id="profile" role="button">
                                                                            <span>
                                                                                <img className="px-2" src="/Images/user_icon.svg" alt="user" data-tip={objClient.CCompanyName} data-for='toolTip3' data-place='right' />{objClient.CCompanyName.slice(0, 20)}
                                                                                <ReactTooltip id="toolTip3" class='mySepecialClass' />
                                                                                <img src="/Images/accordian-open.svg" className="profile-open-dropDown" />
                                                                            </span>
                                                                        </a>
                                                                        {/* <a className="nav-link dropdown-toggle font-semibold font-16 color-black nav-header" onClick={profileMenuClick} id="profile" role="button">
            <img className="px-2" src="/Images/user_icon.svg" alt="user" /> {objClient.CCompanyName}
        </a> */}
                                                                        <ul className="dropdown-menu profile-menu show" id="ProfileMenu" hidden>
                                                                            <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3" >
                                                                                <span data-tip={objClient.CName} data-for='toolTip2' data-place='right'>{objClient.CName.slice(0, 20)}
                                                                                    <ReactTooltip id="toolTip2" class='mySepecialClass' />
                                                                                </span>
                                                                                <span>
                                                                                    <span className="d-block font-14 font-regular profile-mail" data-tip={objClient.CMail} data-for='toolTip1' data-place='right'>{objClient.CMail.slice(0, 24)}</span>
                                                                                    <ReactTooltip id="toolTip1" class='mySepecialClass' />
                                                                                </span>
                                                                            </li>
                                                                            {/* <li className="profile-item font-18 font-semibold border-bottom profile-name px-4 py-3">{objClient.CName}<span className="d-block font-14 font-regular profile-mail">{objClient.CMail}</span></li> */}
                                                                            <li className="py-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" id="ProfileUpdate" href="/ProfileUpdate">Profile Update</a></li>
                                                                            <li className="pb-2 font-14"><a className="dropdown-item profile-bg font-regular px-4 anchorAsPointer" onClick={tourGuideMenuClick}>Watch Demo Video</a></li>
                                                                            <li className="logout-bg text-center font-14"><a className=" bkgColorLogout dropdown-item anchorAsPointer" onClick={clientLogOutClick}>Logout</a></li>
                                                                        </ul>
                                                                    </li>
                                                                    {/* profile ends here */}
                                                                </ul>
                                                            </div>
                                                    //#endregion

                                                }
                                            </div>
                                        //#endregion
                                    }
                                </div>
                        }
                        {/* </div> */}
                    </div>

                </div>
            </nav>
            {/* Navigation Menu End */}
            {/* Body Content Start */}
            <div className="container-bg-withFooter" id='MainContainerMFE'>
                {
                    (objAdmin != undefined && objClient != undefined) ?
                        //#region Admin in Client View
                        <div>
                            {((locationPath.includes("/ChannelMigration") && !objClient.IsChannel) ||
                                (locationPath.includes("/Conversation/") && !objClient.IsChat) ||
                                (locationPath.includes("/UserMapping") && (!objClient.IsChannel && !objClient.IsChat)) ||
                                (locationPath.includes("/Report") && (!objClient.IsChannel && !objClient.IsChat)) ||
                                (locationPath.includes("/MemberMigration") && (!objClient.IsChannel))) ?
                                <AccessDenied /> :
                                <Switch>
                                    {/* Settings Module route Starts Here */}
                                    <Route exact path="/Settings/Slack" component={SettingMasterComponent}></Route>
                                    <Route exact path="/Settings/MSTeams" component={SettingMasterComponent}></Route>
                                   <Route exact path="/Settings/MSTeams/Redirect" component={RedirectPage}></Route>
                                   <Route exact path="/Settings/Slack/Redirect" component={RedirectPage}></Route>
                                    <Route exact path="/Settings">
                                        <Redirect to="/Settings/Slack"></Redirect>
                                    </Route>
                                    {/* Settings Module route Ends Here */}

                                    {/* Licensed User Module route Starts Here */}
                                    <Route exact path="/LicensedUsers" component={LicensedUsers} />
                                    {/* Licensed User Module route Ends Here */}

                                    {/* Inventory Module route Starts Here */}
                                    <Route exact path="/Inventory/HighLevel"  component={InventoryRouting}/>                                   
                                    <Route exact path="/Inventory/Detailed" component={InventoryRouting} />
                                   <Route exact path="/Inventory/UserVerification" component={InventoryRouting} />
                                   <Route exact path="/Inventory/Users" component={InventoryRouting} />
                                   <Route exact path="/Inventory/Usergroups" component={InventoryRouting} />
                                   <Route exact path="/Inventory/UserGroup/UGMembers" component={InventoryRouting} />
                                   <Route exact path="/Inventory/ThirdPartyApp" component={InventoryRouting} />
                                  <Route exact path="/Inventory/Channel" component={InventoryRouting} />
                                  <Route exact path="/Inventory/Channel/Members" component={InventoryRouting} />
                                  <Route exact path="/Inventory/DM" component={InventoryRouting} />
                                  <Route exact path="/Inventory/MM" component={InventoryRouting} />
                                  <Route exact path="/Inventory/InProgress" component={InventoryRouting} />
                                  <Route exact path="/">
                                  <Redirect to="/Inventory/HighLevel"></Redirect>
                                  </Route>
                                  <Route exact path="/Inventory">
                                  <Redirect to="/Inventory/HighLevel"></Redirect>
                                </Route>
                                    {/* Inventory Module route Ends Here */}
                                    {/* Report Module route Starts Here */}
                                    <Route exact path='/Report' component={ReportLanding}></Route>
                                    <Route exact path='/ReportDetails' component={GridComponent}></Route>
                                    {/* Report Module route Ends Here */}
                                    {/* User Mapping Module route Starts Here */}
                                    <Route exact path="/UserMapping" component={UserMappingMasterComponent}></Route>
                                    {/* User Mapping Module route Ends Here */}
                                    {/* Channel Migration Module route Starts Here */}
                                    <Route exact path="/ChannelMigration/SA" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/TeamChannelMapping" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/ConversationMigration/ServiceAccounts" component={ChannelRouting} />
                                    <Route exact path="/ChannelMigration/SA/ConversationMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/ScheduledMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/TeamChannelMapping" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/ConversationMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/ConversationMigration/ServiceApps" component={ChannelRouting} />
                                    <Route exact path="/ChannelMigration/AU/ScheduledMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/TeamCompletion" component={ChannelRouting}></Route> 
                                    <Route exact path="/ChannelMigration/AU/TeamChannelCompletion" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration" component={ChannelRouting}></Route>
                                    {/* Channel Mapping Module route Ends Here */}
                                    {/* Conversation Migration Module route Starts Here */}
                                    {(objClient.isChatServiceAccountMigration || objClient.isChatHTMLMigration) ? <Route exact path="/Conversation" component={GridPage} /> : <Route exact path="/Conversation" component={MSTeamsUserVerification} />}
                                    <Route exact path="/Conversation/AU/ConversationMigration" component={GroupChatRouting} />
                                    <Route exact path="/Conversation/AU/ChatMapping" component={GroupChatRouting} />
                                    <Route exact path="/Conversation/AU/UserVerification" component={GroupChatRouting} />
                                     <Route exact path="/Conversation/SA/ConversationMigration" component={GroupChatRouting} /> 
                                   <Route exact path="/Conversation/SA/ChatMapping" component={GroupChatRouting} /> 
                                     <Route exact path="/Conversation/SA/ConversationMigration/ServiceAccounts" component={GroupChatRouting} /> 
                                    <Route exact path="/Conversation/ChatHtmlFolderCreation" component={GroupChatRouting} />
                                     <Route exact path="/Conversation/ChatHtmlMigration" component={GroupChatRouting} /> 
                                     <Route exact path="/Conversation" component={GridPage}></Route>

                                    {/* Conversation Migration Module route Ends Here */}
                                    
                                    {/* Member Migration Module route Starts Here */}
                                    <Route path="/MemberMigration/TeamMembers" component={MemberMigrationRouting}></Route>
                                    <Route path="/MemberMigration/ChannelMembers" component={MemberMigrationRouting}></Route>
                                    <Route path="/MemberMigration" component={MemberMigrationRouting}></Route>
                                    {/* Member Migration Module route Ends Here */}
                                    {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
                                    <Route path="/UpgradePlan" component={UpgradePlan}></Route>
                                    <Route path="/ContactUs" component={ContactUs}></Route>
                                    <Route path="/ProfileUpdate" component={ProfileUpdate}></Route>
                                    <Route path="/ChoosePlan" component={UpgradePlan}></Route>                                   
                                    {/* Registration Module route Ends Here */}
                                    {/* Registration Module route Starts Here */}
                                    <Route exact path="/Login" component={RegistrationRouting}></Route>
                                    <Route exact path="/VerifyEmail" component={RegistrationRouting}></Route>
                                    <Route exact path="/Registration" component={RegistrationRouting}></Route>
                                    <Route exact path="/ForgotPassword" component={RegistrationRouting}></Route>
                                    <Route exact path="/SignUp" component={RegistrationRouting}></Route>
                                    {/* Registration Module route Ends Here */}
                                    {/* Admin Module route Starts Here */}
                                    <Route exact path="/Admin/ManageClients/EditClient" component={AdminRouting}></Route>
                                    <Route exact path="/Admin/ManageClients/NewClient" component={AdminRouting}></Route>
                                    <Route exact path="/Admin/ManageClients" component={AdminRouting}></Route>
                                    <Route exact path="/Admin/Login" component={AdminRouting}></Route>

                                    <Route exact path="/Admin">
                                        <Redirect to="/Admin/ManageClients" ></Redirect>
                                    </Route>
                                    {/* Admin Module route Ends Here */}
                                    <Route exact path="/"> <Redirect to="/Settings/Slack"> </Redirect> </Route>
                                    <Route path="*" component={PageNotFound} />
                                </Switch>
                            }
                        </div>
                        // <div>
                        //     <p>1condition</p>
                        // </div>
                        //#endregion
                        :
                        <div>
                            {
                                (locationPath.includes("/Admin") && objAdmin != undefined) ?
                                    //#region Admin Only
                                    <div>
                                        <Switch>
                                            {/* Admin Module route Starts Here */}
                                            <Route exact path="/Admin/ManageClients/EditClient" component={AdminRouting}></Route>
                                            <Route exact path="/Admin/ManageClients/NewClient" component={AdminRouting}></Route>
                                            <Route exact path="/Admin/ManageClients" component={AdminRouting}></Route>
                                            <Route exact path="/Admin">
                                                <Redirect to="/Admin/ManageClients" ></Redirect>
                                            </Route>
                                            {/* Admin Module route Ends Here */}
                                        </Switch>
                                    </div>
                                    // <div>
                                    //     <p>2condition</p>
                                    // </div>
                                    //#endregion
                                    :
                                    <div>
                                        {
                                            (objClient != undefined && objClient.IsInitialLogin == false) ?
                                                //#region Client Only - After Plan Selection
                                                <div>
                                                    {((locationPath.includes("/ChannelMigration") && !objClient.IsChannel) ||
                                                        (locationPath.includes("/Conversation/") && !objClient.IsChat) ||
                                                        (locationPath.includes("/UserMapping") && (!objClient.IsChannel && !objClient.IsChat)) ||
                                                        (locationPath.includes("/Report") && (!objClient.IsChannel && !objClient.IsChat)) ||
                                                        (locationPath.includes("/MemberMigration") && (!objClient.IsChannel))) ?
                                                        <AccessDenied />
                                                        :
                                                        <Switch>
                                                            {/* Settings Module route Starts Here */}
                                                            <Route exact path="/Settings/Slack" component={SettingMasterComponent}></Route>
                                    <Route exact path="/Settings/MSTeams" component={SettingMasterComponent}></Route>
                                   <Route exact path="/Settings/MSTeams/Redirect" component={RedirectPage}></Route>
                                   <Route exact path="/Settings/Slack/Redirect" component={RedirectPage}></Route>
                                                            <Route exact path="/Settings">
                                                                <Redirect to="/Settings/Slack"></Redirect>
                                                            </Route>
                                                            {/* Settings Module route Ends Here */}
                                                            {/* Licensed User Module route Starts Here */}
                                                            <Route exact path="/LicensedUsers" component={LicensedUsers} />
                                                            {/* Licensed User Module route Ends Here */}
                                                            {/* Inventory Module route Starts Here */}
                                                            <Route exact path="/Inventory/HighLevel"  component={InventoryRouting}/>                                   
                                    <Route exact path="/Inventory/Detailed" component={InventoryRouting} />
                                   <Route exact path="/Inventory/UserVerification" component={InventoryRouting} />
                                   <Route exact path="/Inventory/Users" component={InventoryRouting} />
                                   <Route exact path="/Inventory/Usergroups" component={InventoryRouting} />
                                   <Route exact path="/Inventory/UserGroup/UGMembers" component={InventoryRouting} />
                                   <Route exact path="/Inventory/ThirdPartyApp" component={InventoryRouting} />
                                  <Route exact path="/Inventory/Channel" component={InventoryRouting} />
                                  <Route exact path="/Inventory/Channel/Members" component={InventoryRouting} />
                                  <Route exact path="/Inventory/DM" component={InventoryRouting} />
                                  <Route exact path="/Inventory/MM" component={InventoryRouting} />
                                  <Route exact path="/Inventory/InProgress" component={InventoryRouting} />
                                                            <Route exact path="/Inventory">
                                                                <Redirect to="/Inventory/HighLevel"></Redirect>
                                                            </Route>
                                                            {/* Inventory Module route Ends Here */}
                                                            {/* Report Module route Starts Here */}
                                                            <Route exact path='/Report' component={ReportLanding}></Route>
                                                            <Route exact path='/ReportDetails' component={GridComponent}></Route>
                                                            {/* Report Module route Ends Here */}
                                                            {/* User Mapping Module route Starts Here */}
                                                            <Route exact path="/UserMapping" component={UserMappingMasterComponent}></Route>
                                                            {/* User Mapping Module route Ends Here */}
                                                            {/* Channel Migration Module route Starts Here */}
                                                            <Route exact path="/ChannelMigration/SA" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/TeamChannelMapping" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/ConversationMigration/ServiceAccounts" component={ChannelRouting} />
                                    <Route exact path="/ChannelMigration/SA/ConversationMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/SA/ScheduledMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/TeamChannelMapping" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/ConversationMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/ConversationMigration/ServiceApps" component={ChannelRouting} />
                                    <Route exact path="/ChannelMigration/AU/ScheduledMigration" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration/AU/TeamCompletion" component={ChannelRouting}></Route> 
                                    <Route exact path="/ChannelMigration/AU/TeamChannelCompletion" component={ChannelRouting}></Route>
                                    <Route exact path="/ChannelMigration" component={ChannelRouting}></Route>
                                                            {/* Channel Mapping Module route Ends Here */}
                                                            {/* Conversation Migration Module route Starts Here */}
                                                            <Route exact path="/Conversation/AU/ConversationMigration" component={GroupChatRouting} />
                                    <Route exact path="/Conversation/AU/ChatMapping" component={GroupChatRouting} />
                                    <Route exact path="/Conversation/AU/UserVerification" component={GroupChatRouting} />
                                     <Route exact path="/Conversation/SA/ConversationMigration" component={GroupChatRouting} /> 
                                   <Route exact path="/Conversation/SA/ChatMapping" component={GroupChatRouting} /> 
                                     <Route exact path="/Conversation/SA/ConversationMigration/ServiceAccounts" component={GroupChatRouting} /> 
                                    <Route exact path="/Conversation/ChatHtmlFolderCreation" component={GroupChatRouting} />
                                     <Route exact path="/Conversation/ChatHtmlMigration" component={GroupChatRouting} /> 
                                     <Route exact path="/Conversation" component={GroupChatRouting}></Route>

                                                            {/* Conversation Migration Module route Ends Here */}
                                                           
                                                            {/* Member Migration Module route Starts Here */}
                                                            <Route path="/MemberMigration/TeamMembers" component={MemberMigrationRouting}></Route>
                                                            <Route path="/MemberMigration/ChannelMembers" component={MemberMigrationRouting}></Route>
                                    <                        Route path="/MemberMigration" component={MemberMigrationRouting}></Route>
                                                            {/* Member Migration Module route Ends Here */}
                                                            {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
                                                            <Route path="/ChoosePlan" component={UpgradePlan}></Route>
                                                            <Route path="/UpgradePlan" component={UpgradePlan}></Route>
                                                            <Route path="/ContactUs" component={ContactUs}></Route>
                                                            <Route path="/ProfileUpdate" component={ProfileUpdate}></Route>
                                                            <Route path="/SuccessPage" component={ResgistrationRouter}></Route>
                                                            {/* Registration Module route Ends Here */}
                                                            {/* Registration Module route Starts Here */}
                                                            <Route exact path="/Login" component={RegistrationRouting}></Route>
                                                           <Route exact path="/VerifyEmail" component={RegistrationRouting}></Route>
                                                           <Route exact path="/Registration" component={RegistrationRouting}></Route>
                                                           <Route exact path="/ForgotPassword" component={RegistrationRouting}></Route>
                                                           <Route exact path="/SignUp" component={RegistrationRouting}></Route>
                                                            {/* Registration Module route Ends Here */}
                                                            <Route exact path="/"> <Redirect to="/Settings/Slack"> </Redirect> </Route>
                                                        </Switch>
                                                    }
                                                </div>
                                                // <div>
                                                //     <p>3-1condition</p>
                                                // </div>
                                                //#endregion
                                                //#endregion
                                                : (objClient != undefined && objClient.IsInitialLogin == true) ?

                                                    //#region Client Only - Before Plan Selection
                                                    <Switch>
                                                        {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
                                                        <Route path="/UpgradePlan" component={UpgradePlan}></Route>
                                                            <Route path="/ContactUs" component={ContactUs}></Route>
                                                            <Route path="/ProfileUpdate" component={ProfileUpdate}></Route>
                                                            <Route path="/ChoosePlan" component={UpgradePlan}></Route>

                                                        {/* Registration Module route Ends Here */}
                                                        <Route path="/">
                                                            <Redirect to="/ChoosePlan"></Redirect>
                                                        </Route>
                                                    </Switch>
                                                    :
                                                    <Switch>
                                                        {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
                                                        <Route path="/UpgradePlan" component={UpgradePlan}></Route>
                                                            <Route path="/ContactUs" component={ContactUs}></Route>
                                                            <Route path="/ProfileUpdate" component={ProfileUpdate}></Route>
                                                        {/* Registration Module route Ends Here */}
                                                        <Route path="/">
                                                            <Redirect to="/UpgradePlan"></Redirect>
                                                        </Route>
                                                    </Switch>

                                            //#endregion
                                        }
                                    </div>
                            }
                        </div>
                }
            </div>
            {/* Body Content Start */}
            {/* TourGuide starts */}
            <div className="modal fade" id="tourVideo" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true" >
                <div className="modal-dialog tourGuide modal-lg">
                    <div className="modal-content">
                        <div className="modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label mb-3 w-100">
                                            <span className="name-style font-24 font-semibold">Welcome,</span><span className="name-style2 font-24 font-semibold"> {(objClient != undefined && objClient.CName != undefined) ? objClient.CName.slice(0, 20) : ""}!</span></label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">To get a feel of the
                                            place, take our guided tour, where we'll get you set up and <br />
                                            show you how to start inventory and migration</label>
                                    </div>
                                    <div className="w-100 float-start text-center">
                                        <video width="100%" className='videoTagMNC' height="315" controls controlsList="nodownload" />
                                        {/* <iframe   width={560} height={315} src="https://www.youtube.com/embed/U-gwTVRU248" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/> */}
                                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/U-gwTVRU248" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mb-4">
                            <button type="button" className="btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={tourGuideOkClick}>Skip</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* TourGuide ends */}
            <div id="tourFadeDiv" className="modal-backdrop fade show" hidden></div>
        </Router >
    );
}