import { sendRequestToNodeServices, APIService, serverDownApiService } from '../Client/Client';
import constants from "../Common/Constants";


let baseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication`;

let serverBaseURL = `${constants.URL.REACT_APP_SRVADMPRTL_BASEURL}`

export const sendRequestToNodeServicesApiTS = (config: any) => {
    try {
        return sendRequestToNodeServices((baseURL + config.endpoint), { method: 'POST', body: config.data }, config.token);

    } catch (error) {

        throw error;

    }
}

export const sendRequest = (loadGridPostData: any) => {
    return sendRequestToNodeServices(loadGridPostData.url, { method: 'POST', body: loadGridPostData.data }, loadGridPostData.token);
}

export const gridLoad = async (loadGridPostData: any) => {
    
    return await APIService(loadGridPostData.url, { method: 'POST', body: loadGridPostData.data }, loadGridPostData.token);
}


export const serverDetail=( loadGridPostData : any) =>
{
    return serverDownApiService(serverBaseURL+ loadGridPostData.endpoint,{method:'POST' , body : loadGridPostData.data});

}
