import React, { useEffect, useState } from 'react';


export default function BackNavigation(backNav: any) {
    useEffect(() => {

    })
    return (
        <div className="col-md-12 mt-4">
            <h4 className="au-heading-font font-bold">
                <a  className='anchorAsPointer' href={backNav.link}><span><img className='test1 anchorAsPointer' src="/Images/left-arrow.svg" alt="left-arrow" title="Back" /></span></a>
                {" " + (backNav.backNav) +" "}
            </h4>
        </div>
    );
}