import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/UserMappingServices';
import ReactTooltip from 'react-tooltip';
import dateFormat from "dateformat"
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv';
import { off } from 'process';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';



export default function GridPage() {
  let GridLoadarray: any[] = [];
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  let [exportsearchValue, setexportsearchValue] = useState("");
  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [sourceUsersCount, setsourceUsersCount] = useState(1);
  const [targetUsersCount, settargetUsersCount] = useState(1);
  const [WorkspaceName, setWorkspaceName] = useState("");
  const [TenantName, setTenantName] = useState("");
  let [alertStatus, setalertStatus] = useState("")
  const date1 = new Date();
  const offset = date1.getTimezoneOffset();
  let [offSet, setoffSet] = useState(offset)
  const state = useLocation().state as any;
  let clientID = state.clientID;
  let clientToken = state.clientToken;
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;
  let history = useHistory();
  const [chatType, setChatType] = useState(state.ChatType)
  const [MigratedDateTime, setMigratedDateTim] = useState(state.navTime)
  const [countPerPage, setCoutPerPage] = useState(10);


  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let byteValue = objCryptoJS.AES.decrypt(clientToken, r_n_Key);
  let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
  let objClient: any = jwt(decryptedValue);
  SessionEpiredDateTime = objClient.CreatedAt;

  useEffect(() => {
    resetSessionTimer();
    let footercomponent: any = document.getElementById("MainContainerMFE");
    footercomponent.classList.add("container-bg-withoutFooter")
    loadGridPage();
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])


  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertStatus = "Your session has expired. Please log in again to continue using the application."
      setalertStatus(alertStatus);

      //#region Show PopUp
      let popup = document.getElementById("wrongalert")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion


  const loadGridPage = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getIndividualReport`,
          token: clientToken,
          data:
          {
            "clientID": clientID,
            "type": "Grid",
            "chatType": `${chatType}`,
            "searchText": `${searchValue}`,
            "pageIndex": indexPerPage,
            "MigratedDateTime": `${MigratedDateTime}`,
            "status": "success",
            "offset": offSet
          }
        })
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data == "No Records Found") {
          if (data.data.statusCode == 200) {
            setGridData(["No Records Found"]);
            //setWorkspaceName("NA");
            //setTenantName("NA");
            setTotalItemsCount(0);
          }
          else {
            setGridData(["No Records Found"]);
            setWorkspaceName("NA");
            setTenantName("NA");
            setTotalItemsCount(0);

          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          if (data.data.statusCode == 200) {
            setGridData(data.data.data[0]);
            setTotalItemsCount(data.data.data[1][0].ReportCount);
            setWorkspaceName(data.data.data[0][0].WorkspaceName);
            setTenantName(data.data.data[0][0].TenantName);
          }
          else {
            setalertStatus("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
            document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
            document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
            document.getElementById("wrongalert")?.setAttribute("role", "dialog");
            document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
            document.getElementById("wrongalert")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
          }

        }
      }
      else {
        localStorage.clear()
      }

    }
    catch (error: any) {
      setalertStatus("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }

  const browsealertokclick = () => {
    try {
      if (isSessionEpired === false) {

        document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
        document.getElementById("wrongalert")?.setAttribute("style", "display:none");
        document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
        document.getElementById("wrongalert")?.removeAttribute("aria-modal");
        document.getElementById("wrongalert")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";

      }
    }
    catch (error) {
    }
  }

  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return gridData.map((obj, index) => {
          let Time = obj.IndividualItemMigratedDateTime;
          let migratedTime: any = Time.split(' ')[1];

          let millisecond = migratedTime.split('.')[0];
          //  let millisecond1 = migratedTime.split('.')[1];
          //  let amOrPM = millisecond1.includes("AM") ? "AM" : "PM";
          let finalmigratedTime: any = millisecond;

          let Date = obj.MigratedDateTime;
          let migratedDate: any = Date.split(' ')[0];
          // let newmigratedDate=dateFormat(migratedDate,"mm/dd/yyyy");
          let newmigratedDate = migratedDate.replace('-', '/').replace('-', '/');




          if (obj.ChatType == "Channel") {
            return (
              <div className="col-12 float-start">
                <div className="row customReportResponsive">
                  <div className='col-md-1 p-0'>
                    <div className="report-margintop">
                      <div className="row">
                        <div className="col-12 ">
                          <label className="setting-heading3 float-lg-end float-sm-start float-md-start font-16 font-semibold">{newmigratedDate}</label>
                        </div>
                        <div className="col-12   ">
                          <label className="pt-3 setting-subhead float-lg-end float-sm-start float-md-start font-16 font-regular">{finalmigratedTime}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="report-left-width padd-info-widalign mt-3">
                    <div className={obj.MigratedStatus == "Success" ? 'bd-callout bd-callout-success' : (obj.MigratedStatus == "success" ? 'bd-callout bd-callout-success' : (obj.MigratedStatus == "Failed" ? 'bd-callout bd-callout-error' : 'bd-callout bd-callout-error'))}>
                      <div className="row pb-3">
                        <div className="col-md-12 col-lg-3 col-sm-12 margin-btm-report">
                          <label className="font-14 slack-style slack-space font-regular">Slack Chat: </label>
                          {(obj.SourceChatName).length > 20 ?
                            <label data-tip={obj.SourceChatName} className="font-14 slack-style  font-regular">{obj.SourceChatName.substring(0, 17)} ...</label> :
                            <label data-tip="" className="slack-style font-14 font-regular">{obj.SourceChatName}
                            </label>
                          }
                        </div>
                        <div className="col-md-12 col-lg-3 col-sm-12 margin-btm-report">
                          <label className="font-14 slack-style slack-space font-regular">Slack Chat Type: </label>
                          <label className="font-14 slack-space font-regular user-name">{obj.ChatType}</label>
                        </div>
                        <div className="col-md-12 col-lg-3 col-sm-12 margin-btm-report">
                          <label className="font-14 slack-style slack-space font-regular">Team: </label>
                          {(obj.TargetTeamName).length > 20 ?
                            <label data-tip={obj.TargetTeamName} className="font-14 slack-space font-regular user-name">{obj.TargetTeamName.substring(0, 17)}...</label> :
                            <label data-tip="" className="font-14 slack-space font-regular user-name">{obj.TargetTeamName}</label>}
                        </div>
                        <div className="col-md-12 col-lg-3 col-sm-12 margin-btm-report">
                          <label className="font-14 slack-style slack-space font-regular">Team Channel: </label>
                          {(obj.TargetChannelName).length > 15 ?
                            <label data-tip={obj.TargetChannelName} className="font-14 slack-space font-regular user-name">{obj.TargetChannelName.substring(0, 12)}...</label> :
                            <label data-tip="" className="font-14 slack-space font-regular user-name">{obj.TargetChannelName}</label>
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="font-14 font-regular user-name">Migrated Successfully</label>
                        <ReactTooltip globalEventOff="click" multiline={true} backgroundColor='black' textColor='white' border={true} borderColor='black' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          else {
            return (
              <div className="col-12 float-start">
                <div className="row customReportResponsive">
                  <div className="col-lg-1 p-0 col-md-12 col-sm-12 report-margintop">
                    <div className="row">
                      <div className="col-12 ">
                        <label className=" setting-heading3 float-lg-end float-sm-start float-md-start font-16 font-semibold">{newmigratedDate}</label>
                      </div>
                      <div className="col-12 ">
                        <label className="pt-3 setting-subhead float-lg-end float-sm-start float-md-start font-16 font-regular">{finalmigratedTime}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-report11 mt-3">
                    <div className={obj.MigratedStatus == "Success" || "success" ? 'bd-callout bd-callout-success' : 'bd-callout bd-callout-error'}>
                      <div className="row pb-3">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <label className="font-14 slack-style slack-space font-regular  margin-btm-report">Slack Chat: </label>
                          {(obj.SourceChatName).length > 30 ?
                            <label data-tip={obj.SourceChatName} className="font-14 slack-style slack-space font-regular">{obj.SourceChatName.substring(0, 27)} ...</label> :
                            <label data-tip="" className="font-14 slack-style slack-space font-regular">{obj.SourceChatName}</label>
                          }
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <label className="font-14 slack-style slack-space font-regular  margin-btm-report">Slack Chat Type: </label> <label className="slack-space font-regular user-name">{obj.ChatType}</label>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                          <label className="font-14 slack-style slack-space font-regular  margin-btm-report">MS Team Chat: </label>
                          {(obj.TargetChannelName).length > 30 ?
                            <label data-tip={obj.TargetChannelName} className="font-14 slack-space font-regular user-name">{obj.TargetChannelName.substring(0, 27)}...</label> :
                            <label data-tip="" className="font-14 slack-space font-regular user-name">{obj.TargetChannelName}</label>
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="font-14 font-regular user-name">Migrated Successfully</label>
                      </div>
                      <ReactTooltip globalEventOff="click" multiline={true} backgroundColor='black' textColor='white' border={true} borderColor='black' />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        }
        )
      }
      else if (gridData[0] == undefined) {
        return (<tr> <td className="aligncenter" colSpan={3}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={3}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img>
              <p className="mt-2">No records found</p>
            </td>
          </tr>)
      }
    }
    catch (error: any) {
    }
  }

  const handleLoadMore = () => {
    try {
      let oindexPerPage = indexPerPage + 1;
      let ocountPerPage = countPerPage + 10;
      setIndexPerPage(oindexPerPage);
      setCoutPerPage(ocountPerPage);
    }
    catch (error: any) {
    }
  }

  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value);
    }
    catch (error: any) {
    }
  }

  const handleSearchIcon = (e: any) => {
    try {
      if (searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setexportsearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
    }
  }

  const handleKeyDown = (e: any) => {
    try {
      if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setexportsearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
    }
  }

  const handleExportBtn = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getIndividualReport`,
          token: clientToken,
          data:
          {
            "clientID": clientID,
            "type": "Export",
            "chatType": `${chatType}`,
            "sortColumn": "",
            "searchText": `${exportsearchValue}`,
            "pageIndex": 1,
            "MigratedDateTime": `${MigratedDateTime}`,
            "status": "success",
            "offset": offSet
          }
        })
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        // setWorkspaceName(data.data.data[0][0].WorkspaceName);
        // setTenantName(data.data.data[0][0].TenantName);
        if (data.data.data == "No Records Found") {
          setalertStatus("No Records Found in the Grid");
          document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
          document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
          document.getElementById("wrongalert")?.setAttribute("role", "dialog");
          document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
          document.getElementById("wrongalert")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden")

        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {

          let exportData = data.data.data[0];
          setWorkspaceName(data.data.data[0][0].WorkspaceName);
          setTenantName(data.data.data[0][0].TenantName);

          exportData.forEach((details: any) => {

            let DateTime = details.MigratedDateTime;
            delete details.navTime;
            let migratedTime: any = DateTime.split(' ')[1];
            let migratedDate: any = DateTime.split(' ')[0];
            let millisecond = migratedTime.split('.')[0];
            let finalDateTime = migratedDate + ' ' + millisecond;
            details.MigratedDateTime = finalDateTime;

            let individualDateTime = details.IndividualItemMigratedDateTime;
            let inidividualmigratedTime: any = individualDateTime.split(' ')[1];
            let individualmigratedDate: any = individualDateTime.split(' ')[0];


            let individualmillisecond = inidividualmigratedTime.split('.')[0];
            let individualfinalDateTime = individualmigratedDate + ' ' + individualmillisecond;
            details.IndividualItemMigratedDateTime = individualfinalDateTime;
          });
          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Export Details',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "Individual Report Details"
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(exportData);
        }
      }
      else {
        localStorage.clear()
      }
    }
    catch (error) {
      setalertStatus("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }

  return (
    <div >
      <div className="row justify-content-center card-container mx-0">
        <div className="col-md-12  float-start px-3">
          <div className="row my-3">
            <div className="col-md-report11 float-start">
              <div className="float-start w-100">
                <div className="float-start">
                </div>
              </div>
            </div>
            <div className="col-md-12 float-start">
              <div className="row status-border">
                <div className="col-xl-3 col-lg-12 col-sm-12 col-md-12 my-2 float-start">
                  <label className="mt-1 me-5"><span className="me-2 float-start rectangle-green font-regular font-16"> </span>Success</label>
                  <label><span className="me-2 float-start rectangle-red font-regular font-16"> </span>Warning</label>
                </div >
                <div className="col-xl-9 col-lg-12 col-sm-12 col-md-12 my-2 float-start ">
                  <div className="fix-align-indualrep">

                    <div className="float-start  me-4 my-2">
                      <label className="float-start setting-heading3 font-bold font-16">Workspace :
                        {WorkspaceName.length > 15 ?
                          <span data-tip={WorkspaceName} className="ms-2 font-regular font-16">{WorkspaceName.substring(0, 15)}...</span> :
                          <span data-tip="" className="ms-2 font-regular font-16">{WorkspaceName}</span>
                        }
                      </label>
                    </div>
                    <div className=" float-start me-4 my-2">
                      <label className="float-start setting-heading3 font-bold font-16">Tenant Name :
                        {TenantName.length > 15 ?
                          <span data-tip={TenantName} className="ms-2 font-regular font-16">{TenantName.substring(0, 15)}...</span> :
                          <span data-tip="" className="ms-2 font-regular font-16">{TenantName}</span>
                        }
                      </label>
                    </div>
                    <div className=" float-start  merepsearch-2">
                      <div className="input-group wid-search-mbl">
                        <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                        <button className="btn btn-outline-secondary" type="button" id="button-addon1"><img src="Images/search.svg" alt="search-icon" onClick={handleSearchIcon} /></button>
                      </div>
                    </div>
                    <div className=" float-start ">
                      <button type="button" className="btn btn-inventory-export text- px-3 float-end font-semibold font-16 btn-scondary-custom" onClick={handleExportBtn}><img src="Images/export-excel.svg" alt="export-excel" onClick={handleExportBtn} /> Export</button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table wrapTxt">
                <tbody>
                  {gridBodyData()}
                  <td colSpan={3} />
                </tbody>
              </table>
              <span>
                <ReactTooltip />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 text-center">
        <div className="col-12 my-5">
          {totalItemsCount > countPerPage ?
            <button type="button" className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={() => handleLoadMore()}>Load More</button>
            : ""}
        </div>
        <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
          <div className="modal-dialog modal-md">
            <div className="modal-content content-margin">
              <div className="modal-body">
                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                <div className="text-center success-content w-100 mt-5">
                  <h5 className="font-semibold font-18">Alert!</h5>
                  <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                  <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
      </div>
      <div className="overlay" id='loader' hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>
  )
}