import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function WizardNavigation(wizActive: any) {
    let GridLoadarray: any[] = [];
    const state: any = useLocation().state;
    const [hideImage, setHideImage] = useState(wizActive.wizActive);
    let [activeWizard, setActiveWizard] = useState(wizActive.wizActive)

    let history = useHistory();
    useEffect(() => {

    })

    const handleWizard = (e: any) => {
         
        ////console.log(history);
        setHideImage(e.target.id)
        let id = e.target.id


        if (id.includes("step3") && !(activeWizard.includes("step3"))) {
            let removeHeight: any = document.getElementById("MainContainerMFE");
            removeHeight.classList.remove("container-bg-withFooter")
            removeHeight.classList.add("container-bg-withFooter")
            removeHeight.classList.remove("container-bg-withoutFooter")
            history.replace({
                pathname: "/ChannelMigration/AU/TeamCompletion"
            })
            // window.location.replace("/ChannelMigration/AU/TeamCompletion")
           
        }
        else if (id.includes("step2") && !(activeWizard.includes("step2"))) {
            history.replace({
                pathname: "/ChannelMigration/AU/ConversationMigration"
            })
           
        }
        else if (id.includes("step1") && !(activeWizard.includes("step1"))) {
            let removeHeight: any = document.getElementById("MainContainerMFE");
            removeHeight.classList.remove("container-bg-withFooter")
            removeHeight.classList.add("channel-container-bg-withFooter")
            removeHeight.classList.remove("container-bg-withoutFooter")
            history.replace({
                pathname: "/ChannelMigration/AU/TeamChannelMapping"
            })
            

        }



    }

    return (

        <div className="col-md-12 my-3">
            <div className="overlay" id='wizardLoader' hidden={true}>
            <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 py-3">
                    <ul className="nav migration-wizard  justify-content-center ">
                        <li>
                            <a className="nav-link anchorAsPointer font-18 font-bold" id="#step1" data-bs-toggle="tab">
                                <span className="px-2">
                                    <img src="/Images/step-1-inActive.svg" id="step1InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step1InActive" ? true : false} alt="step-1" />
                                    <img src="/Images/step-1-active.svg" id="step1Active" hidden={hideImage == "step1InActive" ? false : true} alt="step-1" />
                                </span>
                                <span className={(activeWizard=="step1InActive")? "activeTabColour wizard-res-none anchorAsPointer":"inactiveTabColour wizard-res-none anchorAsPointer"} id="step1InActive" onClick={(e) => { handleWizard(e) }}>Team Channel Creation and Mapping</span>
                            </a>
                        </li>
                        <li>
                            <a className=" nav-link anchorAsPointer active font-18 font-bold" id="#step2" data-bs-toggle="tab">
                                <span className="px-2">
                                    <img src="/Images/step-2-inActive.svg" id="step2InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step2InActive" ? true : false} alt="step-1" />
                                    <img src="/Images/step-2-active.svg" id="step2Active" hidden={hideImage == "step2InActive" ? false : true} alt="step-1" />
                                </span>
                                <span className={(activeWizard=="step2InActive")? "activeTabColour wizard-res-none anchorAsPointer":"inactiveTabColour wizard-res-none anchorAsPointer"}id="step2InActive" onClick={(e) => { handleWizard(e) }}>Content Migration</span>
                            </a>
                        </li>
                        <li>
                            <a className=" nav-link anchorAsPointer font-18 font-bold" id="#step3" data-bs-toggle="tab" >
                                <span className="px-2">
                                    <img src="/Images/step-3-inActive.svg" id="step3InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step3InActive" ? true : false} alt="step-1" />
                                    <img src="/Images/step_3_active.svg" id="step3Active" hidden={hideImage == "step3InActive" ? false : true} alt="step-1" />
                                </span>
                                <span className={(activeWizard=="step3InActive")? "activeTabColour wizard-res-none anchorAsPointer":"inactiveTabColour wizard-res-none anchorAsPointer"} id="step3InActive" onClick={(e) => { handleWizard(e) }}>Team Channel Completion</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
}