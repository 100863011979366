import React, { useEffect, useState } from 'react';
import { gridLoad } from "../../Services/AdminServices";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants  from '../../Common/Constants'


export default function AdminManageUsers() {

    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    let local: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    let GridLoadarray: any[] = [];
    let [gridData, setGridData] = useState(GridLoadarray);
    let [alertMessage, setAlertMessage] = useState("");
    let [enableBtn, setEnableBtn] = useState(false);
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    let [status, setStatus] = useState("");
    let [totalItemsCount, setTotalItemsCount] = useState(2);
    let formObj = { UserName: "", Email: "", Role: "", ID: "" }
    let [formState, setFormState] = useState(formObj);
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
    let [editedData, setEditedData] = useState(formObj);
    let [enableSaveBtn, setEnableSaveBtn] = useState(false);
    let [isOtherAcc, setisOtherAcc] = useState(false);
    let [isMsftAcc, setisMsftAcc] = useState(false);
    let [isEmailAcc, setisEmailAcc] = useState(false);
    let [editDataIndex, setEditDataIndex] = useState(0);
    let [Role, setRole] = useState("Select Role");

    //Declaration to Identify Admins
    //AMU_PS_01
    const[isPrimaryAdmin,setIsPrimaryAdmin] = useState<any>()
    const[isSecondaryAdmin,setIsSecondaryAdmin] = useState<any>()
    const[isServiceAdmin,setIsServiceAdmin] = useState<any>()
    const[transferOwnership,setTransferOwnership] = useState<any>(false)

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = 0;
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;
    }


    useEffect(() => {
        //AMU_PS_02 - AMU_PS_08
        if(objClient?.Role == "Service Admin"){
            setIsServiceAdmin(true)
        }
        if(objClient?.Role == "Primary Admin"){
            setIsPrimaryAdmin(true)
        }
        if(objClient?.Role == "Secondary Admin"){
            setIsSecondaryAdmin(true)
        }

        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.add("container-bg-withoutFooter")
        loadGridPage("fetch")

    }, [])

    //#region Grid Load
    // AMU_PS_10 - AMU_PS_27
    const loadGridPage = async (type: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (objClient.AID != undefined && clientToken != undefined) {

                let transferOwnership = false
                // if(formState.Role != undefined && editedData[editIndex]['Role'] != undefined){
                //     if((formState.Role == 'Primary Admin' || editedData[editIndex]['Role'] == "Primary Admin") && objClient.Role == 'Primary Admin'){
                //         transferOwnership = true
                // //     }
                // }
                if (((formState?.Role ?? '') === 'Primary Admin' || 
                (editedData?.[editIndex]?.['Role'] ?? '') === "Primary Admin") && 
                (objClient?.Role ?? '') === 'Primary Admin') {
                    transferOwnership = true;
                    setTransferOwnership(true)
            }

                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/accountInfo/ManageUsers`,
                    data: type == "update" ?
                        {
                            "adminID": objClient.AID,
                            "type": type,
                            "userName": editedData[editIndex]['Name'],
                            "mailID": editedData[editIndex]['MailID'],
                            "userAdminID": Number(editedData[editIndex]['ID']),
                            "Role": editedData[editIndex]['Role'],
                            "adminUserMail":objClient.AMail,
                            "adminUserRole":objClient.Role,
                            "transferOwnership":transferOwnership,
                            "adminUserName":objClient.AName
                        }
                        :
                        {
                            "adminID": objClient.AID,
                            "type": type,
                            "userName": formState.UserName,
                            "mailID": formState.Email,
                            "userAdminID": Number(formState.ID),
                            "Role": formState.Role,
                            "adminUserMail":objClient.AMail,
                            "adminUserRole":objClient.Role,
                            "transferOwnership":transferOwnership,
                            "adminUserName":objClient.AName
                        },
                }
                )
                let data: any = await gridLoad(config);
                loader?.setAttribute('hidden', 'hidden');
                formState = { UserName: '', Email: '', Role: '', ID: '' }
                setFormState(formState);
                setRole("Select Role")
                if (type == 'update') { setEditIndex(0) };
                if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data == "Email Address provided is already in use!" || data.data.data == "Primary Admin Limit Reached" || data.data.data == "Admin Limit Reached") {
                    setAlertMessage(data.data.data);
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else if(data.data.data == "Admin Successfully Added" || data.data.data == "Admin Successfully Updated" || data.data.data == "Admin Successfully Deleted"){
                    let popup = document.getElementById("sourceSuccessPopUp")
                    //#region Show PopUp
                    setAlertMessage(data.data.data)
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else if(data.data.data == "Invalid authentication token provided"){
                    localStorage.removeItem("aid_t");
                    window.location.pathname = "/Admin/Login"
                }
                else if (data.data.data == "No Records Found") {
           
                    setGridData([data.data.data]);
                }
                else {
                    gridData = (type == "fetch") ? data.data.data[0] : data.data.data[0];
                    setGridData(gridData);
                    editedData = (type == "fetch") ? data.data.data[0] : data.data.data[0];
                    setEditedData(editedData);
                    setRole("Select Role")
                    // totalItemsCount = (type == "fetch") ? Number(data.data.data[0][0].SecondaryAdminLimit) : Number(data.data.data[1][0].SecondaryAdminLimit);
                    // setTotalItemsCount(totalItemsCount);
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Handle button Clicks
    //AMU_PS_28 - AMU_PS_30
    const handleEditClick = async (editingRow: any) => {
        try {
            // let dropdownValue: any = document.getElementsByName("IsSSOAccountEdit")
            // dropdownValue.value = editedData[editingRow]['IsSSOAccount'] == true ? "Microsoft" : "Email";
            setRole(editedData[editingRow]['Role'])
            setEditMode(true);
            setEditIndex(editingRow)
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //AMU_PS_34 - AMU_PS_39
    const handleEditRegexValidation = (e: any, index: number) => {
        try {
            handleInputChange(e, index)
            //#region validation labels
            let userName = document.getElementById("invalidUserName1");
            let email = document.getElementById("invalidEmail1");
            let authType = document.getElementById('invalidAuthType1') as HTMLInputElement;
            let emptyUserName = document.getElementById("emptyuserName1");
            let emptyEmail = document.getElementById("emptyEmail1");

            let authMsg = authType.value;
            setRole(authMsg);
            //#endregion
            if (e.target.id == "Name") {
                if (editedData[index]['Name'] == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    setEnableSaveBtn(false);
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = editedData[index]['Name'].replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        //console.log("False", formState.UserName);
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "MailID") {
                if (editedData[index]['MailID'] == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    setEnableSaveBtn(false);
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(editedData[index]['MailID'])

                    if (result == false && editedData[index]['MailID'].length <= 320) {
                        //console.log("False", formState.EmailAddress);
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            if (e.target.id == "Role") {
                if (editedData[index]['Role'] == undefined || editedData[index]['Role'] == "Select Role") {
                    authType?.removeAttribute('hidden');
                    setEnableSaveBtn(false);
                }
                else {
                    authType?.setAttribute('hidden', 'hidden');
                }
            }
            if (editedData[index]['Name'] != "" && editedData[index]['MailID'] != "" && editedData[index]['MailID'].length <= 320 && (editedData[index]['Role'].toLowerCase() == "primary admin" || editedData[index]['Role'].toLowerCase() == "secondary admin" || editedData[index]['Role'].toLowerCase() == "service admin")) {
                let spaceRemoved = editedData[index]['Name'].replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let Check2 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(editedData[index]['MailID'])

                if (Check1 == true && Check2 == true) {
                    setEnableSaveBtn(true);
                }
                else {
                    setEnableSaveBtn(false);
                }
            }
            else {
                setEnableSaveBtn(false);
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const handleInputChange = (event: any, rowIndex: number) => {
        try {
            let updatedData = { ...editedData };
            if (updatedData[rowIndex]['Role'] == true || updatedData[rowIndex]['Role'] == false) {
                updatedData[rowIndex]['Role'] = event.target.value
            }
            // let dropdownValue: any = document.getElementsByName("IsSSOAccountEdit")
            // dropdownValue.value = event.target.id == "IsSSOAccount" ? event.target.value : updatedData[rowIndex]['IsSSOAccount'];
            setRole(editedData[rowIndex]['Role']);
            setEditMode(true);
            setEditIndex(rowIndex);
            updatedData[rowIndex][`${event.target.id}`] = event.target.value;
            setEditedData(updatedData);


        }
        catch (error) {

        }
    };
    //AMU_PS_54 - AMU_PS_58
    const editSaveClick = async (rowIndex: any) => {
        try {
            setEditMode(false)
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            await loadGridPage('update');
            setEditIndex(0);
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //AMU_PS_46 - AMU_PS_48
    const handleAddClick = async (e: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            loadGridPage('add');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //AMU_PS_31 - AMU_PS_33
    const handleDeleteClick = async (e: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let data = JSON.parse(e.target.id);
            formState = { ...formState, ID: data.ID }
            setFormState(formState);
            loadGridPage('delete');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //AMU_PS_49 - AMU_PS_53
    const editCancelClick = async (editIndex:any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            setEditMode(false);
            setEditIndex(0);
           loadGridPage("fetch");
            // loader?.setAttribute('hidden', 'hidden');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const OnChangeName = (e: any) => {
        try {
            formState = { ...formState, [e.target.id]: e.target.value }
            setFormState(formState);
        }
        catch (error: any) {
            //console.log(error);

        }
    }
    //AMU_PS_40 - AMU_PS_45
    const regexValidation = (e: any) => {
        try {
            OnChangeName(e)
            //#region validation labels
            let userName = document.getElementById("invalidUserName");
            let email = document.getElementById("invalidEmail");
            let authType = document.getElementById('invalidAuthType') ;
            let emptyUserName = document.getElementById("emptyuserName");
            let emptyEmail = document.getElementById("emptyEmail");


            //#endregion
            if (e.target.id == "UserName") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    setEnableBtn(false);
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        //console.log("False", formState.UserName);
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "Email") {
                if (formState.Email == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    setEnableBtn(false);
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.Email)

                    if (result == false && formState.Email.length <= 320) {
                        //console.log("False", formState.EmailAddress);
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            if (e.target.id == "Role") {
                if (formState.Role == "" || formState.Role == "Select Role") {
                    authType?.removeAttribute('hidden');
                    setEnableBtn(false);
                }
                else {
                    authType?.setAttribute('hidden', 'hidden');
                }
            }
            if (formState.UserName != "" && formState.Email != "" && formState.Email.length <= 320 && formState.Role != "" && formState.Role != 'Select Role') {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let Check2 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.Email)

                if (Check1 == true && Check2 == true) {
                    setEnableBtn(true);
                }
                else {
                    setEnableBtn(false);
                }
            }
            else {
                setEnableBtn(false);
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    //#endregion
    //#region bind data in grid
    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
                return gridData.map((obj, index) => {
                    let rowClass = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let activeClass = (obj.AccountStatus.toLowerCase() == "active") ? "status success-status text-center font-14 font-semibold" : "status status-styling3 text-center font-14 font-semibold"
                    return (
                        <tr className={rowClass} key={index} >
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        <input type="text" placeholder="Enter User Name" id="Name" className="form-control  font-14 font-regular customManageSec" value={obj.Name} onChange={(e) => handleEditRegexValidation(e, index)} />
                                        <label id="invalidUserName1" className="required" hidden={true} >Please enter Valid User Name</label>
                                        <label id="emptyuserName1" className="required" hidden={true} >User Name cannot be empty</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.Name}
                                    </span>
                                }
                            </td>
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        <input type="text" placeholder="Enter Email ID" id="MailID" className="form-control  font-14 font-regular customManageSec" value={obj.MailID} onChange={(e) => handleEditRegexValidation(e, index)}></input>
                                        <label id="invalidEmail1" className="required" hidden={true} >Please enter Valid Email ID</label>
                                        <label id="emptyEmail1" className="required" hidden={true} >Email ID cannot be empty</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.MailID}
                                    </span>
                                }
                            </td>
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        {isPrimaryAdmin?
                                        <select  value = {Role} className="form-select font-14 font-regular" aria-label="Default select example" name='RoleEdit' id='Role' onChange={(e) => handleEditRegexValidation(e, index)}>
                                            <option value='Select Role' className='grayColor'>Select Role</option>
                                            <option value="Primary Admin">Primary Admin</option>
                                            <option value="Secondary Admin">Secondary Admin</option>
                                            <option value="Service Admin">Service Admin</option>
                                        </select>
                                        :<></>}
                                        {isSecondaryAdmin?
                                        <select  value = {Role} className="form-select font-14 font-regular" aria-label="Default select example" name='RoleEdit' id='Role' onChange={(e) => handleEditRegexValidation(e, index)}>
                                            <option value='Select Role' className='grayColor'>Select Role</option>
                                            <option value="Service Admin">Service Admin</option>
                                        </select>
                                        :<></>}
                                        <label id="invalidAuthType1" className="required" hidden={true} >Please select any one option</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.Role}
                                    </span>
                                }   
                            </td>
                            <td className="table-head font-14 font-regular">
                                <span className={activeClass}>{obj.AccountStatus}</span>
                            </td>
                            <td className="table-head font-14 font-regular text-center vertical-align text-center ">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        {(enableSaveBtn == false) ?
                                            <span>
                                                <img className="disableDiv icons" src="/Images/check.svg"></img>
                                                <img className="icons anchorAsPointer" src="/Images/cross.svg" id={JSON.stringify(obj)} onClick={e => editCancelClick(index)}></img>
                                            </span>
                                            :
                                            <span>
                                                <img className="icons me-2 anchorAsPointer" src="/Images/check.svg" id={JSON.stringify(obj)} onClick={e => editSaveClick(index)} ></img>
                                                <img className="icons anchorAsPointer" src="/Images/cross.svg" id={JSON.stringify(obj)} onClick={e => editCancelClick(index)} ></img>
                                            </span>
                                        }
                                    </span>
                                    :
                                    <span>
                                        {isSecondaryAdmin && (obj.Role == "Secondary Admin" || obj.Role == "Primary Admin")?
                                        <img className="icons me-2 anchorAsPointer icon-disable" src="/Images/edit-icon.svg" id={JSON.stringify(obj)} ></img>
                                        :
                                        <img className="icons me-2 anchorAsPointer" src="/Images/edit-icon.svg" id={JSON.stringify(obj)} onClick={e => handleEditClick(index)} ></img>
                                        }
                                        {isSecondaryAdmin && (obj.Role == "Secondary Admin" || obj.Role == "Primary Admin")?
                                        <img className="icons anchorAsPointer icon-disable" src="/Images/delete-icon.svg" id={JSON.stringify(obj)}></img>
                                        :
                                        <img className="icons anchorAsPointer" src="/Images/delete-icon.svg" id={JSON.stringify(obj)} onClick={e => handleDeleteClick(e)}></img>
                                        }
                                        {/* <img className="icons me-2 anchorAsPointer" src="/Images/edit-icon.svg" id={JSON.stringify(obj)} onClick={e => handleEditClick(index)} ></img>
                                        <img className="icons anchorAsPointer" src="/Images/delete-icon.svg" id={JSON.stringify(obj)} onClick={e => handleDeleteClick(e)}></img> */}
                                    </span>
                                }
                            </td>
                        </tr>
                    )
                })
            }
            else if (gridData[0] == undefined && gridData[0].AccountStatus == undefined) {
                return (<tr> <td className="aligncenter" colSpan={6}></td>  </tr>)
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    //#endregion
    //#region Alret Popup close
    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            let submitButton = document.getElementById("saveButton")
            submitButton?.setAttribute("disabled", "disabled")
            loadGridPage("fetch")
            setRole("Select Role")
            if(transferOwnership){
                localStorage.removeItem("aid_t");
                window.location.pathname = "/Admin/Login"
            }
            
            //#endregion
            // loadGridPage("fetch");
        } catch (error) {
            //console.log("Issue Faced");
        }
    }
    //#endregion
    return (
        <div id="invoice-history" className="tab-pane active  pb-5">
            <div className="col-md-12 col-12 col-sm-12 col-md-12 float-start mt-2">
                <div className="table-responsive table-border">
                    <table className="table ">
                        <thead className="tabel-head-bg">
                            <tr>
                                <th className="table-head font-14 font-bold">User Name</th>
                                <th className="table-head font-14 font-bold">Email ID</th>
                                <th className="table-head font-14 font-bold">Role</th>
                                <th className="table-head font-14 font-bold">Account Status</th>
                                <th className="table-head font-14 font-bold text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr className="tabel-body-bg" >
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        <input type="text" placeholder="Enter User Name" id="UserName" className="form-control  font-14 font-regular customManageSec" value={formState.UserName} onChange={(e) => regexValidation(e)}></input>
                                        <label id="invalidUserName" className="required" hidden={true} >Please enter Valid User Name</label>
                                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        <input type="text" placeholder="Enter Email ID" id="Email" className="form-control  font-14 font-regular customManageSec" value={formState.Email} onChange={(e) => regexValidation(e)}></input>
                                        <label id="invalidEmail" className="required" hidden={true} >Please enter Valid Email ID</label>
                                        <label id="emptyEmail" className="required" hidden={true} >Email ID cannot be empty</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        {isPrimaryAdmin?
                                        <select className="form-select font-14 font-regular" value={formState.Role} aria-label="Default select example" id='Role' onChange={(e) => regexValidation(e)}>
                                            <option value="Select Role" className='grayColor'>Select Role</option>
                                            <option value="Primary Admin">Primary Admin</option>
                                            <option value="Secondary Admin">Secondary Admin</option>
                                            <option value="Service Admin">Service Admin</option>
                                        </select>:<></>}
                                        {isSecondaryAdmin?
                                        <select className="form-select font-14 font-regular" aria-label="Default select example" value={formState.Role} id='Role' onChange={(e) => regexValidation(e)}>
                                            <option value='Select Role' className='grayColor'>Select Role</option>
                                            <option value="Service Admin">Service Admin</option>
                                        </select>:<></>}
                                        <label id="invalidAuthType" className="required" hidden={true} >Please select any one option</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular"><span className="status status-styling2 text-center font-14 font-semibold">Invite</span></td>
                                <td className="table-head font-14 font-regular text-center vertical-align text-center ">
                                    {(enableBtn == false) ?
                                        <img className="disableDiv icons" src="/Images/Add-icon.svg"></img>
                                        :
                                        <img className="icons anchorAsPointer" src="/Images/Add-icon.svg" onClick={handleAddClick}></img>}
                                </td>
                            </tr>
                            {gridBodyData()}
                        </tbody>
                    </table>
                </div>
                {/* <!-- channel list table ends here -->  */}
                {/* <!-- loadmore button section starts here --> */}
                {/* <div className="col-md-12 mt-3 mb-5 text-center">
                    {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={() => handleLoadMore()}>Load More</button> : ""}
                </div> */}
                {/* <!-- loadmore button section ends here --> */}
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
             {/* <!--Success Pop-up--> */}
             <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>
    );
}