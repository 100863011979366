import React, { useEffect, useState } from 'react';
import { gridLoad } from "../../Services/ProfileServices";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";


export default function PasswordReset() {
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let adminToken: any;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    let[clientMailID, setclienMailID] = useState("");
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;


    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientMailID = objClient.CMail;
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }

    let [alertMessage, setAlertMessage] = useState("")
    let formObj = { EmailAddress: "", OldPassword: "", NewPassword: "", ConfirmPassword: "" }
    let [formState, setFormState] = useState(formObj);
    // const [clientID, setclientID] = useState(1);

    let [oldPswdEyeOpen, setoldPswdEyeOpen] = useState(false);
    let [newPswdEyeOpen, setnewPswdEyeOpen] = useState(false);
    let [confirmPswdEyeOpen, setconfirmPswdEyeOpen] = useState(false);

    useEffect(() => {
        setclienMailID(clientMailID);
        resetSessionTimer();
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.remove("container-bg-withoutFooter")
        let submitButton = document.getElementById("saveButton")
        submitButton?.setAttribute("disabled", "disabled")
        loadAccountInfo()
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])


    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                     let updatedobjClient: any = jwt(dePass);
                     SessionEpiredDateTime = updatedobjClient.CreatedAt;
                     setSessionEpiredDateTime(SessionEpiredDateTime);
                     clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion


    const loadAccountInfo = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientProfileDetails`,
                    data: {
                        "clientID": clientID
                    },
                }
                )
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');

                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }

                else {
                    accountInfo(data.data.data)


                }
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const OnChangeName = (e: any) => {

        formState = { ...formState, [e.target.name]: e.target.value }
        setFormState(formState)
    }

    const saveButton = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let submitButton = document.getElementById("saveButton")
                submitButton?.setAttribute("disabled", "disabled")
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/UpdateClientPassword`,
                    data: {
                        "clientID": clientID,
                        "userEmail": `${clientMailID}`,
                        "oldPassword": await encryptPasswordBL(formState.OldPassword, r_n_Key),// `${formState.OldPassword}`,
                        "confirmPassword": await encryptPasswordBL(formState.ConfirmPassword, r_n_Key) //,  Number(formState.ConfirmPassword)
                    },
                }
                )              
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                        setAlertMessage("Failed to Update Password!")
                    }
                    else {
                        setAlertMessage(data.data.data)
                    }
                }

                let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp"); 
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const accountInfo = async (accDetails: any) => {
        try {

            if (accDetails.oldPassword == undefined) {
                formState = { ...formState, EmailAddress: accDetails.MailID }
            }
            else {
                formState = { ...formState, OldPassword: "", NewPassword: "", ConfirmPassword: "" }
            }
            setFormState(formState)
        }
        catch (error: any) {

        }
    }

    const regexValidation = (e: any) => {
        try {
            OnChangeName(e)
            let submitButton = document.getElementById("saveButton");

            let oldpassword = document.getElementById("oldpassword")
            let newpassword = document.getElementById("newpassword")
            let confirmpassword = document.getElementById("confirmpassword")

            let emptyoldpassword = document.getElementById("emptyoldpassword");
            let emptynewpassword = document.getElementById("emptynewpassword")
            let emptyconfirmpassword = document.getElementById("emptyconfirmpassword")


            let passwordmatch = document.getElementById("passwordmatch")
            let passwordmismatch = document.getElementById("passwordmismatch")

            if (e.target.id == "OldPassword") {
                if (formState.OldPassword == "") {
                    emptyoldpassword?.removeAttribute('hidden')
                    oldpassword?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                    setoldPswdEyeOpen(false)
                }
                else {

                    emptyoldpassword?.setAttribute('hidden', 'hidden')
                    let result = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.OldPassword)
                    if (result == false) {
                        oldpassword?.removeAttribute('hidden')
                    }
                    else {
                        oldpassword?.setAttribute('hidden', 'hidden')
                        if (formState.OldPassword != formState.NewPassword) {
                            passwordmatch?.setAttribute('hidden', 'hidden')
                        }
                        else if (formState.OldPassword == formState.NewPassword) {
                            passwordmatch?.removeAttribute('hidden')
                        }
                    }
                }
            }
            else if (e.target.id == "NewPassword") {
                if (formState.NewPassword == "") {
                    emptynewpassword?.removeAttribute('hidden')
                    newpassword?.setAttribute('hidden', 'hidden')
                    passwordmatch?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                    setnewPswdEyeOpen(false)
                }
                else {
                    emptynewpassword?.setAttribute('hidden', 'hidden')
                    let result = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.NewPassword)
                    if (result == true && formState.NewPassword != formState.OldPassword) {
                        newpassword?.setAttribute('hidden', 'hidden')
                        passwordmatch?.setAttribute('hidden', 'hidden')
                        if (formState.ConfirmPassword == formState.NewPassword) {
                            passwordmismatch?.setAttribute('hidden', 'hidden')
                        }
                        else if (formState.ConfirmPassword != "" && formState.ConfirmPassword != formState.NewPassword) {
                            passwordmismatch?.removeAttribute('hidden')
                        }
                    }
                    // else if(result == true && formState.ConfirmPassword == formState.NewPassword){

                    // }
                    else if (result == false) {
                        passwordmatch?.setAttribute('hidden', 'hidden')
                        newpassword?.removeAttribute('hidden')
                    }
                    else {
                        newpassword?.setAttribute('hidden', 'hidden')
                        passwordmatch?.removeAttribute('hidden')
                        if (formState.ConfirmPassword == formState.NewPassword) {
                            passwordmismatch?.removeAttribute('hidden')
                        }
                        else if (formState.ConfirmPassword != formState.NewPassword) {
                            passwordmismatch?.setAttribute('hidden', 'hidden')
                        }
                    }
                }
            }
            else if (e.target.id == "ConfirmPassword") {
                if (formState.ConfirmPassword == "") {
                    emptyconfirmpassword?.removeAttribute('hidden')
                    confirmpassword?.setAttribute('hidden', 'hidden')
                    passwordmismatch?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                    setconfirmPswdEyeOpen(false)
                }
                else {
                    emptyconfirmpassword?.setAttribute('hidden', 'hidden')
                    let result = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.ConfirmPassword)

                    if (result == false) {
                        passwordmismatch?.setAttribute('hidden', 'hidden')
                        confirmpassword?.removeAttribute('hidden')
                    }
                    else if (result == true && formState.NewPassword != "" && formState.ConfirmPassword != formState.NewPassword) {
                        confirmpassword?.setAttribute('hidden', 'hidden')
                        passwordmismatch?.removeAttribute('hidden')
                    }
                    else if (result == true && formState.NewPassword != "" && formState.ConfirmPassword == formState.NewPassword) {
                        confirmpassword?.setAttribute('hidden', 'hidden')
                        passwordmismatch?.setAttribute('hidden', 'hidden')
                    }
                    else {
                        confirmpassword?.setAttribute('hidden', 'hidden')
                        // passwordmismatch?.removeAttribute('hidden')
                    }
                }
            }
            if (formState.OldPassword != "" && formState.NewPassword != "" && formState.ConfirmPassword != "") {

                let Check1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.OldPassword)
                let Check2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.NewPassword)
                let Check3 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(formState.ConfirmPassword)

                if (Check1 && Check2 && Check3 == true && formState.NewPassword != formState.OldPassword && formState.NewPassword == formState.ConfirmPassword) {
                    submitButton?.removeAttribute('disabled');
                }
                else {
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                submitButton?.setAttribute('disabled', 'disabled');
            }

        }
        catch (error: any) {

        }
    }

    const encryptPasswordBL = async (Data: any, SecretKey: any) => {
        try {
            let encryptedValue = objCryptoJS.AES.encrypt(Data, SecretKey).toString();
            return encryptedValue;
        } catch (error) {
            throw error;
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            if (isSessionEpired === false) {
                //#region Hide PopUp
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                let submitButton = document.getElementById("saveButton");
                submitButton?.setAttribute('disabled', 'disabled');

                formState = { ...formState, OldPassword: "", NewPassword: "", ConfirmPassword: "" }
                setFormState(formState)
                if (type != "alert") {
                    localStorage.removeItem("cid_t");
                    window.location.pathname = "/Login"
                }
            }
            else {
                window.location.pathname = "/Login";
              
            }
            //#endregion
        } catch (error) {

        }
    }


    return (
        <div id="account-information" className="tab-pane active pt-3 pb-5">

            <div className="col-md-12 mb-4 pu-border-none">
                <div className="row">
                    <div className="col-md-4  pu-form-space">
                        <label className="font-16 font-semibold form-label " htmlFor="EmailAddress">Email Address<span className="required">*</span></label>
                        <input type="email" className="form-control custom-form" disabled id="email-address" name="EmailAddress" value={clientMailID} />
                        <label id="email" className="required" hidden={true} >Please enter Email Address</label>
                        <label id="emptyemailaddress" className="required" hidden={true} >Email Address cannot be empty</label>
                    </div>
                    <div className="col-md-4">
                        <label className="font-16 font-semibold form-label " htmlFor="OldPassword">Old Password<span className="required">*</span></label>
                        <div className="eyeiconDiv">
                            <input type={oldPswdEyeOpen == false ? "password" : "text"} className="form-control custom-form eyepadding" id="OldPassword" name="OldPassword" value={formState.OldPassword} onChange={(e) => regexValidation(e)} />
                            <img src={oldPswdEyeOpen == false && formState.OldPassword != "" ? '/Images/eyeOpen-icon.svg' : '/Images/eyeClose-icon.svg'} className={oldPswdEyeOpen == false && formState.OldPassword != "" ? "eyecloseiconImg anchorAsPointer" : "eyeiconImg anchorAsPointer"} alt="eye-open-icon" onClick={(e) => (formState.OldPassword != undefined && formState.OldPassword != "") ? setoldPswdEyeOpen(oldPswdEyeOpen == true ? false : true) : null} />
                        </div>
                        <div>
                            <label id="oldpassword" className="required" hidden={true} >Please enter Valid Old Password</label>
                            <label id="emptyoldpassword" className="required" hidden={true} >Old Password cannot be empty</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-5">
                <div className="row">
                    <div className="col-md-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="NewPassword">New Password<span className="required">*</span></label>
                        <div className="eyeiconDiv">
                            <input type={newPswdEyeOpen == false ? "password" : "text"} className="form-control custom-form eyepadding" id="NewPassword" name="NewPassword" value={formState.NewPassword} onChange={(e) => regexValidation(e)} />
                            <img src={newPswdEyeOpen == false && formState.NewPassword != "" ? '/Images/eyeOpen-icon.svg' : '/Images/eyeClose-icon.svg'} className={newPswdEyeOpen == false && formState.NewPassword != "" ? "eyecloseiconImg anchorAsPointer" : "eyeiconImg anchorAsPointer"} alt="eye-open-icon" onClick={(e) => (formState.NewPassword != undefined && formState.NewPassword != "") ? setnewPswdEyeOpen(newPswdEyeOpen == true ? false : true) : null} />
                        </div>
                        <div>
                            <label id="newpassword" className="required" hidden={true}>The New Password should have minimum of 8-20 characters and atleast one Upper Case, Lower case letter, a special character and a number</label>
                            <label id="emptynewpassword" className="required" hidden={true}>Password cannot be empty </label>
                            <label id="passwordmatch" className="required" hidden={true}>Old Password matches the entered New Password </label>
                        </div>
                    </div>
                    <div className="col-md-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="ConfirmPassword">Confirm Password<span className="required">*</span></label>
                        <div className="eyeiconDiv">
                            <input type={confirmPswdEyeOpen == false ? "password" : "text"} className="form-control custom-form eyepadding" id="ConfirmPassword" name="ConfirmPassword" value={formState.ConfirmPassword} onChange={(e) => regexValidation(e)} />
                            <img src={confirmPswdEyeOpen == false && formState.ConfirmPassword != "" ? '/Images/eyeOpen-icon.svg' : '/Images/eyeClose-icon.svg'} className={confirmPswdEyeOpen == false && formState.ConfirmPassword != "" ? "eyecloseiconImg anchorAsPointer" : "eyeiconImg anchorAsPointer"} alt="eye-open-icon" onClick={(e) => (formState.ConfirmPassword != undefined && formState.ConfirmPassword != "") ? setconfirmPswdEyeOpen(confirmPswdEyeOpen == true ? false : true) : null} />
                        </div>
                        <div>
                            <label id="confirmpassword" className="required" hidden={true}> Please enter Valid Password </label>
                            <label id="emptyconfirmpassword" className="required" hidden={true}>Password cannot be empty </label>
                            <label id="passwordmismatch" className="required" hidden={true}>New Password does not match the Confirm Password </label>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--footer starts here--> */}
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                <div className=" mx-3 d-flex justify-content-end">
                    <button type="button" id="saveButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={(e) => saveButton()} >Submit</button>
                </div>
            </footer>
            {/* <!--footer ends here--> */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">Password Updated successfully!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>

    );
}