import { APIService } from "../Client/Client";
export const gridLoad=( loadGridPostData : any) =>
{
    ;    
    return APIService(loadGridPostData.url,{method:'POST' , body : loadGridPostData.data},loadGridPostData.token);
}
export const formLoad=( loadGridPostData : any) =>
{
    ;    
    return APIService(loadGridPostData.url,{method:'POST' , body : loadGridPostData.data},loadGridPostData.token);
}