import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function WizardNavigation(wizActive: any) {
    const [activeStep, setActiveStep] = useState(wizActive.wizActive);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // Update activeStep based on the current route
        if (location.pathname.includes('ChatHtmlMigration')) {
            setActiveStep('step2InActive');
        } else if (location.pathname.includes('ChatHtmlFolderCreation')) {
            setActiveStep('step1InActive');
        }
    }, [location.pathname]);

    const handleWizard = (e: any) => {
        let id = e.target.id;
        let newActiveStep = id.includes('step2') ? 'step2InActive' : 'step1InActive';

        setActiveStep(newActiveStep);

        if (newActiveStep === 'step2InActive') {
            history.push({
                pathname: '/Conversation/ChatHtmlMigration'
            });
        } else {
            history.push({
                pathname: '/Conversation/ChatHtmlFolderCreation'
            });
        }
    }

    return (
        <div className="col-md-12 my-3">
            <div className="row justify-content-center">
                <div className="col-12 py-3">
                    <ul className="nav migration-wizard justify-content-center ">
                        <li>
                            <a className={`nav-link font-18 font-bold anchorAsPointer ${activeStep === 'step1InActive' ? 'active' : ''}`} id="#step1" data-bs-toggle="tab">
                                <span className="px-2">
                                    <img src="/Images/step-1-inActive.svg" id="step1InActive" onClick={handleWizard} hidden={activeStep === 'step1InActive'} alt="step-1" />
                                    <img src="/Images/step-1-active.svg" id="step1Active" hidden={activeStep !== 'step1InActive'} alt="step-1" />
                                </span>
                                <span className={activeStep === 'step1InActive' ? 'activeTabColour wizard-res-none' : 'inactiveTabColour wizard-res-none'} id="step1InActive" onClick={handleWizard}>HTML Folder Creation</span>
                            </a>
                        </li>
                        <li>
                            <a className={`nav-link font-18 font-bold anchorAsPointer ${activeStep === 'step2InActive' ? 'active' : ''}`} id="#step2" data-bs-toggle="tab">
                                <span className="px-2">
                                    <img src="/Images/step-2-inActive.svg" id="step2InActive" onClick={handleWizard} hidden={activeStep === 'step2InActive'} alt="step-2" />
                                    <img src="/Images/step-2-active.svg" id="step2Active" hidden={activeStep !== 'step2InActive'} alt="step-2" />
                                </span>
                                <span className={activeStep === 'step2InActive' ? 'activeTabColour wizard-res-none' : 'inactiveTabColour wizard-res-none'} id="step2InActive" onClick={handleWizard}>Content Migration</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
        </div>
    );
}