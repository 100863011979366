import React, { useEffect, useState } from 'react';

import { gridLoad } from "../../Services/ProfileServices";
import dateFormat from "dateformat"
import { useLocation } from 'react-router-dom'
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import htmlToPdfmake from 'html-to-pdfmake';

import * as html2pdf from 'html2pdf.js'

export default function InvoiceHistory() {
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let adminLocal: any = localStorage.getItem("aid_t");
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let adminToken: any;
    let isUserBased: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = parseInt(objClient.CID);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        isUserBased = objClient.isUserBasedLicense;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }
    }

    let GridLoadarray: any[] = [];
    let dataObj = { Status: "", ClientName: "", CompanyName: "", MailID: "", InvoiceNumber: "", InvoiceDate: "", PlanType: "", ChannelCount: "", ChatCount: "", TotalCost: "" }
    let [invoiceDetails, setinvoiceDetails] = useState(dataObj);
    const state = useLocation().state as any;
    const [gridData, setGridData] = useState(GridLoadarray);
    const [downloadData, setdownloadData] = useState([]);
    const [totalItemsCount, setTotalItemsCount] = useState(5);
    const [countPerPage, setCoutPerPage] = useState(5);
    const [indexPerPage, setIndexPerPage] = useState(1);
    // const [clientID, setclientID] = useState(1);
    const [invoiceNumber, setinvoicenumber] = useState("");
    // let pdfvalue: any[] = [];
    // const [pdfContent, setPdfContent] = useState(pdfvalue)
    const [status, setStatus] = useState("")
    let [alertMessage, setAlertMessage] = useState("")
    useEffect(() => {
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.add("container-bg-withoutFooter")
        loadGridPage()
    }, [indexPerPage])
    const loadGridPage = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientInvoiceDetails`,
                    data: {
                        "offset": indexPerPage,
                        "clientID": clientID
                    },
                }
                )
                let data: any = await gridLoad(config);
                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    setGridData([data.data.data]);
                }
                else {
                    setGridData(data.data.data[0]);
                }
                setTotalItemsCount(data.data.data[1][0].InvoiceDetailsCount);
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const handleDownloadClink = async (e: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let id = JSON.parse(e.target.id);
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientInvoiceDetails`,
                    data: {
                        "invoiceNumber": `${id.InvoiceNumber}`,
                        "clientID": clientID
                    },
                }
                )
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                let data: any = await gridLoad(config);
                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else {
                    let exportData = data.data.data;
                    // setPdfContent(exportData)
                    getData(exportData)
                    downloadPDF(exportData);
                }
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }


    const downloadPDF = async (invoice: any) => {
        try {
            let InvoiceData: any = invoice[0][0]
            let totalAmount = InvoiceData.TotalAmount //.toLocaleString("en-US")
            let planType: any = (InvoiceData.PurchaseType.includes('User') || isUserBased) ? 'User-Based - ' + InvoiceData.LicenseType : 'Chat & Channel - ' + InvoiceData.LicenseType;

            let newInvoiceTemplate = (InvoiceData.LicenseType.toLowerCase() == 'free') ?
                // 'free plan for chat-channel & user-based'
                `<!DOCTYPE html>
                <html lang="en">
                
                <head>
                    <meta charset="UTF-8">
                    <meta http-equiv="X-UA-Compatible" content="IE=edge">
                    <title>Invoice</title>
                </head>
                
                <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                    <div style="border: 3px solid rgb(70, 71, 117);"></div>
                    <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                        <div style="width: 100%; float: left;margin-top: 10px">
                            <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                            <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                        </div>
                        <div style="width: 100%; float: left;margin-top: 10px;">
                            <div style="width: 25%; float: left;">
                                <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                            </div>
                            <div style="width: 75%; float: left;">
                                <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                    ${InvoiceData.InvoiceNumber} </p>
                                <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                    ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                    ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                            </div>
                        </div>
                        <div style="width: 100%; float: left;margin-top: 20px;">
                            <div style="width: 50%; float: left; ">
                                <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                            </div>
                
                            <div style="width: 50%; float: left;">
                                <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                            </div>
                        </div>
                        <div
                            style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                            <table style="width: 100%; border-collapse: collapse; float: left; border: 1px solid rgb(228, 225, 225); ">
                                <tbody>
                                    <tr>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                        <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">Quantity</th>
                                        <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Unit Cost</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; border-left: 1px solid #DCDCDC;">Price</th>
                                    </tr>
                                    <tr>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>
                                        <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;"></th>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack Migration</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">1</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">1</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">$5</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$59</td>
                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$64</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style="width: 100%;  float: left;  ">
                                <div style="float: right; width: 100%; display:flex; justify-content: end; ">
                                    <label
                                        style="font-size: 7.5pt; line-height: normal; padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Discount</label>
                                    <label
                                        style="font-size: 7.5pt; line-height: normal; padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$64</label>
                                </div>
                            </div>
                            <div style="width: 100%;  float: left;  ">
                                <div
                                    style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                    <label
                                        style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                    <label
                                        style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${totalAmount}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
                </html>`
                : (InvoiceData.PurchaseType.toLowerCase() == 'resource utilization')
                    ? //'resource utilization'
                    `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <title>Invoice</title>
            </head>
            
            <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                <div style="border: 3px solid rgb(70, 71, 117);"></div>
                <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                    <div style="width: 100%; float: left;margin-top: 10px">
                        <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                        <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                    </div>
                    <div style="width: 100%; float: left;margin-top: 10px;">
                        <div style="width: 25%; float: left;">
                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                        </div>
                        <div style="width: 75%; float: left;">
                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                ${InvoiceData.InvoiceNumber} </p>
                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                        </div>
                    </div>
                    <div style="width: 100%; float: left;margin-top: 20px;">
                        <div style="width: 50%; float: left; ">
                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                        </div>
            
                        <div style="width: 50%; float: left;">
                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine1}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine2}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.City}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.State}
                                ${InvoiceData.ZipCode}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.Country}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                        </div>
                    </div>
                    <div
                        style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px; margin-bottom: 48px;">
                        <table style="width: 100%; border-collapse: collapse; float: left;">
                            <tbody>
                                <tr>
                                    <th
                                        style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                        Products & Services</th>
                                    <th
                                        style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                        Plan Type</th>
                                    <th
                                        style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; background-color: rgb(247, 247, 249);">
                                        Category</th>
                                    <th
                                        style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);">
                                        Price</th>
                                </tr>
                                <tr>
                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px">Slack Migration</td>
                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: center;">
                                        ${planType}</td>
                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: center;">
                                        Resource Utilization</td>
                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">
                                        $${totalAmount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="width: 100%;  float: left;  ">
                            <div
                                style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                <label
                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                <label
                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${totalAmount}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
            
            </html>`
                    : (InvoiceData.LicenseType.toLowerCase() == 'premium' && InvoiceData.PurchaseType.toLowerCase() != "user license")
                        ? //'channel premium plan'
                        `<!DOCTYPE html>
                    <html lang="en">
                    
                    <head>
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <title>Invoice</title>
                    </head>
                    
                    <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                        <div style="border: 3px solid rgb(70, 71, 117);"></div>
                        <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                            <div style="width: 100%; float: left;margin-top: 10px">
                                <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                            </div>
                            <div style="width: 100%; float: left;margin-top: 10px;">
                                <div style="width: 25%; float: left;">
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                </div>
                                <div style="width: 75%; float: left;">
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${InvoiceData.InvoiceNumber} </p>
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                </div>
                            </div>
                            <div style="width: 100%; float: left;margin-top: 20px;">
                                <div style="width: 50%; float: left; ">
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                </div>
                    
                                <div style="width: 50%; float: left;">
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                </div>
                            </div>
                            <div
                                style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                                <table style="width: 100%; border-collapse: collapse; float: left; border: 1px solid rgb(228, 225, 225); ">
                                    <tbody>
                                        <tr>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                            <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">Quantity</th>
                                            <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Unit Cost</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">
                                                Resource Utilization Cost</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px;">Price</th>
                                        </tr>
                                        <tr>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>                        
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249); border-left: 1px solid #DCDCDC;"></th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);"></th>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack Migration</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">${InvoiceData.PurchasedGroupChatMigrationLicenseCount}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">${InvoiceData.PurchasedChannelMigrationLicenseCount}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">$${InvoiceData.PurchasedGroupChatMigrationLicenseCost}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${InvoiceData.PurchasedChannelMigrationLicenseCost}</td> 
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$${InvoiceData.PlannedResourceUtilizationCost}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; ">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style="width: 100%;  float: left;  ">
                                    <div
                                        style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                        <label
                                            style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                        <label
                                            style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                    </html>`
                        : ((InvoiceData.PurchaseType.toLowerCase() == "user license" && InvoiceData.LicenseType.toLowerCase() == 'premium') || InvoiceData.PurchaseType.toLowerCase() == "resource utilization & user license")
                            ? //Userr-based premium plan
                            `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta http-equiv="X-UA-Compatible" content="IE=edge">
                        <title>Invoice</title>
                    </head>
                    
                    <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                        <div style="border: 3px solid rgb(70, 71, 117);"></div>
                        <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                            <div style="width: 100%; float: left;margin-top: 10px">
                                <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                            </div>
                            <div style="width: 100%; float: left;margin-top: 10px;">
                                <div style="width: 25%; float: left;">
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                    <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                </div>
                                <div style="width: 75%; float: left;">
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${InvoiceData.InvoiceNumber} </p>
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                    <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                        ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                </div>
                            </div>
                            <div style="width: 100%; float: left;margin-top: 20px;">
                                <div style="width: 50%; float: left; ">
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                </div>
                    
                                <div style="width: 50%; float: left;">
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                    <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                    <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                </div>
                            </div>
                            <div style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                                <table style="width: 100%; border-collapse: collapse; float: left; ">
                                    <tbody>
                                        <tr>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                            <th colspan="3" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">User license</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Resource Utilization Cost</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px;">Price</th>
                                        </tr>
                                        <tr>
                                            <th
                                                style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                            </th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                            </th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">
                                                License Count</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                Unit Cost</th>
                                            <th  style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                Total Cost</th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249); border-left: 1px solid #DCDCDC;">
                                                </th>
                                            <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);">
                                                </th>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack
                                                Migration</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">${InvoiceData.PurchasedUserLicenseCount}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${InvoiceData.TotalAmount / InvoiceData.PurchasedUserLicenseCount}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${totalAmount}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$${InvoiceData.PlannedResourceUtilizationCost}</td>
                                            <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; ">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                               
                                <div style="width: 100%;  float: left;  ">
                                    <div
                                        style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                        <label
                                            style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                        <label
                                            style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                    
                        </html>`
                            : (InvoiceData.PurchaseType.toLowerCase() == "user license" && InvoiceData.LicenseType.toLowerCase() != 'premium')
                                ? //'All plan for user-based'
                                `<!DOCTYPE html>
                        <html lang="en">
                        <head>
                            <meta charset="UTF-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <title>Invoice</title>
                        </head>
                        
                        <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                            <div style="border: 3px solid rgb(70, 71, 117);"></div>
                            <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                                <div style="width: 100%; float: left;margin-top: 10px">
                                    <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                    <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                                </div>
                                <div style="width: 100%; float: left;margin-top: 10px;">
                                    <div style="width: 25%; float: left;">
                                        <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                        <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                        <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                    </div>
                                    <div style="width: 75%; float: left;">
                                        <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                            ${InvoiceData.InvoiceNumber} </p>
                                        <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                            ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                        <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                            ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                    </div>
                                </div>
                                <div style="width: 100%; float: left;margin-top: 20px;">
                                    <div style="width: 50%; float: left; ">
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                    </div>
                        
                                    <div style="width: 50%; float: left;">
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine1}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine2}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.City}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.State} ${InvoiceData.ZipCode}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.Country}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                    </div>
                                </div>
                                <div style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                                    <table style="width: 100%; border-collapse: collapse; float: left; ">
                                        <tbody>
                                            <tr>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                                <th colspan="3" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">User license</th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Resource Utilization Cost</th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px;">Price</th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                                </th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                                </th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">
                                                    License Count</th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                    Unit Cost</th>
                                                <th  style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                    Total Cost</th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249); border-left: 1px solid #DCDCDC;">
                                                    </th>
                                                <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);">
                                                    </th>
                                            </tr>
                                            <tr>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack
                                                    Migration</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">${InvoiceData.PurchasedUserLicenseCount}</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${InvoiceData.TotalAmount / InvoiceData.PurchasedUserLicenseCount}</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${totalAmount}</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$${InvoiceData.ResourceUtilizationCost}</td>
                                                <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; ">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   
                                    <div style="width: 100%;  float: left;  ">
                                        <div
                                            style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                            <label
                                                style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                            <label
                                                style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </body>
                        
                        </html>`

                                : (InvoiceData.PurchaseType.toLowerCase() == "resource utilization & channel license")
                                    ? //'channel license + Resource Utilization'
                                    `<!DOCTYPE html>
                            <html lang="en">
                            
                            <head>
                                <meta charset="UTF-8">
                                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                                <title>Invoice</title>
                            </head>
                            
                            <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                                <div style="border: 3px solid rgb(70, 71, 117);"></div>
                                <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                                    <div style="width: 100%; float: left;margin-top: 10px">
                                        <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                        <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 10px;">
                                        <div style="width: 25%; float: left;">
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                        </div>
                                        <div style="width: 75%; float: left;">
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${InvoiceData.InvoiceNumber} </p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                        </div>
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 20px;">
                                        <div style="width: 50%; float: left; ">
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                        </div>
                            
                                        <div style="width: 50%; float: left;">
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine1}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine2}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.City}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.State}
                                            ${InvoiceData.ZipCode}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.Country}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                        </div>
                                    </div>
                                    <div
                                        style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                                        <table style="width: 100%; border-collapse: collapse; float: left; border: 1px solid rgb(228, 225, 225); ">
                                            <tbody>
                                                <tr>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                                    <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">Quantity</th>
                                                    <th colspan="2" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Unit Cost</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">
                                                        Resource Utilization Cost</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px;">Price</th>
                                                </tr>
                                                <tr>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);"></th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">Chat</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">Channel</th>                        
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249); border-left: 1px solid #DCDCDC;"></th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);"></th>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack Migration</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">${InvoiceData.PurchasedGroupChatMigrationLicenseCount}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">${InvoiceData.PurchasedChannelMigrationLicenseCount}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">$${InvoiceData.PurchasedGroupChatMigrationLicenseCost}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${InvoiceData.TotalAmount / InvoiceData.PurchasedChannelMigrationLicenseCount}</td> 
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$${InvoiceData.ResourceUtilizationCost}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; ">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="width: 100%;  float: left;  ">
                                            <div
                                                style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </body>
                            </html>`
                                    : (InvoiceData.PurchaseType.toLowerCase() == "resource utilization & User license")
                                        ?//'User license + resource Utilization
                                        `<!DOCTYPE html>
                            <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                                <title>Invoice</title>
                            </head>
                            
                            <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                                <div style="border: 3px solid rgb(70, 71, 117);"></div>
                                <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                                    <div style="width: 100%; float: left;margin-top: 10px">
                                        <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                        <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 10px;">
                                        <div style="width: 25%; float: left;">
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                        </div>
                                        <div style="width: 75%; float: left;">
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${InvoiceData.InvoiceNumber} </p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                        </div>
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 20px;">
                                        <div style="width: 50%; float: left; ">
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                        </div>
                            
                                        <div style="width: 50%; float: left;">
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                        <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine1}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine2}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.City}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.State}
                                            ${InvoiceData.ZipCode}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.Country}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                        <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                        </div>
                                    </div>
                                    <div style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px;margin-bottom: 48px;">
                                        <table style="width: 100%; border-collapse: collapse; float: left; ">
                                            <tbody>
                                                <tr>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Products</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px;">Plan Type</th>
                                                    <th colspan="3" style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; border-left: 1px solid #DCDCDC;">User license</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px;border-left: 1px solid #DCDCDC; ">Resource Utilization Cost</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px;">Price</th>
                                                </tr>
                                                <tr>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                                    </th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                                    </th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);border-left: 1px solid #DCDCDC;">
                                                        License Count</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        Unit Cost</th>
                                                    <th  style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        Total Cost</th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 14px; background-color: rgb(247, 247, 249); border-left: 1px solid #DCDCDC;">
                                                        </th>
                                                    <th style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);">
                                                        </th>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">Slack
                                                        Migration</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: left;">${planType}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;border-left: 1px solid #DCDCDC;">${InvoiceData.PurchasedUserLicenseCount}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${(InvoiceData.TotalAmount - InvoiceData.ResourceUtilizationCost) / InvoiceData.PurchasedUserLicenseCount}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">$${InvoiceData.TotalAmount - InvoiceData.ResourceUtilizationCost}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; border-left: 1px solid #DCDCDC;">$${InvoiceData.ResourceUtilizationCost}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right; ">$${(InvoiceData.TotalAmount).toLocaleString("en-US")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                       
                                        <div style="width: 100%;  float: left;  ">
                                            <div
                                                style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${(InvoiceData.ResourceUtilizationCost + InvoiceData.TotalAmount).toLocaleString("en-US")}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </body>
                            
                                </html>`
                                        : //'Chat-channel all remaining plans'
                                        `<!DOCTYPE html>
                            <html lang="en">
                            
                            <head>
                                <meta charset="UTF-8">
                                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                                <title>Invoice</title>
                            </head>
                            
                            <body style=" font-family: Arial, Helvetica, sans-serif; margin: 0px;">
                                <div style="border: 3px solid rgb(70, 71, 117);"></div>
                                <div style="width: 96%; margin-left: 2%; margin-right: 2%;height : 100%; ">
                                    <div style="width: 100%; float: left;margin-top: 10px">
                                        <h1 style="font-size: 15pt; line-height: normal; margin-top: 15px; float: left;">Invoice</h1>
                                        <img src='/Images/Logo.png' style="width: 7%;float: right;" alt="Logo">
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 10px;">
                                        <div style="width: 25%; float: left;">
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Invoice Number</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date of issue</p>
                                            <p style=" font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">Date due</p>
                                        </div>
                                        <div style="width: 75%; float: left;">
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${InvoiceData.InvoiceNumber} </p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                            <p style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;font-weight: bold;">
                                                ${dateFormat(InvoiceData.InvoiceDate, "mmm dd, yyyy")}</p>
                                        </div>
                                    </div>
                                    <div style="width: 100%; float: left;margin-top: 20px;">
                                        <div style="width: 50%; float: left; ">
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill From : </h4>
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">Avasoft Inc</h4>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">630 Skippack Pike</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Blue Bell</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Pennsylvania 19422</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">USA </p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">kumar.r@avasoft.com</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: +1 732-737-9188</p>
                                        </div>
                            
                                        <div style="width: 50%; float: left;">
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin: 3px 0px;">Bill To : </h4>
                                            <h4 style="font-size: 7.5pt; line-height: normal; margin-bottom: 8px;">${InvoiceData.CompanyName}</h4>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine1}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.AddressLine2}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.City}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.State}
                                                ${InvoiceData.ZipCode}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.Country}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">${InvoiceData.MailID}</p>
                                            <p style="font-size: 7.5pt; line-height: normal;margin: 3px 0px; ">Ph: ${InvoiceData.Phone}</p>
                                        </div>
                                    </div>
                                    <div
                                        style=" border: 1px solid rgb(228, 225, 225); float:left; width: 100%; margin-top: 48px; margin-bottom: 48px;">
                                        <table style="width: 100%; border-collapse: collapse; float: left;">
                                            <tbody>
                                                <tr>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: left; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        Products & Services</th>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        Plan Type</th>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        License Count</th>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: center; padding: 14px; background-color: rgb(247, 247, 249);">
                                                        Unit Cost</th>
                                                    <th
                                                        style="font-size: 7.5pt; line-height: normal; text-align: right; padding: 16px 14px 16px 50px; background-color: rgb(247, 247, 249);">
                                                        Price</th>
                                                </tr>
                                                <tr>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px">Slack Migration</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: center;">
                                                        ${planType}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: center;">
                                                        ${InvoiceData.PurchasedChannelMigrationLicenseCount}</td>
                                                        <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: center;">
                                                            $${InvoiceData.TotalAmount / InvoiceData.PurchasedChannelMigrationLicenseCount}</td>
                                                    <td style="font-size: 7.5pt; line-height: normal; padding: 14px; text-align: right;">
                                                        $${totalAmount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div style="width: 100%;  float: left;  ">
                                            <div
                                                style="float: right;background-color: rgb(247, 247, 249); width: 100%; display:flex; justify-content: end; ">
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 40px 16px 48px; float: left; border-right: none; font-weight: bold;">Total</label>
                                                <label
                                                    style="font-size: 7.5pt; line-height: normal; background-color: rgb(247, 247, 249); padding: 16px 26px; text-align: end; float: left; font-weight: bold; color: rgb(70, 71, 117);">$${totalAmount}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </body>
                            
                            </html>`

            var opt = {
                // margin: [1, 0.5, 0, 0.5],
                filename: 'InvoicePDF.pdf',
                image: { type: 'jpg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            html2pdf().from(newInvoiceTemplate).set(opt).save();

        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const getData = (value: any) => {
        try {
            //console.log(value);
            //console.log(value[0].Status);

            //console.log(invoiceDetails);
            // invoiceDetails = { ...invoiceDetails, Status: value.Status }
            setinvoiceDetails({
                ...invoiceDetails, Status: value[0][0].Status, ClientName: value[0][0].ClientName, CompanyName: value[0][0].CompanyName,
                InvoiceNumber: value[0][0].InvoiceNumber, InvoiceDate: value[0][0].PurchasedDate, MailID: value[0][0].MailID,
                PlanType: value[0][0].LicenseType, ChannelCount: value[0][0].PurchasedChannelMigrationLicenseCount,
                ChatCount: value[0][0].PurchasedGroupChatMigrationLicenseCount, TotalCost: value[0][0].TotalAmount
            })

        }
        catch (error: any) {
            //console.log(error);

        }
    }
    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && gridData[0].Status != undefined) {
                return gridData.map((obj, index) => {
                    let rowClass = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let activeClass = (obj.Status == "succeeded") ? "success-status status-block font-14" : "fail-status status-block font-14"
                    let totalAmount = obj.TotalAmount.toLocaleString("en-US")
                    return (
                        <tr className={rowClass} >
                            <td className="py-3 tabel-data-font-level1  " >{obj.InvoiceNumber} </td>
                            <td className="py-3 tabel-data-font-level1 text-center"><div className={activeClass}>{obj.Status == "succeeded" ? "Success" : "Fail"}</div></td>
                            <td className="py-3 tabel-data-font-level1 ">{(obj.PurchaseType.includes('user') || isUserBased) ? 'User-Based - ' + obj.LicenseType : 'Chat & Channel - ' + obj.LicenseType}</td>
                            <td className="py-3 tabel-data-font-level1 ">{obj.PurchaseType}</td>
                            <td className="py-3 tabel-data-font-level1 ">{dateFormat(obj.PurchasedDate, "mmm dd, yyyy")}</td>
                            <td className="py-3 tabel-data-font-level1 float-end">${totalAmount}</td>
                            <td className="py-3 tabel-data-font-level1 text-center" >
                                {obj.Status == "succeeded" ?
                                    <a className="sign-in fontweight-Bold anchorAsPointer" id={JSON.stringify(obj)} onClick={e => handleDownloadClink(e)} >Download</a>
                                    :
                                    <a className="sign-in fontweight-Bold invoice-fail" >Download</a>
                                }
                            </td>
                        </tr>)
                })
            }
            else if (gridData[0] == undefined && gridData[0].Status == undefined) {
                return (<tr> <td className="aligncenter" colSpan={6}></td>  </tr>)
            }
            else {
                return (<tr> <td className="aligncenter" colSpan={6}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img>
                    <p className="mt-2">No records found</p>
                </td>  </tr>)
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const handleLoadMore = () => {
        try {
            let oindexPerPage = indexPerPage + 1;
            let ocountPerPage = countPerPage + 5;
            setIndexPerPage(oindexPerPage);
            setCoutPerPage(ocountPerPage);
        }
        catch (error: any) {
            //console.log(error);
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            //#endregion
        } catch (error) {
            //console.log("Issue Faced");
        }
    }

    return (
        <div id="invoice-history" className="tab-pane active pt-3 pb-5">
            <div className="col-md-12 col-12 col-sm-12 col-md-12 float-start mt-2">
                <div className="table-responsive table-border">
                    <table className="table ">
                        <thead className="tabel-head-bg">
                            <tr>
                                <th className="table-head font-14 font-bold">Invoice #</th>
                                <th className="table-head font-14 font-bold text-center">Payment Status</th>
                                <th className="table-head font-14 font-bold">Plan Type</th>
                                <th className="table-head font-14 font-bold">Purchase Type</th>
                                <th className="table-head font-14 font-bold">Payment Date</th>
                                <th className="table-head font-14 font-bold float-end">Amount</th>
                                <th className="table-head font-14 font-bold text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody >
                            {gridBodyData()}
                        </tbody>
                    </table>
                </div>
                {/* <!-- channel list table ends here -->  */}

                {/* <!-- loadmore button section starts here --> */}
                <div className="col-md-12 mt-3 mb-5 text-center">
                    {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={() => handleLoadMore()}>Load More</button> : ""}
                </div>
                {/* <!-- loadmore button section ends here --> */}
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>
    )
}