import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/InventoryServices';
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory, Redirect } from 'react-router-dom';
import { LicenseVal } from './LicenseValidation'
// import { useLocation } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import constants from '../../Common/Constants'


// import dateFormat from "dateformat"
import * as XLSX from 'xlsx';
import { ExportToCsv } from 'export-to-csv';
// import Pagination from "react-js-pagination";
// import deleteIcon from 'images/delete-icon.svg';
// import editIcon from '/Images/edit.png';
// import WrongIcon from '/Images/wrong.png';
// import searchIcon from '/Images/search.png';
// import filterIcon from '/Images/filter.png';
// import upArrowIcon from '/Images/up.png';
// import downArrowIcon from '/Images/Down.png';
// import { config } from 'process';
import ReactTooltip from 'react-tooltip';
// import downArrow from '/Images/arrow.svg';
// import searchIcon from '/Images/search.svg';
// import exporticon from '/Images/export-excel.svg';
// import filter from '/Images/filter.svg';
// import upArrow from '/Images/sort_upper_arrow.svg';
import moment from 'moment';
import objCryptoJS from 'crypto-js'
import JWT from 'jwt-decode'
// import FilterCloseIcon from '/Images/filter-close-icon.svg';
// import downloadIMG from '/Images/download-template.svg'
// import NoRecords from '/Images/NoRecords.png';


export function DetailedInventory() {
  let GridLoadarray: any[] = [];
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  let [searchValue, setSearchValue] = useState("");
  let [exportsearchValue, setexportsearchValue] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  const [columnOrder, setColumnOrder] = useState("desc");
  const [columnName, setColumnName] = useState("Created");
  const [sorthide, setsorthide] = useState("");
  const [importedFileName, setimportedFileName] = useState("No File Chosen");
  const [type, setType] = useState("Grid")
  const [countPerPage, setCoutPerPage] = useState(10);
  const [indexPerPage, setIndexPerPage] = useState(1);
  let history = useHistory();
  const [Channel, setChannel] = useState(false);
  const [MM, setMM] = useState(false);
  const [DM, setDM] = useState(false);
  let [Custom, setCustom] = useState(false);
  const [viewstart, setviewstart] = useState(true);
  const [viewimport, setviewimport] = useState(false);
  let [InventoryName, setInventoryName] = useState("SlackInventory_" + moment(new Date()).format('MM-DD-YYYY hh:mm A'))
  const [csv, setcsv] = useState("")
  let [FromDate, setFromDate] = useState("")
  let [ToDate, setToDate] = useState("")
  const [channelLicense, setchannelLicense] = useState(false)
  const [LoadMore, setLoadmore] = useState("")
  const [maxMigrationToDate, setMaxMigrationToDate] = useState(new Date().toISOString().split('T')[0]);
  let [ChatDetails, setChatDetails] = useState([]);
  let [ErrorMessage, setErrorMessage] = useState("");
  let [InprogressState, setInprogressState] = useState(0);
  let [bool, setbool] = useState(0);
  let [bool1, setbool1] = useState(0);
  let [Array, setArray] = useState([]);
  let [statusText, setStatusText] = useState("");
  let [ConnectSettings, setConnectSettings] = useState(false);
  let [IsDateOnlySelected, setIsDateOnlySelected] = useState(false);
  let [IsSearchClicked, setIsSearchClicked] = useState(false);
  let [boolInvalidSheet, setboolInvalidSheet] = useState(false);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;




  let ClientID = 0;


  let local: any = localStorage.getItem("cid_t");
  let adminLocal: any = localStorage.getItem("aid_t");

  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;

  let objClient: any;
  let adminObjClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientToken: any;
  let adminToken: any;

  // let boolInvalidSheet: any = false;

  if (local != undefined) {

    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);

    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);

    objClient = JWT(decryptedValue);
    ClientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

    if(adminLocal != undefined){
      let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
      let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
      adminObjClient = JWT(adminDecryptedValue);
      adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
    }
  }

  useEffect(() => {
    resetSessionTimer();
    let removeFooter: any = document.getElementById("MainContainerMFE");
    removeFooter.classList.add("container-bg-withoutFooter")
    loadGridPage();
    var today = new Date();
    let date = (today.getDate().toString()).length < 2 ? "0" + (today.getDate().toString()) : (today.getDate().toString());
    let month = (today.getMonth() + 1).toString().length < 2 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString();
    let newdate = today.getFullYear() + '-' + month + '-' + date;
    setMaxMigrationToDate(newdate)
    //#region Event handlers Keypad click and mouse click
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {

      ErrorMessage = "Your session has expired. Please log in again to continue using the application."
      setErrorMessage(ErrorMessage);

      //#region Show PopUp
      let popup = document.getElementById("alertPopUp1")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");

      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {
      //console.log(error)
    }
  }
  //#endregion
  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {
      if (ClientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(SessionEpiredDateTime);
          //console.log(clientToken);
          let updatedobjClient: any = JWT(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp1")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");

    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = async () => {
    try {
      //console.log("reset")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp1")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");

    }
  }
  //#endregion

  const navigateToChannel = (e: any) => {
     

    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? "" : adminToken
      if (innerText != "0") {
        history.push({
          pathname: "/Inventory/Channel",
          state: (id),
        })
        // history.push("/Inventory/Channel");
        window.location.reload();

        //window.location.pathname = '/Inventory/Channel';
        // event?.composedPath
      }
    }
    catch (error: any) {
      ////console.log(error);
    }

  }

  const navigateToUsers = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? adminToken : null;

      if (innerText != "0") {
        history.push({
          pathname: "/Inventory/Users",
          state: (id)
        })
        window.location.reload();

         
        ////window.location.pathname = '/Inventory/Users';

      }

    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const navigateToInventory = (e: any) => {
     
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken
       
      history.push({
        pathname: "/Inventory/InProgress",
        state: (id)
      })
      window.location.reload();

      ////window.location.pathname = '/Inventory/InProgress';


    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const navigateToDM = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? adminToken : null;
      if (innerText != "0") {
        history.push({
          pathname: "/Inventory/DM",
          state: id
        })
        window.location.reload();

        ////window.location.pathname = '/Inventory/DM';

      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const navigateToMM = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? adminToken : null;

      if (innerText != "0") {
        history.push({
          pathname: "/Inventory/MM",
          state: (id)
        })
        window.location.reload();

        ////window.location.pathname = '/Inventory/MM';

      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const navigateToUsergroups = (e: any) => {
     
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? adminToken : null;
      if (innerText != "0") {
        history.push({
          pathname: "/Inventory/Usergroups",
          state: (id)
        })
        ////console.log(e.target.id);
        window.location.reload();

        ////window.location.pathname = '/Inventory/Usergroups';
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const navigateToThirdPartyApp = (e: any) => {
     
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText
      id["ClientID"] = ClientID
      id["token"] = clientToken
      id["adminToken"] = adminToken? adminToken : null;

      if (innerText != "0") {
        history.replace({
          pathname: "/Inventory/ThirdPartyApp",
          state: (id)
        })
        // history.pushState(id, '', url)
        ////console.log(e.target.id);
        window.location.reload();
        //window.location.pathname='/Inventory/ThirdPartyApp';
        // window.location.assign( '/Inventory/ThirdPartyApp');
        //window.location.href = '/Inventory/ThirdPartyApp';
        // window.history.prop({
        //   pathname: "/Inventory/ThirdPartyApp",
        //   state: (id)
        // })
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const loadGridPage = async () => {
    try {
      let a: any = document.getElementById("loader");
      a.removeAttribute("hidden");
       
      if (ClientID != 0 && clientToken != undefined) {

        let config = (
          {
            method: "POST",
            url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/pageLoad`,
            token: clientToken,
            data: {
              "clientID": ClientID,
              "type": `${type}`,
              "sortColumn": `${columnName}`,
              "searchText": `${exportsearchValue}`,
              "sortType": `${columnOrder}`,
              "pageIndex": Number(indexPerPage)

            }

          }
        )

        if(adminLocal !=undefined && adminLocal !== null){
          (config.data as any).token = adminToken
        }
        
        var data: any = await gridLoad(config);
        // debugger
        let a: any = document.getElementById("loader");
        a.setAttribute("hidden", "hidden");
        ////console.log(data);

      }
      else {
        localStorage.clear();
      }
       

      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        let a: any = document.getElementById("checkstartInv");
        a.setAttribute("disabled", "disabled");
        let popup = document.getElementById("alertPopUp1")
        let abc: any = document.getElementById("loader");
        abc.setAttribute("hidden", "hidden");

        //#region Show PopUp
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Please Connect Settings to proceed Further in Inventory" && data.data.statusCode == 400) {
        let abc: any = document.getElementById("loader");
        abc.setAttribute("hidden", "hidden");
        setGridData(["No Records Found"]);
        setTotalItemsCount(0);
        ConnectSettings = true;
        setConnectSettings(ConnectSettings);
        ErrorMessage = data.data.data;
        setErrorMessage(ErrorMessage);

      }
      else if (data.data.data == "Please assign license to proceed Further with the Detailed Inventory" && data.data.statusCode == 400) {
        let abc: any = document.getElementById("loader");
        abc.setAttribute("hidden", "hidden");
        setGridData(["No Records Found"]);
        setTotalItemsCount(0);
        ErrorMessage = data.data.data;
        setErrorMessage(ErrorMessage);

        let popup = document.getElementById("alertlicenseNav")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv1")?.removeAttribute("hidden");

      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else {
        if (data.data.data == "no records were fetched" || data.data.data == "No records were fetched" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" ||data.data.data.length === 0) {
          setGridData(["No Records Found"]);
          setTotalItemsCount(0);
        }



        if (data.data.data[2] != undefined && data.data.data[2].length > 0 && data.data.data[2][0].status != undefined) {
          let a: any = document.getElementById("checkstartInv");
          a.setAttribute("disabled", "disabled");
          InprogressState = 1;
          setInprogressState(InprogressState);
          let c: any = document.getElementById("inProgressInfo");
          c.removeAttribute("hidden");
          let D: any = document.getElementById("inProgressInfoImg");
          D.removeAttribute("hidden");
          setGridData(data.data.data[0]);
          setTotalItemsCount(data.data.data[1][0].InventoryCount)
        }

        if (data.data.data != "No records were fetched" && data.data.data[2] != undefined && data.data.data[2].length == 0) {
          let a: any = document.getElementById("checkstartInv");
          a.removeAttribute("disabled");
          let D: any = document.getElementById("inProgressInfo");
          D.setAttribute("hidden", "hidden");
          let c: any = document.getElementById("inProgressInfoImg");
          c.setAttribute("hidden", "hidden");
          InprogressState = 0;
          setInprogressState(InprogressState);
          setGridData(data.data.data[0]);
           
          setTotalItemsCount(data.data.data[1][0].InventoryCount)
        }

      }
      let abc: any = document.getElementById("loader");
      abc.setAttribute("hidden", "hidden");

    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support."
      setErrorMessage(ErrorMessage);
      let alertPopUp1 = document.getElementById("alertPopUp1");
      alertPopUp1?.removeAttribute("aria-hidden1");
      alertPopUp1?.setAttribute("aria-modal", "true");
      alertPopUp1?.setAttribute("role", "dialog");
      alertPopUp1?.setAttribute("class", "modal fade show");
      alertPopUp1?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      // document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");
    }

  }

  const gridBodyData = () => {
    try {
      // var variableCount = 0;
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        
        return gridData.map((obj, index) => {
          var InventoryName;
          let StatusInv = ((obj.Status == "Completed") ? "Success" :
            (obj.Status == "Cancelling") ? "Cancelled" : obj.Status);
          obj.App = obj.App == null ? 0 : obj.App


          if ((obj.InventoryName.length > 75) && (objClient.IsChannel != 0 && objClient.IsChat == 0)) {
            InventoryName = obj.InventoryName.substring(0, 72) + "..."
          }
          else if ((obj.InventoryName.length > 47) && (objClient.IsChannel == 0 && objClient.IsChat != 0)) {
            InventoryName = obj.InventoryName.substring(0, 47) + "..."
          }
          else if ((obj.InventoryName.length > 36) && (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
            InventoryName = obj.InventoryName.substring(0, 36) + "..."
          }
          else {
            InventoryName = obj.InventoryName;
          }
          // (obj.UserGroup).toString().length > 3 ? (obj.UserGroup).toLocaleString("en-US") : obj.UserGroup;
          let Users = parseInt(obj.Users)
          let users1 = Users.toLocaleString("en-US")
          let userGroup = parseInt(obj.UserGroup)
          let userGroup1 = userGroup.toLocaleString("en-US")
          let Channel = parseInt(obj.Channel)
          let Channel1 = Channel.toLocaleString("en-US")
          let MM = parseInt(obj.MM)
          let MM1 = MM.toLocaleString("en-US")
          let DM = parseInt(obj.DM)
          let DM1 = DM.toLocaleString("en-US")
          let App = parseInt(obj.App)
          let App1 = App.toLocaleString("en-US")
          let statusClass = (StatusInv == "Failed") ? "status FailedStatus text-center font-14 font-semibold"
            : (StatusInv == "In Progress") ? "status status-styling3 text-center font-14 font-semibold"
              : (StatusInv == "Cancelled") ? "status status-styling2 text-center font-14 font-semibold"
                : "status status-styling4 text-center font-14 font-semibold"
          if (objClient.IsChannel != 0 && objClient.IsChat == 0) {
            return (
              <tr className={index % 2 == 1 ? "tabel-ody bgsecondary" : "tabel-body-bg"} >
                <td data-tip={obj.InventoryName.length > 75 ? obj.InventoryName : ""} data-place={obj.InventoryName.length > 75 ? 'right' : ""} className=" py-3 tabel-data-font-level1" ><span className='anchorAsPointer' id={JSON.stringify(obj)} onClick={(e) => navigateToInventory(e)}>{InventoryName}</span>
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span>
                </td>
                <td data-tip={obj.Status=='Failed' || StatusInv =='Cancelled' ? obj.Message:""} data-place='right' className=" py-3 text-center tabel-data-font-level1"><span  className={statusClass} id={JSON.stringify(obj)}>{StatusInv}</span>
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span>
                </td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1"><span className={obj.Users > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsers(e)}>{users1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.UserGroup > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsergroups(e)}>{userGroup1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1"  ><span className={obj.Channel > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToChannel(e)}>{Channel1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.App > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToThirdPartyApp(e)}>{App1}</span></td>

              </tr>
            )
          }
          else if (objClient.IsChannel == 0 && objClient.IsChat != 0) {
            return (
              <tr className={index % 2 == 1 ? "tabel-ody bgsecondary" : "tabel-body-bg"} >
                <td data-tip={obj.InventoryName.length > 47 ? obj.InventoryName : ""} data-place={obj.InventoryName.length > 47 ? 'right' : ""} className=" py-3 tabel-data-font-level1" ><span className='anchorAsPointer' id={JSON.stringify(obj)} onClick={(e) => navigateToInventory(e)}>{InventoryName}</span></td>
                <td data-tip={obj.Status=='Failed' || StatusInv =='Cancelled' ? obj.Message:""} data-place='right' className=" py-3 text-center tabel-data-font-level1"><span id={JSON.stringify(obj)}  className={statusClass}>{StatusInv}</span>
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.Users > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsers(e)}>{users1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.UserGroup > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsers(e)}>{userGroup1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.MM > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToMM(e)}>{MM1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1" ><span className={obj.DM > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToDM(e)}>{DM1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1"><span className={obj.App > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToThirdPartyApp(e)}>{App1}</span></td>
                <span>
                  <ReactTooltip className='mySepecialClass' />
                </span>
              </tr>
            )
          }
          else {
            return (
              <tr className={index % 2 == 1 ? "tabel-ody bgsecondary" : "tabel-body-bg"} >
                <td data-tip={obj.InventoryName.length > 36 ? obj.InventoryName : ""} data-place={obj.InventoryName.length > 36 ? 'right' : ""} className=" py-3 tabel-data-font-level1" ><span className='anchorAsPointer' id={JSON.stringify(obj)} onClick={(e) => navigateToInventory(e)}>{InventoryName}</span>
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span>
                </td>
                <td data-tip={obj.Status=='Failed' || StatusInv =='Cancelled' ? obj.Message:""} data-place='right' className=" py-3 text-center tabel-data-font-level1"><span id={JSON.stringify(obj)}  className={statusClass}>{StatusInv}</span>
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 " ><span className={obj.Users > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsers(e)}>{users1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 " ><span className={obj.UserGroup > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToUsergroups(e)}>{userGroup1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 "  ><span className={obj.Channel > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToChannel(e)}>{Channel1}</span>
                </td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 "><span className={obj.MM > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToMM(e)}>{MM1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 "><span className={obj.DM > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToDM(e)}>{DM1}</span></td>
                <td className=" py-3 rightAlignInv tabel-data-font-level1 " ><span className={obj.App > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigateToThirdPartyApp(e)}>{App1}</span></td>

              </tr>
            )
          }
        })
        
      }
      else if (gridData[0] == undefined) {
        return (<tr> <td className="centertext" colSpan={8}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={8}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
              <p className="mt-2">No records found</p></td>
          </tr>)
      }
    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const handleKeyDown = (e: any) => {
    try {
       
      if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          exportsearchValue = searchValue;
          setexportsearchValue(exportsearchValue);
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setColumnName(sortval[0])
      setColumnOrder(sortval[1])
      setsorthide(sortID)

    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const handleSearch = (e: any) => {
    try {

      setSearchValue(e.target.value)
      setIndexPerPage(1);

    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const handleSearchIcon = (e: any) => {
    try {
       
      if (searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          exportsearchValue = searchValue;
          setexportsearchValue(exportsearchValue);
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleLoadMore = () => {
    try {
      setCoutPerPage(countPerPage + 10);
      setIndexPerPage(indexPerPage + 1);
      //let oindexPerPage = indexPerPage + 1;
      //let ocountPerPage = countPerPage + 10;
      //setIndexPerPage(oindexPerPage);
      //setCoutPerPage(ocountPerPage);
    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const startInventorybtn = async () => {
     
    try {

      if (ConnectSettings == true) {
        ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp1")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      }
      else {
        let cardDetails = document.getElementById("cardDetails")

        cardDetails?.removeAttribute("aria-hidden");

        cardDetails?.setAttribute("aria-modal", "true");

        cardDetails?.setAttribute("role", "dialog");

        cardDetails?.setAttribute("class", "modal fade show");

        cardDetails?.setAttribute("style", "display:block");

        // let alertPopUp1 = document.getElementById("fadeDiv1");

        document.getElementById("fadeDiv1")?.removeAttribute("hidden");

        // var date: any = new Date().toISOString();
        // let config = ({
        //   method: "POST",
        //   url: "http://localhost:4000/Inventory/HighLevelInventory/startInventory",

        //   data: {
        //     "clientID": Number(ClientID),
        //     "InventoryName": InventoryName,
        //     "InventoryStartedDateTime": date,
        //     "InventoryFromDate": FromDate,
        //     "InventoryToDate": ToDate,
        //     "csv": csv,
        //     "isChannel": Custom == true ? true : Channel,
        //     "isMM": Custom == true ? true : MM,
        //     "isDM": Custom == true ? true : DM,
        //     "isCustom": Custom
        //   },

        // }
        // )
        // let data: any = await gridLoad(config);
        // if(data.data.data)



        // let data: any = await gridLoad(config);
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
        if (objClient.IsChat == 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
          let channel: any = document.getElementById("channel")
          channel.checked = false;
        }
        if (objClient.IsChat != 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
          let mm: any = document.getElementById("mm")
          mm.checked = false;
          let dm: any = document.getElementById("dm")
          dm.checked = false;
        }
        let a: any = document.getElementById("browsebtn");
        a.setAttribute("hidden", "true");
        setimportedFileName("No File Chosen");
        InventoryName = "";
        setInventoryName(InventoryName);
        let custom: any = document.getElementById("custom")
        custom.checked = '';
        let FromDate: any = document.getElementsByName("FromDate")
        FromDate[0].value = '';
        let ToDate: any = document.getElementsByName("ToDate")
        ToDate[0].value = '';
        let InvName: any = document.getElementById("InvName");
        InvName.value = "SlackInventory_" + moment(new Date()).format('MM-DD-YYYY hh:mm A');
        InventoryName = InvName.value;
        setInventoryName(InventoryName);
        let InvNamelbl: any = document.getElementById("InvExistingNamelbl");
        InvNamelbl.setAttribute("hidden", "true");
        ChatDetails = [];
        setChatDetails(ChatDetails);


      }

    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const startInventory = async () => {
    try {
      var Flag = 1;

      if (InventoryName.length < 1) {
        Flag = 0;
        let InvName: any = document.getElementById("InvNamelbl");
        InvName.removeAttribute("hidden");
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      else if (MM == false && DM == false && Custom == false && Channel == false) {
        // alert("Please select anyone Radio buttons")
        Flag = 0;
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      else if (ChatDetails.length < 1 && Custom) {
        // alert(" Import the csv Data")
        Flag = 0;
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      //#region date time validation
      // else if (FromDate != "" && ToDate != "" && FromDate > ToDate) {
      //   alert("Please Enter a valid date")
      //   Flag=0;
      //   let startInventory: any = document.getElementById("startInventory");
      //   startInventory.disabled = true;
      // }
      //#endregion
      if (FromDate != "") {

        let objFromDate = new Date(FromDate.split('T')[0] + "T00:00:00.000Z");
        var now = new Date();
        let inventoryFromDate: any = new Date(objFromDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
        // let fromtime:any = inventoryFromDate.replace("T"," ").replace("Z"," ");
        FromDate = inventoryFromDate;
        setFromDate(FromDate);
      }
      if (ToDate != "") {
        let objToDate = new Date(ToDate.split('T')[0] + "T23:59:59.999Z");
        var now = new Date();
        let inventoryToDate: any = new Date(objToDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
        // let toTime:any = inventoryToDate.replace("T"," ").replace("Z"," ");
        ToDate = inventoryToDate;
        setToDate(ToDate);
      }
      if (viewimport == true) {
        await HideStartInvPopup();
        var data: any = await validatecsv(JSON.stringify(ChatDetails));

        if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          Flag = 0;
          let loader: any = document.getElementById("loader");
          loader?.setAttribute('hidden', 'hidden');
          ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
          setErrorMessage(ErrorMessage);
          let popup = document.getElementById("alertPopUp1")

          //#region Show PopUp
          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv1")?.removeAttribute("hidden");
        }
        else if (data.data.data == "Invalid authentication token provided") {
          clearData();
          enableSessionExpiryPopup();
        }
        else {
          if (data.data.data.importGrid != undefined) {
            Flag = 1;
            setcsv(csv);
            setviewimport(false);
          }
          else {
            Flag = 0;
            let a: any = document.getElementById("loader");
            a.setAttribute("hidden", "true");
            bool = 1;
            setbool(bool);
            let popupText = "Failed to proceed with Inventory due to the below reasons.";
            let arrReason = ["Invalid Chat Details Provided.", "Please purchase a Channel/Chat license to proceed with Channel/Chat inventory."]
            await createDynamicListItem(popupText, arrReason);
            boolInvalidSheet = true;
            setboolInvalidSheet(boolInvalidSheet);

            let popup = document.getElementById("alertPopUp1")

            //#region Show PopUp

            popup?.removeAttribute("aria-hidden");

            popup?.setAttribute("aria-modal", "true");

            popup?.setAttribute("role", "dialog");

            popup?.setAttribute("class", "modal fade show");

            popup?.setAttribute("style", "display:block");

            document.getElementById("fadeDiv1")?.removeAttribute("hidden");
            var validationdata = data.data.data.importSheet;
            setArray(validationdata);



          }
        }
      }
      if (Flag == 1) {
        await HideStartInvPopup();
        let a: any = document.getElementById("loader");
        a.removeAttribute("hidden");
        // let InvName: any = document.getElementById("InvName");
        // InvName.value = "";
        var date: any = new Date().toISOString();
        if (ClientID != 0 && clientToken != undefined) {
           
          let config = ({
            method: "POST",
            url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/HighLevelInventory/startInventory`,
            token: clientToken,

            data: {
              "clientID": Number(ClientID),
              "InventoryName": InventoryName,
              "InventoryStartedDateTime": date,
              "InventoryFromDate": FromDate,
              "InventoryToDate": ToDate,
              "csv": JSON.stringify(ChatDetails),
              "isChannel": Channel == true ? true : Channel,
              "isMM": MM == true ? true : MM,
              "isDM": DM == true ? true : DM,
              "isCustom": Custom
            },

          }
          )

          var data: any = await gridLoad(config);
          if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setErrorMessage(ErrorMessage);
            let popup = document.getElementById("alertPopUp1")

            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv1")?.removeAttribute("hidden");
          }

        }
        else {
          localStorage.clear();
        }


        if (data.data.data.Message == "Inventory is being taken!") {

          var InventoryID = data.data.data.GeneratedInventoryID;
          let obj = { ID: InventoryID, ClientID: ClientID, "token": clientToken };
          history.push({
            pathname: "/Inventory/InProgress",
            state: obj
          })
          // let a: any = document.getElementById("loader");
          // a.setAttribute("hidden", "true");
          window.location.reload();
          // window.location.pathname = '/Inventory/InProgress'
        }
        else if (data.data.data == "Invalid authentication token provided") {
          clearData();
          enableSessionExpiryPopup();
        }

        else {
           
          let a: any = document.getElementById("loader");
          a.setAttribute("hidden", "true");
          await EnableStartInvPopup();
          let InvName: any = document.getElementById("InvExistingNamelbl");
          InvName.removeAttribute("hidden");

          if (viewimport == true) {
            setviewimport(true);
          }
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = true;

        }
      }
    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp1 = document.getElementById("alertPopUp1");
      alertPopUp1?.removeAttribute("aria-hidden");
      alertPopUp1?.setAttribute("aria-modal", "true");
      alertPopUp1?.setAttribute("role", "dialog");
      alertPopUp1?.setAttribute("class", "modal fade show");
      alertPopUp1?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");

    }
  }

  const createDynamicListItem = async (popupText: any, arrReasons: any) => {
    try {
      setErrorMessage(popupText);
      let element = document.getElementById("dynamicData");
      let ulTag = document.createElement("ol");
      ulTag.classList.add('listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      throw error
    }
  }

  function deleteChild() {
    try {
      var e: any = document.getElementById("dynamicData");
      var child = e.lastElementChild;
      ErrorMessage = "";
      setErrorMessage(ErrorMessage);
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const textchange = (e: any) => {
    try {

      setInventoryName(e.target.value);
      let InvInvalidName: any = document.getElementById("InvExistingNamelbl");
      InvInvalidName.setAttribute("hidden", "hidden");


      if (Custom == true) {
        if (ChatDetails.length < 1) {
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = true;
          if (e.target.value == "") {
            let InvName: any = document.getElementById("InvNamelbl");
            InvName.removeAttribute("hidden");
          }
          else {
            let InvName: any = document.getElementById("InvNamelbl");
            InvName.setAttribute("hidden", "hidden");
          }
        }
        if (e.target.value == "") {
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = true;
          let InvName: any = document.getElementById("InvNamelbl");
          InvName.removeAttribute("hidden");
        }
        else {
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = false;
          let InvName: any = document.getElementById("InvNamelbl");
          InvName.setAttribute("hidden", "hidden");
        }
      }
      else {
        let InvInvalidName: any = document.getElementById("InvExistingNamelbl");
        InvInvalidName.setAttribute("hidden", "hidden");
        if (e.target.value == "") {
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = true;
          let InvName: any = document.getElementById("InvNamelbl");
          InvName.removeAttribute("hidden");

        }
        else {
          if (e.target.value != "" && (MM == true || DM == true || Custom != false || Channel == true) ){
          let startInventory: any = document.getElementById("startInventory");
          startInventory.disabled = false;
          let InvName: any = document.getElementById("InvNamelbl");
          InvName.setAttribute("hidden", "hidden");
          }
        }
      }
      if (e.target.value != "" && bool1 == 0) {
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      if ((IsDateOnlySelected == false && e.target.value == '') || (ChatDetails.length < 1 && e.target.value != '' && Custom == true)) {
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      // else if (IsDateOnlySelected == true && e.target.value != '') {
      //   let startInventory: any = document.getElementById("startInventory");
      //   startInventory.disabled = false;
      // }

    }
    catch (error: any) {
      ////console.log(error);

    }
  }

  const StartInvSelect = (e: any) => {
     
    try {
      let enableStartInv = false
      let bool1 = 1;
      setbool1(bool1);




      if (e.target.checked) {
        setviewstart(false)
      }
      else {
        setviewstart(true)
      }
      if (objClient.IsChat == 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
        if (e.target.id == "channel") {
          enableStartInv = InventoryName.trim().length > 0;
          IsDateOnlySelected = true;
          setIsDateOnlySelected(IsDateOnlySelected);
          let a: any = document.getElementById("browsebtn");
          a.setAttribute("hidden", "hidden");
          setChannel(e.target.checked)
          setMM(false)
          setDM(false)
          Custom = false;
          setCustom(false)
        }
      }
      if (objClient.IsChat != 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
        if (e.target.id == "mm") {
          enableStartInv = InventoryName.trim().length > 0;
          IsDateOnlySelected = true;
          setIsDateOnlySelected(IsDateOnlySelected);
          let a: any = document.getElementById("browsebtn");
          a.setAttribute("hidden", "hidden");
          setChannel(false)
          setMM(e.target.checked)
          setDM(false)
          Custom = false;
          setCustom(false)
        }
        else if (e.target.id == "dm") {
          enableStartInv = InventoryName.trim().length > 0;
          IsDateOnlySelected = true;
          setIsDateOnlySelected(IsDateOnlySelected);
          let a: any = document.getElementById("browsebtn");
          a.setAttribute("hidden", "hidden");
          setChannel(false)
          setMM(false)
          setDM(e.target.checked)
          Custom = false;
          setCustom(false)
        }
      }
      if (e.target.id == "custom") {
        enableStartInv = InventoryName.trim().length > 0;
        IsDateOnlySelected = true;
        setIsDateOnlySelected(IsDateOnlySelected);
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
        let a: any = document.getElementById("browsebtn");
        a.removeAttribute("hidden");
        setChannel(false)
        setMM(false)
        setDM(false)
        Custom = true;
        setCustom(Custom);
      }
      if (e.target.name == "FromDate") {
        setFromDate(e.target.value);
      }
      if (e.target.name == "ToDate") {
        setToDate(e.target.value)
      }

      if ((IsDateOnlySelected == false) || (ChatDetails.length < 1 && Custom == true)) {
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = true;
      }
      else {
        if(enableStartInv == true){
        let startInventory: any = document.getElementById("startInventory");
        startInventory.disabled = false;
        }
      }

    }
    catch (error: any) {
      ////console.log(error);
    }


  }

  const handleFileUpload = async (e: any) => {
     
    try {
       
      let Flagbrowse = 0;
      const file = e.target.files[0];
      let startInventory: any = document.getElementById("startInventory");
      startInventory.disabled = true;
      e.target.value = null;
      if (file != undefined) {
         
        // let a: any = document.getElementById("loader");
        // a.removeAttribute("hidden");
        let ChatID = ['ChatID'];
        let ChatName = ['ChatName'];
        let dummy = [''];

        setimportedFileName(file.name)
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data: any = XLSX.utils.sheet_to_json(ws, { header: ["ChatID", "ChatName", "dummy"], defval: "" });
           
          if ((file.name.includes('.csv')) && data[0].dummy == undefined && ChatID.includes(data[0].ChatID) && ChatName.includes(data[0].ChatName) && Object.keys(data[0]).length == 2 && data.length > 1) {
             
            data.shift();
            setChatDetails(data);
            setviewimport(true);
            Flagbrowse = 1;
            // let startInventory: any = document.getElementById("startInventory");
            // startInventory.disabled = false;
          }

          else {
             
            boolInvalidSheet = true;
            setboolInvalidSheet(boolInvalidSheet);
            setimportedFileName("No File Chosen");
            clearData();

            let popup = document.getElementById("alertPopUp1")

            //#region Show PopUp

            popup?.removeAttribute("aria-hidden");

            popup?.setAttribute("aria-modal", "true");

            popup?.setAttribute("role", "dialog");

            popup?.setAttribute("class", "modal fade show");

            popup?.setAttribute("style", "display:block");

            document.getElementById("fadeDiv1")?.removeAttribute("hidden");
          }
           
          if (InventoryName != "" && Flagbrowse == 1) {
            let startInventory: any = document.getElementById("startInventory");
            startInventory.disabled = false;
          }
          else {
            let startInventory: any = document.getElementById("startInventory");
            startInventory.disabled = true;
          }
          //setcsv(JSON.stringify(ChatDetails))
          //processData(data);

        };
        reader.readAsBinaryString(file);
      }
      else {
        setimportedFileName("No File Chosen");
        // await triggerPopUpLoader({ isLoaderTrigger: false });
        setcsv("")
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const HideStartInvPopup = async () => {
    try {
      let cardDiv = document.getElementById("cardDetails")
      cardDiv?.setAttribute("style", "display:none");
      document.getElementById("fadeDiv1")?.setAttribute("hidden", "hidden");
    } catch (error: any) {
      ////console.log(error);
    }
  }

  const EnableStartInvPopup = async () => {
    try {
       
      if (objClient.IsChat == 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
        let channel: any = document.getElementById("channel")
        if (channel.checked == true) {
          channel.checked = true;
        }
      }
      if (objClient.IsChat != 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {

        let mm: any = document.getElementById("mm")
        if (mm.checked == true) {
          mm.checked = true;
        }
        let dm: any = document.getElementById("dm")
        if (dm.checked == true) {
          dm.checked = true;
        }
      }
      let custom: any = document.getElementById("custom")
      if (custom.checked == true) {
        custom.checked = true;
      }
      let cardDetails = document.getElementById("cardDetails")
      cardDetails?.removeAttribute("aria-hidden");
      cardDetails?.setAttribute("aria-modal", "true");
      cardDetails?.setAttribute("role", "dialog");
      cardDetails?.setAttribute("class", "modal fade show");
      cardDetails?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");

    } catch (error: any) {
      ////console.log(error);
    }
  }

  const validatecsv = async (csv: any) => {
    try {
      if (ClientID != 0 && clientToken != undefined) {
         

        let a: any = document.getElementById("loader");
        a.removeAttribute("hidden");
        let config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/HighLevelInventory/importHighLevelInventory`,
          token: clientToken,
          data: {
            "clientID": ClientID,
            "csv": csv,
            "IsChannel": objClient.IsChannel == 0 ? false : true,
            "IsChat": objClient.IsChat == 0 ? false : true
          },

        }
        )

         
        return await gridLoad(config);
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      ////console.log(error);
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp1 = document.getElementById("alertPopUp1");
      alertPopUp1?.removeAttribute("aria-hidden");
      alertPopUp1?.setAttribute("aria-modal", "true");
      alertPopUp1?.setAttribute("role", "dialog");
      alertPopUp1?.setAttribute("class", "modal fade show");
      alertPopUp1?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("style", "display:block");
    }
  }

  const btnDowTemplate = async () => {
     
    try {
      let exportData = [{
        "ChatID": "CLM9V0Y59",
        "ChatName": "Slack_Channel"
      }];

      //let exportData:any = [];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Custom Inventory Template',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Custom Inventory Template",
        //headers: ["TargetTeamName","TargetTeamType","TargetTeamID","TargetChannelName","TargetChannelType"] 
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportData);
      // let enableCreateBtn: any = document.getElementsByName("uploadBtnSlack");
      //   enableCreateBtn.removeAttribute("hidden");
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const clearData = async () => {
    try {
       
      let b: any = document.getElementById("loader");
      b.setAttribute("hidden", "true");
      let startInventory: any = document.getElementById("startInventory");
      startInventory.disabled = true;
      setimportedFileName("No File Chosen");
      let invName: any = document.getElementById("InvName");
      invName.value = "";

      let InvName: any = document.getElementById("InvNamelbl");
      InvName.setAttribute("hidden", "hidden");

      let a: any = document.getElementById("browsebtn");
      a.setAttribute("hidden", "hidden");
      if (objClient.IsChat == 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
        let channel: any = document.getElementById("channel")
        channel.checked = false;
      }
      if (objClient.IsChat != 0 || (objClient.IsChannel != 0 && objClient.IsChat != 0)) {
        let mm: any = document.getElementById("mm")
        mm.checked = false;
        let dm: any = document.getElementById("dm")
        dm.checked = false;
      }
      let custom: any = document.getElementById("custom")
      custom.checked = '';
      let FromDate: any = document.getElementsByName("FromDate")
      FromDate[0].value = '';
      let ToDate: any = document.getElementsByName("ToDate")
      ToDate[0].value = '';
      setChannel(false)
      setMM(false)
      setDM(false)
      setCustom(false)

      let cardDiv = document.getElementById("cardDetails")
      cardDiv?.setAttribute("style", "display:none")
      // let closePopUp = document.getElementById("fadeDiv1");
      // closePopUp?.setAttribute("style", "display:none");
      // document.getElementById("fadeDiv1")?.removeAttribute("hidden");
      document.getElementById("fadeDiv1")?.setAttribute("hidden", "hidden");


    }
    catch (error: any) {
      // //console.log(error);
    }
  }
  const popUpOkClick = async (type: string) => {

    try {
      if (isSessionEpired === false) {
        //#region Hide PopUp

        let popup = (type == "alert") ? document.getElementById("alertPopUp1") : document.getElementById("sourceSuccessPopUp")

        popup?.setAttribute("class", "modal fade");

        popup?.setAttribute("style", "display:none");

        popup?.setAttribute("aria-hidden", "true");

        popup?.removeAttribute("aria-modal");

        popup?.removeAttribute("role");

        document.getElementById("fadeDiv1")?.setAttribute("hidden", "true");



        if (bool == 1) {
          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Invalid ChatDetails Provided',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "Invalid ChatDetails Validation"
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };
          const csvExporter = new ExportToCsv(options);

          csvExporter.generateCsv(Array);
          setviewimport(false)
          setbool(0);
          await deleteChild();
          popup?.setAttribute("class", "modal fade");

          popup?.setAttribute("style", "display:none");

          popup?.setAttribute("aria-hidden", "true");

          popup?.removeAttribute("aria-modal");

          popup?.removeAttribute("role");

          document.getElementById("fadeDiv1")?.setAttribute("hidden", "true");
          //#endregion

        }
        if (boolInvalidSheet == true) {
          boolInvalidSheet = false;
          setboolInvalidSheet(boolInvalidSheet);
          let custom: any = document.getElementById("custom");
          custom.checked = (custom.checked ? false : false);
          startInventorybtn();
        }
      }
      else {
        window.location.pathname = "/Login";

      }
    } catch (error: any) {

      ////console.log("Issue Faced");

    }

  }

  const navigateToLicensedUser = async () => {

    try {
       
      window.location.pathname = '/LicensedUsers'

    } catch (error: any) {

      ////console.log("Issue Faced");

    }

  }

  return (
    <div className="row justify-content-center">
      {/* <div className="col-md-4 AlertInvContent mb-4">
        <label className="required" htmlFor="email" hidden={true} id="inProgressInfo"> <span className="required">*</span>when inventory is in progress,you can't proceed with a inventory. Refresh to get latest status of the inventory</label>
      </div> */}
      <div className="p-2 rounded-2 mb-2  font-14">
        {/* <img src='/Images/Spinner-img.gif' alt="info-icon" id="inProgressInfoImg" className="InventoryInprogressGif" hidden={true} /> */}
        <label htmlFor="email" hidden={true} id="inProgressInfo"><span><img src='/Images/Spinner-img.gif' alt="info-icon" id="inProgressInfoImg" className="InventoryInprogressGif gif-wid-res" hidden={true} /></span> When inventory is in progress, you can't proceed with a inventory. Refresh to get latest status of the inventory</label>
      </div>
      {objClient != undefined && objClient.IsChannel == 0 && objClient.IsChat == 0 ?

        <LicenseVal />
        :

        objClient != undefined && objClient.IsChannel != 0 && objClient.IsChat == 0 ?
          //#region Channel Licence
          <div className="col-md-11 mt-5">
            <div id="fadeDiv1" className="modal-backdrop fade show" hidden></div>

            <div className="col-md-12 col-sm-12 col-lg-12 col-12 float-start">
              <div className="tab-content">
                <div id="high-level-inventory" className="tab-pane active w-100">
                  {/* <!-- channel list table starts here --> */}
                  <div className="IndividualScreenHeadingcss w-100">
                    <div className="col-md-12 float-start DetailedInvName" >
                      <div>
                        <label className=" channel-font font-18 font-bold">Detailed Inventory Summary</label>
                      </div>
                      <div className="float-start  w-100 ">
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 my-2 float-start Inv_MarginTopContentSearch">
                          <label className='font-13'>Fetch detailed inventory of Slack workspace channels or chats messages, files,members and third party apps here!</label>

                          {/* <label>Fetch detailed inventory of your slack workspace channels or chats messages, files, members and third party apps here! </label> */}
                        </div>
                        {/* <div>
                    <label>Detailed InventorySummary</label>
                    </div> */}
                        <div className="col-xl-5 col-lg-12 col-sm-12 col-md-12 my-2 btm-margin-inventory  float-start" >
                          <button className="btn  btn-primaryStartInv startinv-detailinv-res btn-primary-theme float-end StartInvBtn common-btn-class-responsive" data-bs-toggle="modal" id="checkstartInv" data-bs-target="#cardDetails" onClick={() => startInventorybtn()} > Start Inventory</button>
                          {/* <button type="button" className="btn btn-refresh btn-sm float-end d-inline-flex font-14 font-semibold" style={{ visibility: 'hidden' }} id="refreshbtn" onClick={loadGridPageAgain}><span className="pe-2"><img src="/Images/refresh.svg" alt="refresh" /></span> Refresh</button> */}
                          {/* <button type="button" className="btn btn-refresh  px-3 btn-sm float-end d-inline-flex font-16 font-semibold " onClick={loadGridPage} style={{ visibility: 'hidden' }} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh"  /></span> <span >Refresh</span></button> */}
                          {/* Modal starts */}
                          <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                              <div className="modal-content">
                                <div className="modal-header border-0">
                                  <h5 className="inventory-title font-22 font-bold">Inventory details</h5>
                                  <button type="button" onClick={clearData} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className="modal-body pt-1">
                                  <div className="col-md-12 mb-4">
                                    <div className="row">
                                      <label className="form-label popup-label font-16 font-semibold">Select from the below options and fetch detailed inventory here!</label>
                                      <div className="w-100 float-start">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check1"><span>
                                              <input className="form-check-input float-start me-2" defaultChecked onClick={(e) => StartInvSelect(e)} type="radio" name="radio1" id="channel" /></span> Channel</label>
                                          </div>
                                          {/* <div className="col-md-6">
                                            <label className="manage-client-date-font font-14 font-regular" htmlFor="Check2"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="mm" /></span> Multiparty Chats</label>
                                          </div>
                                          <div className="col-md-6 float-start">
                                            <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check3"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="dm" /></span>Direct Chats</label>
                                          </div> */}
                                          <div className="col-md-6 float-start">
                                            <label className="manage-client-date-font font-14 font-regular" htmlFor="Check4"><span><input className="form-check-input float-start me-2" type="radio" onClick={(e) => StartInvSelect(e)} name="radio1" id="custom" /></span>Custom Inventory</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12 float-start mb-4" id="browsebtn" hidden={true}>
                                    <div className="row">
                                      <label className="form-label popup-label font-16 font-semibold">Import Slack Channel or Chat details</label>
                                    </div>
                                    <div className="w-100 float-start">
                                      <div className="input-group mb-2">
                                        <div className="fileUpload common-btn-class btn btn-browse px-4">
                                          <span className="font-17">Browse</span>
                                          <input id="uploadBtnSlack" type="file" accept='.csv' onChange={handleFileUpload} className="upload" />
                                        </div>
                                        <label id="uploadFileSlack" className=" formuploadbtn-browse formpopup-browsewid NoFileChoosen ps-2">{importedFileName}</label>                                    </div>

                                      <button className="btn btn-download-verification font-regular font-14 p-0" onClick={btnDowTemplate}>
                                        <span className="me-2"><img src='/Images/download-template.svg' alt="sort-icon" /></span>Custom Inventory Template</button>
                                    </div>
                                  </div>
                                  <div className="col-md-12 float-start mb-4">
                                    <div className="row">
                                      <label className="form-label popup-label font-16 font-semibold" onClick={(e) => StartInvSelect(e)} >Inventory name <span className="manditory-field">*</span></label>
                                      <div className="w-100 float-start">
                                        <input className="form-control form-style" type="text" value={InventoryName} id="InvName" maxLength={80} onChange={(e) => textchange(e)} placeholder="Enter the Inventory name" />
                                        <label id="InvNamelbl" className="required" hidden>Please Enter Inventory Name</label>
                                        <label id="InvExistingNamelbl" className="required" hidden={true}>Inventory Name Already Exist</label>                                        </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 float-start mb-5">
                                    <div className="row">
                                      <div className="col-md-6 float-start">
                                        <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory from date</label>
                                        <input type="date" onKeyDown={(e) => {
                                          e.preventDefault();
                                        }} className="inventory-date-form-control form-control date-style w-100" max={ToDate != "" ? ToDate : maxMigrationToDate} id="from-date" name="FromDate" onChange={(e) => StartInvSelect(e)} />
                                      </div>
                                      <div className="col-md-6 float-start">
                                        <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory to date</label>
                                        <input type="date" onKeyDown={(e) => {
                                          e.preventDefault();
                                        }} className="date-style form-control w-100" id="to-date" name='ToDate' min={FromDate} max={maxMigrationToDate} onChange={(e) => StartInvSelect(e)} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-end mb-4">
                                  <button type="button" className="btn btn-second me-2 font-16 font-semibold float-start  btn-cancel-border btn-scondary-custom common-btn-class" onClick={clearData}>Cancel</button>
                                  <button type="button" className="btn btn-prime font-16 font-semibold  btn-primary-theme float-start me-3 StartInvBtn common-btn-class" id='startInventory' onClick={startInventory}>Start Inventory</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Modal ends */}
                          {
                            InprogressState == 1 ? <button type="button" className="btn btn-refresh refreshInv refreshbtn-detailinv-res px-3 btn-sm d-inline-flex font-16 font-semibold btn-scondary-custom" onClick={loadGridPage} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> <span >Refresh</span></button>
                              : ""
                          }
                          <div className="row refreshbtn-detailinv-res search-detailinv-res">
                            <div className="Search-Container-DetailedInv search-detailinv-res">
                              <div className="input-group box-shodow-filter" >
                                <input type="text" className="form-control form-control-style-2" placeholder="Search" onChange={handleSearch} onKeyDown={handleKeyDown} aria-describedby="button-addon1" />
                                <button className="btn btn-outline-secondary" onClick={handleSearchIcon} type="button" id="button-addon1"><img src='/Images/search.svg' alt="search-icon" />
                                </button>
                              </div>
                              {/* <div className='refreshbtndiv'> */}

                              {/* </div> */}

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-1 mt-3 table-border table-responsive p-1">
                    <table className="table">
                      <thead className="tabel-head-bg">
                        <tr>
                          <th className=" table-head leftAlignInv font-14 font-bold"> Inventory Name<span>
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="InventoryName@desc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="InventoryName@asc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? false : true} /></span></th>
                          <th className=" table-head text-center font-14 font-bold">Status
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Status@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Status@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? false : true} />
                          </th>
                          <th className=" table-head rightAlignInv font-14 font-bold">Users
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Users@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Users@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? false : true} />
                          </th>
                          <th className=" table-head rightAlignInv font-14 font-bold">User Group
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="UserGroup@desc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="UserGroup@asc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? false : true} />
                          </th>
                          <th className=" table-head rightAlignInv font-14 font-bold">Channel
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Channel@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Channel@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Channel@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Channel@desc" ? false : true} />
                          </th>
                          <th className=" table-head rightAlignInv font-14 font-bold">Third Party Apps
                            <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ThirdPartyApps@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ThirdPartyApps@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? false : true} />
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        {gridBodyData()}
                      </tbody>
                    </table>
                    {/* <!-- channel list table ends here --> */}
                    {/* <!-- loadmore button section starts here --> */}

                    {/* <!-- loadmore button section ends here --> */}
                  </div>
                  <div className="col-md-12 mt-3 mb-5 text-center">
                    {totalItemsCount > countPerPage ?
                      <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>
                      : ""}
                  </div>
                  {/* <!-- footer section starts here --> */}
                </div>
                {/* <!-- inventory-summary tab starts here --> */}
                <div id="inventory-summary" className="tab-pane fade">
                  <div className="row float-end">
                    <div className="col-md-12 mb-3">
                      2
                    </div>
                  </div>
                </div>
                {/* <!-- inventory-summary tab ends here --> */}
              </div>
            </div>

            <div className="overlay" id='loader' hidden={true}>
              <div className="loader-position Loader-Content-position">
                <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                  <span className="visually-hidden"></span>

                </div>
                {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
              </div>
            </div>

            {/* <div className="overlay" id='loader' hidden={true}>
              <div className="loader-position Loader-Content-position">
                <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                  <span className="visually-hidden"></span>

                </div>
                <p>Do not close or refresh the application while the process is in progress...</p>
              </div>
            </div> */}




            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp1" tabIndex={-1} aria-hidden="true">
              <div className="modal-dialog modal-md">
                <div className="modal-content content-margin">
                  <div className="modal-body">
                    <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                    <div className="text-center success-content w-100 mt-5">
                      <h5 className="font-semibold font-18">Alert</h5>
                      <p className="success-text w-100 text-center mb-4">{ErrorMessage == "" ? "Please provide valid template!" : ErrorMessage}</p>
                      <div id="dynamicData"></div>
                      <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Alert pop-up--> */}
          </div>
          //#endregion

          :
          objClient != undefined && objClient.IsChannel == 0 && objClient.IsChat != 0 ?

            //#region Chat Licence
            <div className="col-md-11 mt-5">
              <div id="fadeDiv1" className="modal-backdrop fade show" hidden></div>
              <div className="row">
                <div className="tab-content">
                  <div id="high-level-inventory" className="tab-pane active w-100">
                    {/* <!-- channel list table starts here --> */}
                    <div className="IndividualScreenHeadingcss w-100">
                      <div className="col-md-12 float-start DetailedInvName" >
                        <div>
                          <label className=" channel-font font-18 font-bold">Detailed Inventory Summary</label>
                        </div>
                        <div className="float-start  w-100 ">
                          <div className="col-xl-7 col-lg-12 col-md-12 my-2 col-sm-12  float-start Inv_MarginTopContentSearch">
                            <label className='font-13'>Fetch detailed inventory of Slack workspace channels or chats messages, files,members and third party apps here!</label>

                            {/* <label>Fetch detailed inventory of your slack workspace channels or chats messages, files, members and third party apps here! </label> */}
                          </div>

                          <div className="col-xl-5 col-lg-12 col-sm-12 my-2 col-md-12 btm-margin-inventory  float-start" >
                            <button className="btn  btn-primaryStartInv startinv-detailinv-res float-end StartInvBtn btn-primary-theme common-btn-class-res-inventory" data-bs-toggle="modal" id="checkstartInv" data-bs-target="#cardDetails" onClick={() => startInventorybtn()} > Start Inventory</button>
                            {/* <button type="button" className="btn btn-refresh btn-sm float-end d-inline-flex font-14 font-semibold" style={{ visibility: 'hidden' }} id="refreshbtn" onClick={loadGridPageAgain}><span className="pe-2"><img src="/Images/refresh.svg" alt="refresh" /></span> Refresh</button> */}
                            {/* <button type="button" className="btn btn-refresh  px-3 btn-sm float-end d-inline-flex font-16 font-semibold " onClick={loadGridPage} style={{ visibility: 'hidden' }} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh"  /></span> <span >Refresh</span></button> */}
                            {/* Modal starts */}
                            <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                  <div className="modal-header border-0">
                                    <h5 className="inventory-title font-22 font-bold">Inventory details</h5>
                                    <button type="button" onClick={clearData} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                  </div>
                                  <div className="modal-body pt-1">
                                    <div className="col-md-12 mb-4">
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold">Select from the below options and fetch detailed inventory here!</label>
                                        <div className="w-100 float-start">
                                          <div className="row">
                                            {/* <div className="col-md-6">
                                              <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check1"><span>
                                                <input className="form-check-input float-start me-2" defaultChecked onClick={(e) => StartInvSelect(e)} type="radio" name="radio1" id="channel" /></span> Channel</label>
                                            </div> */}
                                            <div className="col-md-6">
                                              <label className="manage-client-date-font font-14 font-regular" htmlFor="Check2"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="mm" /></span> Multiparty Chats</label>
                                            </div>
                                            <div className="col-md-6 float-start">
                                              <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check3"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="dm" /></span>Direct Chats</label>
                                            </div>
                                            <div className="col-md-6 float-start">
                                              <label className="manage-client-date-font font-14 font-regular" htmlFor="Check4"><span><input className="form-check-input float-start me-2" type="radio" onClick={(e) => StartInvSelect(e)} name="radio1" id="custom" /></span>Custom Inventory</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12 float-start mb-4" id="browsebtn" hidden={true}>
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold">Import Slack Channel or Chat details</label>
                                      </div>
                                      <div className="w-100 float-start">
                                        <div className="input-group mb-2">
                                          <div className="fileUpload common-btn-class btn btn-browse px-4">
                                            <span className="font-17">Browse</span>
                                            <input id="uploadBtnSlack" type="file" accept='.csv' onChange={handleFileUpload} className="upload" />
                                          </div>
                                          <label id="uploadFileSlack" className=" formuploadbtn-browse formpopup-browsewid NoFileChoosen ps-2">{importedFileName}</label>                                    </div>
                                        <button className="btn btn-download-verification font-regular font-14 p-0" onClick={btnDowTemplate}>
                                          <span className="me-2"><img src='/Images/download-template.svg' alt="sort-icon" /></span>Custom Inventory Template</button>
                                      </div>
                                    </div>
                                    <div className="col-md-12 float-start mb-4">
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold" onClick={(e) => StartInvSelect(e)} >Inventory name <span className="manditory-field">*</span></label>
                                        <div className="w-100 float-start">
                                          <input className="form-control form-style" type="text" value={InventoryName} id="InvName" maxLength={80} onChange={(e) => textchange(e)} placeholder="Enter the Inventory name" />
                                          <label id="InvNamelbl" className="required" hidden>Please Enter Inventory Name</label>
                                          <label id="InvExistingNamelbl" className="required" hidden={true}>Inventory Name Already Exist</label>                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 float-start mb-5">
                                      <div className="row">
                                        <div className="col-md-6 float-start">
                                          <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory from date</label>
                                          <input type="date" onKeyDown={(e) => {
                                            e.preventDefault();
                                          }} className="inventory-date-form-control form-control date-style w-100" max={ToDate != "" ? ToDate : maxMigrationToDate} id="from-date" name="FromDate" onChange={(e) => StartInvSelect(e)} />
                                        </div>
                                        <div className="col-md-6 float-start">
                                          <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory to date</label>
                                          <input type="date" onKeyDown={(e) => {
                                            e.preventDefault();
                                          }} className="date-style form-control w-100" id="to-date" name='ToDate' min={FromDate} max={maxMigrationToDate} onChange={(e) => StartInvSelect(e)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 d-flex justify-content-end mb-4">
                                    <button type="button" className="btn btn-second me-2 font-16 font-semibold float-start  btn-cancel-border btn-scondary-custom common-btn-class" onClick={clearData}>Cancel</button>
                                    <button type="button" className="btn btn-prime font-16 font-semibold float-start me-3 StartInvBtn btn-primary-theme common-btn-class" id='startInventory' onClick={startInventory}>Start Inventory</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Modal ends */}
                            {
                              InprogressState == 1 ? <button type="button" className="btn btn-refresh refreshInv px-3 btn-sm refreshbtn-detailinv-res d-inline-flex font-16 font-semibold btn-scondary-custom" onClick={loadGridPage} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> <span >Refresh</span></button>
                                : ""
                            }
                            <div className="row refreshbtn-detailinv-res search-detailinv-res">
                              <div className="Search-Container-DetailedInv search-detailinv-res">
                                <div className="input-group box-shodow-filter" >
                                  <input type="text" className="form-control form-control-style-2" placeholder="Search" onChange={handleSearch} onKeyDown={handleKeyDown} aria-describedby="button-addon1" />
                                  <button className="btn btn-outline-secondary" onClick={handleSearchIcon} type="button" id="button-addon1"><img src='/Images/search.svg' alt="search-icon" />
                                  </button>
                                </div>
                                {/* <div className='refreshbtndiv'> */}

                                {/* </div> */}

                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 table-border mx-1 table-responsive p-1">
                      <table className="table  ">
                        <thead className="tabel-head-bg">
                          <tr>
                            <th className=" table-head leftAlignInv font-14 font-bold"> Inventory Name<span>
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="InventoryName@desc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="InventoryName@asc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? false : true} /></span></th>
                            <th className=" table-head text-center font-14 font-bold">Status
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Status@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Status@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? false : true} />
                            </th>
                            <th className=" table-head rightAlignInv font-14 font-bold">Users
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Users@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Users@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? false : true} />
                            </th>
                            <th className=" table-head rightAlignInv font-14 font-bold">User Group
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="UserGroup@desc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="UserGroup@asc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? false : true} />
                            </th>
                            <th className=" table-head rightAlignInv font-14 font-bold">Multiparty Chats
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="MM@desc" onClick={e => sortClicked(e)} hidden={sorthide == "MM@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="MM@asc" onClick={e => sortClicked(e)} hidden={sorthide == "MM@desc" ? false : true} />
                            </th>
                            <th className=" table-head rightAlignInv font-14 font-bold">Direct Chats
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="DM@desc" onClick={e => sortClicked(e)} hidden={sorthide == "DM@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="DM@asc" onClick={e => sortClicked(e)} hidden={sorthide == "DM@desc" ? false : true} />
                            </th>
                            <th className=" table-head rightAlignInv font-14 font-bold">Third Party Apps
                              <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ThirdPartyApps@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? true : false} />
                              <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ThirdPartyApps@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? false : true} />
                            </th>
                          </tr>
                        </thead>
                        <tbody >
                          {gridBodyData()}
                        </tbody>
                      </table>
                      {/* <!-- channel list table ends here --> */}
                      {/* <!-- loadmore button section starts here --> */}

                      {/* <!-- loadmore button section ends here --> */}
                    </div>
                    <div className="col-md-12 mt-3 mb-5 text-center">
                      {totalItemsCount > countPerPage && LoadMore != "No records were fetched" ?
                        <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>
                        : ""}
                    </div>
                    {/* <!-- footer section starts here --> */}
                  </div>
                  {/* <!-- inventory-summary tab starts here --> */}
                  <div id="inventory-summary" className="tab-pane fade">
                    <div className="IndividualScreenHeadingcss float-end">
                      <div className="col-md-12 mb-3">
                        2
                      </div>
                    </div>
                  </div>
                  {/* <!-- inventory-summary tab ends here --> */}
                </div>
              </div>

              <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                  <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                    <span className="visually-hidden"></span>

                  </div>
                  {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
              </div>

              {/* <!--Alert Pop-up--> */}
              <div className="modal fade" id="alertPopUp1" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                  <div className="modal-content content-margin">
                    <div className="modal-body">
                      <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                      <div className="text-center success-content w-100 mt-5">
                        <h5 className="font-semibold font-18">Alert</h5>
                        <p className="success-text w-100 text-center mb-4">{ErrorMessage == "" ? "Please provide valid template!" : ErrorMessage}</p>
                        <div id="dynamicData"></div>
                        <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Alert pop-up--> */}
            </div>
            //#endregion

            :

            //#region Full License
            <div className="col-md-11 mt-5">
              <div id="fadeDiv1" className="modal-backdrop fade show" hidden></div>

              <div className="col-md-12 col-12 col-sm-12 float-start">
                <div className="tab-content">
                  <div id="high-level-inventory" className="tab-pane active w-100">
                    {/* <!-- channel list table starts here --> */}
                    <div className="IndividualScreenHeadingcss w-100">
                      <div className="col-md-12 float-start DetailedInvName" >
                        <div>
                          <label className=" channel-font font-18 font-bold">Detailed Inventory Summary</label>
                        </div>
                        <div className="float-start  w-100 ">
                          <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 my-3 float-start Inv_MarginTopContentSearch">
                            <label className='font-13'>Fetch detailed inventory of Slack workspace channels or chats messages, files, members and third party apps here</label>

                            {/* <label>Fetch detailed inventory of your slack workspace channels or chats messages, files, members and third party apps here! </label> */}
                          </div>
                          {/* <div>
                    <label>Detailed InventorySummary</label>
                    </div> */}
                          <div className="col-xl-7 col-lg-12 col-sm-12 col-md-12 my-2 btm-margin-inventory float-start" >

                            <button className="btn  btn-primaryStartInv  startinv-detailinv-res StartInvBtn btn-primary-theme common-btn-class-res-inventory" data-bs-toggle="modal" id="checkstartInv" data-bs-target="#cardDetails" onClick={() => startInventorybtn()} > Start Inventory</button>

                            {/* <button type="button" className="btn btn-refresh btn-sm float-end d-inline-flex font-14 font-semibold" style={{ visibility: 'hidden' }} id="refreshbtn" onClick={loadGridPageAgain}><span className="pe-2"><img src="/Images/refresh.svg" alt="refresh" /></span> Refresh</button> */}
                            {/* <button type="button" className="btn btn-refresh  px-3 btn-sm float-end d-inline-flex font-16 font-semibold " onClick={loadGridPage} style={{ visibility: 'hidden' }} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh"  /></span> <span >Refresh</span></button> */}
                            {/* Modal starts */}
                            <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                  <div className="modal-header border-0">
                                    <h5 className="inventory-title font-22 font-bold">Inventory details</h5>
                                    <button type="button" onClick={clearData} className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                  </div>
                                  <div className="modal-body pt-1">
                                    <div className="col-md-12 col-sm-12 col-lg-12 mb-4">
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold">Select from the below options and fetch detailed inventory here!</label>
                                        <div className="w-100 float-start">
                                          <div className="row">
                                            <div className="col-md-12 col-lg-6 col-sm-12">
                                              <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check1"><span>
                                                <input className="form-check-input float-start me-2" defaultChecked onClick={(e) => StartInvSelect(e)} type="radio" name="radio1" id="channel" /></span> Channel</label>
                                            </div>
                                            <div className="col-md-12 col-lg-6 col-sm-12">
                                              <label className="manage-client-date-font font-14 font-regular" htmlFor="Check2"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="mm" /></span> Multiparty Chats</label>
                                            </div>
                                            <div className="col-md-12 col-lg-6 col-sm-12 float-start">
                                              <label className="manage-client-date-font channel-check font-14 font-regular" htmlFor="Check3"><span><input className="form-check-input float-start me-2" onClick={(e) => StartInvSelect(e)} name="radio1" type="radio" id="dm" /></span>Direct Chats</label>
                                            </div>
                                            <div className="col-md-12 col-lg-6 col-sm-12 float-start">
                                              <label className="manage-client-date-font font-14 font-regular" htmlFor="Check4"><span><input className="form-check-input float-start me-2" type="radio" onClick={(e) => StartInvSelect(e)} name="radio1" id="custom" /></span>Custom Inventory</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-12 col-sm-12 col-lg-12 col-12 float-start mb-4" id="browsebtn" hidden={true}>
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold">Import Slack Channel or Chat details</label>
                                      </div>
                                      <div className="w-100 float-start">
                                        <div className="input-group mb-2">
                                          <div className="fileUpload common-btn-class btn btn-browse px-4">
                                            <span className="font-17">Browse</span>
                                            <input id="uploadBtnSlack" type="file" accept='.csv' onChange={handleFileUpload} className="upload" />
                                          </div>
                                          <label id="uploadFileSlack" className=" formuploadbtn-browse formpopup-browsewid NoFileChoosen ps-2">{importedFileName}</label>                                    </div>
                                        <button className="btn btn-download-verification font-regular font-14 p-0" onClick={btnDowTemplate}>
                                          <span className="me-2"><img src='/Images/download-template.svg' alt="sort-icon" /></span>Custom Inventory Template</button>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-lg-12 float-start mb-4">
                                      <div className="row">
                                        <label className="form-label popup-label font-16 font-semibold" onClick={(e) => StartInvSelect(e)} >Inventory name <span className="manditory-field">*</span></label>
                                        <div className="w-100 float-start">
                                          <input className="form-control form-style" type="text" value={InventoryName} id="InvName" maxLength={80} onChange={(e) => textchange(e)} placeholder="Enter the Inventory name" />
                                          <label id="InvNamelbl" className="required" hidden>Please Enter Inventory Name</label>
                                          <label id="InvExistingNamelbl" className="required" hidden={true}>Inventory Name Already Exist</label>                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-lg-12 float-start mb-5">
                                      <div className="row">
                                        <div className="col-lg-6 col-sm-12 col-md-12 btm-margin-inventory float-start">
                                          <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory from date</label>
                                          <input type="date" onKeyDown={(e) => {
                                            e.preventDefault();
                                          }} className="inventory-date-form-control form-control date-style w-100" max={ToDate != "" ? ToDate : maxMigrationToDate} id="from-date" name="FromDate" onChange={(e) => StartInvSelect(e)} />
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-lg-6 float-start">
                                          <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3" >Inventory to date</label>
                                          <input type="date" onKeyDown={(e) => {
                                            e.preventDefault();
                                          }} className="date-style form-control w-100" id="to-date" name='ToDate' min={FromDate} max={maxMigrationToDate} onChange={(e) => StartInvSelect(e)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-lg-12 d-flex justify-content-end mb-4">
                                    <button type="button" className="btn btn-second me-2 font-16 font-semibold float-start btn-scondary-custom  btn-cancel-border common-btn-class" onClick={clearData}>Cancel</button>
                                    <button type="button" className="btn btn-prime font-16 font-semibold btn-primary-theme float-start me-3 StartInvBtn common-btn-class" id='startInventory' onClick={startInventory}>Start Inventory</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Modal ends */}
                            {
                              InprogressState == 1 ? <button type="button" className="btn btn-refresh refreshInv px-3 btn-sm refreshbtn-detailinv-res d-inline-flex font-16 font-semibold btn-scondary-custom" onClick={loadGridPage} id="refreshbtn"><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> <span >Refresh</span></button>
                                : ""
                            }
                             
                            <div className=" refreshbtn-detailinv-res search-detailinv-res d-flex flex-nowrap">
                           
                              <div className="Search-Container-DetailedInv search-detailinv-res ">
                                <div className="input-group box-shodow-filter" >
                                  <input type="text" className="form-control form-control-style-2" placeholder="Search" onChange={handleSearch} onKeyDown={handleKeyDown} aria-describedby="button-addon1" />
                                  <button className="btn btn-outline-secondary" onClick={handleSearchIcon} type="button" id="button-addon1"><img src='/Images/search.svg' alt="search-icon" />
                                  </button>
                                </div>
                                {/* <div className='refreshbtndiv'> */}

                                {/* </div> */}

                              </div>
                             
                            </div>
                            <p className='mb-0 font-semibold font-16 text-nowrap float-end mt-2 me-3'># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {totalItemsCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-12 col-sm-12 col-lg-12 float-start mt-2 ">
                      <div className='table-responsive p-1'>
                        <table className="table table-border">
                          <thead className="tabel-head-bg">
                            <tr>
                              <th className=" table-head leftAlignInv font-14 font-bold"> Inventory Name<span>
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="InventoryName@desc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="InventoryName@asc" onClick={e => sortClicked(e)} hidden={sorthide == "InventoryName@desc" ? false : true} /></span></th>
                              <th className=" table-head text-center font-14 font-bold">Status
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Status@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Status@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">Users
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Users@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Users@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Users@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">User Group
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="UserGroup@desc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="UserGroup@asc" onClick={e => sortClicked(e)} hidden={sorthide == "UserGroup@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">Channel
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Channel@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Channel@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Channel@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Channel@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">Multi-Party Chats
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="MM@desc" onClick={e => sortClicked(e)} hidden={sorthide == "MM@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="MM@asc" onClick={e => sortClicked(e)} hidden={sorthide == "MM@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">Direct Chats
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="DM@desc" onClick={e => sortClicked(e)} hidden={sorthide == "DM@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="DM@asc" onClick={e => sortClicked(e)} hidden={sorthide == "DM@desc" ? false : true} />
                              </th>
                              <th className=" table-head rightAlignInv font-14 font-bold">Third Party Apps
                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ThirdPartyApps@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? true : false} />
                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ThirdPartyApps@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ThirdPartyApps@desc" ? false : true} />
                              </th>
                            </tr>
                          </thead>
                          <tbody >
                            {gridBodyData()}
                          </tbody>
                        </table>
                      </div>
                      {/* < !-- channel list table ends here --> */}
                      {/* <!-- loadmore button section starts here --> */}

                      {/* <!-- loadmore button section ends here --> */}
                    </div>
                    <div className="col-md-12 mt-3 mb-5 text-center">
                      {totalItemsCount > countPerPage && LoadMore != "no records were fetched" ?
                        <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>
                        : ""}
                    </div>
                    {/* <!-- footer section starts here --> */}
                  </div>
                  {/* <!-- inventory-summary tab starts here --> */}
                  <div id="inventory-summary" className="tab-pane fade">
                    <div className="row float-end">
                      <div className="col-md-12 mb-3">
                        2
                      </div>
                    </div>
                  </div>
                  {/* <!-- inventory-summary tab ends here --> */}
                </div>
              </div>
              <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                  <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                    <span className="visually-hidden"></span>

                  </div>
                  {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
              </div>

              {/* <!--Alert Pop-up--> */}
              <div className="modal fade" id="alertPopUp1" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                  <div className="modal-content content-margin">
                    <div className="modal-body">
                      <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                      <div className="text-center success-content w-100 mt-5">
                        <h5 className="font-semibold font-18">Alert</h5>
                        <p className="success-text w-100 text-center mb-4">{ErrorMessage == "" ? "Please provide valid template!" : ErrorMessage}</p>
                        <div id="dynamicData"></div>
                        <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Alert pop-up--> */}

              <div className="modal fade" id="alertlicenseNav" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                  <div className="modal-content content-margin">
                    <div className="modal-body">
                      <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                      <div className="text-center success-content w-100 mt-5">
                        <h5 className="font-semibold font-18">Alert</h5>
                        <p className="success-text w-100 text-center mb-4">Before proceeding with detailed inventory, assign license to your users</p>
                        <div id="dynamicData"></div>
                        <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={navigateToLicensedUser}>Go to License Assignment</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
        //#endregion


      }
    </div >
  );
}
