import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/InventoryServices';

import { ExportToCsv } from 'export-to-csv';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import backarrow from '/Images/backarrow.svg';
import ReactTooltip from 'react-tooltip';
import fileSizes from 'filesize';
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import constants from '../../Common/Constants'





export function DMGrid() {
    let GridLoadarray: any[] = [];
    let history = useHistory();
    const state = useLocation().state as any;


    const [gridData, setGridData] = useState(GridLoadarray);
    const [totalItemsCount, setTotalItemsCount] = useState(10);

    const [searchValue, setSearchValue] = useState("");
    let [exportsearchValue, setexportsearchValue] = useState("");

    const [tmpsearchValue, settmpSearchValue] = useState("");

    const [columnOrder, setColumnOrder] = useState("asc");
    const [columnName, setColumnName] = useState("Human posted");
    const [sorthide, setsorthide] = useState("");
    const [clientID, setclientID] = useState(state.ClientID);
    const [token, settoken] = useState(state.token);
    const [adminToken, setadminToken] = useState(state.adminToken);
    const [inventorySummaryID, setInventorySummaryID] = useState(state.ID);
    const [type, setType] = useState("Grid");
    const [indexPerPage, setIndexPerPage] = useState(1);
    const [fileSize, setFileSize] = useState();
    const [exportbtn, setexportbtn] = useState(false);
    const [InventoryName, setInventoryName] = useState(state.InventoryName);
    const [LoadMore, setLoadmore] = useState("")
    let [ErrorMessage, setErrorMessage] = useState("");
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    let byteValue = objCryptoJS.AES.decrypt(state.token, r_n_Key);
    let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    let objClient: any = jwt(decryptedValue);
    SessionEpiredDateTime = objClient.CreatedAt;

    const [countPerPage, setCoutPerPage] = useState(10);
    useEffect(() => {
        resetSessionTimer();
        let removeFooter: any = document.getElementById("MainContainerMFE");
        removeFooter.classList.add("container-bg-withoutFooter")
        loadGridPage();
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [columnName, columnOrder, countPerPage])


    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            ErrorMessage = "Your session has expired. Please log in again to continue using the application."
            setErrorMessage(ErrorMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (state.ClientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: state.token
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(SessionEpiredDateTime);
                    //console.log(state.token);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    state.token = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(state.token);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setErrorMessage(ErrorMessage);
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")

            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setErrorMessage(ErrorMessage);
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion



    const exportMethod = async () => {
        try {
            let a: any = document.getElementById("loader");
            a.removeAttribute("hidden");
            let config = ({
                method: "POST",
                url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getDMDetails`,
                token: token,

                data: {

                    "clientID": clientID,
                    "InventorySummaryID": Number(inventorySummaryID),
                    "type": "Export",
                    "sortColumn": `${columnName}`,
                    "searchText": `${exportsearchValue}`,
                    "sortType": `${columnOrder}`,
                    "pageIndex": indexPerPage

                }
            })
            if( adminToken !== null){
                (config.data as any).token = adminToken
              }
            let data: any = await gridLoad(config);
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")

                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else if (data.data.data == "no records were fetched") {
                ErrorMessage = "No Records Found in the Grid";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
            else if (data.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
            }
            else {
                let exportData = data.data.data.DMDetails[0];


                const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: false,
                    title: 'DM Details',
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true,
                    filename: "DM Details"
                    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
                };
                const csvExporter = new ExportToCsv(options);

                csvExporter.generateCsv(exportData);
            }
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
        }
        catch (error: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const loadGridPage = async () => {
         
        try {
            let a: any = document.getElementById("loader");
            a.removeAttribute("hidden");
            let config = ({
                method: "POST",
                url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getDMDetails`,
                token: token,
                data: {

                    "clientID": clientID,
                    "InventorySummaryID": parseInt(inventorySummaryID),
                    "type": `${type}`,
                    "sortColumn": `${columnName}`,
                    "searchText": `${exportsearchValue}`,
                    "sortType": `${columnOrder}`,
                    "pageIndex": indexPerPage

                }
            })
            if( adminToken !== null){
                (config.data as any).token = adminToken
              }



             
            let data: any = await gridLoad(config);
            let b: any = document.getElementById("loader");
            b.setAttribute("hidden", "hidden");
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")

                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            if (data.data.data == "no records were fetched" || data.data.data == "No records were fetched" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data.length === 0) {
                setGridData(["No Records Found"]);
                setLoadmore(data.data.data)
            }
            else if (data.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
            }
            else {
                setGridData(data.data.data.DMDetails[0]);
                setLoadmore(data.data.data)
                setTotalItemsCount(data.data.data.DMDetails[1][0].DMCount)
                setInventoryName(data.data.data.DMDetails[0][0].InventoryName)
            }
        }
        catch (error: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const handleSearchIcon = (e: any) => {

        try {
             
            if (searchValue != null && searchValue != undefined) {

                if (searchValue !== tmpsearchValue) {

                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    gridData.length = 0;

                    settmpSearchValue(searchValue);

                    setIndexPerPage(indexPerPage ? indexPerPage : 1);

                    setCoutPerPage(countPerPage ? countPerPage : 10);

                    loadGridPage();

                }

            }

        }

        catch (error: any) {

            ////console.log(error);

        }

    }
    const gridBodyData = () => {
        try {
             
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
                ////console.log(gridData[0].HumanMessageCount);
                return gridData.map((obj, index) => {
                    let stepDMNames = "";
                    if (obj.SourceDMName.length > 73) {
                        obj.DMName = obj.SourceDMName.substring(0, 73) + "..."
                        let lstDMNames = obj.SourceDMName.split(",");
                        lstDMNames.forEach((element: any) => {
                             
                            stepDMNames = stepDMNames + element + "<br/>";
                        });
                    }
                    else {
                        obj.DMName = obj.SourceDMName;
                    }
                     
                    let MessageCount = parseInt(obj.HumanMessageCount) + parseInt(obj.BotMessageCount);

                    let MessageSize = obj.OverAllSize - obj.FilesSize;
                    let MesSize = fileSizes(MessageSize);
                    let FilesSize = fileSizes(obj.FilesSize);
                    obj.fileSiz = fileSizes(obj.OverAllSize);
                    let HumanMessageCount = parseInt(obj.HumanMessageCount)
                    let HumanMessageCount1 = HumanMessageCount.toLocaleString("en-US")
                    let BotMessageCount = parseInt(obj.BotMessageCount)
                    let BotMessageCount1 = BotMessageCount.toLocaleString("en-US")
                    let TotalMessageCount = parseInt(obj.TotalMessageCount)
                    let TotalMessageCount1 = TotalMessageCount.toLocaleString("en-US")
                    let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"
                    return (
                        <tr className={rowClass} >
                            <td data-tip={obj.DMName.length > 15 ? stepDMNames : ""} data-place={obj.DMName.length > 15 ? 'right' : ""} className=" py-3 tabel-data-font-level1" >{obj.DMName}
                                <span>
                                    <ReactTooltip className='mySepecialClass' multiline={true} />
                                </span></td>
                            <td className="py-3 tabel-data-font-level1 rightAlignInv">{HumanMessageCount1}</td>
                            <td className="py-3 tabel-data-font-level1 rightAlignInv">{BotMessageCount1}</td>
                            <td data-tip={"Message Count: " + MessageCount + "<br>" + " File Count: " + obj.FilesCount} className="py-3 tabel-data-font-level1 rightAlignInv">{TotalMessageCount1}
                            </td>
                            <td data-tip={"Message Size: " + MesSize + "<br>" + "File Size: " + FilesSize} className="py-3 tabel-data-font-level1 rightAlignInv">{obj.fileSiz}</td>
                            {
                                obj.IsCompleted == true ? 
                                <td className="py-3 tabel-data-font-level1 text-center "><span className="status-styling4 status">Success</span> </td> 
                                :<td className="py-3 tabel-data-font-level1 text-center"><span className="fail-status status">Failed</span></td>
                            }
                        </tr>
                    )
                }
                )
            }
            else if (gridData[0] == undefined && gridData[0].SourceDMName == undefined) {
                return (<tr> <td className="centertext" colSpan={5}></td>  </tr>)
            }
            else {
                return (
                    <tr>
                        <td className="aligncenter" colSpan={5}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
                            <p className="mt-2">No records found</p></td>
                    </tr>)
            }
        }
        catch (error: any) {
            ////console.log(error)
        }
    }
    const sortClicked = (e: any) => {
        try {
            let sortID = e.target.id;
            let sortval = sortID.split('@');

            setColumnName(sortval[0])
            setColumnOrder(sortval[1])
            setsorthide(sortID)

        }
        catch (error: any) {

        }
    }
    const handleSearch = (e: any) => {
        try {
            setSearchValue(e.target.value)
        }
        catch (error: any) {

        }
    }
    const handleKeyDown = (e: any) => {

        try {



            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {

                if (searchValue !== tmpsearchValue) {

                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    gridData.length = 0;

                    settmpSearchValue(searchValue);

                    setIndexPerPage(indexPerPage ? indexPerPage : 1);

                    setCoutPerPage(countPerPage ? countPerPage : 10);

                    loadGridPage();

                }

            }

        }

        catch (error: any) {

            ////console.log(error);

        }

    }
    const handleLoadMore = () => {
        try {
            setCoutPerPage(countPerPage + 10);
            setIndexPerPage(indexPerPage + 1);
        }
        catch (error: any) {

        }
    }
    const handleExport = () => {
        try {
            exportMethod();
            setexportbtn(true)
        }
        catch (error: any) {
            ////console.log("error", error);
        }
    }
    const DMBackbtn = () => {
        try {

            history.push({
                pathname: "/Inventory/Detailed",
                state: false
            })
            // window.location.reload();

            // window.location.pathname = '/Inventory/Detailed';
        }
        catch (error: any) {

        }
    }
    const popUpOkClick = (type: string) => {

        try {

            //#region Hide PopUp
            if (isSessionEpired === false) {


                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")

                popup?.setAttribute("class", "modal fade");

                popup?.setAttribute("style", "display:none");

                popup?.setAttribute("aria-hidden", "true");

                popup?.removeAttribute("aria-modal");

                popup?.removeAttribute("role");

                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

                // $('input[name="checkbox"]').prop('checked', false); // Unchecks it

                let migrateButton = document.getElementById("migrateButton")

                migrateButton?.setAttribute('disabled', 'disabled');

                //   loadGridPage();

                //#endregion
            }
            else {
                window.location.pathname = "/Login";

            }

        } catch (error) {

            ////console.log("Issue Faced");

        }

    }
    return (

        <div className="row justify-content-center card-container mx-0 ms-0 single-grile-grid-res">
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                    <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                    {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>

            <div className="col-md-11 mt-4">


                <div className="col-md-12 col-12 float-start">
                    <label className="user-head-font font-26 font-bold mb-2"><a className="me-2 anchorAsPointer" onClick={DMBackbtn} title="Back"><img title="Back" src="/Images/backarrow.svg" alt="Back arrow" /></a>Direct Chats</label>


                    <div className="w-100 float-start">
                        {/* <label className=" inventory-details-font font-14 font-regular me-2">Fetch the Slack Channels list here!</label>
                  <button type="button" className="btn btn-slack-list font-16 font-semibold">Get Slack Channel List</button> */}
                    </div>



                    <div className="tab-content">
                        <div id="high-level-inventory" className="tab-pane active w-100">



                            <div className="IndividualScreenHeadingcss w-100">
                                <div className="col-md-12 col-12 col-sm-12 col-lg-12 float-start">
                                    <div className="float-start  w-100 ">
                                        <div className="float-start Inv_MarginTopContentSearch">
                                            <label className="font-14 my-2 me-5 pb-2 font-bold">Inventory :
                                                {
                                                    InventoryName?.length > 36 ? <span data-tip={InventoryName} className="font-regular font-14">{InventoryName.substring(0, 33)}...</span> :
                                                        <span data-tip="" className="font-regular font-14">{InventoryName}</span>
                                                }

                                            </label>


                                        </div >
                                        <div className="float-end">

                                            <button className="btn my-2 btn-inventory-export btn-scondary-custom text- px-3 float-end font-semibold ms-2 font-16" onClick={() => handleExport()} ><img src='/Images/export-excel.svg' alt="export-excel" onClick={() => handleExport()} /> Export</button>




                                            <div className="row float-end">
                                                <div className="col-md-12 col-lg-12 col-sm-12 my-2  sg-search-res2">
                                                    <div className="input-group box-shodow-filter">
                                                        <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                                                        <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={handleSearchIcon}><img src='/Images/search.svg' alt="search-icon" />

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='mb-0 font-semibold font-16 text-nowrap float-end mt-2 me-3 pt-2' ># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {gridData[0] != "No Records Found"?totalItemsCount:0}</p>

                                        </div>



                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-12 float-start mt-2 table-responsive p-1">
                                <table className="table table-border">
                                    <thead className="tabel-head-bg">
                                        <tr>
                                            <th className="table-head font-14 font-bold">Direct Chats Name<span>

                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="DM Name@desc" onClick={e => sortClicked(e)} hidden={sorthide == "DM Name@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="DM Name@asc" onClick={e => sortClicked(e)} hidden={sorthide == "DM Name@desc" ? false : true} />
                                            </span></th>

                                            <th className="table-head rightAlignInv font-14 font-bold">Human Posted

                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Human posted@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Human posted@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Human posted@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Human posted@desc" ? false : true} />
                                            </th>
                                            <th className="table-head rightAlignInv font-14 font-bold">Bot Posted


                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Bot posted@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Bot posted@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Bot posted@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Bot posted@desc" ? false : true} /></th>

                                            <th className="table-head rightAlignInv font-14 font-bold">Total Count

                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Total count@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Total count@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Total count@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Total count@desc" ? false : true} />

                                            </th>
                                            <th className="table-head rightAlignInv font-14 font-bold">Chat size

                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Chat size@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Chat size@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Chat size@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Chat size@desc" ? false : true} />
                                            </th>
                                             <th className="table-head  font-14 font-bold text-center">Status

                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Chat size@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Chat size@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Chat size@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Chat size@desc" ? false : true} />
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody >

                                        {gridBodyData()}

                                    </tbody>
                                </table>


                            </div>
                            <div className="col-md-12 mt-3 mb-5 text-center">
                                {totalItemsCount > countPerPage && LoadMore != "no records were fetched" ?
                                    <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>


                                    : ""}
                            </div>

                        </div>

                        <div id="inventory-summary" className="tab-pane fade">
                            <div className="row float-end">
                                <div className="col-md-12 mb-3">
                                    2
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* <div className="icon-container">
                <label className="custom-icon-style" id="hidden-section" data-bs-toggle="modal" data-bs-target="#cardDetails">
                    <img className="fixed-icon" src={helpIcon} alt="help-icon" />
                    <img className="expand-icon expand-content" src={helpIcon} alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
                </label>
            </div> */}
            <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-radius">
                        <div className="modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help ?</label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to learn how to take Inventory!</label>
                                    </div>

                                    <div className="w-100 float-start text-center">
                                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/1PnVor36_40" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end mb-4">
                                <button type="button" className="btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                                <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position">
                  <div className="spinner-border Loader text-primary align-center" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                  <p>Do not close or refresh the application while the process is in progress...</p>
                </div>
              </div>  */}
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position">
                  <div className="spinner-border Loader text-primary align-center" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                  <p>Do not close or refresh the application while the process is in progress...</p>
                </div>
              </div> */}

        </div>

    );
}