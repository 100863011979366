import { useState, useEffect } from "react";
import { gridLoad } from "../../Services/AdminServices";
import { useLocation, useHistory } from 'react-router-dom'
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import intlTelInput from 'intl-tel-input';
import { type } from "@testing-library/user-event/dist/type";
import { stringify } from "querystring";
import constants from "../../Common/Constants";

export default function AccountInfo() {
    const [isServiceAdmin, setisServiceAdmin] = useState<any>();
    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    let local: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    }

    const state = useLocation().state as any;
    let formObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "", CountryCode: "" }
    let [formState, setFormState] = useState(formObj);
    let [isSSOAccount, setIsSSOAccount] = useState(false);
    let licenseObj = { ChannelCount: 0, ChannelCost: 0, ChatCount: 0, ChatCost: 0, UserCount: 0, UserCost: 0, resCost: 0, TotalCost: 0, parallelCount: 0, validTill: 0 }
    let [license, setLicense] = useState(licenseObj)
    let ObjlicenseDetails = { AvailableChannelLicense: 0, AvaliableGroupChatLicense: 0, PurchasedChannelMigrationLicense: 0, PurchasedGroupChatMigrationLicense: 0, AvailableUserLicense: 0, PurchasedUserLicenseCount: 0, ID: 0, CompanyName: "", IsUserBasedLicense: '', ValidTillDate: "" };
    let [updatedLicense, setUpdatedLicense] = useState(ObjlicenseDetails);
    let [alertMessage, setAlertMessage] = useState("")
    const [clientID, setclientID] = useState(state.ID);
    const history = useHistory()
    let [regexPass, setRegexPass] = useState(true)
    let [licensePass, setLicensePass]= useState(true)
    let licenseRegexObj = { ChannelCount: false, ChannelCost: false, ChatCount: false, ChatCost: false, UserCount: false, UserCost: false, resCost: false }
    let [licenseRegex, setLicensseRegex] = useState(licenseRegexObj)
    let [loaderText, setLoaderText] = useState("");
    let [isUserBased, setIsUserBased] = useState(state.IsUserBasedLicense);
    let [isClientActive, setIsClientActive] = useState(true);
    let [initialSSOStatus, setInitialSSOStatus] = useState(false);
    let [initalClientStatus, setinitialClientStatus] = useState(false);
    let [channelCount, setChannelCount] = useState('');
    let [chatCount, setChatCount] = useState('');
    let [reason, setReason] = useState('');
    let [userCount, setUserCount] = useState('');

    let [channelCountError, setChannelCountError] = useState('');
    let [chatCountError, setChatCountError] = useState('');
    let [reasonError, setReasonError] = useState('');

    // Variable Declarations for the Chat Html Migration
    let [isChatHtmlMigration, setIsChatHtmlMigration] = useState(false);
    let [chatHtmlCount, setChatHtmlCount] = useState('');
    let [chatHtmlCost, setChatHtmlCost] = useState('');


    useEffect(() => {
        let submitButton = document.getElementById("submitButton")
        submitButton?.setAttribute("disabled", "disabled");
        const input: any = document.querySelector("#phone");

        // Validate the Service Admin
        if(objClient?.Role == "Service Admin"){
            setisServiceAdmin(true)
        }

        intlTelInput(input, {
            preferredCountries: ['us'],
            separateDialCode: true,
        });
        loadAccountInfo()
    }, [])
    const loadAccountInfo = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/getClientDetails`,
                    data: {
                        "clientID": Number(clientID)
                    },
                }
                )
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');
                if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else {
                    accountInfo(data.data.data[0])
                }
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

        }
    }

    const handleSubmitClick = async () => {
        try {
            if (clientID != undefined && clientToken != undefined) {
                let submitButton = document.getElementById("submitButton")
                submitButton?.setAttribute("disabled", "disabled")

                countrycode();
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/admin/updateClientDetails`,
                    data: {
                        "clientID": parseInt(clientID),
                        "name": `${formState.UserName}`,
                        "mailID": `${formState.EmailAddress}`,
                        "phone": `${formState.PhoneNumber}`,
                        "countryCode": `${formState.CountryCode}`,
                        "companyName": `${formState.CompanyName}`,
                        "channelCount": Number(license.ChannelCount),
                        "channelCost": Number(license.ChannelCost),
                        "chatCount": Number(license.ChatCount),
                        "chatCost": Number(license.ChatCost),
                        "userCount": Number(license.UserCount),
                        "userCost": Number(license.UserCost),
                        "parallelCount": Number(license.parallelCount),
                        "validTill": Number(license.validTill),
                        "resCost": Number(license.resCost),
                        "isUserBased": isUserBased,
                        "isSSOAccount": isSSOAccount,
                        "isActive": isClientActive,
                        "isRevokeLicense" : false,
                        "reason" : "",
                        "chatHtmlCount": Number(chatHtmlCount),
                        "chatHtmlCost": Number(chatHtmlCost),
                        "isChatHtmlMigration": isChatHtmlMigration,
                    },
                }
                )
                let loader = document.getElementById("loader")
                loader?.removeAttribute('hidden');
                let data: any = await gridLoad(config);

                if (data.data.statusCode != 200 && data.data.data=='Mail ID already present' ) {
                    setAlertMessage("Email Id entered is already exist!")
                }
                else if(data.data.statusCode != 200){
                    setAlertMessage("Failed To Update")
                }
                else {
                    accountInfo(data.data.data[0])
                    updatedLicense = { AvailableChannelLicense: data.data.data[0].AvailableChannelLicense, AvaliableGroupChatLicense: data.data.data[0].AvaliableGroupChatLicense, PurchasedChannelMigrationLicense: data.data.data[0].PurchasedChannelMigrationLicense, PurchasedGroupChatMigrationLicense: data.data.data[0].PurchasedGroupChatMigrationLicense, PurchasedUserLicenseCount: data.data.data[0].PurchasedUserLicenseCount, AvailableUserLicense: data.data.data[0].AvailableUserLicense, ID: state.ID, CompanyName: data.data.data[0].CompanyName, IsUserBasedLicense: data.data.data[0].IsUserBasedLicense, ValidTillDate: data.data.data[0].ValidTillDate }
                    setUpdatedLicense(updatedLicense)                   
                }
                loader?.setAttribute('hidden', 'hidden');
                setLoaderText("")
                let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                //#endregion
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {
            setLoaderText("")
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

        }
    }

    const countrycode = async () => {
        try {
            var selectedflag: any = document.getElementsByClassName("iti__flag")
            var selectedCountryCode: any = document.getElementsByClassName("iti__selected-dial-code")
            var title: any = document.getElementsByClassName('iti__selected-flag')
            formState.CountryCode = `${selectedCountryCode[0].innerText}~${selectedflag[0].className}`;
        }
        catch (error: any) {

        }
    }

    const accountInfo = async (accDetails: any) => {
        try {
            //#region new method
            setIsChatHtmlMigration(accDetails.isChatHtmlMigration)
            setIsSSOAccount(accDetails.IsSSOAccount);
            setInitialSSOStatus(accDetails.IsSSOAccount);
            if(accDetails?.IsActive!=undefined){
                setIsClientActive(accDetails.IsActive);
            }

            let storedCountry = accDetails.CountryCode.split('~');
            let countrycode = storedCountry[0]
            var selectedCountry: any = document.getElementsByClassName("iti__selected-dial-code")
            selectedCountry[0].innerHTML = countrycode //CountryCode
            selectedCountry[0].previousSibling.className = storedCountry[1] //CountryFlag
            let title: any = document.getElementsByClassName('iti__selected-flag');
            // title[0].title = storedCountry[2] //CountryTitle
            let paddingclass = (countrycode.length == 2) ? "countryCodePadding1" : (countrycode.length == 3) ? "countryCodePadding2" : "countryCodePadding3"
            let addClass: any = document.getElementById("phone")
            addClass?.classList.add(paddingclass);
            //#endregion

            formState = { UserName: accDetails.Name, CompanyName: accDetails.CompanyName, EmailAddress: accDetails.MailID, PhoneNumber: accDetails.Phone, CountryCode: accDetails.CountryCode }
            setFormState(formState);
            license = { ChannelCount: 0, ChannelCost: 0, ChatCount: 0, ChatCost: 0, UserCount: 0, UserCost: 0, resCost: 0, TotalCost: 0, parallelCount: 0, validTill: 0 }
            setLicense(license);
            let parallelInstance: any = document.getElementById('parallelCount');
            parallelInstance.value = "Select Parallel Instance";
            let validDate: any = document.getElementById('validTill');
            validDate.value = "Select Valid Till";
        }
        catch (error: any) {

        }
    }


    const OnChangeName = (e: any) => {
        formState = { ...formState, [e.target.name]: e.target.value }
        setFormState(formState)
    }

    const OnChangeLicense = (e: any) => {
        try {
            if ((e.target.id == "parallelCount") || (e.target.id == "validTill")) {
                license = { ...license, [e.target.name]: Number(e.target.value) }
            }
            else{
                license = { ...license, [e.target.name]: e.target.value }
            }
            setLicense(license)
        }
        catch (error: any) {

        }
    }

    const licenseValidation = (e: any) => {
        try {
            e.target.value = e.target.value != "" ? e.target.value : 0
            OnChangeLicense(e)

            let parallelInstance = document.getElementById('invalidparallelInstance');
            let validTill = document.getElementById('invalidExpirationDate');
            if (e.target.id == "parallelCount") {
                if (e.target.value == "Select Parallel Instance") {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    parallelInstance?.removeAttribute('hidden')
                    setLicensePass(false)
                    totalCost()
                }
                else {
                    parallelInstance?.setAttribute('hidden', 'hidden')
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: false }
                    totalCost();
                }
            }
            else if (e.target.id == "validTill") {
                if (e.target.value == "Select Valid Till") {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    validTill?.removeAttribute('hidden')
                    setLicensePass(false)
                    totalCost()
                }
                else {
                    validTill?.setAttribute('hidden', 'hidden')
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: false }
                    totalCost();
                }
            }
            else {
                let result = /^[0-9]{1,8}$/.test(e.target.value)
                let validation = document.getElementById(e.target.name)
                if (result == false) {
                    licenseRegexObj = { ...licenseRegexObj, [e.target.name]: true }
                    setLicensseRegex(licenseRegexObj)
                    validation?.removeAttribute('hidden')
                    license = { ...license, TotalCost: 0 }
                    setLicense(license)
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.setAttribute('disabled', 'disabled');
                    setLicensePass(false)
                }
                else {
                    validation?.setAttribute('hidden', 'hidden')
                    totalCost()
                }
            }
        }
        catch (error: any) {

        }
    }

    const removeState = (e: any) => {
        license = { ...license, [e.target.name]: "" }
        setLicense(license)
    }

    const totalCost = () => {
        try {

            let licenseCost = isUserBased 
            ? (license.UserCost * license.UserCount) 
            : (license.ChannelCount * license.ChannelCost) + (license.ChatCount * license.ChatCost) + (Number(chatHtmlCount) * Number(chatHtmlCost));

            let resourceValidation = (license.resCost != 0 && licenseRegexObj.resCost == false && ((license.parallelCount!= 0 &&  !isNaN(license.parallelCount) )  || (license.validTill != 0 && !isNaN(license.validTill)))) ? true : false;
            licenseCost = (isNaN(licenseCost) == true || licenseRegexObj.ChannelCost == true || licenseRegexObj.ChannelCount == true || licenseRegexObj.ChatCost == true || licenseRegexObj.ChatCount == true || licenseRegexObj.UserCost == true || licenseRegexObj.UserCount == true)
                ? 0 : licenseCost;
            let totalcost = licenseCost != 0 ? licenseCost : resourceValidation == true ? license.resCost : 0;
            license = { ...license, TotalCost: totalcost }
            setLicense(license)
            let costTotal = (license.ChannelCost != 0 && license.ChannelCount == 0) || (license.ChannelCost == 0 && license.ChannelCount != 0) || (license.ChatCost != 0 && license.ChatCount == 0) || (license.ChatCost == 0 && license.ChatCount != 0) || (license.UserCount != 0 && license.UserCost == 0) || (license.UserCount == 0 && license.UserCost != 0) ? false : true
            if (totalcost != 0 && costTotal != false) {
                setLicensePass(true)
                if(regexPass==true){
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.removeAttribute('disabled');
                    loaderText = "Please wait while we set up your Migration Environment..."
                    setLoaderText(loaderText)
                }
            }
            else {
                
                if(regexPass==true && license.ChannelCount == 0 && license.ChannelCost==0 && license.ChatCount==0 && license.ChatCost==0 && license.UserCount==0 && license.UserCost==0 && (license.parallelCount == 0 || isNaN(license.parallelCount)) && (license.validTill==0 || isNaN(license.validTill)) && license.TotalCost==0 && license.resCost==0){
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.removeAttribute('disabled');
                    setLicensePass(true)
                }
                else {
                    let submitButton = document.getElementById("submitButton");
                    submitButton?.setAttribute('disabled', 'disabled');
                    setLicensePass(false)
                }
            }
        }
        catch (error: any) {

        }
    }

    const regexValidation = (e: any) => {
        try {
            OnChangeName(e)
            // setState({ ...state, [e.target.name]: e.target.value })

            let submitButton = document.getElementById("submitButton");

            let userName = document.getElementById("userName")
            let companyName = document.getElementById("companyName")
            let email = document.getElementById("Email")
            let phone = document.getElementById("Phone")

            let emptyUserName = document.getElementById("emptyuserName");
            let emptyCompanyName = document.getElementById("emptycompanyName")
            let emptyEmail = document.getElementById("emptyEmail")
            let emptyPhone = document.getElementById("emptyPhone")

            if (e.target.id == "user-name") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    // let x = document.getElementById("emptyuserName")
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        // let x = document.getElementById("userName")
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        // let x = document.getElementById("userName")
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "companyname") {
                if (formState.CompanyName == "") {
                    // let x = document.getElementById("emptycompanyName")
                    emptyCompanyName?.removeAttribute('hidden')
                    companyName?.setAttribute('hidden', 'hidden')
                    // let submitButton = document.getElementById("signUp");
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    // let x = document.getElementById("emptycompanyName")
                    emptyCompanyName?.setAttribute('hidden', 'hidden')

                    let spaceRemoved = formState.CompanyName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[0-9a-zA-Z ]{1,80}$/.test(trimmed)//can include numbers and characters and max length is 80

                    if (result == false) {
                        // let x = document.getElementById("companyName")
                        companyName?.removeAttribute('hidden')
                    }
                    else {
                        // let x = document.getElementById("companyName")
                        companyName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "email") {
                if (formState.EmailAddress == "") {
                    // let x = document.getElementById("emptyEmail")
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    // let submitButton = document.getElementById("signUp");
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    // let x = document.getElementById("emptyEmail")
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)

                    if (result == false || formState.EmailAddress.length > 320) {
                        // let x = document.getElementById("Email")
                        email?.removeAttribute('hidden')
                    }
                    else {
                        // let x = document.getElementById("Email")
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "phone") {
                if (formState.PhoneNumber == "") {
                    // let x = document.getElementById("emptyPhone")
                    emptyPhone?.removeAttribute('hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    // let x = document.getElementById("emptyPhone")
                    emptyPhone?.setAttribute('hidden', 'hidden')
                    phone?.setAttribute('hidden', 'hidden')
                    let result = /^[0-9]{5,15}$/.test(formState.PhoneNumber)
                    if (result == false) {
                        // let x = document.getElementById("Phone")
                        phone?.removeAttribute('hidden')
                    }
                    else {
                        // let x = document.getElementById("Phone")
                        phone?.setAttribute('hidden', 'hidden')

                    }
                }
            }
            if (formState.UserName != "" && formState.CompanyName != "" && formState.EmailAddress != "" && formState.PhoneNumber != "") {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let removeSpace = formState.CompanyName.replace(/\s\s+/g, ' ')
                let trim = removeSpace.trim().replaceAll(" +", " ");
                let Check2 = /^[0-9a-zA-Z ]{1,80}$/.test(trim)
                let Check3 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)
                let Check4 = /^[0-9]{5,15}$/.test(formState.PhoneNumber);
                let check5 = isClientActive == isClientActive ? true : false;
                let check6 = initialSSOStatus == isSSOAccount ? true : false;

                if ((Check1 && Check2 && Check3 && formState.EmailAddress.length <= 320 && Check4 == true && (!check5 || !check6)) ||  (Check1 && Check2 && Check3 && formState.EmailAddress.length <= 320 && Check4 == true) ) {
                    // let submitButton = document.getElementById("signUp");
                    setRegexPass(true)
                    if(licensePass==true || (license.ChannelCount == 0 && license.ChannelCost && license.ChatCount==0 && license.ChatCost && license.UserCount==0 && license.UserCost && license.resCost==0 && (license.parallelCount == 0 || isNaN(license.parallelCount)) && (license.validTill == 0 || isNaN(license.validTill)) && license.TotalCost)){
                        submitButton?.removeAttribute('disabled');
                    }
                }
                else {
                    // let submitButton = document.getElementById("signUp");
                    submitButton?.setAttribute('disabled', 'disabled');
                    setRegexPass(false)
                }
            }
            else {
                // let submitButton = document.getElementById("signUp");
                submitButton?.setAttribute('disabled', 'disabled');
                setRegexPass(false)
            }
        }
        catch (error: any) {

        }
    }
    const revokeRegexValidation = (e : any) => {
        try {
                // Reset error messages
                let revokeButton = document.getElementById("formRevokeButton");


                if(e.target.id == "channelCount" && !state.IsUserBasedLicense){
                     channelCount = e.target.value
                     setChannelCount(channelCount)
                }
                else if (e.target.id == "channelCount" && state.IsUserBasedLicense){
                    userCount = e.target.value
                    setUserCount(userCount)
                }
                else if (e.target.id == "chatCount"){
                     chatCount = e.target.value
                     setChatCount(chatCount)
                }
                else if (e.target.id=="reason"){
                     reason = e.target.value
                     setReason(reason)
                }
            
                // Channel Count and User Count validation
                if(e.target.id == "channelCount"){
                     if(!state.IsUserBasedLicense){
                        if (!channelCount.trim()) {
                            channelCountError = 'Please enter channel count';
                            setChannelCountError(channelCountError);
                          }
                           else if (!/^\d{1,8}$/.test(channelCount) || state.AvailableChannelLicense<channelCount || (parseInt(chatCount)==0 && parseInt(channelCount) == 0)) {
                            channelCountError = "Enter the valid channel count"
                            setChannelCountError(channelCountError);
                          }
                          else if(state.PurchasedChannelMigrationLicense==0 && channelCount!="0"){
                            channelCountError = "Please provide the channel count as zero"
                            setChannelCountError(channelCountError);
                          }
                          else{
                            channelCountError = '';
                            setChannelCountError(channelCountError);
                            if(parseInt(chatCount)==0){
                                chatCountError = '';
                                setChatCountError(chatCountError);
                            }
                          }
                     }
                     else {
                        if (!userCount.trim()) {
                            channelCountError = 'Please enter User count';
                            setChannelCountError(channelCountError);
                          } else if (!/^\d{1,8}$/.test(userCount) || state.AvailableUserLicense<userCount || parseInt(userCount)==0) {
                            channelCountError = 'Enter valid user count';
                            setChannelCountError(channelCountError);
                          }
                          else {
                            channelCountError = '';
                            setChannelCountError(channelCountError);
                          }
                     }     
                }
               
                // Chat Count validation
                else if (e.target.id == "chatCount"){
                    if (!chatCount.trim() && !state.IsUserBasedLicense) {
                        chatCountError = "Please enter the chat count";
                        setChatCountError(chatCountError);
                      } else if (!/^\d{1,8}$/.test(chatCount) || state.AvaliableGroupChatLicense<chatCount || (parseInt(channelCount)==0 && parseInt(chatCount) == 0)) {
                        chatCountError = "Enter the valid chat count"
                        setChatCountError(chatCountError);
                      }
                      else if(state.PurchasedGroupChatMigrationLicense==0 && chatCount!="0"){
                        chatCountError = "Please provide the chat count as zero"
                        setChannelCountError(chatCountError);
                      }
                      else{
                        chatCountError = ""
                        setChatCountError(chatCountError);
                        if(parseInt(channelCount)==0){
                            channelCountError = "";
                            setChannelCountError(channelCountError);
                        }
                      }
                }
                // Reason validation
                else if (e.target.id == "reason"){
                    if (!reason.trim()) {
                        reasonError = "Please enter the reason"   
                        setReasonError(reasonError);
                      } else if (!/^[a-zA-Z\s]{1,250}$/.test(reason)) {
                        reasonError = "Enter the valid reason" ;
                        setReasonError(reasonError);
                      }
                      else{
                        reasonError = "" ;
                        setReasonError(reasonError);
                      }
                }
                
                

              if((channelCountError=='' && chatCountError=='' && reasonError=='') && ((userCount!="" && reason!="") || (channelCount!="" && chatCount!="" && reason!="") )){
                 //enable revoke button
                 revokeButton?.removeAttribute('disabled');
              }    
              else{
                  //disable revoke button
                  revokeButton?.setAttribute('disabled', 'disabled');
              }
        } catch (error) {
            
        }
    }

    const handleRadioBtn = (e: any) => {
        try {
            if (e.target.name == "planType") {
                isUserBased = (e.target.id == "userBased") ? true : false;
                setIsUserBased(isUserBased)
                license = { ChannelCount: 0, ChannelCost: 0, ChatCount: 0, ChatCost: 0, UserCount: 0, UserCost: 0, resCost: 0, TotalCost: 0, parallelCount: 0, validTill: 0 }
                setLicense(license)
                let parallelInstance: any = document.getElementById('parallelCount');
                parallelInstance.value = "Select Parallel Instance";
                let validDate: any = document.getElementById('validTill');
                validDate.value = "Select Valid Till";
            }
            else if (e.target.name == "status") {
                isClientActive = (e.target.id == "active") ? true : false;
                setIsClientActive(isClientActive);

            }
            else if (e.target.name == "regType") {
                isSSOAccount = (e.target.id == "sso") ? true : false;
                setIsSSOAccount(isSSOAccount);
            }
            regexValidation(e);

        }
        catch (error: any) {

        }
    }

    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            if (type != "alert") {
                history.push({
                    pathname: "/Admin/ManageClients/EditClient",
                    state: updatedLicense
                });
            }
            // window.location.pathname = "/Admin/ManageClients/EditClient";

            //#endregion
        } catch (error) {
        }
    }

    const handleCountryPadding = () => {
        try {
            let storedCountry = formState.CountryCode.split('~');
            let countrycode = storedCountry[0]
            let paddingclass = (countrycode.length == 2) ? "countryCodePadding1" : (countrycode.length == 3) ? "countryCodePadding2" : "countryCodePadding3"
            let addClass: any = document.getElementById("phone")
            addClass?.classList.remove(paddingclass);
        }
        catch (error: any) {


        }
    }

    const formReset = () => {
     try {
         //#region Form Reset
         userCount = "";
         setUserCount(userCount);
         channelCount = "";
         setChannelCount(channelCount);
         chatCount = "";
         setChatCount(chatCount);
         reason = "";
         setReason(reason);
         channelCountError = "";
         setChannelCountError(channelCountError);
         chatCountError = "";
         setChatCountError(chatCountError);
         reasonError = "";
         setReasonError(reasonError);
         const channelCountField = document.getElementById("channelCount") as HTMLInputElement | null;
         const chatCountField = document.getElementById("chatCount") as HTMLInputElement | null;
         const reasonField = document.getElementById("reason") as HTMLInputElement | null;

         if(channelCountField && chatCountField && reasonField){
             channelCountField.value = "";
             chatCountField.value = "";
             reasonField.value = "";
         }
        
      
          //#endregion
     } catch (error: any) {
        
     }
    }

    const handleRevoke = (type: any) => {
        try {
            let popup = document.getElementById("revokePopup");
            let revokeBtn = document.getElementById("formRevokeButton");
            revokeBtn?.setAttribute('disabled', 'disabled');
            
        
            if (type == "enable") {
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                formReset();
                //#endregion
            }
            else if (type == "close") {  //close with form reset for revoke
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                formReset();
            }
            else{     //close without form reset
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            
        }
        catch (error) {
        
        }
    }
   const revokeSubmit  = async() =>{
    try {
        if (clientID != undefined && clientToken != undefined) {
            let submitButton = document.getElementById("submitButton")
            submitButton?.setAttribute("disabled", "disabled")
            handleRevoke("ClosewithoutFormReset");
            countrycode();
            let config = ({
                method: "POST",
                token: clientToken,
                url: `${baseURL}/admin/updateClientDetails`,
                data: {
                    "clientID": parseInt(clientID),
                    "name": `${formState.UserName}`,
                    "mailID": `${formState.EmailAddress}`,
                    "phone": `${formState.PhoneNumber}`,
                    "countryCode": `${formState.CountryCode}`,
                    "companyName": `${formState.CompanyName}`,
                    "channelCount": Number(channelCount),
                    "channelCost": Number(license.ChannelCost),
                    "chatCount": Number(chatCount),
                    "chatCost": Number(license.ChatCost),
                    "userCount": Number(userCount),
                    "userCost": Number(license.UserCost),
                    "parallelCount": Number(license.parallelCount),
                    "validTill": Number(license.validTill),
                    "resCost": Number(license.resCost),
                    "isUserBased": state.IsUserBasedLicense,
                    "isSSOAccount": initialSSOStatus,
                    "isActive": isClientActive,
                    "isRevokeLicense": true,
                    "reason" : reason
                },
            }
            )
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let data: any = await gridLoad(config);
            formReset();
            if (data.data.statusCode != 200 && data.data.data=='Mail ID already present' ) {
                setAlertMessage("Mail ID already present")
            }
            else if(data.data.statusCode != 200){
                setAlertMessage("Failed To Update")
            }
            else {
                accountInfo(data.data.data[0])
                updatedLicense = { AvailableChannelLicense: data.data.data[0].AvailableChannelLicense, AvaliableGroupChatLicense: data.data.data[0].AvaliableGroupChatLicense, PurchasedChannelMigrationLicense: data.data.data[0].PurchasedChannelMigrationLicense, PurchasedGroupChatMigrationLicense: data.data.data[0].PurchasedGroupChatMigrationLicense, PurchasedUserLicenseCount: data.data.data[0].PurchasedUserLicenseCount, AvailableUserLicense: data.data.data[0].AvailableUserLicense, ID: state.ID, CompanyName: data.data.data[0].CompanyName, IsUserBasedLicense: data.data.data[0].IsUserBasedLicense, ValidTillDate: data.data.data[0].ValidTillDate }
                setUpdatedLicense(updatedLicense)
            }
            loader?.setAttribute('hidden', 'hidden');
            setLoaderText("")
            let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
        }
        else {
            localStorage.clear();
        }

    }
    catch (error: any) {
        setLoaderText("")
        let loader = document.getElementById("loader")
        loader?.setAttribute('hidden', 'hidden');
        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
        let alertPopUp = document.getElementById("alertPopUp");
        alertPopUp?.removeAttribute("aria-hidden");
        alertPopUp?.setAttribute("aria-modal", "true");
        alertPopUp?.setAttribute("role", "dialog");
        alertPopUp?.setAttribute("class", "modal fade show");
        alertPopUp?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

    }
   }

    return (
        <div id="account-information" className="tab-pane active  pt-3 pb-5">
            {/* <!-- Registration Starts --> */}
            <div className="col-md-12 mb-4">
                <h5 className="font-20 font-bold mb-4">General Information</h5>
                <div className="row">
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label" htmlFor="username">User Name<span className="required">*</span></label>
                        <input type="text" className="form-control custom-form" id="username" placeholder="Enter User Name" name="UserName" value={formState.UserName} disabled = {isServiceAdmin?true:false} onChange={(e) => regexValidation(e)} />
                        <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                    </div>
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label" htmlFor="companyname">Company Name<span className="required">*</span></label>
                        <input type="text" className="form-control custom-form" id="companyname" placeholder="Enter Company Name" name="CompanyName" value={formState.CompanyName} disabled = {isServiceAdmin?true:false} onChange={(e) => regexValidation(e)} />
                        <label id="companyName" className="required" hidden={true} >Please enter Valid Company Name</label>
                        <label id="emptycompanyName" className="required" hidden={true} >Company Name cannot be empty</label>
                    </div>
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label" htmlFor="email">Email Address<span className="required">*</span></label>
                        <input type="email" className="form-control custom-form" id="email" placeholder="Enter Email Address" name="EmailAddress" disabled = {isServiceAdmin?true:false} value={formState.EmailAddress} onChange={(e) => regexValidation(e)} />
                        <label id="Email" className="required" hidden={true}>Please enter Valid Mail Address</label>
                        <label id="emptyEmail" className="required" hidden={true}>Mail Address cannot be empty</label>
                    </div>
                </div>
            </div>
            <div className="col-md-12 mb-5">
                <div className="row">
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label" htmlFor="phone">Phone Number<span className="required">*</span></label>
                        <div id='countryFlag' onClick={(e) => handleCountryPadding()}>
                            <input type="text" className="form-control custom-form" id="phone" placeholder="Enter Phone Number" disabled = {isServiceAdmin?true:false} name="PhoneNumber" value={formState.PhoneNumber} onChange={(e) => regexValidation(e)} />
                        </div>
                        <label id="Phone" className="required" hidden={true}> Please enter Valid Phone number </label>
                        <label id="emptyPhone" className="required" hidden={true}>Phone number cannot be empty </label>
                    </div>
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label mb-3">Status<span className="required">*</span></label>
                        <div className="d-flex mb-4">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="status" id="active" checked={isClientActive} disabled = {isServiceAdmin?true:false} onClick={(e) => handleRadioBtn(e)} />
                                <label className="form-check-label font-14 font-regular ms-1" htmlFor="active">
                                    Active
                                </label>
                            </div>
                            <div className="form-check me-5">
                                <input className="form-check-input ms-1 me-1" type="radio" name="status" id="inactive" checked={!(isClientActive)} disabled = {isServiceAdmin?true:false} onClick={(e) => handleRadioBtn(e)} />
                                <label className="form-check-label font-14 font-regular ms-1" htmlFor="inactive">
                                    Inactive
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <label className="font-14 font-semibold  form-label mb-3 ">Registration Type<span className="required">*</span></label>
                        <div className="d-flex mb-4">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="regType" id="sso" checked={isSSOAccount} onClick={(e) => handleRadioBtn(e)} disabled = {isServiceAdmin?true:false}/>
                                <label className="form-check-label font-14 font-regular ms-1" htmlFor="sso">
                                    SSO
                                </label>
                            </div>
                            <div className="form-check me-5">
                                <input className="form-check-input ms-1 me-1" type="radio" name="regType" id="regEmail" checked={!(isSSOAccount)} onClick={(e) => handleRadioBtn(e)} disabled = {isServiceAdmin?true:false} />
                                <label className="form-check-label font-14 font-regular ms-1" htmlFor="regEmail">
                                    Email
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Chat Html Migration Toggle */}
                <div className="form-check form-switch float-start mt-2 me-5">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        id="chatHtmlMigration"
                        checked={isChatHtmlMigration}
                        onChange={(e) => setIsChatHtmlMigration(e.target.checked)}
                    />
                    <label className="form-check-label font-semibold font-15" htmlFor="chatHtmlMigration">
                        Chat HTML Migration
                    </label>
                </div>
            </div>
            
            <div className="col-md-12">
                <h5 className="font-20 font-bold mb-4">Plan Information</h5>
                <div className="row">
                    <label className="font-16 font-semibold mb-3 ">Plan Type</label>
                    <div className="d-flex mb-4">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="planType" checked={!(isUserBased)} id="chatChannel" disabled = {isServiceAdmin?true:false} onClick={(e) => handleRadioBtn(e)} />
                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="chatChannel">
                                Chat & Channel
                            </label>
                        </div>
                        <div className="form-check me-5">
                            <input className="form-check-input ms-1 me-1" type="radio" name="planType" checked={isUserBased} id="userBased" disabled = {isServiceAdmin?true:false} onClick={(e) => handleRadioBtn(e)} />
                            <label className="form-check-label font-14 font-regular ms-1" htmlFor="userBased">
                                User-based
                            </label>
                        </div>

                    </div>
                </div>
                <div className="row">
                    {isUserBased ?
                        <div className="col-md-3">
                            <label className="font-16 font-semibold mb-3 ">User License</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="usercount">License Count</label>
                                    <input type="text" className="form-control custom-form" id="usercount" name="UserCount" value={license.UserCount == 0 ? "" : license.UserCount} placeholder="License Count" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                    <label id="UserCount" className="required" hidden={true}>Enter Valid License Count</label>
                                </div>
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="usercost">License Cost</label>
                                    <div className="input-group custom-form mb-3">
                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                        <input type="text" className="form-control border-start-0 text-end" id="usercost" aria-label="Amount (to the nearest dollar)" name="UserCost" value={license.UserCost == 0 ? "" : license.UserCost} placeholder="Enter Cost" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                        <label id="UserCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {isUserBased == false ?
                        <div className="col-md-3">
                            <label className="font-16 font-semibold mb-3 ">Channel Migration</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="channelcount">Channel Count</label>
                                    <input type="text" className="form-control custom-form" id="channelcount" name="ChannelCount" value={license.ChannelCount == 0 ? "" : license.ChannelCount} placeholder="License Count" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                    <label id="ChannelCount" className="required" hidden={true}>Enter Valid License Count</label>
                                </div>
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="channelcost">Channel Cost</label>
                                    <div className="input-group custom-form mb-3">
                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                        <input type="text" className="form-control border-start-0 text-end" id="channelcost" aria-label="Amount (to the nearest dollar)" name="ChannelCost" value={license.ChannelCost == 0 ? "" : license.ChannelCost} placeholder="Enter Cost" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                        <label id="ChannelCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                    {isUserBased == false ?
                        <div className="col-md-3">
                            <label className="font-16 font-semibold mb-3 ">Conversation Migration</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="Conversationcount">Conversation Count</label>
                                    <input type="text" className="form-control custom-form" id="Conversationcount" name="ChatCount" value={license.ChatCount == 0 ? "" : license.ChatCount} placeholder="License Count" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                    <label id="ChatCount" className="required" hidden={true}>Enter Valid License Count</label>
                                </div>
                                <div className="col-md-6">
                                    <label className="font-14 font-regular   form-label" htmlFor="Conversationcost">Conversation Cost</label>
                                    <div className="input-group custom-form mb-3">
                                        <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                        <input type="text" className="form-control border-start-0 text-end" id="Conversationcost" aria-label="Amount (to the nearest dollar)" name="ChatCost" value={license.ChatCost == 0 ? "" : license.ChatCost} placeholder="Enter Cost" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                        <label id="ChatCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}
                        {isUserBased == false ?
                        <div className="col-md-3">
                        <label className="font-16 font-semibold mb-3 ">Chat HTML Migration</label>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="font-14 font-regular form-label" htmlFor="chatHtmlCount">Chat HTML Count</label>
                                <input 
                                    type="text" 
                                    className="form-control custom-form" 
                                    id="chatHtmlCount" 
                                    name="ChatHtmlCount" 
                                    value={chatHtmlCount} 
                                    placeholder="License Count" 
                                    disabled={isServiceAdmin} 
                                    onChange={(e) => {
                                        setChatHtmlCount(e.target.value);
                                        licenseValidation(e);
                                    }} 
                                />
                                <label id="ChatHtmlCount" className="required" hidden={true}>Enter Valid License Count</label>
                            </div>
                            <div className="col-md-6">
                                <label className="font-14 font-regular form-label" htmlFor="chatHtmlCost">Chat HTML Cost</label>
                                <div className="input-group custom-form mb-3">
                                    <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                    <input 
                                        type="text" 
                                        className="form-control border-start-0 text-end" 
                                        id="chatHtmlCost" 
                                        name="ChatHtmlCost" 
                                        value={chatHtmlCost} 
                                        placeholder="Enter Cost" 
                                        disabled={isServiceAdmin} 
                                        onChange={(e) => {
                                            setChatHtmlCost(e.target.value);
                                            licenseValidation(e);
                                        }} 
                                    />
                                    <label id="ChatHtmlCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                </div>
                            </div>
                        </div>
                    </div>
                        : null}
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="font-16 font-semibold mb-3 d-block">Parallel Migration</label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="font-14 font-regular form-label" htmlFor="months">Migration Count</label>
                                                <select className="form-select custom-form" id="parallelCount" name="parallelCount" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)}>
                                                    <option selected value="Select Parallel Instance">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                                <label id="invalidparallelInstance" className="required" hidden={true}>Select any option</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label className="font-16 font-semibold mb-3 d-block">Valid Till</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="font-14 font-regular   form-label" htmlFor="months"># of Months</label>
                                                <select className="form-select custom-form" id="validTill" name="validTill" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)}>
                                                    <option selected value="Select Valid Till">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                                <label id="invalidExpirationDate" className="required" hidden={true}>Select any option</label>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="font-14 font-regular   form-label">Res. Utilization Cost</label>
                                                <div className="input-group custom-form mb-3">
                                                    <span className="input-group-text border-end-0 bg-white font-bold font-18">$</span>
                                                    <input type="text" className="form-control border-start-0 text-end" id="Conversationcost" aria-label="Amount (to the nearest dollar)" name="resCost" value={license.resCost == 0 ? "" : license.resCost} placeholder="Enter Cost" disabled = {isServiceAdmin?true:false} onChange={(e) => licenseValidation(e)} />
                                                    <label id="ChatCost" className="required" hidden={true}>Enter Valid License Cost</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className="col-md-12 d-flex justify-content-end align-items-center mt-4">
                            <label className="font-14 font-regular  text-nowrap form-label" htmlFor="channelcount "><span className="">Total Cost</span>
                                <span className="font-24 font-bold pt-2 ms-2">
                                    <span className="font-20">$</span>{license.TotalCost.toLocaleString("en-US")}
                                </span>
                            </label>
                        </div>
                        {/* <div className="col-md-3">
                                <label className="font-14 font-regular " htmlFor="channelcount form-label">Total</label>
                                <label className="font-24 font-bold d-block pt-2"><span className="font-20">$</span>{license.TotalCost.toLocaleString("en-US")}</label>
                            </div> */}

                    </div>
                </div>
            </div >
            {/* <!-- Registration Ends --> */}
            {/* <!-- footer starts --> */}
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
  <div className="d-flex justify-content-between mx-3">
    <a href="/Admin/ManageClients" className="anchorAsPointer">
      <button type="button" className="btn-cancel-border common-btn-class btn-scondary-custom btn btn-admin-second font-16 font-regular me-2 btn-scondary-custom">
        Cancel
      </button>
    </a>

    <div>

    {isServiceAdmin?<button type="button" id = 'revokeButton' className="common-btn-class btn font-16 font-regular me-2 revoke-license">
        Revoke License
      </button>
      :
      <button type="button" id = 'revokeButton' className="common-btn-class btn font-16 font-regular me-2 revoke-license" onClick={(e) => handleRevoke("enable")}>
        Revoke License
      </button>}

      {isServiceAdmin?<button type="button" id="submitButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2">
        Submit
      </button>
      :
      <button type="button" id="submitButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={(e) => handleSubmitClick()}>
        Submit
      </button>}

    </div>
  </div>
</footer>
            {/* <!-- footer ends -->  */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4"> Account Information Updated Successfully!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* Revoke License Popup start */}
            <div className="modal fade" id="revokePopup" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0 px-4">
                            <h5 className="modal-title font-20 font-bold">Revoke License</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => handleRevoke("close")}></button>
                        </div>
                        <div className="modal-body mt-0 px-4">
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label htmlFor="channelCount" className="form-label font-semibold font-16">
                                      {!state.IsUserBasedLicense ? "Channel Count" : "User Count"} <span className="required">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="channelCount" onChange={(e) => revokeRegexValidation(e)}/>
                                    <label className="required font-12 font-regular">{channelCountError}</label>
                                </div>
                                <div className={`mb-3 col-md-6 ${state.IsUserBasedLicense ? 'd-none' : ''}`}>
                                    <label htmlFor="chatCount" className="form-label font-semibold font-16">
                                        Chat Count <span className="required">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="chatCount" onChange={(e) => revokeRegexValidation(e)} />
                                    <label className="required font-12 font-regular">{chatCountError}</label>
                                </div>
                                <div className="mb-3 col-md-12 mb-4">
                                    <label htmlFor="reason" className="form-label font-semibold font-16">
                                        Reason <span className="required">*</span>
                                    </label>
                                    <textarea rows={4} className="form-control" id = "reason" onChange={(e) => revokeRegexValidation(e)}></textarea>
                                    <label className="required font-12 font-regular">{reasonError}</label>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end">
                                <button type="button" className="btn-cancel-border common-btn-class btn-scondary-custom  btn btn-admin-second font-16 font-regular me-2 btn-scondary-custom" onClick={(e) => handleRevoke("close")}>
                                    Cancel
                                </button>
                                <button type="button" id='formRevokeButton' className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={(e) => revokeSubmit()}>
                                    Revoke
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Revoke license popup ends */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Admin-Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="aligncenter">{loaderText}</p>
                </div>
            </div>
            {/* <!--loader section--> */}

        </div >
    )
}