import { useState, useEffect } from "react";
import { callBackEnd } from "../../Services/GroupChatMigrationServices";
import constants from '../../Common/Constants';
import $ from 'jquery';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode';
import ReactTooltip from "react-tooltip";
import { debug } from "console";

export default function ChatHtmlManageMigration() {

  //#region Customization Migration Dates 
  let [migrationFromDate, setMigrationFromDate] = useState("");
  let [migrationToDate, setMigrationToDate] = useState("");
  let [minMigrationFromDate, setMinMigrationFromDate] = useState("2013-01-01");
  let [minMigrationToDate, setMinMigrationToDate] = useState("2013-01-01");
  let [maxMigrationFromDate, setMaxMigrationFromDate] = useState(new Date().toISOString().split('T')[0]);
  let [schedulemaxDate, setSchedulemaxDate] = useState(new Date().toISOString().split('T')[0]);
  let [maxMigrationToDate, setMaxMigrationToDate] = useState(new Date().toISOString().split('T')[0]);
  //#endregion

  $(function () {
    $('#migrationFromDate').attr('min', minMigrationFromDate);
    $('#migrationToDate').attr('min', minMigrationToDate);
    $('#migrationFromDate').attr('max', maxMigrationFromDate);
    $('#migrationToDate').attr('max', maxMigrationToDate);
  });

  //#region [Inventory]
  const [inventorySearchText, setInventorySearchText] = useState("");
  const [inventoryGridData, setInventoryGridData] = useState([]);
  let [inventorySummaryID, setInventorySummaryID] = useState(0);
  let [inventoryName, setInventoryName] = useState("");
  //#endregion 

  //#region [Migration Summary]
  const [migrationSearchText, setMigrationSearchText] = useState("");
  let [migrationGridData, setMigrationGridData] = useState<any>([]);
  const [migrationStatus, setMigrationStatus] = useState("");
  let [pageIndex, setpageIndex] = useState(1);
  const [sourceType, setSourceType] = useState("All");
  let [sortColumn, setsortColumn] = useState("SourceChatName");
  let [sortType, setsortType] = useState("asc");
  //#endregion

  //#region [saveSchedule]
  let [scheduledDate, setScheduledDate] = useState("");
  let [scheduledTime, setScheduledTime] = useState("");
  const [arrSelectedValues, setArrSelectedValues] = useState<any>([]);
  //#endregion

  let selectedGroupChatValues: any[] = [];
  let [clientID, setClientID] = useState(0);
  let [clientToken, setClientToken] = useState("");
  let [adminToken, setadminToken] = useState("");
  let [nonGifLoaderContent, setNonGifLoaderContent] = useState("");

  // Select All Count
  const [selectAllCount, setSelectAllCount] = useState(0);
  const [isFullSelect, setIsFullSelect] = useState(false);
  const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
  const [operation, setOperation ] = useState("")
  const [ validTotalCount, setValidTotalCount] = useState(0);
  const [ disabledCount, setDisabledCount] = useState(0);

  //update scheduled date and time
  const [arrTime, setArrTime] = useState([{ UI: "00:00", value: 0 }, { UI: "01:00", value: 1 }, { UI: "02:00", value: 2 }, { UI: "03:00", value: 3 }, { UI: "04:00", value: 4 }, { UI: "05:00", value: 5 }, { UI: "06:00", value: 6 }, { UI: "07:00", value: 7 }, { UI: "08:00", value: 8 }, { UI: "09:00", value: 9 }, { UI: "10:00", value: 10 },
  { UI: "11:00", value: 11 }, { UI: "12:00", value: 12 }, { UI: "13:00", value: 13 }, { UI: "14:00", value: 14 }, { UI: "15:00", value: 15 }, { UI: "16:00", value: 16 }, { UI: "17:00", value: 17 }, { UI: "18:00", value: 18 }, { UI: "19:00", value: 19 }, { UI: "20:00", value: 20 },
  { UI: "21:00", value: 21 }, { UI: "22:00", value: 22 }, { UI: "23:00", value: 23 }]);

  const [sorthide, setsorthide] = useState("");
  let [countPerPage, setCoutPerPage] = useState(10);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  let [statusText, setStatusText] = useState("");
  let [planType, setPlanType] = useState('Free');
  let [parallelInstance, setparallelInstance] = useState(1);

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  let local: any = localStorage.getItem("cid_t");
  let adminLocal: any = localStorage.getItem("aid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  let adminObjClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;




  useEffect(() => {
    resetSessionTimer();
    let client_Token: any;
    if (local != undefined) {
      let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
      decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
      objClient = jwt(decryptedValue);
      SessionEpiredDateTime = objClient.CreatedAt;
      clientID = Math.random();
      setClientID(clientID);
      clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
      setClientToken(clientToken);
      window.addEventListener('keydown', resetSessionTimer);
      window.addEventListener('click', resetSessionTimer);

      if(adminLocal != undefined){
        let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
        let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
        adminObjClient = jwt(adminDecryptedValue);
        adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
        setadminToken(adminToken);
      }
      //#endregion

    }


    let removeHeight: any = document.getElementById("MainContainerMFE");
    removeHeight.classList.remove("container-bg-withFooter");
    removeHeight.classList.remove("container-bg-withoutFooter-chat");
    removeHeight.classList.remove("container-bg-withFooter-chat");
    removeHeight.classList.add("container-bg-withFooter-chat");

    let btnStartMigrationElement = document.getElementById("btnStartMigration")
    btnStartMigrationElement?.setAttribute('disabled', 'disabled');

    let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
    btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');

    document.getElementById('saveBtn')?.setAttribute("disabled", "true");
    //document.getElementById("ApplyFilter")?.setAttribute("disabled", "true");

    var now = new Date();

    var today = new Date();
    let date = (today.getDate().toString()).length < 2 ? "0" + (today.getDate().toString()) : (today.getDate().toString());
    let month = (today.getMonth() + 1).toString().length < 2 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString();
    let newdate = today.getFullYear() + '-' + month + '-' + date;
    setMaxMigrationFromDate(newdate);
    setMaxMigrationToDate(newdate);
    loadGridPage();
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [])


  useEffect(() => {
    // To Handle the Select All through Individual Checkboxes
    let selectAllelement:any = document.getElementById("selectAllCheckBox")
    selectAllelement.disabled = false
    let allGridDataDisabled = false

      if (migrationGridData.length != 0 && migrationGridData != "N" && migrationGridData[0] != "No Records Found") {
        // To get the gridData Count without the Disbled Checkbox
          let tempArr: any[] =[]
          let disabledTempCount = 0
          migrationGridData.forEach((obj:any)=>{
              let element:any = document.getElementById(obj.ID)
              if(element != "" && element != null && element != undefined  && element.name == "disableCheckbox" ){
                  tempArr.push(obj);
              }
          })
          disabledTempCount = migrationGridData.length - tempArr.length
          setDisabledCount(disabledTempCount)

      // Check Whether all the CheckBoxes in the Grid are Disabled
        const allObjectsPresent = migrationGridData.every((obj1: { ID: any; }) =>
            tempArr.some(obj2 => obj1.ID === obj2.ID)
        )
        if(allObjectsPresent){
            allGridDataDisabled = true
        }
        else{
            allGridDataDisabled = false
        }

        if (selectAllCount == 0) {
           // Possible Conditions when the SelectAll is UnChecked 
          if (arrSelectedValues.length == disabledTempCount || arrSelectedValues.length != disabledTempCount) {
            let isAllSelected = false
                let tempCheckArr:any = []
               
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.ID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }  
             }
             if(validTotalCount !=0 && arrSelectedValues.length == validTotalCount){
              setSelectAllCount(2);
              setIsFullSelect(true)
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
        }
        if (selectAllCount == 1) {
          // Possible Conditions when the SelectAll is Partial Select
          let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.ID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                  if(isFullSelect != true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                  }
                  else{
                    setSelectAllCount(2);
                    setIsFullSelect(true)
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                  }
                }
                else{
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
        }
        else{
          if (selectAllCount == 1 && arrSelectedValues.length != validTotalCount) {
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
          }
          if (selectAllCount == 1 && arrSelectedValues.length == validTotalCount) {
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
        }

        if (selectAllCount == 2) {
          // Possible Conditions when the SelectAll is Full Select
          if(operation != "search" && operation != "statusFilter" && operation != "sourceFilter"){
            setSelectAllCount(2)
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
        }
        else{
            if (arrSelectedValues.length != validTotalCount) {
                setSelectAllCount(0);
                setIsFullSelect(false)
                selectAllelement.checked = ""
            }
          }
        }
         // to handle the pageload of grid for Full Select
         if (validTotalCount == disabledTempCount && migrationGridData.length !=0 && arrSelectedValues.length == disabledTempCount) {
          setIsFullSelect(true);
          setSelectAllCount(2)
          selectAllelement.checked ="true";
          selectAllelement.className = "form-check-input me-2 check-alg-2"
      }

        handleCheckedItems()
      }
      if(migrationGridData == "N" || migrationGridData[0] == "No Records Found" || allGridDataDisabled == true){
        setSelectAllCount(0);
        setIsFullSelect(false)
        selectAllelement.checked = ""
        selectAllelement.disabled = true
      }

  }, [migrationGridData]);

  useEffect(() => {

     // To Handle the Select All through Individual Checkboxes
        let selectAllelement:any = document.getElementById("selectAllCheckBox")
        let allDataBinded = false
         // Condition to Check whether all the Data From the Backend is Binded in the Grid
        if(migrationGridData.length == totalItemsCount){
          allDataBinded = true
        }
        if (migrationGridData.length != 0) {
          if (selectAllCount == 0) {
             // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
            if (arrSelectedValues.length == disabledCount && isFullSelect == false && allDataBinded == false) {
              let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.ID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                if(isAllSelected){
                  setSelectAllCount(1);
                  selectAllelement.checked ="true";
                  selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
            }
            if(arrSelectedValues.length == disabledCount && isFullSelect == true || arrSelectedValues.length == disabledCount && allDataBinded == true){
              setSelectAllCount(2);
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
            if(arrSelectedValues.length != disabledCount && isFullSelect == true){
              let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.ID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                  if(isAllSelected == true){
                      setSelectAllCount(2);
                      setIsFullSelect(true)
                      selectAllelement.checked ="true";
                      selectAllelement.className = "form-check-input me-2 check-alg-2"
                  }
            }
            if(arrSelectedValues.length != disabledCount && isFullSelect == false){
              let isAllSelected = false
              let tempCheckArr:any = []
              
              migrationGridData.forEach((obj:any)=>{
                  if(arrSelectedValues.some((obj1:any)=>{
                      if(JSON.stringify(obj1) == obj.ID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }

              })
              if(tempCheckArr.length == disabledCount){
                  isAllSelected = true
              }
              if(isAllSelected == true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
              }
            }
          }
          if (selectAllCount == 1) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select
            if (arrSelectedValues.length != disabledCount) {
              let isAllSelected = false
              let tempCheckArr:any = []
              
              migrationGridData.forEach((obj:any)=>{
                  if(arrSelectedValues.some((obj1:any)=>{
                      if(JSON.stringify(obj1) == obj.ID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }

              })
              if(tempCheckArr.length == disabledCount){
                  isAllSelected = true
              }
                  if(isAllSelected == true){
                      setSelectAllCount(1);
                      selectAllelement.checked ="true";
                      selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                  }
                  else{
                      setSelectAllCount(0);
                       setIsFullSelect(false)
                      selectAllelement.checked = ""
                  }
              
              }
          }
          if (selectAllCount == 2) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
            if (arrSelectedValues.length != validTotalCount) {
              setSelectAllCount(0);
               setIsFullSelect(false)
              selectAllelement.checked = ""
            }
          }
        }

    handleCheckedItems();
}, [arrSelectedValues]);

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      statusText = "Your session has expired. Please log in again to continue using the application."
      setStatusText(statusText)

      //#region Show PopUp
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion


  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await callBackEnd(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText);

      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText);
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion





  const popUpOkClick = () => {
    try {
      if (isSessionEpired === false) {
        const element: any = document.getElementById("alertPopUp");
        document.getElementById("alertPopUp")?.setAttribute("class", "modal fade");
        document.getElementById("alertPopUp")?.setAttribute("style", "display:none");
        document.getElementById("alertPopUp")?.setAttribute("aria-hidden", "true");
        document.getElementById("alertPopUp")?.removeAttribute("aria-modal");
        document.getElementById("alertPopUp")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        $('input[name="disableCheckbox"]').prop('checked', false);
        $('input[name="enableCheckbox"]').prop('checked', false);
        deleteChild()
      }
      else {
        window.location.pathname = "/Login";
      }
    } catch (error) {
      ////console.log("Issue Faced");
    }
  }

  const enableAlertPopup = () => {
    try {
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    } catch (error) {
      throw (error)
    }
  }

  const loadGridPage = async () => {
    let loader;
    try {

      if (clientID != undefined && clientToken != undefined) {
        //document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
        let loadInventoryGroupChatData = (
          {
            method: "POST",
            url: constants.URL.getGroupChatInventoryDataURL,
            data:
            {
              "clientID": clientID,
              "searchText": inventorySearchText,
              "type": "chatHTML"
            },
            token: clientToken
          }
        );

        loader = document.getElementById("loaderWithoutContent");
        loader?.removeAttribute('hidden');

        let data: any = await callBackEnd(loadInventoryGroupChatData);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data[1] != undefined) {
          setInventorySummaryId(parseInt(data.data.data[1][0][0].ID));
          setInventoryname(data.data.data[1][0][0].InventoryName);
        }

        //updated after dev pre verification 2
        if (data.data.datarecords == "No Records Found") {
          setInventoryGridData(data.data.datarecords);
          setPlanType(data.data.data[0][1][0].LicenseType);
          setparallelInstance(data.data.data[0][0][0].ParallelMigrationCount);
          setTotalItemsCount(0);
          setMigrationGridData([]);
          inventoryName = "NA"
          setInventoryName(inventoryName);
        }
        else if (typeof (data.data.data) != 'string') {

          setInventoryGridData(data.data.data[1][0])//data.data.data[0]);
          setTotalItemsCount(data.data.data[1][2][0].GroupChatInventoryCount);

          // To Set the ValidTotalCount for the Select All Excluding the Disabled CheckBoxes
          setValidTotalCount(data.data.data[1][3][0].SelectedTotalValue)

          setMigrationGridData(data.data.data[1][1]);
          setPlanType(data.data.data[0][1][0].LicenseType);
          setparallelInstance(data.data.data[0][0][0].ParallelMigrationCount);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.statusCode != 200) {
          setStatusText(data.data.data);
          inventoryName = "NA"
          setInventoryName(inventoryName);
          enableAlertPopup();
        }
      }
      else {
        localStorage.clear();
      }
    }

    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden');
      setStatusText("Something went wrong! Please reach AVAMIGRATRON");
      enableAlertPopup();
    }
  }

  const sortClicked = (e: any) => {
    try {
      if (totalItemsCount != 0 && inventorySummaryID != null && inventorySummaryID != undefined) {
        let sortID = e.target.id;
        let sortval = sortID.split('@');
        sortColumn = sortval[0];
        sortType = sortval[1];

        setsortColumn(sortColumn);
        setsortType(sortType);
        setsorthide(sortID);
        loadGroupChatData(operation);
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const deleteQueueStatus = async (obj: any) => {
    let loader;
    try {
      if (clientID != undefined && clientToken != undefined) {
        let deleteQueuedGroupChatStatus = (
          {
            method: "POST",
            url: constants.URL.deleteQueuedGroupChatDataURL,
            data:
            {
              "clientID": clientID,
              "migrationSummaryID": parseInt(obj.ID),
              "inventorySummaryID": inventorySummaryID,
              "status":obj.MigrationStatus
            },
            token: clientToken
          }
        );
        loader = document.getElementById("loaderWithoutContent");
        loader?.removeAttribute('hidden');
        let data: any = await callBackEnd(deleteQueuedGroupChatStatus);
        loader?.setAttribute('hidden', 'hidden')
        if (data.data.statusCode == 200) {
          loadGroupChatData(operation);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          setStatusText(data.data.data);
          enableAlertPopup();
        }

      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      loader?.setAttribute('hidden', 'hidden')
      setStatusText("Something went wrong! Please reach AVAMIGRATRON");
      enableAlertPopup();
    }
  }

  const setInventorySummaryId = async (invSummaryId: any) => {
    try {
      setInventorySummaryID(invSummaryId);
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const setInventoryname = async (invName: any) => {
    try {
      setInventoryName(invName);
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const handleInventorySearch = async (e: any) => {
    try {
      setInventorySearchText(e.target.value);
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const handleMigrationSearch = async (e: any) => {
    try {
      setMigrationSearchText(e.target.value);
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const handleSourceType = async (e: any) => {
    try {
      setSourceType(e.target.value);
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const handleMigrationStatus = async (e: any) => {
    try {
      setMigrationStatus(e.target.value);
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const enableReschedulePopup = async () => {
    try {
      const element: any = document.getElementById("cardDetails");
      document.getElementById('saveBtn')?.setAttribute("disabled", "true"); //To disabled the savebutton when popup is open and when popup is closed
      let errorlabletime = document.getElementById("errorlabelTime")    //To hide the errorlabel when popup is close and when popup is open
      errorlabletime?.setAttribute('hidden', 'true');
      //unCheckAllCheckboxes();
      if (element.hasAttribute("aria-modal")) {
        //#region Hide PopUp

        document.getElementById("cardDetails")?.setAttribute("class", "modal fade");
        document.getElementById("cardDetails")?.setAttribute("style", "display:none");
        document.getElementById("cardDetails")?.setAttribute("aria-hidden", "true");
        document.getElementById("cardDetails")?.removeAttribute("aria-modal");
        document.getElementById("cardDetails")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        document.getElementById("timeDD")?.setAttribute("hidden", "true");
        //#endregion
      }
      else {
        document.getElementById("cardDetails")?.removeAttribute("aria-hidden");
        document.getElementById("cardDetails")?.setAttribute("aria-modal", "true");
        document.getElementById("cardDetails")?.setAttribute("role", "dialog");
        document.getElementById("cardDetails")?.setAttribute("class", "modal fade show");
        document.getElementById("cardDetails")?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
        document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        // let element: any = document.getElementById("from-date");
        // element.value = "";
        // element = document.getElementById("picker");
        // element.value = "";
        // $('input[name="disableCheckbox"]').prop('checked', false);
        // $('input[name="enableCheckbox"]').prop('checked', false);
      }

      scheduledDate = "";
      setScheduledDate(scheduledDate);
      scheduledTime = "";
      setScheduledTime(scheduledTime);
      let eleDate: any = document.getElementById("txtScheduledTime");
      let eleTime: any = document.getElementById("txtScheduledDate");
      eleDate.value = "";
      eleTime.value = "";
      document.getElementById('errorlabelTime')?.setAttribute("hidden", "true");

    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const gridBodyData = () => {
    try {

      ////console.log(migrationGridData);
      if (migrationGridData.length > 0 && migrationGridData[0] != "No Records Found" && migrationGridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return migrationGridData.map((obj: any, index:any) => {
          $('input[name="enableCheckbox"]')?.removeAttr('disabled');
          let i: number = index
          let trclassName = i % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          $('input[name="disableCheckbox"]').attr("disabled", "disabled");

          // $('input[name="Queue"]').attr("disabled", "disabled");
          let isCheckboxEnable = (obj.AllStatus === 1 || obj.MigrationStatus == 'Queue' || obj.MigrationStatus == 'Completed' || obj.MigrationStatus == 'Scheduled') ? "disableCheckbox" : "enableCheckbox";

          return (
            <tr className={trclassName} >
              {
                (obj.SourceChatName).length > 32 ? <td className="py-3 tabel-data-font-level1" >
                  <input name={isCheckboxEnable} className="form-check-input me-2" id={obj.ID} onChange={async (e) => await handleCheckbox(e)} type="checkbox" />
                  <span data-tip={obj.SourceChatName} >{obj.SourceChatName.substring(0, 30)}...</span></td> :
                  <td className="py-3 tabel-data-font-level1" data-tip=""><input name={isCheckboxEnable} className="form-check-input me-2" id={obj.ID} onChange={async (e) => await handleCheckbox(e)} type="checkbox" />{obj.SourceChatName}</td>
              }
              {
                (obj.TargetChannelName).length > 32 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetChannelName} >{(obj.TargetChannelName).substring(0, 30)}...</td> : <td className="py-3 tabel-data-font-level1" data-tip="">{obj.TargetChannelName}</td>
              }
              {obj.MigrationStatus === 'Queue' ? (
                <td className="py-3 tabel-data-font-level1 text-center"> <span className="queue-status status-block font-14">Queue</span></td>
              ) :
                obj.MigrationStatus === 'In progress' ? (
                  <td className="py-3 tabel-data-font-level1 text-center"> <span className="inprogress-status status-block font-14">In progress</span></td>
                ) :
                  obj.MigrationStatus === 'Completed' ? (
                    <td className=" py-3 tabel-data-font-level1 text-center"><span className="completed-status status-block font-14">Completed</span></td>
                  ) :
                    obj.MigrationStatus === null || obj.MigrationStatus == 'Yet To Start' ? (
                      <td className=" py-3 tabel-data-font-level1 text-center"><span className="yettostart-status status-block font-14">Yet to Start</span></td>
                    ) :
                      obj.MigrationStatus === 'Scheduled' ? (
                        <td className="py-3 tabel-data-font-level1 text-center"><span className="scheduled-status status-block font-14">Scheduled</span></td>
                      ) :
                        obj.MigrationStatus === 'Warning' ? (
                          <td className="py-3 tabel-data-font-level1 text-center"><span className="warning-status status-block font-14">Warning</span></td>
                        ) :
                          obj.MigrationStatus === 'Cancelled' ? (
                            <td className="py-3 tabel-data-font-level1 text-center"> <span className="yettostart-status status-block font-14">Paused</span></td>
                          ) :
                            (
                              <td className="py-3 tabel-data-font-level1 text-center"> <span className="status status-styling font-14 font-semibold">{obj.MigrationStatus}</span></td>
                            )
              }
              {/* <td className=" py-3 tabel-data-font-level1">{obj.TargetChannelName}</td> */}
              {obj.MigrationStatus === "Queue" ? 
              <td className=" py-3 tabel-data-font-level1 text-center">
                <button className="common-btn-class btn-scondary-custom border-0 px-3 py-1 ConMig_Cancel_button_enabled" onClick={() => deleteQueueStatus(obj)}>Cancel</button>
              </td> 
              : obj.MigrationStatus === "In progress" ?  
              <td className=" py-3 tabel-data-font-level1 text-center ">
                <button className="common-btn-class border-0 px-3 py-1 ConMig_Cancel_button inprogress-status" onClick={() => deleteQueueStatus(obj)}>Pause</button>
              </td> 
              : obj.MigrationStatus === "Cancelled" ? 
              <td className=" py-3 tabel-data-font-level1 text-center ">
                <button className="common-btn-class border-0 px-3 py-1 ConMig_Cancel_button yettostart-status" id={obj.ID} value = 'resume' onClick={(e)=>{startMigration(e)}}>Resume</button>
              </td>
              :
              <td className=" py-3 tabel-data-font-level1 text-center ">
                <button className="common-btn-class border-0 px-3 py-1 ConMig_Cancel_button_disabled" disabled>Cancel</button>
              </td> }

              <span>
                <ReactTooltip />
              </span>
            </tr>)
        })
      }
      else if (migrationGridData[0] == undefined && migrationGridData == undefined) {
        return (<tr> <td className="aligncenter" colSpan={4}></td>  </tr>)
      }//empty binding
      else {
        return (<tr> <td className="aligncenter" colSpan={4}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img><p className="mt-2">No records found</p></td>  </tr>)
      }//No records found

    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const formatFromToDate = (date: any) => {
    try {

      let objDate = new Date(date);
      if (date != null && !isNaN(objDate.getTime())) {
        let strDate = objDate.getDate().toString();
        strDate = strDate[1] ? strDate : "0" + strDate[0];
        let strMonth = (objDate.getMonth() + 1).toString();
        strMonth = strMonth[1] ? strMonth : "0" + strMonth[0];
        //let convertedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        let convertedDate = `${strMonth}/${strDate}/${objDate.getFullYear()}`;
        return convertedDate;
      }
      else {
        return "NA"
      }
    } catch (error) {
      throw (error);
    }
  }

  const gridInventoryBodyData = () => {

    try {

      //charcter restring Slack@Channel@Worskspace_Team_D 
      if (inventoryGridData.length > 0 && typeof (inventoryGridData) !== "string") {

        return inventoryGridData.map((obj: any, index) => {
          return (
            <a onClick={() => inventoryNameOnClick(obj)} className={obj.ID == inventorySummaryID ? "list-group-item list-group-item-action border-0 border-left border-bottom mb-2 anchorAsPointer" : "list-group-item list-group-item-action border-0 border-bottom mb-2 anchorAsPointer"}>
              <p className="mb-1 font-14 font-semibold">
                {obj.InventoryName.length > 28 ?
                  <span data-tip={obj.InventoryName} className="font-regular font-14">{obj.InventoryName.substring(0, 28)}...
                    {/* <ReactTooltip /> */}
                  </span> :
                  <span data-tip="" className="font-regular font-14">{obj.InventoryName}</span>
                }
              </p>
              {
                (obj.InventoryFromDate !== null || obj.InventoryToDate !== null) ?
                  <p className="mb-1 font-12 font-semibold">Duration : <span className="font-regular">({formatFromToDate(obj.InventoryFromDate)}-{formatFromToDate(obj.InventoryToDate)})</span> </p>
                  :
                  ''
              }

              {/* {
                (obj.InventoryFromDate !== null && obj.InventoryToDate !== null) ?
                  <p className="mb-1 font-12 font-semibold">Duration : <span className="font-regular"> ({obj.InventoryFromDate.replace(/-/g, "/").split("T")[0]}-{obj.InventoryToDate.replace(/-/g, "/").split("T")[0]}) </span></p>
                  :
                  (obj.InventoryToDate !== null) ?
                    <p className="mb-1 font-12 font-semibold">Duration : <span className="font-regular"> ({" "}-{obj.InventoryToDate.replace(/-/g, "/").split("T")[0]}) </span></p>
                    :
                    (obj.InventoryToDate !== null) ?
                      <p className="mb-1 font-12 font-semibold">Duration : <span className="font-regular"> ({obj.InventoryFromDate.replace(/-/g, "/").split("T")[0]}-{" "}) </span></p>
                      :
                      <p></p>
              } */}
            </a>
          )
        })
      }
      else if (inventoryGridData == undefined) {
        return (<tr> <td className="aligncenter" colSpan={1}></td></tr>)
      }//empty binding
      else {
        return (<tr> <td className="center" colSpan={1}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img><p className="mt-2">No records found</p></td>  </tr>)
        // return (<tr> <td className="inventory-norecords-found" colSpan={1}>No Records Found</td></tr>)
      }//No records found
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleLoadMore = () => {
    try {

      pageIndex = pageIndex + 1;
      setpageIndex(pageIndex);
      countPerPage = countPerPage + 10;
      setCoutPerPage(countPerPage);
      loadGroupChatData(operation);

      // To handle the Select All When Load More is Clicked
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if (selectAllCount == 1) {
              if(isFullSelect == true){
                  setSelectAllCount(2);
                  selectAllelement.checked = "true";
                  selectAllelement.className = "form-check-input me-2 check-alg-2"
              }
              else{
                  setSelectAllCount(0);
                  selectAllelement.checked = "";
              }
          } 
      else if(selectAllCount == 2) {
              setSelectAllCount(2);
              selectAllelement.checked = "true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const btnClearFilterClick = () => {
    try {

      const eleMigrationStatus: any = document.getElementById("ddMigrationStatus");
      const eleSlackChatType: any = document.getElementById("ddSlackChatType");
      setMigrationStatus('');
      setSourceType('All');
      eleMigrationStatus.value = '';
      eleSlackChatType.value = 'All';
      setMigrationStatus('');
      setSourceType('All');
      //document.getElementById("ApplyFilter")?.removeAttribute("disabled");
    }

    catch (error: any) {
      ////console.log(error)
    }
  }

  const btnCloseFilterClick = () => {
    try {

      const eleMigrationStatus: any = document.getElementById("ddMigrationStatus");
      const eleSlackChatType: any = document.getElementById("ddSlackChatType");
      setMigrationStatus('');
      setSourceType('All');
      eleMigrationStatus.value = '';
      eleSlackChatType.value = 'All';
      setMigrationStatus('');
      setSourceType('All');
      document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      //document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
    }

    catch (error: any) {
      ////console.log(error)
    }
  }

  //#region [On Change ]
  const handleMigrationFromDate = (e: any) => {

    migrationFromDate = e.target.value;
    minMigrationToDate = e.target.value;
    setMigrationFromDate(migrationFromDate);
    setMinMigrationToDate(minMigrationToDate);
    ////console.log(migrationFromDate, minMigrationToDate);
  }

  const handleMigrationToDate = (e: any) => {

    migrationToDate = e.target.value;
    maxMigrationFromDate = e.target.value;
    setMigrationToDate(migrationToDate);
    setMaxMigrationFromDate(maxMigrationFromDate);
  }
  //#endregion

  const keypressInventory = (e: any) => {
    try {
      if (e.keyCode == 13) {
        setInventorySearchText(e.target.value);
        //document.getElementById("ApplyFilter")?.removeAttribute("disabled")
        loadGridPage();
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const keypressMigration = async (e: any) => {
    try {
      if (e.keyCode == 13) {
        let tempOperation = ""
        if(migrationSearchText != ""){
          tempOperation = "search"
        }
        setMigrationSearchText(e.target.value);
        setOperation(tempOperation)
        await loadGroupChatData(tempOperation);
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const startMigration = async (e:any) => {
    let loader;
    try {

      if (clientID != undefined && clientToken != undefined) {
        let selectedChats: { "migrationSummaryID": any, "inventorySummaryID": any }[] = [];
        let tempObj: any = {};
        if(e.target.value=='resume'){
          tempObj["migrationSummaryID"] = Number(e.target.id);
          tempObj["inventorySummaryID"] = inventorySummaryID;
          tempObj["isServiceAccount"] = false
          selectedChats.push(tempObj);
          tempObj = {};
        }
        else{
          for (let i = 0; i < arrSelectedValues.length; i++) {
            tempObj["migrationSummaryID"] = arrSelectedValues[i];
            tempObj["inventorySummaryID"] = inventorySummaryID;
            tempObj["isServiceAccount"] = false
            selectedChats.push(tempObj);
            tempObj = {};
          }
          var now = new Date();
          if (migrationFromDate != "") {
            let objFromDate = new Date(migrationFromDate.split('T')[0] + "T00:00:00.000Z");
            migrationFromDate = new Date(objFromDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
          }
          if (migrationToDate != "") {
            let objToDate = new Date(migrationToDate.split('T')[0] + "T23:59:59.999Z");
            migrationToDate = new Date(objToDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
          }
        }    

        // console.log("selected Chats",selectedChats)

        let startMigration = (
          {
            method: "POST",
            url: constants.URL.startGroupChatMigrationURL,
            data:
              [
                {
                  "isScheduleMigration": false,
                  "isInprogress": false,
                  "clientID": clientID,
                  "migratedDateTime": (new Date()).toISOString(),// (new Date()).toISOString().split("T")[0],
                  "fromDate": migrationFromDate === "" ? null : migrationFromDate,
                  "toDate": migrationToDate === "" ? null : migrationToDate,
                  "jsonResponse": selectedChats,
                  "type" : "ChatHTML"
                }
              ],
            token: clientToken
          });
        if(adminLocal !=undefined && adminLocal !== null){
          (startMigration.data[0] as any).token = adminToken
        }
        setNonGifLoaderContent("Please wait. We are initiating migration.");
        ////console.log("Loader enabled");
        loader = document.getElementById("loaderWithContent");
        loader?.removeAttribute('hidden');

        let data: any = await callBackEnd(startMigration);
        resetFromToDates();
        loader?.setAttribute('hidden', 'hidden')
        if (data.data.statusCode == 200) {
          setArrSelectedValues([]);
          loadGroupChatData(operation);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          if (data.data.data != "Lisence count exceeded" && data.data.data != "Invalid MigrationSummaryID/InventorySummaryID provided" && data.data.data != "Something went wrong. Please reach AVAMIGRATRON team") {
            (objClient.isUserBasedLicense)
              ?
              createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Group Chat Provided", "Already Migrated Group Chat Provided", "Already Queued Group Chat Provided", "Group Chat which is In Progress Provided", "Licensed User not present in the chat"])
              :
              createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Group Chat Provided", "Already Migrated Group Chat Provided", "Already Queued Group Chat Provided", "Group Chat which is In Progress Provided"]);

          }
          else {
            statusText = data.data.data == "Lisence count exceeded" ? "License Count Exceeded.  Kindly Upgrade Your License to proceed the migration." : "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setStatusText(statusText);
          }
          setArrSelectedValues([]);
          enableAlertPopup();

          // statusText = data.data.data=="Lisence count exceeded" ? "License Count Exceeded.  Kindly Upgrade Your License to proceed the migration." : "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
          // setStatusText(statusText);
          // enableAlertPopup();
          loadGroupChatData(operation);
          //setArrSelectedValues([]);
        }
      }
      else {
        localStorage.clear();
      }

    }
    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden')
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      setArrSelectedValues([]);
      loadGroupChatData(operation);
      enableAlertPopup();
    }
  }


  const resetFromToDates = async () => {
    try {
      let eleMigFromDate: any = document.getElementById("migrationFromDate");
      eleMigFromDate.value = "";
      let eleMigToDate: any = document.getElementById("migrationToDate");
      eleMigToDate.value = "";
      migrationFromDate = "";
      migrationToDate = "";
      setMigrationFromDate(migrationFromDate);
      setMigrationToDate(migrationToDate);
      setMaxMigrationFromDate(new Date().toISOString().split('T')[0]);
      setMinMigrationToDate("2013-01-01");
    }
    catch (error) {
      throw (error)
    }
  }

  //#region [save schedule click]check
  const saveScheduleClick = async () => {
    let loader;
    try {

      if (clientID != undefined && clientToken != undefined) {

        let objScheduledDate: any = new Date(scheduledDate + "T" + scheduledTime + "Z");
        let objCurrentDate: any = new Date();
        let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;
        var currentTime = parseInt(new Date().getUTCHours().toString());
        let chosenTime = parseInt((scheduledTime).substring(0, 2));
        var currentDate: any = new Date().toISOString().split('T')[0];
        let objCurrentDateCheck = new Date(currentDate);
        let objScheduledDateCheck = new Date(scheduledDate);

        //!(objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime)  same date and past time validation
        //objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled   same date and time difference is 5 minutes. 
        if (!(objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) && !(objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
          //unCheckAllCheckboxes();
          setNonGifLoaderContent("Please wait. We are scheduling your migration");
          loader = document.getElementById("loaderWithContent");
          loader?.removeAttribute('hidden');
          let scheduledChats: { "MigrationSummaryID": any, "InventorySummaryID": any, "MigrationFromDate": any, "MigrationToDate": any }[] = [];
          let tempObj: any = {};
          var now = new Date();
          if (migrationFromDate != "") {
            let objFromDate = new Date(migrationFromDate.split('T')[0] + "T00:00:00.000Z");
            migrationFromDate = new Date(objFromDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
          }
          if (migrationToDate != "") {
            let objToDate = new Date(migrationToDate.split('T')[0] + "T23:59:59.999Z");
            migrationToDate = new Date(objToDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();
          }

          for (let i = 0; i < arrSelectedValues.length; i++) {
            tempObj["MigrationSummaryID"] = arrSelectedValues[i];
            tempObj["InventorySummaryID"] = inventorySummaryID;
            tempObj["MigrationFromDate"] = migrationFromDate === "" ? null : migrationFromDate;
            tempObj["MigrationToDate"] = migrationToDate === "" ? null : migrationToDate;
            scheduledChats.push(tempObj);
            tempObj = {};
          }

          let saveScheduledData = (
            {
              method: "POST",
              url: constants.URL.saveScehduledGroupChatDataURL,
              data:
              {
                "clientID": clientID,
                "createdAt": (new Date()).toISOString().replace("T", " ").replace("Z", ""),
                "scheduledAt": scheduledDate + " " + scheduledTime,
                "arrParamChats": scheduledChats,          //selectedGroupChatValuesScheduler
                "type" : "ChatHTML"
              },
              token: clientToken
            }
          );
          if(adminLocal !=undefined && adminLocal !== null){
            (saveScheduledData.data as any).token = adminToken
          }


          resetFromToDates();
          enableReschedulePopup();
          let data: any = await callBackEnd(saveScheduledData);
          loader?.setAttribute('hidden', 'hidden')


          if (data.data.statusCode == 200) {
            setArrSelectedValues([]);
            loadGroupChatData(operation);
          }
          else if (data.data.data == "Invalid authentication token provided") {
            enableSessionExpiryPopup();
          }
          else {

            if (data.data.data != "Lisence count exceeded" && data.data.data != "Invalid MigrationSummaryID/InventorySummaryID provided" && data.data.data != "Something went wrong. Please reach AVAMIGRATRON team") {
              (objClient.isUserBasedLicense)
                ?
                createDynamicListItem("Failed to Schedule Group Chat Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Group Chat Provided", "Already Migrated Group Chat Provided", "Already Queued Group Chat Provided", "Group Chat which is In Progress Provided", "Licensed User not present in the chat"])
                :
                createDynamicListItem("Failed to Schedule Group Chat Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Group Chat Provided", "Already Migrated Group Chat Provided", "Already Queued Group Chat Provided", "Group Chat which is In Progress Provided"]);

            }
            else {
              statusText = data.data.data == "Lisence count exceeded" ? "License Count Exceeded.  Kindly Upgrade Your License to proceed the migration." : "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
              setStatusText(statusText);
            }
            loader?.setAttribute('hidden', 'hidden')
            setArrSelectedValues([]);
            enableAlertPopup();
          }
          //setGridData(data.data.data[0]);
        }
        else {
          let x = document.getElementById("errorlabelTime")
          x?.removeAttribute('hidden');
          document.getElementById('saveBtn')?.setAttribute("disabled", "true");
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden')
      setStatusText("Something went wrong! Please reach AVAMIGRATRON Support.");
      setArrSelectedValues([]);
      loadGroupChatData(operation);
      enableAlertPopup();
    }
  }
  //#endregion

  const createDynamicListItem = async (popupText: any, arrReasons: any) => {
    try {
      setStatusText(popupText);
      // let boldTag = document.createElement("b");
      // let text = document.createTextNode(popupText); 
      // boldTag.appendChild(text);
      let element = document.getElementById("dynamicData");
      //element?.appendChild(boldTag);  
      let ulTag = document.createElement("ol");
      ulTag.classList.add('info-background-color', 'listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      throw error
    }
  }

  function deleteChild() {
    try {
      var e: any = document.getElementById("dynamicData");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    } catch (error) {
      throw (error);
    }
  }


  const loadGroupChatData = async (operation:any) => {
    try {
      if (totalItemsCount != 0 && inventorySummaryID != null && inventorySummaryID != undefined) {
        if (clientID != undefined && clientToken != undefined) {
          migrationGridData = []
          setMigrationGridData(migrationGridData)

          let loadGCData = (
            {
              method: "POST",
              url: constants.URL.getGroupChatDataURL,
              data:
              {
                "clientID": clientID,
                "searchText": migrationSearchText,
                "sourceType": sourceType,
                "inventoryId": inventorySummaryID,
                "pageIndex": pageIndex,
                "migrationStatus": migrationStatus,
                "sortColumn": sortColumn,
                "sortType": sortType,
                "type": "ChatHTML"
              },
              token: clientToken
            }
          );

          // $('input[name="disableCheckbox"]').prop('checked', false);
          // $('input[name="enableCheckbox"]').prop('checked', false);
          // $('input[name="disableCheckbox"]')?.removeAttr('disabled');
          // $('input[name="enableCheckbox"]')?.removeAttr('disabled');


          let loader = document.getElementById("loaderWithoutContent");
          loader?.removeAttribute('hidden');
          let data: any = await callBackEnd(loadGCData);
          let ototalItemsCount = data.data.data == "No Records Found" ? 0 : data.data.data[1][0].GroupChatInventoryCount;
          if(operation == "search" || operation == "statusFilter" || operation == "sourceFilter"){
            setTotalItemsCountFeature(ototalItemsCount)
          }
          else{
              setTotalItemsCount(ototalItemsCount)
              // To Set the ValidTotalCount for the Select All Excluding the Disabled CheckBoxes
              setValidTotalCount(data.data.data[2][0].SelectedTotalValue)
          }

          if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
            let arr: any = [data.data.data];
            setMigrationGridData(arr);

          }
          else if (typeof (data.data.data) != 'string') {
            setMigrationGridData(data.data.data[0])//data.data.data[0]); 

          }
          else if (data.data.data == "Invalid authentication token provided") {
            enableSessionExpiryPopup();
          }
          else {
            setStatusText(data.data.data);
            enableAlertPopup();
          }

          // setTotalItemsCount(data.data.data[1][0].GroupChatInventoryCount);
          loader?.setAttribute('hidden', 'hidden')
        }
        else {
          localStorage.clear();
        }
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      }
    }
    catch (error: any) {
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("loader")?.setAttribute('hidden', 'hidden')
      enableAlertPopup();
    }
  }


  const inventoryNameOnClick = async (obj: any) => {
    try {

      //updateInventoryInfo(obj);
      inventorySummaryID = parseInt(obj.ID);
      setInventorySummaryID(inventorySummaryID);
      setInventoryName(obj.InventoryName);
      setpageIndex(1);
      setCoutPerPage(10);

      // To reset the Select All on the Inventory Click
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      setArrSelectedValues([])
      selectedGroupChatValues = []
      setSelectAllCount(0);
      selectAllelement.checked = ""
      setIsFullSelect(false)

      loadGroupChatData(operation)
    }
    catch (error: any) {
      ////console.log(error);
    }
  }


  const handleCheckbox = async (e: any) => {
    try {

      let tempArr:any = arrSelectedValues

      if (e.target.checked == true) {
        selectedGroupChatValues.push({
          "migrationSummaryID": parseInt(e.target.id),
          "inventorySummaryID": inventorySummaryID
        })

        tempArr = [...arrSelectedValues, parseInt(e.target.id)]
        setArrSelectedValues([...tempArr]);
          let btnStartMigrationElement = document.getElementById("btnStartMigration")
          btnStartMigrationElement?.removeAttribute('disabled');
          let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
          btnScheduleMigrationElement?.removeAttribute('disabled');
      }
      else {
        let ind = tempArr.findIndex((id: any) => id == e.target.id)
        tempArr.splice(ind, 1);
        setArrSelectedValues([...tempArr]);
        if (tempArr.length == 0) {
          let btnStartMigrationElement = document.getElementById("btnStartMigration")
          btnStartMigrationElement?.setAttribute('disabled', 'disabled');
          let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
          btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');
        }
      }
      // To find whether must be Be in full Select on individual CheckBox Check
      if(tempArr.length == validTotalCount){
        setIsFullSelect(true)
      }
    }
    catch (error: any) {
      ////console.log("error", error);
    }
  }

  const handleErrorLabelPopup = () => {
    try {

      var currentTime = parseInt(new Date().getUTCHours().toString());
      var currentDate = new Date().toISOString().split('T')[0];
      let chosenTime = parseInt((scheduledTime).substring(0, 2))
      let objCurrentDateCheck = new Date(currentDate);
      let objScheduledDateCheck = new Date(scheduledDate);

      let objScheduledDate: any = new Date(scheduledDate + "T" + scheduledTime + "Z");
      let objCurrentDate: any = new Date();
      let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;  //1800000

      if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
        let x = document.getElementById("errorlabelTime")
        x?.removeAttribute('hidden');
        document.getElementById('saveBtn')?.setAttribute("disabled", "true");
      }
      else {
        document.getElementById('errorlabelTime')?.setAttribute("hidden", "true");
        if (scheduledDate != "" && scheduledTime != "") {
          document.getElementById('saveBtn')?.removeAttribute("disabled");
        }
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

   const handleCheckedItems = async () => {
    if (arrSelectedValues.length != 0) {
      migrationGridData.forEach((obj:any) => {
        for (let i = 0; i < arrSelectedValues.length + 1; i++) {
          if (arrSelectedValues[i] == parseInt(obj.ID)){
            let element: any = document.getElementById(obj.ID);
            if (element != "" && element != null && element != undefined && element.disabled == false) {
              element.checked = "true";
              break;
            }
          }
        }
      });
    }
  };

  const handleSelectAll = async ()=>{

    try{
      // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
      let count: any = undefined;
      if (totalItemsCount == migrationGridData.length) {
        setIsFullSelect(true);
        count = selectAllCount + 2;
        if (count > 2) {
          count = 0;
        }
      } else {
        count = selectAllCount + 1;
        if (count > 2) {
          count = 0;
        }
      }
      // to handle the Select All CheckBox CSS
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if(count==2){
        selectAllelement.checked = "true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      else if(count == 1){
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
      }
      else{
        selectAllelement.checked = "";
      }

      setSelectAllCount(count);

       // To handle the SelectAll when the Checkbox is Unchecked
      if (count == 0) {
        setIsFullSelect(false)
        arrSelectedValues.forEach((obj: any) => {
          const element: any = document.getElementById(obj);
          if (element != "" && element != null && element != undefined) {
            element.checked = "";
          }
        });
        let tempArr: any = [];
        setArrSelectedValues([...tempArr]);
        selectedGroupChatValues = tempArr
      }

      // To handle the SelectAll when the Checkbox is PartialSelect
      if (count == 1) {
        let tempArr: any = arrSelectedValues
        migrationGridData.forEach((obj: any) => {
          const element: any = document.getElementById(obj.ID);
          if (
            element != "" &&
            element != null &&
            element != undefined &&
            element.checked != true &&
            element.disabled == false
          ) {
            element.checked = "true";
            selectedGroupChatValues.push({
              "migrationSummaryID": parseInt(obj.ID),
              "inventorySummaryID": inventorySummaryID  
            })
            tempArr.push(parseInt(obj.ID))
          }

        });
        setArrSelectedValues([...tempArr]);
        // To find whether must be Be in full Select on individual CheckBox Check
        if(tempArr.length == validTotalCount){
          setIsFullSelect(true)
        }
      }

       // To handle the SelectAll when the Checkbox is Full Select
      if(count == 2){
        if (totalItemsCount != 0 && inventorySummaryID != null && inventorySummaryID != undefined) {
          if (clientID != undefined && clientToken != undefined) {
            let pageIndex = totalItemsCount
            let loadGCData = (
              {
                method: "POST",
                url: constants.URL.getGroupChatDataURL,
                data:
                {
                  "clientID": clientID,
                  "searchText": "",
                  "sourceType": "All",
                  "inventoryId": inventorySummaryID,
                  "pageIndex": pageIndex,
                  "migrationStatus": "",
                  "sortColumn": sortColumn,
                  "sortType": sortType,
                  "type":"ChatHTML"
                },
                token: clientToken
              }
            );
  
            let loader = document.getElementById("loaderWithoutContent");
            loader?.removeAttribute('hidden');
            let data: any = await callBackEnd(loadGCData);
            if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
              let arr: any = [data.data.data];
              setMigrationGridData(arr);
  
            }
            else if (typeof (data.data.data) != 'string') {
              setIsFullSelect(true)
              let tempArr: any = [];

              data.data.data[0].forEach((obj: any) => {
                if(obj.MigrationStatus != "Completed" &&
                   obj.MigrationStatus != "In Progress" &&
                   obj.MigrationStatus != "Scheduled" &&
                   obj.MigrationStatus != "Queue" &&
                   obj.AllStatus != 1){

                  selectedGroupChatValues.push({
                    "migrationSummaryID": parseInt(obj.ID),
                    "inventorySummaryID": inventorySummaryID  
                  })
                  
                  tempArr.push(parseInt(obj.ID))
                }
               
              });

              setValidTotalCount(tempArr.length)
              setArrSelectedValues([...tempArr])
          
            }
            else if (data.data.data == "Invalid authentication token provided") {
              enableSessionExpiryPopup();
            }
            else {
              setStatusText(data.data.data);
              enableAlertPopup();
            }
  
            setTotalItemsCount(data.data.data[1][0].GroupChatInventoryCount);
            loader?.setAttribute('hidden', 'hidden')
          }
          else {
            localStorage.clear();
          }
          document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
        }

      }

    }
    catch(error){

    }

  }

  const handleDate = (e: any) => {
    try {

      scheduledDate = e;
      setScheduledDate(scheduledDate);
      handleErrorLabelPopup();
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleTime = async (e: any) => {
    try {

      scheduledTime = e;
      setScheduledTime(scheduledTime);
      document.getElementById("timeDD")?.setAttribute("hidden", "true");
      let x = document.getElementById("picker")
      x?.setAttribute("value", `${e.target.id}`);
      handleErrorLabelPopup();
    }
    catch (error: any) {
      ////console.log(error);
    }
  }




  return (
    <div id="manage-migration" className="tab-pane active">
      <div className="col-md-12">
        <div className="p-3 rounded-2 mb-4 info-background-color font-14">
          <div className=" col-md-12 font-14 font-regular"><img src="../Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />To perform incremental migration, take detailed inventory to get latest conversations.</div><br />
          <label className="font-14 font-bold"><span className="me-2"><img src="/Images/info-icon.svg" alt="info_small" /></span><span className="font-regular">With {planType} plan, you can perform {parallelInstance} parallel migrations.</span></label>

        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 mb-4">
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="font-16 font-bold">Customize Migration Dates</label>
              </div>
              <div>
                {/* channel's popup  */}
                <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h5 className="inventory-title font-22 font-bold">Schedule Migration</h5>
                        <button type="button" className="common-btn-class btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={enableReschedulePopup} ></button>
                      </div>
                      <div className="modal-body pt-0">
                        <div className="col-md-12 mb-4">
                          <div className="row">
                            <label className="form-label popup-label font-14 font-regular">If any migration is in progress, the scheduled channels will be in queue.</label>
                          </div>
                        </div>

                        <div className="col-md-12  mb-5">
                          <div className="row">
                            <div className="col-md-6 my-2 float-start">
                              <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Date</label>
                              {/* <input type="date" className="inventory-date-form-control date-style w-100" id="from-date" value={datePopUp} onChange={(e) => handleDate(e)} /> */}
                              <input type="date" className="form-control inventory-date-form-control date-style date-style-cm w-100" id="txtScheduledDate" min={schedulemaxDate} onChange={(e) => handleDate(e.target.value)} onKeyDown={(e) => e.preventDefault()} />

                            </div>
                            <div className="col-md-6 my-2 float-start clk-icon-fix-chat fake-input">
                              <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Time (UTC)</label>

                              <input type="text" className="inventory-date-form-control date-style date-style-cm w-100 input" id="txtScheduledTime" placeholder="-- : --" onClick={() => { document.getElementById("timeDD")?.removeAttribute("hidden") }} value={scheduledTime} />
                              <img src="..\Images\Clock-icon.svg" className="ms-2" onClick={() => { document.getElementById("timeDD")?.removeAttribute("hidden") }} alt="clockicon" />
                              <ul className="editClickdropdown" id="timeDD" hidden>
                                {arrTime.map((obj, index) => {
                                  return (<li className="dropdown-item" id={obj.UI} onClick={(e) => handleTime(obj.UI)}>{obj.UI}</li>)
                                })
                                }
                              </ul>
                              <label id="errorlabelTime" className="required" hidden={true} >Please choose future time</label>

                              {/* <input type="number" className="date-style w-100" id="to-date" min="1" max="24" value={time} onChange={handleTimePicker} /> */}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-12 d-flex justify-content-end mb-4">
                        <button type="button" className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-second me-2 font-16 font-semibold float-start ps-4 pe-4" onClick={enableReschedulePopup}>Cancel</button>
                        <button type="button" className="common-btn-class btn-primary-theme btn btn-prime font-16 font-semibold float-start me-3 ps-4 pe-4" id="saveBtn" onClick={saveScheduleClick}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* channel's popup ends here */}
              </div>
              <div className="col-md-12 d-md-flex d-lg-flex">
                <div className="mt-3 mb-4">
                  <label className="font-14 font-bold me-2">From date</label>
                  <input className="form-control form-control-sm date-form-custom d-inline-flex" id="migrationFromDate" type="date" placeholder=".form-control-sm" aria-label=".form-control-sm example" onChange={handleMigrationFromDate} onKeyDown={(e) => e.preventDefault()} />
                </div>
                <div className="my-3">
                  <label className="font-14 font-bold todate-res-conv date-space-btw-res">To date</label>
                  <input className="form-control form-control-sm date-form-custom d-inline-flex" id="migrationToDate" type="date" placeholder=".form-control-sm" aria-label=".form-control-sm example" onChange={handleMigrationToDate} onKeyDown={(e) => e.preventDefault()} />
                </div>
              </div>
              <div className="col-md-12">
                <label id="errorlabelDate" hidden={true} className="required errorlable"  >Please provide valid date</label>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="font-16 font-bold pb-2">Migrate conversation from Slack to Teams chat </label>
            <p className="font-regular font-14">Select the required chats to begin with conversation migration.</p>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/* Inventory List starts here */}
              <div className="col-lg-3 col-sm-12 col-md-12 mt-2">
                <div className="row mx-1 margin-btm-res-mm">
                  <div className="col-md-12 mb-3">
                    <label className="font-16 font-bold ">Inventory List</label>
                  </div>
                  <div className="col-md-12 inventory-card rounded-3 shadow-sm py-3">
                    <div className="input-group pb-3">
                      <input type="text" className="form-control z-value-unset form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onKeyDown={(e) => keypressInventory(e)} onChange={(e) => handleInventorySearch(e)} />
                      <button className=" btn z-value-unset btn-outline-secondary" type="button" id="button-addon1"><img src="../Images/search.svg" alt="search-icon" onClick={loadGridPage} />
                      </button>
                    </div>
                    <div className="list-group widget-min-height inlineScrollManageMigration-res responsive-design p-1">
                      {gridInventoryBodyData()}
                    </div>
                  </div>
                </div>
              </div>
              {/* Inventory List ends here */}
              {/* Inventory grid starts here */}
              <div className="col-lg-9 col-sm-12 col-md-12">
                <div className="row">
                  <div className="float-start mb-1 w-100">
                    <div className="phn-btn-float-s mt-2">
                      <label className="font-14 float-start font-bold me-4 border-bottom-res12">Inv. Name : {
                        inventoryName?.length > 52 ? <span data-tip={inventoryName} className="font-regular font-14">{inventoryName.substring(0, 49)}...</span> :
                          <span data-tip="" className="font-regular font-14">{inventoryName}</span>
                      }
                      </label>
                    </div>

                    {/* <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3"># of records: {migrationGridData[0] != "No Records Found" ? migrationGridData.length:0} <span className="font-regular font-16">of </span> {totalItemsCount}</p> */}
                    <p className="mb-0 font-bold font-12 text-nowrap float-start ms-custom-chat-3 me-3" style={{ marginTop: "10px" }}>
                                            # of records: {migrationGridData != "N" && migrationGridData[0] != "No Records Found" ? migrationGridData.length:0}{" "}
                                            <span className="font-regular font-12">of </span> 
                                            { migrationGridData != "N" || migrationGridData[0] != "No Records Found" ?
                                            operation == "search" || operation == "statusFilter" || operation == "sourceFilter" ?  
                                                totalItemsCountFeature: totalItemsCount 
                                            :0}{" ("}{arrSelectedValues.length}{" Selected)"}
                                        </p>

                    <div className="phn-btn-float-e">
                      <div className="search-container-mm cm-refresh-margin input-group box-shodow-filter float-start ">
                        <input type="text" className="form-control z-value-unset form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onKeyDown={(e) => keypressMigration(e)} onChange={(e) => handleMigrationSearch(e)} />
                        <button className=" btn z-value-unset btn-outline-secondary" type="button" id="button-addon1" ><img src="../Images/search.svg" alt="search-icon" onClick={async(e:any)=>{
                           let tempOperation = ""
                           if(migrationSearchText != ""){
                             tempOperation = "search"
                           }
                          setMigrationSearchText(e.target.value);
                          setOperation(tempOperation)
                          await loadGroupChatData(tempOperation);
                        }} />
                        </button>
                      </div>

                      {/* advanced-filter */}
                      <div className="dropdown filter float-start position-dropdown">
                        <button type="button" className="btn float-start hli-filter-margin12 btn-scondary-custom box-shodow-filter filter-shadow p-0" title="Advanced Filter" onClick={() => { document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden") }}>
                          <img src="../Images/filter.svg " alt="filter" />
                        </button>
                        {/* added show class to resolve the popup not opening issue */}
                        <div className="manageMigrationFilter-position-res manage-filt-posi-tab w-100 GroupChatdrop-filter-sty  dropdown-menu show advancedFilterPopUp" id="AdvancedFilterDiv" hidden>
                          <div className="container-fluid">
                            {/* <div className="row"> */}
                            <div className="col-md-12 pb-2 border-0 mb-1">
                              <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                              <a className="advancedfilter_close_padding btn float-end anchorAsPointer" id="close-filter" data-bs-dismiss="advanced-filter" aria-label="Close" onClick={btnCloseFilterClick}>
                                <span aria-hidden="true"><img src="../Images/filter-close-icon.svg" alt="filter-close-icon" /></span>
                              </a>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mt-2 mb-3 float-start px-2">
                                <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Migration Status</label>
                                <select className="custom-date-dropdown-border form-control form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => handleMigrationStatus(e)} id="ddMigrationStatus">
                                  <option value="">All</option>
                                  <option value="In Progress">Inprogress</option>
                                  <option value="Yet To Start">Yet to Start</option>
                                  <option value="Completed">Completed</option>
                                  <option value="Warning">Warning</option>
                                  <option value="Queue">Queue</option>
                                  <option value="Scheduled">Scheduled</option>
                                </select>
                              </div>
                              <div className="col-md-6 mt-2 mb-3 float-start advancedfilter_todate_padding">
                                <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Slack Chat Type</label>
                                <select className="custom-date-dropdown-border form-control form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => handleSourceType(e)} id="ddSlackChatType">
                                  <option value="All">All</option>
                                  <option value="MM">MM</option>
                                  <option value="DM">DM</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12 mt-5 mb-3">
                              <div className="float-end">
                                <button className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-clear font-16 font-semibold px-4 me-3" onClick={btnClearFilterClick} >Clear</button>
                                <button className="common-btn-class btn-primary-theme btn btn-apply font-16 font-semibold text-light" id="ApplyFilter" onClick={()=>{
                                  let tempOperation = ""
                                  if(sourceType != "All" || migrationStatus != ""){
                                    tempOperation= "sourceFilter"
                                  }
                                  setOperation(tempOperation)
                                  loadGroupChatData(tempOperation);
                                }}>Apply Filter</button>

                                {/* <a href="#" className="btn btn-clear font-16 font-semibold px-4 me-3" onClick={btnClearFilterClick} >Clear</a>
                                  <a href="#" className="btn btn-apply font-16 font-semibold text-light" id="ApplyFilter" onClick={loadGroupChatData} >Apply Filter</a>*/}
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>

                      </div>
                      {/* advanced-filter ends*/}
                      <button type="button" className="btn btn-refresh btn-refresh-cm cm-refresh-margin btn-scondary-custom   px-3 btn-sm float-start d-inline-flex font-16 font-semibold padding-six-px" onClick={loadGroupChatData} ><span className="pe-2"><img src="../Images/refresh-black.svg" alt="refresh" /></span> <span className="refreshTextColor">Refresh</span></button>

                    </div>

                  </div>

                  <div className="table-responsive col-md-12 float-start mt-2 p-1">
                    <table className="table table-border first-child-wrap">
                      <thead className="tabel-head-bg">
                        <tr >
                        <th className="table-head font-14 font-bold">
                        {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                        <input
                          className="form-check-input me-2 check-alg-2 intermediate"
                          id="selectAllCheckBox"
                          name="checkbox"
                          type="checkbox"
                          onClick={handleSelectAll}
                        />
                        Slack Chat Name
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_arrow.svg' alt="SourceChatNameDesc" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_upper_arrow.svg' alt="SourceChatNameAsc" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                      </th>
                          {/* <th className="table-head font-14 font-bold">Slack Chat Name
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_arrow.svg' alt="SourceChatNameDesc" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_upper_arrow.svg' alt="SourceChatNameAsc" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                          </th> */}
                          <th className="table-head font-14 font-bold">MS Teams Chat Name
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_arrow.svg' alt="TargetChatNameDesc" id="TargetChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatName@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_upper_arrow.svg' alt="TargetChatNameAsc" id="TargetChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatName@desc" ? false : true} />
                          </th>
                          <th className="table-head font-14 font-bold text-center">Migration Status
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_arrow.svg' alt="MigrationStatusDesc" id="MigrationStatus@desc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? true : false} />
                            <img className="ms-2 anchorAsPointer" src='../Images/sort_upper_arrow.svg' alt="MigrationStatusAsc" id="MigrationStatus@asc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? false : true} />
                          </th>
                          <th className="table-head font-14 font-bold text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gridBodyData()}
                      </tbody>
                    </table>
                    {/* channel list table ends here */}

                  </div>
                  {/* loadmore button section starts here */}
                  <div className="col-md-12 mt-3 mb-5 text-center">
                    {/* {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={() => handleLoadMore()}>Load More</button> : ""} */}
                    {operation != "search" && operation != "statusFilter" && operation != "sourceFilter" ? (
                                        totalItemsCount > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        ) : (
                                        totalItemsCountFeature > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        )}
                  </div>
                  {/* loadmore button section ends here */}
                </div>
              </div>
              {/* Inventory grid ends here */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <footer id="footerDiv" className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
          <div className=" mx-3 d-flex justify-content-end">
            <button type="button" id="btnScheduleMigration" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" disabled = {arrSelectedValues.length == 0? true:false} onClick={enableReschedulePopup}>Schedule Migration</button>
            <button type="button" id="btnStartMigration" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular" disabled = {arrSelectedValues.length == 0? true:false} onClick={(e)=>{startMigration(e)}}>Start Migration</button>
          </div>
        </footer>
      </div>

      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='../Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{statusText}</p>
                <div id="dynamicData"></div>
                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={popUpOkClick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Loader with content starts here */}
      <div className="overlay" id='loaderWithContent' hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
          <p className="text-center ">{nonGifLoaderContent}</p>
        </div>
      </div>
      {/* loader with content ends here */}

      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>

  )
}