import axios from 'axios';
export async function APIService (endpoint : any,{requestType, body, ...customConfig} : any = {}, token: any)
{
  
    const header : any = {
      'Content-Type': 'application/json',
      'Authorization': token
    } 
    try
    {
      const apiResponse = await axios.post(endpoint,body,{
        headers: header
      });
      return apiResponse;
    }
    catch(error)
{
  ////console.log(error);
}
}

export const sendRequestToNodeServices = async (endpoint:any, { requestType, body, ...customConfig }:any = {}, token:string) => {
  let apiResponse;
  try {
      const headers: any = {
          'Content-type': 'application/json',
          "Authorization": token
      };
      apiResponse = await axios.post(endpoint, body, {headers:headers});
  }
  catch (err) {
      
      throw err;
  }
  return apiResponse;
}

export async function loadListData(config: any){
    try{
        const apiResponse = await axios(config)
        return apiResponse;
    }
    catch(error : any){
                
    }
}

export async function APIServiceToken (endpoint : any,{requestType, body, ...customConfig} : any = {},)
{
    try{
    const headers : any = {'Content-type': 'application/json; charset=UTF-8'};
    const apiResponse = await axios.post(endpoint,body,{headers:headers});
    return apiResponse;
}
catch(error:any){
    
    
}
}

export async function serverDownApiService(endpoint: any, { requestType, body, ...customConfig }: any = {},) {
  try {
      const headers: any = { 'Content-type': 'application/json; charset=UTF-8' };
      const apiResponse = await axios.post(endpoint, body, { headers: headers });     
      return apiResponse;
  }
  catch (error) {
      console.log(error);
  }
}