import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { sendRequestToNodeServicesApiTS,sendRequestToUpdateUsersList } from "../../Services/SettingsServices";

import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from "../../Common/Constants";

export function Source(): JSX.Element {

    let dataObj: any = [];
    const [isServiceAdmin, setisServiceAdmin] = useState<any>();
    const [state, setState] = useState(dataObj);
    const [failedPopUp, setFailedPopUp] = useState("");
    const [isSlackSecretIDEyeOpen, setIsSlackSecretIDEyeOpen] = useState(false);
    const [isSlackAppTokenEyeOpen, setIsSlackAppTokenEyeOpen] = useState(false);
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    let [loaderContent, setloaderContent] = useState("");
    let isPoupClosedAfterSuccess = false;
    const [sourceEmailId, setsourceEmailId] = useState("");
     



    let local: any = localStorage.getItem("cid_t");

    let adminLocal: any = localStorage.getItem("aid_t");

    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let clientName: any;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let adminToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientName = objClient.CName;
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;
        // Admin Token Decryption 
        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
        }

    }
    let IsInitialLogin = (objClient != undefined && objClient.IsInitialLogin != undefined && objClient.IsInitialLogin) ? true : false;

    useEffect(() => {
        // Validate the Service Admin
        if(adminObjClient?.Role == "Service Admin"){
            setisServiceAdmin(true)
        }

        let loaderElement = document.getElementById("slackLoader")
        loaderElement?.removeAttribute("hidden")
        resetSessionTimer();
        LoadData().then(() => {
            // document.getElementById("sourceButton")?.setAttribute("Disabled", "Disabled");
            loaderElement?.setAttribute("hidden", "true")
        });
        const videoElement = document.getElementById('demovideo');
        
        if (objClient.isMarketPlaceClient&& objClient.IsInitialLogin) {
            if (videoElement) {
            videoElement.setAttribute("src", "https://avaeusavamigs2tgvidprod.blob.core.windows.net/avamigratron/TourGuide.mp4?sp=r&st=2024-03-12T13:57:08Z&se=2025-03-13T21:57:08Z&spr=https&sv=2022-11-02&sr=b&sig=gyC7IHJoRrQ%2F%2FfNcymDP2XFDX5SKRDLnMm5UYIqi63I%3D");
        }      
    
        document.getElementById("tourVideo")?.setAttribute("class", "modal fade show")
        document.getElementById("tourFadeDiv")?.removeAttribute("hidden");
        }
        
        else {
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade")
            document.getElementById("tourFadeDiv")?.setAttribute("hidden", "true");
        }
     
    


        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion

        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])



    //#region Session Expiry POP-UP enable and Session Clear method
    const enableSessionExpiryPopup = () => {
        try {
            setFailedPopUp("Your session has expired. Please log in again to continue using the application.");
            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion



    const tourGuideOkClick = async () => {
        try {
            //#region Hide PopUp
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade");
            document.getElementById("tourVideo")?.setAttribute("style", "display:none");
            document.getElementById("tourVideo")?.setAttribute("aria-hidden", "true");
            document.getElementById("tourVideo")?.removeAttribute("aria-modal");
            document.getElementById("tourVideo")?.removeAttribute("role");
            document.getElementById("tourFadeDiv")?.setAttribute("hidden", "true");
            const demoVideo = document.getElementById('demoVideo') as HTMLVideoElement;
            if (demoVideo) {
                demoVideo.src = "";
            }            //#endregion
        } catch (error) {
        }
    }




    //#region  To fetch the updated sess
    const getClientSessionDetails = async () => {
        try {
            if (clientID != undefined) {
                let config = {
                    endpoint: "/ClientSession",
                    token: clientToken
                }

                let data: any = await sendRequestToNodeServicesApiTS(config)

                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //#endregiona
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {

            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
        }

    }
    //#endregion


    const onTextBoxValueChange = (e: any) => {
        try {
            let ostate = state;
            ostate[e.target.name] = e.target.value;
            setState({ ...state, [e.target.name]: e.target.value });
            onBlurValidation(e);
        } catch (error) {

        }
    }

    const onBlurValidation = (e: any) => {

        try {
            let button = document.getElementById("sourceButton");
            let invalidWorkspaceElement = document.getElementById("invalidWorkspace");
            let invalidSlackEmailElement = document.getElementById("invalidSlackEmail");
            let invalidSlackAppTokenElement = document.getElementById("invalidSlackAppToken");
            let botSlackAppTokenElement = document.getElementById("botSlackAppToken");
            let emptyWorkspaceElement = document.getElementById("emptyWorkspace");
            let emptySlackEmailElement = document.getElementById("emptySlackEmail");
            let emptySlackClientIDElement = document.getElementById("emptySlackClientID");
            let emptySlackClientSecretIDElement = document.getElementById("emptySlackClientSecretID");
            let emptySlackAppTokenElement = document.getElementById("emptySlackAppToken");

            if (e.target.name == "workSpaceURL") {
                if (state.workSpaceURL == "") {
                    invalidWorkspaceElement?.setAttribute("hidden", "true");
                    emptyWorkspaceElement?.removeAttribute("hidden");
                }
                else if (!(/^(?:https:\/\/).*(?:.slack.com)$/.test(state.workSpaceURL))) {
                    emptyWorkspaceElement?.setAttribute("hidden", "true");
                    invalidWorkspaceElement?.removeAttribute("hidden");
                }
                else {
                    invalidWorkspaceElement?.setAttribute("hidden", "true");
                    emptyWorkspaceElement?.setAttribute("hidden", "true");
                }
            }
            // else if (e.target.name == "slackEmail") {
            //     if (state.slackEmail == "") {
            //         emptySlackEmailElement?.removeAttribute("hidden");
            //         invalidSlackEmailElement?.setAttribute("hidden", "true");
            //     }
            //     else if (!((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.slackEmail)) && (state.slackEmail.toString().length <= 320))) {
            //         invalidSlackEmailElement?.removeAttribute("hidden");
            //         emptySlackEmailElement?.setAttribute("hidden", "true");
            //     }
            //     else {
            //         invalidSlackEmailElement?.setAttribute("hidden", "true");
            //         emptySlackEmailElement?.setAttribute("hidden", "true");
            //     }
            // }
            else if (e.target.name == "slackAppID") {
                if (state.slackAppID != "") {
                    emptySlackClientIDElement?.setAttribute("hidden", "true");
                }
                else {
                    emptySlackClientIDElement?.removeAttribute("hidden");
                }
            }
            else if (e.target.name == "slackAppSecretID") {
                if (state.slackAppSecretID != "") {
                    emptySlackClientSecretIDElement?.setAttribute("hidden", "true");
                }
                else {
                    emptySlackClientSecretIDElement?.removeAttribute("hidden");
                    setIsSlackSecretIDEyeOpen(false);
                }
            }
            // else if (e.target.name == "slackAppToken") {
            //     if (state.slackAppToken == "") {
            //         invalidSlackAppTokenElement?.setAttribute("hidden", "true");
            //         botSlackAppTokenElement?.setAttribute("hidden", "true");
            //         emptySlackAppTokenElement?.removeAttribute("hidden");
            //     }
            //     else if (!(/^xoxp-/.test(state.slackAppToken))) {
            //         if((/^xoxb-/.test(state.slackAppToken))){
            //             emptySlackAppTokenElement?.setAttribute("hidden", "true");
            //             invalidSlackAppTokenElement?.setAttribute("hidden", "true");
            //             botSlackAppTokenElement?.removeAttribute("hidden");
            //             setIsSlackAppTokenEyeOpen(false);
            //         }
            //         else{
            //             emptySlackAppTokenElement?.setAttribute("hidden", "true");
            //             botSlackAppTokenElement?.setAttribute("hidden", "true");
            //             invalidSlackAppTokenElement?.removeAttribute("hidden");
            //             setIsSlackAppTokenEyeOpen(false);
            //         }

            //     }
            //     else {
            //         emptySlackAppTokenElement?.setAttribute("hidden", "true");
            //         invalidSlackAppTokenElement?.setAttribute("hidden", "true");
            //         botSlackAppTokenElement?.setAttribute("hidden", "true");
            //         setIsSlackAppTokenEyeOpen(false);
            //     }
            // }

            if (state.workSpaceURL != undefined && state.workSpaceURL != "" && state.slackAppID != undefined && state.slackAppID != "" && state.slackAppSecretID != undefined && state.slackAppSecretID != "" ) {

                // let result1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.slackEmail)
                let result2 = /^(?:https:\/\/).*(?:.slack.com)$/.test(state.workSpaceURL)
                // let result3 = /^xoxp-/.test(state.slackAppToken)

                if ( result2 == true  && state.slackAppID != "" && state.slackAppSecretID != "") {
                    button?.removeAttribute("disabled");
                }
                else {
                    button?.setAttribute("disabled", "disabled");
                }
            }
            else {
                button?.setAttribute("disabled", "disabled");
            }
        } catch (error) {

        }

    }

    const slackAuthwindow = ()=>{
        try {
            loaderContent = "Please wait. We are connecting the Slack settings";
            setloaderContent(loaderContent);
          const workspaceName = state.workSpaceURL.split("//")[1];
          const slackLoginUrlTemmplate = constants.URL.REACT_APP_SLACK_AUTH_URL;
          const slackScopes:any = constants.URL.REACT_APP_SLACK_SCOPES;
          let SlackLoginUrl = slackLoginUrlTemmplate
          ? slackLoginUrlTemmplate
              .replace("{0}", encodeURIComponent(workspaceName.trim()))
              .replace("{1}", encodeURIComponent(state.slackAppID.trim()))
              .replace("{2}", encodeURIComponent(slackScopes))
              .replace("{3}", encodeURIComponent(`${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/Slack/Redirect`))
          : "";
          const width = 500;
          const height = 600;
          const left = (window.innerWidth - width) / 2;
          const top = (window.innerHeight - height) / 2;
          
          const newWindow = window.open(SlackLoginUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
          
          if (!newWindow) {
            setFailedPopUp("Popup has been blocked, enable it to authetnicate your account");
            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
          } else {
            let loaderElement = document.getElementById("slackLoader")
              loaderElement?.removeAttribute("hidden")
              let button = document.getElementById("sourceButton");
              button?.setAttribute("disabled", "disabled");
            newWindow?.postMessage("message",`${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/Slack`)
            const checkWindowClosed = setInterval(() => {
              if (newWindow.closed && !isPoupClosedAfterSuccess) {
                clearInterval(checkWindowClosed);
                loaderElement?.setAttribute("hidden", "true")
                let y = document.getElementById("sourceButton")
                y?.removeAttribute("disabled")
              }
            }, 1000); // Check every second
              
            
          }
        } catch (error) {
          let loaderElement = document.getElementById("slackLoader")
              loaderElement?.setAttribute("hidden", "true")
      
                let y = document.getElementById("sourceButton")
                y?.removeAttribute("disabled")
          setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
          //#region Show PopUp
          document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }

    useEffect(() => {
        const responseRedirect = (event : any)=>{
          if(event.data.authorization == `${constants.URL.REACT_APP_MAIN_URL_POPUP}/Settings/Slack/Redirect` && event.origin==`${constants.URL.REACT_APP_MAIN_URL_POPUP}`){
          let loaderElement = document.getElementById("slackLoader")
          loaderElement?.removeAttribute("hidden")
          isPoupClosedAfterSuccess = true;
          generateSlackToken(event);
          }
          
        };
        window.addEventListener("message", responseRedirect);
        return() => window.removeEventListener("message", responseRedirect)
      }, [state])

    const generateSlackToken = async (event: { data: any; }) => {
        try{
            let loaderElement = document.getElementById("slackLoader")
            let config = {
                url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigrationWebApp/FetchSlackToken`,
              token: clientToken,
               data: {
                clientID: clientID,
                slackAppClientID: state.slackAppID.trim(),
                slackAppClientSecretID: `${state.slackAppSecretID.trim()}`,
                slackWorkspaceURL: `${state.workSpaceURL.trim()}`,
                slackAuthCode: event.data.authorizationCode,
              }
            }
            if(adminLocal !=undefined && adminLocal !== null){
                (config.data as any).token = adminToken
            }
            let responsedata: any = await sendRequestToUpdateUsersList(config);
            if (responsedata.data.statusText == "ok" ){
                let sourceDetails = {
                    ClientID: clientID,
                    slackAppClientID: config.data.slackAppClientID,
                    slackAppClientSecretID: config.data.slackAppClientSecretID,
                    slackWorkspaceURL: config.data.slackWorkspaceURL,
                    slackUserAppToken : responsedata.data.data.accessToken,
                    slackUserID: responsedata.data.data.userID,
                }
                if(adminLocal !=undefined && adminLocal !== null){
                    (sourceDetails as any).token = adminToken
                }
                submitClick(sourceDetails);
            }
            else if(responsedata.data.data == "Insufficient Scopes Provided"){

                loaderElement?.setAttribute("hidden", "true")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                setFailedPopUp("Missing scopes! Please refer to the Slack App configuration Guide.");

                        //#region Show PopUp
                        document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
            else if(responsedata.data.data == "Invalid Client Secret"){

                loaderElement?.setAttribute("hidden", "true")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                setFailedPopUp("Invalid Client Secret ID Provided! Please refer to the Slack App configuration guide.");

                        //#region Show PopUp
                        document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
        }
        catch(error){
            let loaderElement = document.getElementById("slackLoader")
              loaderElement?.setAttribute("hidden", "true")
      
                let y = document.getElementById("sourceButton")
                y?.removeAttribute("disabled")
          setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
          //#region Show PopUp
          document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }

    const submitClick = async (sourceDetails : any) => {

        try {
            loaderContent = "Please wait. We are connecting the Slack settings.";
            setloaderContent(loaderContent);
            if (clientID != undefined && clientToken != undefined) {
                let loaderElement = document.getElementById("slackLoader")

                loaderElement?.removeAttribute("hidden")

                let button = document.getElementById("sourceButton");
                button?.setAttribute("disabled", "disabled");
                let config = {
                    endpoint: "validateSlackDetails",
                    token: objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString(),
                    data: {
                        clientID: clientID,
                        slackPlan: "Standard/Plus",
                        slackAppClientID: sourceDetails.slackAppClientID,
                        slackAppClientSecretID: sourceDetails.slackAppClientSecretID,
                        slackWorkspaceURL: sourceDetails.slackWorkspaceURL,
                        slackUserID: sourceDetails.slackUserID,
                        slackUserAppToken: sourceDetails.slackUserAppToken
                    }
                }
                
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                }
                let data: any = await sendRequestToNodeServicesApiTS(config);
                if (data.data.statusText == "ok") { 
                    loaderContent = "Please wait. We are fetching the Slack users details"
                    setloaderContent(loaderContent);
                    let config = {
                        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/GetUserProfile`,
                        token: objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString(),
                        data: {
                            clientID: clientID,
                            type: "Slack"
                        }
                    }
                    let sourceUserMailID = data.data.data.SlackUserMailID;
                    setsourceEmailId(sourceUserMailID);

                    var nodeResponse: any = await sendRequestToUpdateUsersList(config);
                    if (nodeResponse.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                        
                        setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

                        //#region Show PopUp
                        document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
                        //#endregion
                    }
                    else if (nodeResponse.data.data == "Invalid authentication token provided"){
                        loaderElement?.setAttribute("hidden", "true")
                        enableSessionExpiryPopup();
                    }
                                
                  else if(nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined)
                  {

                    setState({ ...state, slackEmail: data.data.data.SlackUserMailID, workSpaceURL: data.data.data.SlackWorkspaceURL, slackAppID: data.data.data.SlackAppClientID, slackAppSecretID: data.data.data.SlackAppClientSecretID, slackAppToken: data.data.data.SlackUserAppToken });

                    loaderElement?.setAttribute("hidden", "true")

                    let x = document.getElementById("workSpaceURL")
                    x?.setAttribute("disabled", "disabled")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                    //#region Show PopUp
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                  }
                  else{
                    setFailedPopUp("Settings Connected, but Failed To Fetch Slack Users");

                        //#region Show PopUp
                        document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                        document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
                        //#endregion
                  }

                }
                else if (data.data.data == "Invalid authentication token provided") {
                    loaderElement?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {

                    loaderElement?.setAttribute("hidden", "true")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                    setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else if (data.data.data == "Workspace Already Exists in Another client"){
                    loaderElement?.setAttribute("hidden", "true")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")                  

                    //#region Show PopUp
                    document.getElementById("sourceWorkspaceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                }
                else if (data.data.data == "Invalid Workspace URL Provided") {
                    let loaderElement = document.getElementById("slackLoader")

                    loaderElement?.removeAttribute("hidden")
                        
                    setFailedPopUp("Please provide a valid Workspace URL ");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");

                    //#endregion
                }
                else if (data.data.data == "Invalid User Role Provided" || data.data.data == "Invalid User Email Provided") {
                    let loaderElement = document.getElementById("slackLoader")

                    loaderElement?.setAttribute("hidden","true")
                        
                    setFailedPopUp("Please enter a valid user mail id");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else {

                    loaderElement?.setAttribute("hidden", "true")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                    setFailedPopUp("Please provide valid slack workspace details");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion

                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {

            let loaderElement = document.getElementById("slackLoader")
            loaderElement?.setAttribute("hidden", "true")

            let y = document.getElementById("sourceButton")
            y?.removeAttribute("disabled")

            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion

        }

    }

    const LoadData = async () => {

        try {
            debugger;
            if (clientID != undefined && clientToken != undefined) {
                let config = {
                    endpoint: "getSettingsDetails",
                    token: objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString(),
                    data: {
                        clientID: clientID,
                        type: "Slack",
                    }
                }
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                }
                let data: any = await sendRequestToNodeServicesApiTS(config)

                if (data.data.statusText == "ok" && data.data.data != undefined && data.data.data.SlackUserMailID != undefined) {

                    setState({ ...state, slackEmail: data.data.data.SlackUserMailID, workSpaceURL: data.data.data.SlackWorkspaceURL, slackAppID: data.data.data.SlackAppClientID, slackAppSecretID: data.data.data.SlackAppClientSecretID, slackAppToken: data.data.data.SlackUserAppToken });

                    let sourceUserMailID = data.data.data.SlackUserMailID;
                    setsourceEmailId(sourceUserMailID);

                    let x = document.getElementById("workSpaceURL")
                    x?.setAttribute("disabled", "disabled")

                    let y = document.getElementById("sourceButton")
                    y?.removeAttribute("disabled")

                }
                else if (data.data.data == "Invalid authentication token provided") {
                    let button = document.getElementById("sourceButton");
                    button?.removeAttribute("disabled");

                    enableSessionExpiryPopup();
                }
                else if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {

                    let button = document.getElementById("sourceButton");

                    if (state.slackEmail != undefined && state.slackEmail != "" && state.slackEmail.length <= 320 && state.workSpaceURL != undefined && state.workSpaceURL != "" && state.slackAppID != undefined && state.slackAppID != "" && state.slackAppSecretID != undefined && state.slackAppSecretID != "" && state.slackAppToken != undefined && state.slackAppToken != "") {

                        let result1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.slackEmail)
                        let result2 = /^(?:https:\/\/).*(?:.slack.com)$/.test(state.workSpaceURL)

                        if (result1 == true && result2 == true && state.slackAppID != "" && state.slackAppSecretID != "" && state.slackAppToken != "") {
                            button?.removeAttribute("disabled");
                        }
                        else {
                            button?.setAttribute("disabled", "disabled");
                        }
                    }
                    else {
                        button?.setAttribute("disabled", "disabled");
                    }

                    setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else if (data.data.statusText == "ok" && data.data.data != undefined && data.data.data.SlackUserMailID == undefined) {

                    let button = document.getElementById("sourceButton");

                    if (state.slackEmail != undefined && state.slackEmail != "" && state.slackEmail.length <= 320 && state.workSpaceURL != undefined && state.workSpaceURL != "" && state.slackAppID != undefined && state.slackAppID != "" && state.slackAppSecretID != undefined && state.slackAppSecretID != "" && state.slackAppToken != undefined && state.slackAppToken != "") {

                        let result1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.slackEmail)
                        let result2 = /^(?:https:\/\/).*(?:.slack.com)$/.test(state.workSpaceURL)

                        if (result1 == true && result2 == true && state.slackAppID != "" && state.slackAppSecretID != "" && state.slackAppToken != "") {
                            button?.removeAttribute("disabled");
                        }
                        else {
                            button?.setAttribute("disabled", "disabled");
                        }
                    }
                    else {
                        button?.setAttribute("disabled", "disabled");
                    }
                }
                else {

                    let button = document.getElementById("sourceButton");

                    if (state.slackEmail != undefined && state.slackEmail != "" && state.slackEmail.length <= 320 && state.workSpaceURL != undefined && state.workSpaceURL != "" && state.slackAppID != undefined && state.slackAppID != "" && state.slackAppSecretID != undefined && state.slackAppSecretID != "" && state.slackAppToken != undefined && state.slackAppToken != "") {

                        let result1 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.slackEmail)
                        let result2 = /^(?:https:\/\/).*(?:.slack.com)$/.test(state.workSpaceURL)

                        if (result1 == true && result2 == true && state.slackAppID != "" && state.slackAppSecretID != "" && state.slackAppToken != "") {
                            button?.removeAttribute("disabled");
                        }
                        else {
                            button?.setAttribute("disabled", "disabled");
                        }
                    }
                    else {
                        button?.setAttribute("disabled", "disabled");
                    }

                    setFailedPopUp("Please provide valid slack workspace details");

                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion

                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            let y = document.getElementById("sourceButton")
            y?.setAttribute("disabled", "disabled")

            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
        }

    }

    const proceedWithInventoryClick = () => {
        try {
            window.location.pathname = "/Inventory/HighLevel";
        } catch (error) {
        }
    }

    const proceedWithMSTeamsConnectionClick = () => {
        try {
            window.location.pathname = "/Settings/MSTeams";
        } catch (error) {
        }
    }

    const failedPopUpOkClick = () => {
        try {
            if (isSessionEpired === false) {
                //#region Hide PopUp
                document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
                document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
                document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
                document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
                document.getElementById("sourceFailedPopUp")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                //#endregion
            }
            else {
                window.location.pathname = '/Login';

            }
        } catch (error) {
        }
    }

    const downloadRefrenceGuide = async () => {
        try {
            var link = document.createElement('a');
            link.href = "/Slack App Configuration Guide.pdf";
            link.download = 'Slack App Configuration Guide.pdf';
            link.dispatchEvent(new MouseEvent('click'));

        } catch (error) {
        }
    }

    const handleWorkspacePopupRedirect = async (type:any) => {
        try {
            if(type == "cancel"){
                //#region Hide PopUp
                document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("class", "modal fade");
                document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("style", "display:none");
                document.getElementById("sourceWorkspaceFailedPopUp")?.setAttribute("aria-hidden", "true");
                document.getElementById("sourceWorkspaceFailedPopUp")?.removeAttribute("aria-modal");
                document.getElementById("sourceWorkspaceFailedPopUp")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                //#endregion
            }
            else{
                window.location.pathname = "/ContactUs";
            }
        } 
        catch (error) {
            
        }
    }

    return (
        <div className="col-md-12 col-sm-12 col-lg-12 float-start mt-4">

            {/* <!-- info container--> */}
            <div className="p-3 rounded-2 mb-4 info-background-color font-14">
                <div>
                    <img src="/Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />Connect to Slack workspace.
                    Refer<a className="underline-remove hyperlink-color font-semibold anchorAsPointer" onClick={downloadRefrenceGuide}> Slack app configuration
                    guide</a> to fetch the app details. The inventory will be taken based on the connected workspace details.
                </div>
                <div>
                    <img src="/Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />You are connected using this Mail ID: <strong>{sourceEmailId}</strong>
                </div>
            </div>

            

            {/* <!--end of info container--> */}

            {/* <!-- Text Boxes--> */}

            <div className="row pu-border-none">
                <div className="col-md-12 col-sm-12 col-lg-6 my-2 pu-form-space">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="workSpaceURL">Workspace URL <span
                        className="required">*</span></label>
                    <input type="text" className="form-control form-control-style font-14" id="workSpaceURL"
                        placeholder="Enter the Workspace URL" value={state.workSpaceURL} name="workSpaceURL" onChange={onTextBoxValueChange} />
                    <label className="float-start font-semibold font-16 required" id="invalidWorkspace" hidden>Invalid Workspace URL provided</label>
                    <label className="float-start font-semibold font-16 required" id="emptyWorkspace" hidden>Workspace URL cannot be empty</label>
                </div>

                {/* <div className="col-md-12 col-sm-12 my-2 col-lg-6">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="slackEmail">Email Address
                    </label>
                    <span
                        className="required">*</span>
                    <span>
                        <img src="/Images/info-icon.svg" className="slack-icon pe-2" alt="info-icon" data-tip="Enter primary owner or workspace owner Email Address" data-for='toolTipSlack' data-place='right' />
                        <ReactTooltip id="toolTipSlack" class='mySepecialClass' />
                        {/* <span />
                            </img> */}
                    {/*</span>
                    <input type="email" className="form-control form-control-style font-14 " id="slackEmail"
                        placeholder="Enter the Email Address" value={state.slackEmail} name="slackEmail"
                        //  onBlur={onBlurValidation} onChange={onTextBoxValueChange} onKeyDown={onBlurValidation}
                        onChange={onTextBoxValueChange} />
                    <label className="float-start font-semibold font-16 required" id="invalidSlackEmail" hidden>Invalid Email Address provided</label>
                    <label className="float-start font-semibold font-16 required" id="emptySlackEmail" hidden>Email Address cannot be empty</label>
                </div> */}
                <div className="col-md-12 col-sm-12 col-lg-6 my-2 pu-form-space padding-x">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="slackAppID">Client ID <span
                        className="required">*</span></label>
                    <input type="text" className="form-control form-control-style font-14" id="slackAppID"
                        placeholder="Enter the Client ID" value={state.slackAppID} name="slackAppID" onChange={onTextBoxValueChange} />
                    <label className="float-start font-semibold font-16 required" id="invalidSlackClientID" hidden>Invalid Client ID provided</label>
                    <label className="float-start font-semibold font-16 required" id="emptySlackClientID" hidden>Client ID cannot be empty</label>
                </div>
            </div>

            <div className="row  pu-border-none">
                {/* <div className="col-md-12 col-sm-12 col-lg-6 my-2 pu-form-space padding-x">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="slackAppID">Client ID <span
                        className="required">*</span></label>
                    <input type="text" className="form-control form-control-style font-14" id="slackAppID"
                        placeholder="Enter the Client ID" value={state.slackAppID} name="slackAppID" onChange={onTextBoxValueChange} />
                    <label className="float-start font-semibold font-16 required" id="invalidSlackClientID" hidden>Invalid Client ID provided</label>
                    <label className="float-start font-semibold font-16 required" id="emptySlackClientID" hidden>Client ID cannot be empty</label>
                </div> */}

                {isServiceAdmin == true?
                <></>
                :
                <div className="col-md-12 col-sm-12 col-lg-6 my-2 padding-x">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="slackAppSecretID">Client Secret ID <span
                        className="required">*</span></label>
                    <div className="eyeiconDiv">
                        <input type={isSlackSecretIDEyeOpen ? "text" : "password"} className="form-control form-control-style font-14 eyeiconInputElement" id="slackAppSecretID"
                            placeholder="Enter the Client Secret ID" value={state.slackAppSecretID} name="slackAppSecretID" onChange={onTextBoxValueChange} />
                        <img src={isSlackSecretIDEyeOpen == false && state.slackAppSecretID != undefined && state.slackAppSecretID != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"}
                            className={isSlackSecretIDEyeOpen == false && state.slackAppSecretID != undefined && state.slackAppSecretID != "" ? "eyeiconOpenImg anchorAsPointer" : "eyeiconCloseImg anchorAsPointer"}
                            id="slackAppSecretIDEyeOpen" alt="eye-open-icon" onClick={() => (state.slackAppSecretID != undefined && state.slackAppSecretID != "") ? setIsSlackSecretIDEyeOpen(isSlackSecretIDEyeOpen ? false : true) : null} />
                    </div>
                    <label className="float-start font-semibold font-16 required" id="invalidSlackClientSecretID" hidden>Invalid Client Secret ID provided</label>
                    <label className="float-start font-semibold font-16 required" id="emptySlackClientSecretID" hidden>Client Secret ID cannot be empty</label>
                </div>
                }
                
            </div>

            {/* <div className="row pu-border-none ">
                <div className="col-md-12 col-sm-12 col-lg-6 padding-x">
                    <label className="float-start font-semibold pb-3 font-16" htmlFor="slackAppToken">App Token <span
                        className="required">*</span></label>
                    <div className="eyeiconDiv">
                        <input type={isSlackAppTokenEyeOpen ? "text" : "password"} className="form-control form-control-style font-14 eyeiconInputElement" id="slackAppToken"
                            placeholder="Enter the primary owner or workspace owner App Token" name="slackAppToken" value={state.slackAppToken} onChange={onTextBoxValueChange} />
                        <img src={isSlackAppTokenEyeOpen == false && state.slackAppToken != undefined && state.slackAppToken != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"}
                            className={isSlackAppTokenEyeOpen == false && state.slackAppToken != undefined && state.slackAppToken != "" ? "eyeiconOpenImg anchorAsPointer" : "eyeiconCloseImg anchorAsPointer"}
                            id="slackAppTokenEyeOpen" alt="eye-open-icon" onClick={() => (state.slackAppToken != undefined && state.slackAppToken != "") ? setIsSlackAppTokenEyeOpen(isSlackAppTokenEyeOpen ? false : true) : null} />
                    </div>
                    <label className="float-start font-semibold font-16 required" id="invalidSlackAppToken" hidden>Please provide valid App token credentials.</label>
                    <label className="float-start font-semibold font-16 required" id="botSlackAppToken" hidden>Provided Bot token, Kindly enter the Valid token</label>
                    <label className="float-start font-semibold font-16 required" id="emptySlackAppToken" hidden>App Token cannot be empty</label>
                </div>
            </div> */}

            {/* <!-- end of Text Boxes--> */}

            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/success.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-3 px-4">You have successfully connected your Slack workspace.<br />You can now proceed with connecting your target MS Teams.</p>
                            </div>
                            <div className="text-center w-100 mt-3 d-inline-block">
                                {/* <button type="button" className="btn font-semibold font-14 me-2 settingsSuccessPopUp_Secondary mb-2 goToInventory" data-bs-dismiss="modal" onClick={proceedWithInventoryClick}>Go Inventory</button> */}
                                {/* <a href="/Inventory/HighLevel" className="font-semibold font-14 me-2 settingsSuccessPopUp_Secondary mb-2 goToInventorySlack" data-bs-dismiss="modal">Go to Inventory</a> */}
                                <button type="button" className="btn-primary-theme mb-2 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={proceedWithMSTeamsConnectionClick}>Connect MS Teams</button>
                            </div>
                            {/* <div className="text-center success-content w-100 mt-3">
                                <p className="px-4">You can now take Inventory of Slack Workspace or proceed with connecting MS Teams account!</p>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="tourVideo" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true" >
                <div className="modal-dialog tourGuide modal-lg">
                    <div className="modal-content demo-vedio">
                        <div className="modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label mb-3 w-100">
                                            <span className="name-style font-24 font-semibold">Welcome,</span><span className="name-style2 font-24 font-semibold"> {clientName}</span></label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">To get a feel of the
                                            place, take our guided tour, where we'll get you set up and <br />
                                            show you how to migrate your Slack Workspace into MS Teams</label>
                                    </div>
                                    <div className="w-100 float-start text-center">
                                        <video width="100%" height="315" className='videoTagMNC' id="demovideo" controls controlsList="nodownload" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mb-4">
                            <button type="button" className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={tourGuideOkClick}>Skip</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="sourceFailedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}

            {/* <!--Alert Workspace Pop-up--> */}
            <div className="modal fade" id="sourceWorkspaceFailedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">Your Slack workspace has already been connected by <br /> another user. Please reach out to AVAMIGRATRON</p>
                            </div>
                            <div className="text-center w-100 mt-3 d-inline-block">
                                {/* <button type="button" className="btn font-semibold font-14 me-2 settingsSuccessPopUp_Secondary mb-2 goToInventory" data-bs-dismiss="modal" onClick={proceedWithInventoryClick}>Go Inventory</button> */}
                                <button type="button" className="btn-cancel-border me-2 btn-scondary-custom btn-admin-second mb-2 common-btn-class btn" data-bs-dismiss="modal" onClick={() => handleWorkspacePopupRedirect('cancel')}>Cancel</button>
                                <button type="button" className="btn-primary-theme mb-2 common-btn-class btn btn-ok " data-bs-dismiss="modal" onClick={(e) => handleWorkspacePopupRedirect('support')}>Contact Support</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div className="overlay settingLoaderMainDiv" id='slackLoader' hidden={true}>
                <div className="settingLoaderSubDiv">
                    <div className="spinner-border loaderImg text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p className="text-center">{loaderContent}</p>
                </div>
            </div>
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                <div className=" mx-3 d-flex justify-content-end">
                    <button type="button" className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular" id="sourceButton" onClick={slackAuthwindow} >Connect Slack</button>
                </div>
            </footer>

        </div>
    )
}
