import { useState, useEffect } from "react";
import { gridLoad } from "../../Services/ProfileServices";
import AccountInformation from "./AccountInformation";
import PasswordReset from "./PasswordReset";
import InvoiceHistory from "./InvoiceHistory";
import ManageSecondaryAdmins from "./ManageSecondaryAdmins";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";


export default function ProfileUpdate() {
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let isUserBasedAccount: any;
    let isPrimaryAdmin: any;
    let isSSOAccount: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = parseInt(objClient.CID);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        isPrimaryAdmin = objClient.isPrimaryAdmin;
        isSSOAccount = objClient.isSSOAccount;
        isUserBasedAccount = objClient.isUserBasedLicense;
    }

    let formObj = { AvailableChannelLicense: 0, PurchasedChannelMigrationLicense: 0, AvaliableGroupChatLicense: 0, PurchasedGroupChatMigrationLicense: 0,AvaliableHTMLChatLicense: 0, PurchasedHTMLChatMigrationLicense: 0, AvailableUserLicense: 0, PurchasedUserLicense: 0 }
    let [formState, setFormState] = useState(formObj);
    // const [clientID, setclientID] = useState(1);
    let activeTab = isPrimaryAdmin ? "AccountInfo" : isSSOAccount ? "InvoiceHistory" : "PasswordReset";
    const [tabType, setTabType] = useState(activeTab)
    let [alertMessage, setAlertMessage] = useState("")
    let [expiryDate, setExpiryDate] = useState('');

    useEffect(() => {
        loadAccountInfo()
    }, [])

    const tabChange = (e: any) => {
        try {
            setTabType(e.target.id);

            document.getElementById("AccountInfo")?.classList.remove("active")
            document.getElementById("PasswordReset")?.classList.remove("active")
            document.getElementById("CardInformation")?.classList.remove("active")
            document.getElementById("InvoiceHistory")?.classList.remove("active")
            document.getElementById("ManageSecondaryAdmins")?.classList.remove("active")
            document.getElementById(e.target.id)?.classList.add("active")
        }
        catch (error: any) {
            //console.log(error);

        }
    }
    const loadAccountInfo = async () => {
        try {
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientLicense`,
                    data: {
                        "clientID": clientID
                    },
                })
                let data: any = await gridLoad(config);
                //console.log(data);
                if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }
                else {
                    updateState(data.data.data);
                    const parsedDate = new Date(data.data.data.ValidTillDate);
                    const formattedDate = new Intl.DateTimeFormat('en-US', {
                        month: 'short', // 'short' or 'long' for month
                        day: 'numeric',
                        year: 'numeric',
                    }).format(parsedDate);
                    setExpiryDate(formattedDate);
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const updateState = async (licenseDetails: any) => {
        try {
            formState = { AvailableChannelLicense: licenseDetails.PurchasedChannelMigrationLicense - licenseDetails.UsedChannelMigrationLicense, PurchasedChannelMigrationLicense: licenseDetails.PurchasedChannelMigrationLicense, 
                AvaliableGroupChatLicense: licenseDetails.PurchasedGroupChatMigrationLicense - licenseDetails.UsedGroupChatMigrationLicense, PurchasedGroupChatMigrationLicense: licenseDetails.PurchasedGroupChatMigrationLicense,
                AvaliableHTMLChatLicense: licenseDetails.PurchasedChatHtmlMigrationLicense - licenseDetails.UsedChatHtmlMigrationCount, PurchasedHTMLChatMigrationLicense: licenseDetails.PurchasedChatHtmlMigrationLicense,
                AvailableUserLicense: licenseDetails.PurchasedUserLicenseCount - licenseDetails.UsedUserLicenseCount, PurchasedUserLicense: licenseDetails.PurchasedUserLicenseCount
            }
            setFormState(formState)
            let activeTab = isPrimaryAdmin ? "AccountInfo" : isSSOAccount ? "InvoiceHistory" : "PasswordReset";
            setTabType(activeTab)
        }
        catch (error: any) {
            //console.log(error);

        }
    }

    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            //#endregion
        } catch (error) {
            //console.log("Issue Faced");
        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center mt-4 my-5 card-container mx-0" id="profileupdate">
                <div className="col-md-12 mt-4 col-lg-11 col-sm-12">
                    <h4 className="font-26 font-bold mb-4 ">
                        Profile Update
                    </h4>
                    <h4 className="font-18 font-semibold mb-4" >Available License</h4>
                    {isUserBasedAccount ?
                        <h5 className="font-16 font-semibold mb-3">User-based </h5>
                        :
                        <h5 className="font-16 font-semibold mb-3">Chat & Channel </h5>
                    }
                    {isUserBasedAccount ?
                        <div className="d-flex mb-4 flex-md-col">
                            <label className="font-14 font-regular me-5 pb-2 d-sm-block">License Count <span className="font-20 font-bold ms-2 theme-color">{formState.AvailableUserLicense.toLocaleString("en-US")}/{formState.PurchasedUserLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block ">Plan Expires On<span className="font-20 font-bold ms-2 theme-color"><span>{expiryDate}</span></span></label>
                        </div>
                        :
                        <div className="d-flex mb-4 flex-md-col">
                            <label className="font-14 font-regular me-5 pb-2 d-sm-block">Channel Migration <span className="font-20 font-bold ms-2 theme-color">{formState.AvailableChannelLicense.toLocaleString("en-US")}/{formState.PurchasedChannelMigrationLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block me-5 pb-2">Conversation Migration <span className="font-20 font-bold ms-2 theme-color">{formState.AvaliableGroupChatLicense.toLocaleString("en-US")}/{formState.PurchasedGroupChatMigrationLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block me-5 pb-2">Conversation Migration as HTML <span className="font-20 font-bold ms-2 theme-color">{formState.AvaliableHTMLChatLicense.toLocaleString("en-US")}/{formState.PurchasedHTMLChatMigrationLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block ">Plan Expires On<span className="font-20 font-bold ms-2 theme-color"><span>{expiryDate}</span></span></label>
                        </div>
                    }

                    <div className=" mb-4">
                        {isPrimaryAdmin ?
                            <ul className="nav customtabProfile" role="tablist">
                                <li className="nav-item ">
                                    <a className="common-navTab-profile px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" id="AccountInfo" onClick={(e) => tabChange(e)}>Account Information</a>
                                </li>
                                {isSSOAccount ? '' :
                                    <li className="nav-item">
                                        <a className="common-navTab-profile px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer" id="PasswordReset" onClick={(e) => tabChange(e)}>Password Reset</a>
                                    </li>
                                }
                                <li className="nav-item">
                                    <a className="common-navTab-profile px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer" id="ManageSecondaryAdmins" onClick={(e) => tabChange(e)}>Manage Secondary Admins</a>
                                </li>
                                <li className="nav-item">
                                    <a className="common-navTab-profile px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer" id="InvoiceHistory" onClick={(e) => tabChange(e)}>Invoice History</a>
                                </li>
                            </ul>
                            :
                            <ul className="nav customtabProfile" role="tablist">
                                {isSSOAccount ? '' :
                                    <li className="nav-item">
                                        <a className={isSSOAccount ? "common-navTab-profile px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer"
                                            : "common-navTab-profile px-5 py-2 nav-link active nav-tab-custom font-16 font-bold anchorAsPointer"}
                                            id="PasswordReset" onClick={(e) => tabChange(e)}>Password Reset</a>
                                    </li>
                                }
                                <li className="nav-item">
                                    <a className={isSSOAccount ? "common-navTab-profile px-5 py-2 nav-link active nav-tab-custom font-16 font-bold anchorAsPointer"
                                        : "common-navTab-profile px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer"}
                                        id="InvoiceHistory" onClick={(e) => tabChange(e)}>Invoice History</a>
                                </li>
                            </ul>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="row justify-content-center">
                                <div className="tab-content">
                                    {tabType == "AccountInfo"
                                        ? <AccountInformation />
                                        : tabType == "PasswordReset"
                                            ? <PasswordReset />
                                            : tabType == "ManageSecondaryAdmins"
                                                ? <ManageSecondaryAdmins />
                                                : <InvoiceHistory />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Nav tabs starts here --> */}

                {/* <!-- Nav tabs ends here --> */}
                {/* <!-- Tab panes starts --> */}

                {/* <!-- Tab panes endss --> */}
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>


    )
}