import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { InventoryMasterComponent } from './MasterComponent';
import { SlackPreMigration } from './SlackPreMigration';
import { ChannelGrid } from './ChannelGrid'
import { MembersGrid } from './MembersGrid'
import { UsersList } from './Userslist'
import { UsersGroup } from './Usergrouplist'
import { ThirdPartyApps } from './Thirdpartyappslist'
import { UserGroupMembers } from './Usergroupuserslist'
import { DMGrid } from './DMGrid'
import { MMGrid } from './MMGrid'
import { InventoryDetailsInProgress } from './InventoryDetail'


function InventoryRouting() {

  useEffect(() => {
    document.getElementsByTagName('video')[0]?.setAttribute("src", "");
  }, [])

  const helpIconClick = async () => {
    try {
      document.getElementsByTagName('video')[0]?.setAttribute("src", "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Inventory.mp4?sp=r&st=2024-06-04T17:04:55Z&se=2024-12-31T01:04:55Z&spr=https&sv=2022-11-02&sr=b&sig=AS%2FL9fvjgue95GDkjjPUfMkd6lAbpm01q0uLocoRCdI%3D");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
    } catch (error) {
    }
  }
  const helpPopUpCloseClick = async () => {
    try {
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
      document.getElementsByTagName('video')[0]?.setAttribute("src", "");
    } catch (error) {
    }
  }

  return (
    <Router>

      {/*fixed section*/}
      <div className="icon-container" onClick={helpIconClick}>
        <label className="custom-icon-style" id="hidden-section">
          <img className="fixed-icon" src="/Images/help-web-button.svg" alt="help-icon" />
          <img className="expand-icon expand-content" src="/Images/help-web-button.svg" alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
        </label>
      </div>
      {/*fixed section*/}

      {/*Items in cart popup*/}
      <div className="modal fade" id="helpPopUpDiv" tabIndex={-1}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-radius">
            <div className="help-modal-body pt-1">
              <div className="col-md-12 col-sm-12 col-lg-12 mb-4 mt-4">
                <div className="row">
                  <div className="w-100 float-start text-center mb-3">
                    <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help
                      ?</label>
                    <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to
                      learn how to take Inventory of Slack Workspace</label>
                  </div>
                  <div className="w-100 float-start text-center">
                    <video width="100%" className='videoTag' height="315" controls controlsList="nodownload" />                 
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-lg-12 d-flex justify-content-end mb-4">
                <button type="button" className="btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4 btn-scondary-custom  btn-cancel-border common-btn-class" data-bs-dismiss="modal" onClick={helpPopUpCloseClick}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Items in cart popup*/}

      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

      <Switch>
        <Route exact path="/Inventory/HighLevel" component={InventoryMasterComponent} />
        <Route exact path="/Inventory/Detailed" component={InventoryMasterComponent} />
        <Route exact path="/Inventory/UserVerification" component={SlackPreMigration} />
        <Route exact path="/Inventory/Users" component={UsersList} />
        <Route exact path="/Inventory/Usergroups" component={UsersGroup} />
        <Route exact path="/Inventory/UserGroup/UGMembers" component={UserGroupMembers} />
        <Route exact path="/Inventory/ThirdPartyApp" component={ThirdPartyApps} />
        <Route exact path="/Inventory/Channel" component={ChannelGrid} />
        <Route exact path="/Inventory/Channel/Members" component={MembersGrid} />
        <Route exact path="/Inventory/DM" component={DMGrid} />
        <Route exact path="/Inventory/MM" component={MMGrid} />
        <Route exact path="/Inventory/InProgress" component={InventoryDetailsInProgress} />
        <Route exact path="/">
          <Redirect to="/Inventory/HighLevel"></Redirect>
        </Route>
        <Route exact path="/Inventory">
          <Redirect to="/Inventory/HighLevel"></Redirect>
        </Route>
      </Switch>
    </Router>
  );
}

export default InventoryRouting;
