import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { MasterComponent } from './components/MasterComponent';
import RegistrationRouting from  './components/Registration/RegistrationRouter'
import ChatMigrationRouting  from './components/GroupChatMigration/GroupChatRouter'
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import { PageNotFound } from './components/PageNotFound';
import { sendRequestToNodeServicesApiTS, serverDetail } from "./Services/MainContainer";
import { ServerDown } from './components/ServerDown';
import { useEffect, useState } from 'react';
// import { useHelpVideo } from './Common/HelpVideo';
// import { RouteComponentProps } from 'react-router-dom';


import { SlackUserVerification } from './components/PreMigration/SlackWebApp';
import { MSTeamCodeTokenGen } from './components/PreMigration/MSTeamsWebApp';
import AdminRouting from './components/Admin/AdminRouter'
import ProfileRouter from './components/Profile/ProfileRotuer'




export function App(): JSX.Element {


  // const { currentVideo, setVideoForSection } = useHelpVideo();

  // const helpIconClick = () => {
  //   document.getElementById("fadeDiv")?.removeAttribute("hidden");
  //   document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
  // };

  // const helpPopUpCloseClick = () => {
  //   document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
  //   document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
  // };



  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

  let c_local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;

  let a_local: any = localStorage.getItem("aid_t");
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let objAdmin: any;

  let [serverDown, setServerDown] = useState<string>("");

  useEffect(()=>{
    getServerStatus()
  },[])

  async function getServerStatus(){
    try{
      let config = {
        endpoint: '/admin/serverDown',
        data:{"type":"getServer",}
      }
      let data: any = await serverDetail(config)
      if(data.data.statusCode==200){
        if(data.data.data.serverStatus=='true' && objAdmin == undefined){
          localStorage.removeItem("cid_t");
        }
        setServerDown(data.data.data.serverStatus)
      }
    }
    catch(error){

    }
  }

  if (c_local != undefined) {
    let c_byteValue = objCryptoJS.AES.decrypt(c_local, c_t_Key);
    var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(c_decryptedValue);
  }

  if (a_local != undefined) {
    let a_byteValue = objCryptoJS.AES.decrypt(a_local, a_t_Key);
    var a_decryptedValue = a_byteValue.toString(objCryptoJS.enc.Utf8);
    objAdmin = jwt(a_decryptedValue);
  }

  if (window.location.pathname.toString().toLowerCase() == "/admin") {
    localStorage.removeItem("cid_t");
  }
  if (window.location.pathname.toString().toLowerCase().includes("/inventory")) {
    document.title = "AVAMIGRATRON - Inventory"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/settings")) {
    document.title = "AVAMIGRATRON - Settings"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/usermapping")) {
    document.title = "AVAMIGRATRON - User Mapping"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/channelmigration")) {
    document.title = "AVAMIGRATRON - Channel Migration"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/conversation")||window.location.pathname.toString().toLowerCase().includes("/conversation/au/userverification") || window.location.pathname.toString().toLowerCase().includes("/conversation/chatMapping")
    || window.location.pathname.toString().toLowerCase().includes("/conversation/messageMigration") || window.location.pathname.toString().toLowerCase().includes("/conversation/scheduleMigration")) {
    document.title = "AVAMIGRATRON - Conversation Migration"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/membermigration")) {
    document.title = "AVAMIGRATRON - Member Migration"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/report")) {
    document.title = "AVAMIGRATRON - Reports"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/admin")) {
    document.title = "AVAMIGRATRON - Admin"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/contactus")) {
    document.title = "AVAMIGRATRON - Contact Us"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/upgradeplan")) {
    document.title = "AVAMIGRATRON - Upgrade Plan"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/chooseplan")) {
    document.title = "AVAMIGRATRON - Choose Plan"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/profileupdate")) {
    document.title = "AVAMIGRATRON - Profile Update"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/login")) {
    document.title = "AVAMIGRATRON - Login"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/registration")) {
    document.title = "AVAMIGRATRON - Registration"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/verifyemail")) {
    document.title = "AVAMIGRATRON - Verify Email"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/slackuserverification")) {
    document.title = "AVAMIGRATRON - Slack UserVerification"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/msteamsuserverification")) {
    document.title = "AVAMIGRATRON - MSTeams UserVerification"
  }
  else if (window.location.pathname.toString().toLowerCase().includes("/licensedusers")) {
    document.title = "AVAMIGRATRON - LicensedUsers"
  }

  const getClientSessionDetails = async () => {
    try {
      if (objClient.CMail != undefined) {
        let config = {
          endpoint: "/ClientSession",
          token: objCryptoJS.AES.encrypt(c_decryptedValue, r_n_Key).toString()
        }

        let data: any = await sendRequestToNodeServicesApiTS(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          // var nodeToken = dePass.toString(objCryptoJS.enc.Utf8);
          let nodeSession: any = jwt(dePass);
          if (nodeSession.isClientExpired == true) {
            window.location.pathname = "/UpgradePlan";
          }
        }
        else {
        }
      }
      else {
      }

    } catch (error) {

    }
  }

  if (window.location.pathname.toString().toLowerCase().includes("/channelmigration") || window.location.pathname.toString().toLowerCase().includes("/inventory") || window.location.pathname.toString().toLowerCase().includes("/report") ||
    window.location.pathname.toString().toLowerCase().includes("/conversation") || window.location.pathname.toString().toLowerCase().includes("/membermigration") || window.location.pathname.toString().toLowerCase().includes("/usermapping")
    || window.location.pathname.toString().toLowerCase().includes("/settings/slack")) {
    getClientSessionDetails();
  }

  const navigateToLogin = async () => {
    try {
      window.location.pathname = "/Login";
    } catch (error) {
    }
  }

  const navigateToSettings = async () => {
    try {
      window.location.pathname = '/Settings'
    } catch (error) {

    }
  }



  const navigateToServerDown = async () => {
    try {
      window.location.pathname = '/ServerDown';
    } catch (error) {

    }
  }

  return (
    <Router>

{


     (serverDown === "true" && objAdmin == undefined && window.location.href.includes("/Admin") != true) ?
        <Switch>
            <Route exact path="/ServerDown" component={ServerDown} ></Route>
            <Route path="*" >
              <Redirect to="/ServerDown"></Redirect>
              <Route path="*" render={() => navigateToServerDown()}></Route>
            </Route>
          </Switch>
          :
          ((objAdmin != undefined || objClient != undefined || window.location.pathname.toString().toLowerCase() == "/login" || window.location.pathname.toString().toLowerCase() == "/registration" || window.location.pathname.toString().toLowerCase() == "/admin/login" || window.location.pathname.toString().toLowerCase() == "/signup") && window.location.pathname.toString().toLowerCase() != "/successpage") ? <Switch>

            {/* Registration Module route Starts Here */}
            <Route exact path="/Registration"  component={RegistrationRouting}></Route>
            <Route exact path="/SignUp"  component={RegistrationRouting}></Route>
            <Route exact path="/VerifyEmail"  component={RegistrationRouting}></Route>
            <Route exact path="/Login"  component={RegistrationRouting}></Route>
            <Route exact path="/AccessDenied"  component={RegistrationRouting}></Route>
            <Route exact path="/Admin/Login"  component={AdminRouting}></Route>
            {/* Registration Module route Ends Here */}

            {/* Other Routings Start */}

            <Route exact path="/VerifyUser/SlackUserVerification"  component={MasterComponent} ></Route>
            <Route exact path="/VerifyUser/MSTeamsUserVerification" component={MasterComponent}></Route>

            {/* Settings Module route Starts Here */}
            <Route exact path="/Settings/Slack" component={MasterComponent} />
            <Route exact path="/Settings/MSTeams" component={MasterComponent} />
            <Route exact path="/Settings/MSTeams/Redirect" component={MasterComponent} />
            <Route exact path="/Settings/Slack/Redirect" component={MasterComponent} />
            <Route exact path="/Settings" component={MasterComponent} />
            {/* Settings Module route Ends Here */}

            {/* Licensed User Module route Starts Here */}
            <Route exact path="/LicensedUsers" component={MasterComponent} />
            {/* Licensed User Module route Ends Here */}

            {/* Inventory Module route Starts Here */}

            <Route exact path="/Inventory/HighLevel"  component={MasterComponent} />
            <Route exact path="/Inventory/UserVerification" component={MasterComponent} />
            <Route exact path="/Inventory/InProgress" component={MasterComponent} />
            <Route exact path="/Inventory/Users" component={MasterComponent} />
            <Route exact path="/Inventory/Usergroups" component={MasterComponent} />
            <Route exact path="/Inventory/UserGroup/UGMembers" component={MasterComponent} />
            <Route exact path="/Inventory/ThirdPartyApp" component={MasterComponent} />
            <Route exact path="/Inventory/Channel" component={MasterComponent} />
            <Route exact path="/Inventory/Channel/Members" component={MasterComponent} />
            <Route exact path="/Inventory/DM" component={MasterComponent} />
            <Route exact path="/Inventory/MM" component={MasterComponent} />
            <Route exact path="/Inventory" component={MasterComponent} />
            {/* Inventory Module route Ends Here */}

            {/* Report Module route Starts Here */}
            <Route exact path="/Report" component={MasterComponent} />
            <Route exact path="/ReportDetails" component={MasterComponent} />
            {/* Report Module route Ends Here */}

            {/* User Mapping Module route Starts Here */}
            <Route exact path="/UserMapping" component={MasterComponent} />
            {/* User Mapping Module route Ends Here */}

            {/* Channel Migration Module route Starts Here */}
            <Route exact path="/ChannelMigration" component={MasterComponent} />
            <Route exact path="/ChannelMigration/AU/TeamChannelMapping" component={MasterComponent} />
            <Route exact path="/ChannelMigration/AU/ConversationMigration" component={MasterComponent} />
            <Route exact path="/ChannelMigration/AU/ConversationMigration/ServiceApps" component={MasterComponent} />
            <Route exact path="/ChannelMigration/AU/TeamCompletion" component={MasterComponent} />
            <Route exact path="/ChannelMigration/AU/TeamChannelCompletion" component={MasterComponent} />
            <Route exact path="/ChannelMigration/SA/TeamChannelMapping" component={MasterComponent} />
            <Route exact path="/ChannelMigration/SA/ConversationMigration" component={MasterComponent} />
            <Route exact path="/ChannelMigration/SA/ConversationMigration/ServiceAccounts" component={MasterComponent} />
            {/* Channel Mapping Module route Ends Here */}

            {/* Conversation Migration Module route Starts Here */}
            <Route exact path="/Conversation" component={MasterComponent} />
            <Route exact path="/Conversation/AU/UserVerification" component={MasterComponent} />
            <Route exact path="/Conversation/AU/ChatMapping" component={MasterComponent} />
            <Route exact path="/Conversation/AU/MessageMigration" component={MasterComponent} />
            <Route exact path="/Conversation/SA/ChatMapping" component={MasterComponent} />
            <Route exact path="/Conversation/SA/MessageMigration" component={MasterComponent} />
            <Route exact path="/Conversation/ScheduleMigration" component={MasterComponent} />
            <Route exact path="/Conversation/SA/ConversationMigration/ServiceAccounts" component={MasterComponent} />
            {/* Conversation Migration Module route Ends Here */}

            {/* Conversation Migration Module route Starts Here */}
            <Route exact path="/Conversation" component={MasterComponent} />
            <Route exact path="/Conversation/AU/UserVerification" component={MasterComponent} />
            <Route exact path="/Conversation/AU/ChatMapping" component={MasterComponent} />
            <Route exact path="/Conversation/AU/ConversationMigration" component={MasterComponent} />
            <Route exact path="/Conversation/SA/ChatMapping" component={MasterComponent} />
            <Route exact path="/Conversation/SA/ConversationMigration" component={MasterComponent} />
            <Route exact path="/Conversation/SA/ConversationMigration/ServiceAccounts" component={MasterComponent} />
            <Route exact path="/Conversation/ChatHtmlFolderCreation" component={MasterComponent} />
            <Route exact path="/Conversation/ChatHtmlMigration" component={MasterComponent} />

            {/* Conversation Migration Module route Ends Here */}

            {/* Member Migration Module route Starts Here */}
            <Route exact path="/MemberMigration" component={MasterComponent} />
            <Route exact path="/MemberMigration/TeamMembers" component={MasterComponent} />
            <Route exact path="/MemberMigration/ChannelMembers" component={MasterComponent} />
            {/* Member Migration Module route Ends Here */}

            {/* ProfileUpdate Contact-Us Choose/Upgrade-Plan Module route Starts Here */}
            <Route exact path="/ChoosePlan" component={MasterComponent} />
            <Route exact path="/UpgradePlan" component={MasterComponent} />
            <Route exact path="/ContactUs" component={MasterComponent} />
            <Route exact path="/ProfileUpdate" component={MasterComponent} />
            {/* Registration Module route Ends Here */}

            {/* Admin Module route Starts Here */}
            <Route exact path="/Admin/ManageClients" component={MasterComponent} />
            <Route exact path="/Admin/ManageClients/NewClient" component={MasterComponent} />
            <Route exact path="/Admin/ManageClients/EditClient" component={MasterComponent} />

            <Route exact path="/Admin">
              <Redirect to="/Admin/ManageClients" ></Redirect>
            </Route>
            {/* Admin Module route Ends Here */}

            <Route path="*">
              <Redirect to="/Settings/Slack"></Redirect>
              <Route path="*" render={() => navigateToSettings()}></Route>
            </Route>

            {/* Other Routings End */}


          </Switch>
            :
            (serverDown == "false" || window.location.href.includes("/Admin") == true)?

            <Switch>
              <Route exact path="/SuccessPage" component={ProfileRouter}>

              </Route>
              <Route exact path="/VerifyUser/SlackUserVerification" component={SlackUserVerification}></Route>
              <Route exact path="/VerifyUser/MSTeamsUserVerification" component={MSTeamCodeTokenGen}></Route>
              <Route path="*" render={() => navigateToLogin()}>
              </Route>
            </Switch>
            :null
}
    </Router>
  );
}

export default App;
