import { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { callBackEnd } from "../../Services/GroupChatMigrationServices";
import constants from '../../Common/Constants';
import WizardNavigation from './WizardSA';
import BackNavigation from './BackNavigation'
import { ExportToCsv } from 'export-to-csv';
import Enumerable, { create, from } from "node-enumerable";
import ReactTooltip from "react-tooltip";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode';
import { debug } from "console";


export default function GroupChatCreationAndMappingSA() {

  let [clientID, setClientID] = useState(0);
  let [clientToken, setClientToken] = useState("");
  let GridLoadarray: any[] = [];
  let [gridData, setGridData] = useState(GridLoadarray);
  const [SlackworkspaceID, setSlackworkspaceID] = useState("");
  const [data, setData] = useState([]);
  let [isExport, setIsExport] = useState(0);
  let [isMappingExport, setIsMappingExport] = useState(0);


  const [selectAllCount, setSelectAllCount] = useState(0);
  const [arrSelectedValues, setArrSelectedValues] = useState<any>([]);
  const [selectAllShowHide, setSelectAllShowHide] = useState(false)
  const [isFullSelect, setIsFullSelect] = useState(false);
  const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
  const [operation, setOperation ] = useState("")
  const [ disabledCount, setDisabledCount] = useState(0);
  const [ validTotalCount, setValidTotalCount] = useState(0);

  const [isArr, setIsArr] = useState(0);
  let anyType: any;
  let [csvData, setCsvData] = useState([{ "SlackChatName": "", "SourceChatName": "", "SourceChatID": "", "MSTeamChatName": "", "TargetChatName": "", "SlackChatID": "", "IsConversationMigrated": anyType }]);
  let [MMType, setMMType] = useState("MM");
  let [DMType, setDMType] = useState("DM");
  let [totalItemsCount, setTotalItemsCount] = useState(0);
  let [searchValue, setSearchValue] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  let [columnOrder, setColumnOrder] = useState("asc");
  let [columnName, setColumnName] = useState("SourceChatName");
  const [sorthide, setsorthide] = useState("");
  let [indexPerPage, setIndexPerPage] = useState(1);
  let [countPerPage, setCoutPerPage] = useState(10);
  const [subVal, setsubVal] = useState(false);
  const [createMap, setcreateMap] = useState(false);
  const [SourceTargetDetails, setSourceTargetDetails] = useState([]);
  const [ChatDetails, setChatDetails] = useState([]);
  const [file1, setfile1] = useState(false);
  const [file2, setfile2] = useState(false);
  let [isInitial, setIsInitial] = useState(1);
  let [isSortApplied, setIsSortApplied] = useState(false);
  let [statusText, setStatusText] = useState("Please provide valid template!");
  let [isCreateMSTeamsAlertPopup, setIsCreateMSTeamsAlertPopup] = useState(false);
  let [isTargetSettingsConnected, setIsTargetSettingsConnected] = useState(0);
  let [isSourceSettingsConnected, setIsSourceSettingsConnected] = useState(0);
  let [isInventoryTaken, setIsInventoryTaken] = useState(0);
  let [loaderContent, setLoaderContent] = useState("");
  let [nonGifLoaderContent, setNonGifLoaderContent] = useState("");
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let [adminToken, setAdminToken] = useState("");
  let InActiveTimeout: NodeJS.Timeout;
  let local: any = localStorage.getItem("cid_t");
  let adminTok : any = localStorage.getItem("aid_t");
  let [isInvalidUsersFound, setisInvalidUsersFound] = useState(false);
  const inactivityTimer = Number(process.env.REACT_APP_TABIDLESCENARIO);
  let [exportChatDetails, setExportChatDetails] = useState([]);
  let [exportInvalidUserDetails, setExportInvalidUserDetails] = useState<{ 'Invalid Users': any; }[]>([]);
  let [isAdmin, setIsAdmin] = useState(false);
  let [isEnableDeleteChecked, setIsEnableDeleteChecked] = useState(false);
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  let objAdmin : any;
  var decryptedValue: any;
  var decryptedAdminValue : any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  const a_t_Key : any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;

  let selectedCheckbox: any = [];
  let a: any;
  let arrMailId: any[] = [];
  let d: any = [];
  let s: any[] = [];
  let y: any[] = [];

  let isDirectCreation = true

  useEffect(() => {

    resetSessionTimer();

    let client_Token: any;
    if (local != undefined) {
      let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
      decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
      objClient = jwt(decryptedValue);
      SessionEpiredDateTime = objClient.CreatedAt;
      clientID = Math.random();
      setClientID(clientID);
      clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
      setClientToken(clientToken);
      window.addEventListener('keydown', resetSessionTimer);
      window.addEventListener('click', resetSessionTimer);
      //#endregion

    }
    if (adminTok!=undefined){
      let adminByteValue = objCryptoJS.AES.decrypt(adminTok, a_t_Key);
      decryptedAdminValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
      objAdmin = jwt(decryptedAdminValue);
      if(objAdmin!=undefined && objAdmin!=null){
        isAdmin = true;
        setIsAdmin(isAdmin);
        adminToken = objCryptoJS.AES.encrypt(decryptedAdminValue, r_n_Key).toString();
        setAdminToken(adminToken);
      }

    }

    // ReactTooltip.rebuild()

    let removeHeight: any = document.getElementById("MainContainerMFE");
    removeHeight.classList.remove("container-bg-withFooter");
    removeHeight.classList.remove("container-bg-withoutFooter-chat");
    removeHeight.classList.remove("container-bg-withFooter-chat");
    removeHeight.classList.add("container-bg-withFooter-chat");

      loadGridPage(operation);
    
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnOrder, columnName, countPerPage]);

  // useEffect to handle Select All
  useEffect(() => {
    let selectAllelement:any = document.getElementById("selectAllCheckBox")
    selectAllelement.disabled = false
    let allGridDataDisabled = false
    
      // To Handle the Select All through Individual Checkboxes
      if (gridData.length != 0 && gridData[0] != "No Records Found" && selectAllShowHide !=true) {
         // To Get the grid Data Count without Disabled CheckBoxes
         let tempArr:any =[]
         let disabledTempCount = 0
         gridData.forEach((obj:any)=>{
             let element:any = document.getElementById(JSON.stringify(obj))
             if(element != "" && element != null && element != undefined  && element.disabled == true){
                 tempArr.push(obj);
             }
         })
         disabledTempCount = gridData.length - tempArr.length
         setDisabledCount(disabledTempCount)

     // Check Whether all the CheckBoxes in the Grid are Disabled
       const allObjectsPresent = gridData.every((obj1: { SourceChatID: any; }) => tempArr.some((obj2: {SourceChatID: any}) => obj1.SourceChatID === obj2.SourceChatID)
         )
         if(allObjectsPresent){
             allGridDataDisabled = true
         }
         else{
             allGridDataDisabled = false
         }

        if (selectAllCount == 0) {
           // Possible Conditions when the SelectAll is UnChecked 
          if(selectAllShowHide == false){
            selectAllelement.disabled = false
          }

          if (arrSelectedValues.length == disabledTempCount || arrSelectedValues.length != disabledTempCount) {

            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj:any)=>{
                if(arrSelectedValues.some((obj1:any)=>{
                    if(obj1.SourceChatID == obj.SourceChatID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length == disabledTempCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }
          }
          if(validTotalCount != 0 &&(arrSelectedValues.length == validTotalCount)){
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
        }

        if (selectAllCount == 1) {
           // Possible Conditions when the SelectAll is Partial Select
          let isAllSelected = false
          let tempCheckArr:any = []
            
            gridData.forEach((obj:any)=>{
                if(arrSelectedValues.some((obj1:any)=>{
                    if(obj1.SourceChatID == obj.SourceChatID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length == disabledTempCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
              if(isFullSelect != true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
              }
              else{
                setSelectAllCount(2);
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
              }
            }
            else{
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
            }
        }
        else{
          if (selectAllCount == 1 && arrSelectedValues.length != validTotalCount) {
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
          }
          if (selectAllCount == 1 && arrSelectedValues.length == validTotalCount) {
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }  
        } 
        if (selectAllCount == 2) {
          // Possible Conditions when the SelectAll is Full Select
          if(operation != "search" && operation != "typeFilter"){
            setSelectAllCount(2)
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
        }
        else{
            if (arrSelectedValues.length != validTotalCount) {
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
            }
        }
      }
      // to handle the pageload of grid for Full Select
      if (validTotalCount == disabledTempCount && gridData.length !=0 && arrSelectedValues.length == disabledTempCount) {
        setIsFullSelect(true);
        setSelectAllCount(2)
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      handleCheckedItems()
    }
    if(gridData.length == 0 || gridData[0] =="No Records Found" ||allGridDataDisabled == true || selectAllShowHide == true){
      setSelectAllCount(0);
      setIsFullSelect(false)
      selectAllelement.checked = ""
      selectAllelement.disabled = true
    }

  }, [gridData]);

  useEffect(() => {
     // To Handle the Select All through Individual Checkboxes
    let selectAllelement:any = document.getElementById("selectAllCheckBox")
    let allDataBinded = false
    // Condition to Check whether all the Data From the Backend is Binded in the Grid
      if(gridData.length == totalItemsCount){
        allDataBinded = true
      }

    if (gridData.length != 0) {
      if (selectAllCount == 0) {
        // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
        if (arrSelectedValues.length == disabledCount && isFullSelect == false && allDataBinded == false) {
          let isAllSelected = false
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(arrSelectedValues.some((obj1:any)=>{
                  if(obj1.SourceChatID == obj.SourceChatID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
          if(isAllSelected){
            setSelectAllCount(1);
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
          }
        }
        if(arrSelectedValues.length == disabledCount && isFullSelect == true || arrSelectedValues.length == disabledCount && allDataBinded == true){
          setSelectAllCount(2);
          selectAllelement.checked ="true";
          selectAllelement.className = "form-check-input me-2 check-alg-2"
        }
        if(arrSelectedValues.length != disabledCount && isFullSelect == true){
          let isAllSelected = false
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(arrSelectedValues.some((obj1:any)=>{
                  if(obj1.SourceChatID == obj.SourceChatID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
         if(isAllSelected == true){
             setSelectAllCount(2);
             setIsFullSelect(true)
             selectAllelement.checked ="true";
             selectAllelement.className = "form-check-input me-2 check-alg-2"
         }
        }
        if(arrSelectedValues.length != disabledCount && isFullSelect == false){
          let isAllSelected = false
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(arrSelectedValues.some((obj1:any)=>{
                  if(obj1.SourceChatID == obj.SourceChatID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
         if(isAllSelected == true){
             setSelectAllCount(1);
             selectAllelement.checked ="true";
             selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
         }
        }
      }
      if (selectAllCount == 1) {
        // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select

          let isAllSelected = false
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(arrSelectedValues.some((obj1:any)=>{
                  if(obj1.SourceChatID == obj.SourceChatID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
         if(isAllSelected == true){
             setSelectAllCount(1);
             selectAllelement.checked ="true";
             selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
         }
         else{
           setSelectAllCount(0);
           setIsFullSelect(false)
           selectAllelement.checked = ""
         }
        
      }
      if (selectAllCount == 2) {
        // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
        if (arrSelectedValues.length != validTotalCount) {
          setSelectAllCount(0);
          setIsFullSelect(false)
          selectAllelement.checked = ""
        }
      }
    }

    handleCheckedItems();
}, [arrSelectedValues]);

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {

      statusText = "Your session has expired. Please log in again to continue using the application."
      setStatusText(statusText)

      //#region Show PopUp
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion


  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await callBackEnd(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText);

      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, inactivityTimer);
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatusText(statusText);

      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  //#endregion

  function deleteChild() {
    try {
      var e: any = document.getElementById("dynamicData");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    } catch (error) {
      throw (error);
    }
  }

  const loadCSVData = async (operation:any) => {
    let loader;
    try {
      // console.log("CSV Data",csvData)

      loader = document.getElementById("loaderWithoutContent");
      loader?.removeAttribute('hidden');

      if (columnName == "SlackChatType") {
        columnName = "SourceChatType";
        setColumnName(columnName);
      }

      let newarr: any = csvData.filter((x) => x.SourceChatName.toLowerCase().includes(searchValue.toLocaleLowerCase()) || x.TargetChatName.toLowerCase().includes(searchValue.toLowerCase()));

      if (isSortApplied) {

        // if (columnOrder == "desc") {
        //   newarr = Enumerable.from(newarr).orderByDescending(x => x[columnName]).toArray();
        // }
        // else {
        //   newarr = Enumerable.from(newarr).orderBy(x => x[columnName]).toArray();
        // }

        if (columnOrder == "desc") {
          newarr = newarr.sort(function (a: any, b: any) {
            var textA = a[columnName].toUpperCase();
            var textB = b[columnName].toUpperCase();
            return textA == textB ? 0 : textA < textB ? 1 : -1;
          });
        }
        else {
          newarr = newarr.sort(function (a: any, b: any) {
            var textA = a[columnName].toUpperCase();
            var textB = b[columnName].toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
        }
        //   
        //   newarr = newarr.sort(function(a: any, b: any) {
        //     var textA = a[columnName].toUpperCase();
        //     var textB = b[columnName].toUpperCase();
        //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        // });
      }

      if (MMType == "MM" && DMType == "") {
        newarr = newarr.filter((x: any) => x.SourceChatType == "MM");
      }
      else if (DMType == "DM" && MMType == "") {
        newarr = newarr.filter((x: any) => x.SourceChatType == "DM");
      }

      // totalItemsCount = newarr.length;
      // setTotalItemsCount(newarr.length)

      if(operation == "search" || operation == "typeFilter"){
        setTotalItemsCountFeature(newarr.length)
      }
      else{
          setTotalItemsCount(newarr.length)
      }

     // to Uncheck the Check Boxes after Binding
      gridData.forEach((obj:any)=>{
        let element:any = document.getElementById(JSON.stringify(obj))
        if(element != "" && element != undefined && element != null){
          element.checked = false;
        }
    })

      gridData = newarr.slice(0, countPerPage)
      setGridData(gridData);
      ////console.log("load csv data is finished ", new Date());
      loader?.setAttribute('hidden', 'hidden');
    }
    catch (error) {
      loader?.setAttribute('hidden', 'hidden');
      ////console.log(error)
    }
  }

  const gridBodyData = () => {

    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return gridData.map((obj, index) => {
          let i: number = index
          let trclassName = i % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          obj.SourceChatType = (obj.SourceChatType == 1 || obj.SourceChatType =="DM") ? "DM" : "MM"
          obj.TargetChatName = obj.TargetChatName == null ? "-" : obj.TargetChatName;
          obj.TargetChatType = obj.TargetChatType == null ? "-" : ((obj.TargetChatType == 1 || obj.TargetChatType == 'DM') ? 'DM': (obj.TargetChatType == 0 || obj.TargetChatType == 'MM')? 'MM': obj.TargetChatType);
          let dataTip = (obj.TargetChatName).length > 10 ? obj.TargetChatName : "";
          obj.TargetChatName = (obj.MigrationState == 'ChatHTML') ? "-" : obj.TargetChatName
          obj.IsConversationMigrated = (obj.MigrationState == 'ChatHTML') ? null : obj.IsConversationMigrated
          //obj.TargetChatName = (obj.TargetChatName).length > 10 ? (obj.TargetChatName).substring(0, 10) + '...' : obj.TargetChatName;
          return (
            <tr className={trclassName} >
              {isArr == 1 && obj.IsConversationMigrated == false ? <td className=" py-3 tabel-data-font-level2"><input className="form-check-input" type="checkbox" id={JSON.stringify(obj)} name="checkbox" value={obj.SourceChatID} onChange={(e) => handleCheckbox(e)} disabled /></td> :
                <td className=" py-3 tabel-data-font-level2" data-tip={obj.isUsersMapped == 0 ? "Please provide valid user mapping to proceed further." : ""}><input className="form-check-input" type="checkbox" id={JSON.stringify(obj)} name="checkbox" value={obj.SourceChatID} onChange={(e) => handleCheckbox(e)} disabled={((obj.IsConversationMigrated == null && obj.TargetChatName != '-') || (obj.IsConversationMigrated == null && obj.isUsersMapped == 1 && isDirectCreation)) ? false : true} /></td>
              }

              {
                (obj.SourceChatName).length > 38 ? <td className="py-3 border-0 px-0 tabel-data-font-level" data-tip={obj.SourceChatName}> {obj.SourceChatName.substring(0, 35)}...</td> :
                  <td className="py-3 border-0 px-0 tabel-data-font-level" data-tip="">{obj.SourceChatName}</td>
              }

              <td className="py-3 tabel-data-font-level1">{obj.SourceChatType}</td>
              {
                (obj.TargetChatName).length > 38 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetChatName}> {(obj.TargetChatName).substring(0, 35) + '...'}</td> :
                  <td className="py-3 tabel-data-font-level1" data-tip="">{obj.TargetChatName}</td>
              }

              <td className="py-3 tabel-data-font-level1">{obj.TargetChatType}</td>
              {/* <td className="hide" style={{display: 'none'}}>{obj.MigrationSummaryID}</td> */}
              <td className="py-3 tabel-data-font-level1 text-center">
                {
                 !isEnableDeleteChecked ? (obj.IsConversationMigrated != false ? <div className='icon-disable'> <img className="icons" src='/Images/delete-icon.svg' /> </div> : <div className='icons'> <img className="icons anchorAsPointer" title="Delete" src='/Images/delete-icon.svg' id={obj.SourceChatID} data-MigrationSummmaryID={obj.MigrationSummaryID}  data-ChatType={obj.SourceChatType} onClick={e => deleteClicked(e)} /> </div>) 
                 :(obj.IsConversationMigrated ==true && obj.TargetChatName!=null && obj.TargetChatType!=null) ? <div className='icons'> <img className="icons anchorAsPointer" title="Delete" src='/Images/delete-icon.svg' id={obj.SourceChatID} data-MigrationSummmaryID={obj.MigrationSummaryID} data-ChatType={obj.SourceChatType}  onClick={e => deleteClicked(e)} /> </div> :<div className='icon-disable'> <img className="icons" src='/Images/delete-icon.svg' /> </div>  
                }
              </td>
              <span>
                <ReactTooltip />
              </span>
            </tr>)


        })
      }

      else if (gridData[0] == undefined && isArr == 0) {
        return (<tr> <td className="aligncenter" colSpan={5}></td>  </tr>)
      }//empty binding
      else {
        return (<tr> <td className="aligncenter" colSpan={5}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img><p className="mt-2">No records found</p></td>  </tr>)
      }//No records found
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const handleCheckbox = (e: any) => {
    try {

      let id = e.target.id
      let objState = JSON.parse(id)
      let tempArr = arrSelectedValues;
      if (e.target.checked == true) {
        // let x = document.getElementById("btnCreateMapping")
        // x?.removeAttribute("disabled");

        // let tempObj = {
        //   "SourceChatID": objState.SourceChatID,
        //   "SourceChatName": objState.SourceChatName,
        //   "TeamChatID": objState.TeamsChatID,
        //   "TeamChatName": objState.TargetChatName,
        //   "SourceChatType": objState.SourceChatType.toLowerCase() == "mm" ? objState.SourceChatType = 0 : objState.SourceChatType = 1,
        //   "TeamChatType": objState.TargetChatType.toLowerCase() == "mm" ? objState.TargetChatType = 0 : objState.TargetChatType = 1,
        //   "SlackworkspaceID": SlackworkspaceID,
        // }

        tempArr.push(objState)
        setArrSelectedValues([...tempArr]);

      }
      else {

        tempArr = [...arrSelectedValues];
        let ind = tempArr.findIndex((obj: any) => obj.SourceChatID == e.target.value);
        tempArr.splice(ind, 1);
        setArrSelectedValues([...tempArr]);
        if (tempArr.length == 0) {
          let x = document.getElementById("btnCreateMapping")
          x?.setAttribute("disabled", "disabled");
        }
        else {
          let x = document.getElementById("btnCreateMapping")
          x?.removeAttribute("disabled");
        }
      }

      // To find whether must be Be in full Select on individual CheckBox Check
      if(tempArr.length == validTotalCount){
        setIsFullSelect(true)
      }

    }
    catch (error: any) {
      ////console.log("error", error);
    }
  }

  const deleteClicked = async (e: any) => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let id = e.target.id;
        let config = ({
          method: "POST",
          //url: "http://localhost:7000/GroupChatMigration/deleteMapping",deleteGroupChatMappingURL
          //url: `${process.env.REACT_APP_CONVERSATIONMIGRATION_BASEURL}GroupChatMigration/deleteMapping`,
          url: constants.URL.deleteGroupChatMappingURL,
          data: {

            "clientID": clientID,
            "SourceChatID": `${id}`, 
            "isAdminDelete" : isEnableDeleteChecked,
            "adminToken" : adminToken,
            "deleteType" : e.target.dataset.chattype,
            "summaryID" : Number(e.target.dataset.migrationsummmaryid)
          },
          token: clientToken
        }
        )
        // let id = e.target.id.replace('delete', '');
        // let userid = sourceChatId;

        let data: any = await callBackEnd(config);

        if (data.data.statusCode == 200) {
          loadGridPage(operation);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          setStatusText(data.data.data);
          enableAlertPopup();
        }
      }
      else {
        localStorage.clear();
      }

    }
    catch (error: any) {
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      isExport = 0;
      setIsExport(isExport);
      enableAlertPopup();
    }
  }

  const handleChatType = async (e: any) => {
    try {
      if (e.target.value == "All") {
        DMType = "DM";
        MMType = "MM";
      }
      else if (e.target.value == "DM") {
        DMType = "DM";
        MMType = "";
      }
      else {
        DMType = "";
        MMType = "MM";
      }
      setDMType(DMType);
      setMMType(MMType);
      if (isArr == 0) {
        let tempOperation = ""
        if(e.target.value != "All"){
          tempOperation = "typeFilter"
        }
        setOperation(tempOperation)
        await loadGridPage(tempOperation);
      }
      else {
        let tempOperation = ""
        if(e.target.value != "All"){
          tempOperation = "typeFilter"
        }
        setOperation(tempOperation)
        await loadCSVData(tempOperation);
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }

  const loadGridPage = async (operation:any) => {
    let loader;
    try {

      if (clientID != undefined && clientToken != undefined) {

        ////console.log(process.env.REACT_APP_CONVERSATIONMIGRATION_BASEURL);
        let config = ({
          method: "POST",
          url: constants.URL.getMappingChatDataURL,
          data: {
            "clientID": clientID,
            "SortColumn": `${columnName}`,
            "Sorttype": `${columnOrder}`,
            "Searchtext": `${searchValue}`,
            "IsDM": DMType,
            "IsMM": MMType,
            "pageIndex": indexPerPage,
            "type":"Standard"
          },
          token: clientToken
        }
        );


      debugger

        loader = document.getElementById("loaderWithoutContent");
        loader?.removeAttribute('hidden');
        let data: any = await callBackEnd(config);
        loader?.setAttribute('hidden', 'hidden');

          
        isSourceSettingsConnected = data.data.data[2] != undefined && data.data.data[2][0] != undefined && data.data.data[2][0].isSlackConnected != undefined && data.data.data[2][0].isSlackConnected != null ? data.data.data[2][0].isSlackConnected : 0;
        isTargetSettingsConnected = data.data.data[2] != undefined && data.data.data[2][0] != undefined && data.data.data[2][0].isTeamsConnected != undefined && data.data.data[2][0].isTeamsConnected != null ? data.data.data[2][0].isTeamsConnected : 0;
        let isDMTaken: any;
        let isMMTaken: any;
        isDMTaken = data.data.data[3] != undefined && data.data.data[3].length > 0 && data.data.data[3][0] != undefined && data.data.data[3][0] != null && data.data.data[3][0].isInventoryTaken != undefined && data.data.data[3][0].isInventoryTaken != null ? data.data.data[3][0].isInventoryTaken : 0;
        isMMTaken = data.data.data[3] != undefined && data.data.data[3].length > 0 && data.data.data[3][1] != undefined && data.data.data[3][1] != null && data.data.data[3][1].isInventoryTaken != undefined && data.data.data[3][1].isInventoryTaken != null ? data.data.data[3][1].isInventoryTaken : 0;
        isInventoryTaken = (isDMTaken + isMMTaken) > 0 ? 1 : 0;
        setIsSourceSettingsConnected(isSourceSettingsConnected);
        setIsTargetSettingsConnected(isTargetSettingsConnected);
        setIsInventoryTaken(isInventoryTaken);
        setValidTotalCount(data.data.data[4][0].validTotalCount)
        if (data.data.statusCode == 200) {
          if (data.data.data[0].length == 0) {
            data.data.data = "No Records Found"
          }

          if (data.data.data == "No Records Found") {     //data.data.data.recordsets[0].length==0
            setGridData([data.data.data]);
            // setTotalItemsCount(0);
          }
          else if (data.data.data == "Invalid authentication token provided") {
            enableSessionExpiryPopup();
          }
          else {
            let Count = data.data.data[1][0].CHATCOUNT;
            // setTotalItemsCount(Count);

            if(operation == "search" || operation == "typeFilter"){
              setTotalItemsCountFeature(Count)
            }
            else{
              setTotalItemsCount(Count)
            }

            var currentData = data.data.data[0];
            gridData.length = 0;
            setGridData([...gridData, ...currentData]);
            
          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
          isExport = 0
          setIsExport(isExport);
          enableAlertPopup();
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      isExport = 0
      setIsExport(isExport);
      loader?.setAttribute('hidden', 'hidden');
      enableAlertPopup();
    }
  }

  const btnCreateMapping = async () => {
    let loader;
    try {
      if (clientID != undefined && clientToken != undefined) {
        let selectedChannels: any = [];

        await arrSelectedValues.forEach((obj: any) => {
          selectedChannels.push({
            "SourceChatID": obj.SourceChatID,
            "SourceChatName": obj.SourceChatName,
            "SourceChatType": obj.SourceChatType.toLowerCase() == "mm" ? obj.SourceChatType = 0 : obj.SourceChatType = 1,
            "SlackworkspaceID": SlackworkspaceID,
          });
        });



        document.getElementById("btnCreateMapping")?.setAttribute("disabled", "disabled");
        let config = ({
          method: "POST",
          url: constants.URL.createDirectChatURL,
          data: {
            "clientID": clientID,
            "createDirectChatList": selectedChannels          
          },
          token: clientToken
        }
        );
        setNonGifLoaderContent("Please wait. We are mapping Slack and Teams Chat")
        loader = document.getElementById("loaderWithContent");
        loader?.removeAttribute('hidden');
        let data: any = await callBackEnd(config);
        loader?.setAttribute('hidden', 'hidden')
        if (data.data.data == "Source Target Mapped Successfully") {
          setArrSelectedValues([])
          selectedCheckbox = [];
            let els: any = document.getElementsByName("checkbox")
            for (let i = 0; i < els.length; i++) {
              if (els[i].checked == true) {
                els[i].checked = false;
              }
            }
            loadGridPage(operation);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          setStatusText(data.data.data);
          enableAlertPopup();
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      loader?.setAttribute('hidden', 'hidden')
      setStatusText("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      setArrSelectedValues([]);
      isExport = 0;
      setIsExport(isExport);
      enableAlertPopup();
    }
  }

  const sortClicked = async (e: any) => {
    try {
      isSortApplied = true;
      setIsSortApplied(isSortApplied);
      let sortID = e.target.id;
      setsorthide(sortID);
      let sortval = sortID.split('@');
      columnName = sortval[0];
      columnOrder = sortval[1];
      setColumnName(columnName);
      setColumnOrder(columnOrder);
      setIndexPerPage(indexPerPage ? indexPerPage : 1);
      setCoutPerPage(countPerPage ? countPerPage : 10);
      loadGridPage(operation);
    } catch (error: any) {
      ////console.log(error);
    }
  }

  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value);
    }
    catch (error: any) {
    }
  }

  const handleSearchIcon = async (e: any) => {
    try {
      let tempOperation = ""
        if(searchValue != ""){
          tempOperation = "search"
        }
      settmpSearchValue(searchValue);
      setOperation(tempOperation)
      await loadGridPage(tempOperation);
    }
    catch (error: any) {
    }
  }

  const handleKeyDown = async (e: any) => {
    try {
      if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
        if (isArr == 0) {
          let tempOperation = ""
          if(searchValue != ""){
            tempOperation = "search"
          }
          settmpSearchValue(searchValue);
          setOperation(tempOperation)
          await loadGridPage(tempOperation);
        }
        else {
          handleSearchIcon(e);
        }
      }
    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const handleLoadMore = async () => {
    try {
      indexPerPage = indexPerPage + 1;
      countPerPage = countPerPage + 10;
      setIndexPerPage(indexPerPage);
      setCoutPerPage(countPerPage);

      
      // To find whether must be Be in full Select on individual CheckBox Check
      if(arrSelectedValues.length == validTotalCount){
        setIsFullSelect(true)
      }

      loadGridPage(operation);
      
      // To handle the Select All When Load More is Clicked
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if (selectAllCount == 1) {
              if(isFullSelect == true){
                  setSelectAllCount(2);
                  selectAllelement.checked = "true";
                  selectAllelement.className = "form-check-input me-2 check-alg-2"
              }
              else{
                  setSelectAllCount(0);
                  selectAllelement.checked = "";
              }
          } 
      else if(selectAllCount == 2) {
              setSelectAllCount(2);
              selectAllelement.checked = "true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
          }

    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const disableCreateButtton = async () => {
    try {

      let btnCreate = document.getElementById("btnCreate")
      btnCreate?.setAttribute('disabled', 'disabled');
    }
    catch (error) {
      ////console.log(error);
    }
  }

  const enableCreateMSTeamsChatPopup = async () => {

    disableCreateButtton();
    try {
      if (isTargetSettingsConnected) {
        const element: any = document.getElementById("teams-creation");

        if (element.hasAttribute("aria-modal")) {
          //#region Hide PopUp
          document.getElementById("teams-creation")?.setAttribute("class", "modal fade");
          document.getElementById("teams-creation")?.setAttribute("style", "display:none");
          document.getElementById("teams-creation")?.setAttribute("aria-hidden", "true");
          document.getElementById("teams-creation")?.removeAttribute("aria-modal");
          document.getElementById("teams-creation")?.removeAttribute("role");
          document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
          let ele: any = document.getElementById("uploadFile");
          ele.value = "";
          disableCreateButtton();
          //#endregion
        }
        else {
          document.getElementById("teams-creation")?.removeAttribute("aria-hidden");
          document.getElementById("teams-creation")?.setAttribute("aria-modal", "true");
          document.getElementById("teams-creation")?.setAttribute("role", "dialog");
          document.getElementById("teams-creation")?.setAttribute("class", "modal fade show");
          document.getElementById("teams-creation")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
      }
      else {
        statusText = "Please Connect the MS Teams Tenant!"
        setStatusText(statusText)
        enableAlertPopup();
        isExport = 0;
        setIsExport(isExport);
      }
    }
    catch (error: any) {
      ////console.log(error)
    }
  }

  const enableAlertPopup = () => {
    try {
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    } catch (error) {
      ////console.log(error)
    }
  }

  const exportCSV = () => {

    try {

      if (isMappingExport == 0) {
        var options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: 'Invalid Chat Details',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "Invalid Chat Details",
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
        isExport = 0
        setIsExport(isExport);
      }

      else {
        let options: any = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: 'Invalid Mapping Details',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "Invalid Mapping Details"
        };

        const csvExporter = new ExportToCsv(options);
        //csvExporter.generateCsv(data[0]);
        csvExporter.generateCsv(data);
        setIsMappingExport(0);
      }

      isExport = 0
      setIsExport(isExport);
      isMappingExport = 0;
      setIsMappingExport(isMappingExport);

    }
    catch (error) {
      ////console.log(error);
    }
  }

   const handleCheckedItems = async () => {
    // if (arrSelectedValues.length != 0) {
    //   gridData.forEach((obj) => {
    //     for (let i = 0; i < arrSelectedValues.length + 1; i++) {
    //       if (arrSelectedValues[i].SourceChatID == obj.SourceChatID) {
    //         let element: any = document.getElementById(JSON.stringify(obj));
    //         if (element != "" && element != null && element != undefined && element.disabled == false) {
    //           element.checked = "true";
    //           break;
    //         }
    //       }
    //     }
    //   });
    // }
    if (arrSelectedValues.length !== 0) {
      gridData.forEach((obj) => {
        const matchingValue = arrSelectedValues.find((value:any) => value && value.SourceChatID === obj.SourceChatID);
        if (matchingValue) {
          const element = document.getElementById(JSON.stringify(obj));
          if (element instanceof HTMLInputElement && !element.disabled) {
            element.checked = true;
          }
        }
        else{
          let element: any = document.getElementById(JSON.stringify(obj));
          element.checked = "";
        }
      });
    }
    
  };

  const handleSelectAll = async () =>{
      try{
      // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
      let count: any = undefined;
      if (totalItemsCount == gridData.length) {
        setIsFullSelect(true);
        count = selectAllCount + 2;
        if (count > 2) {
          count = 0;
        }
      } else {
        count = selectAllCount + 1;
        if (count > 2) {
          count = 0;
        }
      }
      // to handle the Select All CheckBox CSS
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if(count==2){
        selectAllelement.checked = "true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      else if(count == 1){
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
      }
      else{
        selectAllelement.checked = "";
      }

      setSelectAllCount(count);

      // To handle the SelectAll when the Checkbox is Unchecked
      if (count == 0) {
        setIsFullSelect(false)

        arrSelectedValues.forEach((obj: any) => {
          if (obj.TargetChatName == null) obj.TargetChatName = "-"
          if (obj.TargetChatType == null) obj.TargetChatType = "-"
          const element: any = document.getElementById(JSON.stringify(obj));
          if (element != "" && element != null && element != undefined) {
            element.checked = "";
          }
        });
        let tempArr: any = [];
        setArrSelectedValues([...tempArr]);
      }

       // To handle the SelectAll when the Checkbox is PartialSelect
      if (count == 1) {
         let tempArr: any = arrSelectedValues;
         gridData.forEach((obj: any) => {
           const element: any = document.getElementById(JSON.stringify(obj));
           //   console.log(element,"element")
           if (
             element != "" &&
             element != null &&
             element != undefined &&
             element.checked != true &&
             element.disabled == false
           ) {
             element.checked = "true";
             tempArr.push(obj);
           }
         });
         setArrSelectedValues([...tempArr]);
         // To find whether must be Be in full Select on individual CheckBox Check
          if(tempArr.length == validTotalCount){
            setIsFullSelect(true)
          }
      }

      // To handle the SelectAll when the Checkbox is Full Select
      if(count == 2){
       
        setIsFullSelect(true)
        let tempArr: any = [];

          let config = ({
            method: "POST",
            url: constants.URL.getMappingChatDataURL,
            data: {
              "clientID": clientID,
              "SortColumn": `${columnName}`,
              "Sorttype": `${columnOrder}`,
              "Searchtext": `${searchValue}`,
              "IsDM": DMType,
              "IsMM": MMType,
              "pageIndex": totalItemsCount,
              "type":"Standard"
            },
            token: clientToken
          }
          );
  
         let loader = document.getElementById("loaderWithoutContent");
          loader?.removeAttribute('hidden');
          let data: any = await callBackEnd(config);
          loader?.setAttribute('hidden', 'hidden');

          data.data.data[0].forEach((obj: any) => {
          if(obj.isUsersMapped == true && obj.TargetChatID == null){
            tempArr.push(obj);
          }
        });
        setValidTotalCount(tempArr.length)
        setArrSelectedValues([...tempArr]);
        loader?.setAttribute("hidden", "hidden");
      }
      
      }
      catch(error){

      }

  }

  const popUpOkClick = () => {
    try {

      if (isInvalidUsersFound){
        const workSheet = XLSX.utils.json_to_sheet(exportChatDetails);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Teams Chat Details")
        XLSX.writeFile(workBook, "Teams Chat Details.xlsx")
        const workSheetUsers = XLSX.utils.json_to_sheet(exportInvalidUserDetails);
        const workBookUsers = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBookUsers, workSheetUsers, "Invalid User Details")
        XLSX.writeFile(workBookUsers, "Invalid Details.xlsx")

      }
      if (isSessionEpired === false) {


        const element: any = document.getElementById("alertPopUp");
        document.getElementById("alertPopUp")?.setAttribute("class", "modal fade");
        document.getElementById("alertPopUp")?.setAttribute("style", "display:none");
        document.getElementById("alertPopUp")?.setAttribute("aria-hidden", "true");
        document.getElementById("alertPopUp")?.removeAttribute("aria-modal");
        document.getElementById("alertPopUp")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        let eleMappingBrowse: any = document.getElementById("uploadFileSlack");
        eleMappingBrowse.value = "";
        let y = document.getElementById("Submit")
        y?.setAttribute("disabled", "disabled");

        if (isExport == 1) {
          exportCSV();
        }
        if (isMappingExport == 1) {
          exportCSV();
        }

        if (isCreateMSTeamsAlertPopup == true) {
          enableCreateMSTeamsChatPopup();
          isCreateMSTeamsAlertPopup = false;
          setIsCreateMSTeamsAlertPopup(isCreateMSTeamsAlertPopup);
        }

        deleteChild();
      }
      else {

        window.location.pathname = "/Login";

      }

    } catch (error) {
      ////console.log("Issue Faced");
    }
  }

  const successPopUpOkClick = () => {
    try {

      disableCreateButtton();
      const element: any = document.getElementById("success");
      document.getElementById("success")?.setAttribute("class", "modal fade");
      document.getElementById("success")?.setAttribute("style", "display:none");
      document.getElementById("success")?.setAttribute("aria-hidden", "true");
      document.getElementById("success")?.removeAttribute("aria-modal");
      document.getElementById("success")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'MSTeam Chat Created Successfully',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "MSTeam Chat Created Successfully",
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);

    } catch (error) {
      ////console.log("Issue Faced");
    }
  }

  const step3HideShowClick = async () => {
    try {
      if (document.getElementById("panelsStayOpen-collapseThree")?.hidden == true) {
        document.getElementById("panelsStayOpen-collapseThree")?.removeAttribute("hidden");
        document.getElementById("step3Arrow")?.setAttribute("class", "close-dropDown");
      }
      else {
        document.getElementById("panelsStayOpen-collapseThree")?.setAttribute("hidden", "true");
        document.getElementById("step3Arrow")?.setAttribute("class", "open-dropDown-cm");
      }
    } catch (error) {
      ////console.log("Step3HideShowClick Error:", error);
    }
  }

  const handleCheckboxChange = () => {
     try {
      setIsEnableDeleteChecked(!isEnableDeleteChecked);
     } catch (error) {
      
     }
    
   
  };


  return (
    <div className="row justify-content-center my-5 card-container mx-0 ms-0 overall-res-padding">

      <BackNavigation backNav={"  Migration using Service Account "} link={'/Conversation'}/>
      <WizardNavigation wizActive={"step2InActive"} />
      {/* <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position">
                    <div className="spinner-border Loader text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    <p>Loading...</p>
                </div>
        </div>  */}


      {/* <div className="col-md-11"> */}
      <div className="col-md-12">

        <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>MS Team Chat Creation and Mapping</p>
        <div className="col-md-12 col-lg-12 col-sm-10 theme-color-bg py-2 p-3 rounded-2 mb-4 info-background-color width-98 font-14">
                <label className="font-14"><span className="me-2"><img src="/Images/info-icon.svg" alt="info_small" /></span><span>Complete </span><a href="/UserMapping" className="theme-color  font-bold anchorAsPointer">User Mapping </a><span className="font-regular">& then proceed to migrate Conversations from the Slack workspace.</span></label>
            </div>
        <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
          {/* accordian-3 starts here */}
          <div className="accordion mb-5">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
              <div className="toggle accordion accordine-font-size-res font-bold accordian-label">
                <img id="step3Arrow" src="/Images/accordian-open.svg" className="close-dropDown" onClick={step3HideShowClick} />
                <span className="accordian-label-grey anchorAsPointer" onClick={step3HideShowClick}>Step 1 :</span> <span className="accordian-label-dark anchorAsPointer" onClick={step3HideShowClick}>MS Team Chat Creation and Mapping</span>
              </div>
            </h2>
            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse  show" aria-labelledby="panelsStayOpen-headingThree" >
              <div className="accordion-body">
                <div className="d-grid d-lg-flex flex-lg-nowrap justify-content-md-between">
                  <div className="slack-btn-phn-s "><label className="font-14 font-regular accordian-para me-3 my-2">Select the required mappings to create mapping.</label></div>

                  <div className="d-grid d-lg-flex">
                    <div className="d-grid d-lg-flex">
                    {isAdmin && (
                      <div className="form-check form-switch float-start mt-3 me-5">
                        <input className="form-check-input" type="checkbox" id="enableDelete" checked = {isEnableDeleteChecked} onClick={handleCheckboxChange}></input>
                        <label className="form-check-label font-semibold font-15 text-nowrap" htmlFor="enableDelete">
                          Enable Delete
                        </label>
                      </div>
                    )}
                    {/* <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-3 me-4"># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className="font-regular font-16">of </span> {totalItemsCount}</p> */}
                    <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-3 me-4">
                            # of records: {gridData[0] != "No Records Found" ? gridData.length:0}{" "}
                            <span className="font-regular font-16">of </span> 
                            { gridData[0] != "No Records Found" ?
                            operation == "search" || operation == "typeFilter"?
                                totalItemsCountFeature: totalItemsCount 
                            :0}{" ("}{arrSelectedValues.length}{" Selected)"}
                    </p>
                    <label className="font-14 my-2 pt-2 font-semibold  me-2 margin-top-customspacing text-nowrap">Slack Chat Type</label>

                    <select className="dd-slackChatType-height form-select my-2 form-select-sm custom-select me-2" aria-label=".form-select-sm example" onChange={handleChatType}>
                      <option value="All">All</option>
                      <option value="DM">DM</option>
                      <option value="MM">MM</option></select>
                  </div>

                    <div className="input-group me-2 search-ms-chat-slack my-2 custom-search ">
                      <input type="text" className="form-control z-value-unset form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={searchValue} onKeyDown={handleKeyDown} onChange={handleSearch} />
                      <button className=" z-value-unset btn btn-outline-secondary" type="button" id="button-addon1" onClick={handleSearchIcon}>
                        <img src="/Images/search.svg" alt="search-icon" /></button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive col-12 col-md-12 float-start mt-2 p-1">
                  <table className="table table-border mb-4">
                    <thead className="tabel-head-bg">
                      <tr>
                        {/* <th className="table-head" /> */}
                        <th className="tabel-head-font-level1 font-bold font-14 chat-mapping-column">
                  {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                  <input
                    className="form-check-input me-2 check-alg-2 intermediate"
                    id="selectAllCheckBox"
                    type="checkbox"
                    disabled = {selectAllShowHide}
                    onClick={handleSelectAll}
                  />
                </th>
                        <th className="table-head1 font-14 font-bold">Slack Chat Name
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="Slack Chat Name" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="Slack Chat Name" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Slack Chat Type
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="Slack Chat Type" id="SlackChatType@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SlackChatType@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="Slack Chat Type" id="SlackChatType@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SlackChatType@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">MS Teams Chat Name
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="MS Teams Chat Name" id="TargetChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatName@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="MS Teams Chat Name" id="TargetChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatName@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">MS Team Chat Type
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="TeamChatType" id="TargetChatType@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatType@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="TeamChatType" id="TargetChatType@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChatType@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody >

                      {gridBodyData()}
                    </tbody>
                  </table>

                </div>
                {/* <!-- loadmore button section starts here --> */}
                <div className="col-md-12  mt-3 mb-5 text-center">
                  {/* {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={() => handleLoadMore()}>Load More</button> : ""} */}
                  {operation == "search" || operation == "typeFilter" ? (
                      totalItemsCountFeature > countPerPage ? (
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={handleLoadMore}>
                          Load More
                        </button>
                      ) : ""
                    ) : (
                      totalItemsCount > countPerPage ? (
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4" onClick={handleLoadMore}>
                          Load More
                        </button>
                      ) : ""
                    )}
                </div>
                {/* <!-- loadmore button section ends here --> */}
              </div>
            </div>
          </div>
          {/* accordian-3 ends here */}
        </div>
      </div>
      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{statusText}</p>
                <div id="dynamicData"></div>
                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={popUpOkClick}>Ok</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <!--Success Pop-up--> */}
      <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/success.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Success!</h5>
                <p className="success-text w-100 text-center mb-4">MS Teams Chat created Successfully</p>

                <button type="button" className="common-btn-class  btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={successPopUpOkClick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--success pop-up--> */}
      <div>
        <footer id="footerDiv" className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
          <div className=" mx-3 d-flex justify-content-end">
            <button type="button" disabled={arrSelectedValues.length == 0? true:false} id="btnCreateMapping" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={btnCreateMapping} >Create Mapping</button>
          </div>
        </footer>
      </div>
      {/* <!--Alert pop-up--> */}

      {/* loader for download slack & teams chats starts here  */}
      <div className="delayoverlay" id='delayloader' hidden={true}>
        <div className="loader-position Loader-Content-position gif-top-align loadercustom ">
          <img className='delayloader-width' src='/Images/delayloader.gif' />
          <p className='mt-4 font-semibold'>{loaderContent}</p>
        </div>
      </div>
      {/* loader for download slack & teams chats ends here  */}

      {/* Loader with content starts here */}
      <div className="overlay" id='loaderWithContent' hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
          <p className="text-center ">{nonGifLoaderContent}</p>
        </div>
      </div>
      {/* loader with content ends here */}

      {/* loader without content starts here  */}
      <div className="overlay" id='loaderWithoutContent' hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      </div>
      {/* loader without content ends here */}


      {/* <div id="fadeDiv" className="modal-backdrop fade show" hidden></div> */}
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>

  )
}
