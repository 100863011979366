import { Router, useHistory, useLocation } from 'react-router-dom';
import { sendRequestToAPI } from '../../Services/PreMigrationServices';
import { useEffect, useState } from 'react';
import '../../styles/pre_migration.css'
import objCryptoJS from "crypto-js";
let r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY



export function SlackUserVerification(): JSX.Element {
    const location = useLocation();
    let userVerificationLink = "";

    useEffect(() => {
        
        const currentURL = window.location.href
        document.getElementById("sourceLoader")?.removeAttribute("hidden");
        document.getElementById("mainContent")?.removeAttribute("hidden");
        // document.getElementById("fadeDiv")?.removeAttribute("hidden");
        try {
            const queryString = location.search;
            const params: any = new URLSearchParams(queryString);
            let code = params.get("code");
            if (code != undefined && code != "") {
                //#region URL with Code
                let userID = localStorage.getItem("s_uid");
                if (userID != undefined && userID != "") {
                    getUpdateSlackAppToken(userID, code);
                }
                //#endregion
            }
            else {
                //#region URL with User
                let cid = queryString.split("cid=")[1];
                let uid = params.get('suid');
                // localStorage.clear();
                localStorage.removeItem("s_uid");
                localStorage.removeItem("s_cid");
                localStorage.setItem("s_uid", uid);
                localStorage.setItem("s_cid", cid);
                document.getElementById("sourceLoader")?.setAttribute("hidden", "hidden");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                //#endregion
            }
        } catch (error) {
            
            ////console.log("UseEffect Issue: ", error);
            document.getElementById("sourceLoader")?.setAttribute("hidden", "hidden");
            userVerificationLink = `https://${window.location.host}/SlackUserVerification?suid=${localStorage.getItem("s_uid")}&cid=${localStorage.getItem("s_cid")}`;
            // localStorage.clear();
            localStorage.removeItem("s_uid");
            localStorage.removeItem("s_cid");
            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion        
        }
    }, [])

    const allowAccessClick = async () => {
        try {
            
            document.getElementById("sourceLoader")?.removeAttribute("hidden");
            // document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#region Get Slack App Code Generator URL
            try {
                let cid: any = localStorage.getItem("s_cid");
                let byteValue = objCryptoJS.AES.decrypt(cid, r_n_Key);
                let val = byteValue.toString(objCryptoJS.enc.Utf8);
                let config = {
                    endpoint: "getGenerateCodeURL",
                    token: '',
                    data: {
                        clientID: parseInt(val),
                        type: "Slack"
                    }
                }
                let response = await sendRequestToAPI(config);
                ////console.log(response);
                if (response?.data != undefined && response.data.statusCode == 200 && response.data.data != undefined) {
                    window.location.href = response.data.data;/* Redirect to Generate Code */
                }
                else {
                    throw new Error("Failed to fetch Slack App Code Generator URL")
                }
            } catch (error) {
                throw error;
            }
            //#endregion
        } catch (err) {
            
            ////console.log("Trigger Loader:", err);
            document.getElementById("sourceLoader")?.setAttribute("hidden", "hidden");
            userVerificationLink = `https://${window.location.host}/SlackUserVerification?suid=${localStorage.getItem("s_uid")}&cid=${localStorage.getItem("s_cid")}`;
            // localStorage.clear();
            localStorage.removeItem("s_uid");
            localStorage.removeItem("s_cid");
            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion  
        }
    }

    const failedPopUpOkClick = () => {
        try {
            // localStorage.clear();
            localStorage.removeItem("s_uid");
            localStorage.removeItem("s_cid");
            //#region Hide PopUp
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
            document.getElementById("sourceFailedPopUp")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            //#endregion
            window.location.href = userVerificationLink;
            // window.location.reload()
        } catch (error) {
            ////console.log("Issue Faced");
        }
    }

    const getUpdateSlackAppToken = async (userID: string, slackAppCode: string) => {
        try {
            
            let cid: any = localStorage.getItem("s_cid");
            let byteValue = objCryptoJS.AES.decrypt(cid, r_n_Key);
            let val = byteValue.toString(objCryptoJS.enc.Utf8);
            let config = {
                endpoint: "FetchUserToken",
                token: '',
                data: {
                    userID: userID,
                    clientID: parseInt(val),
                    slackAppCode: slackAppCode,
                    type: "slack",
                    isReponseRequired : false

                }
            }
            let response = await sendRequestToAPI(config);
            ////console.log(response);
            if (response?.data != undefined && response.data.statusCode == 200) {
                
                // localStorage.clear();
                localStorage.removeItem("s_uid");
                localStorage.removeItem("s_cid");
                document.getElementById("sourceLoader")?.setAttribute("hidden", "hidden");
                document.getElementById("mainContent")?.setAttribute("hidden", "hidden");
                document.getElementById("successMessage")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                throw new Error("Failed to fetch slack app token");
            }
        } catch (error) {
            
            //console.log("Slack App Token: ", error);
            document.getElementById("sourceLoader")?.setAttribute("hidden", "hidden");
            userVerificationLink = `https://${window.location.host}/SlackUserVerification?suid=${localStorage.getItem("s_uid")}&cid=${localStorage.getItem("s_cid")}`;
            // localStorage.clear();
            localStorage.removeItem("s_uid");
            localStorage.removeItem("s_cid");
            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion  
        }
    }

    return (
        <div>
            {/* Navigation Menu Start */}
            < nav className="navbar navbar-expand-lg navbar-light navbar-color shadow-sm fixed-top" >
                <div className="container-fluid">
                    <a className="navbar-brand brandLogoWebApp">
                        <img className='brandLogoWebApp' src="/Images/brand_logo.svg" alt="AVAMIGRATRON" />
                    </a>
                </div>
            </nav >
            {/* Navigation Menu End */}
            <div className='container-fluid p-0'>
            < div className="row justify-content-center preMig-SlackMaring card-container mx-0" >
                {/* <!-- Slack Web App Screen--> */}
                < div className="col-md-12 mt-5 py-5" id="mainContent" hidden >
                    <div className="row mb-2">
                        {/* <!--image section stars here--> */}
                        <div className="col-md-12">
                            <div className="w-100 float-start text-center mb-3">
                                <img className="illustarion-width img mb-4" src="/Images/allow-accesss-illustration.svg"
                                    alt="validation" />
                                <label className="form-lable lable-style font-regular w-100 font-12">Allow access to install the
                                    Slack App and get inventory of all Private Channels and Private Chats (Direct and
                                    Multiparty chats).</label>
                            </div>
                            <div className="w-100 float-start text-center">
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-prime font-16 font-semibold me-3" id="allowAccess" onClick={allowAccessClick}>Allow Access</button>
                            </div>
                        </div>
                        {/* <!--image section stars here--> */}
                    </div>
                    {/* <!--Loader Screen--> */}
                    {/* <div className="overlay" id='sourceLoader' hidden>
                        <div className="loaderDiv">
                            <div className="spinner-border loaderImg text-primary align-center" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            <p>Do not close or refresh the application while the process is in progress</p>
                        </div>
                    </div> */}
                    <div className="overlay" id='sourceLoader' hidden={true}>
                        <div className="Loader-Content-position">
                            <div className="spinner-border loaderImg  Loader-Img-position text-primary align-center" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                            {/* <p className="text-center">Do not close or refresh the application while the process is in progress...</p> */}
                            {/* <p className="text-center">Loading...</p> */}
                        </div>
                    </div>
                    {/* <!--Loader Screen--> */}
                    <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
                </div >
                {/* <!-- Slack Web App Screen--> */}
                {/*<!-- Success Msg --> */}
                <div className="col-md-6 mt-5 py-5" id="successMessage" hidden>
                    <div className="row mb-2">
                        {/* <!--image section stars here--> */}
                        <div className="col-md-12">
                            <div className="w-100 float-start text-center">
                                <img className="illustarion img mb-4" src="/Images/validation-illustation.svg" alt="validation" />
                                <label className="w-100 text-center form-lable lable-style font-regular font-18">Thanks for installing the Slack App!</label>
                            </div>
                        </div>  
                        {/* <!--image section stars here--> */}
                    </div>
                </div>
                {/* <!-- Success Msg --> */}
                {/* <!--Alert Pop-up--> */}
                <div className="modal fade" id="sourceFailedPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Alert!</h5>
                                    <p className="success-text w-100 text-center mb-4">Failed while installing in Slack workspace </p>
                                    <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--Alert pop-up--> */}
            </div >
            </div>
        </div>

    )
}