import {APIService, sendRequestToNodeServices} from '../Client/Client';
import constants from "../Common/Constants";



export const callBackEnd=async ( config : any) =>
{
    return await APIService(config.url,{method:config.method , body : config.data}, config.token);
}

var baseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/`;
let baseURLUserAuthentication = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication`;
//var baseURL = `http://localhost:3000/PreMigration/`
export const sendRequestToAPI=( config : any) => 
{
    try {
        ////console.log(process.env);
        let endpoint = ((config.endpoint).includes("ClientSession")) ? baseURLUserAuthentication + config.endpoint : baseURL + config.endpoint;
        
        return sendRequestToNodeServices(endpoint,{method:'POST' , body : config.data},config.token);
    } catch (error) {
        
        throw error;
    }
}




