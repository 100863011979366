import { useEffect, useState, useRef, forwardRef } from "react";
import { CsvConfigConsts, ExportToCsv } from 'export-to-csv';
import { gridLoad } from '../../Services/UserMappingServices';
import Userlist from '../UserMapping/Userlist';
import MappedUsersGrid from '../UserMapping/MappedUsersGrid'
import * as XLSX from 'xlsx';
import ImageUpload from "./ImageUpload";
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import { useHistory, useLocation } from "react-router-dom";
import { debug } from "console";
import constants from '../../Common/Constants';

export default function UserMappingMasterComponent() {
    let a: any[] = [];
    const activeTab = "common-navTab py-2  nav-link active font-15  nav-tab-custom font-bold";
    const inActiveTab = "common-navTab py-2  nav-link nav-tab-custom font-15  font-bold";
    let [checkString, setString] = useState("2");
    const inputFile = useRef(null);
    let [alert, setalert] = useState('');
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;


    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

    }

    useEffect(() => {
        document.getElementsByTagName('video')[0]?.setAttribute("src", "");
    }, [])



    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alert = "Your session has expired. Please log in again to continue using the application."
            setalert(alert);
            enablealertpopup();
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion



    const handleExport = () => {
        try {
            exportMethod();
        }
        catch (error: any) {
        }
    }

    const exportMethod = async () => {
        try {
            if (clientID != undefined && clientToken != undefined) {
                let config = (
                    {
                        token: clientToken,
                        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/exportUserMappingGrid`,
                        data:
                        {
                            "clientID": clientID,
                            "type": "export"
                        }
                    }
                )
                let loader = document.getElementById("loader");
                loader?.removeAttribute('hidden');
                let data: any = await gridLoad(config);
                let settingsDetails = data.data.data[1].length;
                loader?.setAttribute('hidden', 'hidden');
                if (settingsDetails == 0) {
                    setalert("Please Connect Settings!");
                    enablealertpopup()
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.data[1][0].SlackWorkspaceURL != null || data.data.data[1][0].TenantID != null) {
                    let export1 = data.data.data[0];
                    const options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        showTitle: false,
                        title: 'User List',
                        useTextFile: false,
                        useBom: true,
                        useKeysAsHeaders: true,
                        filename: "UserList"
                    };
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(export1);
                }
                else {
                    setalert("Something went wrong!Please try again or reach AVAMIGRATRON Support.")
                    enablealertpopup();
                }
            }
            else {
                localStorage.clear();
            }
        }

        catch (error: any) {
            setalert("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
            enablealertpopup();
        }
    }

    const enablealertpopup = () => {
        try {
            document.getElementById("alert")?.removeAttribute("aria-hidden");
            document.getElementById("alert")?.setAttribute("aria-modal", "true");
            document.getElementById("alert")?.setAttribute("role", "dialog");
            document.getElementById("alert")?.setAttribute("class", "modal fade show");
            document.getElementById("alert")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        catch (error: any) {
        }

    }

    const helpIconClick = async () => {
        try {
            document.getElementsByTagName('video')[0]?.setAttribute("src", "https://avaeusavamigs2tgvidprod.blob.core.windows.net/avamigratron/UserMapping.mp4?sp=r&st=2024-03-12T13:57:39Z&se=2025-03-13T21:57:39Z&spr=https&sv=2022-11-02&sr=b&sig=b9gVJe1oo98KqapmbwGpAR8mPvn25k4eNaNGX4g%2BDG4%3D")
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
        } catch (error) {
        }
    }

    const helpPopUpCloseClick = async () => {
        try {
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
            document.getElementsByTagName('video')[0]?.setAttribute("src", "");
        } catch (error) {
        }
    }

    const tabSwitch1 = (e: any) => {
        try {
            setString("1")
            document.getElementById("userslisttab")?.setAttribute("className", activeTab)
            document.getElementById("mappedusertab")?.setAttribute("className", inActiveTab)
        }
        catch (error) {
        }
    }

    const tabSwitch2 = (e: any) => {
        try {
            setString("2")
            document.getElementById("mappedusertab")?.setAttribute("className", activeTab)
            document.getElementById("userslisttab")?.setAttribute("className", inActiveTab)
        }
        catch (error) {
        }
    }

    const browsealertokclick = () => {
        try {
            if (isSessionEpired === false) {
                document.getElementById("alert")?.setAttribute("class", "modal fade");
                document.getElementById("alert")?.setAttribute("style", "display:none");
                document.getElementById("alert")?.setAttribute("aria-hidden", "true");
                document.getElementById("alert")?.removeAttribute("aria-modal");
                document.getElementById("alert")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                window.location.pathname = "/Login";

            }
        }
        catch (error) {
        }
    }

    return (
        <body className="body-color">
            <div className="container-fluid">
                <div className="row justify-content-center  card-container mx-0 ms-0 overall-res-padding">
                    <div className="col-lg-12 col-md-12 col-sm-12 usermapping-mt-5 mb-3">
                        <h4 className="font-26 font-bold">
                            User Mapping
                        </h4>
                        <p className="font-14 font-regular">Map the Slack and MS Teams users, if you wish to migrate your channels or chats contents on-behalf of mapped users</p>
                    </div>

                    <div className="col-lg-12 col-sm-12 col-md-12 float-start mb-4">
                        <div className="float-start margin-btm-report">
                            <ul className="nav" role="tablist">
                                <li className="nav-item anchorAsPointer">
                                    <a className={(checkString == "2") ? activeTab : inActiveTab} id="mappedusertab" onClick={(e) => tabSwitch2(e)}>Mapped User</a>
                                </li>
                                <li className="nav-item anchorAsPointer" >
                                    <a className={(checkString == "1") ? activeTab : inActiveTab} id="userslisttab" onClick={(e) => tabSwitch1(e)}>Users List</a>
                                </li>
                            </ul>
                        </div>
                        <div className="float-end">
                            <ImageUpload />

                            <button type="button" className="common-btn-class  btn btn-user-mapping btn-primary-theme" onClick={handleExport}><span className="me-2"><img src="/Images/export-excel-white.svg" alt="export-excel" /></span>Export Users list </button>
                        </div>
                    </div>
                    <div>
                        {checkString == "2" ? <MappedUsersGrid /> : <Userlist />}
                    </div>
                    <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content content-margin">
                                <div className="modal-body">
                                    <img src="/Images/success.svg" className="success-icon" alt="success" />
                                    <div className="text-center success-content w-100 mt-5">
                                        <h5 className="font-semibold font-18">Success!</h5>
                                        <p className="success-text w-100 text-center mb-4">Users List Fetched Successfully</p>
                                        <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal">Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="alert" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alert}</p>
                                <button type="button" className="common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>

            <div className="icon-container userMappingHelpIcon help-icon-overlap" onClick={helpIconClick}>
                <label className="custom-icon-style" id="hidden-section">
                    <img className="fixed-icon" src='/Images/help-web-button.svg' alt="help-icon" />
                    <img className="expand-icon expand-content" src='/Images/help-web-button.svg' alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
                </label>
            </div>

            <div className="modal fade" id="helpPopUpDiv" tabIndex={-1}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-radius">

                        <div className="help-modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help ?</label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to learn how to map the Slack and MS Teams Users!</label>
                                    </div>

                                    <div className="w-100 float-start text-center">
                                        <video width="100%" className='videoTag' height="315" controls controlsList="nodownload" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end mb-4">
                                <button type="button" className="common-btn-class btn-cancel-border btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4 btn-scondary-custom" data-bs-dismiss="modal" onClick={helpPopUpCloseClick}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}
