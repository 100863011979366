import React, { useState, useRef, useEffect } from "react";
import * as XLSX from 'xlsx';
import { gridLoad } from "../../Services/UserMappingServices";
import { ExportToCsv } from "export-to-csv";
import MappedUsersGrid from './MappedUsersGrid';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import { useHistory, useLocation } from "react-router-dom";
import MasterComponent from './UserMappingMastercomponent'
import constants from '../../Common/Constants';



export default function ImageUpload() {
  let GridLoadarray: any[] | (() => any[]) = [];
  var ChatDetails: any[] = [];
  const [image, setImage] = useState("");
  const [checkString, setString] = useState("2");
  const inputFile: any = useRef(null);
  const [gridData, setGridData] = useState(GridLoadarray);
  let history = useHistory();
  const [migrationGridData, setMigrationGridData] = useState([ChatDetails]);
  let [alertstatus, setalertstatus] = useState('')
  let local = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;
  let [workspaceURL, setWorkspaceURL] = useState('')
  let [TenantID, setTenantID] = useState('')


  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;




  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;
  }
  useEffect(() => {
    loadGridPage();

  }, [])



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertstatus = "Your session has expired. Please log in again to continue using the application."
      setalertstatus(alertstatus);

      //#region Show PopUp
      let popup = document.getElementById("failure")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion





  const handleFileSelect = async (e: any) => {

    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      ////console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.
      await handleFileUpload(files[0]);
    }
  };

  const handleFileUpload = async (file: any) => {
    try {

      var Chatdetails: any = [];
      //const file = image;
      if (file != undefined && (file.name.includes('.csv'))) {
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header:
              ["SlackUserName", "SlackUserMailID", "MSTeamsUserName", "MSTeamsUserMailID", "********"], defval: ""
          });
          Chatdetails = data;
          if (Chatdetails[0].SlackUserName != "SlackUserName" || Chatdetails[0].SlackUserMailID != "SlackUserMailID" || Chatdetails[0].MSTeamsUserMailID != "MSTeamsUserMailID" || Chatdetails[0].MSTeamsUserName != "MSTeamsUserName" ||
            Object.keys(Chatdetails[0]).length > 4 || Object.keys(Chatdetails[0]).length < 4) {
            enablealertpopup();
            setalertstatus('Please provide valid template!')
          }
          else if (Chatdetails.length == 1) {
            setalertstatus('Empty Template provided!');
            enablealertpopup();
          }
          else {
            Chatdetails.shift()
            var response = importmethod(JSON.stringify(Chatdetails))
          }
        };
        reader.readAsBinaryString(file);
      }
      else {

        setalertstatus("Please provide valid template!")
        enablealertpopup();
      }
    }
    catch (error) {
    }
  }

  const importmethod = async (Chatdetails: any) => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          token: clientToken,
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/importMappedUser`,
          data: {

            "clientID": clientID,
            "csv": Chatdetails
          }
        })
        let loader: any = document.getElementById("importloader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        setMigrationGridData(data.data.data)
        setString("2");
        if (data.data.statusCode == 200) {
          if (data.data.data[1].length == 0) {
            setalertstatus("Please connect Settings!")
            enablealertpopup();
          }
          else if (data.data.data[1].length != 0) {
            if (data.data.data[1][0].SlackWorkspaceURL == "" || data.data.data[1][0].SlackWorkspaceURL == undefined || data.data.data[1][0].SlackWorkspaceURL == null) {
              setalertstatus("Please Connect Slack!");
              enablealertpopup();
            }
            else if (data.data.data[1][0].TenantID == "" || data.data.data[1][0].TenantID == undefined || data.data.data[1][0].TenantID == null) {
              setalertstatus("Please connect MSTeams!");
              enablealertpopup();
            }

            else {
              enablesuccesspopup();
              let clearbrowse: any = document.getElementById("uploadbtn");
              clearbrowse.value = "";
            }
          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {

          if (data.data.data == "Please Provide Valid Details") {
            createDynamicListItem1("Failed to proceed complete User Mapping due to the below reasons.", ["Invalid Slack or MS Teams User Details provided.", "Duplicate Slack User Mapping Found."])
            enableinvalidrecordspopup();
          }
          else {
            createDynamicListItem("Failed to proceed complete User Mapping due to the below reasons.", ["Invalid Slack or MS Teams User Details provided.", "Duplicate Slack User Mapping Found."])
            enableimportfailurepopup();
          }
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      setalertstatus('Something went wrong!Please try again or reach AVAMIGRATRON Support.')
      enablealertpopup();

    }
  }

  const createDynamicListItem = async (popupText: any, arrReasons: any) => {
    try {
      setalertstatus(popupText);
      let element = document.getElementById("dynamicData");
      let ulTag = document.createElement("ol");
      ulTag.classList.add('listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      throw error
    }
  }


  const createDynamicListItem1 = async (popupText: any, arrReasons: any) => {
    try {
      setalertstatus(popupText);
      let element: any = document.getElementById("dynamicData1");
      let ulTag: any = document.createElement("ol");
      ulTag.classList.add('listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      throw error
    }
  }

  function deleteChild() {
    try {
      var e: any = document.getElementById("dynamicData");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    } catch (error) {
      throw (error);
    }
  }

  function deleteChild1() {
    try {
      var e: any = document.getElementById("dynamicData1");
      var child = e.lastElementChild;
      while (child) {
        e.removeChild(child);
        child = e.lastElementChild;
      }
    } catch (error) {
      throw (error);
    }
  }

  const loadGridPage = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let loader = document.getElementById("loader")
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/getUserMappingGrid`,
          token: clientToken,
          data: {
            "clientID": clientID,
            "type": "userslist",
            "offsetValue": 1,
            "columnName": "",
            "columnType": "",
            "searchString": "",
            "trgtColumnName": "",
            "trgtColumnType": "",
            "trgtSearchString": ""
          }
        }
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if(data.data.data[2][0].SourceUserCount>0) {
          loader?.setAttribute('hidden', 'hidden');
          workspaceURL = data.data.data[4][0].SlackWorkspaceName;
          TenantID = data.data.data[5][0].TenantID;
          setWorkspaceURL(workspaceURL);
          setTenantID(TenantID);
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      setalertstatus('Something went wrong!Please try again or reach AVAMIGRATRON Support.')
      enablealertpopup();
    }
  }

  const enablealertpopup = () => {
    try {
      document.getElementById("invalidtemplate")?.removeAttribute("aria-hidden");
      document.getElementById("invalidtemplate")?.setAttribute("aria-modal", "true");
      document.getElementById("invalidtemplate")?.setAttribute("role", "dialog");
      document.getElementById("invalidtemplate")?.setAttribute("class", "modal fade show");
      document.getElementById("invalidtemplate")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const enableimportfailurepopup = () => {
    try {
      document.getElementById("failure")?.removeAttribute("aria-hidden");
      document.getElementById("failure")?.setAttribute("aria-modal", "true");
      document.getElementById("failure")?.setAttribute("role", "dialog");
      document.getElementById("failure")?.setAttribute("class", "modal fade show");
      document.getElementById("failure")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const enableinvalidrecordspopup = () => {
    try {
      document.getElementById("invalidrecords")?.removeAttribute("aria-hidden");
      document.getElementById("invalidrecords")?.setAttribute("aria-modal", "true");
      document.getElementById("invalidrecords")?.setAttribute("role", "dialog");
      document.getElementById("invalidrecords")?.setAttribute("class", "modal fade show");
      document.getElementById("invalidrecords")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const enablesuccesspopup = () => {
    try {
      document.getElementById("success")?.removeAttribute("aria-hidden");
      document.getElementById("success")?.setAttribute("aria-modal", "true");
      document.getElementById("success")?.setAttribute("role", "dialog");
      document.getElementById("success")?.setAttribute("class", "modal fade show");
      document.getElementById("success")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const okclick = () => {
    try {
      document.getElementById("success")?.setAttribute("class", "modal fade");
      document.getElementById("success")?.setAttribute("style", "display:none");
      document.getElementById("success")?.setAttribute("aria-hidden", "true");
      document.getElementById("success")?.removeAttribute("aria-modal");
      document.getElementById("success")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      window.location.pathname = "/UserMapping";
    }
    catch (error) {
    }
  }


  const invalidrecordsokclick = () => {
    try {
      document.getElementById("invalidrecords")?.setAttribute("class", "modal fade");
      document.getElementById("invalidrecords")?.setAttribute("style", "display:none");
      document.getElementById("invalidrecords")?.setAttribute("aria-hidden", "true");
      document.getElementById("invalidrecords")?.removeAttribute("aria-modal");
      document.getElementById("invalidrecords")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      let clearbrowse: any = document.getElementById("uploadbtn");
      clearbrowse.value = "";
      deleteChild1();
    }
    catch (error) {

    }
  }

  const okclick1 = () => {
    try {
      if (isSessionEpired === false) {

        document.getElementById("failure")?.setAttribute("class", "modal fade");
        document.getElementById("failure")?.setAttribute("style", "display:none");
        document.getElementById("failure")?.setAttribute("aria-hidden", "true");
        document.getElementById("failure")?.removeAttribute("aria-modal");
        document.getElementById("failure")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        exportmethod()
        let clearbrowse: any = document.getElementById("uploadbtn");
        clearbrowse.value = "";
        deleteChild();
      }
      else {
        window.location.pathname = "/Login";

      }

    }
    catch (error) {
    }
  }

  const invalidtemplatealetokclick = () => {
    try {
      if (isSessionEpired === false) {

        document.getElementById("invalidtemplate")?.setAttribute("class", "modal fade");
        document.getElementById("invalidtemplate")?.setAttribute("style", "display:none");
        document.getElementById("invalidtemplate")?.setAttribute("aria-hidden", "true");
        document.getElementById("invalidtemplate")?.removeAttribute("aria-modal");
        document.getElementById("invalidtemplate")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        let clearbrowse: any = document.getElementById("uploadbtn");
        clearbrowse.value = "";
      }
      else {
        window.location.pathname = "/Login";
      }
    }
    catch (error) {
    }
  }

  const exportmethod = async () => {
    try {

      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          token: clientToken,
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/importMappedUser`,
          data: {
            "clientID": clientID,
            "csv": migrationGridData
          }
        })
        let data: any = await gridLoad(config);
        if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          let exportData = migrationGridData
          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Invalid Users List',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "Invalid Users list"
          };
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(exportData);
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      setalertstatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      enablealertpopup();
    }
  }

  const onButtonClick = async () => {
    try {

      // await loadGridPage();
      if ((workspaceURL == null || workspaceURL == "") || (TenantID == null || TenantID == "")) {
        setalertstatus("Please connect Settings!");
        enablealertpopup();
      }
      else {
        await inputFile.current.click();
      }
    } catch (error) {
      setalertstatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      enablealertpopup();
    }

  };

  return (
    <div className="float-start">
      <button type="button" className="common-btn-class btn btn-user-mapping btn-primary-theme" onClick={onButtonClick} >
        <span className="me-2" >
          <img src="/Images/import.svg" alt="import" />
        </span>Import Mapped Users
        <input style={{ display: "none" }} ref={inputFile} onChange={(e) => handleFileSelect(e)} id="uploadbtn" type="file" ></input>
      </button>
      <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/success.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Success!</h5>
                <p className="success-text w-100 text-center mb-4">Imported mapped Slack and MS Teams users successfully</p>
                <button type="button" id="okbtn" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={okclick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="failure" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{alertstatus}</p>
                {/* <p className="success-text w-100 usermappingtextalign mb-4">Failed to proceed complete User Mapping due to the below reasons.</p> */}
                {/* <div className="usermapping-poptextalign">
                  <p className="textalignusermapping">1. Invalid Slack or MS Teams User Details provided.</p>
                  <p className="textalignusermapping">2. Duplicate Slack User Mapping Found.</p>
                </div> */}
                <div id="dynamicData" />
                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={okclick1}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="invalidrecords" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{alertstatus}</p>
                {/* <p className="success-text w-100 usermappingtextalign mb-4">Failed to proceed complete User Mapping due to the below reasons.</p> */}
                {/* <div className="usermapping-poptextalign">
                  <p className="textalignusermapping">1. Invalid Slack or MS Teams User Details provided.</p>
                  <p className="textalignusermapping">2. Duplicate Slack User Mapping Found.</p>
                </div> */}
                <div id="dynamicData1" />
                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={invalidrecordsokclick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="invalidtemplate" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{alertstatus}</p>
                <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={invalidtemplatealetokclick} onChange={handleFileSelect}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" id="loader" hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
            <span className="visually-hidden" />
          </div>
        </div>
      </div>
      <div className="overlay" id="importloader" hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
            <span className="visually-hidden" />
          </div>
          <p className="text-center">Please wait. We are mapping Slack and MS Teams users.</p>
        </div>
      </div>
      <div id="fadeDiv" className="modal-backdrop fade show" hidden />
    </div>
  );
};

// export default ImageUpload;
