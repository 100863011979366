import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { gridLoad, loadCountryCode } from "../../Services/ProfileServices";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";

import postalCodes from 'postal-codes-js'

export default function CheckoutForm(paymentDetails: any) {

  const stripe = useStripe();
  const elements = useElements();
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }
  let billing: any = { ClientName: "", AddressLine1: "", AddressLine2: "", State: "", City: "", Country: "", ZipCode: "" }
  let [clientBillingDetails, setClientBillingDetails] = useState(billing)


  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let [alertMessage, setAlertMessage] = useState("")
  let [countries, setCountries] = useState([])
  let [states, setStates] = useState([]);
  let [cities, setCities] = useState([]);
  let [selectedCountry, setselectedCountry] = useState("")
  let [selectedState, setselectedState] = useState("")
  let [selectedCities, setselectedCities] = useState("")
  let [tempState, settempState] = useState("")
  let [countryISO, setcountryISO] = useState("")
  let [isStateInitial, setIsStateInitial] = useState(false);
  let [isCityInitial, setIsCityInitial] = useState(false);


  useEffect(() => {

    if (selectedCountry != "") {
      loadCountryDropDown("state")
    }
  }, [selectedCountry])

  useEffect(() => {
    if (selectedCountry != "" && selectedState != "") {
      loadCountryDropDown("city")
    }
  }, [selectedState])

  useEffect(() => {



    if (selectedCountry == "") {

      loadCountryDropDown("country")
    }

    if (paymentDetails.billingDetails.AddressLine1 == null && paymentDetails.billingDetails.AddressLine2 == null && paymentDetails.billingDetails.City == null && paymentDetails.billingDetails.Country == null && paymentDetails.billingDetails.State == null && paymentDetails.billingDetails.ZipCode == null) {
      clientBillingDetails = { ...clientBillingDetails, ClientName: paymentDetails.billingDetails.ClientName }
      setClientBillingDetails(clientBillingDetails)
      let payNowButton: any = document.getElementById("submit")
      payNowButton.setAttribute('disabled', 'disabled')
    }
    else {
      clientBillingDetails = {
        ...clientBillingDetails, ClientName: paymentDetails.billingDetails.ClientName, AddressLine1: paymentDetails.billingDetails.AddressLine1,// Country: paymentDetails.billingDetails.Country,
        AddressLine2: paymentDetails.billingDetails.AddressLine2, ZipCode: paymentDetails.billingDetails.ZipCode// City: paymentDetails.billingDetails.City, State: paymentDetails.billingDetails.State
      }
      setClientBillingDetails(clientBillingDetails)
      // let State : any = paymentDetails.billingDetails.State
      // selectedState(State)
      // selectedCities()
      // selectedCountry()
    }



    if (!stripe) {
      return;
    }



    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then((paymentIntent: any) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertMessage = "Your session has expired. Please log in again to continue using the application."
      setAlertMessage(alertMessage);
      //#region Show PopUp
      let popup = document.getElementById("alertPopUp")
      //#region Show PopUp
      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  const loadCountryDropDown = async (value: any) => {
    try {
      let payNowButton: any = document.getElementById("submit")
      var config: any;
      if (value == "country") {
        config = {
          method: 'get',
          url: 'https://countriesnow.space/api/v0.1/countries'
        }
        let data: any = await loadCountryCode(config)

        setCountries(data.data.data);
        if (paymentDetails.billingDetails.Country != undefined && paymentDetails.billingDetails.Country != null && paymentDetails.billingDetails.Country != '') {
          let dropdown1: any = document.getElementById("CountryDp")
          dropdown1.value = paymentDetails.billingDetails.Country;
          selectedCountry = paymentDetails.billingDetails.Country
          setselectedCountry(selectedCountry)
        }
      }
      else if (value == "state") {
        if (selectedCountry == "Select Country") {
          selectedState = "Select State";
          setselectedState(selectedState)
          setStates([]);
          selectedCities = "Select Cities";
          setselectedCities(selectedCities);
          setCities([]);
          payNowButton?.setAttribute('disabled', 'disabled');
        }
        else {

          var data: any;
          // if (selectedState == "" || (selectedState != "" && paymentDetails.billingDetails.Country != selectedCountry && tempState != selectedState)) {
          data = JSON.stringify({
            "country": `${selectedCountry}`
          });
          config = {
            method: 'post',
            url: 'https://countriesnow.space/api/v0.1/countries/states',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };

          let response: any = await loadCountryCode(config)

          setStates(response.data.data.states)
          if (paymentDetails.billingDetails.State != undefined && paymentDetails.billingDetails.State != null && paymentDetails.billingDetails.State != '') {
            let dropdown2: any = document.getElementById("StatesDp")
            dropdown2.value = paymentDetails.billingDetails.State
            var state = paymentDetails.billingDetails.State
            tempState = paymentDetails.billingDetails.State
            settempState(tempState)
            if (isStateInitial) {
              selectedState = "Select State";
              dropdown2.value = "Select State";
              setselectedState(selectedState)
              selectedCities = "Select City";
              let dropdown: any = document.getElementById("CitiesDp")
              dropdown.value = "Select City";
              setselectedCities(selectedCities);
              setIsStateInitial(false);
              payNowButton?.setAttribute('disabled', 'disabled');
            }
            else {
              setselectedState(state)
            }
          }
        }
      }
      else {
        if (selectedState == "Select State") {
          selectedCities = "Select City";
          setselectedCities(selectedCities);
          setCities([]);
          payNowButton?.setAttribute('disabled', 'disabled');
        }
        else {

          data = JSON.stringify({
            "country": `${selectedCountry}`,
            "state": `${selectedState}`
          });
          config = {
            method: 'post',
            url: 'https://countriesnow.space/api/v0.1/countries/state/cities',
            headers: {
              'Content-Type': 'application/json'
            },
            data: data
          };

          let response: any = await loadCountryCode(config)

          setCities(response.data.data)
          if (paymentDetails.billingDetails.City != undefined && paymentDetails.billingDetails.City != null && paymentDetails.billingDetails.City != '') {
            let dropdown: any = document.getElementById("CitiesDp")
            dropdown.value = paymentDetails.billingDetails.City
            var cities = paymentDetails.billingDetails.City
            if (isCityInitial) {
              selectedCities = "Select City";
              dropdown.value = "Select City";
              setselectedCities(selectedCities);
              setIsCityInitial(false)
              payNowButton?.setAttribute('disabled', 'disabled');
            }
            else {
              setselectedCities(cities)
            }
          }
        }
      }

    }
    catch (error: any) {

    }
  }

  const storeBillingDetails = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          token: clientToken,
          url: `${baseURL}/upgradeLicense/storeBillingDetails`,
          data: {
            "clientID": clientID,
            "BillingName": `${clientBillingDetails.ClientName}`,
            "AddressLine1": `${clientBillingDetails.AddressLine1}`,
            "AddressLine2": `${clientBillingDetails.AddressLine2}`,
            "State": `${selectedState}`,
            "City": `${selectedCities}`,
            "Country": `${selectedCountry}`,
            "ZipCode": ''//`${clientBillingDetails.ZipCode}`
          },
        })
        let data: any = await gridLoad(config);

        let popup: any;
        if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.statusCode != 200) {
          setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
          popup = document.getElementById("alertPopUp")

          //#region Show PopUp
          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
          document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
          //#endregion

          // let cardDiv = document.getElementById("cardDetails")
          // let closePopUp = document.getElementById("fadeDiv")
          // let closeButton: any = document.getElementById("closebutton")
          // let hyperLink: any = document.getElementById("termsId")

          // cardDiv?.setAttribute("style", "display:none")
          // closePopUp?.setAttribute("style", "display:none");
          // closePopUp?.removeAttribute("hidden");
          // closeButton?.removeAttribute('disabled');
          // hyperLink?.classList.remove("invoice-fail")
          return false
        }

        else {
          return true
        }
      }
      else {
        localStorage.clear();
      }

    }
    catch (error: any) {
      throw (error);
    }
  }

  const handleSubmit = async (e: any) => {
    try {
      let closeButton: any = document.getElementById("closebutton")
      let hyperLink: any = document.getElementById("termsId")
      let cancelButton: any = document.getElementById("paymentCancelButton");

      closeButton?.setAttribute("disabled", "disabled")
      hyperLink?.classList.add("invoice-fail")
      cancelButton?.setAttribute("disabled", "disabled")



      e.preventDefault();
      if (!stripe || !elements) {
        let payNowButton: any = document.getElementById("submit")
        payNowButton?.setAttribute('disabled', 'disabled');
        return;
      }
      else {
        let payNowButton: any = document.getElementById("submit")
        payNowButton.removeAttribute('disabled');
      }

      setIsLoading(true);

      var redirectURL = window.location.origin
      let result: any = await storeBillingDetails()
      if (result != undefined && result == true) {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${redirectURL}/SuccessPage?LicenseType=${paymentDetails.license}&PageType=${paymentDetails.pageType}&Plantype=${paymentDetails.billingDetails.licensePlan}`,
          },
        });

        if (error != undefined && error.code == "card_declined") {
          try {
            if (clientID != undefined && clientToken != undefined && error.payment_intent != undefined) {
              const paymentIntent: any = error.payment_intent.id
              const status = 'Failed'
              const licenseType = paymentDetails.license;
              let config = ({
                method: "POST",
                token: clientToken,
                url: `${baseURL}/upgradeLicense/storeLicenseCount`,
                data: {
                  "clientID": clientID,
                  "LicenseType": `${licenseType}`,
                  "paymentIntent": `${paymentIntent}`,
                  "status": `${status}`,
                  "PlanType": paymentDetails.billingDetails.licensePlan,
                  "userCount": paymentDetails.billingDetails.userCount
                },
              })
              let data: any = await gridLoad(config);
              let popup;
              if (data.data.statusCode != 200) {
                setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                popup = document.getElementById("alertPopUp")
              }
              else if (data.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
              }
              else {
                if (data.data.statusCode == 200 && data.data.data.clientToken == null && data.data.data.clientToken == "") {
                  setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                  popup = document.getElementById("alertPopUp")
                }

                else {
                  //#region Update and Frame Client Session
                  localStorage.removeItem("cid_t");
                  let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                  var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                  let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                  localStorage.setItem("cid_t", enPass);
                  //#endregion
                  if (data.data.statusCode == 200 && data.data.data.paymentStatus != null && data.data.data.paymentStatus != "") {
                    popup = document.getElementById("alertPopUp")
                    setAlertMessage("Failed to Complete Payment!")
                    let cardDiv = document.getElementById("cardDetails")
                    cardDiv?.setAttribute("style", "display:none")
                    let closePopUp = document.getElementById("fadeDiv")
                    closePopUp?.setAttribute("style", "display:none");
                    closePopUp?.removeAttribute("hidden");
                  }
                  else {
                    popup = document.getElementById("successPopUp")
                  }
                }
              }
              //#region Show PopUp
              popup?.removeAttribute("aria-hidden");
              popup?.setAttribute("aria-modal", "true");
              popup?.setAttribute("role", "dialog");
              popup?.setAttribute("class", "modal fade show");
              popup?.setAttribute("style", "display:block");
              document.getElementById("fadeDiv")?.removeAttribute("hidden");
              document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
              //#endregion
            }
            else {
              localStorage.clear();
            }

            closeButton?.removeAttribute('disabled');
            hyperLink?.classList.remove("invoice-fail")
            cancelButton?.removeAttribute('disabled');
          }
          catch (error: any) {

            let cardDiv = document.getElementById("cardDetails")
            let closeButton: any = document.getElementById("closebutton")
            let hyperLink: any = document.getElementById("termsId")

            cardDiv?.setAttribute("style", "display:none")
            closeButton?.removeAttribute('disabled');
            hyperLink?.classList.remove("invoice-fail")
            cancelButton?.removeAttribute('disabled');


            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
          }
        }
        else if (error != undefined) {
          if (error.code == "payment_intent_authentication_failure") {
            let cardDiv = document.getElementById("cardDetails")
            cardDiv?.setAttribute("style", "display:none")
            let closePopUp = document.getElementById("fadeDiv")
            closePopUp?.setAttribute("style", "display:none");
            closePopUp?.removeAttribute("hidden");
          }
          // else if (error.type == "validation_error") {
          //   // let closePopUp: any = document.getElementById("fadeDiv");
          //   // closePopUp?.setAttribute("style", "display:none");
          //   let popup = document.getElementById("alertPopUp")
          //   popup?.setAttribute("class", "modal fade");
          //   popup?.setAttribute("style", "display:none");
          //   popup?.setAttribute("aria-hidden", "true");
          //   popup?.removeAttribute("aria-modal");
          //   popup?.removeAttribute("role");
          //   // document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
          // }
        }
      }
      setIsLoading(false);
      closeButton?.removeAttribute('disabled');
      hyperLink?.classList.remove("invoice-fail")
      cancelButton?.removeAttribute('disabled');



    }
    catch (error: any) {
    }
  };

  const handleOnChange = async (e: any) => {
    try {

      if (e.target.name == "ZipCode") {
        let result: any = /^[0-9]{0,40}$/.test(e.target.value)
        if (result == true) {
          clientBillingDetails = { ...clientBillingDetails, ZipCode: e.target.value }
        }
      }
      else {
        clientBillingDetails = { ...clientBillingDetails, [e.target.name]: e.target.value }
      }
      setClientBillingDetails(clientBillingDetails)

      let zipCodeInput: any = document.getElementById("zipCode")
      let stateInput: any = document.getElementById("StatesDp")
      let cityInput: any = document.getElementById("CitiesDp")
      let countryInput: any = document.getElementById("CountryDp")
      let Address2Input: any = document.getElementById("Address2")
      let Address1Input: any = document.getElementById("Address1")
      let ClientNameInput: any = document.getElementById("clientName")

      let emptyZipCode: any = document.getElementById("emptyZipCode");
      let emptyState: any = document.getElementById("emptyState");
      let emptyCountry: any = document.getElementById("emptyCountry");
      let emptyCity: any = document.getElementById("emptyCity");
      let emptyAddress2: any = document.getElementById("emptyAddress2");
      let emptyAddress1: any = document.getElementById("emptyAddress1");
      let emptyName: any = document.getElementById("emptyName");

      let invalidZipCode: any = document.getElementById("invalidZipCode");
      let invalidState: any = document.getElementById("invalidState");
      let invalidCity: any = document.getElementById("invalidCity");
      let invalidAddress2: any = document.getElementById("invalidAddress2");
      let invalidAddress1: any = document.getElementById("invalidAddress1");
      let invalidName: any = document.getElementById("invalidName");

      if (e.target.name == "ClientName") {

        ClientNameInput?.classList.remove("Input-invalid")
        emptyName?.setAttribute('hidden', 'hidden')
        invalidName?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "AddressLine1") {

        Address1Input?.classList.remove("Input-invalid")
        emptyAddress1?.setAttribute('hidden', 'hidden')
        invalidAddress1?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "AddressLine2") {

        Address2Input?.classList.remove("Input-invalid")
        emptyAddress2?.setAttribute('hidden', 'hidden')
        invalidAddress2?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "CitiesDp") {

        cityInput?.classList.remove("Input-invalid")
        emptyCity?.setAttribute('hidden', 'hidden')
        invalidCity?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "StatesDp") {

        stateInput?.classList.remove("Input-invalid")
        emptyState?.setAttribute('hidden', 'hidden')
        invalidState?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "CountryDp") {

        countryInput?.classList.remove("Input-invalid")
        emptyCountry?.setAttribute('hidden', 'hidden')

      }


      // zipCodeInput?.classList.remove("Input-invalid")
      // stateInput?.classList.remove("Input-invalid")
      // countryInput?.classList.remove("Input-invalid")
      // cityInput?.classList.remove("Input-invalid")
      // Address2Input?.classList.remove("Input-invalid")
      // Address1Input?.classList.remove("Input-invalid")
      // ClientNameInput?.classList.remove("Input-invalid")




    }
    catch (error: any) {

    }
  }

  const regexValidation = async (e: any) => {
    try {
      let checkAllfields: any = false;
      let payNowButton: any = document.getElementById("submit")

      let zipCodeInput: any = document.getElementById("zipCode")
      let stateInput: any = document.getElementById("StatesDp")
      let cityInput: any = document.getElementById("CitiesDp")
      let countryInput: any = document.getElementById("CountryDp")
      let Address2Input: any = document.getElementById("Address2")
      let Address1Input: any = document.getElementById("Address1")
      let ClientNameInput: any = document.getElementById("clientName")

      zipCodeInput?.classList.remove("Input-focused")
      stateInput?.classList.remove("Input-focused")
      countryInput?.classList.remove("Input-focused")
      cityInput?.classList.remove("Input-focused")
      Address2Input?.classList.remove("Input-focused")
      Address1Input?.classList.remove("Input-focused")
      ClientNameInput?.classList.remove("Input-focused")


      let emptyZipCode: any = document.getElementById("emptyZipCode");
      let emptyState: any = document.getElementById("emptyState");
      let emptyCountry: any = document.getElementById("emptyCountry");
      let emptyCity: any = document.getElementById("emptyCity");
      let emptyAddress2: any = document.getElementById("emptyAddress2");
      let emptyAddress1: any = document.getElementById("emptyAddress1");
      let emptyName: any = document.getElementById("emptyName");

      let invalidZipCode: any = document.getElementById("invalidZipCode");
      let invalidState: any = document.getElementById("invalidState");
      let invalidCity: any = document.getElementById("invalidCity");
      let invalidAddress2: any = document.getElementById("invalidAddress2");
      let invalidAddress1: any = document.getElementById("invalidAddress1");
      let invalidName: any = document.getElementById("invalidName");

      let invalidRegexName: any = document.getElementById("RegexValidationName");
      let invalidRegexAddress1: any = document.getElementById("RegexValidationAddress1");
      let invalidRegexAddress2: any = document.getElementById("RegexValidationAddress2");

      if (e.target.name == "ZipCode") {
        if (e.target.value == "") {
          emptyZipCode?.removeAttribute('hidden')
          invalidZipCode?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          zipCodeInput?.classList.add("Input-invalid")
        }
        else {
          let result = /^[0-9]{1,12}$/.test(e.target.value)
          let testPOstalCode: any = postalCodes.validate(countryISO, e.target.value)


          if (testPOstalCode != true) {
            invalidZipCode?.removeAttribute('hidden')
            emptyZipCode?.setAttribute('hidden', 'hidden')
            zipCodeInput?.classList.add("Input-invalid")
          }
          else {
            zipCodeInput?.classList.remove("Input-invalid")
            zipCodeInput?.classList.remove("Input-focused")
            emptyZipCode?.setAttribute('hidden', 'hidden')
            invalidZipCode?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.name == "StatesDp") {
        payNowButton?.setAttribute('disabled', 'disabled');
        if (selectedState == "" || selectedState == "Select State") {
          emptyState?.removeAttribute('hidden')
          invalidState?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          stateInput?.classList.add("Input-invalid")
        }
        else {
          // let result = e.target.value.length <= 32
          // if (result == false) {
          //   invalidState?.removeAttribute('hidden')
          //   emptyState?.setAttribute('hidden', 'hidden')
          //   stateInput?.classList.add("Input-invalid")
          // }
          // else {
          stateInput?.classList.remove("Input-invalid")
          stateInput?.classList.remove("Input-focused")
          emptyState?.setAttribute('hidden', 'hidden')
          invalidState?.setAttribute('hidden', 'hidden')
          // }
        }
      }
      else if (e.target.name == "CitiesDp") {
        checkAllfields = true;
        if (selectedCities == "" || selectedCities == "Select City") {
          emptyCity?.removeAttribute('hidden')
          invalidCity?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          cityInput?.classList.add("Input-invalid")
        }
        else {
          // let result = e.target.value.length <= 30
          // if (result == false) {
          //   invalidCity?.removeAttribute('hidden')
          //   emptyCity?.setAttribute('hidden', 'hidden')
          //   cityInput?.classList.add("Input-invalid")
          // }
          // else {
          cityInput?.classList.remove("Input-invalid")
          cityInput?.classList.remove("Input-focused")
          emptyCity?.setAttribute('hidden', 'hidden')
          invalidCity?.setAttribute('hidden', 'hidden')
          // }
        }
      }
      else if (e.target.name == "CountryDp") {
        payNowButton?.setAttribute('disabled', 'disabled');
        if (selectedCountry == "" || selectedCountry == "Select Country") {
          emptyCountry?.removeAttribute('hidden')
          // invalidCity?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          countryInput?.classList.add("Input-invalid")
        }
        else {
          // let result = e.target.value.length <= 30
          // if (result == false) {
          //   invalidCity?.removeAttribute('hidden')
          //   emptyCity?.setAttribute('hidden', 'hidden')
          //   cityInput?.classList.add("Input-invalid")
          // }
          // else {
          countryInput?.classList.remove("Input-invalid")
          countryInput?.classList.remove("Input-focused")
          emptyCountry?.setAttribute('hidden', 'hidden')
          // invalidCity?.setAttribute('hidden', 'hidden')
          // }
        }
      }
      else if (e.target.name == "AddressLine2") {
        if (e.target.value == "") {
          emptyAddress2?.removeAttribute('hidden')
          invalidRegexAddress2?.setAttribute('hidden', 'hidden')
          invalidAddress2?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          Address2Input?.classList.add("Input-invalid")
        }
        else {
          let result = e.target.value.length <= 44
          let result2 = /^[0-9a-zA-Z!@#$%^&*()_+=[\]{}|\\:;'",.?\/\s]+$/.test(e.target.value);
          if (result == false) {
            invalidAddress2?.removeAttribute('hidden')
            emptyAddress2?.setAttribute('hidden', 'hidden')
            Address2Input?.classList.add("Input-invalid")
            invalidRegexAddress2?.setAttribute('hidden', 'hidden')
          }
          else if (result2 == false) {
            invalidRegexAddress2?.removeAttribute('hidden')
            invalidAddress2?.setAttribute('hidden', 'hidden')
            emptyAddress2?.setAttribute('hidden', 'hidden')
            Address2Input?.classList.add("Input-invalid")

          }
          else {
            Address2Input?.classList.remove("Input-invalid")
            Address2Input?.classList.remove("Input-focused")
            emptyAddress2?.setAttribute('hidden', 'hidden')
            invalidAddress2?.setAttribute('hidden', 'hidden')
            invalidRegexAddress2?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.name == "AddressLine1") {
        if (e.target.value == "") {
          emptyAddress1?.removeAttribute('hidden')
          invalidAddress1?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          Address1Input?.classList.add("Input-invalid")
          invalidRegexAddress1?.setAttribute('hidden', 'hidden')
        }
        else {
          let result = e.target.value.length <= 44
          let result2 = /^[0-9a-zA-Z!@#$%^&*()_+=[\]{}|\\:;'",.?\/\s]+$/.test(e.target.value);

          if (result == false) {
            invalidAddress1?.removeAttribute('hidden')
            emptyAddress1?.setAttribute('hidden', 'hidden')
            Address1Input?.classList.add("Input-invalid")
            invalidRegexAddress1?.setAttribute('hidden', 'hidden')
          }
          else if (result2 == false) {
            invalidRegexAddress1?.removeAttribute('hidden')
            invalidAddress1?.setAttribute('hidden', 'hidden')
            emptyAddress1?.setAttribute('hidden', 'hidden')
            Address1Input?.classList.add("Input-invalid")
          }
          else {
            Address1Input?.classList.remove("Input-invalid")
            Address1Input?.classList.remove("Input-focused")
            emptyAddress1?.setAttribute('hidden', 'hidden')
            invalidAddress1?.setAttribute('hidden', 'hidden')
            invalidRegexAddress1?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.name == "ClientName") {
        if (e.target.value == "") {
          emptyName?.removeAttribute('hidden')
          invalidName?.setAttribute('hidden', 'hidden')
          payNowButton?.setAttribute('disabled', 'disabled');
          ClientNameInput?.classList.add("Input-invalid")
          invalidRegexName?.setAttribute('hidden', 'hidden')
        }
        else {
          let result = e.target.value.length <= 80
          let result2 = /^[a-zA-Z ]{1,80}$/.test(e.target.value);
          if (result == false) {
            invalidName?.removeAttribute('hidden')
            emptyName?.setAttribute('hidden', 'hidden')
            ClientNameInput?.classList.add("Input-invalid")
            invalidRegexName?.setAttribute('hidden', 'hidden')
          }
          else if (result2 == false) {
            invalidRegexName?.removeAttribute('hidden')
            invalidName?.setAttribute('hidden', 'hidden')
            emptyName?.setAttribute('hidden', 'hidden')
            ClientNameInput?.classList.add("Input-invalid")
          }
          else {
            ClientNameInput?.classList.remove("Input-invalid")
            ClientNameInput?.classList.remove("Input-focused")
            emptyName?.setAttribute('hidden', 'hidden')
            invalidName?.setAttribute('hidden', 'hidden')
            invalidRegexName?.setAttribute('hidden', 'hidden')
          }
        }
      }
      if (clientBillingDetails.AddressLine1 != "" && selectedCountry != "" && clientBillingDetails.AddressLine2 != "" && selectedState != "" && selectedCities != "" && selectedCountry != "Select Country" && selectedCities != "Select City" && selectedState != "Select State") {
        // let check1 = /^[0-9]{1,12}$/.test(clientBillingDetails.ZipCode)
        let check2 = clientBillingDetails.ClientName.length <= 80
        let check3 = /^[a-zA-Z ]{1,80}$/.test(clientBillingDetails.ClientName);
        let check4 = clientBillingDetails.AddressLine2.length <= 44
        let check1 = /^[0-9a-zA-Z!@#$%^&*()_+=[\]{}|\\:;'",.?\/\s]+$/.test(clientBillingDetails.AddressLine2);
        let check5 = clientBillingDetails.AddressLine1.length <= 44
        let check6 = /^[0-9a-zA-Z!@#$%^&*()_+=[\]{}|\\:;'",.?\/\s]+$/.test(clientBillingDetails.AddressLine1);
        if (check1 && check2 && check3 && check4 && check5 && check6) {
          let payNowButton: any = document.getElementById("submit")
          payNowButton.removeAttribute('disabled');
        }
        else {
          payNowButton.setAttribute('disabled', 'disabled')
        }
      }
      else {
        payNowButton.setAttribute('disabled', 'disabled')
      }
    }
    catch (error: any) {

    }
  }

  const handleOnFocus = async (e: any) => {
    try {

      let inputField: any = document.getElementById(e.target.id)
      // if (e.target.name == "ZipCode") {
      inputField?.classList.add("Input-focused")
      // }

      let zipCodeInput: any = document.getElementById("zipCode")
      let stateInput: any = document.getElementById("StatesDp")
      let cityInput: any = document.getElementById("CitiesDp")
      let countryInput: any = document.getElementById("CountryDp")
      let Address2Input: any = document.getElementById("Address2")
      let Address1Input: any = document.getElementById("Address1")
      let ClientNameInput: any = document.getElementById("clientName")

      let emptyZipCode: any = document.getElementById("emptyZipCode");
      let emptyState: any = document.getElementById("emptyState");
      let emptyCountry: any = document.getElementById("emptyCountry");
      let emptyCity: any = document.getElementById("emptyCity");
      let emptyAddress2: any = document.getElementById("emptyAddress2");
      let emptyAddress1: any = document.getElementById("emptyAddress1");
      let emptyName: any = document.getElementById("emptyName");

      let invalidZipCode: any = document.getElementById("invalidZipCode");
      let invalidState: any = document.getElementById("invalidState");
      let invalidCity: any = document.getElementById("invalidCity");
      let invalidAddress2: any = document.getElementById("invalidAddress2");
      let invalidAddress1: any = document.getElementById("invalidAddress1");
      let invalidName: any = document.getElementById("invalidName");


      if (e.target.name == "CitiesDp") {

        cityInput?.classList.remove("Input-invalid")
        emptyCity?.setAttribute('hidden', 'hidden')
        invalidCity?.setAttribute('hidden', 'hidden')

      }
      else if (e.target.name == "StatesDp") {

        stateInput?.classList.remove("Input-invalid")
        emptyState?.setAttribute('hidden', 'hidden')
        invalidState?.setAttribute('hidden', 'hidden')
        setIsCityInitial(true);

      }
      else if (e.target.name == "CountryDp") {
        setIsStateInitial(true);
        countryInput?.classList.remove("Input-invalid")
        emptyCountry?.setAttribute('hidden', 'hidden')

      }

    }
    catch (error: any) {

    }
  }

  const handleCancel = async () => {
    try {
      if (paymentDetails.pageType == 'UpgradePlan') {
        window.location.href = '/UpgradePlan'
      }
      else {
        window.location.href = '/ChoosePlan'

      }
      // let cardNumberInput : any = document.getElementById("Field-numberInput")

      // cardNumberInput.value = ""

      let cardDiv = document.getElementById("cardDetails")
      let closePopUp = document.getElementById("fadeDiv")
      let closeButton: any = document.getElementById("closebutton")
      let hyperLink: any = document.getElementById("termsId")

      cardDiv?.setAttribute("style", "display:none")
      closePopUp?.setAttribute("style", "display:none");
      closePopUp?.removeAttribute("hidden");
      closeButton?.removeAttribute('disabled');
      hyperLink?.classList.remove("invoice-fail")

      let emptyZipCode: any = document.getElementById("emptyZipCode");
      let emptyState: any = document.getElementById("emptyState");
      let emptyCountry: any = document.getElementById("emptyCountry");
      let emptyCity: any = document.getElementById("emptyCity");
      let emptyAddress2: any = document.getElementById("emptyAddress2");
      let emptyAddress1: any = document.getElementById("emptyAddress1");

      let invalidZipCode: any = document.getElementById("invalidZipCode");
      let invalidState: any = document.getElementById("invalidState");
      let invalidCity: any = document.getElementById("invalidCity");
      let invalidAddress2: any = document.getElementById("invalidAddress2");
      let invalidAddress1: any = document.getElementById("invalidAddress1");

      emptyZipCode?.setAttribute('hidden', 'hidden')
      emptyState?.setAttribute('hidden', 'hidden')
      emptyCountry?.setAttribute('hidden', 'hidden')
      emptyCity?.setAttribute('hidden', 'hidden')
      emptyAddress2?.setAttribute('hidden', 'hidden')
      emptyAddress1?.setAttribute('hidden', 'hidden')
      invalidZipCode?.setAttribute('hidden', 'hidden')
      invalidState?.setAttribute('hidden', 'hidden')
      invalidCity?.setAttribute('hidden', 'hidden')
      invalidAddress2?.setAttribute('hidden', 'hidden')
      invalidAddress1?.setAttribute('hidden', 'hidden')

      let zipCodeInput: any = document.getElementById("zipCode")
      let stateInput: any = document.getElementById("StatesDp")
      let cityInput: any = document.getElementById("CitiesDp")
      let countryInput: any = document.getElementById("CountryDp")
      let Address2Input: any = document.getElementById("Address2")
      let Address1Input: any = document.getElementById("Address1")

      zipCodeInput?.classList.remove("Input-invalid")
      stateInput?.classList.remove("Input-invalid")
      countryInput?.classList.remove("Input-invalid")
      cityInput?.classList.remove("Input-invalid")
      Address2Input?.classList.remove("Input-invalid")
      Address1Input?.classList.remove("Input-invalid")


    }
    catch (error: any) {

    }
  }

  const handleCountrySelect = async (e: any) => {
    try {
      if (e.target.id == "CountryDp") {
        let dropdown: any = document.getElementById("CountryDp")
        var country = dropdown.options[dropdown.selectedIndex].value;
        setselectedCountry(country)
        var ISOValue: any = dropdown.options[dropdown.selectedIndex].id

        setcountryISO(ISOValue)
      }
      else if (e.target.id == "StatesDp") {
        let dropdown: any = document.getElementById("StatesDp")
        var state = dropdown.options[dropdown.selectedIndex].value;
        setselectedState(state)
      }
      else if (e.target.id == "CitiesDp") {
        let dropdown: any = document.getElementById("CitiesDp")
        var cities = dropdown.options[dropdown.selectedIndex].value;
        setselectedCities(cities)
      }
    }
    catch (error: any) {

    }
  }

  const popUpOkClick = (type: string) => {
    try {
      if (isSessionEpired === false) {
        //#region Hide PopUp
        let popup = document.getElementById("alertPopUp");
        popup?.setAttribute("class", "modal fade");
        popup?.setAttribute("style", "display:none");
        popup?.setAttribute("aria-hidden", "true");
        popup?.removeAttribute("aria-modal");
        popup?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        let submitButton = document.getElementById("saveButton")
        submitButton?.setAttribute("disabled", "disabled")
        if (type != "alert") {
          window.location.reload();
        }
      }
      else {
        window.location.pathname = "/Login";
      }
      //#endregion
    } catch (error) {

    }
  }

  return (
    <form id="payment-form" >
      <div className="col-md-12">
        <div className="billing-address text-start pe-3 billing-card-divider">
          <label className="payment-header ps-0 pb-2 pt-2">Billing Address</label>
          <div className="row">
            <div className="col-md-12 mb-2 ">
              <label htmlFor="clientName" className="Label-billing">Billing Name</label>
              <input type="text" name="ClientName" className="Input-billing clientName" id="clientName" value={clientBillingDetails.ClientName} onChange={(e) => handleOnChange(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)} />
              <label id="emptyName" className="Error-label" hidden={true}>Billing Name cannot be empty </label>
              <label id="RegexValidationName" className="Error-label" hidden={true}> Provide Valid Billing Name </label>
              <label id="invalidName" className="Error-label" hidden={true}>Name is too long</label>
            </div>
            <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="Address1" className="Label-billing">Address Line 1</label>
              <input type="text" name="AddressLine1" className="Input-billing Input--empty input-field-size" placeholder="Enter Address" id="Address1" value={clientBillingDetails.AddressLine1} onChange={(e) => handleOnChange(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)} />
              <label id="emptyAddress1" className="Error-label" hidden={true}> Address cannot be empty </label>
              <label id="RegexValidationAddress1" className="Error-label" hidden={true}> Provide Valid Address</label>
              <label id="invalidAddress1" className="Error-label" hidden={true}> Address is too Long </label>
            </div>
            <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="Address2" className="Label-billing">Address Line 2</label>
              <input type="text" name="AddressLine2" className="Input-billing Input--empty input-field-size" placeholder="Enter Address" id="Address2" value={clientBillingDetails.AddressLine2} onChange={(e) => handleOnChange(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)} />
              <label id="emptyAddress2" className="Error-label" hidden={true}> Address cannot be empty </label>
              <label id="RegexValidationAddress2" className="Error-label" hidden={true}> Provide Valid Address</label>
              <label id="invalidAddress2" className="Error-label" hidden={true}> Address is too Long </label>
            </div>

            <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="Country" className="Label-billing">Country</label>
              <select className="plan-dropdown Input-billing Input--empty card-dropdown" name="CountryDp" id="CountryDp" onChange={(e) => handleCountrySelect(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)}  >
                {/* {clientBillingDetails.Country == null && clientBillingDetails.Country == "" ? */}
                <option selected >Select Country</option>
                {/* :
                  <option >{clientBillingDetails.Country}</option>
                } */}
                {countries.map((obj: any, index) => {
                  return (
                    <option value={obj.country} id={obj.iso2}>{obj.country}</option>
                  );
                })}
              </select>
              <label id="emptyCountry" className="Error-label" hidden={true}> Select a Country </label>
            </div>

            <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="States" className="Label-billing">State</label>
              <select className="plan-dropdown Input-billing Input--empty card-dropdown" name="StatesDp" id="StatesDp" onChange={(e) => handleCountrySelect(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)}>
                {/* {clientBillingDetails.State == null && clientBillingDetails.State == "" ? */}
                <option selected>Select State</option>
                {/* :
                  <option >{clientBillingDetails.State}</option>
                } */}
                {states.map((obj: any, index) => {
                  return (
                    <option value={obj.name}>{obj.name}</option>
                  );
                })}
              </select>
              <label id="emptyState" className="Error-label" hidden={true}> Select a State</label>
            </div>
            <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="Cities" className="Label-billing">City</label>
              <select className="plan-dropdown Input-billing Input--empty card-dropdown" name="CitiesDp" id="CitiesDp" onChange={(e) => handleCountrySelect(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)} >
                {/* {clientBillingDetails.City == null && clientBillingDetails.City == "" ? */}
                <option selected >Select City</option>
                {/* :
                  <option >{clientBillingDetails.City}</option>
                } */}
                {cities.map((obj: any, index) => {
                  return (
                    <option value={obj}>{obj}</option>
                  );
                })}
              </select>
              <label id="emptyCity" className="Error-label" hidden={true}> Select a City </label>
            </div>
            {/* <div className="col-md-6 mb-2 padding-x">
              <label htmlFor="zipCode" className="Label-billing">Zip Code<span className="required">*</span></label>
              <input type="text" name="ZipCode" className="Input-billing Input--empty" placeholder="Enter ZipCode" id="zipCode" value={clientBillingDetails.ZipCode} onChange={(e) => handleOnChange(e)} onFocus={(e) => handleOnFocus(e)} onBlur={(e) => regexValidation(e)} />
              <label id="emptyZipCode" className="Error-label" hidden={true}> ZipCode cannot be empty </label>
              <label id="invalidZipCode" className="Error-label" hidden={true}> Please enter Valid ZipCode </label>
            </div> */}
          </div>
        </div>
        <div className="card-details text-start ps-3">
          <label className="payment-header ps-0">Card Details</label>
          <PaymentElement id="payment-element" />
        </div>
      </div>
      <div className="col-md-12 paynow-btn d-flex justify-content-end">
        <button type="button" id="paymentCancelButton" className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-second me-2 font-16 font-semibold" onClick={() => handleCancel()}>Cancel</button>
        <button type="button" disabled={isLoading || !stripe || !elements} id="submit" className="common-btn-class btn-primary-theme btn payment-button-width font-16 font-semibold" onClick={handleSubmit}>
          <span id="button-text">
            {isLoading ? <div id="spinner" ><img className="PayNowLoader" src="/Images/PayNowLoader.gif" alt="loader" /></div> : "Pay now"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </div>
      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--Alert pop-up--> */}
    </form>

  );
}