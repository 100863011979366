import React, { useEffect, useState } from 'react';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode';
import constants from '../../Common/Constants';
import { callBackEnd } from "../../Services/GroupChatMigrationServices";
import { asFunc } from 'node-enumerable';

export default function GridPage() {
    let InActiveTimeout: NodeJS.Timeout;
    let [statusText, setStatus] = useState("")
    let [clientID, setClientID] = useState(0);
    let [clientToken, setClientToken] = useState("");
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    let [isChatHtmlMigration, setIsChatHtmlMigration] = useState(false);
    let [isChatServiceAccountMigration, setIsChatServiceAccountMigration] = useState(false)
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;

    useEffect(() => {
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.add("container-bg-withoutFooter")
        resetSessionTimer();
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion

        let client_Token: any;
        if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        setIsChatHtmlMigration(objClient.isChatHTMLMigration)
        setIsChatServiceAccountMigration(objClient.isChatServiceAccountMigration)
        SessionEpiredDateTime = objClient.CreatedAt;
        clientID = Math.random();
        setClientID(clientID);
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        setClientToken(clientToken);
        }

        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    },[])

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            statusText = "Your session has expired. Please log in again to continue using the application."
            setStatus(statusText)

            //#region Show PopUp
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("className", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            //#endregion
            localStorage.removeItem("cid_t");
        } catch (error) {

        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));

        }
        catch (ex: any) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("className", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion

    return (
        <div>
            <div className="col-md-12">
                <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                    <h2 className="font-bold custom-header-color font-22 w-100 mb-5">Conversation Migration</h2>
                    <div className="my-5 vh-50">
                        <div className="d-md-flex d-block gap-3 justify-content-center custom-conversation-migration">
                            {isChatHtmlMigration?
                            <div className={`d-flex flex-column align-items-center justify-content-between mt-5 mt-md-0 ${isChatServiceAccountMigration ? "custom-three-col" : "custom-two-col"}`} >
                                <h3 className="mb-3 font-20 font-bold custom-header-color">HTML Migration</h3>
                                <img src="/Images/html-migration.svg" alt="bg" height="128"/>
                                <p className="font-15 color-grey-v3 font-regular text-center mt-3">Migrate your Slack conversations as HTML files for seamless access.</p>
                                <a className='anchorAsPointer' href="/Conversation/ChatHtmlFolderCreation"> <button type="button" className="btn-primary-theme common-btn-class btn anchorAsPointer btn-migrate-channel font-16 font-regular" >Migrate Conversations</button> </a>
                                <p className="card-text font-12 font-regular my-4">Click <a className='anchorAsPointer' href="https://www.avamigratron.com/Product/slack-to-teams-how-easy-is-slack-conversation-migration-using-a-html-migration/" target="_blank">here</a> to know more about HTML Migration.</p>
                            </div>
                            :<></>}
                            {isChatServiceAccountMigration?
                            <div className={`d-flex flex-column align-items-center justify-content-between mt-5 mt-md-0 ${isChatHtmlMigration ? "custom-three-col" : "custom-two-col"}`}>
                                <h3 className="mb-3 font-20 font-bold custom-header-color">Service Account Migration</h3>
                                <img src="/Images/Service-account-migration.svg" alt="bg" height="128"/>
                                <p className="w-75 font-15 color-grey-v3 font-regular text-center mt-3">Migrate conversations from Slack on behalf of service account as Multi party chats.</p>
                                <a className='anchorAsPointer' href="/Conversation/SA/ChatMapping"> <button type="button" className="btn-primary-theme common-btn-class btn anchorAsPointer btn-migrate-channel font-16 font-regular" >Migrate Conversations</button> </a>
                                <p className="card-text font-12 font-regular my-4">Click <a className='anchorAsPointer' href="https://www.avamigratron.com/Product/slack-to-teams-how-easy-is-slack-conversation-migration-using-a-service-account-user/" target="_blank">here</a> to know more about Service Account Migration.</p>
                            </div>
                            :<></>}
                            <div className={`d-flex flex-column align-items-center justify-content-between mt-5 mt-md-0 ${(isChatHtmlMigration && isChatServiceAccountMigration) ? "custom-three-col" : "custom-two-col"}`}>
                                <h3 className="mb-3 font-20 font-bold custom-header-color">Actual User Migration</h3>
                                <img src="/Images/Actual-user-migration.svg" alt="bg" height="128"/>
                                <p className="w-75 font-15 color-grey-v3 font-regular text-center mt-3">Migrate conversations from Slack on behalf of actual users as MS Teams direct or multi party chats.</p>
                                <a className='anchorAsPointer' href="/Conversation/AU/UserVerification"><button type="button" className="btn-primary-theme common-btn-class btn anchorAsPointer btn-migrate-channel font-16 font-regular">Migrate Conversations</button></a>
                                <p className="card-text font-12 font-regular my-4">Click <a className='anchorAsPointer' href="https://www.avamigratron.com/Product/slack-to-teams-all-you-need-to-know-about-slack-chat-migration-with-user-mapping/" target="_blank">here</a> to know more about Actual User Migration.</p>
                            </div>
                        </div>                            
                    </div>
                    
                </div>
            </div>
            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
        </div>
    );
}