import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Insert_Details } from "../../Services/ResgistrationServices";
import { PublicClientApplication, Configuration, BrowserCacheLocation, AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import constants from "../../Common/Constants";
import intlTelInput from 'intl-tel-input';

export default function SSORegistrationForm() {
    const history = useHistory()
    let dataObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "", CountryCode: "" }
    let [state, setState] = useState(dataObj);
    let [alertMessage, setAlertMessage] = useState("")
    let [successMessafe, setSuccessMessafe] = useState("")
    let baseURL = `${process.env.REACT_APP_REGISTRATION_BASEURL}`;
    let avamigratronLink = constants.URL.REACT_APP_AVAMIGRATRON_LINK
    let utmSource: any;
    useEffect(() => {
                let reg: any = document.getElementById("main-container-body")
        reg.classList.add("body-bg-color")
        const params = new URLSearchParams(window.location.search);
        utmSource = params.get('utm_source');
    }, [])

    const onSignUpWithEmailClick = async () => {
        try {
            history.push({
                pathname: "/Registration",
                state: {
                    type: "EmailReg",
                    RegSource: utmSource
                }
            })
        } catch (error) {

        }
    }

    const onSignUpWithMicrosoftClick = async () =>{
        try {

            const msalConfig: Configuration = {
                auth: {
                    clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
                    authority: `${constants.URL.REACT_APP_AUTHORITY_URL}`,
                    redirectUri: `${constants.URL.REACT_APP_SSO_REDIRECT_URL}`
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.SessionStorage, // Change cache location if needed
                    storeAuthStateInCookie: false
                }
            };

            const msalInstance = new PublicClientApplication(msalConfig);
            await msalInstance.initialize();

            let loginPopupPromise: Promise<AuthenticationResult> | null = null;
            try {

                if (loginPopupPromise) {
                    throw new Error('An authentication interaction is already in progress.');
                }

                const loginRequest: PopupRequest = {
                    scopes: ['user.read'] // Replace with the necessary scopes for your application
                };

                loginPopupPromise = msalInstance.loginPopup(loginRequest);

                const loginResponse = await loginPopupPromise;
                const account = loginResponse.account;
                loginPopupPromise = null;
                const userProperties = {
                    userName: account?.name,
                    userEmail: account?.username,
                    type: "SSOReg",
                    RegSource: utmSource
                }
                history.push({
                    pathname: "/Registration",
                    state: userProperties
                })
            } catch (error) {
                if (typeof error === 'object' && error !== null) {
                    const errorMessage = (error as Error).message;
                    if (
                        errorMessage.includes('popup_window_error') ||
                        errorMessage.includes('empty_window_error')
                    ) {
                        alert("Popuphas been blocked")
                    }
                }
                if (loginPopupPromise) {
                    loginPopupPromise.catch(() => { });
                    loginPopupPromise = null;
                }
            }





        } catch (error) {


        }
    }


    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            //#endregion
        } catch (error) {
        }
    }




    return (
        // <div className="float-start reg-left-border-height width100">
        <div className="float-start reg-border-left-signup width100">
            <div className="col-md-12 float-start bg-white px-0">
                <div className="col-12 col-sm-12 col-md-12 col-lg-7 margin-leftlayout float-start pt-4">
                    <a href={avamigratronLink} className='anchorAsPointer'>
                        <img src='/Images/brand_logo.svg' className="brand-logo icon-responsive mt-4 ms-5" alt="logo"></img>
                    </a>

                    {/* <!-- Registration form starts here --> */}
                    <div className="row justify-content-center mt-3 h-500px align-items-center">
                        <div className="col-md-6 float-start px-3">

                            <h1 className="font-24 font-bold mb-3 reg-header-color">Slack To Teams Migration</h1>

                            {/* <!-- main heading starts here --> */}
                            <h4 className="font-18 font-bold mb-3">Get started with your account</h4>
                            {/* <!-- main heading ends here --> */}

                            {/* <!-- top para starts here --> */}
                            <p className="font-12 font-regular grey-200 mb-4 text-nowrap">Please register to proceed further. Already have an account ?
                                <span><a href="/Login" className="sign-in anchorAsPointer font-semibold"> Sign In</a></span>
                            </p>
                            {/* <!-- top para ends here --> */}

                            <div className="d-grid gap-3">
                                <button className="btn btn-light font-14 font-semibold" onClick={onSignUpWithMicrosoftClick} type="button"><span><img src='/Images/microsoft-icon.svg' alt="microsoft"  className="me-2"></img></span>Sign up with Microsoft</button>
                                <button className="btn btn-primary theme-bg border-0 font-14 font-semibold" onClick={onSignUpWithEmailClick} type="button"><span><img src='/Images/email-btn-icon.svg' alt="microsoft" className="me-2"></img></span>Sign up with Email</button>
                            </div>
                            {/* this is not needed starts 
                            <div className="d-none">
                                <div className="">
                                    <label className="font-14 font-semibold pb-1" htmlFor="user-name">User Name<span
                                        className="required">*</span></label>
                                    <input type="text" className="form-control registration_form_control" id="user-name" placeholder="Enter User Name" name="UserName" value={state.UserName} onChange={(e) => regexValidation(e)} />
                                    <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                                    <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                                </div>
                                <div>
                                    <label className="font-14 font-semibold  pt-3 pb-1" htmlFor="company-name">Company Name<span
                                        className="required">*</span></label>
                                    <input type="text" className="form-control registration_form_control" id="company-name" placeholder="Enter Company name" name="CompanyName" value={state.CompanyName} onChange={(e) => regexValidation(e)} />
                                    <label id="companyName" className="required" hidden={true} >Please enter Valid Company Name</label>
                                    <label id="emptycompanyName" className="required" hidden={true} >Company Name cannot be empty</label>
                                </div>
                                <div>
                                    <label className="font-14 font-semibold pt-3 pb-1" htmlFor="email-address">Email Address<span
                                        className="required">*</span></label>
                                    <input type="email" className="form-control registration_form_control" id="email-address" placeholder="Enter Email Address" name="EmailAddress" value={state.EmailAddress} onChange={(e) => regexValidation(e)} />
                                    <label id="Email" className="required" hidden={true}>Please enter Valid Mail Address</label>
                                    <label id="emptyEmail" className="required" hidden={true}>Mail Address cannot be empty</label>
                                </div>
                                <div>
                                    <label className="font-14 font-semibold pt-3 pb-1" htmlFor="phone-num">Phone Number<span
                                        className="required">*</span></label>
                                    <input type="tel" className="form-control registration_form_control" id="phone-num" placeholder="Enter Phone Number" name="PhoneNumber" value={state.PhoneNumber} onChange={(e) => regexValidation(e)} maxLength={20} />
                                    <label id="Phone" className="required" hidden={true}> Please enter Valid Phone number </label>
                                    <label id="emptyPhone" className="required" hidden={true}>Phone number cannot be empty </label>
                                </div>
                            </div>
                              this is not needed end*/}
                            {/* <!-- button starts here --> */}
                            <p className="font-12 font-regular pt-4 grey-200">By signing up, you agree to our&nbsp;
                                <span><a href="https://www.avamigratron.com/terms-of-use/" target="_blank" className="sign-in anchorAsPointer font-semibold">Terms of Use</a></span> and&nbsp;
                                <span><a href="https://www.avamigratron.com/privacy-policy/" target="_blank" className="sign-in anchorAsPointer font-semibold">Privacy Policy</a></span>
                            </p>
                        </div>
                    </div>
                    {/* <!-- Registration form ends here --> */}
                </div>
                {/* <!--background image form starts here --> */}
                <div className="col-lg-5 d-lg-block d-md-none d-none d-sm-none float-start p-0 Reg-img-position">
                    <img src='/Images/signup_img_bg.png' className="Registration_bg_img" alt="registration-bg-img" />
                </div>
                {/* <!--background image form ends here --> */}
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position loadercss Loader-Content-position">
                    <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="align-center">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    )
}