import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WizardNavigation from './Wizard'
import BackNavigation from './BackNavigation'
import ManageScheduleMigration from './ManageScheduleMigration'
import ManageMigrationGrid from "./ManageMigration";
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";

export default function ConversationMigration() {

    let c_local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let objClient: any;
    let clientToken: any;

    let [clientObj,setClientObj] = useState({
            isChatServiceAccountMigration:false,
            isChatHTMLMigration:false
        })


    let history = useHistory()
    let [grid, setGrid]: any = useState("ManageMigration")

    
    useEffect(() => {
        if (c_local != undefined) {
            let c_byteValue = objCryptoJS.AES.decrypt(c_local, c_t_Key);
            var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
            objClient = jwt(c_decryptedValue);
            setClientObj(objClient)
            clientToken = objCryptoJS.AES.encrypt(c_decryptedValue, r_n_Key).toString();
        }
        ////console.log("Grid status", grid);
    }, [grid])

    const tabClick = (e: any) => {
        
        let id = e.target.id
        if (id == "ManageMigration") {
            grid = "ManageMigration"
            setGrid(grid)
            
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let x = document.getElementById("loader")
            //window.location.reload()
            x?.removeAttribute("hidden")
        }
        else {
            grid = "ManageSchedule"
            setGrid(grid)
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let x = document.getElementById("loader")
            x?.removeAttribute("hidden")
        }
    }

    return (
        <div className="row justify-content-center mt-5 card-container mx-0 ms-0 overall-res-padding">
        {(clientObj.isChatServiceAccountMigration || clientObj.isChatHTMLMigration) ? (
        <BackNavigation backNav=" Migration using Actual User  " link='/Conversation' />
        ) : (
        <h4 className="font-26 font-bold">Conversation Migration</h4>
        )} 
            <WizardNavigation wizActive={"step3InActive"} />
            <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>Content Migration</p>
            <div className="col-md-12 float-start mb-4">
                <div className="float-start">
                    <ul className="nav" role="tablist">
                        <li className="nav-item ">
                            <a className={grid == "ManageMigration" ? "common-navTab-mm-convmig px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" : "common-navTab-mm-convmig px-5 py-2 nav-link  font-16 nav-tab-custom font-bold anchorAsPointer"} id="ManageMigration" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Migration</a>
                        </li>
                        <li className="nav-item">
                            <a className={grid == "ManageSchedule" ? "common-navTab-smm-convmig px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" : "common-navTab-smm-convmig px-5 py-2 nav-link  font-16 nav-tab-custom font-bold anchorAsPointer"} id="ManageSchedule" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Scheduled Migration</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Nav tabs ends here */}
            
            
            {/* Tab panes starts */}
            <div className="col-md-12">
                <div className="row justify-content-center">
                    <div className="tab-content">
                        {
                            grid == "ManageMigration" ? <ManageMigrationGrid /> : <ManageScheduleMigration />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

