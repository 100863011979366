import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { useEffect, useState } from "react";
import { HighLevelInventory } from './HighLevelInventory';
import { DetailedInventory } from './DetailedInventory';

export function InventoryMasterComponent(): JSX.Element {

     

    let history = useHistory();
    const state = useLocation().state as any;

    let IsInventory: any
    IsInventory = window.location.pathname;
    let IsHighLevelReturnState: any = (IsInventory == '/Inventory/Detailed' ? false : true);
    const [isHighLevelInventory, setIsHighLevelInventory] = useState(IsHighLevelReturnState);
    const sourceTabClick = async () => {
        try {
             
            document.getElementById("sourceTab")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("targetTab")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            setIsHighLevelInventory(true);
            history.push({
                pathname: "/Inventory/HighLevel"
            })
        } catch (error) {
             
            ////console.log("HighLevelTabClick: ", error);
        }
    }

    const targetTabClick = async () => {
        try {
             
            document.getElementById("sourceTab")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold");
            document.getElementById("targetTab")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active");
            setIsHighLevelInventory(false);
            history.push({
                pathname: "/Inventory/Detailed"
            })
        } catch (error) {
             
            ////console.log("DetailedTabClick: ", error);
        }
    }

    const preMigrationClick = async () => {
        try {
            //debugger
            history.push({
                pathname: "/Inventory/UserVerification",
                state: isHighLevelInventory
            })
        } catch (error) {
             
            ////console.log("PreMigrationClick:", error);
        }
    }


    return (

        <Router>

            <div className="container-fluid">
                <div className="row invt-total-pad justify-content-center card-container">
                    <div className="col-md-12 col-lg-12 col-sm-12 mt-4 mb-3">
                        <h2 className="mb-3 font-bold font-26">Inventory</h2>
                        <div className="p-3 rounded-2 mb-4 info-background-color font-14">
                            <img src="../Images/info-icon.svg" alt="info-icon" className="slack-icon pe-2" />Click on <a className="underline-remove hyperlink-color font-semibold anchorAsPointer" onClick={preMigrationClick}> Verify Users
                            </a> if you wish to migrate private channels, multi-party, and direct chats from the Slack workspace. The Slack user connected in Settings will be automatically verified. 
                        </div>
                        <div>
                            <ul className="nav " role="tablist">
                                <li className="nav-item">
                                    <a className={isHighLevelInventory == true ? "x-5 py-2 nav-link active common-navTab-inventory  font-16 nav-tab-custom font-bold anchorAsPointer" : "x-5 py-2 nav-link  common-navTab-inventory  font-16 nav-tab-custom font-bold anchorAsPointer"} onClick={sourceTabClick} data-bs-toggle="tab" >High-Level Inventory</a>
                                </li>
                                <li className="nav-item">
                                    <a className={isHighLevelInventory == false ? "x-5 py-2 nav-link active common-navTab-inventory  font-16 nav-tab-custom font-bold anchorAsPointer" : "x-5 py-2 nav-link  common-navTab-inventory  font-16 nav-tab-custom font-bold anchorAsPointer"} onClick={targetTabClick} data-bs-toggle="tab" >Detailed Inventory</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- Nav tabs starts here --> */}
                    {/* <!-- Nav tabs ends here --> */}

                    {/* <!-- Tab panes --> */}
                    <div id="tab" className="tab-content">
                        <div id="slack" className="tab-pane active">
                            {isHighLevelInventory ? <HighLevelInventory /> : <DetailedInventory />}
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="//Inventory/HighLevel" />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                {/* <!-- End of Tab panes --> */}
                
            </div>
        </Router>

    );
}

