import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/MemberMigrationServices';
import { useLocation, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import objCryptoJS from 'crypto-js';
import jwt from 'jwt-decode';
import constants from "../../Common/Constants";


import { ExportToCsv } from 'export-to-csv'
import Papa from 'papaparse';

export default function MemberMigrationGrid() {
  let baseURL: any = constants.URL.REACT_APP_MEMMIG_BASEURL;
  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    SessionEpiredDateTime = objClient.CreatedAt;
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
  }

  let GridLoadarray: any[] = [];

  const [selectedChannelsArray, setSelectedChannelsArray] = useState<any>([]);
  const [selectAllCount, setSelectAllCount] = useState(0);
  const [isFullSelect, setIsFullSelect] = useState(false);
  const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
  const [operation, setOperation ] = useState("")

  const history = useHistory();
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [columnOrder, setColumnOrder] = useState("");
  const [columnName, setColumnName] = useState("");
  const [sorthide, setsorthide] = useState("");
  const [countPerPage, setCoutPerPage] = useState(10);
  // const [clientID, setclientID] = useState(1);
  const [type, setType] = useState("memberMigration");
  let [migrationType, setMigrationType] = useState("sameMail");
  const [sourceId, setSourceId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [active, setActive] = useState(false);
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [tmpsearchValue, settmpSearchValue] = useState("");
  let [alertMessage, setAlertMessage] = useState("");
  let [loaderText, setLoaderText] = useState("");

  useEffect(() => {
     // To Handle the Select All through Individual Checkboxes
    let selectAllelement:any = document.getElementById("selectAllCheckBox")
    selectAllelement.disabled = false

      if (gridData.length != 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && gridData[0] != "Something went wrong! Please try again or reach AVAMIGRATRON Support." && gridData[0] != "Invalid authentication token provided") { 
        if (selectAllCount == 0) {
           // Possible Conditions when the SelectAll is UnChecked 
          if (selectedChannelsArray.length == gridData.length || selectedChannelsArray.length != gridData.length) {
             let isAllSelected = false
             let tempCheckArr:any = []
    
             gridData.forEach((obj:any)=>{
                 if(selectedChannelsArray.some((obj1:any)=>{
                     if(obj1.SourceChatID == obj.SourceChatID){
                         return true
                     }
                 })){
                     tempCheckArr.push(obj)
                 }

             })
             if(tempCheckArr.length == gridData.length){
                 isAllSelected = true
             }
             if(isAllSelected == true){
                 setSelectAllCount(1);
                 selectAllelement.checked ="true";
                 selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
             }
          }
          if(selectedChannelsArray.length == totalItemsCount){
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
        }
        if (selectAllCount == 1) {
          // Possible Conditions when the SelectAll is Partial Select 
          let isAllSelected = false
          let tempCheckArr:any = []
          
          gridData.forEach((obj)=>{
              if(selectedChannelsArray.some((obj1:any)=>{
                  if(obj1.SourceChatID == obj.SourceChatID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == gridData.length){
              isAllSelected = true
          }
          if(isAllSelected == true){
            if(isFullSelect != true){
              setSelectAllCount(1);
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }
            else{
              setSelectAllCount(2);
              setIsFullSelect(true)
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
          }
          else{
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
          }
        }
        else{
          if (selectAllCount == 1 && selectedChannelsArray.length != totalItemsCount) {
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
          }
          if (selectAllCount == 1 && selectedChannelsArray.length == totalItemsCount) {
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
      }
        if (selectAllCount == 2) {
          // Possible Conditions when the SelectAll is Full Select
          if(operation != "search"){
            setSelectAllCount(2)
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
          else{
            if (selectedChannelsArray.length != totalItemsCount) {
                setSelectAllCount(0);
                setIsFullSelect(false)
                selectAllelement.checked = ""
            }
          }
        }
        // to handle the pageload of grid for Full Select
        if (totalItemsCount == gridData.length && gridData.length !=0 && selectedChannelsArray.length == gridData.length) {
          setIsFullSelect(true);
          setSelectAllCount(2)
          selectAllelement.checked ="true";
          selectAllelement.className = "form-check-input me-2 check-alg-2"
      }

      handleCheckedItems()
    }
    // Condition to Uncheck the Select All CheckBox when the No Records are in the Grid
    if(gridData[0] == "No Records Found"){
      setSelectAllCount(0);
      setIsFullSelect(false)
      selectAllelement.checked = ""
      selectAllelement.disabled = true
    }

  }, [gridData]);

  useEffect(() => {
     // To Handle the Select All through Individual Checkboxes
        let selectAllelement:any = document.getElementById("selectAllCheckBox")
        let allDataBinded = false
        // Condition to Check whether all the Data From the Backend is Binded in the Grid
        if(gridData.length == totalItemsCount){
          allDataBinded = true
        }
        if (gridData.length != 0) {
          if (selectAllCount == 0) {
               // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
            if (selectedChannelsArray.length == gridData.length && isFullSelect == false && allDataBinded == false) {
              let isAllSelected = false
              let tempCheckArr:any = []
              
              gridData.forEach((obj)=>{
                  if(selectedChannelsArray.some((obj1:any)=>{
                      if(obj1.SourceChatID == obj.SourceChatID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }
 
              })
              if(tempCheckArr.length == gridData.length){
                  isAllSelected = true
              }
              if(isAllSelected){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
              }
            }
            if(selectedChannelsArray.length == gridData.length && isFullSelect == true || selectedChannelsArray.length == gridData.length && allDataBinded == true){
              setSelectAllCount(2);
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
            if(selectedChannelsArray.length != gridData.length && isFullSelect == true){
              let isAllSelected = false
              let tempCheckArr:any = []
              
              gridData.forEach((obj)=>{
                  if(selectedChannelsArray.some((obj1:any)=>{
                      if(obj1.SourceChatID == obj.SourceChatID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }
 
              })
              if(tempCheckArr.length == gridData.length){
                  isAllSelected = true
              }
                  if(isAllSelected == true){
                    setSelectAllCount(2);
                    setIsFullSelect(true)
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                  }
            }
            if(selectedChannelsArray.length != gridData.length && isFullSelect == false){
              let isAllSelected = false
              let tempCheckArr:any = []
              
              gridData.forEach((obj)=>{
                  if(selectedChannelsArray.some((obj1:any)=>{
                      if(obj1.SourceChatID == obj.SourceChatID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }
 
              })
              if(tempCheckArr.length == gridData.length){
                  isAllSelected = true
              }
                  if(isAllSelected == true){
                      setSelectAllCount(1);
                      selectAllelement.checked ="true";
                      selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                  }
            }
          }
          if (selectAllCount == 1) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select
            if (selectedChannelsArray.length != gridData.length) {
              let isAllSelected = false
              let tempCheckArr:any = []
              
              gridData.forEach((obj)=>{
                  if(selectedChannelsArray.some((obj1:any)=>{
                      if(obj1.SourceChatID == obj.SourceChatID){
                          return true
                      }
                  })){
                      tempCheckArr.push(obj)
                  }
 
              })
              if(tempCheckArr.length == gridData.length){
                  isAllSelected = true
              }
                  if(isAllSelected == true){
                      setSelectAllCount(1);
                      selectAllelement.checked ="true";
                      selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                  }
                  else{
                      setSelectAllCount(0);
                      setIsFullSelect(false)
                      selectAllelement.checked = ""
                  }
              
              }
          }
          if (selectAllCount == 2) {
             // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
            if (selectedChannelsArray.length != totalItemsCount) {
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
            }
          }
        }
    handleCheckedItems();
}, [selectedChannelsArray]);

  useEffect(() => {
    resetSessionTimer();
    let invoiceHeight: any = document.getElementById("MainContainerMFE");
    invoiceHeight.classList.remove("container-bg-withoutFooter");
    // $('input[name="checkbox"]').prop("checked", false);
    let migrateButton = document.getElementById("migrateButton");
    migrateButton?.setAttribute("disabled", "disabled");
    document.getElementsByTagName("video")[0]?.setAttribute("src", "");
    // ReactTooltip.rebuild()
    loadGridPage(operation);
    window.addEventListener("keydown", resetSessionTimer);
    window.addEventListener("click", resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener("click", resetSessionTimer);
      window.removeEventListener("keydown", resetSessionTimer);
    };
  }, [columnName, columnOrder, indexPerPage]);

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertMessage =
        "Your session has expired. Please log in again to continue using the application.";
      setAlertMessage(alertMessage);

      //#region Show PopUp
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  };
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {
      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken,
        };

        let data: any = await gridLoad(config);
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(
            data.data.data.clientToken,
            r_n_Key
          );
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        } else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        } else {
          enableSessionExpiryPopup();
        }
      } else {
        localStorage.clear();
      }
    } catch (error) {
      alertMessage =
        "Something went wrong! Please try again or reach AVAMIGRATRON Support";
      setAlertMessage(alertMessage);

      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  };
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(
        new Date(SessionEpiredDateTime).getTime() -
          new Date(currentDateTime).getTime()
      );
      let minutes = Math.floor(
        (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    } catch (ex: any) {
      alertMessage =
        "Something went wrong! Please try again or reach AVAMIGRATRON Support";
      setAlertMessage(alertMessage);
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  };
  //#endregion

  const loadGridPage = async (operation:any) => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let loader = document.getElementById("loader");
        loader?.removeAttribute("hidden");
        let config = {
          method: "POST",
          token: clientToken,
          url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
          data: {
            clientID: clientID,
            offsetValue: indexPerPage,
            columnName: `${columnName}`,
            columnType: `${columnOrder}`,
            searchString: `${searchValue}`,
            type: `${type}`,
            sourceId: `${sourceId}`,
            teamId: `${teamId}`,
            channelId: `${channelId}`,
            export: "",
          },
        };
        let data: any = await gridLoad(config);
        loader?.setAttribute("hidden", "hidden");
        if (
          data.data.data == "No Records Found" ||
          data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" ||
          data.data.data == "Invalid authentication token provided"
        ) {

          if(operation == "search"){
            setTotalItemsCountFeature(data.data.data[1][0].MemberMigrationCount)
          }
          else{
              setTotalItemsCount(data.data.data[1][0].MemberMigrationCount)
          }

          setGridData([data.data.data]);

        } 
        else {

          if(operation == "search"){
            setTotalItemsCountFeature(data.data.data[1][0].MemberMigrationCount)
          }
          else{
              setTotalItemsCount(data.data.data[1][0].MemberMigrationCount)
          }
            setGridData(data.data.data[0]);
        }
        if (
          data.data.data ==
          "Something went wrong. Please reach AVAMIGRATRON team"
        ) {
          setAlertMessage(
            "Something went wrong! Please try again or reach AVAMIGRATRON Support."
          );
          let alertPopUp = document.getElementById("alertPopUp");
          alertPopUp?.removeAttribute("aria-hidden");
          alertPopUp?.setAttribute("aria-modal", "true");
          alertPopUp?.setAttribute("role", "dialog");
          alertPopUp?.setAttribute("class", "modal fade show");
          alertPopUp?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        } else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        // setTotalItemsCount(data.data.data[1][0].MemberMigrationCount);
      } else {
        localStorage.clear();
      }
    } catch (error: any) {
      let loader = document.getElementById("loader");
      loader?.setAttribute("hidden", "hidden");
      setAlertMessage(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support."
      );
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  };

  const gridBodyData = () => {
    try {
      if (
        gridData.length > 0 &&
        gridData[0] != "No Records Found" &&
        gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team"
      ) {
        return gridData.map((obj, index) => {
          let rowClass =
            index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          let statusClass =
            obj.TeamMigratedStatus == "Yet to start"
              ? "status-yettostart-bg"
              : obj.TeamMigratedStatus != "-"
              ? "anchorAsPointer"
              : "";
          let channelClass =
            obj.TeamChannelMigratedStatus == "Yet to start"
              ? "status-yettostart-bg"
              : obj.TeamChannelMigratedStatus != "-"
              ? "anchorAsPointer"
              : "";

          if (
            obj.TeamMigratedStatus != "Yet to start" &&
            obj.TeamMigratedStatus != "-"
          ) {
            let count = obj.TeamMigratedStatus.split(" out of ");
            let users = parseInt(count[0]);
            let usersMigrated = users.toLocaleString("en-US");
            let totUsers = parseInt(count[1]);
            let totalUsers = totUsers.toLocaleString("en-US");
            obj.TeamMigratedStatus = usersMigrated + " out of " + totalUsers;
          }

          if (
            obj.TeamChannelMigratedStatus != "Yet to start" &&
            obj.TeamChannelMigratedStatus != "-"
          ) {
            let count = obj.TeamChannelMigratedStatus.split(" out of ");
            let users = parseInt(count[0]);
            let usersMigrated = users.toLocaleString("en-US");
            let totUsers = parseInt(count[1]);
            let totalUsers = totUsers.toLocaleString("en-US");
            obj.TeamChannelMigratedStatus =
              usersMigrated + " out of " + totalUsers;
          }

          return (
            <tr className={rowClass}>
              <td className="py-3 tabel-data-font-level1">
                <input
                  className="form-check-input me-2 check-alg-2"
                  name="checkbox"
                  type="checkbox"
                  id={JSON.stringify(obj)}
                  onChange={(e) => handleCheckbox(e)}
                />
              </td>
              {obj.SourceChatName.length > 17 ? (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={obj.SourceChatName}
                >
                  {obj.SourceChatName.substring(0, 14)}...
                </td>
              ) : (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={""}
                >
                  {obj.SourceChatName}
                </td>
              )}
              <td className=" py-3 tabel-data-font-level1 text-center member-table-column">
                <span
                  className={statusClass}
                  onClick={() => navigateToMemberMigTeams(JSON.stringify(obj))}
                >
                  {obj.TeamMigratedStatus}
                </span>
              </td>
              <td className=" py-3 tabel-data-font-level1 text-center member-table-column">
                <span
                  className={channelClass}
                  onClick={() =>
                    navigateToMemberMigChannels(JSON.stringify(obj))
                  }
                >
                  {obj.TeamChannelMigratedStatus}
                </span>
              </td>
              {obj.TargetTeamName.length > 15 ? (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={obj.TargetTeamName}
                >
                  {obj.TargetTeamName.substring(0, 12)}...
                </td>
              ) : (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={""}
                >
                  {obj.TargetTeamName}
                </td>
              )}
              {obj.TargetChannelName.length > 20 ? (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={obj.TargetChannelName}
                >
                  {obj.TargetChannelName.substring(0, 17)}...
                </td>
              ) : (
                <td
                  className="py-3 tabel-data-font-level1 member-table-column"
                  data-tip={""}
                >
                  {obj.TargetChannelName}
                </td>
              )}
              <td className=" py-3 tabel-data-font-level1 member-table-column">
                {obj.TeamChannelType}
              </td>
              <span>
                <ReactTooltip className="mySepecialClass" />
              </span>
            </tr>
          );
        });
      } else if (
        gridData[0] == undefined &&
        gridData[0].SourceChatName == undefined
      ) {
        return (
          <tr>
            {" "}
            <td className="aligncenter" colSpan={7}></td>{" "}
          </tr>
        );
      } else {
        return (
          <tr>
            {" "}
            <td className="aligncenter" colSpan={7}>
              <img className="mt-3" src="/Images/NoRecordsFound.svg"></img>
              <p className="mt-2">No records found</p>
            </td>{" "}
          </tr>
        );
      }
    } catch (error: any) {}
  };

  const handleCheckbox = (e: any) => {
    try {
      let id = e.target.id;
      let objState: any = JSON.parse(id);
      let tempArr = selectedChannelsArray;

      if (e.target.checked == true) {
        // selectedChannels.push({
        //     "migSumId": objState.ID,
        //     "sourceId": objState.SourceChatID,
        //     "teamId": objState.TargetTeamID,
        //     "channelId": objState.TargetChannelID,
        //     "startedAt": startedAt,
        //     "channelType": objState.TeamChannelType
        // })

        tempArr.push(objState);
        setSelectedChannelsArray([...tempArr]);
        let migrateButton = document.getElementById("migrateButton");
        migrateButton?.removeAttribute("disabled");
      } else {

        let ind = tempArr.findIndex((obj: any) => obj.ID == objState.ID);
        tempArr.splice(ind, 1);
        setSelectedChannelsArray([...tempArr]);
        if (tempArr.length == 0) {
          let migrateButton = document.getElementById("migrateButton");
          migrateButton?.setAttribute("disabled", "disabled");
        }
      }
      // To find whether must be Be in full Select on individual CheckBox Check
      if(tempArr.length == totalItemsCount){
        setIsFullSelect(true)
      }
    } catch (error: any) {}
  };

  let navigateToMemberMigTeams = (rowObj: any) => {
    let objState = JSON.parse(rowObj);
    objState["clientID"] = clientID;
    objState["clientToken"] = clientToken;
    history.push({
      pathname:
        objState.TeamMigratedStatus == "Yet to start" ||
        objState.TeamMigratedStatus == "-"
          ? ""
          : "/MemberMigration/TeamMembers",
      state: objState,
    });
  };

  let navigateToMemberMigChannels = (rowObj: any) => {
    let objState = JSON.parse(rowObj);
    objState["clientID"] = clientID;
    objState["clientToken"] = clientToken;
    history.push({
      pathname:
        objState.TeamChannelMigratedStatus == "Yet to start" ||
        objState.TeamChannelMigratedStatus == "-"
          ? ""
          : "/MemberMigration/ChannelMembers",
      state: objState,
    });
  };

  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");

      setColumnName(sortval[0]);
      setColumnOrder(sortval[1]);
      setsorthide(sortID);
      handleCheckedItems();
    } catch (error: any) {}
  };

  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value);
    } catch (error: any) {}
  };

  const btnDownloadMemberMigGrid = async () => {
    try{
      if(totalItemsCount != undefined ){

        if (clientID != undefined && clientToken != undefined) {
          let loader = document.getElementById("loader");
          loader?.removeAttribute("hidden");
          let indexPerPage = totalItemsCount
          let config = {
            method: "POST",
            token: clientToken,
            url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
            data: {
              clientID: clientID,
              offsetValue: indexPerPage,
              columnName: "",
              columnType: "",
              searchString: "",
              type: `${type}`,
              sourceId: `${sourceId}`,
              teamId: `${teamId}`,
              channelId: `${channelId}`,
              export: "",
            },
          };
  
          let data: any = await gridLoad(config);
  
          let exportData: any[] = data.data.data[0]
  
          const modifiedData = exportData.map(({ SourceChatID, SourceChatName }) => ({
            'Slack Channel ID': SourceChatID,
            'Slack Channel Name': SourceChatName
          }));
  
          const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              showTitle: false,
              title: 'Member Migration Channel Details',
              useTextFile: false,
              useBom: true,
              useKeysAsHeaders: true,
              filename: "Member Migration Channel Details"
          };
          const csvExporter = new ExportToCsv(options);
          loader?.setAttribute("hidden", "hidden");
          
          csvExporter.generateCsv(modifiedData);
        }

      }
      else{
        setAlertMessage(
          "No records to export"
        );
        let alertPopUp = document.getElementById("alertPopUp");
        alertPopUp?.removeAttribute("aria-hidden");
        alertPopUp?.setAttribute("aria-modal", "true");
        alertPopUp?.setAttribute("role", "dialog");
        alertPopUp?.setAttribute("class", "modal fade show");
        alertPopUp?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      
    }
    catch(error){

    }
  }

  // function to Remove Duplicates
  function removeDuplicates(array:any, key:any) {
    const uniqueMap = new Map();
  
    array.forEach((item: { [x: string]: any; }) => {
      const keyValue = item[key];
      if (!uniqueMap.has(keyValue)) {
        uniqueMap.set(keyValue, item);
      }
    });
  
    const uniqueArray = Array.from(uniqueMap.values());
  
    return uniqueArray;
  }

  // Function to Handle Imported CSV
  const handleImportFile = async (e:any) => {
    try{
      if(totalItemsCount != undefined ){

      let tempimportedDetails:any = []
      const file = e.target.files[0];
      let fileName = file.name
      e.target.value = null;
      if (file) {
        const parsePromise = new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            complete: (result) => resolve(result.data),
            error: (error) => reject(error),
          });
        });

        const jsonData:any = await parsePromise;
        
        // Removeing Duplicates and Empty Array
        tempimportedDetails = removeDuplicates(jsonData, "Slack Channel Name")

        let importedDetails = await tempimportedDetails.filter((obj: { [s: string]: unknown; } | ArrayLike<unknown>) => {
          return Object.values(obj).every(value => value !== '' && value !== null && value !== undefined);
        });
      
        const firstObjectKeys = Object.keys(importedDetails[0]);

        if(importedDetails.length >= 1 && firstObjectKeys.includes('Slack Channel ID') && firstObjectKeys.includes('Slack Channel Name') && file.name.includes('.csv')){
          setSelectedChannelsArray([])
          setOperation("search")
          // Hit the API to fetch all the rows from the Database and Filter the Data in the Backend
          if (clientID != undefined && clientToken != undefined) {
            let loader = document.getElementById("loader");
            loader?.removeAttribute("hidden");
            let indexPerPage = totalItemsCount
            let config = {
              method: "POST",
              token: clientToken,
              url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
              data: {
                clientID: clientID,
                offsetValue: indexPerPage,
                columnName: "",
                columnType: "",
                searchString: "",
                type: `${type}`,
                sourceId: `${sourceId}`,
                teamId: `${teamId}`,
                channelId: `${channelId}`,
                export: "",
                importedDetails:importedDetails
              },
            };
            let data: any = await gridLoad(config);
            loader?.setAttribute("hidden", "hidden");
            if (data.data.data == "No Records Found" ||data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
              setCoutPerPage(10)
              setTotalItemsCountFeature(data.data.data[1][0].MemberMigrationCount)
              setGridData(data.data.data);
            } 
            if(data.data.statusCode == 200 && data.data.statusText == "OK"){
                setCoutPerPage(10)  
                setTotalItemsCountFeature(data.data.data[1][0].MemberMigrationCount)    
                setGridData(data.data.data[0]);
                setCoutPerPage(data.data.data[1][0].MemberMigrationCount)
            }
            // To Handle the Invalid Export Scenario If any Invalid is Present
          if(data.data.statusCode == 400 && data.data.statusText == "Invalid CSV Data"){
            if(data.data.data[3] != undefined){
              let invalidData = data.data.data[3]
              const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: "Invalid Channel Details",
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: "Invalid Channel Details"
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(invalidData);
            }
            setTotalItemsCountFeature(totalItemsCount) 
          }

            if (
              data.data.data ==
              "Something went wrong. Please reach AVAMIGRATRON team"
            ) {
              setAlertMessage(
                "Something went wrong! Please try again or reach AVAMIGRATRON Support."
              );
              let alertPopUp = document.getElementById("alertPopUp");
              alertPopUp?.removeAttribute("aria-hidden");
              alertPopUp?.setAttribute("aria-modal", "true");
              alertPopUp?.setAttribute("role", "dialog");
              alertPopUp?.setAttribute("class", "modal fade show");
              alertPopUp?.setAttribute("style", "display:block");
              document.getElementById("fadeDiv")?.removeAttribute("hidden");
            } else if (data.data.data == "Invalid authentication token provided") {
              enableSessionExpiryPopup();
            }
          } else {
            localStorage.clear();
          }

        }
        else{
            
          setAlertMessage(
            "Please provide valid template!"
          );
          let alertPopUp = document.getElementById("alertPopUp");
          alertPopUp?.removeAttribute("aria-hidden");
          alertPopUp?.setAttribute("aria-modal", "true");
          alertPopUp?.setAttribute("role", "dialog");
          alertPopUp?.setAttribute("class", "modal fade show");
          alertPopUp?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
            
        }
        
      } 
    }
    else{
      setAlertMessage(
        "No records to import"
      );
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
               
        

    }
    catch(error){
      // console.log(error)
    }
  }


  const handleMigrateButton = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let selectedChannels: any = [];
        let startedAt = new Date().toISOString();
        // looping the SelectedChannelsArray to form the JSON Object
        await selectedChannelsArray.forEach((obj: any) => {
          selectedChannels.push({
            "migSumId": obj.ID,
            "sourceId": obj.SourceChatID,
            "teamId": obj.TargetTeamID,
            "channelId": obj.TargetChannelID,
            "startedAt": startedAt,
            "channelType": obj.TeamChannelType,
          });
        });

        let config = {
          method: "POST",
          token: clientToken,
          url: `${baseURL}/memberMigration/migrationMembers`,
          data: {
            "clientID": clientID,
            "type": `${migrationType}`,
            "selectedChannels": JSON.stringify(selectedChannels),
          },
        };
        setLoaderText(
          "Please wait. We are migrating Slack Channel Users to Teams Channels."
        );
        let loader = document.getElementById("loader");
        loader?.removeAttribute("hidden");
        let data: any = await gridLoad(config);
        if (data.data.data == "Invalid authentication token provided") {
          loader?.setAttribute("hidden", "hidden");
          enableSessionExpiryPopup();
        } else {
          loader?.setAttribute("hidden", "hidden");
          setLoaderText("");
          setSelectedChannelsArray([])
          let popup =
            data.data.statusCode == 200
              ? document.getElementById("sourceSuccessPopUp")
              : document.getElementById("alertPopUp");
          setAlertMessage("Failed to Migrate Members.");
          // data.data.statusCode == 400 ? setAlertMessage("Failed to Migrate Members.") : setAlertMessage("Something went wrong! Please reach AVAMIGRATRON team")
          //#region Show PopUp
          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        //#endregion
      } else {
        localStorage.clear();
      }
    } catch (error: any) {
      let loader = document.getElementById("loader");
      loader?.setAttribute("hidden", "hidden");
      setAlertMessage(
        "Something went wrong! Please try again or reach AVAMIGRATRON Support."
      );
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      $('input[name="checkbox"]').prop("checked", false); // Unchecks it
      let migrateButton = document.getElementById("migrateButton");
      migrateButton?.setAttribute("disabled", "disabled");
    }
  };

  const handleCheckedItems = async () => {
    if (selectedChannelsArray.length != 0) {
      gridData.forEach((obj) => {
        for (let i = 0; i < selectedChannelsArray.length + 1; i++) {
          if (JSON.stringify(selectedChannelsArray[i]) == JSON.stringify(obj)) {
            let element: any = document.getElementById(JSON.stringify(obj));
            if (element != "" && element != null && element != undefined) {
              element.checked = "true";
              break;
            }
          }
        }
      });
    }
  };

  const handleSelectAll = async () => {
    try {
      // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
      let count: any = undefined;
      if (totalItemsCount == gridData.length) {
        setIsFullSelect(true);
        count = selectAllCount + 2;
        if (count > 2) {
          count = 0;
        }
      } else {
        count = selectAllCount + 1;
        if (count > 2) {
          count = 0;
        }
      }

      // to handle the Select All CheckBox CSS
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if(count==2){
        selectAllelement.checked = "true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      else if(count == 1){
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
      }
      else{
        selectAllelement.checked = "";
      }

      setSelectAllCount(count);

      // To handle the SelectAll when the Checkbox is Unchecked
      if (count == 0) {
        setIsFullSelect(false)

        selectedChannelsArray.forEach((obj: any) => {
          const element: any = document.getElementById(JSON.stringify(obj));
          if (element != "" && element != null && element != undefined) {
            element.checked = "";
          }
        });
        let tempArr: any = [];
        setSelectedChannelsArray([...tempArr]);
      }

       // To handle the SelectAll when the Checkbox is PartialSelect
      if (count == 1) {
        let tempArr: any = selectedChannelsArray;
        gridData.forEach((obj: any) => {
          const element: any = document.getElementById(JSON.stringify(obj));
          if (
            element != "" &&
            element != null &&
            element != undefined &&
            element.checked != true
          ) {
            element.checked = "true";
            tempArr.push(obj);
          }
        });
        setSelectedChannelsArray([...tempArr]);

        // To find whether must be Be in full Select on individual CheckBox Check
        if(tempArr.length == totalItemsCount){
          setIsFullSelect(true)
        }
      }

       // To handle the SelectAll when the Checkbox is Full Select
      if (count == 2) {
        if (clientID != undefined && clientToken != undefined) {
          setIsFullSelect(true)
          let loader = document.getElementById("loader");
          loader?.removeAttribute("hidden");
          let indexPerPage = totalItemsCount;

          let config = {
            method: "POST",
            token: clientToken,
            url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
            data: {
              clientID: clientID,
              offsetValue: indexPerPage,
              columnName: `${columnName}`,
              columnType: `${columnOrder}`,
              searchString: "",
              type: `${type}`,
              sourceId: `${sourceId}`,
              teamId: `${teamId}`,
              channelId: `${channelId}`,
              export: "",
            },
          };
          let data: any = await gridLoad(config);
          loader?.setAttribute("hidden", "hidden");
          if(data.data.statusText == "OK" && data.data.statusCode == 200) {
       
            let tempArr: any = [];

            data.data.data[0].forEach((obj: any) => {
              tempArr.push(obj);
            });
            setSelectedChannelsArray([...tempArr]);
          }
          if (
            data.data.data ==
            "Something went wrong. Please reach AVAMIGRATRON team"
          ) {
            setAlertMessage(
              "Something went wrong! Please try again or reach AVAMIGRATRON Support."
            );
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
          } else if (
            data.data.data == "Invalid authentication token provided"
          ) {
            enableSessionExpiryPopup();
          }
        } else {
          localStorage.clear();
        }
      }
    } catch (error: any) {}
  };

  const popUpOkClick = (type: string) => {
    try {
      //#region Hide PopUp
      if (isSessionEpired === false) {
        let popup =
          type == "alert"
            ? document.getElementById("alertPopUp")
            : document.getElementById("sourceSuccessPopUp");
        popup?.setAttribute("class", "modal fade");
        popup?.setAttribute("style", "display:none");
        popup?.setAttribute("aria-hidden", "true");
        popup?.removeAttribute("aria-modal");
        popup?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        $('input[name="checkbox"]').prop("checked", false); // Unchecks it
        let migrateButton = document.getElementById("migrateButton");
        migrateButton?.setAttribute("disabled", "disabled");
        loadGridPage(operation);
      } else {
        window.location.pathname = "/Login";
      }

      //#endregion
    } catch (error) {}
  };

  const handleKeyDown = async (e: any) => {
    try {
      if (
        e.key === "Enter" &&
        searchValue != null &&
        searchValue != undefined
      ) {
        if (searchValue !== tmpsearchValue) {
          let tempOperation = ""
          if(searchValue != ""){
            tempOperation = "search"
          }
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          setOperation(tempOperation)
          await loadGridPage(tempOperation);
        }
      }
    } catch (error: any) {}
  };

  const handleSearchIcon = async (e: any) => {
    try {
      if (searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          let tempOperation = ""
          if(searchValue != ""){
            tempOperation = "search"
          }
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          setOperation(tempOperation)
          await loadGridPage(tempOperation);
        }
      }
    } catch (error: any) {}
  };

  const handleLoadMore = () => {
    try {
      setCoutPerPage(countPerPage + 10);
      setIndexPerPage(indexPerPage + 1);

       // To handle the Select All When Load More is Clicked
       let selectAllelement:any = document.getElementById("selectAllCheckBox")
       if (selectAllCount == 1) {
        if(isFullSelect == true){
            setSelectAllCount(2);
            selectAllelement.checked = "true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
        }
        else{
            setSelectAllCount(0);
            selectAllelement.checked = "";
        }
      } 
      else if(selectAllCount == 2) {
              setSelectAllCount(2);
              selectAllelement.checked = "true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
          }

    } catch (error: any) {}
  };

  const handleRadioBtn = (e: any) => {
    try {
      setMigrationType(e.target.value);
    } catch (error: any) {}
  };

  const helpIconClick = async () => {
    try {
      document
        .getElementsByTagName("video")[0]
        ?.setAttribute(
          "src",
          "https://avaeusavamigs2tgvidprod.blob.core.windows.net/avamigratron/MemberMigration.mp4?sp=r&st=2024-03-12T13:55:55Z&se=2025-03-13T21:55:55Z&spr=https&sv=2022-11-02&sr=b&sig=BS2mOHcsDU91Z3y%2Bd2JsuqFXPiUcSmABc2x5TC3CyHQ%3D"
        );
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document
        .getElementById("helpPopUpDiv")
        ?.setAttribute("class", "modal fade show");
    } catch (error) {}
  };

  const helpPopUpCloseClick = async () => {
    try {
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      document
        .getElementById("helpPopUpDiv")
        ?.setAttribute("class", "modal fade");
      document.getElementsByTagName("video")[0]?.setAttribute("src", "");
    } catch (error) {}
  };

  return (
    <div className="row justify-content-center mt-5 card-container mx-0 ms-0 overall-res-padding">
      <div className="col-md-12 py-4">
        <div className="row">
          <div className="col-md-12 mb-2">
            <h5 className="float-start member-header font-bold font-26 mb-3 w-100">
              Member Migration
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6">
            <label className="form-label my-2 form-labelheader  font-semibold font-16 w-100">
              Choose from the below options and proceed with Member Migration.
            </label>

            <div className="float-start my-2">
              <input
                className="form-check-input me-2 "
                type="radio"
                name="radio"
                id="migrate-members2"
                value="sameMail"
                defaultChecked
                onClick={(e) => handleRadioBtn(e)}
              />
              <label
                className="migrate-label dis-label-inline me-4 font-regular font-14"
                htmlFor="migrate-members2"
              >
                Migrate members based on same Email Address in Slack and MS
                Teams
              </label>
            </div>
            <div className="float-start my-2">
              <input
                className="form-check-input me-2 "
                type="radio"
                name="radio"
                id="migrate-members"
                value="userMapping"
                onClick={(e) => handleRadioBtn(e)}
              />
              <label
                className="migrate-label dis-label-inline font-regular font-14"
                htmlFor="migrate-members"
              >
                Migrate members based on user mapping
              </label>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6 d-flex align-items-end ">
            <div className="d-md-flex align-items-center gap-2 gap-md-3 d-grid w-100 justify-content-xl-end">
              <p className="mb-0 font-semibold font-16 text-nowrap">
                # of records: {gridData[0] != "No Records Found" ? gridData.length:0}{" "}
                <span className="font-regular font-16">of </span> 
                { gridData[0] != "No Records Found" ?
                  operation == "search"?
                    totalItemsCountFeature: totalItemsCount 
                :0}{" ("}{selectedChannelsArray.length}{" Selected)"}
              </p>
              <div>
                {" "}
                {/* removed className="float-end memberMigrationSearch" */}
                <div className="input-group flex-nowrap">
                  <input
                    type="text"
                    className="form-control form-control-style-2"
                    placeholder="Search"
                    aria-describedby="button-addon1"
                    name="SearchText"
                    value={searchValue}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    style={{ minWidth: "82px", width: 'auto' }}
                  />
                  <button
                    className="common-btn-class btn btn-border btn-outline-secondary"
                    type="button"
                    onClick={handleSearchIcon}
                    id="button-addon1"
                  >
                    <img src="/Images/search.svg" alt="search-icon" />
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-slack-list btn-primary-theme font-16 font-semibold common-btn-class hli-btn-res-bottom mt-0 d-flex align-items-center gap-1 justify-content-center"
                id="btnInv"
                onClick={()=>{
                  if(totalItemsCount != undefined){
                    document.getElementById("uploadbtn")?.click()
                  }
                  else{
                    setAlertMessage(
                      "No records to import"
                    );
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                  }
                }} 
              >
                {" "}
                <img src="/Images/import.svg" alt="import"></img>Import
                <input id="uploadbtn" type="file" accept=".csv" style={{display:"none"}} onChange={handleImportFile}></input>
              </button>
              <button
                type="button"
                onClick={btnDownloadMemberMigGrid}
                className="btn btn-slack-list btn-primary-theme font-16 font-semibold common-btn-class hli-btn-res-bottom mt-0  d-flex align-items-center gap-1 justify-content-center"
                id="btnInv"
              >
                {" "}
                <img
                  src="/Images/export-excel-white.svg"
                  alt="export-excel"
                ></img>
                Export
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 memberMigrationGridTop table-responsive p-1">
          <table className="table table-border">
            <thead className="tabel-head-bg">
              <tr>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column">
                  {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                  <input
                    className="form-check-input me-2 check-alg-2 intermediate"
                    id="selectAllCheckBox"
                    name="checkbox"
                    type="checkbox"
                    onClick={handleSelectAll}
                  />
                </th>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column">
                  Slack Channel Name
                  <span>
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/arrow.svg"
                      alt="SlackChannelName"
                      id="SlackChannelName@DESC"
                      onClick={(e) => sortClicked(e)}
                      hidden={
                        sorthide == "SlackChannelName@DESC" ? true : false
                      }
                    />
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/sort_upper_arrow.svg"
                      alt="SlackChannelName"
                      id="SlackChannelName@ASC"
                      onClick={(e) => sortClicked(e)}
                      hidden={
                        sorthide == "SlackChannelName@DESC" ? false : true
                      }
                    />
                  </span>
                </th>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column text-center">
                  User Adding in Team Status
                </th>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column">
                  User Adding in Team Channel Status
                </th>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column">
                  Team Name
                  <span>
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/arrow.svg"
                      alt="TeamName"
                      id="TeamName@DESC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamName@DESC" ? true : false}
                    />
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/sort_upper_arrow.svg"
                      alt="TeamName"
                      id="TeamName@ASC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamName@DESC" ? false : true}
                    />
                  </span>
                </th>
                <th className="tabel-head-font-level1 font-bold font-14 member-table-column">
                  Team Channel Name
                  <span>
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/arrow.svg"
                      alt="TeamChannelName"
                      id="TeamChannelName@DESC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamChannelName@DESC" ? true : false}
                    />
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/sort_upper_arrow.svg"
                      alt="TeamChannelName"
                      id="TeamChannelName@ASC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamChannelName@DESC" ? false : true}
                    />
                  </span>
                </th>
                <th className="tabel-head-font-level1  font-bold font-14 member-table-column">
                  Team Channel Type
                  <span>
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/arrow.svg"
                      alt="TeamChannelType"
                      id="TeamChannelType@DESC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamChannelType@DESC" ? true : false}
                    />
                    <img
                      className="sort-img ms-2 anchorAsPointer"
                      src="/Images/sort_upper_arrow.svg"
                      alt="TeamChannelType"
                      id="TeamChannelType@ASC"
                      onClick={(e) => sortClicked(e)}
                      hidden={sorthide == "TeamChannelType@DESC" ? false : true}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>{gridBodyData()}</tbody>
          </table>
        </div>
        <div className="col-md-12 mt-3 mb-5 text-center">
           {operation != "search" ? (
                totalItemsCount > countPerPage ? (
                  <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore}>
                    Load More
                  </button>
                  ) : ""
                  ) : (
                totalItemsCountFeature > countPerPage ? (
                  <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore}>
                    Load More
                  </button>
                  ) : ""
                  )}
        </div>
        {/* <!-- footer starts --> */}
        <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
          <div className=" mx-3 d-flex justify-content-end">
            <button
              type="button"
              id="migrateButton"
              className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2"
              onClick={handleMigrateButton}
              disabled={selectedChannelsArray.length ==0 ? true:false}
            >
              Migrate Members
            </button>
          </div>
        </footer>
        {/* <!-- footer ends -->  */}
      </div>
      {/* <!--loader section--> */}
      <div className="overlay" id="loader" hidden={true}>
        <div className="loader-position Loader-Content-position">
          <div
            className="spinner-border Loader Loader-Img-position text-primary align-center"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
          <p className="align-center">{loaderText}</p>
        </div>
      </div>
      {/* <!--loader section--> */}
      {/* <!--Alert Pop-up--> */}
      <div
        className="modal fade"
        id="alertPopUp"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img
                src="/Images/Alert.svg"
                className="success-icon"
                alt="success"
              />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">
                  {alertMessage}
                </p>

                <button
                  type="button"
                  className="common-btn-class btn-primary-theme btn btn-ok  text-center"
                  data-bs-dismiss="modal"
                  onClick={(e) => popUpOkClick("alert")}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Alert pop-up--> */}
      {/* <!--Success Pop-up--> */}
      <div
        className="modal fade"
        id="sourceSuccessPopUp"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img
                src="/Images/success.svg"
                className="success-icon"
                alt="success"
              />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Success!</h5>
                <p className="success-text w-100 text-center mb-4">
                  Members Migrated Successfully
                </p>
                <button
                  type="button"
                  className="common-btn-class btn-primary-theme btn btn-ok  text-center"
                  data-bs-dismiss="modal"
                  onClick={(e) => popUpOkClick("success")}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--success pop-up--> */}
      {/* <!--fixed section--> */}
      <div className="icon-container" onClick={helpIconClick}>
        <label
          className="custom-icon-style help-icon-overlap"
          id="hidden-section"
          data-bs-toggle="modal"
          data-bs-target="#cardDetails"
        >
          <img
            className="fixed-icon"
            src="/Images/help-web-button.svg"
            alt="help-icon"
          />
          <img
            className="expand-icon expand-content"
            src="/Images/help-web-button.svg"
            alt="help expand-logo"
          />{" "}
          <span className="hidden-content expand-content font-bold font-16">
            Help
          </span>
        </label>
      </div>
      {/* <!--fixed section--> */}
      {/* <!--Items in cart popup--> */}
      <div
        className="modal fade"
        id="helpPopUpDiv"
        tabIndex={-1}
        aria-labelledby="cardDetailsLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-radius">
            <div className="help-modal-body pt-1">
              <div className="col-md-12 mb-4 mt-4">
                <div className="row">
                  <div className="w-100 float-start text-center mb-3">
                    <label className="form-label popup-label font-24 font-semibold mb-3 w-100">
                      Need Help ?
                    </label>
                    <label className="form-label popup-label2 font-16 font-regular w-100">
                      Watch this video to learn how to Migrate Members from
                      Slack Channels to MS Teams & Channels!
                    </label>
                  </div>
                  <div className="w-100 float-start text-center">
                    <video
                      width="100%"
                      className="videoTag"
                      height="315"
                      controls
                      controlsList="nodownload"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end mb-4">
                <button
                  type="button"
                  className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4"
                  data-bs-dismiss="modal"
                  onClick={helpPopUpCloseClick}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Items in cart popup--> */}
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>
  );
}
