import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/UserMappingServices';
import { ExportToCsv } from 'export-to-csv';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import { useLocation } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import ReactTooltip from 'react-tooltip';
import constants from '../../Common/Constants';



export default function GridPage() {
  let GridLoadarray: any[] = [];
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [targetsearchValue, settargetSearchValue] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("SourceUserName");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("mappedusers");
  const [indexPerPage, setIndexPerPage] = useState(1);
  let [sourceUsersCount, setsourceUsersCount] = useState(0);
  let [targetUsersCount, settargetUsersCount] = useState(0);
  const [targetcolumnOrder, settargetColumnOrder] = useState("");
  const [targetcolumnName, settargetColumnName] = useState("");
  const [countPerPage, setCoutPerPage] = useState(10);
  let [workspacename, setworkspacename] = useState('')
  let local: any = localStorage.getItem("cid_t");
  let [alertStatus, setalertStatus] = useState("Something went wrong! Please try again or reach AVAMIGRATRON Support.")

  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }
  useEffect(() => {
    resetSessionTimer();
    let footercomponent: any = document.getElementById("MainContainerMFE");
    footercomponent.classList.add("container-bg-withoutFooter")
    loadGridPage();
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])


  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertStatus = "Your session has expired. Please log in again to continue using the application."
      setalertStatus(alertStatus);

      //#region Show PopUp
      let popup = document.getElementById("wrongalert")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion




  const loadGridPage = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/userMapping/getUserMappingGrid`,
          token: clientToken,
          data: {
            "clientID": clientID,
            "offsetValue": indexPerPage,
            "columnName": `${columnName}`,
            "columnType": `${columnOrder}`,
            "searchString": `${searchValue}`,
            "type": `${type}`,
            "trgtColumnName": `${targetcolumnName}`,
            "trgtColumnType": `${targetcolumnOrder}`,
            "trgtSearchString": `${targetsearchValue}`
          }
        }
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data[0].length == 0) {
          setGridData(["No Records Found"])
          let totalCount = data.data.data[2][0].SourceUserCount;
          let count = totalCount.toLocaleString("en-US");
          setsourceUsersCount(count);
          let totalCount1 = data.data.data[3][0].TargetUserCount;
          let count1 = totalCount1.toLocaleString("en-US");
          settargetUsersCount(count1);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          setGridData(data.data.data)
          enablealertpopup();
        }
        else {
          setGridData(data.data.data[0]);
          setTotalItemsCount(data.data.data[1][0].mappedMembersGrid)
          let totalCount = data.data.data[2][0].SourceUserCount;
          let count = totalCount.toLocaleString("en-US");
          setsourceUsersCount(count);
          let totalCount1 = data.data.data[3][0].TargetUserCount;
          let count1 = totalCount1.toLocaleString("en-US");
          settargetUsersCount(count1);
        }
        setTotalItemsCount(data.data.data[1][0].mappedMembersGrid)
      }
      else {
        localStorage.clear();
      }

    }

    catch (error: any) {
      enablealertpopup();
    }
  }

  const enablealertpopup = () => {
    try {
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const modulerestrictionpopup = () => {
    try {
      document.getElementById("settingsalert")?.removeAttribute("aria-hidden");
      document.getElementById("settingsalert")?.setAttribute("aria-modal", "true");
      document.getElementById("settingsalert")?.setAttribute("role", "dialog");
      document.getElementById("settingsalert")?.setAttribute("class", "modal fade show");
      document.getElementById("settingsalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error) {
    }
  }

  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went Wrong.Please reach Avamigratron team") {
        return gridData.map((obj, index) => {
          let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          return (
            <tr className={rowClass} >
              {(obj.SourceUserName).length > 19 ?
                <td data-tip={obj.SourceUserName} className="py-3 customtabel-data-font-level1">{obj.SourceUserName.substring(0, 16)}...</td> :
                <td data-tip="" className="py-3 customtabel-data-font-level1">{obj.SourceUserName}</td>
              }
              {(obj.SourceUserMailID).length > 40 ?
                <td data-tip={obj.SourceUserMailID} className="py-3 customtabel-data-font-level1">{obj.SourceUserMailID.substring(0, 37)}...</td> :
                <td data-tip="" className="py-3 customtabel-data-font-level1">{obj.SourceUserMailID}</td>
              }
              {(obj.TargetUserName).length > 19 ?
                <td data-tip={obj.TargetUserName} className="py-3 customtabel-data-font-level1">{obj.TargetUserName.substring(0, 16)}...</td> :
                <td data-tip="" className="py-3 customtabel-data-font-level1">{obj.TargetUserName}</td>

              }
              {(obj.TargetUserMailID).length > 40 ?
                <td data-tip={obj.TargetUserMailID} className="py-3 customtabel-data-font-level1">{obj.TargetUserMailID.substring(0, 37)}...</td> :
                <td data-tip="" className="py-3 customtabel-data-font-level1">{obj.TargetUserMailID}</td>
              }
              <span>
                <ReactTooltip />
              </span>
            </tr>
          )
        }
        )
      }
      else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
        return (<tr> <td className="aligncenter" colSpan={4}></td>  </tr>)
      }
      else {
        return (
          <tr> <td className="aligncenter" colSpan={4}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
            <p className="mt-2">No records found</p>
          </td>  </tr>)
      }
    }
    catch (error: any) {
    }
  }

  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      setsorthide(sortID);
      let sortval = sortID.split('@');
      setColumnName(sortval[0]);
      setColumnOrder(sortval[1]);
      setIndexPerPage(indexPerPage ? indexPerPage : 1);
      setCoutPerPage(countPerPage ? countPerPage : 10);
    }
    catch (error: any) {
    }
  }

  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value);
    }
    catch (error: any) {
    }
  }

  const handleSearchIcon = (e: any) => {
    try {
      if (searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
    }
  }

  const handleKeyDown = (e: any) => {
    try {
      if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {
    }
  }

  const handleLoadMore = () => {
    try {
      let oindexPerPage = indexPerPage + 1;
      let ocountPerPage = countPerPage + 10;
      setIndexPerPage(oindexPerPage);
      setCoutPerPage(ocountPerPage);
    }
    catch (error: any) {
    }
  }

  const okclick1 = () => {
    try {
      document.getElementById("failure")?.setAttribute("class", "modal fade");
      document.getElementById("failure")?.setAttribute("style", "display:none");
      document.getElementById("failure")?.setAttribute("aria-hidden", "true");
      document.getElementById("failure")?.removeAttribute("aria-modal");
      document.getElementById("failure")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    }
    catch (error) {
    }
  }

  const browsealertokclick = () => {
    try {
      if (isSessionEpired === false) {
        document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
        document.getElementById("wrongalert")?.setAttribute("style", "display:none");
        document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
        document.getElementById("wrongalert")?.removeAttribute("aria-modal");
        document.getElementById("wrongalert")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";


      }
    }
    catch (error) {
    }
  }

  const setingspopupokclick = () => {
    try {
      document.getElementById("settingsalert")?.setAttribute("class", "modal fade");
      document.getElementById("settingsalert")?.setAttribute("style", "display:none");
      document.getElementById("settingsalert")?.setAttribute("aria-hidden", "true");
      document.getElementById("settingsalert")?.removeAttribute("aria-modal");
      document.getElementById("settingsalert")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    }
    catch (error) {
    }
  }

  return (
    <div className="row justify-content-center card-container mx-0 ms-0">
      <div className="col-lg-12 col-md-12 col-sm-12 p-0">
        <div className="tab-content">
          <div id="high-level-inventory" className="tab-pane active w-100">
            <div className="row w-100">
              <div className="col-md-12 float-start">

                <div className="float-start ">
                  <label className="font-16 font-semibold my-2 um-margin-res">Total # of Slack Users :<span className="font-regular ms-2">{sourceUsersCount}</span></label>
                  <label className="font-16 font-semibold my-2 um-margin-res">Total # of MS Teams Users :<span className="font-regular ms-2">{targetUsersCount}</span></label>
                </div >
                <div className="float-lg-end float-sm-start float-md-start">
                  <div className="">
                    <div className="p-0">
                      <div className="input-group search-width-usermapping box-shodow-filter">
                        <input type="text" className="form-control my-2 form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                        <button className="btn btn-outline-secondary my-2" type="button" id="button-addon1" onClick={handleSearchIcon}><img src='/Images/search.svg' alt="search-icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="failure" tabIndex={-1} aria-hidden="true">
              <div className="modal-dialog modal-md">
                <div className="modal-content content-margin">
                  <div className="modal-body">
                    <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                    <div className="text-center success-content w-100 mt-5">
                      <h5 className="font-semibold font-18">Alert!</h5>
                      <p className="success-text w-100 text-center mb-4">User Mapping sheet contains invalid records.</p>
                      <button type="button" className="btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={okclick1}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
              <div className="modal-dialog modal-md">
                <div className="modal-content content-margin">
                  <div className="modal-body">
                    <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                    <div className="text-center success-content w-100 mt-5">
                      <h5 className="font-semibold font-18">Alert!</h5>
                      <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                      <button type="button" className="btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="settingsalert" tabIndex={-1} aria-hidden="true">
              <div className="modal-dialog modal-md">
                <div className="modal-content content-margin">
                  <div className="modal-body">
                    <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                    <div className="text-center success-content w-100 mt-5">
                      <h5 className="font-semibold font-18">Alert!</h5>
                      <p className="success-text w-100 text-center mb-4">Please Connect the Slack Workspace!</p>
                      <button type="button" className="btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={setingspopupokclick}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

            <div className="col-md-12 col-12 float-start mt-2">
              <div className='table-responsive p-1'>
                <table className="table table-border">
                  <thead className="tabel-head-bg">
                    <tr>
                      <th className="table-head font-14 font-bold">Slack User Name<span>
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="SourceUserName@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserName@Desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserName@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserName@Desc" ? false : true} /></span>
                      </th>
                      <th className="table-head font-14 font-bold">Slack User Email Address
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="SourceUserEmail@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserEmail@Desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="SourceUserEmail@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserEmail@Desc" ? false : true} /></th>
                      <th className="table-head font-14 font-bold">MS Teams User Name
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="TargetUserName@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserName@Desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="TargetUserName@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserName@Desc" ? false : true} /></th>
                      <th className="table-head font-14 font-bold">MS Teams User Email Address
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="TargetUserEmail@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserEmail@Desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="TargetUserEmail@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserEmail@Desc" ? false : true} /></th>
                    </tr>
                  </thead>
                  <tbody >
                    {gridBodyData()}
                    <td colSpan={5} />
                  </tbody>
                </table>
              </div>
              <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position">
                  <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-12 mt-3 mb-5 text-center">
              {totalItemsCount > countPerPage ?
                <button className="btn-common-loadmore btn btn-loadmore font-bold px-3 my-4" onClick={() => handleLoadMore()}>Load More</button>
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}