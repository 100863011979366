import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WizardNavigation from './WizardSA'
import BackNavigation from './BackNavigation'
import ManageMigrationGrid from "./ManageMigrationSA";
import ManageScheduleMigrationHtml from "./ManageScheduleMigrationSA";

export default function ConversationMigrationSA() {
    let history = useHistory()
    let [grid, setGrid]: any = useState("ManageMigration")
    useEffect(() => {
        ////console.log("Grid status", grid);
    }, [grid])

    const tabClick = (e: any) => {
        
        let id = e.target.id
        if (id == "ManageMigration") {
            grid = "ManageMigration"
            setGrid(grid)
            
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let x = document.getElementById("loader")
            //window.location.reload()
            x?.removeAttribute("hidden")
        }
        else {
            grid = "ManageSchedule"
            setGrid(grid)
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let x = document.getElementById("loader")
            x?.removeAttribute("hidden")
        }
    }

    return (
        <div className="row justify-content-center mt-5 card-container mx-0 ms-0 overall-res-padding">
            <BackNavigation backNav={"  Migration using Service Account "} link={'/Conversation'} />
            <WizardNavigation wizActive={"step3InActive"} />
            <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>Content Migration</p>
            <div className="col-md-12 float-start mb-4">
                <div className="float-start">
                    <ul className="nav" role="tablist">
                        <li className="nav-item ">
                            <a className={grid == "ManageMigration" ? "common-navTab-mm-convmig px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" : "common-navTab-mm-convmig px-5 py-2 nav-link  font-16 nav-tab-custom font-bold anchorAsPointer"} id="ManageMigration" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Migration</a>
                        </li>
                        <li className="nav-item">
                            <a className={grid == "ManageSchedule" ? "common-navTab-smm-convmig px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" : "common-navTab-smm-convmig px-5 py-2 nav-link  font-16 nav-tab-custom font-bold anchorAsPointer"} id="ManageSchedule" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Scheduled Migration</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* Nav tabs ends here */}
            
            
            {/* Tab panes starts */}
            <div className="col-md-12">
                <div className="row justify-content-center">
                    <div className="tab-content">
                        {
                            grid == "ManageMigration" ? <ManageMigrationGrid /> : <ManageScheduleMigrationHtml />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

