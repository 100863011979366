import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AccountInfo from "./AccountInfo";
import InvoiceHistory from "./InvoiceHistory";

export default function EditClient() {

    const state = useLocation().state as any;
    let [tabType, setTabType] = useState("AccountInfo")
    let [isUserBasedAccount, setisUserBasedAccount] = useState(state.IsUserBasedLicense);
    let [expiryDate, setExpiryDate] = useState('');
    useEffect(() => {
        setTabType("AccountInfo");
        const parsedDate = new Date(state.ValidTillDate);
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'short', // 'short' or 'long' for month
            day: 'numeric',
            year: 'numeric',
        }).format(parsedDate);
        setExpiryDate(formattedDate);
    }, [])

    const tabChange = (e: any) => {
        try {
            setTabType(e.target.id);
            if (e.target.id == "InvoiceHistory") {
                document.getElementById(e.target.id)?.classList.add("active")
                document.getElementById("AccountInfo")?.classList.remove("active")
            }
            else {
                document.getElementById(e.target.id)?.classList.add("active")
                document.getElementById("InvoiceHistory")?.classList.remove("active")
            }
        }
        catch (error: any) {


        }
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center mt-4 my-5 card-container mx-0 ms-0 profle-update">
                <div className="col-md-11 mt-4">
                    <h4 className="font-26 font-bold mb-4 ">
                        <a href="/Admin/ManageClients" className="anchorAsPointer"><span><img src='/Images/backarrow.svg' className="p-1" alt="left-arrow" title="Back" /></span></a>
                        {state.CompanyName}
                    </h4>
                </div>
                <div className="col-md-11 mb-4">
                    <h4 className="font-18 font-semibold mb-3" >Available License</h4>
                    {isUserBasedAccount ?
                        <h5 className="font-16 font-semibold mb-3">User-based </h5>
                        :
                        <h5 className="font-16 font-semibold mb-3">Chat & Channel </h5>
                    }
                    {isUserBasedAccount ?
                        <div className="d-flex mb-4 flex-md-col">
                            <label className="font-14 font-regular me-5 pb-2 d-sm-block">License Count <span className="font-20 font-bold ms-2 theme-color">{state.AvailableUserLicense.toLocaleString("en-US")}/{state.PurchasedUserLicenseCount.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block ">Plan Expires On<span className="font-20 font-bold ms-2 theme-color"><span>{expiryDate}</span></span></label>
                        </div>
                        :
                        <div className="d-flex mb-4 flex-md-col">
                            <label className="font-14 font-regular me-5 pb-2 d-sm-block">Channel Migration <span className="font-20 font-bold ms-2 theme-color">{state.AvailableChannelLicense.toLocaleString("en-US")}/{state.PurchasedChannelMigrationLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block me-5 pb-2">Conversation Migration <span className="font-20 font-bold ms-2 theme-color">{state.AvaliableGroupChatLicense.toLocaleString("en-US")}/{state.PurchasedGroupChatMigrationLicense.toLocaleString("en-US")}</span></label>
                            <label className="font-14 font-regular profile-update-float d-sm-block ">Plan Expires On<span className="font-20 font-bold ms-2 theme-color"><span>{expiryDate}</span></span></label>
                        </div>
                    }
                </div>
                {/* <!-- Nav tabs starts here --> */}
                <div className="col-md-11 float-start mb-4">
                    <div className="float-start">
                        <ul className="nav" role="tablist">
                            <li className="nav-item">
                                <a className="common-navTab px-5 py-2 nav-link active font-16 nav-tab-custom font-bold anchorAsPointer" id="AccountInfo" onClick={(e) => tabChange(e)}>Account Information</a>
                            </li>
                            <li className="nav-item">
                                <a className="common-navTab px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer" id="InvoiceHistory" onClick={(e) => tabChange(e)}>Invoice History</a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- Nav tabs ends here --> */}
                {/* <!-- Tab panes starts --> */}
                <div className="col-md-11">
                    <div className="row justify-content-center">
                        <div className="tab-content Admin-bottom">
                            {tabType == "AccountInfo" ? <AccountInfo /> : <InvoiceHistory />}
                        </div>
                    </div>
                </div>
                {/* <!-- Tab panes endss --> */}
            </div>
        </div>


    )
}

