import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Insert_Details } from '../../Services/ResgistrationServices';
import objCryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import { PublicClientApplication, Configuration, BrowserCacheLocation, AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import constants from "../../Common/Constants";


export function Login(): JSX.Element {
  let varState = useLocation().state as any;
  const history = useHistory()
  let dataObj: any = [];
  const [state, setState] = useState(dataObj);
  let [failedPopUp, setFailedPopUp] = useState("");
  const [isMSTeamsPasswordEyeOpen, setIsMSTeamsPasswordEyeOpen] = useState(false);
  let [isPopUpOpen, setIsPopUpOpen] = useState(false)
  let [otpData, setotpData] = useState("");
  let [otpFrontEndData, setotpFrontEndData] = useState("");
  let [otpVerificationType, setotpVerificationType] = useState("Verify");
  const [createdSession, setCreatedSession] = useState("");

  let [otpVerificationLimit, setotpVerificationLimit] = useState(45); // default 45
  let [otpVerificationMinLimit, setotpVerificationMinLimit] = useState(5); // default value 5
  let [otpVerificationSecLimit, setotpVerificationSecLimit] = useState('45'); // default value 45
  let [otpVerificationMinLimitForTimer, setotpVerificationMinLimitForTimer] = useState('45');
  let [bool, setbool] = useState(false);
  let [VerifyBtnName, setVerifyBtnName] = useState("Verify");

  // Variable Declaration for Login Otp
  let OTPObj:any = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
  let [OTPState, setOTPState] = useState(OTPObj);

  const reactNode_en_K: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  const reactClient_en_K: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let baseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}`;
  let avamigratronLink = constants.URL.REACT_APP_AVAMIGRATRON_LINK

  var Interval: any;
          

  useEffect(() => {
    if (varState != undefined) {
      let x = document.getElementById("targetEmail");
      x?.setAttribute("disabled", "disabled");
      setState(varState);
    }
    if (bool == true) {
      if (otpVerificationLimit > 0) {
        const timer = setTimeout(() => {
          setotpVerificationLimit((prevSeconds) => {
            let newTime = prevSeconds - 1;
            let stringnewTime = newTime < 10 ? `0${newTime}` : `${newTime}`;
            setotpVerificationSecLimit(stringnewTime);
            return newTime;
          });
        }, 1000);

        return () => {

          clearTimeout(timer);
        }
      }
      else if (otpVerificationLimit === 0 && otpVerificationMinLimit !== 0) {
        const timer = setTimeout(() => {
          setotpVerificationLimit(59);
          setotpVerificationSecLimit('59');
          if (otpVerificationLimit == 0) {
            setotpVerificationMinLimit((prevSeconds) => {
              let newTime = prevSeconds - 1;
              let stringnewTime = newTime < 10 ? `0${newTime}` : `${newTime}`;
              setotpVerificationMinLimitForTimer(stringnewTime);
              return newTime;
            });
          }
        }, 1000);
        return () => {
          clearTimeout(timer);
        }
      }
      if (otpVerificationLimit === 0 && otpVerificationMinLimit === 0) {
        document.getElementById("Verifybtn")?.removeAttribute("disabled");
        setVerifyBtnName("Resend");
        setotpVerificationType("Resend");
        setotpFrontEndData("");
        let invalidOTP = document.getElementById("invalidOTP");
        invalidOTP?.setAttribute('hidden', 'hidden');
        let validationLabel = document.getElementById("OTPLimitExpired");
        validationLabel?.setAttribute("hidden", 'hidden');
      }
    }
    if (otpVerificationLimit !== 0 && otpVerificationMinLimit !== 0) {
      let button = document.getElementById("targetButton");
      button?.setAttribute("disabled", "disabled");
    }
  }, [otpVerificationLimit, bool])

  const onTextBoxValueChange = (e: any) => {
    try {

      let ostate = state;
      ostate[e.target.name] = e.target.value;
      setState({ ...state, [e.target.name]: e.target.value });
      onBlurValidation(e);
    } catch (error) {

    }
  }

  const onTextBoxotpValueChange = (e: any) => {
    try {
      if (e.target.value.length <= 1) {
          if (OTPState[e.target.id].length < 1 || OTPState[e.target.id].length == 1) {
              OTPState = { ...OTPState, [e.target.id]: e.target.value }
              setOTPState(OTPState)
          }
          const [fieldIndex] = e.target.id.split("_")[1];

          let fieldIntIndex = parseInt(fieldIndex, 10);
          let nxtFieldIntIndex = (e.target.value != "") ? fieldIntIndex + 1 : fieldIntIndex - 1;
          let elementID = 'OTPValue_' + nxtFieldIntIndex;
          if (nxtFieldIntIndex < 8) {
              const timer = setTimeout(() => {
                  const currentfield = document.getElementById(e.target.id);
                  if (e.target.value != "") {
                      currentfield?.setAttribute("type", "password");
                  }
                  else {
                      currentfield?.setAttribute("type", "text");
                  }
              }, 1000);
              const nextfield = document.getElementById(elementID);
              if (nextfield !== null) {
                  nextfield?.focus();
              }
          }
      }
      else if (e.target.value.length >= 1 || e.target.value.length <= 6) {
          const OTPChars = e.target.value.split('');
          let fieldIndex = e.target.id.split("_")[1];
          let charLength = OTPChars.length > 6 ? 6 : OTPChars.length;
          for (let i = 0; i < charLength; i++) {
              OTPState = { ...OTPState, [`OTPValue_${fieldIndex}`]: OTPChars[i] }
              setOTPState(OTPState);
              const currentfield = document.getElementById(`OTPValue_${fieldIndex}`);
              const timer = setTimeout(() => {
                  if (e.target.value != "") {
                      currentfield?.setAttribute("type", "password");
                  }
                  else {
                      currentfield?.setAttribute("type", "text");
                  }
              }, 1000);
              fieldIndex = Number(fieldIndex) + 1;
          }
      }
      let Verifybtn = document.getElementById("Verifybtn");
      let invalidOTP = document.getElementById("invalidOTP");
      invalidOTP?.setAttribute('hidden', 'hidden');
      if (OTPState.OTPValue_1 != '' && OTPState.OTPValue_2 != '' && OTPState.OTPValue_3 != '' && OTPState.OTPValue_4 != '' && OTPState.OTPValue_5 != '' && OTPState.OTPValue_6 != '') {
          if (otpVerificationType == "Verify") {
          Verifybtn?.removeAttribute('disabled');
          }
          otpFrontEndData = `${OTPState.OTPValue_1}${OTPState.OTPValue_2}${OTPState.OTPValue_3}${OTPState.OTPValue_4}${OTPState.OTPValue_5}${OTPState.OTPValue_6}`
          setotpFrontEndData(otpFrontEndData)

      }
      else {
          if (otpVerificationType == "Verify") {
          Verifybtn?.setAttribute("disabled", "disabled");
          }
      }
  }
  catch (error) {

  }
  }
  const onLoginWithMicrosoftClick = async () => {
    let loaderElement1 = document.getElementById("targetLoader")
    loaderElement1?.removeAttribute("hidden");
    let button = document.getElementById("targetButton");
    button?.setAttribute("disabled", "disabled");
    const msalConfig: Configuration = {
      auth: {
        clientId: `${process.env.REACT_APP_SSO_CLIENT_ID}`,
        authority: `${constants.URL.REACT_APP_AUTHORITY_URL}`,
        redirectUri: `${constants.URL.REACT_APP_SSO_REDIRECT_URL}`
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // Change cache location if needed
        storeAuthStateInCookie: false
      }
    };

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    let loginPopupPromise: Promise<AuthenticationResult> | null = null;
    try {

      if (loginPopupPromise) {
        throw new Error('An authentication interaction is already in progress.');
      }

      const loginRequest: PopupRequest = {
        scopes: ['user.read'] // Replace with the necessary scopes for your application
      };

      loginPopupPromise = msalInstance.loginPopup(loginRequest);

      const loginResponse = await loginPopupPromise;
      const account = loginResponse.account;
      const userEmail = account?.username;
      loginPopupPromise = null;
      const loggedinUserToken = loginResponse.accessToken;
      let encryptedLoggedinUserToken = objCryptoJS.AES.encrypt(loggedinUserToken, reactNode_en_K).toString();


      let config = {
        method: "POST",
        url: `${baseURL}/UserAuthentication/Login`,
        data: {
          "clientMailID": userEmail,
          "clientPassword": "",
          "isSSOAccount": true,
          "loggedinUserToken": encryptedLoggedinUserToken
        }
      }
      let data: any = await Insert_Details(config);
      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.setAttribute("hidden", "true")

      if (data.data.statusText == "ok") {

        let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, reactNode_en_K);
        var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

        let enPass = objCryptoJS.AES.encrypt(dePass, reactClient_en_K).toString();
        setCreatedSession(enPass);
        localStorage.setItem("cid_t", enPass);
        window.location.pathname = "/Settings"
      }
      else if (data.data.data == "Your account is inactive. Please login to an active account or contact AVAMIGRATRON Team.") {

        setFailedPopUp(data.data.data);

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Unauthorized Access") {

        setFailedPopUp(data.data.data);

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication type") {
        setFailedPopUp("Kindly Use Email ID and Password to Login");
        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");

      }
      else {

        setFailedPopUp("Please provide valid details!")

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
        //#endregion

      }



    } catch (error) {
      if (typeof error === 'object' && error !== null) {
        const errorMessage = (error as Error).message;
        if (
          errorMessage.includes('popup_window_error') ||
          errorMessage.includes('empty_window_error')
        ) {
          let loaderElement = document.getElementById("targetLoader")
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Popup has been blocked. Please enable to it to authenticate your account");

          //#region Show PopUp
          setIsPopUpOpen(true);
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
        }
        else if (errorMessage.includes('user_cancelled')) {
          let loaderElement = document.getElementById("targetLoader")
          loaderElement?.setAttribute("hidden", "true")

          // let y = document.getElementById("targetButton")
          // y?.removeAttribute("disabled")
        }
        else {
          let loaderElement = document.getElementById("targetLoader")
          loaderElement?.setAttribute("hidden", "true")

          let y = document.getElementById("targetButton")
          y?.removeAttribute("disabled")

          setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

          //#region Show PopUp
          setIsPopUpOpen(true);
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
        }
      }
      if (loginPopupPromise) {
        loginPopupPromise.catch(() => { });
        loginPopupPromise = null;
      }
    }
  }
  const onBlurValidation = (e: any) => {
    try {


      // let button = document.getElementById("targetButton");
      let invalidTargetEmailElement = document.getElementById("invalidTargetEmail");
      let invalidTargetPasswordElement = document.getElementById("invalidTargetPassword");
      let emptyTargetEmailElement = document.getElementById("emptyTargetEmail");
      let emptyTargetPasswordElement = document.getElementById("emptyTargetPassword");


      if (e.target.name == "targetEmail") {
        if (state.targetEmail == "") {
          invalidTargetEmailElement?.setAttribute("hidden", "true");
          emptyTargetEmailElement?.removeAttribute("hidden");
        }
        else if (!((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))) {
          emptyTargetEmailElement?.setAttribute("hidden", "true");
          invalidTargetEmailElement?.removeAttribute("hidden");
        }
        else {
          invalidTargetEmailElement?.setAttribute("hidden", "true");
          emptyTargetEmailElement?.setAttribute("hidden", "true");
        }
      }
      else if (e.target.name == "targetPassword") {
        if (state.targetPassword == "") {
          emptyTargetPasswordElement?.removeAttribute("hidden");
          invalidTargetPasswordElement?.setAttribute("hidden", "true");
          setIsMSTeamsPasswordEyeOpen(false)
        }
        else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(state.targetPassword))) {
          invalidTargetPasswordElement?.removeAttribute("hidden");
          emptyTargetPasswordElement?.setAttribute("hidden", "true");
        }
        else {
          invalidTargetPasswordElement?.setAttribute("hidden", "true");
          emptyTargetPasswordElement?.setAttribute("hidden", "true");
        }
      }



      if (state.targetEmail != undefined && state.targetEmail != "" && state.targetEmail.length <= 320 && state.targetPassword != undefined && state.targetPassword != "" && state.targetPassword.length >= 8 && state.targetPassword.length <= 20) {
        let Check = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.targetEmail);
        let Check2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(state.targetPassword);
        if (Check == true && Check2 == true) {
          let submitButton = document.getElementById("targetButton");
          submitButton?.removeAttribute('disabled');
        }
        else {
          let submitButton = document.getElementById("targetButton");
          submitButton?.setAttribute('disabled', 'disabled');

        }
      }
      else {
        let submitButton = document.getElementById("targetButton");
        submitButton?.setAttribute("disabled", "disabled");
      }
    }
    catch (error: any) {
      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.setAttribute("hidden", "true")

      let y = document.getElementById("targetButton")
      y?.removeAttribute("disabled")

      setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
    }
  }

  const submitClick = async (e: any) => {
    try {
      if (otpVerificationType === "Verify") {
        let loaderElement1 = document.getElementById("targetLoader")
        loaderElement1?.removeAttribute("hidden");
      }
      // let faderElement = document.getElementById("targetFadeDiv")
      // faderElement?.removeAttribute("hidden");
      if (otpVerificationType === "Verify") {
        let button = document.getElementById("targetButton");
        button?.setAttribute("disabled", "disabled");
      }


      let enPass = objCryptoJS.AES.encrypt(state.targetPassword, reactNode_en_K).toString();

      let config = {
        method: "POST",
        url: `${baseURL}/UserAuthentication/Login`,
        data: {
          "clientMailID": state.targetEmail,
          "clientPassword": enPass,
          "isSSOAccount": false,
          "loggedinUserToken": "" //To check whether the user is waited for 5 mins or not.
        }
      }

      setIsPopUpOpen(true)
      let data: any = await Insert_Details(config);
      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.setAttribute("hidden", "true")
      if (otpVerificationType === "Verify") {
        let y = document.getElementById("targetButton")
        y?.removeAttribute("disabled")
      }

      if (data.data.statusText == "ok"){

        if (data.data.data.SecretKey !== "") {
          let SecretKey = objCryptoJS.AES.decrypt(data.data.data.SecretKey, reactNode_en_K);
          var SecretKeyDecrypted = SecretKey.toString(objCryptoJS.enc.Utf8);

          setotpVerificationMinLimit(0);
          setotpVerificationMinLimitForTimer('00');
          setotpData(SecretKeyDecrypted);
          setotpVerificationType("Verify")

          //#region Show PopUp
          setIsPopUpOpen(true)
          document.getElementById("OTPAlertPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("OTPAlertPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("OTPAlertPopUp")?.setAttribute("role", "dialog");
          document.getElementById("OTPAlertPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("OTPAlertPopUp")?.setAttribute("style", "display:block");
          document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
          document.getElementById("Verifybtn")?.setAttribute("disabled", "true");
          bool = true;
          setbool(bool);
          //#endregion
        }


        setIsPopUpOpen(false)
        let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, reactNode_en_K);
        var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

        let enPass = objCryptoJS.AES.encrypt(dePass, reactClient_en_K).toString();
        setCreatedSession(enPass);




      }
      else if (data.data.data.value != undefined && data.data.data.value != null && data.data.data.value == "OTP Limit Reached") {
        //#region Show PopUp
        if (otpVerificationType === "Verify") {
          failedPopUp = "Your OTP Limit expired. Please retry after " + data.data.data.minutesLeft + " minutes or reach AVAMIGRATRON Support.";
          setFailedPopUp(failedPopUp);
          //#region Show PopUp
          setIsPopUpOpen(true)
          document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
          document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
          document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
        }
        else {

          setIsPopUpOpen(true)
          document.getElementById("OTPAlertPopUp")?.removeAttribute("aria-hidden");
          document.getElementById("OTPAlertPopUp")?.setAttribute("aria-modal", "true");
          document.getElementById("OTPAlertPopUp")?.setAttribute("role", "dialog");
          document.getElementById("OTPAlertPopUp")?.setAttribute("class", "modal fade show");
          document.getElementById("OTPAlertPopUp")?.setAttribute("style", "display:block");
          document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
          document.getElementById("Verifybtn")?.setAttribute("disabled", "true");
          otpVerificationMinLimitForTimer = `${data.data.data.minutesLeft}`
          setotpVerificationMinLimit(data.data.data.minutesLeft);
          setotpVerificationMinLimitForTimer(otpVerificationMinLimitForTimer);
          setotpVerificationSecLimit(data.data.data.secondsLeft);
          let validationLabel = document.getElementById("OTPLimitExpired");
          validationLabel?.removeAttribute("hidden");
          setotpVerificationType("Resend")
          setVerifyBtnName("Resend");
          bool = true;
          setbool(bool);
        }
      }
      else if (data.data.data == "You have reached maximum Login attempts. Please reach out to AVAMIGRATRON Team") {

        setFailedPopUp(data.data.data);

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Your account is inactive. Please login to an active account or contact AVAMIGRATRON Team.") {

        setFailedPopUp(data.data.data);

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication type") {
        setFailedPopUp("Kindly Use Microsoft to Login");
        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");

      }
      else {

        setFailedPopUp("Please provide valid Mailid/Password details!")

        //#region Show PopUp
        setIsPopUpOpen(true)
        document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
        document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
        document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
        document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
        document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
        document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
        //#endregion

      }
    } catch (error: any) {
      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.setAttribute("hidden", "true")

      let y = document.getElementById("targetButton")
      y?.removeAttribute("disabled")

      setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      //#endregion
    }
  }

  const onForgotPasswordClick = async () => {
    try {
      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.removeAttribute("hidden")
      history.push({
        pathname: "/ForgotPassword",
        state: `${state.targetEmail}`
      })
    } catch (error: any) {

      let loaderElement = document.getElementById("targetLoader")
      loaderElement?.setAttribute("hidden", "true")

      let y = document.getElementById("targetButton")
      y?.removeAttribute("disabled")

      setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

      //#region Show PopUp
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
      document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
      //#endregion

    }
  }
  const failedPopUpOkClick = () => {
    try {
      //#region Hide PopUp
      setIsPopUpOpen(false)
      document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade");
      document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:none");
      document.getElementById("targetFailedPopUp")?.setAttribute("aria-hidden", "true");
      document.getElementById("targetFailedPopUp")?.removeAttribute("aria-modal");
      document.getElementById("targetFailedPopUp")?.removeAttribute("role");
      document.getElementById("targetFadeDiv")?.setAttribute("hidden", "true");
      //#endregion
    } catch (error) {
    }
  }

  const onPasswordKeyDown = async (e: any) => {
    try {

      if (e.keyCode === 13) {
        let mailRegex = ((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))
        let pswdRegex = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(state.targetPassword))
        if (mailRegex == true && pswdRegex == true && isPopUpOpen == false) {
          await submitClick(e);
        }
      }
    } catch (error) {
    }
  }

  const handleKeyDown = (e:any) => {
    try{
      if(e.key === "Enter"){
        if(otpFrontEndData != "" && otpFrontEndData != undefined && otpFrontEndData != null){
          verifyButtonClick(otpVerificationType)  
         }
      }
    }
    catch(error){

    }
    
  }

  const verifyButtonClick = (data: any) => {
    try {
      //#region Hide PopUp

      let submitButton = document.getElementById("Verifybtn");
      let invalidOTP = document.getElementById("invalidOTP");
      if (data !== "Resend") {


        if (otpData === otpFrontEndData) {
          setIsPopUpOpen(false)
          document.getElementById("OTPAlertPopUp")?.setAttribute("class", "modal fade");
          document.getElementById("OTPAlertPopUp")?.setAttribute("style", "display:none");
          document.getElementById("OTPAlertPopUp")?.setAttribute("aria-hidden", "true");
          document.getElementById("OTPAlertPopUp")?.removeAttribute("aria-modal");
          document.getElementById("OTPAlertPopUp")?.removeAttribute("role");
          document.getElementById("OTPAlertPopUp")?.setAttribute("hidden", "true");
          localStorage.setItem("cid_t", createdSession);
          window.location.pathname = "/Settings"
        }
        else {

          invalidOTP?.removeAttribute("hidden");
          submitButton?.setAttribute('disabled', 'disabled');
        }
      }
      else {
        setVerifyBtnName("Verify");
        setotpVerificationType("Resend");
        document.getElementById("Verifybtn")?.setAttribute("disabled", "true");
        invalidOTP?.setAttribute('hidden', 'hidden');
        let OTPObj:any = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
        setOTPState(OTPObj)
        setotpVerificationLimit(45);
        setotpVerificationSecLimit('45');
        submitClick(data);
      }
      //#endregion
    } catch (error) {
    }
  }

  return (
    <div className="float-start  width100">
      <span className='reg-border-left-signup'></span>
      <div className="col-md-12 float-start bg-white screen-height px-0">
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 margin-leftlayout float-start pt-4">
          <a href="/Login" className='anchorAsPointer'>
            <img src="/Images/brand_logo.svg" className="brand-logo icon-responsive mt-4 ms-5" alt="logo"></img>
          </a>
          {/* <!-- Registration form starts here --> */}
          <div className="row justify-content-center mt-4 ">
            <div className="col-md-6 float-start">
              <h1 className="font-24 font-bold mb-3 reg-header-color">Slack To Teams Migration</h1>
              {/* <!-- main heading starts here --> */}
              <h4 className="font-18 font-bold mb-4">Welcome!</h4>
              {/* <!-- main heading ends here --> */}
              <div className="d-grid gap-3 mb-4">
                <button className="btn btn-light font-14 font-semibold" type="button" onClick={onLoginWithMicrosoftClick}><span><img src='/Images/microsoft-icon.svg' alt="microsoft" className="me-2"></img></span>Login with Microsoft</button>
              </div>
              <p className="font-14 font-semibold grey-200 text-center or-divider "><span className='bg-white px-3 position-relative' style={{ zIndex: "1" }}>Or</span></p>
              {/* <!-- top para starts here --> */}
              {/* <p className="font-14 font-regular grayColor mb-4">Please Log in to continue
            </p> */}
              {/* <!-- top para ends here --> */}
              <div className="emailDiv">
                <label className="font-14 font-semibold pb-2" htmlFor="targetEmail">Email Address</label>
                <input type="email" className="form-control custom-form" id="targetEmail"
                  placeholder="Enter Email Address" name="targetEmail" value={state.targetEmail} onChange={onTextBoxValueChange} />
                <label className="float-start font-semibold pb-3 font-14 required" id="invalidTargetEmail" hidden>Invalid Email Address provided</label>
                <label className="float-start font-semibold pb-3 font-14 required" id="emptyTargetEmail" hidden>Email Address cannot be empty</label>
              </div>
              <div className="passwordDiv">
                <label className="font-14 font-semibold  pt-3 pb-2" htmlFor="targetPassword">Password</label>
                <div className="eyeiconDiv">
                  <input type={isMSTeamsPasswordEyeOpen ? "text" : "password"} className="form-control custom-form eyepadding" id="targetPassword"
                    placeholder="Enter Password" value={state.targetPassword} name="targetPassword" onChange={onTextBoxValueChange} onKeyDown={onPasswordKeyDown} />
                  <img src={isMSTeamsPasswordEyeOpen == false && state.targetPassword != undefined && state.targetPassword != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"} className={isMSTeamsPasswordEyeOpen ? "eyeiconImg anchorAsPointer" : "eyecloseiconImg anchorAsPointer"} alt="eye-open-icon" onClick={() => (state.targetPassword != undefined && state.targetPassword != "") ? setIsMSTeamsPasswordEyeOpen(isMSTeamsPasswordEyeOpen ? false : true) : null} />
                </div>
                <label className="float-start font-semibold pb-3 font-14 required" id="invalidTargetPassword" hidden>Invalid Password provided</label>
                <label className="float-start font-semibold pb-3 font-14 required" id="emptyTargetPassword" hidden>Password cannot be empty</label>
              </div>
              <div className="forgotpasswordDiv">
                <span className="forgotPasswordSpan"><a className="forgot_password font-semibold font-14 anchorAsPointer" onClick={onForgotPasswordClick}>Forgot Password</a></span>
              </div>
              <div className="loginDiv">
                {/* <!-- button starts here --> */}
                <button id="targetButton" className="btn btn-primary-theme loginButton" onClick={(event) => submitClick(event)} >LOGIN</button>
                {/* <!-- button ends here --> */}
              </div>

              <div className="createAccountLoginDiv">
                <p className="font-14 font-regular grayColor">Don't have an account ? <span ><a href="/SignUp" className="sign-in anchorAsPointer font-14 font-semibold">Create Account</a></span>
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Registration form ends here --> */}
        </div>
        {/* <!--background image form starts here --> */}
        <div className="col-lg-5 d-lg-block d-md-none d-none d-sm-none float-start p-0 Reg-img-position">
          <img src="/Images/signup_img_bg.png" className="Registration_bg_img" alt="registration-bg-img" />
        </div>
        {/* <!--background image form ends here --> */}
      </div>

      {/* <!--Loader Screen--> */}
      <div className="overlay" id='targetLoader' hidden={true}>
        <div className="Loader-Content-position">
          <div className="spinner-border loaderImg Loader-Img-position text-primary aligncenter" role="status">
            <span className="visually-hidden"></span>
          </div>
          {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
        </div>
      </div>
      {/* <!--Loader Screen--> */}

      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="targetFailedPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src="/Images/Alert.svg" className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--Alert pop-up--> */}

      <div className="modal fade" id="OTPAlertPopUp" tabIndex={-1} aria-hidden="true" >
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/otp-img.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Enter OTP!</h5>
                <p className="success-text w-100  mb-4">Please enter the 6-digit OTP that was send to your email</p>
                <div className="d-flex justify-content-center mb-2 w-100 ">
                  <div className="d-flex justify-content-between w-75">
                    <input id="OTPValue_1" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_1} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                    <input id="OTPValue_2" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_2} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                    <input id="OTPValue_3" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_3} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                    <input id="OTPValue_4" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_4} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                    <input id="OTPValue_5" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_5} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                    <input id="OTPValue_6" type="text" className="form-control otp-form-Admin px-auto" onKeyDown ={handleKeyDown} value={OTPState.OTPValue_6} onChange={(e) => onTextBoxotpValueChange(e)}></input>
                  </div>

                </div>
                <div className="d-flex justify-content-center mb-2 w-100 ">
                  <p className="font-12 font-semibold mb-3 text-end w-75">Resend OTP in <span>{otpVerificationMinLimitForTimer}:{otpVerificationSecLimit}</span></p>
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button type="button" id="Verifybtn" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => verifyButtonClick(otpVerificationType)}>{VerifyBtnName}</button>
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <label className="float-start font-semibold pb-3 font-14 required text-center" id="invalidOTP" hidden>Invalid OTP provided</label>
                  <label className="float-start font-semibold pb-3 font-14 required text-center" id="OTPLimitExpired" hidden>OTP Limit expired. Please retry after sometime.</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="targetFadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>
  );
}