import React, { useEffect, useState } from 'react';
import { gridLoad, sendRequestToAPI } from '../../Services/InventoryServices';
import dateformat from 'dateformat';
import ReactTooltip from "react-tooltip";
import { ExportToCsv } from 'export-to-csv';
import dateFormat from 'dateformat';
import $ from 'jquery';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import { error } from 'console';
import constants from '../../Common/Constants'



export function HighLevelInventory() {

  let GridLoadarray: any[] = [];
  let [gridData, setGridData] = useState(GridLoadarray);
  let [highLevelInventoryStatus, setHighLevelInventoryStatus] = useState("Failed");
  let [successFailedClass, setsuccessFailedClass] = useState("status FailedStatus text-center font-14 font-semibold border border-danger ms-3");
  let [totalItemsCount, setTotalItemsCount] = useState(10);
  let [exportsearchValue, setexportsearchValue] = useState("");
  let [searchValue, setSearchValue] = useState("");
  let [columnOrder, setColumnOrder] = useState("asc");
  let [columnName, setColumnName] = useState("LastActivity");
  let [sorthide, setsorthide] = useState("");
  const [temp, setTemp] = useState(0);
  const [type, settype] = useState("Grid");
  let [ChatType, setChatType] = useState("Channel,Shared Channel")
  let [ismm, setismm] = useState(false)
  let [isdm, setisdm] = useState(false)
  let [IsArchived, setIsArchived] = useState("")
  let [hideFilterPopUp, sethideFilterPopUp] = useState(true);
  let [pageindex, setpage] = useState(1)
  let [isprivate, setisprivate] = useState(false)
  let [ispublic, setispublic] = useState(false)
  let [isyes, setisyes] = useState(false)
  let [isno, setisno] = useState(false)
  let [channel, setchannel] = useState(false);
  let [isSharedchannel, setIsSharedchannel] = useState(false)
  let [CreatedOnFrom, setCreatedOnFrom] = useState("")
  let [CreatedOnTo, setCreatedOnTo] = useState("")
  let [LastActivityFrom, setLastActivityFrom] = useState("")
  let [LastActivityTo, setLastActivityTo] = useState("")
  let [DM, setDM] = useState(false)
  let [MM, setMM] = useState(false)
  let [SlackChatType, setSlackChatType] = useState(false)
  let [boolVal, setBoolVal] = useState(0);
  const [maxMigrationToDate, setMaxMigrationToDate] = useState(new Date().toISOString().split('T')[0]);
  let [ErrorMessage, setErrorMessage] = useState("");
  let [LoadMore, setLoadmore] = useState("");
  let [Checktype, setChecktype] = useState(0);
  let [countPerPage, setCoutPerPage] = useState(10);
  let [boolValChannel, setBoolVal1] = useState(0);
  let [boolValChat, setBoolVal2] = useState(0);
  let [HighLevelStatus, setHighLevelStatus] = useState(false);
  let [checkpgload, setcheckpgload] = useState(false);
  let [ConnectSettings, setConnectSettings] = useState(false);
  let [tabSwitchInv, settabSwitchInv] = useState(false);
  let [tabSwitchInv1, settabSwitchInv1] = useState(false);
  let [IsFilterApplied, setIsFilterApplied] = useState(false);
  let [IsChannel, setIsChannel] = useState(false);
  let [IsInitialChannelLoad, setIsInitialChannelLoad] = useState(false);
  let [getHighLevelClick, setgetHighLevelClick] = useState(false);
  let [loaderContent, setloaderContent] = useState("");
  let [IsChat, setIsChat] = useState(false);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let[showHighLevelInventoryStatus,setshowHighLevelInventoryStatus]=useState(false)
  let InActiveTimeout: NodeJS.Timeout;

  let ClientID = 0;
  let local: any = localStorage.getItem("cid_t");
  let adminLocal: any = localStorage.getItem("aid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  let adminObjClient: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientToken: any;
  let adminToken: any;

  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    ClientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

    // Admin Token Decryption 
    if(adminLocal != undefined){
      let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
      let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
      adminObjClient = jwt(adminDecryptedValue);
      adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
  }

  }
  useEffect(() => {
    resetSessionTimer();
    checkpgload = true;
    setcheckpgload(checkpgload);
    let removeFooter: any = document.getElementById("MainContainerMFE");
    removeFooter.classList.add("container-bg-withoutFooter");
    loadGridPage();
    var today = new Date();
    let date = (today.getDate().toString()).length < 2 ? "0" + (today.getDate().toString()) : (today.getDate().toString());
    let month = (today.getMonth() + 1).toString().length < 2 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString();
    let newdate = today.getFullYear() + '-' + month + '-' + date;
    setMaxMigrationToDate(newdate)
    //#region Event handlers Keypad click and mouse click
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage, ChatType])

  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      ErrorMessage = "Your session has expired. Please log in again to continue using the application."
      setErrorMessage(ErrorMessage);

      //#region Show PopUp
      let popup = document.getElementById("alertPopUp")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {
      if (ClientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        //console.log(data);
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(clientToken);
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp")
      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp")
      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion



  const exportmethod = async () => {
    try {
       
      var config: any = {};

      if (ClientID != 0 && clientToken != undefined) {
        let a: any = document.getElementById("loader");
        a.removeAttribute("hidden");

        config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/HighLevelInventory/pageLoad`,
          token: clientToken,

          data: {
            "clientID": ClientID,
            "type": "Grid",
            "Filterparams":
            {
              "searchText": `${exportsearchValue}`,
              "sortColumn": columnName,
              "sortType": columnOrder,
              "isPrivate": isprivate == false ? null : "Private",
              "isPublic": ispublic == false ? null : "Public",
              "ChatTypes": ChatType,
              "isSharedchannel": isSharedchannel == false ? null : isSharedchannel,
              "IsArchivedyes": isyes == false ? null : "Archived",
              "IsArchivedno": isno == false ? null : "UnArchived",
              "CreatedOnToDate": CreatedOnFrom == "" ? null : CreatedOnFrom,
              "CreatedOnFromDate": CreatedOnTo == "" ? null : CreatedOnTo,
              "LastActivityFromDate": LastActivityFrom == "" ? null : LastActivityFrom,
              "LastActivityToDate": LastActivityTo == "" ? null : LastActivityTo,
              "pageIndex": pageindex,
              "type": "Export"
            }
          },

        }
        )
       
        if(adminLocal !=undefined && adminLocal !== null){
          (config.data as any).token = adminToken
        }

        var data: any = await gridLoad(config);
        if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" && data.data.statusCode == 400) {
          ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
          setErrorMessage(ErrorMessage);
          let popup = document.getElementById("alertPopUp")

          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        else if (data.data.data == "No records were fetched") {
          ErrorMessage = "No Records Found in the Grid";
          setErrorMessage(ErrorMessage);
          let popup = document.getElementById("alertPopUp")
          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if ((data.data.data == "Please Connect Settings to proceed Further in Inventory" && data.data.statusCode == 400) || (data.data.data.HighLevelInventory[0].length == 0)) {
          ErrorMessage = "No Records Found in the Grid";
          setErrorMessage(ErrorMessage);
          let popup = document.getElementById("alertPopUp")
          popup?.removeAttribute("aria-hidden");
          popup?.setAttribute("aria-modal", "true");
          popup?.setAttribute("role", "dialog");
          popup?.setAttribute("class", "modal fade show");
          popup?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        else {
          var currentData = data.data.data.HighLevelInventory[0];
          for (let i = 0; i < currentData.length; i++) {
            let a: any = (dateformat(currentData[i].CreatedOn, "mm/dd/yyyy hh:mm:ss"));
            currentData[i].CreatedOn = a;

            let b: any = dateformat(currentData[i].LastActivity, "mm/dd/yyyy hh:mm:ss");
            currentData[i].LastActivity = b;
          }

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Highlevel Inventory Details',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "HighlevelInventory Details"
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
          };
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(currentData);
        }
      }
      else {
        localStorage.clear();
      }
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');

    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const loadGridPage = async () => {
    try {
      var config:{ [key: string]: any } = {};
      if (getHighLevelClick == false) {
        let a: any = document.getElementById("loader");
        a.removeAttribute("hidden");
      }
      else {
        let a: any = document.getElementById("delayloader");
        a.removeAttribute("hidden");
      }
      exportsearchValue = searchValue;
      setexportsearchValue(exportsearchValue);
      if (ClientID != 0 && clientToken != undefined) {
        config = ({
          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/HighLevelInventory/pageLoad`,
          token: clientToken,

          data: {
            "clientID": ClientID,
            "type": "pageload",
            "Filterparams":
            {
              "searchText": `${exportsearchValue}`,
              "sortColumn": columnName,
              "sortType": columnOrder,
              "isPrivate": isprivate == false ? null : "Private",
              "isPublic": ispublic == false ? null : "Public",
              "ChatTypes": ChatType,
              "isSharedchannel": isSharedchannel == false ? null : isSharedchannel,
              "IsArchivedyes": isyes == false ? null : "Archived",
              "IsArchivedno": isno == false ? null : "UnArchived",
              "CreatedOnToDate": CreatedOnFrom == "" ? null : CreatedOnFrom,
              "CreatedOnFromDate": CreatedOnTo == "" ? null : CreatedOnTo,
              "LastActivityFromDate": LastActivityFrom == "" ? null : LastActivityFrom,
              "LastActivityToDate": LastActivityTo == "" ? null : LastActivityTo,
              "pageIndex": pageindex,
              "type": type
            }
          },

        }
        )

        if(adminLocal !=undefined && adminLocal !== null){
          (config.data as any).token = adminToken
        }
         
        var data: any = await gridLoad(config);
        ////console.log(data);
        var Data = data.data;


      }
      else {
        localStorage.clear();
      }

      
      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" && data.data.statusCode == 400) {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        setGridData(["No Records Found"]);
        setTotalItemsCount(0);
        let popup = document.getElementById("alertPopUp");
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Please Connect Settings to proceed Further in Inventory" && data.data.statusCode == 400) {
        let abc: any = document.getElementById("loader");
        abc.setAttribute("hidden", "hidden");
        ConnectSettings = true;
        setConnectSettings(ConnectSettings);
        ErrorMessage = data.data.data;
        setErrorMessage(ErrorMessage);
        setGridData(["No Records Found"]);
        setTotalItemsCount(0)
      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else {
        if ((data.data.data == "No records were fetched" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data == "no records found") && (data.data.statusText == "ok")) {
          setGridData(["No Records Found"]);
          setTotalItemsCount(0);
          setsuccessFailedClass("status status-styling4 text-center font-14 font-semibold border border-success ms-3");
          setHighLevelInventoryStatus("Success");
        }
        else if (data.data.statusText != "ok" && data?.data?.statusText != "Inventory Failed") {
          var currentData1 = Data.statusText.HighLevelInventory;
          ////console.log(currentData1);
          setGridData(currentData1[0]);
          if (Data.statusText.HighLevelInventory[1] != undefined) {
            setTotalItemsCount(currentData1[1][0].ChatsCount);
            ////console.log(totalItemsCount);
          }
          else {
            setTotalItemsCount(0);
          }
          if ((checkpgload == true) && (currentData1[2] != undefined && currentData1[2][0] != undefined && currentData1[2][0].Status != undefined && currentData1[2][0].Status.toLowerCase() === "inprogress")) {
            let a: any = document.getElementById("delayloader");
            a.removeAttribute("hidden");
            getHighLevelClick = true;
            setgetHighLevelClick(getHighLevelClick);
            const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
            await snooze(10000);
            await loadGridPage();
          }
          else if (currentData1[2] != undefined && currentData1[2][0] != undefined && currentData1[2][0].Status != undefined && currentData1[2][0].Status.toLowerCase() ==="inprogress") {
            let a: any = document.getElementById("delayloader");
            a.removeAttribute("hidden");
            return currentData1[2][0].Status;
          }
          else if (checkpgload != true && currentData1[2] != undefined && currentData1[2][0] != undefined && currentData1[2][0].Status != undefined && currentData1[2][0].Status.toLowerCase() === "failed") {
            let a: any = document.getElementById("delayloader");
            a.removeAttribute("hidden");
            let c: any = document.getElementById("btnInv");
            c.disabled = false;
            let ErrorMessage = (ChatType == 'Channel,Shared Channel') ? "Failed to Fetch Slack Channels. Please try again Later" : "Failed to Fetch Slack Multi-Party and Direct chats. Please try again Later"
            setErrorMessage(ErrorMessage);
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            if(data.data.data.HighLevelInventory[0] != undefined && data.data.data.HighLevelInventory[0].length!=0){
              setGridData(data.data.data.HighLevelInventory[0]);
            }
            else{
              setGridData(["No Records Found"]);
            }
            return currentData1[2][0].Status;
          }
          else if (checkpgload == true && currentData1[2] != undefined && currentData1[2][0] != undefined && currentData1[2][0].Status != undefined && currentData1[2][0].Status.toLowerCase() == "Failed"){
            setsuccessFailedClass("status status-styling4 text-center font-14 font-semibold border border-success ms-3");
            setHighLevelInventoryStatus("Failed");
            setshowHighLevelInventoryStatus(true)

            if(Data.statusText.HighLevelInventory[0] != undefined && Data.statusText.HighLevelInventory[0].length!=0){
              setGridData(Data.statusText.HighLevelInventory[0]);
            }
            else{
              setGridData(["No Records Found"]);
            }
          }
        }
        else {
          if(data.data.data.HighLevelInventory[2][0].Status == null || data.data.data.HighLevelInventory[2][0].Status.toLowerCase() === "completed" ) {
            setshowHighLevelInventoryStatus(true)
            setsuccessFailedClass("status status-styling4 text-center font-14 font-semibold border border-success ms-3");
              setHighLevelInventoryStatus("Success");
              if(data.data.data.HighLevelInventory[0] != undefined && data.data.data.HighLevelInventory[0].length!=0){
                setGridData(data.data.data.HighLevelInventory[0]);
              }
              else{
                setGridData(["No Records Found"]);
              }
          }
          else if(data.data.data.HighLevelInventory[2][0].Status.toLowerCase() === "failed" ){
            setshowHighLevelInventoryStatus(true)
            setsuccessFailedClass("status FailedStatus text-center font-14 font-semibold border border-danger ms-3");
            setHighLevelInventoryStatus("Failed");
            if(data.data.data.HighLevelInventory[0] != undefined && data.data.data.HighLevelInventory[0].length!=0){
              setGridData(data.data.data.HighLevelInventory[0]);
            }
            else{
              setGridData(["No Records Found"]);
            }
          }
          setLoadmore(Data);
          var currentData = Data.data.HighLevelInventory;
          // ////console.log(currentData);
          // var newdata = Data.data.InventoryName;
          // setGridData(currentData[0]);
          if (Data.data.HighLevelInventory[1] != undefined) {
            setTotalItemsCount(currentData[1][0].ChatsCount);
            ////console.log(totalItemsCount);
          }
          else {
            setTotalItemsCount(0);
          }
          if ((checkpgload == true) && (currentData[2] != undefined && currentData[2][0] != undefined && currentData[2][0].Status != undefined && currentData[2][0].Status.toLowerCase() == "inprogress")) {
            let abc: any = document.getElementById("loader");
            abc.setAttribute("hidden", "hidden");
            let a: any = document.getElementById("delayloader");
            a.removeAttribute("hidden");
            getHighLevelClick = true;
            setgetHighLevelClick(getHighLevelClick);
            const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
            await snooze(10000);
            await loadGridPage();
          }
          else if (currentData[2] != undefined && currentData[2][0] != undefined && currentData[2][0].Status != undefined && currentData[2][0].Status == "inprogress") {
            return currentData[2][0].Status;
          }

          else if (currentData[2] != undefined && currentData[2][0] != undefined && currentData[2][0].Status != undefined && currentData[2][0].Status == "completed") {
            let a: any = document.getElementById("loader");
            a.setAttribute("hidden", "hidden");
            let def: any = document.getElementById("delayloader");
            def.setAttribute("hidden", "hidden");
            let c: any = document.getElementById("btnInv");
            c.disabled = false;
            getHighLevelClick = false;
            setgetHighLevelClick(getHighLevelClick);
            return currentData[2][0].Status;
          }

          else if (checkpgload != true && currentData[2] != undefined && currentData[2][0] != undefined && currentData[2][0].Status != undefined && currentData[2][0].Status == "failed") {
            let a: any = document.getElementById("delayloader");
            a.setAttribute("hidden", "hidden");
            let c: any = document.getElementById("btnInv");
            c.disabled = false;
            let ErrorMessage = (ChatType == 'Channel,Shared Channel') ? "Something went wrong. Please reach AVAMIGRATRON team" : "Something went wrong. Please reach AVAMIGRATRON team"
            setErrorMessage(ErrorMessage);
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            return currentData[2][0].Status;
          }
        }
      }
      let abc: any = document.getElementById("loader");
      abc.setAttribute("hidden", "hidden");
      let def: any = document.getElementById("delayloader");
      def.setAttribute("hidden", "hidden");
      getHighLevelClick = false;
      setgetHighLevelClick(getHighLevelClick);

    }
    catch (error: any) {
      let c: any = document.getElementById("btnInv");
      c.disabled = true;
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const gethighlevel = async () => {
    try {
       

      if (ConnectSettings == true) {
        ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else {
        let a: any = document.getElementById("delayloader");
        a.removeAttribute("hidden");
        let b: any = document.getElementById("btnInv");
        b?.setAttribute("disabled", "disabled");
        if (ChatType == 'Multi Party Chat,Direct Chat' || ChatType == 'Direct Chat' || ChatType == 'Multi Party Chat') {
          loaderContent = "We're working on getting your complete Slack chats list based on the volume of data! Relax! It may take some time!";
          setloaderContent(loaderContent);
        }
        else {
          loaderContent = "We're working on getting your complete Slack Channels list based on the volume of data! Relax! It may take some time!";
          setloaderContent(loaderContent);
        }
        getHighLevelClick = true;
        setgetHighLevelClick(getHighLevelClick);
        gethighlevelapi();//Only Node Trigger

        let variable;
        checkpgload = false;
        setcheckpgload(checkpgload);
        let iteration = 0;

        do {
          //#region Load Grid
          if (iteration == 0) {
            const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
            await snooze(10000);
            iteration++;
          }
          variable = await loadGridPage();
          //#endregion

          //#region Condition Timout and Looping
          if (variable != undefined && variable == "inprogress") {
            const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
            await snooze(10000);
          }
          else if (variable != undefined) {
            let def: any = document.getElementById("delayloader");
            def.setAttribute("hidden", "hidden");
            let c: any = document.getElementById("btnInv");
            c.disabled = false;
            getHighLevelClick = false;
            setgetHighLevelClick(getHighLevelClick);
            break;
          }
          else {
            let def: any = document.getElementById("delayloader");
            def.setAttribute("hidden", "hidden");
            let c: any = document.getElementById("btnInv");
            c.disabled = true;
            getHighLevelClick = false;
            setgetHighLevelClick(getHighLevelClick);
            break;
          }
          //#endregion

          //#region Old
          // checkpgload = false;
          // setcheckpgload(checkpgload);
          //  
          // const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
          // await snooze(10000);
          // alert("3")
          // let variable = await loadGridPage();
          //  
          // while (variable == "InProgress") {
          //    
          //   const snooze = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
          //   await snooze(10000);
          //   alert("4")
          //   let variable = await loadGridPage();
          //   if (variable != "InProgress") {
          //     let a: any = document.getElementById("loader");
          //     a.setAttribute("hidden", "hidden");
          //     let b: any = document.getElementById("btnInv");
          //     b?.removeAttribute("disabled");
          //     break;
          //   }
          // }
          // let loader: any = document.getElementById("loader");
          // loader.setAttribute("hidden", "hidden");
          // let c: any = document.getElementById("btnInv");
          // c.disabled = false;
          //#endregion
        } while (variable == "inprogress")


      }
    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      let b: any = document.getElementById("btnInv");
      b.disabled = true;
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const gethighlevelapi = async () => {
    try {
      debugger
      HighLevelStatus = true;
      setHighLevelStatus(HighLevelStatus);
       
      let config = ({
        method: "POST",
        url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/HighLevelInventory/getHighLevelInventoryAPI`,
        token: clientToken,


        data: {
          "clientID": ClientID,
          "chatType": ChatType
        },

      }
      )

      
      if(adminLocal !=undefined && adminLocal !== null){
        (config.data as any).token = adminToken
      }

    
      let data: any = await gridLoad(config);
      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        
        
        setsuccessFailedClass("status FailedStatus text-center font-14 font-semibold border border-danger ms-3");
        setHighLevelInventoryStatus("Failed");
     
        

      }
      else if (data.data.data == "Invalid authentication token provided") {
        let def: any = document.getElementById("delayloader");
        def.setAttribute("hidden", "hidden");
        enableSessionExpiryPopup();
      }
    }
    catch (error: any) {
      throw error;
    }
  }
  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {

        return gridData.map((obj, index) => {
          var chatName;
          let isArchieved = obj.IsArchived == "UnArchived" ? "No" : "Yes"
          if ((obj.ChatName.length > 20) && (ChatType == 'Channel,Shared Channel' || ChatType == 'Channel' || ChatType == 'Shared Channel')) {
            chatName = obj.ChatName.substring(0, 17) + "..."
          }
          else if ((obj.ChatName.length > 47) && (ChatType == 'Multi Party Chat,Direct Chat' || ChatType == 'Direct Chat' || ChatType == 'Multi Party Chat')) {
            chatName = obj.ChatName.substring(0, 44) + "..."
          }
          else {
            chatName = obj.ChatName;
          }

          if (SlackChatType == false) {
            return (
              <tr className={index % 2 == 1 ? "tabel-ody bgsecondary" : "tabel-body-bg"}>
                <td data-tip={obj.ChatName.length > 20 ? obj.ChatName : ""} data-place={obj.ChatName.length > 20 ? 'right' : ""} className=" py-3 tabel-data-font-level1">{chatName}
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span></td>
                <td className=" py-3 tabel-data-font-level1">{obj.ChatType}</td>
                <td className=" py-3 tabel-data-font-level1">{obj.ChatVisibilty}</td>
                <td className=" py-3 tabel-data-font-level1">{dateformat(obj.CreatedDateTime, "mm/dd/yyyy")}</td>
                <td className=" py-3 tabel-data-font-level1">{dateformat(obj.LastActivity, "mm/dd/yyyy")}</td>
                <td className=" py-3 tabel-data-font-level1">{obj.CreatedBy}</td>
                <td className=" py-3 tabel-data-font-level1">{isArchieved}</td>
              </tr>

            )
          }
          else {
            return (
              <tr className={index % 2 == 1 ? "tabel-ody bgsecondary" : "tabel-body-bg"}>
                <td data-tip={obj.ChatName.length > 47 ? obj.ChatName : ""} data-place={obj.ChatName.length > 47 ? 'right' : ""} className=" py-3 tabel-data-font-level1">{chatName}
                  <span>
                    <ReactTooltip className='mySepecialClass' />
                  </span></td>
                <td className=" py-3 tabel-data-font-level1">{obj.ChatType}</td>
                <td className=" py-3 tabel-data-font-level1">{dateformat(obj.CreatedDateTime, "mm/dd/yyyy")}</td>
                <td className=" py-3 tabel-data-font-level1">{dateformat(obj.LastActivity, "mm/dd/yyyy")}</td>
                <td className=" py-3 tabel-data-font-level1">{obj.CreatedBy}</td>
                <td className=" py-3 tabel-data-font-level1">{isArchieved}</td>
              </tr>

            )
          }
        })
      }
      else if (gridData[0] == undefined && gridData[0].ChatName == undefined) {
        return (<tr> <td className="centertext" colSpan={8}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={8}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
              <p className="mt-2">No records found</p></td>
          </tr>)
      }
    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const sortClicked = (e: any) => {
    try {
       

      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setColumnName(sortval[0])
      setColumnOrder(sortval[1])
      setsorthide(sortID)
      ////console.log("setColumnName", columnName);

    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const handleSearch = (e: any) => {
    try {
       

      setSearchValue(e.target.value);
      setpage(1);
    }

    catch (error: any) {
      ////console.log(error);

    }
  }
  const filterClearClicked = () => {
     
    try {

      if (SlackChatType) {
        // ChatType = 'Multi Party Chat,Direct Chat';
        // setChatType("Multi Party Chat,Direct Chat")
        let DM: any = document.getElementsByName("DM")
        DM[0].checked = false;
        let MM: any = document.getElementsByName("MM")
        MM[0].checked = false;
        setisdm(false)
        setismm(false)
      }
      else {
        let channel: any = document.getElementsByName("Channel")
        channel[0].checked = false;
        let SharedChannel: any = document.getElementsByName("SharedChannel")
        SharedChannel[0].checked = false;
        let Public: any = document.getElementsByName("Public")
        Public[0].checked = false;
        let Private: any = document.getElementsByName("Private")
        Private[0].checked = false;
        // ChatType = 'Channel,Shared Channel';
        // setChatType("Channel,Shared Channel");
        setisprivate(false);
        setispublic(false);
        setchannel(false);
        setIsSharedchannel(false);

      }
      IsFilterApplied = false;
      setIsFilterApplied(IsFilterApplied);
      setIsArchived("");
      setisno(false);
      setisyes(false);
      setCreatedOnFrom("");
      setCreatedOnTo("");
      setLastActivityFrom("");
      setLastActivityTo("");

      let Yes: any = document.getElementsByName("Yes")
      Yes[0].checked = false;
      let No: any = document.getElementsByName("No")
      No[0].checked = false;
      let CreatedOnTo: any = document.getElementsByName("CreatedOnTo")
      CreatedOnTo[0].value = '';
      let CreatedOnFrom: any = document.getElementsByName("CreatedOnFrom")
      CreatedOnFrom[0].value = '';
      let LastActivityTo: any = document.getElementsByName("LastActivityTo")
      LastActivityTo[0].value = '';
      let LastActivityFrom: any = document.getElementsByName("LastActivityFrom")
      LastActivityFrom[0].value = '';

      // let a: any = document.getElementById("ApplyFilter");
      // a.disabled = true;
      boolVal = 0;
      setBoolVal(boolVal);

       

    }
    catch (error: any) {
      ////console.log(error);

    }

  }
  const filterCloseClicked = () => {
     
    try {

      document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");

      if (SlackChatType) {
        // setChatType("Multi Party Chat,Direct Chat")
        let DM: any = document.getElementsByName("DM")
        DM[0].checked = false;
        let MM: any = document.getElementsByName("MM")
        MM[0].checked = false;
        setisdm(false)
        setismm(false)
      }
      else {
        let channel: any = document.getElementsByName("Channel")
        channel[0].checked = false;
        let SharedChannel: any = document.getElementsByName("SharedChannel")
        SharedChannel[0].checked = false;
        let Public: any = document.getElementsByName("Public")
        Public[0].checked = false;
        let Private: any = document.getElementsByName("Private")
        Private[0].checked = false;
        // setChatType("Channel,Shared Channel");
        setisprivate(false);
        setispublic(false);
        setchannel(false);
        setIsSharedchannel(false);
      }
      IsFilterApplied = false;
      setIsFilterApplied(IsFilterApplied);
      setIsArchived("");
      setisno(false);
      setisyes(false);
      setCreatedOnFrom("");
      setCreatedOnTo("");
      setLastActivityFrom("");
      setLastActivityTo("");

      let Yes: any = document.getElementsByName("Yes")
      Yes[0].checked = false;
      let No: any = document.getElementsByName("No")
      No[0].checked = false;
      let CreatedOnTo: any = document.getElementsByName("CreatedOnTo")
      CreatedOnTo[0].value = '';
      let CreatedOnFrom: any = document.getElementsByName("CreatedOnFrom")
      CreatedOnFrom[0].value = '';
      let LastActivityTo: any = document.getElementsByName("LastActivityTo")
      LastActivityTo[0].value = '';
      let LastActivityFrom: any = document.getElementsByName("LastActivityFrom")
      LastActivityFrom[0].value = '';

      let a: any = document.getElementById("ApplyFilter");
      a.disabled = true;
      boolVal = 0;
      setBoolVal(boolVal);

    }
    catch (error: any) {
      ////console.log(error);

    }

  }
  const handleFilterInput = async (e: any) => {
    try {
       
      // boolVal={...boolVal,data:"empty"}
      // setBoolVal(boolVal)     
      if (e.target.name == "Channel") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)

          ////console.log(boolVal);
          setchannel(true);
          ////console.log(channel);
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setchannel(false);
        }
      }
      if (e.target.name == "SharedChannel") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setIsSharedchannel(true)
          ////console.log(isSharedchannel);
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setIsSharedchannel(false)
        }
      }

      if (e.target.name == "DM") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setisdm(true)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setisdm(false)
        }
      }
      if (e.target.name == "MM") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setismm(true)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setismm(false)
        }
      }
      if (e.target.name == "CreatedOnTo") {
        if (e.target.value != '') {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setCreatedOnTo("")
          setCreatedOnTo(e.target.value)
          ////console.log(e.target.value);
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setCreatedOnTo("")
        }
      }
      if (e.target.name == "CreatedOnFrom") {
        if (e.target.value != '') {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setCreatedOnFrom("")
          setCreatedOnFrom(e.target.value)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setCreatedOnFrom("")
        }
      }
      if (e.target.name == "LastActivityTo") {
        if (e.target.value != '') {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setLastActivityTo("")
          setLastActivityTo(e.target.value)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setLastActivityTo("")
        }
      }
      if (e.target.name == "LastActivityFrom") {
        if (e.target.value != '') {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setLastActivityFrom("")
          setLastActivityFrom(e.target.value);
          // dateFormat(LastActivityFrom).toISOString().split('T')[0]
          // (dateFormat(LastActivityFrom, "mm/dd/yyyy h:MM:ss TT").toISOString().split('T')[0])
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setLastActivityFrom("")
        }
      }
      if (e.target.name == "Private") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setisprivate(false);
          setisprivate(true);
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setisprivate(false);
        }
      }
      if (e.target.name == "Public") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal)
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setispublic(false);
          setispublic(true);
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setispublic(false);
        }

      }

      if (e.target.name == "Yes") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal);
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setisyes(false)
          setisyes(true)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setisyes(false)
        }
      }
      if (e.target.name == "No") {
        if (e.target.checked) {
          boolVal = boolVal + 1;
          setBoolVal(boolVal);
          let a: any = document.getElementById("ApplyFilter");
          a?.removeAttribute("disabled");
          setisno(false)
          setisno(true)
        }
        else {
          boolVal = boolVal - 1;
          setBoolVal(boolVal)
          setisno(false)
        }

      }

      //   if((channel==false && MM== false&&  DM==false && isSharedchannel==false && CreatedOnTo==""&& CreatedOnFrom==""&& LastActivityFrom=="" &&  isprivate==false && ispublic==false && isyes==false && isno==false)|| i==0)
      // {
      //   let a:any = document.getElementById("ApplyFilter");
      //   a.disabled=true;
      // }

      // if (boolVal == 0) {
      //   // let a: any = document.getElementById("ApplyFilter");
      //   // a.disabled = true;
      //   await loadGridPage();
      // }


    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const handleApplyFilter = async () => {
    try {
      IsFilterApplied = true;
      setIsFilterApplied(IsFilterApplied);
      if (channel) {
        ChatType = "Channel";
        setChatType(ChatType);
      }
      if (isdm) {
        ChatType = "Direct Chat"
        setChatType(ChatType);
      }
      if (ismm) {
        ChatType = "Multi Party Chat"
        setChatType(ChatType);
      }
      if(isdm && ismm){
        ChatType = "Multi Party Chat,Direct Chat"
        setChatType(ChatType);
      }
      if (isSharedchannel) {
        ChatType = "Channel,Shared Channel";
        setChatType(ChatType);
        setIsSharedchannel(true);
      }
      if (channel && isSharedchannel) {
        isSharedchannel = false;
        setIsSharedchannel(isSharedchannel);
        ChatType = 'Channel,Shared Channel';
        setChatType("Channel,Shared Channel");
      }
      else if (!isdm && !ismm && !channel && !isSharedchannel && ChatType != 'Channel,Shared Channel' && ChatType != 'Shared Channel' && ChatType != 'Channel' && (ChatType == 'Direct Chat' || ChatType == 'Multi Party Chat')) {
        ChatType = 'Multi Party Chat,Direct Chat';
        setChatType(ChatType);
      }
      else if (!isdm && !ismm && !channel && !isSharedchannel && ChatType != 'Multi Party Chat,Direct Chat' && ChatType != 'Direct Chat' && ChatType != 'Multi Party Chat') {
        ChatType = 'Channel,Shared Channel';
        setChatType(ChatType);
      }

      if ((IsChannel == true || IsInitialChannelLoad == false) && boolVal == 0) {
        sorthide = "";
        setsorthide(sorthide);
        ChatType = 'Channel,Shared Channel';
        setChatType(ChatType);
        columnOrder = 'asc';
        setColumnOrder(columnOrder);
        await loadGridPage();
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      }
      else if (IsChat == true && boolVal == 0) {
        sorthide = "";
        setsorthide(sorthide);
        ChatType = 'Multi Party Chat,Direct Chat';
        setChatType(ChatType);
        columnOrder = 'asc';
        setColumnOrder(columnOrder);
        await loadGridPage();
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      }
      else {
        await loadGridPage();
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
      }




    }
    catch (error) {
      let a: any = document.getElementById("ApplyFilter");
      a.disabled = true;
    }
  }
  const keypress = (e: any) => {
    try {
       

      if (e.keyCode == 13) {

        setSearchValue(e.target.value);
        exportsearchValue = searchValue;
        setexportsearchValue(exportsearchValue);
        setpage(1);
        loadGridPage();
      }

    } catch (error) {
      ////console.log(error);

    }
  }
  const handleExport = async () => {
    try {
      exportmethod();
    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const handleLoadMore = async () => {
    try {
       
      setpage(pageindex + 1);
      setCoutPerPage(countPerPage + 10);
    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const btnChannelList = () => {
    try {
       
      if (boolValChannel == 0) {
        exportsearchValue = "";
        setexportsearchValue(exportsearchValue);
        IsChannel = true;
        setIsChannel(IsChannel);
        IsChat = false;
        setIsChat(IsChat);
        sorthide = "";
        setsorthide(sorthide);
        let searchData: any = document.getElementById("searchData");
        searchData.value = "";
        columnName = "LastActivity";
        setColumnName(columnName);
        columnOrder = "asc";
        setColumnOrder(columnOrder);
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
        searchValue = "";
        setSearchValue(searchValue);
        SlackChatType = false;
        setSlackChatType(SlackChatType);
        ChatType = "Channel,Shared Channel";
        setChatType(ChatType);
        totalItemsCount = 10;
        setTotalItemsCount(totalItemsCount);
        pageindex = 1;
        setpage(pageindex);
        gridData = [];
        setGridData(gridData);
        LoadMore = "";
        setLoadmore(LoadMore);
        countPerPage = 10;
        setCoutPerPage(countPerPage);
        boolValChat = 0;
        setBoolVal2(boolValChat);
        checkpgload = true;
        setcheckpgload(checkpgload);

        if (tabSwitchInv1 == true) {
          tabSwitchInv = false;
          settabSwitchInv(tabSwitchInv);
          tabSwitchInv1 = false;
          settabSwitchInv1(tabSwitchInv1);
          let DM: any = document.getElementsByName("DM")
          DM[0].checked = false;
          let MM: any = document.getElementsByName("MM")
          MM[0].checked = false;
          isdm = false;
          ismm = false;

          setisdm(isdm)
          setismm(ismm)
        }
        else {

          let channel: any = document.getElementsByName("Channel")
          channel[0].checked = false;
          let SharedChannel: any = document.getElementsByName("SharedChannel")
          SharedChannel[0].checked = false;
          let Public: any = document.getElementsByName("Public")
          Public[0].checked = false;
          let Private: any = document.getElementsByName("Private")
          Private[0].checked = false;
          isprivate = false;
          setisprivate(isprivate);
          ispublic = false;
          setispublic(ispublic);
          channel = false;
          setchannel(channel);
          isSharedchannel = false;
          setIsSharedchannel(isSharedchannel);
        }
        IsFilterApplied = false;
        setIsFilterApplied(IsFilterApplied);
        IsArchived = ""
        setIsArchived(IsArchived);
        isno = false;
        setisno(false);
        isyes = false;
        setisyes(false);
        CreatedOnFrom = ""
        setCreatedOnFrom(CreatedOnFrom);
        CreatedOnTo = ""
        setCreatedOnTo(CreatedOnTo);
        LastActivityFrom = ""
        setLastActivityFrom(LastActivityFrom);
        LastActivityTo = ""
        setLastActivityTo(LastActivityTo);

        let Yes: any = document.getElementsByName("Yes")
        Yes[0].checked = false;
        let No: any = document.getElementsByName("No")
        No[0].checked = false;
        let CreatedOnTO: any = document.getElementsByName("CreatedOnTo")
        CreatedOnTO[0].value = '';
        let CreatedOnFroM: any = document.getElementsByName("CreatedOnFrom")
        CreatedOnFroM[0].value = '';
        let LastActivityTO: any = document.getElementsByName("LastActivityTo")
        LastActivityTO[0].value = '';
        let LastActivityFroM: any = document.getElementsByName("LastActivityFrom")
        LastActivityFroM[0].value = '';
        boolVal = 0;
        setBoolVal(boolVal);

        loadGridPage();
      }
      else {
        loadGridPage();
      }
    }
    catch (err: any) {
      ////console.log(err);
    }
  }
  const btnChatList = () => {
     
    try {
      if (boolValChat == 0) {
        checkpgload = true;
        setcheckpgload(checkpgload);
        loaderContent = "We're working on getting your complete Slack chats list based on the volume of data! Relax! It may take some time!";
        setloaderContent(loaderContent);
        exportsearchValue = "";
        setexportsearchValue(exportsearchValue);
        IsChat = true;
        setIsChat(IsChat);
        IsChannel = false;
        setIsChannel(IsChannel);
        IsInitialChannelLoad = true;
        setIsInitialChannelLoad(IsInitialChannelLoad);
        sorthide = "";
        setsorthide(sorthide);
        let searchData: any = document.getElementById("searchData");
        searchData.value = "";
        columnName = "LastActivity";
        setColumnName(columnName);
        columnOrder = "asc";
        setColumnOrder(columnOrder);
        document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true");
        searchValue = "";
        setSearchValue(searchValue);
        gridData = [];
        setGridData(gridData);
        ChatType = "Multi Party Chat,Direct Chat";
        // filterCloseClicked(Checktype);
        totalItemsCount = 10;
        setTotalItemsCount(totalItemsCount);
        pageindex = 1;
        setpage(pageindex);
        setChatType(ChatType);
        SlackChatType = true;
        setSlackChatType(SlackChatType);
        LoadMore = "";
        setLoadmore(LoadMore);
        countPerPage = 10;
        setCoutPerPage(countPerPage);
        boolValChannel = 0;
        setBoolVal1(boolValChannel);
        checkpgload = true;
        setcheckpgload(checkpgload);


        if (tabSwitchInv == false) {
          tabSwitchInv = true;
          settabSwitchInv(tabSwitchInv);
          tabSwitchInv1 = true;
          settabSwitchInv1(tabSwitchInv1);
          let channel: any = document.getElementsByName("Channel")
          channel[0].checked = false;
          let SharedChannel: any = document.getElementsByName("SharedChannel")
          SharedChannel[0].checked = false;
          let Public: any = document.getElementsByName("Public")
          Public[0].checked = false;
          let Private: any = document.getElementsByName("Private")
          Private[0].checked = false;
          isprivate = false;
          setisprivate(isprivate);
          ispublic = false;
          setispublic(ispublic);
          channel = false;
          setchannel(channel);
          isSharedchannel = false;
          setIsSharedchannel(isSharedchannel);
        }
        else {
          // tabSwitchInv1 = false;
          // settabSwitchInv1(tabSwitchInv1);
          let DM: any = document.getElementsByName("DM")
          DM[0].checked = false;
          let MM: any = document.getElementsByName("MM")
          MM[0].checked = false;
          isdm = false;
          ismm = false;
          setisdm(isdm)
          setismm(ismm)
        }
        IsFilterApplied = false;
        setIsFilterApplied(IsFilterApplied);
        IsArchived = ""
        setIsArchived(IsArchived);
        isno = false;
        setisno(false);
        isyes = false;
        setisyes(false);
        CreatedOnFrom = ""
        setCreatedOnFrom(CreatedOnFrom);
        CreatedOnTo = ""
        setCreatedOnTo(CreatedOnTo);
        LastActivityFrom = ""
        setLastActivityFrom(LastActivityFrom);
        LastActivityTo = ""
        setLastActivityTo(LastActivityTo);

        let Yes: any = document.getElementsByName("Yes")
        Yes[0].checked = false;
        let No: any = document.getElementsByName("No")
        No[0].checked = false;
        let CreatedOnTO: any = document.getElementsByName("CreatedOnTo")
        CreatedOnTO[0].value = '';
        let CreatedOnFroM: any = document.getElementsByName("CreatedOnFrom")
        CreatedOnFroM[0].value = '';
        let LastActivityTO: any = document.getElementsByName("LastActivityTo")
        LastActivityTO[0].value = '';
        let LastActivityFroM: any = document.getElementsByName("LastActivityFrom")
        LastActivityFroM[0].value = '';
        boolVal = 0;
        setBoolVal(boolVal);

        loadGridPage();
      }
      else {
        loadGridPage();
      }

    }
    catch (err: any) {
      ////console.log(err);
    }
  }
  const ApplyFilter = () => {
    try {
       
      let a: any = document.getElementById("ApplyFilter");
      a.disabled = true;

    }
    catch (error: any) {
      ////console.log(error);

    }
  }
  const OpenAdvFilter = () => {
    try {
       
      if (ConnectSettings == true) {
        ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else {
        IsFilterApplied = false;
        setIsFilterApplied(IsFilterApplied);
        document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden")
        // if (boolVal == 0) {
        //   document.getElementById("ApplyFilter")?.setAttribute("disabled", "true");
        // }
        // else {
        //   document.getElementById("ApplyFilter")?.removeAttribute("hidden");
        // }
      }
    } catch (error) {
      ////console.log(error);
    }
  }
  const popUpOkClick = (type: string) => {

    try {

      if (isSessionEpired === false) {

        //#region Hide PopUp

        let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")

        popup?.setAttribute("class", "modal fade");

        popup?.setAttribute("style", "display:none");

        popup?.setAttribute("aria-hidden", "true");

        popup?.removeAttribute("aria-modal");

        popup?.removeAttribute("role");

        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

        // $('input[name="checkbox"]').prop('checked', false); // Unchecks it

        let migrateButton = document.getElementById("migrateButton")

        migrateButton?.setAttribute('disabled', 'disabled');




        //#endregion
      }
      else {
        window.location.pathname = "/Login";


      }

    } catch (error) {

      ////console.log("Issue Faced");

    }

  }
  return (
    <div className="row justify-content-center card-container">
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

      <div className="overlay" id='loader' hidden={true}>
        <div className="loader-position Loader-Content-position">
          <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>

          </div>
          {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
        </div>
      </div>

      {/* <div className="overlay" id='loader' hidden={true}>
        <div className="loader-position Loader-Content-position">
          <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>

          </div>
          <p>Do not close or refresh the application while the process is in progress...</p>
        </div>
      </div> */}

      <div className="delayoverlay" id='delayloader' hidden={true}>
        <div className="loader-position Loader-Content-position gif-top-align loadercustom ">
          {/* <div className="Loader Loader-Img-position text-primary align-center" role="status"> */}
          <img className='delayloader-width' src='/Images/delayloader.gif' />
          {/* // <span className="visually-hidden"></span> */}
          {/* </div> */}
          <p className='mt-4 font-semibold'>{loaderContent == "" ? "We're working on getting your complete Slack Channels list based on the volume of data! Relax! It may take some time!" : loaderContent}</p>
        </div>
      </div>

      <div className="col-md-11 mt-5">

        {/* <!-- Nav tabs starts here --> */}

        <div className="row">
          <div className="col-md-12 mt-3 mb-4 mb-44">
            <ul className="nav nav-pills highlevelchatchanneldiv" role="tablist">
              <li className="nav-item Channellist">
                <a className={SlackChatType == false ? "nav-link active tab-font font-14 font-bold anchorAsPointer" : "nav-link  tab-font font-14 font-bold anchorAsPointer"} data-bs-toggle="tab" onClick={() => btnChannelList()} >Channel List</a>
              </li>
              <li className="nav-item Chatlist">
                <a className={SlackChatType == false ? "nav-link  tab-font font-14 font-bold ChatListPadding anchorAsPointer" : "nav-link active tab-font font-14 font-bold ChatListPadding anchorAsPointer"} data-bs-toggle="tab" onClick={() => btnChatList()}>Chat List</a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- Nav tabs ends here -->

        <!--Slack channel section starts here--> */}
        <div className="col-md-12 col-12 col-sm-12 col-lg-12 float-start">
          {SlackChatType == false ? <div>
            <h5 className="channel-header font-18 font-bold " >Get Slack Channel List</h5>
            <div className="w-100 float-start">
              <label className=" inventory-details-font font-14 font-regular me-2">Fetch the Slack Channels list here</label>
              <button type="button" className="btn btn-slack-list btn-primary-theme font-16 font-semibold common-btn-class hli-btn-res-bottom" id="btnInv" onClick={() => gethighlevel()}>Get Slack Channel List</button>

            </div>            </div>

            : <div>
              <h5 className="channel-header font-18 font-bold">Get Slack Chat List</h5>
              <div className="w-100 float-start">
                <label className=" inventory-details-font font-14 font-regular me-2">Fetch the Slack multi-party and direct chats list here</label>
                <button type="button" className="btn btn-slack-list btn-primary-theme font-16 font-semibold common-btn-class hli-btn-res-bottom" id="btnInv" onClick={() => gethighlevel()}>Get Slack Chats List</button>

              </div>
            </div>}

          {/* <!--Slack channel section ends here--> */}

          <div className="tab-content">
            <div id="high-level-inventory" className="tab-pane float-start active w-100">


              {/* <!-- channel list table starts here --> */}
              <div className="float-start w-100 mx-0">
                <div className="float-start p-0 w-100">
                  <div className="float-start mt-4 w-100 ">
                    {SlackChatType == false ?
                      <div className="float-lg-start float-sm-none float-md-start my-2 d-flex align-items-center">
                        <h3 className=" channel-font font-18 font-bold mb-0">Channels List</h3>{showHighLevelInventoryStatus? <span className= {successFailedClass} >{highLevelInventoryStatus}</span>:null}
                      </div >
                      :
                      <div className="float-lg-start float-sm-none float-md-start my-2 d-flex align-items-center">
                        <h3 className=" channel-font font-18 font-bold mb-0">Chats List </h3>{showHighLevelInventoryStatus?  <span className={successFailedClass}>{highLevelInventoryStatus}</span>:null}
                      </div >}

                    <div className="float-lg-end float-sm-start float-md-end">
                    <p className='mb-0 font-semibold font-16 text-nowrap float-start me-3 mt-3'># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {totalItemsCount}</p>
                      <div className="input-group my-2 box-shodow-filter search-container-hli float-start">
                        <input type="text" className="form-control form-control-style-2" id='searchData' placeholder="Search" onChange={handleSearch} onKeyDown={keypress} aria-describedby="button-addon1" />
                        <button className="btn btn-outline-secondary" onClick={() => loadGridPage()} type="button" id="button-addon1"><img src='/Images/search.svg'
                          alt="search-icon" />
                        </button>
                      </div>
                      {/* </div> */}

                      {/* <div className="col-md-8 float-end">  */}

                      {/* <!-- advanced-filter --> */}
                      <div className="dropdown filter my-2 float-start hli-filter-margin dropdown-position"  >
                        <button type="button" onClick={() => OpenAdvFilter()} className="btn float-end btn-scondary-custom box-shodow-filter p-0" title="Advanced Filter">
                          <img src='/Images/filter.svg' className="filterIconHeight" alt="filter" />
                        </button>
                        {/* <div className="dropdown-menu advance-filter-inventory"> */}
                        <div className="filter-dropdown-inv-hli drop-filter-sty dropdown-menu show Advance-Filter-Popup filter-res-width" id="AdvancedFilterDiv" hidden>
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-12 pb-2 border-0 mb-1">
                                <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                                <a className="btn float-end" id='close-filter' data-bs-dismiss="advanced-filter" aria-label="Close" onClick={() => { filterCloseClicked() }}>
                                  <span ><img src='/Images/filter-close-icon.svg'
                                    alt="filter-close-icon" /></span>
                                </a>
                              </div>
                              <div className="col-md-12">
                                <div className="row my-2">
                                  <div className={SlackChatType == false ? "col-md-7" : "ChatVisibilitydiv"}>
                                    {SlackChatType == false ?
                                      <label className="channeltype-font font-16 font-semibold" >Channel
                                        Type</label>
                                      :
                                      <label className="channeltype-font font-16 font-semibold" >Chat
                                        Type</label>}
                                    <div className="row mt-2">
                                      <div className="col-md-12">
                                        {SlackChatType == false ?
                                          <div>
                                            <label
                                              className="manage-client-date-font channel-check me-2 font-14 font-regular me-4"><span><input
                                                className="form-check-input highlevelCheckbox" name="Channel" type="checkbox" value="" id="Check1" onChange={handleFilterInput} /></span>
                                              Channel</label>
                                            <label className="manage-client-date-font font-14 font-regular"><span><input
                                              className="form-check-input highlevelCheckbox" name='SharedChannel' type="checkbox" value="" id="Check2" onChange={handleFilterInput} /></span>
                                              Shared Channel</label></div>
                                          :
                                          <div >
                                            <label className="manage-client-date-font channel-check me-2 font-14 font-regular me-4"><span><input
                                              className="form-check-input highlevelCheckbox" name='DM' type="checkbox" value="" id="Check2" onChange={handleFilterInput} /></span>
                                              Direct Message</label>
                                            <label
                                              className="manage-client-date-font font-14 font-regular"><span><input
                                                className="form-check-input highlevelCheckbox" name='MM' type="checkbox" value="" id="Check4" onChange={handleFilterInput} /></span>
                                              Multi-Party Message</label>

                                          </div>}
                                      </div>
                                    </div>
                                  </div>
                                  {SlackChatType == false ?
                                    <div className="col-md-5 ChannelVisStyle-hli" >
                                      <label className="manage-client-date-font font-16 font-semibold">Channel Visibility</label>
                                      <div className="row mt-2">
                                        <div className="col-md-12">
                                          <label
                                            className="manage-client-date-font me-2 channel-check font-14 font-regular me-4"><span><input
                                              className="form-check-input highlevelCheckbox" name='Public' type="checkbox" value="" id="Check4" onChange={handleFilterInput} /></span>
                                            Public</label>
                                          <label className="manage-client-date-font font-14 font-regular"><span><input
                                            className="form-check-input highlevelCheckbox" name='Private' type="checkbox" value="" id="Check5" onChange={handleFilterInput} /></span>
                                            Private</label>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    ""}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="row my-2">
                                  <div className='Createdon-hli-filter'>
                                    <label className="manage-client-date-font font-16 font-semibold">Created On</label>
                                    <div className="row my-2">
                                      <div className="col-md-12">
                                        <div className='row'>
                                          <div className="col-md-6 float-start">
                                            <label
                                              className="date-created w-100 float-start channel-check font-14 font-regular">From</label>
                                            <input type="date" onKeyDown={(e) => {
                                              e.preventDefault();
                                            }} placeholder="select date" name='CreatedOnFrom' max={CreatedOnTo != "" ? CreatedOnTo : maxMigrationToDate} onChange={handleFilterInput}
                                              className="inventory-date-to-control form-control date-style" id="from-date2" />
                                          </div>

                                          <div className="col-md-6 float-start TodateAdvFilter-new">
                                            <label
                                              className="date-created w-100 float-start channel-check font-14 font-regular">To</label>
                                            <input type="date" onKeyDown={(e) => {
                                              e.preventDefault();
                                            }} name='CreatedOnTo' min={CreatedOnFrom} max={maxMigrationToDate} onChange={handleFilterInput}
                                              className="inventory-date-to-control form-control date-style" id="from-date2" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='Isarchived-hli-filter'>
                                    <label className="manage-client-date-font font-16 font-semibold mt-4">Is Archived
                                      ?</label>
                                    <div className="row my-2">
                                      <div className="col-md-12">
                                        <label
                                          className="manage-client-date-font channel-check me-2 font-14 font-regular me-4"><span><input
                                            className="form-check-input highlevelCheckbox " name='Yes' type="checkbox" value="" id="Check6" onChange={handleFilterInput} /></span>
                                          Yes</label>
                                        <label
                                          className="manage-client-date-fontfont-14 channel-check font-regular"><span><input
                                            className="form-check-input highlevelCheckbox" name='No' type="checkbox" value="" id="Check7" onChange={handleFilterInput} /></span>
                                          No</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="row my-2">
                                  <div className='LastActivity-hli-filter'>
                                    <label className="manage-activity-date-font font-16 font-semibold">Last Activity</label>
                                    <div className="row my-2">
                                      <div className="col-md-12">
                                        <div className='row'>
                                          <div className="col-md-6 float-start">
                                            <label className="activity-date-created w-100 float-start">From</label>
                                            <input type="date" onKeyDown={(e) => {
                                              e.preventDefault();
                                            }} name='LastActivityFrom' className="date-form-control form-control date-style" max={LastActivityTo != "" ? LastActivityTo : maxMigrationToDate} id="from-date" onChange={handleFilterInput} />
                                          </div>

                                          <div className="col-md-6 float-start TodateAdvFilter-new" >
                                            <label className="activity-date-created w-100 float-start">To</label>
                                            <input type="date" onKeyDown={(e) => {
                                              e.preventDefault();
                                            }} name='LastActivityTo' min={LastActivityFrom} max={maxMigrationToDate} className="activity--date-to-control form-control date-style" id="from-date" onChange={handleFilterInput} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 col-12 mt-5 mb-3">
                                <div className="float-end">
                                  <button className="btn btn-clear font-16 font-semibold px-4 me-3  btn-cancel-border btn-scondary-custom common-btn-class" type="reset" onClick={() => filterClearClicked()}>Clear</button>
                                  <button className="btn btn-apply btn-primary-theme font-16 font-semibold text-light common-btn-class" id='ApplyFilter' onClick={(e) => handleApplyFilter()}>Apply Filter</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- advanced-filter ends--> */}



                      <button className="btn btn-inventory-export my-2 hli-export-margin btn-scondary-custom text- px-3 btn-scondary-custom font-semibold font-16 newclass float-start" onClick={() => handleExport()} ><img src='/Images/export-excel.svg' alt="export-excel" /> Export</button>



                    </div>
                    {/* </div> */}



                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 float-start mt-3 table-responsive p-1">
                {SlackChatType == false ?
                  <table className="table table-border">
                    <thead className="tabel-head-bg">
                      <tr>
                        <th className="table-head font-14 font-bold"> Channel Name<span>
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatName@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatName@desc" ? false : true} /></span></th>

                        <th className="table-head font-14 font-bold" id='row'>Channel Type
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ChatType@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatType@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ChatType@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatType@desc" ? false : true} />
                        </th>

                        <th className="table-head font-14 font-bold">Channel Visibility
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ChatVisibilty@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatVisibilty@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ChatVisibilty@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatVisibilty@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Created On
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="CreatedDateTime@desc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedDateTime@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="CreatedDateTime@asc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedDateTime@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Last Activity
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="LastActivity@desc" onClick={e => sortClicked(e)} hidden={sorthide == "LastActivity@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="LastActivity@asc" onClick={e => sortClicked(e)} hidden={sorthide == "LastActivity@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Created By
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="CreatedBy@desc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedBy@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="CreatedBy@asc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedBy@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Archived status
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="IsArchived@desc" onClick={e => sortClicked(e)} hidden={sorthide == "IsArchived@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="IsArchived@asc" onClick={e => sortClicked(e)} hidden={sorthide == "IsArchived@desc" ? false : true} />
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      {gridBodyData()}
                    </tbody>
                  </table>
                  :
                  <table className="table table-border">
                    <thead className="tabel-head-bg">
                      <tr>
                        <th className="table-head font-14 font-bold"> Chat Name<span>
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatName@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatName@desc" ? false : true} /></span></th>



                        <th className="table-head font-14 font-bold">Chat Type
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ChatType@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatType@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ChatType@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChatType@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Created On
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="CreatedDateTime@desc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedDateTime@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="CreatedDateTime@asc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedDateTime@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Last Activity
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="LastActivity@desc" onClick={e => sortClicked(e)} hidden={sorthide == "LastActivity@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="LastActivity@asc" onClick={e => sortClicked(e)} hidden={sorthide == "LastActivity@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Created By
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="CreatedBy@desc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedBy@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="CreatedBy@asc" onClick={e => sortClicked(e)} hidden={sorthide == "CreatedBy@desc" ? false : true} />
                        </th>
                        <th className="table-head font-14 font-bold">Archived status
                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="IsArchived@desc" onClick={e => sortClicked(e)} hidden={sorthide == "IsArchived@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="IsArchived@asc" onClick={e => sortClicked(e)} hidden={sorthide == "IsArchived@desc" ? false : true} />
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                      {gridBodyData()}
                    </tbody>
                  </table>}
                {/* <!-- channel list table ends here -->

<!-- loadmore button section starts here --> */}

                {/* <!-- loadmore button section ends here --> */}
              </div>
              <div className="col-md-12 mt-3 mb-5 text-center">
                {totalItemsCount > countPerPage && LoadMore != "no records were fetched" ?
                  <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>
                  : ""}
              </div>
              {/* <!-- footer section starts here --> */}
            </div>

            {/* <!-- inventory-summary tab starts here --> */}
            {/* <div id="inventory-summary" className="tab-pane fade">
                <div className="row float-end">
                  <div className="col-md-12 mb-3">
                    2
                  </div>
                </div>

              </div> */}
            {/* <!-- inventory-summary tab ends here --> */}
          </div>
        </div>

      </div>

      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                <button type="button" className="btn btn-ok btn-primary-theme text-center  common-btn-class common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
}