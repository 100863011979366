import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formLoad } from '../../Services/AdminServices';
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import constants  from '../../Common/Constants'
import '../../styles/admin.css'


export default function AdminLogin(): JSX.Element {

    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let c_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    const history = useHistory()
    let dataObj: any = [];
    const [state, setState] = useState(dataObj);
    const [failedPopUp, setFailedPopUp] = useState("");
    const [isMSTeamsPasswordEyeOpen, setIsMSTeamsPasswordEyeOpen] = useState(false);
    let [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const reactNode_en_K: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    const reactAdmin_en_K: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let avamigratronLink = constants.URL.REACT_APP_AVAMIGRATRON_LINK

    let OTPVerificationTime = 45;
    let OTPObj = { OTPValue_1: "", OTPValue_2: "", OTPValue_3: "", OTPValue_4: "", OTPValue_5: "", OTPValue_6: "" }
    let [OTPState, setOTPState] = useState(OTPObj);
    let [otpVerificationLimit, setotpVerificationLimit] = useState(OTPVerificationTime);
    let [bool, setbool] = useState(false);
    let [OTPBtnName, setOTPBtnName] = useState("Verify");
    let [otpFrontEndData, setotpFrontEndData] = useState("");
    let [otpData, setotpData] = useState("");
    const [createdSession, setCreatedSession] = useState("");

    useEffect(() => {
        let button = document.getElementById("targetButton");
        button?.setAttribute("disabled", "disabled");
        if (bool == true) {
            if (otpVerificationLimit > 0) {
                const timer = setTimeout(() => {
                    setotpVerificationLimit((prevSeconds) => prevSeconds - 1);
                }, 1000);

                return () => {
                    clearTimeout(timer);
                }
            }
            if (otpVerificationLimit === 0) {
                document.getElementById("Verifybtn")?.removeAttribute("disabled");
                setOTPBtnName("Resend");
                setotpFrontEndData("");
                let invalidOTP = document.getElementById("invalidOTP");
                invalidOTP?.setAttribute('hidden', 'hidden');

            }
            if (otpVerificationLimit !== 0) {
                let button = document.getElementById("targetButton");
                button?.setAttribute("disabled", "disabled");
            }
        }
    }, [otpVerificationLimit, bool])

    const onTextBoxValueChange = (e: any) => {
        try {
            let ostate = state;
            ostate[e.target.name] = e.target.value;
            setState({ ...state, [e.target.name]: e.target.value });
            onBlurValidation(e);
        } catch (error) {
        }
    }

    const OTPPopUp = (type: any) => {
        try {
            let Verifybtn = document.getElementById("Verifybtn");
            Verifybtn?.setAttribute("disabled", "disabled");
            let OTPPopUp = document.getElementById("OtpPopUp");
            if (type) {
                OTPPopUp?.removeAttribute("aria-hidden");
                OTPPopUp?.setAttribute("aria-modal", "true");
                OTPPopUp?.setAttribute("role", "dialog");
                OTPPopUp?.setAttribute("class", "modal fade show");
                OTPPopUp?.setAttribute("style", "display:block");
                document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
            }
            else {
                OTPPopUp?.setAttribute("class", "modal fade");
                OTPPopUp?.setAttribute("style", "display:none");
                OTPPopUp?.setAttribute("aria-hidden", "true");
                OTPPopUp?.removeAttribute("aria-modal");
                OTPPopUp?.removeAttribute("role");
                document.getElementById("targetFadeDiv")?.setAttribute("hidden", "true");
            }
        }
        catch (error) {

        }
    }

    const OTPValidation = async (e: any) => {
        try {

            if (e.target.value.length <= 1) {
                if (OTPState[e.target.id].length < 1 || OTPState[e.target.id].length == 1) {
                    OTPState = { ...OTPState, [e.target.id]: e.target.value }
                    setOTPState(OTPState)
                }
                const [fieldIndex] = e.target.id.split("_")[1];

                let fieldIntIndex = parseInt(fieldIndex, 10);
                let nxtFieldIntIndex = (e.target.value != "") ? fieldIntIndex + 1 : fieldIntIndex - 1;
                let elementID = 'OTPValue_' + nxtFieldIntIndex;
                if (nxtFieldIntIndex < 8) {
                    const timer = setTimeout(() => {
                        const currentfield = document.getElementById(e.target.id);
                        if (e.target.value != "") {
                            currentfield?.setAttribute("type", "password");
                        }
                        else {
                            currentfield?.setAttribute("type", "text");
                        }
                    }, 1000);
                    const nextfield = document.getElementById(elementID);
                    if (nextfield !== null) {
                        nextfield?.focus();
                    }
                }
            }
            else if (e.target.value.length >= 1 || e.target.value.length <= 6) {
                const OTPChars = e.target.value.split('');
                let fieldIndex = e.target.id.split("_")[1];
                let charLength = OTPChars.length > 6 ? 6 : OTPChars.length;
                for (let i = 0; i < charLength; i++) {
                    OTPState = { ...OTPState, [`OTPValue_${fieldIndex}`]: OTPChars[i] }
                    setOTPState(OTPState);
                    const currentfield = document.getElementById(`OTPValue_${fieldIndex}`);
                    const timer = setTimeout(() => {
                        if (e.target.value != "") {
                            currentfield?.setAttribute("type", "password");
                        }
                        else {
                            currentfield?.setAttribute("type", "text");
                        }
                    }, 1000);
                    fieldIndex = Number(fieldIndex) + 1;
                }
            }
            let Verifybtn = document.getElementById("Verifybtn");
            let invalidOTP = document.getElementById("invalidOTP");
            invalidOTP?.setAttribute('hidden', 'hidden');
            if (OTPState.OTPValue_1 != '' && OTPState.OTPValue_2 != '' && OTPState.OTPValue_3 != '' && OTPState.OTPValue_4 != '' && OTPState.OTPValue_5 != '' && OTPState.OTPValue_6 != '') {
                if (OTPBtnName == "Verify") {
                Verifybtn?.removeAttribute('disabled');
                }
                otpFrontEndData = `${OTPState.OTPValue_1}${OTPState.OTPValue_2}${OTPState.OTPValue_3}${OTPState.OTPValue_4}${OTPState.OTPValue_5}${OTPState.OTPValue_6}`
                setotpFrontEndData(otpFrontEndData)

            }
            else {
                if (OTPBtnName == "Verify") {
                Verifybtn?.setAttribute("disabled", "disabled");
                }
            }
        }
        catch (error) {
            // console.log(error);

        }
    }

  const handleKeyDown = (e:any) => {
    try{
      if(e.key === "Enter"){
        if(otpFrontEndData != "" && otpFrontEndData != undefined && otpFrontEndData != null){
          verifyButtonClick(OTPBtnName)  
         }
      }
    }
    catch(error){
    }
    
  }

    const verifyButtonClick = async (data: any) => {
        try {
            //#region Hide PopUp
            let submitButton = document.getElementById("Verifybtn");
            let invalidOTP = document.getElementById("invalidOTP");
            if (data !== "Resend") {
                if (otpData === otpFrontEndData) {
                    setIsPopUpOpen(false)
                    document.getElementById("OTPAlertPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("OTPAlertPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("OTPAlertPopUp")?.removeAttribute("role");
                    document.getElementById("OTPAlertPopUp")?.setAttribute("hidden", "true");
                    localStorage.setItem("aid_t", createdSession);

                        let byteValue = objCryptoJS.AES.decrypt(createdSession, c_t_Key);
                        let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
                        let objClient:any = jwt(decryptedValue);
                        let clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
                    
                    let config = {
                        method: "POST",
                        url: `${baseURL}/AdminAuthentication/Login`,
                        data: {
                            adminID:objClient.AID,
                            updateAccountStatus:true
                        }
                    }
                    setIsPopUpOpen(true)
                    let data: any = await formLoad(config);
                    window.location.pathname = "/Admin/ManageClients";
                }
                else {
                    invalidOTP?.removeAttribute("hidden");
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                setOTPBtnName("Verify");
                document.getElementById("Verifybtn")?.setAttribute("disabled", "true");
                invalidOTP?.setAttribute('hidden', 'hidden');
                setotpVerificationLimit(OTPVerificationTime);
                submitClick(data);
            }
            //#endregion
        }
        catch (error) {

        }
    }

    const onBlurValidation = (e:any) => {
        try {
          let invalidTargetEmailElement = document.getElementById("invalidTargetEmail");
          let invalidTargetPasswordElement = document.getElementById("invalidTargetPassword");
          let emptyTargetEmailElement = document.getElementById("emptyTargetEmail");
          let emptyTargetPasswordElement = document.getElementById("emptyTargetPassword");
      
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-]).{8,20}$/;
      
          if (e.target.name == "targetEmail") {
            if (state.targetEmail == "") {
              invalidTargetEmailElement?.setAttribute("hidden", "true");
              emptyTargetEmailElement?.removeAttribute("hidden");
            } else if (!(emailRegex.test(state.targetEmail) && state.targetEmail.toString().length <= 320)) {
              emptyTargetEmailElement?.setAttribute("hidden", "true");
              invalidTargetEmailElement?.removeAttribute("hidden");
            } else {
              invalidTargetEmailElement?.setAttribute("hidden", "true");
              emptyTargetEmailElement?.setAttribute("hidden", "true");
            }
          }
          else if (e.target.name == "targetPassword") {
            if (state.targetPassword == "") {
              emptyTargetPasswordElement?.removeAttribute("hidden");
              invalidTargetPasswordElement?.setAttribute("hidden", "true");
            }
            else if (!passwordRegex.test(state.targetPassword)) {
              invalidTargetPasswordElement?.removeAttribute("hidden");
              emptyTargetPasswordElement?.setAttribute("hidden", "true");
            }
            else {
              invalidTargetPasswordElement?.setAttribute("hidden", "true");
              emptyTargetPasswordElement?.setAttribute("hidden", "true");
            }
          }
      
          if (state.targetEmail && emailRegex.test(state.targetEmail) && state.targetPassword && passwordRegex.test(state.targetPassword)) {
            let submitButton = document.getElementById("targetButton");
            submitButton?.removeAttribute('disabled');
          } else {
            let submitButton = document.getElementById("targetButton");
            submitButton?.setAttribute("disabled", "disabled");
          }
        } catch (error) {

        }
      };

    const submitClick = async (e: any) => {
        try {
            localStorage.clear();

            let loaderElement = document.getElementById("targetLoader")
            loaderElement?.removeAttribute("hidden");

            // let faderElement = document.getElementById("targetFadeDiv")
            // faderElement?.removeAttribute("hidden");

            // let button = document.getElementById("targetButton");
            // button?.setAttribute("disabled", "disabled");

            let enPass = objCryptoJS.AES.encrypt(state.targetPassword, reactNode_en_K).toString();

            let config = {
                method: "POST",
                url: `${baseURL}/AdminAuthentication/Login`,
                data: {
                    "adminMailID": state.targetEmail,
                    "adminPassword": enPass
                }
            }
            setIsPopUpOpen(true)
            let data: any = await formLoad(config);
            if (OTPBtnName === "Verify") {
                let y = document.getElementById("targetButton")
                y?.removeAttribute("disabled")
            }

            if (data.data.statusText.toString().toLowerCase() == "ok") {
                let encPswd = objCryptoJS.AES.decrypt(data.data.data.SecretKey, reactNode_en_K);
                var actPswd = encPswd.toString(objCryptoJS.enc.Utf8);

                setotpData(actPswd);
                OTPPopUp(true);
                setbool(true);

                setIsPopUpOpen(false)
                let byteValue = objCryptoJS.AES.decrypt(data.data.data.adminToken, reactNode_en_K);
                var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

                let enPass = objCryptoJS.AES.encrypt(dePass, reactAdmin_en_K).toString();
                setCreatedSession(enPass);
            }
            else {

                let loaderElement = document.getElementById("targetLoader")
                loaderElement?.setAttribute("hidden", "true")


                setFailedPopUp('Please provide valid login credentials!');

                //#region Show PopUp
                setIsPopUpOpen(true)
                document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
                document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
                document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
                document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
                document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
                document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
                //#endregion

            }
        } catch (error: any) {
            let loaderElement = document.getElementById("targetLoader")
            loaderElement?.setAttribute("hidden", "true")


            setFailedPopUp("Something went wrong! Please try again or reach AVAMIGRATRON Support.");

            //#region Show PopUp
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("targetFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("targetFadeDiv")?.removeAttribute("hidden");
            //#endregion
        }
    }

    const failedPopUpOkClick = () => {
        try {
            //#region Hide PopUp
            setIsPopUpOpen(false)
            document.getElementById("targetFailedPopUp")?.setAttribute("class", "modal fade");
            document.getElementById("targetFailedPopUp")?.setAttribute("style", "display:none");
            document.getElementById("targetFailedPopUp")?.setAttribute("aria-hidden", "true");
            document.getElementById("targetFailedPopUp")?.removeAttribute("aria-modal");
            document.getElementById("targetFailedPopUp")?.removeAttribute("role");
            document.getElementById("targetFadeDiv")?.setAttribute("hidden", "true");
            //#endregion
        } catch (error) {
        }
    }

    const onPasswordKeyDown = async (e: any) => {
        try {
            ;
            if (e.keyCode === 13) {
                let mailRegex = ((/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.targetEmail)) && (state.targetEmail.toString().length <= 320))
                let pswdRegex = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,@'";:$!%*?&}(\/){[+~.<\\>_)=(\])|#()`^-])[A-Za-z\d,'";:@$!%*?&+}(\/){[~.<\\>_|=(\])#()^`-]{8,20}$/.test(state.targetPassword))
                if (mailRegex == true && pswdRegex == true && isPopUpOpen == false) {

                    await submitClick(e);
                }
            }
        } catch (error) {
        }
    }

    return (
        <div className="float-start reg-border-left width100">
            <div className="col-md-12 float-start bg-white screen-height px-0">
                <div className="col-md-7 float-start pt-3">
                    <a href={avamigratronLink} className='anchorAsPointer'>
                        <img src="/Images/brand_logo.svg" className="brandLogo mt-4 ms-5" alt="logo"></img>
                    </a>
                    {/* <!-- Registration form starts here --> */}
                    <div className="row justify-content-center mt-4 fieldMainDiv">
                        <div className="col-md-6 float-start">
                            {/* <!-- main heading starts here --> */}
                            <h4 className="font-24 font-bold">Welcome!</h4>
                            {/* <!-- main heading ends here --> */}

                            {/* <!-- top para starts here --> */}
                            <p className="font-14 font-regular grayColor mb-4">Please Log in to continue
                            </p>
                            {/* <!-- top para ends here --> */}
                            <div className="emailDiv">
                                <label className="font-14 font-semibold pb-2" htmlFor="targetEmail">Email Address</label>
                                <input type="email" className="form-control custom-form " id="targetEmail"
                                    placeholder="Enter the Email Address" name="targetEmail" value={state.targetEmail} onChange={onTextBoxValueChange} />
                                <label className="float-start font-semibold font-14 required" id="invalidTargetEmail" hidden>Invalid Email Address provided</label>
                                <label className="float-start font-semibold font-14 required" id="emptyTargetEmail" hidden>Email Address cannot be empty</label>
                            </div>
                            <div className="passwordDiv">
                                <label className="font-14 font-semibold  pt-3 pb-2" htmlFor="targetPassword">Password</label>
                                <div className="eyeiconDiv">
                                    <input type={isMSTeamsPasswordEyeOpen ? "text" : "password"} className="form-control custom-form eyepadding" id="targetPassword"
                                        placeholder="Enter the Password" value={state.targetPassword} name="targetPassword" onChange={onTextBoxValueChange} onKeyDown={onPasswordKeyDown} />
                                    <img src={isMSTeamsPasswordEyeOpen == false && state.targetPassword != undefined && state.targetPassword != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"} className={isMSTeamsPasswordEyeOpen == false && state.targetPassword != undefined && state.targetPassword != "" ? "eyecloseiconImg anchorAsPointer" : "eyeiconImg anchorAsPointer"} alt="eye-open-icon" onClick={() => (state.targetPassword != undefined && state.targetPassword != "") ? setIsMSTeamsPasswordEyeOpen(isMSTeamsPasswordEyeOpen ? false : true) : null} />
                                </div>
                                <label className="float-start font-semibold font-14 required" id="invalidTargetPassword" hidden>Invalid Password provided</label>
                                <label className="float-start font-semibold font-14 required" id="emptyTargetPassword" hidden>Password cannot be empty</label>
                            </div>
                            <div className="loginDiv">
                                {/* <!-- button starts here --> */}
                                <button id="targetButton" className="btn loginButton btn-primary-theme" onClick={submitClick} >LOGIN</button>
                                {/* <!-- button ends here --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Registration form ends here --> */}
                </div>
                {/* <!--background image form starts here --> */}
                <div className="col-md-5 float-start p-0">
                    <img src="/Images/signup_img_bg.png" className="Registration_bg_img" alt="registration-bg-img" />
                </div>
                {/* <!--background image form ends here --> */}
            </div>

            {/* <!--Loader Screen--> */}
            <div className="overlay" id='targetLoader' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--Loader Screen--> */}

            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="targetFailedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={failedPopUpOkClick}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* Confirmation OTP Popup */}
            <div className="modal fade" id="OtpPopUp" tabIndex={-1}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/otp-img.svg' className="success-icon" alt="success" />
                            <div className=" success-content w-100 mt-5 text-center">
                                <h5 className="font-semibold font-18">Enter OTP!</h5>
                                <p className="success-text w-100  mb-4">Please enter the 6-digit OTP that was send to your email</p>
                                <div className="d-flex justify-content-center mb-2 w-100 ">
                                    <div className="d-flex justify-content-between w-75">
                                        <input id="OTPValue_1" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_1} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_2" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_2} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_3" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_3} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_4" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_4} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_5" type="text" className="form-control otp-form-Admin px-auto" value={OTPState.OTPValue_5} onChange={(e) => OTPValidation(e)}></input>
                                        <input id="OTPValue_6" type="text" className="form-control otp-form-Admin px-auto" onKeyDown={handleKeyDown} value={OTPState.OTPValue_6} onChange={(e) => OTPValidation(e)}></input>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center mb-2 w-100 ">
                                    <p className="font-12 font-semibold mb-3 text-end w-75">Resend OTP in <span>00:{(otpVerificationLimit < 10) ? (0 + otpVerificationLimit) : otpVerificationLimit}</span></p>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <button type="button" id='Verifybtn' className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => verifyButtonClick(OTPBtnName)}>{OTPBtnName}</button>
                                </div>
                                <div className="d-flex justify-content-center mb-3">
                                    <label className="float-start font-semibold pb-3 font-14 required text-center" id="invalidOTP" hidden>Invalid OTP provided</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Confirmation OTP Popup */}
            <div id="targetFadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    );
}