import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import RegistrationForm from './Registration';
import VerifyMail from './VerifyMail';
import {Login} from './Login';
import ForgotPassword from './Forgot Password';
import SSORegistrationForm from './SignUp';

function RegistrationRouting() {
  return (
    <Router>
      <Switch>
        <Route exact path="/Login" component={Login}></Route>
        <Route exact path="/VerifyEmail" component={VerifyMail}></Route>
        <Route exact path="/Registration" component={RegistrationForm}></Route>
        <Route exact path="/ForgotPassword" component={ForgotPassword}></Route>
        <Route exact path="/SignUp" component={SSORegistrationForm}></Route>
      </Switch>
    </Router>
  );
}

export default RegistrationRouting;
