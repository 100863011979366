
let baseURL= process.env.REACT_APP_BASE_URL;
let mainFEURL = process.env.REACT_APP_MAIN_URL_POPUP;

export const constants = {
    URL: {
        getScheduledGroupChatDataURL: baseURL + "/chl-migr-svc/ManageScheduledMigration/getManaageScheduledMigrationGrid",
        updateScheduledGroupChatDataURL: baseURL + '/chl-migr-svc/ManageScheduledMigration/updateManaageScheduledMigrationGrid',
        deleteScheduledGroupChatDataURL: baseURL + '/chl-migr-svc/ManageScheduledMigration/deleteManaageScheduledMigrationGrid',
        getChannelInventory: baseURL + '/chl-migr-svc/ManageMigrationGrid/GetInventoryDetails',
        getMigratedTeamChannel: baseURL + "/chl-migr-svc/TeamChannelCompletion/GetMigratedTeamChannel",
        getChannelData:  baseURL+ '/chl-migr-svc/ManageMigrationGrid/GetInventoryChannelList',
        completeTeamChannel: "/ChannelMigration/AU/TeamChannelCompletion",
        saveScheduleChannelData: baseURL + '/chl-migr-svc/ManageMigrationGrid/SaveScheduleMigration',

        deleteQueuedChannelDataURL: baseURL + '/chl-migr-svc/ManageMigrationGrid/deleteManageChannel',

        startChannelMigration: baseURL + '/chl-conv-migr-svc/StartChannelMigration/startChannelMigration',



         //Manage Scehdule Migration
         chatgetScheduledGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatScheduledMigration/getScheduledGroupChatData',
         chatupdateScheduledGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatScheduledMigration/updateScheduledGroupChatData',
         chatdeleteScheduledGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatScheduledMigration/deleteScheduledGroupChatData',

         //Manage Migration
         getGroupChatInventoryDataURL : baseURL + '/grp-chts-migr-svc/GroupChatManageMigration/ChatInventoryList',
         getGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatManageMigration/GroupChatList',
         saveScehduledGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatManageMigration/saveManageGroupChat',
         deleteQueuedGroupChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatManageMigration/deleteGroupChat',
         startGroupChatMigrationURL : baseURL+ '/cht-conv-migr-svc/GroupChatMigration/startMigration',

         //Group Chat Mapping and Creation
         createGroupChatURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/createTeamsChat',
         getSourceChatListURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/getSourceChatList',
         getTargetChatListURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/getTeamsChatlist',
         saveGroupChatMappingURL: baseURL + '/grp-chts-migr-svc/GroupChatMigration/SaveGroupChatMappedData',
         getMappingChatDataURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/getGroupChatMappingData',
         getChatDownloadStatusAndDataURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/getStatusAndChatList',
         deleteGroupChatMappingURL : baseURL +'/grp-chts-migr-svc/GroupChatMigration/deleteMapping',
         validateGroupChatMappingURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/validateSourceTargetChatDetails',
         createDirectChatURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/directChatCreation',
         createFolderURL : baseURL + '/grp-chts-migr-svc/GroupChatMigration/HtmlFolderCreation',
         REACT_APP_SRVADMPRTL_BASEURL :baseURL+'/svc-adm-prtl-svc',
         REACT_APP_Inventory_BASEURL:baseURL+'/invt-svc',
         REACT_APP_MICROSERVICE_NODE_HOST:baseURL+'/mcr-svc-coll-svc',
         REACT_APP_CHANNELMIGRATION_BASEURL : baseURL+ '/chl-migr-svc',
         REACT_APP_CHANNELMIGRATION_INPROGRESS_BASEURL :baseURL+'/chl-conv-migr-svc',
         REACT_APP_MAIN_URL_POPUP : mainFEURL,
         REACT_APP_MICROSOFT_AUTH_URL : 'https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id={0}&redirect_uri={1}&prompt=login',
         REACT_APP_SLACK_AUTH_URL : 'https://{0}/oauth/v2/authorize?client_id={1}&user_scope={2}&redirect_uri={3}',
         REACT_APP_SLACK_SCOPES :"channels:history,channels:read,groups:history,groups:read,mpim:history,mpim:read,im:history,im:read,files:read,usergroups:read,users:read,users:read.email,team:read",
         REACT_APP_STARTMIGRATION_BASEURL :baseURL+'/cht-conv-migr-svc/',
         REACT_APP_MEMMIG_BASEURL :baseURL+'/mbr-migr-svc',
         REACT_APP_AVAMIGRATRON_LINK :'https://www.avamigratron.com/Slack-Migration',
         REACT_APP_REACT_STRIPE_PUBLIC_KEY :'pk_test_51Kw1veIDIFOzBWcVBEliwx9yXfL6sJKrcN0XC17iEYotvEUmACtyDB2m3Qae7g1bGyHSGjKP6L0yRjLKQDEEws0n00k7aTKc65',
         REACT_APP_AUTHORITY_URL:'https://login.microsoftonline.com/common',
         REACT_APP_SSO_REDIRECT_URL: mainFEURL+ '/Settings/MSTeams/Redirect'

    }
}


export let Inventoryvideolink= '';

export default constants;