import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/UserMappingServices';
import dateFormat from "dateformat"
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';


export default function GridPage() {
  let GridLoadarray: any[] = [];
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  const date1 = new Date();
  const offset = date1.getTimezoneOffset();
  let [offSet, setoffSet] = useState(offset)
  const [columnOrder, setColumnOrder] = useState("desc");
  const [columnName, setColumnName] = useState("MigratedDate");
  const [sorthide, setsorthide] = useState("");
  const [type, setType] = useState("Grid");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [chatType, setChatType] = useState(null)
  const [WorkspaceName, setWorkspaceName] = useState("");
  const [TenantName, setTenantName] = useState("");
  const [Date1, setDate] = useState("");
  const [countPerPage, setCoutPerPage] = useState(10);
  const [date, setCreated] = useState("");
  let [alertStatus, setalertStatus] = useState("Something went wrong!Please try again or reach AVAMIGRATRON Support.")
  const [tab, setTab] = useState("")
  const [SettingsWorkspace, setSettingsWorkspacename] = useState();
  const [SettingsTenantname, setSettingsTenantname] = useState();
  const state = useLocation().state as any;
  let history = useHistory() ;

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;


  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientID: any;
  let clientToken: any;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientID = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }
  useEffect(() => {
    resetSessionTimer();
    let footercomponent: any = document.getElementById("MainContainerMFE");
    footercomponent.classList.add("container-bg-withoutFooter")
    loadGridPage();
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])




  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      alertStatus = "Your session has expired. Please log in again to continue using the application."
      setalertStatus(alertStatus);

      //#region Show PopUp
      document.getElementById("wrongalertAllReport")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalertAllReport")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalertAllReport")?.setAttribute("role", "dialog");
      document.getElementById("wrongalertAllReport")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalertAllReport")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      setalertStatus("Something went wrong!Please try again or reach AVAMIGRATRON Support.");
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden")
    }
  }
  //#endregion




  const loadGridPage = async () => {
    try {
      if (clientID != undefined && clientToken != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Report/getReportLanding`,
          token: clientToken,
          data: {
            "type": `${type}`,
            "clientID": clientID,
            "chatType": chatType,
            "sortColumn": `${columnName}`,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage,
            "offset": offSet

          }
        }
        let loader = document.getElementById("loader");
        loader?.removeAttribute('hidden');
        let data: any = await gridLoad(config);
        loader?.setAttribute('hidden', 'hidden');
        if (data.data.data[0].length == 0 || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data == "No Records Found") {
          setGridData(["No Records Found"]);
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          setGridData(["Something went wrong. Please reach AVAMIGRATRON team"])
          enablealertpopup();
        }
        else {
          setGridData(data.data.data[0]);
          setTotalItemsCount(data.data.data[1][0].ReportCount)
          setWorkspaceName(data.data.data[0][0].SlackWorkspaceName);
          setTenantName(data.data.data[0][0].TeanantName);
        }
      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      enablealertpopup();
    }
  }

  const navigation = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText;
      let data = { ...id, "type": "All", "reportLandingtab": "All", "clientID": clientID, "clientToken": clientToken }
      if (innerText != "0") {
        history.push({
          pathname: "/ReportDetails",
          state: data
        })
      }
    }
    catch (error: any) {

    }
  }

  const navigation1 = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText;
      let data = { ...id, "type": "success", "reportLandingtab": "All", "clientID": clientID, "clientToken": clientToken }
      if (innerText != 0) {
        history.push({
          pathname: "/ReportDetails",
          state: data
        })
      }
    }
    catch (error: any) {

    }
  }

  const navigation2 = (e: any) => {
    try {
      let id = JSON.parse(e.target.id)
      let innerText = e.target.innerText;
      let data = { ...id, "type": "warning", "reportLandingtab": "All", "clientID": clientID, "clientToken": clientToken }
      if (innerText != 0) {
        history.push({
          pathname: "/ReportDetails",
          state: data
        })
      }
    }
    catch (error: any) {

    }
  }

  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return gridData.map((obj, index) => {
          let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"

          let DateTime = obj.MigratedDateTime;
          let migratedTime: any = DateTime.split(' ')[1];
          let migratedDate: any = DateTime.split(' ')[0];
          let date = migratedDate.replace('-', '/').replace('-', '/');

          let millisecond = migratedTime.split('.')[0];
          let finalDateTime = date + ' ' + millisecond

          let failed = obj.failed;
          let failedCount = failed.toLocaleString("en-US");

          let success = obj.success;
          let successCount = success.toLocaleString("en-US");

          let total = obj.Total;
          let totalCount = total.toLocaleString("en-US");
          return (
            <tr className={rowClass} >
              <td className="py-3 customtabel-data-font-level1 ">{obj.ChatType}</td>
              <td className="py-3 customtabel-data-font-level1 ">{finalDateTime}</td>
              <td className="py-3 report-othercount-align " >
                <span className={obj.failed > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigation2(e)}>
                  {failedCount}
                </span>
              </td>
              <td className="py-3 report-othercount-align  ">
                <span className={obj.success > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigation1(e)}>
                  {successCount}
                </span>
              </td>
              <td className="py-3 report-totalcount-align  ">
                <span className={obj.Total > 0 ? 'anchorAsPointer' : ''} id={JSON.stringify(obj)} onClick={(e) => navigation(e)}>
                  {totalCount}
                </span>
              </td>
            </tr>
          )
        }
        )
      }
      else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
        return (<tr> <td className="centertext" colSpan={5}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={5}><img className="mt-3" src="/Images/NoRecordsFound.svg"></img>
              <p className="mt-2">No records found</p>
            </td>
          </tr>)
      }
    }
    catch (error: any) {

    }
  }

  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      setsorthide(sortID);
      let sortval = sortID.split('@');
      setColumnName(sortval[0]);
      setColumnOrder(sortval[1]);
      setIndexPerPage(indexPerPage ? indexPerPage : 1);
      setCoutPerPage(countPerPage ? countPerPage : 10);
    }
    catch (error: any) {

    }
  }

  const handleLoadMore = () => {
    try {
      let oindexPerPage = indexPerPage + 1;
      let ocountPerPage = countPerPage + 10;
      setIndexPerPage(oindexPerPage);
      setCoutPerPage(ocountPerPage);
    }
    catch (error: any) {

    }
  }

  const enablealertpopup = () => {
    try {
      document.getElementById("wrongalert")?.removeAttribute("aria-hidden");
      document.getElementById("wrongalert")?.setAttribute("aria-modal", "true");
      document.getElementById("wrongalert")?.setAttribute("role", "dialog");
      document.getElementById("wrongalert")?.setAttribute("class", "modal fade show");
      document.getElementById("wrongalert")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    catch (error: any) {

    }
  }

  const browsealertokclick = () => {
    try {
      if (isSessionEpired === false) {
        document.getElementById("wrongalert")?.setAttribute("class", "modal fade");
        document.getElementById("wrongalert")?.setAttribute("style", "display:none");
        document.getElementById("wrongalert")?.setAttribute("aria-hidden", "true");
        document.getElementById("wrongalert")?.removeAttribute("aria-modal");
        document.getElementById("wrongalert")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      }
      else {
        window.location.pathname = "/Login";


      }
    }
    catch (error: any) {

    }
  }

  return (
    <div className="row justify-content-center card-container mx-0">
      <div className="col-md-12">
        <div className="table-responsive table-border col-12 col-md-12 float-start mt-2">
          <table className="table ">
            <thead className="tabel-head-bg">
              <tr>
                <th className="table-head font-14 font-bold">Type<span>
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="chattype@desc" onClick={e => sortClicked(e)} hidden={sorthide == "chattype@desc" ? true : false} />
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="chattype@asc" onClick={e => sortClicked(e)} hidden={sorthide == "chattype@desc" ? false : true} /></span>
                </th>
                <th className="table-head font-14 font-bold">Migrated Date & Time
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="migrateddate@desc" onClick={e => sortClicked(e)} hidden={sorthide == "migrateddate@desc" ? true : false} />
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="migrateddate@asc" onClick={e => sortClicked(e)} hidden={sorthide == "migrateddate@desc" ? false : true} /></th>
                <th className="table-head font-14 font-bold">Warning
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="failed@desc" onClick={e => sortClicked(e)} hidden={sorthide == "failed@desc" ? true : false} />
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="failed@asc" onClick={e => sortClicked(e)} hidden={sorthide == "failed@desc" ? false : true} /></th>
                <th className="table-head font-14 font-bold">Success
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="success@desc" onClick={e => sortClicked(e)} hidden={sorthide == "success@desc" ? true : false} />
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="success@asc" onClick={e => sortClicked(e)} hidden={sorthide == "success@desc" ? false : true} /></th>
                <th className="table-head font-14 font-bold">Total
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="total@desc" onClick={e => sortClicked(e)} hidden={sorthide == "total@desc" ? true : false} />
                  <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="total@asc" onClick={e => sortClicked(e)} hidden={sorthide == "total@desc" ? false : true} /></th>
              </tr>
            </thead>

            <tbody >
              {gridBodyData()}
              <td colSpan={5} />
            </tbody>

          </table>
          <div className="overlay" id='loader' hidden={true}>
            <div className="Loader-Content-position">
              <div className="spinner-border loaderImg text-primary align-center Loader-Img-position" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          </div>

          <div className="modal fade" id="wrongalert" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-md">
              <div className="modal-content content-margin">
                <div className="modal-body">
                  <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                  <div className="text-center success-content w-100 mt-5">
                    <h5 className="font-semibold font-18">Alert!</h5>
                    <p className="success-text w-100 text-center mb-4">{alertStatus}</p>
                    <button type="button" className="common-btn-class btn btn-ok  text-center btn-primary-theme" data-bs-dismiss="modal" onClick={browsealertokclick}>Ok</button>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div className="col-md-12 mt-3 mb-5 text-center">

          {totalItemsCount > countPerPage ?
            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-4 " onClick={() => handleLoadMore()}>Load More</button>
            : ""}
        </div>
      </div>
    </div>
  );
}