// import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ManageScheduleMigrationGrid from './ManageScheduleMigration'
import React, { useEffect, useState } from 'react';
import ManageMigration from './ManageMigration'
import wizard from './Wizard'
import GroupChatCreationAndMapping from './ChatCreationAndMapping'
import ConversationMigration from './ConversationMigration';
import MSTeamsUserVerification from './UserVerification';
import GridPage from './ChatLandingPage';
import ManageMigrationSA from './ManageMigrationSA';
import GroupChatCreationAndMappingSA from './ChatCreationAndMappingSA';
import GroupChatHtmlFolderCreation from './ChatHtmlFolderCreation';
import ChatHtmlManageMigration from './ConversationMigrationHtml';
import ConversationMigrationSA from './ConversationMigrationSA';
import AddServiceAccounts from './ServiceAccount'
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'


function App() {
  let c_local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let objClient: any;
  let clientToken: any;


  if (c_local != undefined) {
    let c_byteValue = objCryptoJS.AES.decrypt(c_local, c_t_Key);
    var c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(c_decryptedValue);
    clientToken = objCryptoJS.AES.encrypt(c_decryptedValue, r_n_Key).toString();

}

  useEffect(() => {
    document.getElementsByTagName('video')[0]?.setAttribute("src", "");
  }, [])
  const helpIconClick = async () => {
    try {
      
     document.getElementsByTagName('video')[0]?.setAttribute("src", "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Conversation%20Migration.mp4?sp=r&st=2024-09-19T10:50:00Z&se=2025-02-06T18:50:00Z&sv=2022-11-02&sr=b&sig=9d408B%2B4o4klw7qP0NMHqE%2F2PYfBUNuKJ5NmQb5S4G0%3D")
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
    } catch (error) {
      ////console.log("HelpIconClick: ", error);
    }
  }

  const helpPopUpCloseClick = async () => {
    try {
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
      document.getElementsByTagName('video')[0]?.setAttribute("src", "");
    } catch (error) {
      ////console.log("HelpIconClick: ", error);
    }
  }
  return (
    <Router>
     
      <div className="icon-container" onClick={helpIconClick} >
        <label className="custom-icon-style help-icon-overlap" id="hidden-section" data-bs-toggle="modal" data-bs-target="#cardDetails">
          <img className="fixed-icon" src='/Images/help-web-button.svg' alt="help-icon" />
          <img className="expand-icon expand-content" src='/Images/help-web-button.svg' alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
        </label>
      </div>
      {/* <!--fixed section--> */}
      <div className="modal fade" id="helpPopUpDiv" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-radius">
            <div className="help-modal-body pt-1">
              <div className="col-md-12 mb-4 mt-4">
                <div className="row">
                  <div className="w-100 float-start text-center mb-3">
                    <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help ?</label>
                    <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to learn how to migrate from Slack Chats to MS Teams Chats!</label>
                  </div>
                  <div className="w-100 float-start text-center">
                  <video width="100%" height="315" controls controlsList="nodownload" />                
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end mb-4">
                <button type="button" className="btn-cancel-border common-btn-class btn btn-second btn-scondary-custom me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={helpPopUpCloseClick}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Help Icon ended */}
      <Switch>

      <Route exact path="/Conversation" component={GridPage} /> 
      <Route exact path="/Conversation/AU/ConversationMigration" component={ConversationMigration} />
      <Route exact path="/Conversation/AU/ChatMapping" component={GroupChatCreationAndMapping} />
      <Route exact path="/Conversation/AU/UserVerification" component={MSTeamsUserVerification} />
      {objClient.isChatServiceAccountMigration ? <Route exact path="/Conversation/SA/ConversationMigration" component={ConversationMigrationSA} /> : <Redirect from="/Conversation/SA/ConversationMigration" to="/Conversation" />}
      {objClient.isChatServiceAccountMigration ? <Route exact path="/Conversation/SA/ChatMapping" component={GroupChatCreationAndMappingSA} /> : <Redirect from="/Conversation/SA/ChatMapping" to="/Conversation" />}
      {objClient.isChatServiceAccountMigration ? <Route exact path="/Conversation/SA/ConversationMigration/ServiceAccounts" component={AddServiceAccounts} /> : <Redirect from="/Conversation/SA/ConversationMigration/ServiceAccounts" to="/Conversation" />}
       <Route exact path="/Conversation/ChatHtmlFolderCreation" component={GroupChatHtmlFolderCreation} /> 
      <Route exact path="/Conversation/ChatHtmlMigration" component={ChatHtmlManageMigration} /> 

      <Route exact path="/">
        <Redirect to="/Conversation" />
      </Route>
    </Switch>

    </Router>
  );
}

export default App;
