import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import ManageClientGrid from './ManageClient'
import EditClient from './EditClient'
import NewClient from './NewClient';
import AdminLogin from './Login';
import AdminProfile from './AdminProfileUpdate'

function AdminRouting() {
  return (
    <Router>
      <Switch>
        <Route exact path="/Admin/ManageClients/EditClient" component={EditClient}></Route>
        <Route exact path="/Admin/ManageClients/NewClient" component={NewClient}></Route>
        <Route exact path="/Admin/ManageClients" component={ManageClientGrid}></Route>
        <Route exact path="/Admin/ProfileUpdate" component={AdminProfile}></Route>
        <Route exact path="/Admin/Login" component={AdminLogin}></Route>
      </Switch>
    </Router>
  );
}

export default AdminRouting;
