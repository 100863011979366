import { useHistory, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import { sendRequestToAPI } from "../../Services/InventoryServices";
import objCryptoJS from 'crypto-js'
import JWT from 'jwt-decode'
import constants from '../../Common/Constants';

export function SlackPreMigration(): JSX.Element {

    let history = useHistory();
    const state = useLocation().state as any;

    const [statusCount, setStatusCount] = useState({ linkMailedCount: 0, verifiedCount: 0, yetToVerifyCount: 0 });
    const [importedFileName, setimportedFileName] = useState("No File Chosen");
    const [failedPopUp, setfailedPopUp] = useState("");
    const [successPopUp, setsuccessPopUp] = useState("");
    const [importedUsersList, setimportedUsersList] = useState({});
    const [isStopPageLoad, setisStopPageLoad] = useState(false);
    const [isUseEffectTrigger, setisUseEffectTrigger] = useState(false);
    let [ErrorMessage, setErrorMessage] = useState("");
    let [bool, setbool] = useState(0);
    let [bool1, setbool1] = useState(0);

    let [Array, setArray] = useState([]);
    let [Array1, setArray1] = useState([]);
    let [ConnectSettings, setConnectSettings] = useState(false);

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;




    let ClientID: any = 0;
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");

    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;

    let objClient: any;
    let adminObjClient: any;

    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientToken: any;
    let adminToken: any;


    if (local != undefined) {

        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);

        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);

        objClient = JWT(decryptedValue);
        ClientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = JWT(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }

    useEffect(() => {
        resetSessionTimer();
        document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
        let removeFooter: any = document.getElementById("MainContainerMFE");
        removeFooter.classList.add("container-bg-withoutFooter")
        if (!isStopPageLoad) {
            pageLoad();
        }
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        // if (importedFileName == undefined || importedFileName == "No File Chosen") {
        //     document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
        // }
        // else {
        //     document.getElementById("sendVerification")?.removeAttribute("Disabled");
        // }
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };

    }, [isUseEffectTrigger])

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            ErrorMessage = "Your session has expired. Please log in again to continue using the application."
            setfailedPopUp(ErrorMessage);

            //#region Show PopUp
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (ClientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await sendRequestToAPI(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = JWT(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion



    const pageLoad = async () => {
        try {
            let a: any = document.getElementById("sourceLoader");
            a.removeAttribute("hidden");

            if (ClientID != 0 && clientToken != undefined) {

                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/GetVerifiedUserStatus`,
                    token: clientToken,
                    data: {
                        clientID: ClientID,
                        type: "Slack",
                        requestType: "Status"
                    }
                }
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                var nodeResponse: any = await sendRequestToAPI(config);
                let a: any = document.getElementById("sourceLoader");
                a.setAttribute("hidden", "hidden");


            }
            else {
                localStorage.clear();
            }
            if (nodeResponse.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else if (nodeResponse.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
            }
            else if (nodeResponse.data.data[1][0] == undefined || (nodeResponse.data.data[1][0].length > 0 && nodeResponse.data.data[1][0].SlackAppClientID == undefined || nodeResponse.data.data[1][0].SlackAppClientID == null) || (nodeResponse.data.data == "Please Connect Settings to proceed Further in Inventory" && nodeResponse.data.statusCode == 400)) {
                let a: any = document.getElementById("sourceLoader");
                a.setAttribute("hidden", "hidden");
                ConnectSettings = true;
                setConnectSettings(ConnectSettings);
                ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
                setfailedPopUp(ErrorMessage);

            }

            else {

                if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                    let val = {
                        linkMailedCount: ((nodeResponse.data.data[0][0].UsersSetForVerification == 0) ? 0 : ((nodeResponse.data.data[0][0].UsersSetForVerification) - 1)),
                        verifiedCount: (nodeResponse.data.data[0][0].SourceUserAppToken),
                        yetToVerifyCount: ((nodeResponse.data.data[0][0].UsersSetForVerification - 1) - (nodeResponse.data.data[0][0].SourceUserAppToken - 1)),
                    }


                    setStatusCount(val);

                }
                else if (nodeResponse.data.statusCode == 400 && typeof nodeResponse.data.data == 'object' && nodeResponse.data.data.length > 0) {
                    setfailedPopUp("Failed to send verification link to users");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
                    downloadCSVFromJson(nodeResponse.data.data, "Invalid Source Users List");
                }

                else {
                    let val = {
                        linkMailedCount: 0,
                        verifiedCount: 0,
                        yetToVerifyCount: 0,
                    }
                    setStatusCount(val);

                }
            }

        } catch (error) {
             
            ////console.log("Page Load:", error);
            let val = {
                linkMailedCount: 0,
                verifiedCount: 0,
                yetToVerifyCount: 0,
            }
            setStatusCount(val);
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

        }
    }
    const triggerPopUpLoader = async (control: any) => {
        try {

            if (control.isLoaderTrigger != undefined) {
                let loaderElement = document.getElementById("sourceLoader")

                if (control.isLoaderTrigger) {
                    loaderElement?.removeAttribute("hidden")
                }
                else {
                    loaderElement?.setAttribute("hidden", "true")
                }
            }
            if (control.isSuccessTrigger != undefined) {
                if (control.isSuccessTrigger) {
                    //#region Show PopUp
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else {
                    //#region Hide PopUp
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                    //#endregion
                }
            }
            if (control.isFailedTrigger != undefined) {
                if (control.isFailedTrigger) {
                    //#region Show PopUp
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-hidden");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-modal", "true");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("role", "dialog");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade show");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    //#endregion
                }
                else {
                    //#region Hide PopUp
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                    //#endregion
                }
            }

        } catch (error) {
             
            ////console.log("Trigger Loader:", error);
        }
    }
    const backButtonClick = async () => {
        try {
             
            let IsHighLevel = (state != undefined && !state) ? false : true;
            history.push({
                pathname: (IsHighLevel ? "/Inventory/HighLevel" : "/Inventory/Detailed"),
                state: IsHighLevel
            })
        } catch (error) {
             
            ////console.log("BackButtonClick: ", error);
        }
    }
    const getUsersListClick = async () => {
        try {
            if (ConnectSettings == true) {
                ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else {
                document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
                await triggerPopUpLoader({ isLoaderTrigger: true });
                 
                if (ClientID != 0 && clientToken != undefined) {

                    let config = {
                        url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/GetUserProfile`,
                        token: clientToken,
                        data: {
                            clientID: ClientID,
                            type: "Slack"
                        }
                    }

                    if(adminLocal !=undefined && adminLocal !== null){
                        (config.data as any).token = adminToken
                    }
                    ////console.log(config);
                    var nodeResponse: any = await sendRequestToAPI(config);
                    if (nodeResponse.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                        setfailedPopUp(ErrorMessage);
                        await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

                    }

                }
                else {
                    localStorage.clear();
                }
                if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                    setArray1(nodeResponse.data.data);
                    setbool1(1);
                    setsuccessPopUp("Slack Users List downloaded successfully");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isSuccessTrigger: true });

                }
                else if (nodeResponse.data.data == "Invalid authentication token provided") {
                    await triggerPopUpLoader({ isLoaderTrigger: false });
                    enableSessionExpiryPopup();
                }
                else {

                    setfailedPopUp("Failed To Fetch Slack Users");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

                }
            }
        } catch (error) {
             
            ////console.log("Get Users List Click:", error);
            setfailedPopUp("Failed To Fetch Slack Users");
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
        }
        finally {
            setimportedFileName("No File Chosen");
            setimportedUsersList({});
        }
    }
    const downloadCSVFromJson = async (arrayOfJson: any, fileName: string) => {
        try {



            const workSheet = XLSX.utils.json_to_sheet(arrayOfJson);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, fileName)
            XLSX.writeFile(workBook, `${fileName}.csv`)

        } catch (error) {
            //debugger
            ////console.log("Download File Method:", error);
        }
    };
    const btnImportClick = async (e: any) => {
        try {


            if (ConnectSettings == true) {
                ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else {
                const file = e.target.files[0];
                e.target.value = null;
                if (file != undefined) {

                     
                    setimportedFileName(file.name)
                    const reader = new FileReader();
                    let csvDetails = ['SlackUserName', 'SlackUserMailID', 'IsActiveUser', 'IsRemoved'];
                    let dummy = [''];
                    reader.onload = async (evt: any) => {
                         
                        const bstr = evt.target.result;
                        const wb = XLSX.read(bstr, { type: 'binary' });
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];

                        try {
                            const data: any = XLSX.utils.sheet_to_json(ws, { header: ["SlackUserName", "SlackUserMailID", "IsActiveUser", "IsRemoved", "dummy"], defval: "" });/* Remove Empty Rows and Convert Array to JSON */
                            if ((file.name.includes('.csv')) && csvDetails.includes(data[0].SlackUserName) && csvDetails.includes(data[0].SlackUserMailID) && csvDetails.includes(data[0].IsActiveUser) && csvDetails.includes(data[0].IsRemoved) && data[0].dummy == undefined && Object.keys(data[0]).length == 4 && data.length > 1) {
                                var ChatDetails = data
                                ChatDetails.shift()
                                await triggerPopUpLoader({ isLoaderTrigger: false });
                                document.getElementById("sendVerification")?.removeAttribute("Disabled");
                                setimportedUsersList(ChatDetails)
                            }
                            else {
                                 
                                setfailedPopUp("Please provide valid template!");
                                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
                                setimportedFileName("No File Chosen")
                                setimportedUsersList({});
                            }
                        } catch (error) {
                             
                            ////console.log(error);
                        }
                    };
                    reader.readAsBinaryString(file);
                }
                else {
                    setimportedUsersList({});
                    setimportedFileName("No File Chosen");
                    await triggerPopUpLoader({ isLoaderTrigger: false });
                }
            }
        } catch (error) {
             
            ////console.log("Import Click:", error);
            setfailedPopUp("Failed To Fetch Verified User Status");
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
            setimportedFileName("No File Chosen");
            setimportedUsersList({});

        }
    }
    const btnSendVerificationClick = async () => {
        let loader;
        try {

            document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
            setimportedFileName("No File Choosen");

            // await triggerPopUpLoader({ isLoaderTrigger: true });

            let config = {
                url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/ValidateUserDetails`,
                token: clientToken,
                data: {
                    clientID: ClientID,
                    type: "Slack",
                    users: importedUsersList
                }
            }

            if(adminLocal !=undefined && adminLocal !== null){
                (config.data as any).token = adminToken
              }

            loader = document.getElementById("Loadersendverification");

            loader?.removeAttribute('hidden');
            let nodeResponse: any = await sendRequestToAPI(config);
            loader?.setAttribute('hidden', 'hidden')


            if (nodeResponse.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else if (nodeResponse.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
            }
            else {
                if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                    setsuccessPopUp("Verification link sent to users");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isSuccessTrigger: true });
                    isUseEffectTrigger == false ? setisUseEffectTrigger(true) : (setisUseEffectTrigger(false));

                }
                else {
                    setfailedPopUp("Please provide valid Slack User Details");
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
                    setArray(nodeResponse.data.data);
                    setbool(1);
                    isUseEffectTrigger == false ? setisUseEffectTrigger(true) : (setisUseEffectTrigger(false));

                }
            }
        } catch (error) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');

            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

        }
    }
    const PopupOkClick = () => {
        try {
            if (isSessionEpired === false) {
                 
                if (bool == 1) {
                    setbool(0);
                    downloadCSVFromJson(Array, "Invalid Source Users List");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                }
                else if (bool1 == 1) {
                    setbool1(0);
                    downloadCSVFromJson(Array1[0], "SlackUsersList");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");
                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                }
                else {
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceSuccessPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceSuccessPopUp")?.removeAttribute("role");

                    document.getElementById("sourceFailedPopUp")?.setAttribute("class", "modal fade");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("style", "display:none");
                    document.getElementById("sourceFailedPopUp")?.setAttribute("aria-hidden", "true");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("aria-modal");
                    document.getElementById("sourceFailedPopUp")?.removeAttribute("role");

                    document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                }
            }
            else {
                window.location.pathname = "/Login";


            }
        } catch (error) {

        }
    }
    const btnRefreshClick = async () => {
        try {
            if (ConnectSettings == true) {
                ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else {
                document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");
                try {
                    pageLoad();

                } catch (error) {
                    setfailedPopUp("Failed To Fetch Verified User Status")
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

                }
            }
        } catch (error) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
        }
        finally {
            setimportedUsersList({});
            setimportedFileName("No File Chosen");
        }
    }
    const btnDownloadVerifiedUsersClick = async () => {
        try {
            if (ConnectSettings == true) {
                ErrorMessage = "Please Connect Settings to proceed Further in Inventory";
                setfailedPopUp(ErrorMessage);
                await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

            }
            else {
                document.getElementById("sendVerification")?.setAttribute("Disabled", "Disabled");

                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigration/GetVerifiedUserStatus`,
                    token: clientToken,
                    data: {
                        clientID: ClientID,
                        type: "Slack",
                        requestType: "Details"
                    }
                }
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                }
                
                let nodeResponse: any = await sendRequestToAPI(config);
                if (nodeResponse.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                    ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                    setfailedPopUp(ErrorMessage);
                    await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });

                }
                else if (nodeResponse.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    if (nodeResponse?.data != undefined && nodeResponse.data.statusCode == 200 && nodeResponse.data.data != undefined) {
                        await downloadCSVFromJson(nodeResponse.data.data[0], "VerifiedSlackUsers");
                        // setisUseEffectTrigger(true);

                    }
                    else {
                        setfailedPopUp("Failed To Downalod Verified User Details");
                        await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
                        // setisUseEffectTrigger(true);

                    }

                }
            }
        } catch (error) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setfailedPopUp(ErrorMessage);
            await triggerPopUpLoader({ isLoaderTrigger: false, isFailedTrigger: true });
        }
    }
    const step1HideShowClick = async () => {
        try {
            if (document.getElementById("panelsStayOpen-collapseOne")?.hidden == true) {
                document.getElementById("panelsStayOpen-collapseOne")?.removeAttribute("hidden");
                document.getElementById("step1Arrow")?.setAttribute("class", "close-dropDown-SPM");
            }
            else {
                document.getElementById("panelsStayOpen-collapseOne")?.setAttribute("hidden", "true");
                document.getElementById("step1Arrow")?.setAttribute("class", "open-dropDown-SPM");
            }
        } catch (error) {
            ////console.log("Step1HideShowClick Error:", error);
        }
    }
    const step2HideShowClick = async () => {
        try {
            if (document.getElementById("panelsStayOpen-collapseTwo")?.hidden == true) {
                document.getElementById("panelsStayOpen-collapseTwo")?.removeAttribute("hidden");
                document.getElementById("step2Arrow")?.setAttribute("class", "close-dropDown-SPM");
            }
            else {
                document.getElementById("panelsStayOpen-collapseTwo")?.setAttribute("hidden", "true");
                document.getElementById("step2Arrow")?.setAttribute("class", "open-dropDown-SPM");
            }
        } catch (error) {
            ////console.log("Step2HideShowClick Error:", error);
        }
    }
    return (
        <div className='container-fluid bg-white'>
            <div className="row justify-content-center">
                <div className="col-md-12 col-sm-12 col-lg-8 mt-4 mb-2">
                    <label className="user-head-font heading-font-responsive font-26 font-bold mb-2"><a className="me-2 anchorAsPointer" onClick={backButtonClick} title="Back"><img title="Back" src="/Images/backarrow.svg" alt="Back arrow" /></a>User Verification</label>


                    {/*accordian starts here*/}
                    <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item mb-2 float-start w-100">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOne" >
                                <div className="accordion-open" id="usersListDiv" >
                                    <label className="font-18 font-semibold accordion-stepstyle anchorAsPointer" onClick={step1HideShowClick}><img id="step1Arrow" src="/Images/accordian-open.svg" className="close-dropDown-SPM" />Step 1 :</label> <label className="font-18 font-bold accordion-stepstyle2 anchorAsPointer" onClick={step1HideShowClick}> Slack Users List</label>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse mb-3 show" >
                                <div className="accordion-body">
                                    <div className="col-md-12 col-sm-12 col-lg-12 mt-2 ">
                                        <label className=" slack-details uv-margin-instruction-btm font-14 font-regular me-2">Fetch the list of users from Slack workspace here</label>
                                        <button type="button" className="btn btn-user-list btn-primary-theme font-16 font-semibold common-btn-class" onClick={getUsersListClick}>Get Users List</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item pb-5 float-start w-100">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo" >
                                <div className="accordion-open "  >
                                    <label className="font-18 font-semibold accordion-stepstyle anchorAsPointer" onClick={step2HideShowClick}><img id="step2Arrow" src="/Images/accordian-open.svg" className="close-dropDown-SPM" />Step 2 :</label> <label onClick={step2HideShowClick} className="font-18 font-bold accordion-stepstyle2 anchorAsPointer">User Verification</label>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" >
                                <div className="accordion-body">
                                    <div className="col-md-12 col-sm-12 col-lg-12 mt-2">
                                        <label className=" customize-details font-14 font-regular mb-3">Customize the downloaded users list and
                                            import the sheet to verify users. Users will receive the verification link in their emails!</label>
                                        <div className="w-100 float-start mb-5">
                                            <div className="input-group">
                                                <div className="fileUpload common-btn-class btn btn-browse px-4">
                                                    <span className="font-17" >Browse</span>
                                                    <input id="uploadBtnSlack" type="file" accept=".csv" className="upload" onChange={btnImportClick} />
                                                </div>
                                                <label id="uploadFileSlack" className=" formcontrol-browse-uv NoFileChoosen ps-2">{importedFileName}</label>
                                                <button type="button" className="btn btn-user-list font-16 font-semibold btn-primary-theme verfication-btn-responsive sendVerificationButton common-btn-class" id="sendVerification" onClick={btnSendVerificationClick}>Send Verification Link</button>
                                            </div>
                                        </div>
                                        <div className='row justify-content-between margin-bottom-responsive-uv'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 d-lg-flex align-items-center  mb-2'>
                                                <div className="col-md-12 col-lg-4 col-sm-12">
                                                    <label className="user-status font-18 font-semibold my-2">User Verification Status</label>
                                                </div>
                                                <div className="col-md-12 col-lg-8 col-sm-12 text-lg-end text-sm-start PreMigrationDownloadRefresh ps-0">
                                                    <button className="btn btn-download-verification my-2 me-3 verification-btn-responsive font-semibold font-16" onClick={btnDownloadVerifiedUsersClick}>
                                                        <span><img src="/Images/download.svg" alt="sort-icon" /></span> Download Verification
                                                        Status</button>
                                                    <button className="btn ms-0 btn-user-list my-2 marginLeft5rem UserVerificationRefresh btn-primary-theme  font-semibold font-16" onClick={btnRefreshClick}>
                                                        <span><img className="refresh-img me-2" src="/Images/refresh.svg" alt="refresh-icon" /></span>Refresh</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-lg-flex">
                                            <div className='col-lg-12 col-sm-12 col-md-12  d-lg-flex'>
                                                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                                                    <div className="card card-margin-responsive-uv">
                                                        <div className="card-body">
                                                            <label className="card-text mb-3">Link sent for user verification</label>
                                                            <div className="w-100 float-start">
                                                                <div className="float-start">
                                                                    <span className="user-num font-semibold font-20">{(statusCount.linkMailedCount).toLocaleString("en-us")}</span>
                                                                </div>
                                                                <div className="float-end">
                                                                    <img className="mail-icon" src="/Images/mail-icon.svg" alt="mail-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                                                    <div className="card card-margin-responsive-uv">
                                                        <div className="card-body">
                                                            <label className="card-text mb-3">Verified users</label>
                                                            <div className="w-100 float-start">
                                                                <div className="float-start">
                                                                    <span className="user-num font-semibold font-20">{(statusCount.verifiedCount).toLocaleString("en-us")}</span>
                                                                </div>
                                                                <div className="float-end">
                                                                    <img className="mail-icon" src="/Images/user-icon.svg" alt="user-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
                                                    <div className="card card-margin-responsive-uv">
                                                        <div className="card-body">
                                                            <label className="card-text mb-3">Users yet to verify</label>
                                                            <div className="w-100 float-start">
                                                                <div className="float-start">
                                                                    <span className="user-num font-semibold font-20">{(statusCount.yetToVerifyCount).toLocaleString("en-us")}</span>
                                                                </div>
                                                                <div className="float-end">
                                                                    <img className="mail-icon" src="/Images/user-time.svg" alt="usertime-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*accordian starts here*/}
                </div>

                {/* <!--Success Pop-up--> */}
                <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src="/Images/success.svg" className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Success!</h5>
                                    <p className="success-text w-100 text-center mb-4">{successPopUp}</p>

                                    <button type="button" className="btn btn-ok  btn-primary-theme text-center common-btn-class" data-bs-dismiss="modal" onClick={PopupOkClick}>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!--success pop-up--> */}

                {/* <!--Alert Pop-up--> */}
                <div className="modal fade" id="sourceFailedPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Alert!</h5>
                                    <p className="success-text w-100 text-center mb-4">{failedPopUp}</p>
                                    <button type="button" className="btn btn-ok  text-center common-btn-class" data-bs-dismiss="modal" onClick={PopupOkClick}>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!--Alert pop-up--> */}

                {/* <!--Loader Screen--> */}
                <div className="overlay" id='sourceLoader' hidden={true}>
                    <div className="loader-position Loader-Content-position">
                        <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                    </div>
                </div>


                <div className="overlay" id='Loadersendverification' hidden={true}>
                    <div className="loader-position Loader-Content-position">
                        <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <p>Please wait. We are sending verification link to the specified Slack Users</p>
                    </div>
                </div>
                {/* <div className="overlay" id='sourceLoader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                    <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                {/* </div> */}
                {/* </div> */}
                {/* <!--Loader Screen--> */}

                <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            </div>
        </div>
    )
}

