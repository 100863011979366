// import './App.css';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { TeamChannelCompletion } from './TeamChannelCompletion'
import ChannelCompletion from './ChannelCompletion'
import ConversationMigration from './ConversationMigration';
import ManageScheduleMigration from './ManageScheduleMigration'
import AddAzureApps from './AzureApp'
import AddServiceAccounts from './ServiceAccount'
import LandingPage from './ChannelLanding'
import TeamChannelCreation from './TeamChannelcreationandmapping'
import ConversationMigrationSA from './ConversationMigrationSA';
import ManageScheduleMigrationSA from './ManageScheduleMigrationSA'
import TeamChannelCreationSA from './TeamChannelcreationandmappingSA'
import { useEffect, useState, useRef, forwardRef } from "react";

// import './styles/High-level Inventory .css'



function ChannelRouting() {
  const helpIconClick = async () => {
    try {
      document.getElementsByTagName('video')[0]?.setAttribute("src", "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Channel%20migration.mp4?sp=r&st=2024-06-04T16:59:47Z&se=2024-12-31T00:59:47Z&spr=https&sv=2022-11-02&sr=b&sig=v8rzpYtgHKepMkSezTg%2FH6vPfU6Z2ufmH3eNvL2d8mk%3D");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
    } catch (error) {
      ////console.log("HelpIconClick: ", error);
    }
  }

  const helpPopUpCloseClick = async () => {
    try {
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
      document.getElementsByTagName('video')[0]?.setAttribute("src", "");
    } catch (error) {
      ////console.log("HelpIconClick: ", error);/
    }
  }

  useEffect(() => {
    document.getElementsByTagName('video')[0]?.setAttribute("src", "");
}, [])

  return (

    <Router>
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
      <div className="icon-container help-icon-overlap" onClick={helpIconClick}>
        <label className="custom-icon-style" id="hidden-section" data-bs-toggle="modal" data-bs-target="#cardDetails">
          <img className="fixed-icon" src='/Images/help-web-button.svg' alt="help-icon" />
          <img className="expand-icon expand-content" src='/Images/help-web-button.svg' alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
        </label>
      </div>
      {/* <!--fixed section--> */}
      <div className="modal fade" id="helpPopUpDiv" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-radius">
            <div className="help-modal-body pt-1">
              <div className="col-md-12 mb-4 mt-4">
                <div className="row">
                  <div className="w-100 float-start text-center mb-3">
                    <label className="form-label popup-label font-26 font-semibold mb-3 w-100">Need Help ?</label>
                    <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to learn how to migrate from Slack Channels to MS Teams Channels!</label>
                  </div>
                  <div className="w-100 float-start text-center">
                    <video width="100%" className='videoTag' height="315" controls controlsList="nodownload"/>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end mb-4">
                <button type="button" className="btn-scondary-custom btn-cancel-border common-btn-class btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={helpPopUpCloseClick}>Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route exact path="/ChannelMigration/SA" component={TeamChannelCreationSA}></Route>
        <Route exact path="/ChannelMigration/SA/TeamChannelMapping" component={TeamChannelCreationSA}></Route>
        <Route exact path="/ChannelMigration/SA/ConversationMigration/ServiceAccounts" component={AddServiceAccounts} />
        <Route exact path="/ChannelMigration/SA/ConversationMigration" component={ConversationMigrationSA}></Route>
        <Route exact path="/ChannelMigration/SA/ScheduledMigration" component={ManageScheduleMigrationSA}></Route>
        {/* <Route path="/ChannelMigration/AU" component={TeamChannelCreation}></Route> */}
        <Route exact path="/ChannelMigration/AU/TeamChannelMapping" component={TeamChannelCreation}></Route>
        <Route exact path="/ChannelMigration/AU/ConversationMigration" component={ConversationMigration}></Route>
        <Route exact path="/ChannelMigration/AU/ConversationMigration/ServiceApps" component={AddAzureApps} />
        <Route exact path="/ChannelMigration/AU/ScheduledMigration" component={ManageScheduleMigration}></Route>
        <Route exact path="/ChannelMigration/AU/TeamCompletion" component={TeamChannelCompletion}></Route>
        <Route exact path="/ChannelMigration/AU/TeamChannelCompletion" component={ChannelCompletion}></Route>
        <Route exact path="/ChannelMigration" component={LandingPage}></Route>
        <Route exact path="/ChannelMigration/AU" >
          <Redirect to="/ChannelMigration/AU/TeamChannelMapping"></Redirect>
        </Route>
        <Route exact path="/" >
          <Redirect to="/ChannelMigration"></Redirect>
        </Route>
      </Switch>


    </Router>

  );

}



export default ChannelRouting;