import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProfileUpdate from './ProfileUpdate'
import ContactUs from './ContactUs';
import UpgradePlan from './UpgradePlan';
import SuccessPage from './SuccessPayment';

function ProfileRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/ChoosePlan" component={UpgradePlan}></Route>
        <Route path="/UpgradePlan" component={UpgradePlan}></Route>
        <Route path="/ContactUs" component={ContactUs}></Route>
        <Route path="/ProfileUpdate" component={ProfileUpdate}></Route>
        <Route path="/SuccessPage" component={SuccessPage}></Route>
      </Switch>
    </Router>
  );
}
export default ProfileRouter;