import React, { useEffect, useState } from 'react';
import dateFormat from "dateformat";
import fileSize from 'filesize';
import { gridLoad } from '../../Services/InventoryServices';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import objCryptoJS from 'crypto-js'
import JWT from 'jwt-decode'
import { debug } from 'console';
import ReactTooltip from "react-tooltip";
import constants from '../../Common/Constants'



export function InventoryDetailsInProgress() {
    let GridLoadarray: any[] = [];
    let history = useHistory();
    const state = useLocation().state as any;
    const [InventoryName, setInventoryName] = useState("");
    const [SlackWorkspaceURL, setSlackWorkspaceURL] = useState("");
    const [Created, setCreated] = useState("");
    const [EndTime, setEndTime] = useState("");
    const [InventoryFromDate, setInventoryFromDate] = useState("");
    const [InventoryToDate, setInventoryToDate] = useState("");
    const [IsUserInventoryTaken, setIsUserInventoryTaken] = useState("");
    const [IsUserGroupInventoryTaken, setIsUserGroupInventoryTaken] = useState("");
    const [IsChannelInventoryTaken, setIsChannelInventoryTaken] = useState("");
    const [IsMMInventoryTaken, setIsMMInventoryTaken] = useState("");
    const [IsDMInventoryTaken, setIsDMInventoryTaken] = useState("");
    const [Status, setStatus] = useState("");
    const [CreatedTime, setCreatedTime] = useState("");
    const [thirdpartyApps, setthirdpartyApps] = useState("");
    const [Users, setUsers] = useState(0);
    const [thirdpartyAppscount, setthirdpartyAppscount] = useState(0);

    const [UserGroup, setUserGroup] = useState(0);
    const [Channel, setChannel] = useState(0);
    const [MM, setMM] = useState(0);
    const [DM, setDM] = useState(0);
    const [MessageCount, setMessageCount] = useState(0);
    const [FileCount, setFileCount] = useState(0);
    const [FileSize, setFileSize] = useState("");
    const [InventoryID, setInventoryID] = useState(state.ID);
    let bool = state.Status == "In Progress" || state.Status == "Cancelling" || state.Status == undefined ? true : false;
    let [InventoryStatus, setInventoryStatus] = useState(bool);
    let [ErrorMessage, setErrorMessage] = useState("");
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;


    const [Hours, sethrs] = useState(0);
    const [minutes, setminutes] = useState(0);
    const [seconds, setseconds] = useState(0);


    const [clientID, setclientID] = useState(state.ClientID);
    const [token, settoken] = useState(state.token);
    const [adminToken, setadminToken] = useState(state.adminToken)
    const [Cancelledmessage, setCancelledmessage] = useState("")


    let local: any = localStorage.getItem("cid_t");

    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;


    let objClient: any;

    if (local != undefined) {

        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);

        var decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);

        objClient = JWT(decryptedValue);
        SessionEpiredDateTime = objClient.CreatedAt;


    }


    useEffect(() => {
        let aa: any = document.getElementById("loader");
        aa.removeAttribute("hidden");
        resetSessionTimer();
        InventoryDetails();
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };

    }, [])
    let clearinv: any;

    useEffect(() => {
        if (InventoryStatus == true) {
            clearinv = setInterval(InventoryDetails, 5000);
        }
    }, [])

    var Interval: any;
    useEffect(() => {
        if (InventoryStatus == true) {
            Interval = setInterval(() =>
                setseconds(seconds => seconds + 1), 1000);
        }
    }, [])

    useEffect(() => {
        if (InventoryStatus == true) {

            if (seconds < 60) return;
            setseconds(0);
            setminutes(minutes => minutes + 1);
        }
    }, [seconds])

    useEffect(() => {
        if (InventoryStatus == true) {
            if (minutes < 60) return;
            setminutes(0);
            sethrs(hrs => hrs + 1);
        }
    }, [minutes])



    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            ErrorMessage = "Your session has expired. Please log in again to continue using the application."
            setErrorMessage(ErrorMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")

            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: token
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(token);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = JWT(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    let clientToken: any = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    settoken(clientToken);
                    //console.log(token);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setErrorMessage(ErrorMessage);
            let popup = document.getElementById("alertPopUp")
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
            setErrorMessage(ErrorMessage);
            let popup = document.getElementById("alertPopUp")
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion


    const cancelsavebtn = async () => {
        try {
            callCancelAPI();
        }
        catch (error: any) {
        }
    }
    const closecancelpopup = async () => {
        let cancelReason: any = document.getElementById("notes-0");
        cancelReason.value = "";
        let a: any = document.getElementById("cancelsaveid");
        a.setAttribute("disabled", "disabled");
        let cardDiv = document.getElementById("cardDetails")
        cardDiv?.setAttribute("style", "display:none")
        let closePopUp = document.getElementById("fadeDiv");
        closePopUp?.setAttribute("style", "display:none");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
    const cancelclearbtn = async () => {
        let cancelReason: any = document.getElementById("notes-0");
        cancelReason.value = "";
        let a: any = document.getElementById("cancelsaveid");
        a.setAttribute("disabled", "disabled");
        await closecancelpopup();

    }
    const cancelMessage = async (e: any) => {
        try {
            let a: any = document.getElementById("cancelsaveid");
            a.removeAttribute("disabled");
            setCancelledmessage(e.target.value);
            if (e.target.value == "") {
                let a: any = document.getElementById("cancelsaveid");
                a.setAttribute("disabled", "disabled");
            }
        } catch (error) {

        }
    }
    const cancelbtn = async () => {
        try {
            let cardDetails = document.getElementById("cardDetails")

            cardDetails?.removeAttribute("aria-hidden");

            cardDetails?.setAttribute("aria-modal", "true");

            cardDetails?.setAttribute("role", "dialog");

            cardDetails?.setAttribute("class", "modal fade show");

            cardDetails?.setAttribute("style", "display:block");

            let alertPopUp = document.getElementById("fadeDiv");

            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

            let a: any = document.getElementById("cancelsaveid");
            a.setAttribute("disabled", "disabled");
            // let b: any = document.getElementById("Cancelbtnclick");
            // b.removeAttribute("style");

        } catch (error) {

        }
    }
    const callCancelAPI = async () => {
        try {
            await closecancelpopup();
            let aa: any = document.getElementById("loader");
            aa.removeAttribute("hidden");
            let config = ({
                method: "POST",
                url: `$constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventoryInProgressView/cancelInventory`,
                token: token,

                data: {
                    "clientID": clientID,
                    "InventoryID": Number(InventoryID),
                    "CancelledMessage": Cancelledmessage
                },


            }
            )
            let data: any = await gridLoad(config);
            let bb: any = document.getElementById("loader");
            bb.setAttribute("hidden", "hidden");
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                setErrorMessage(ErrorMessage);
                let popup = document.getElementById("alertPopUp")

                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            }
            else if (data.data.data == "Invalid authentication token provided") {
                enableSessionExpiryPopup();
            }
            else if (data.data.statusCode == "200") {
                await invDetailsbackbtn();
            }
            else if (data.data.statusCode == "400") {

                if (data.data.data == "Inventory Already Completed") {

                    // InventoryStatus=true;
                    // setInventoryStatus(InventoryStatus);
                    clearInterval(Interval);
                    clearInterval(clearinv);
                    await InventoryDetails();

                }

            }
        }
        catch (error: any) {
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const InventoryDetails = async () => {
        try {
            if (window.location.pathname.includes('/Inventory/InProgress')) {
                // let aa: any = document.getElementById("loader");
                // aa.removeAttribute("hidden");
                let config = (
                    {
                        method: "POST",
                        url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventoryInProgressView/pageLoad`,
                        token: token,

                        data: {
                            "clientID": clientID,
                            "InventoryID": Number(InventoryID)
                        }
                    }
                )
                if(adminToken !=undefined && adminToken !== null){
                    (config.data as any).token = adminToken
                  }
                let data: any = await gridLoad(config);
                if (window.location.pathname.includes('/Inventory/InProgress')) {
                    let bb: any = document.getElementById("loader");
                    bb.setAttribute("hidden", "hidden");
                    if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                        setErrorMessage(ErrorMessage);
                        let popup = document.getElementById("alertPopUp")

                        //#region Show PopUp
                        popup?.removeAttribute("aria-hidden");
                        popup?.setAttribute("aria-modal", "true");
                        popup?.setAttribute("role", "dialog");
                        popup?.setAttribute("class", "modal fade show");
                        popup?.setAttribute("style", "display:block");
                        document.getElementById("fadeDiv")?.removeAttribute("hidden");
                        document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    }
                    else if (data.data.data == "Invalid authentication token provided") {
                        enableSessionExpiryPopup();
                    }
                    else {
                        if (InventoryStatus == true && data.data.data[1][0] != undefined && data.data.data[1][0].LatestStatus != undefined && data.data.data[1][0].LatestStatus != "Completed") {
                            let c: any = document.getElementById("Cancelbtnclick");
                            c.removeAttribute("hidden");
                        }

                        if (state.Status == "Cancelling" || data.data.data[1][0].LatestStatus == "Completed" || data.data.data[1][0].LatestStatus == "Failed" || data.data.data[1][0].LatestStatus == "Cancelled") {
                            // InventoryStatus = false;
                            // setInventoryStatus(InventoryStatus);
                            let c: any = document.getElementById("Cancelbtnclick");
                            c.setAttribute("disabled", "disabled");
                        }
                        var Data = data.data.data[0][0];
                        setInventoryName(Data.InventoryName)
                        setSlackWorkspaceURL(Data.SlackWorkspaceURL)
                        setStatus(Data.Status)
                        setCreated(dateFormat(Data.Created, "mm/dd/yyyy h:MM:ss TT"))

                        if (Data.Status == "In Progress" || Data.Status == "Cancelling") {
                            var InventoryStartedDate: any = new Date(Data.Created);
                            var currentDateTime: any = new Date();

                            var a = moment(currentDateTime);//now
                            var b = moment(InventoryStartedDate);
                            var diffsecs = a.diff(b, 'seconds');
                            var h = Math.floor(diffsecs / 3600);
                            var m = Math.floor(diffsecs % 3600 / 60);
                            var s = Math.floor(diffsecs % 3600 % 60);

                            sethrs(h)
                            setminutes(m)
                            setseconds(s)
                        }
                        else {
                            var InventoryStartedDate: any = new Date(Data.Created);
                            var currentDateTime: any = new Date(Data.EndTime);
                            var a = moment(currentDateTime);//now
                            var b = moment(InventoryStartedDate);
                            var diffsecs = a.diff(b, 'seconds');
                            var h = Math.floor(diffsecs / 3600);
                            var m = Math.floor(diffsecs % 3600 / 60);
                            var s = Math.floor(diffsecs % 3600 % 60);
                            sethrs(h)
                            setminutes(m)
                            setseconds(s)
                            clearInterval(Interval)
                            clearInterval(clearinv)

                        }

                        var FromDate = (Data.InventoryFromDate)
                        if (FromDate == null) {
                            setInventoryFromDate("NA")
                        }
                        else {
                            setInventoryFromDate(dateFormat(FromDate, "mm/dd/yyyy"))
                        }
                        var toDate = (Data.InventoryToDate)
                        if (toDate == null) {
                            setInventoryToDate("NA")
                        }
                        else {
                            setInventoryToDate(dateFormat(toDate, "mm/dd/yyyy"))
                        }

                        // var users = Data.IsUserInventoryTaken == true ? "taken" : "not taken";
                        var users
                        if (Data.IsUserInventoryTaken == true) {
                            users = "taken"
                        }
                        else if (Data.IsUserInventoryTaken == false) {
                            users = "Failed"
                        }
                        else {
                            users = "not taken"
                        }
                        setIsUserInventoryTaken(users)
                        // var ThirdPartyAppcount: any = Data.ThirdPartApps > 0 ? "taken" : "not taken";
                        var ThirdPartyAppcount: any;
                        if (Data.IsBotInventoryTaken == true || (Data.IsBotInventoryTaken == null && Data.ThirdPartApps > 0)) {
                            ThirdPartyAppcount = "taken"
                        }
                        else if (Data.IsBotInventoryTaken == false) {
                            ThirdPartyAppcount = "Failed"
                        }

                        else {
                            ThirdPartyAppcount = "not taken"
                        }

                        setthirdpartyApps(ThirdPartyAppcount);
                        var usergroups = Data.IsUserGroupInventoryTaken == true ? "taken" : "not taken";
                        if (Data.IsUserGroupInventoryTaken == true) {
                            usergroups = "taken"
                        }
                        else if (Data.IsUserGroupInventoryTaken == false) {
                            usergroups = "Failed"
                        }
                        else {
                            usergroups = "not taken"
                        }
                        setIsUserGroupInventoryTaken(usergroups)
                        // var Channels = Data.IsChannelInventoryTaken == true ? "taken" : "Failed";
                        var Channels
                        if (Data.IsChannelInventoryTaken == true) {
                            Channels = "taken"
                        }
                        else if (Data.IsChannelInventoryTaken == false) {
                            Channels = "Failed"
                        }
                        else {
                            Channels = "not taken"
                        }
                        setIsChannelInventoryTaken(Channels)
                        // var MM = Data.IsMMInventoryTaken == true ? "taken" : "Failed";
                        var MM
                        if (Data.IsMMInventoryTaken == true) {
                            MM = "taken"
                        }
                        else if (Data.IsMMInventoryTaken == false) {
                            MM = "Failed"
                        }
                        else {
                            MM = "not taken"
                        }
                        setIsMMInventoryTaken(MM)
                        // var DM = Data.IsDMInventoryTaken == true ? "taken" : "Failed";
                        var DM
                        if (Data.IsDMInventoryTaken == true) {
                            DM = "taken"
                        }
                        else if (Data.IsDMInventoryTaken == false) {
                            DM = "Failed"
                        }
                        else {
                            DM = "not taken"
                        }

                        setIsDMInventoryTaken(DM)
                        setStatus(Data.Status)
                        let USERS1 = parseInt(Data.Users);
                        setUsers(USERS1)
                        let USERGROUP1 = parseInt(Data.UserGroup);
                        setUserGroup(USERGROUP1);
                        if (Data.ThirdPartApps == null) {
                            Data.ThirdPartApps = 0;
                        }
                        let ThirdPartApps1 = parseInt(Data.ThirdPartApps);
                        setthirdpartyAppscount(ThirdPartApps1)
                        let CHANNEL1 = parseInt(Data.Channel);
                        setChannel(CHANNEL1)
                        let MM1 = parseInt(Data.MM);
                        setMM(MM1)
                        let DM1 = parseInt(Data.DM);
                        setDM(DM1)
                        let MESSAGECOUNT1 = parseInt(Data.MessageCount);
                        setMessageCount(MESSAGECOUNT1)
                        let FILECOUNT1 = parseInt(Data.FileCount);
                        setFileCount(FILECOUNT1)
                        var Size = fileSize(Data.FileSize)
                        setFileSize(Size)
                    }

                }
            }
        }
        catch (error: any) {
            clearInterval(Interval)
            clearInterval(clearinv)
            let loader: any = document.getElementById("loader");
            loader?.setAttribute('hidden', 'hidden');
            setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const invDetailsbackbtn = async () => {
        try {
            history.push({
                pathname: "/Inventory/Detailed",
                state: false
            })
            // window.location.reload();
            // window.location.pathname = '/Inventory/Detailed';
        }
        catch (error: any) {

        }
    }
    const popUpOkClick = (type: string) => {

        try {
            if (isSessionEpired === false) {

                //#region Hide PopUp

                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")

                popup?.setAttribute("class", "modal fade");

                popup?.setAttribute("style", "display:none");

                popup?.setAttribute("aria-hidden", "true");

                popup?.removeAttribute("aria-modal");

                popup?.removeAttribute("role");

                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

                let migrateButton = document.getElementById("migrateButton")

                migrateButton?.setAttribute('disabled', 'disabled');

                // loadGridPage();



                //#endregion
            }
            else {
                window.location.pathname = "/Login";

            }
        } catch (error) {

            ////console.log("Issue Faced");

        }

    }

    return (
        <div className="container-fluid">
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position Loader-Content-position">
                    <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                    {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            <div className="row justify-content-center card-container mx-0">
                <div className="col-md-12 col-sm-12 col-lg-10 mt-3">
                    <h4 className="font-24 font-bold mt-4">
                        <label className="user-head-font font-26 font-bold mb-2"><a className="me-2 anchorAsPointer" onClick={invDetailsbackbtn} title="Back"><img title="Back" src='/Images/backarrow.svg' alt="Back arrow" /></a>Inventory Details</label>
                    </h4>
                    {InventoryStatus == false ?
                        <div className="row float-start div-border">
                            <div className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Workspace URL</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{SlackWorkspaceURL}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory</span>
                                    {
                                        InventoryName?.length > 36 ?
                                            <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb" data-tip={InventoryName}>{InventoryName.substring(0, 33)}...</span>
                                            :
                                            <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb" data-tip="">{InventoryName}</span>
                                    }
                                </div>

                            </div>

                            <div className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory start date time</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{Created}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory from date</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{InventoryFromDate}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory to date</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{InventoryToDate}</span>
                                </div>

                            </div>


                            <div id='MessageCount' className="col-md-4 float-start mt-4 mb-2">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Message Count</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{MessageCount.toLocaleString("en-us")}</span>
                                </div>

                            </div>
                            <div id='FileCount' className="col-md-4 float-start mt-4 mb-2" >
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title ">File Count </span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{FileCount.toLocaleString("en-us")}</span>
                                </div>

                            </div>
                            <div id='FileSize' className="col-md-4 float-start mt-4 mb-2" >
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title ">File size</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{FileSize}</span>
                                </div>

                            </div>

                            <div className="col-md-4 float-start mt-4 mb-2 ">
                                <div className="float-start col-md-8 p-2">
                                    <div className="col-md-2 float-start">
                                        <img src="/Images/timer.svg" className="hourglass-icon" alt="hour-glass" />
                                    </div>
                                    <div className="col-md-10 float-start">
                                        <table className="p-2 ms-3">
                                            <thead>
                                                <tr>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Hrs</th>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Mins</th>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Sec</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{Hours}</td>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{minutes}</td>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{seconds}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        <div className="col-md-12 float-start div-border">
                            <div className="col-md-4 float-start mt-4 mb-4">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Workspace URL</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{SlackWorkspaceURL}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-4">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory</span>
                                    {
                                        InventoryName?.length > 36 ?
                                            <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb" data-tip={InventoryName}>{InventoryName.substring(0, 33)}...</span>
                                            :
                                            <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb" data-tip="">{InventoryName}</span>
                                    }

                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-4">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory start date time</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{Created}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-4">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory from date</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{InventoryFromDate}</span>
                                </div>

                            </div>
                            <div className="col-md-4 float-start mt-4 mb-4">
                                <div className="float-start">
                                    <span className="float-start w-100 font-14 font-regular widget-title">Inventory to date</span>
                                    <span className="float-start w-100 font-14 font-semibold widget-subtitle f-sb">{InventoryToDate}</span>
                                </div>

                            </div>

                            <div className="col-md-4 float-start mt-4 mb-4 ">
                                <div className="float-start col-md-8 p-2">
                                    <div className="col-md-2 float-start">
                                        <img src="/Images/timer.svg" className="hourglass-icon" alt="hour-glass" />
                                    </div>
                                    <div className="col-md-10 float-start">
                                        <table className="p-2 ms-3">
                                            <thead>
                                                <tr>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Hrs</th>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Mins</th>
                                                    <th className="font-14 Inv_TimerPadding widget-title">Sec</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{Hours}</td>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{minutes}</td>
                                                    <td className="font-24 font-semibold Inv_TimerPadding table-content text-center">{seconds}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        </div>}

                    {objClient != undefined && objClient.IsChannel != 0 && objClient.IsChat != 0 ?
                        <div className=" mb-5 mt-4 float-start mx-0 w-100">
                            <h6 className="float-left inventory-progress font-18 font-bold mb-3">Overall Summary</h6>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className=" mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {IsUserInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                : <div className="progress-bar bg-progress-bar form-range progressbarwidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0">
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {IsUserInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (IsUserInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (IsUserInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className=" text-center w-100 card-row-block">
                                                        <img src="/Images/user_icon_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold text-center mt-2 mb-0">{Users.toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular text-center">Users</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className="mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {thirdpartyApps == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                : ""
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0">
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {thirdpartyApps == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (thirdpartyApps == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (thirdpartyApps == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className=" text-center w-100 card-row-block">
                                                        <img src="/Images/app_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold text-center mt-2 mb-0">{thirdpartyAppscount == 0 || thirdpartyAppscount == null ? 0 : thirdpartyAppscount.toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular  text-center">Third Party Apps</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className=" mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {IsUserGroupInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                : ""
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0">
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {IsUserGroupInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (IsUserGroupInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (IsUserGroupInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className="text-center w-100 card-row-block">
                                                        <img className="menu-logo" src="/Images/usergroup_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold text-center mt-2 mb-0">{(UserGroup).toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular  text-center">User Group</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className="mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {IsChannelInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                : ""
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0">
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {IsChannelInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (IsChannelInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (IsChannelInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className=" text-center w-100 card-row-block">
                                                        <img src="/Images/channel_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold text-center mt-2 mb-0">{Channel.toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular  text-center">Channel</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className=" mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {IsMMInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                : ""
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0" >
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {IsMMInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (IsMMInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (IsMMInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className=" text-center w-100 card-row-block">
                                                        <img className="menu-logo" src="/Images/multiparty_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold  text-center f-sb mt-2 mb-0">{MM.toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular  text-center">Multi-party Messages</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 float-start px-2">
                                <div className=" mb-5 p-0 w-100">
                                    <span>
                                        <div className="p-style">
                                            {IsDMInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                : ""
                                            }
                                        </div>
                                    </span>
                                    <div className="w-100">
                                        <div className="card card-width shadow-sm">
                                            <div className="card-body pb-0">
                                                <div className="col-md-12">
                                                    <div className="float-end col-md-12">
                                                        {IsDMInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                            : (IsDMInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                : (IsDMInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                    : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                    </div>
                                                    <div className=" text-center w-100 card-row-block">
                                                        <img className="menu-logo" src="/Images/directchats_inventory.svg" alt="menus-pic" />
                                                    </div>
                                                    <div className=" text-center mb-4">
                                                        <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{DM.toLocaleString("en-us")}</h6>
                                                        <p className="font-14 font-regular  text-center">Direct Messages</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        : objClient != undefined && objClient.IsChannel != 0 && objClient.IsChat == 0 ?
                            <div className="row mb-5 mt-5 float-start w-100">
                                <h6 className="float-left inventory-progress font-18 font-bold mb-3">Overall Summary</h6>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0 float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsUserInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                    : <div className="progress-bar bg-progress-bar form-range progressbarwidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsUserInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsUserInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsUserInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img src="/Images/user_icon_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold  text-center f-sb mt-2 mb-0">{Users.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular text-center">Users</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0 float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {thirdpartyApps == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {thirdpartyApps == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (thirdpartyApps == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (thirdpartyApps == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img src="/Images/app_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{thirdpartyAppscount == 0 ? 0 : thirdpartyAppscount.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">Third Party Apps</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0  float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsUserGroupInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsUserGroupInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsUserGroupInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsUserGroupInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img className="menu-logo" src="/Images/usergroup_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{(UserGroup).toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">User Group</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0  float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsChannelInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsChannelInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsChannelInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsChannelInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                            }
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img src="/Images/channel_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{Channel.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">Channel</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :

                            <div className="row mb-5 mt-5 float-start w-100">
                                <h6 className="float-left inventory-progress font-18 font-bold mb-3">Overall Summary</h6>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0 float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsUserInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                                                    : <div className="progress-bar bg-progress-bar form-range progressbarwidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsUserInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsUserInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsUserInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img src="/Images/user_icon_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold  text-center f-sb mt-2 mb-0">{Users.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular text-center">Users</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0 float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {thirdpartyApps == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {thirdpartyApps == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (thirdpartyApps == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (thirdpartyApps == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img src="/Images/app_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{thirdpartyAppscount == 0 || thirdpartyAppscount == null ? 0 : thirdpartyAppscount.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">Third Party Apps</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0  float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsUserGroupInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsUserGroupInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsUserGroupInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsUserGroupInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img className="menu-logo" src="/Images/usergroup_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{(UserGroup).toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">User Group</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0   float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsMMInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0" >
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsMMInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsMMInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsMMInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img className="menu-logo" src="/Images/multiparty_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold  text-center f-sb mt-2 mb-0">{MM.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">Multi-party Messages</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 float-start px-2">
                                    <div className=" mb-5 p-0  float-start w-100">
                                        <span>
                                            <div className="p-style">
                                                {IsDMInventoryTaken == "taken" ? <div className="progress-bar bg-progress-bar form-range progressbarFullWidth" role="progressbar" aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                                                    : ""
                                                }
                                            </div>
                                        </span>
                                        <div className="float-start w-100">
                                            <div className="card card-width float-start shadow-sm">
                                                <div className="card-body pb-0">
                                                    <div className="col-md-12 float-start">
                                                        <div className="float-end col-md-12">
                                                            {IsDMInventoryTaken == "taken" ? <img className="card-checked float-end " src="/Images/check_inventory.svg" alt="checked" />
                                                                : (IsDMInventoryTaken == "not taken") ? <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />
                                                                    : (IsDMInventoryTaken == "Failed") ? <img className="card-checked float-end users " src="/Images/canceled-inventory.svg" alt="checked" />
                                                                        : <img className="card-checked float-end users " src="/Images/uncheck_inventory.svg" alt="checked" />}
                                                        </div>
                                                        <div className=" text-center w-100">
                                                            <img className="menu-logo" src="/Images/directchats_inventory.svg" alt="menus-pic" />
                                                        </div>
                                                        <div className=" text-center mb-4">
                                                            <h6 className="font-22 font-semibold text-center f-sb mt-2 mb-0">{DM.toLocaleString("en-us")}</h6>
                                                            <p className="font-14 font-regular  text-center">Direct Messages</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">

                <div className=" mx-3 d-flex justify-content-end">

                    <button type="button" className="btn Inv_CancelButton btn-scondary-custom common-btn-class" id="Cancelbtnclick" hidden={true} onClick={cancelbtn}>Cancel Inventory</button>
                </div>

            </footer>
            {/* Modal starts */}
            <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="inventory-title font-22 font-bold">Confirmation</h5>
                            <button type="button" className="btn-close " data-bs-dismiss="modal" onClick={closecancelpopup} aria-label="Close" />
                        </div>
                        <div className="modal-body pt-1">
                            <div className="col-md-12 mb-4">
                                <div className="row">
                                    <div className="w-100 float-start">
                                        <label className="form-label popup-label font-14 font-regular">Are you sure you want to cancel the inventory ? Please
                                            provide the reason for cancelation <span className="manditory-flied">*</span></label>
                                        <textarea className="custom-form-control form-control text-area body-color" onChange={(e) => cancelMessage(e)} rows={4} id="notes-0" defaultValue={""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mb-4">
                            <button type="button" className="btn Inv_CancelPopUpButton me-2 font-16 font-semibold float-start ps-4 pe-4  btn-cancel-border btn-scondary-custom common-btn-class Inv_CancelButton" onClick={cancelclearbtn} >Cancel</button>
                            <button type="button" className="btn btn-prime btn-primary-theme font-16 font-semibold float-start me-3 ps-4 pe-4 common-btn-class" id="cancelsaveid" onClick={cancelsavebtn}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal ends */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                                <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            {/* <!--Alert pop-up--> */}
            <span><ReactTooltip /></span>
        </div>

    );
}

