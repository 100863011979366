import { useState, useEffect } from "react";
import { gridLoad } from "../../Services/ProfileServices";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";

export default function SuccessPage(LicenseType: any) {
    
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let IsInitialLogin: any;
    
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        IsInitialLogin = objClient.IsInitialLogin;
        SessionEpiredDateTime = objClient.CreatedAt;

        
    }

    let formObj = { AvailableChannelLicense: 0, PurchasedChannelMigrationLicense: 0, AvaliableGroupChatLicense: 0, PurchasedGroupChatMigrationLicense: 0 }


    let [formState, setFormState] = useState(formObj);
    // const [clientID, setclientID] = useState(1);
    const [tabType, setTabType] = useState("AccountInfo")
    let [alertMessage, setAlertMessage] = useState("")

    useEffect(() => {
        resetSessionTimer();
        loadAccountInfo()
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])
    let pageType: any;

    
    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion


    const loadAccountInfo = async () => {
        try {
            if (clientID != undefined && clientToken != undefined) {
                const sucessURL = new URLSearchParams(window.location.search);
                const paymentIntent = sucessURL.get("payment_intent");
                const status = sucessURL.get("redirect_status");
                const licenseType = sucessURL.get("LicenseType");
                const playType = sucessURL.get("Plantype");


                pageType = sucessURL.get("PageType")
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/upgradeLicense/storeLicenseCount`,
                    data: {
                        "clientID": clientID,
                        "LicenseType": `${licenseType}`,
                        "paymentIntent": `${paymentIntent}`,
                        "status": `${status}`,
                        "PlanType":playType,
                        "userCount":""
                    },
                })
                let loader = document.getElementById("loader");
                loader?.removeAttribute('hidden');
                let data: any = await gridLoad(config);
                
                let popup: any;
                loader?.setAttribute('hidden', 'hidden');
                if (data.data.statusCode != 200) {
                    {
                        IsInitialLogin == true ?
                            setAlertMessage("Migration Environment Set Up is Failed.Please try Again Later!")
                            :
                            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    }
                    popup = document.getElementById("alertPopUp")
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    if (data.data.statusCode == 200 && data.data.data.clientToken == null && data.data.data.clientToken == "") {
                        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                        popup = document.getElementById("alertPopUp")
                    }
                    else {
                        //#region Update and Frame Client Session
                        localStorage.removeItem("cid_t");
                        let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                        var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                        let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                        localStorage.setItem("cid_t", enPass);
                        //#endregion
                        popup = document.getElementById("successPopUp")
                    }
                }
                //#region Show PopUp
                loader?.setAttribute('hidden', 'hidden');
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                //#endregion
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }


    const popUpOkClick = (type: string) => {
        try {
            if (isSessionEpired === false) {

            //#region Hide PopUp
            let closePopUp = document.getElementById("fadeDiv");
            closePopUp?.setAttribute("style", "display:none");
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("successPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            if (type == "alert") {
                if (pageType == 'ChoosePlan') {
                    window.location.href = '/ChoosePlan'
                }
                else {
                    window.location.href = "/UpgradePlan";
                }
            }
            else {
                window.location.href = "/Settings";
            }
            //#endregion
        }
        else {
            window.location.pathname = "/Login";
        
        }

     } catch (error) {
        }
    }

    return (
        <div className="container-fluid">
            <div className="Loader-Content-position " id='loader' hidden={true}>
                <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                    <span className="visually-hidden"></span>
                </div>
                {IsInitialLogin == true ?
                    <p className="aligncenter font-22">Your payment was successful, Please wait while we set up your Migration Environment...</p>
                    :
                    <p className="aligncenter font-22">Your payment was successful, Please wait while we update your Migration Environment...</p>
                }
            </div>
            <div id="fadeDiv" className="card-modal-backdrop fade show" hidden></div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="successPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">{IsInitialLogin == true ? 'Migration Environment Set Up is completed!' : 'Process Completed Sucessfully'}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
        </div>


    )
}
