import { useState, useEffect } from "react";
import { GridLoad } from "../../Services/ChannelMigrationServices";
import constants from '../../Common/Constants';
import $ from 'jquery'
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import ReactTooltip from "react-tooltip";
import { debug } from "console";
import Operation from "antd/lib/transfer/operation";

export default function ManageMigrationGrid() {
    //#region Customization Migration Dates 
    let [migrationFromDate, setMigrationFromDate] = useState("");
    let [migrationToDate, setMigrationToDate] = useState("");
    const [minMigrationFromDate, setMinMigrationFromDate] = useState("2013-01-01");
    let [minMigrationToDate, setMinMigrationToDate] = useState("2013-01-01");
    let [pageIndex, setPageIndex] = useState(1);
    let [maxMigrationFromDate, setMaxMigrationFromDate] = useState(new Date().toISOString().split('T')[0]);
    let [schedulemaxDate, setSchedulemaxDate] = useState(new Date().toISOString().split('T')[0]);
    const [maxMigrationToDate, setMaxMigrationToDate] = useState(new Date().toISOString().split('T')[0]);
    const [datePopUp, setDate] = useState("")
    let [timeVal, setTimeVal] = useState("")
    let selectedCheckbox: any = []
    let [statusText, setStatus] = useState("")
    let [statusCode, setCode] = useState(200)
    let [sortColumn, setsortColumn] = useState("SourceChatName");
    let [sortType, setsortType] = useState("asc");
    //#endregion

    // Variable Declaration for Select All
    const [selectAllCount, setSelectAllCount] = useState<any>(0);
    const [isFullSelect, setIsFullSelect] = useState(false);
    const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
    const [operation, setOperation ] = useState("")
    const [ validTotalCount, setValidTotalCount] = useState(0);
    const [ disabledCount, setDisabledCount] = useState(0);

    //#region [Inventory]
    const [inventorySearchText, setInventorySearchText] = useState("");
    let [inventoryGridData, setInventoryGridData] = useState([]);
    let [inventorySummaryID, setInventorySummaryID] = useState(0);
    let [inventoryName, setInventoryName] = useState("");
    let [loaderContent, setLoaderContent] = useState("")
    //#endregion 
    let migrationGridArray: any[] = [];
    //#region [Migration Summary]
    const [migrationSearchText, setMigrationSearchText] = useState("");
    let [migrationGridData, setMigrationGridData] = useState<any>(migrationGridArray);
    const [migrationSummaryID, setmigrationSummaryID] = useState(0);
    let [migrationStatus, setMigrationStatus] = useState("All");
    const [sourceType, setSourceType] = useState("All");
    const [arrSelectedValues, setArrSelectedValues] = useState<any>([]);

    //#endregion
    let GridLoadarray: any[] = [];
    let [clientID, setClientID] = useState(0);
    const [loaderHide, setLoaderHide] = useState(false);
    //update scheduled date and time
    const [hideEditPopup, sethideEditPopup] = useState("none");
    let [updatedDate, setUpdatedDate] = useState("");
    const [updatedTime, setUpdatedTime] = useState("");
    const [schedulerID, setSchedulerID] = useState(0);
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    const [arrTime, setArrTime] = useState([{ UI: "00:00", value: 0 }, { UI: "01:00", value: 1 }, { UI: "02:00", value: 2 }, { UI: "03:00", value: 3 }, { UI: "04:00", value: 4 }, { UI: "05:00", value: 5 }, { UI: "06:00", value: 6 }, { UI: "07:00", value: 7 }, { UI: "08:00", value: 8 }, { UI: "09:00", value: 9 }, { UI: "10:00", value: 10 },
    { UI: "11:00", value: 11 }, { UI: "12:00", value: 12 }, { UI: "13:00", value: 13 }, { UI: "14:00", value: 14 }, { UI: "15:00", value: 15 }, { UI: "16:00", value: 16 }, { UI: "17:00", value: 17 }, { UI: "18:00", value: 18 }, { UI: "19:00", value: 19 }, { UI: "20:00", value: 20 },
    { UI: "21:00", value: 21 }, { UI: "22:00", value: 22 }, { UI: "23:00", value: 23 }]);


    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientIDValdidate: any;
    let clientToken: any;
    let adminToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientIDValdidate = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }


    const [gridData, setGridData] = useState(GridLoadarray);
    const [sorthide, setsorthide] = useState("");
    let [countPerPage, setCoutPerPage] = useState(10);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    let [planType, setPlanType] = useState('Free');
    let [parallelInstance, setparallelInstance] = useState(1);


    $(function () {

        $('#migrationFromDate').attr('min', minMigrationFromDate);

        $('#migrationToDate').attr('min', minMigrationToDate);

        $('#migrationFromDate').attr('max', maxMigrationFromDate);

        $('#migrationToDate').attr('max', maxMigrationToDate);

    });



    useEffect(() => {
        var now = new Date();

        var today = new Date();

        let date = (today.getDate().toString()).length < 2 ? "0" + (today.getDate().toString()) : (today.getDate().toString());

        let month = (today.getMonth() + 1).toString().length < 2 ? "0" + (today.getMonth() + 1).toString() : (today.getMonth() + 1).toString();

        let newdate = today.getFullYear() + '-' + month + '-' + date;

        setMaxMigrationFromDate(newdate);

        setMaxMigrationToDate(newdate);
        resetSessionTimer();

        clientID = clientIDValdidate
        setClientID(clientID)
        let btnStartMigrationElement = document.getElementById("btnStartMigration")
        btnStartMigrationElement?.setAttribute('disabled', 'disabled');
        let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
        btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.remove("container-bg-withFooter")
        removeHeight.classList.add("channel-container-bg-withFooter")
        removeHeight.classList.remove("container-bg-withoutFooter")
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion

        loadGridPage();
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])


    useEffect(() => {
    // To Handle the Select All through Individual Checkboxes
        let selectAllelement:any = document.getElementById("selectAllCheckBox")
        selectAllelement.disabled = false
        let allGridDataDisabled = false

      if (migrationGridData.length != 0 && migrationGridData != "N" && migrationGridData[0] != "No Records Found") {
        // To get the gridData Count without the Disbled Checkbox
            let tempArr: any[] =[]
            let disabledTempCount = 0
            migrationGridData.forEach((obj:any)=>{
                let element:any = document.getElementById(obj.MigrationSummaryID)
                if(element != "" && element != null && element != undefined  && element.disabled == true){
                    tempArr.push(obj);
                }
            })
            disabledTempCount = migrationGridData.length - tempArr.length
            setDisabledCount(disabledTempCount)

        // Check Whether all the CheckBoxes in the Grid are Disabled
            const allObjectsPresent = migrationGridData.every((obj1: { MigrationSummaryID: any; }) =>
                tempArr.some(obj2 => obj1.MigrationSummaryID === obj2.MigrationSummaryID)
            )
            if(allObjectsPresent){
                allGridDataDisabled = true
            }
            else{
                allGridDataDisabled = false
            }

        if (selectAllCount == 0) {
             // Possible Conditions when the SelectAll is UnChecked 
          if (arrSelectedValues.length == disabledTempCount || arrSelectedValues.length != disabledTempCount) {
                let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
            
          }
          if(validTotalCount !=0 && arrSelectedValues.length == validTotalCount){
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
        }
        if (selectAllCount == 1) {
             // Possible Conditions when the SelectAll is Partial Select
            let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    if(isFullSelect != true){
                        setSelectAllCount(1);
                        selectAllelement.checked ="true";
                        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                    }
                    else{
                        setSelectAllCount(2);
                        setIsFullSelect(true)
                        selectAllelement.checked ="true";
                        selectAllelement.className = "form-check-input me-2 check-alg-2"
                    }
                }
                else{
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
        }
        else{
            if (selectAllCount == 1 && arrSelectedValues.length != validTotalCount) {
                setSelectAllCount(0);
                setIsFullSelect(false)
                selectAllelement.checked = ""
            }
            if (selectAllCount == 1 && arrSelectedValues.length == validTotalCount) {
                setSelectAllCount(2);
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
            
        }
        if (selectAllCount == 2) {
             // Possible Conditions when the SelectAll is Full Select
            if(operation != "search" && operation != "statusFilter"){
                setSelectAllCount(2)
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
            else{
                if (arrSelectedValues.length != validTotalCount) {
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
            }
        }

        // to handle the pageload of grid for Full Select
        if (validTotalCount == disabledTempCount && migrationGridData.length !=0 && arrSelectedValues.length == disabledTempCount) {
            setIsFullSelect(true);
            setSelectAllCount(2)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"

        } 
        handleCheckedItems()
      }
      if(migrationGridData == "N" || migrationGridData[0] == "No Records Found" || allGridDataDisabled == true){
        setSelectAllCount(0);
        setIsFullSelect(false)
        selectAllelement.checked = ""
        selectAllelement.disabled = true
      }

    }, [migrationGridData]);    

    useEffect(() => {
    // To Handle the Select All through Individual Checkboxes
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      let allDataBinded = false
      // Condition to Check whether all the Data From the Backend is Binded in the Grid
      if(migrationGridData.length == totalItemsCount){
        allDataBinded = true
      }
      if (migrationGridData.length != 0) {
        if (selectAllCount == 0) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
          if (arrSelectedValues.length == disabledCount && isFullSelect == false && allDataBinded == false) {
            let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                if(isAllSelected){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
          }
          if(arrSelectedValues.length == disabledCount && isFullSelect == true || arrSelectedValues.length == disabledCount && allDataBinded == true){
            setSelectAllCount(2);
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
          if(arrSelectedValues.length != disabledCount && isFullSelect == true){
            let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(2);
                    setIsFullSelect(true)
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
          }
          if(arrSelectedValues.length != disabledCount && isFullSelect == false){
            let isAllSelected = false
            let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
          }
        }
        if (selectAllCount == 1) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select
          if (arrSelectedValues.length != disabledCount) {
                let isAllSelected = false
                let tempCheckArr:any = []
                
                migrationGridData.forEach((obj:any)=>{
                    if(arrSelectedValues.some((obj1:any)=>{
                        if(JSON.stringify(obj1) == obj.MigrationSummaryID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length == disabledCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
                else{
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
            
            }
        }
        if (selectAllCount == 2) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
          if (arrSelectedValues.length != validTotalCount) {
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
          }
        }
      }

        handleCheckedItems();
    }, [arrSelectedValues]);

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            statusText = "Your session has expired. Please log in again to continue using the application."
            setStatus(statusText)

            //#region Show PopUp
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);

            const element: any = document.getElementById("cardDetails");
            if (element.hasAttribute("aria-modal")) {
                document.getElementById("cardDetails")?.setAttribute("class", "modal fade");
                document.getElementById("cardDetails")?.setAttribute("style", "display:none");
                document.getElementById("cardDetails")?.setAttribute("aria-hidden", "true");
                document.getElementById("cardDetails")?.removeAttribute("aria-modal");
                document.getElementById("cardDetails")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                document.getElementById('timeDD')?.setAttribute('hidden', 'true')
                document.getElementById("picker")?.setAttribute("value", ``)
            }
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await GridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(SessionEpiredDateTime);
                    //#endregiona
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)

            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)

            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion




    const loadGridPage = async () => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                inventoryGridData = []
                setInventoryGridData(inventoryGridData);
                let loadInventoryChannelData = (
                    {
                        method: "POST",
                        url: constants.URL.getChannelInventory,
                        token: clientToken,
                        data:
                        {
                            "clientID": clientID,
                            "SearchText": inventorySearchText,
                            "pageIndex": pageIndex,
                            "inventorySummaryID": null,
                            "MigrationType": "Standard"
                        }
                    }
                );
                let x = document.getElementById("loaderWithoutContent")
                x?.removeAttribute("hidden")
                $('input[name="check-box"]').prop('checked', false);
                var data: any = await GridLoad(loadInventoryChannelData);
                statusCode = data.data.statusCode
                setCode(statusCode)
                if (data.data.statusCode == 200) {
                    setPlanType(data.data.data[1][1][0].LicenseType);
                    setparallelInstance(data.data.data[1][0][0].ParallelMigrationCount);
                    x?.setAttribute("hidden", "true")
                    setLoaderHide(false)
                    inventoryGridData = data.data.data[0] == "No Records Found" ? ["No Records Found"] : data.data.data[0][0]
                    setInventoryGridData(inventoryGridData);
                    migrationGridData = data.data.data[0] == "No Records Found" ? ["No Records Found"] : data.data.data[0][1]
                    inventorySummaryID = data.data.data[0] == "No Records Found" ? 0 : parseInt(data.data.data[0][0][0].InventorySummaryID)
                    setInventorySummaryID(inventorySummaryID)
                    // setMigrationGridData(migrationGridData);
                    setTotalItemsCount(data.data.data[0] == "No Records Found" ? 0 : data.data.data[0][2][0].gridCount)

                    // Setting the State of the ValidTotalCount excluding the Disabled Checkboxes
                    setValidTotalCount(data.data.data[0][3][0].SelectedTotalValue)

                    setMigrationGridData(migrationGridData);


                    inventoryName = ""
                    setInventoryName(inventoryName)
                    inventoryName = data.data.data[0] == "No Records Found" ? "NA" : data.data.data[0][0][0].InventoryName
                    setInventoryName(inventoryName)
                    let btnStartMigrationElement = document.getElementById("btnStartMigration")
                    btnStartMigrationElement?.setAttribute('disabled', 'disabled');
                    let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
                    btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    x?.setAttribute("hidden", "true")
                    enableAlertPopUp(data)
                }


            }
            else {
                localStorage.clear();
            }
        }

        catch (error: any) {
            ////console.log(error);
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp(data)
        }
    }

    const handleMigrationFromDate = (e: any) => {
        try {

            migrationFromDate = e.target.value
            setMigrationFromDate(migrationFromDate);
            minMigrationToDate = e.target.value
            setMinMigrationToDate(minMigrationToDate);
        } catch (error) {
            ////console.log(error);
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")

        }


    }
    const handleErrorLabelPopup = () => {
        try {
            var currentTime = parseInt(new Date().getUTCHours().toString());
            var currentDate = new Date().toISOString().split('T')[0];
            let chosenTime = parseInt((timeVal).substring(0, 2))

            let objCurrentDateCheck = new Date(currentDate);

            let objScheduledDateCheck = new Date(updatedDate);

            let objScheduledDate: any = new Date(updatedDate + "T" + timeVal + "Z");

            let objCurrentDate: any = new Date();

            let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;



            if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
                let x = document.getElementById("errorlabelTime")
                x?.removeAttribute('hidden');
                document.getElementById('saveBtn')?.setAttribute("disabled", "true");
            }
            else {
                document.getElementById('errorlabelTime')?.setAttribute("hidden", "true");
                if (updatedDate != "" && timeVal != "") {
                    document.getElementById('saveBtn')?.removeAttribute("disabled");
                }
            }
        }
        catch (error: any) {
            // ////console.log(error);
        }
    }
    const handleMigrationToDate = (e: any) => {

        migrationToDate = e.target.value
        setMigrationToDate(migrationToDate);
        maxMigrationFromDate = e.target.value
        setMaxMigrationFromDate(maxMigrationFromDate);
    }
    const loadChannelData = async (operation:any) => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                if (!(totalItemsCount == 0)) {
                    let x = document.getElementById("loaderWithoutContent")
                    x?.removeAttribute("hidden")
                    migrationGridData = []
                    setMigrationGridData(migrationGridData);

                    let loadInventoryChannelData = (
                        {
                            method: "POST",
                            url: constants.URL.getChannelData,
                            token: clientToken,
                            data:
                            {
                                "clientID": clientID,
                                "SearchText": migrationSearchText,
                                "pageIndex": pageIndex,
                                "inventorySummaryID": inventorySummaryID,
                                "SortColumn": `${sortColumn}`,
                                "Sorttype": `${sortType}`,
                                "MigrationType": "Standard",
                                "migrationStatus": migrationStatus

                            }
                        }
                    );
                    // $('input[name="check-box"]').prop('checked', false);
                    var data: any = await GridLoad(loadInventoryChannelData);
                    
                    if(operation == "search" || operation == "statusFilter"){
                        setTotalItemsCountFeature(data.data.data[1][0].gridCount)
                    }
                    else{
                        setTotalItemsCount(data.data.data[1][0].gridCount)
                        setValidTotalCount(data.data.data[2][0].SelectedTotalValue)
                    }
                   
                    x?.setAttribute("hidden", "true")

                    statusCode = data.data.statusCode
                    setCode(statusCode)
                    if (data.data.statusCode == 200) {
                        migrationGridData = data.data.data[0]
                        setMigrationGridData(migrationGridData);
                        // let btnStartMigrationElement = document.getElementById("btnStartMigration")
                        // btnStartMigrationElement?.setAttribute('disabled', 'disabled');
                        // let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
                        // btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');
                        setCoutPerPage(migrationGridData.length)

                    }
                    else if (data.data.data == "Invalid authentication token provided") {
                        let x = document.getElementById("loaderWithoutContent")
                        x?.setAttribute("hidden", "true")
                        enableSessionExpiryPopup();
                    }
                    else {
                        enableAlertPopUp(data)
                        let x = document.getElementById("loaderWithoutContent")
                        x?.setAttribute("hidden", "true")

                    }

                }
            }
            else {
                localStorage.clear();
            }
        } catch (error) {
            enableAlertPopUp(data)
            let x = document.getElementById("loaderWithoutContent")
            x?.setAttribute("hidden", "true")
            ////console.log(error);

        }


    }
    const disablePopUp = () => {
        if (isSessionEpired === false) {

            deleteChild()
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:none");
            document.getElementById("failedPopUp")?.setAttribute("aria-hidden", "true");
            document.getElementById("failedPopUp")?.removeAttribute("aria-modal");
            document.getElementById("failedPopUp")?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            $('input[name="checkbox"]').prop('checked', false);
            setArrSelectedValues([]);
            loadChannelData(operation)
        }
        else {
            window.location.pathname = "/Login";
        }


    }

    const handleLoadMore = async () => {
        try {

            let oindexPerPage = pageIndex + 1;
            let ocountPerPage = countPerPage + 10;
            pageIndex = oindexPerPage
            countPerPage = ocountPerPage
            setPageIndex(pageIndex);
            setCoutPerPage(countPerPage);
            await loadChannelData(operation)

            // To handle the Select All When Load More is Clicked
            let selectAllelement:any = document.getElementById("selectAllCheckBox")
            if (selectAllCount == 1) {
                    if(isFullSelect == true){
                        setSelectAllCount(2);
                        selectAllelement.checked = "true";
                        selectAllelement.className = "form-check-input me-2 check-alg-2"
                    }
                    else{
                        setSelectAllCount(0);
                        selectAllelement.checked = "";
                    }
                } 
            else if(selectAllCount == 2) {
                    setSelectAllCount(2);
                    selectAllelement.checked = "true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    const gridInventoryBodyData = () => {

        try {
            if (!(typeof inventoryGridData[0] == "string" || statusCode != 200 || inventoryGridData.length == undefined || inventoryGridData.length == 0)) {
                return inventoryGridData.map((obj: any, index:any) => {
                    return (
                        <a onClick={() => inventoryNameOnClick(JSON.stringify(obj))} className={obj.InventorySummaryID == inventorySummaryID ? "list-group-item list-group-item-action anchorAsPointer border-0 border-left border-bottom mb-2" : "list-group-item list-group-item-action anchorAsPointer border-0 border-bottom mb-2"}>
                            <p className="mb-1 font-14 font-semibold">
                                {obj.InventoryName.length > 32 ?
                                    <span data-tip={obj.InventoryName} className="font-regular font-14">{obj.InventoryName.substring(0, 32)}...
                                        {/* <ReactTooltip /> */}
                                    </span> :
                                    <span data-tip="" className="font-regular font-14">{obj.InventoryName}</span>
                                }
                            </p>
                            {

                                (obj.InventoryFromDate !== null || obj.InventoryToDate !== null) ?

                                    <p className="mb-1 font-12 font-semibold">Duration : <span className="font-regular">({formatFromToDate(obj.InventoryFromDate)}-{formatFromToDate(obj.InventoryToDate)})</span> </p>

                                    :

                                    ""

                            }
                        </a>
                    )
                })


            }
            else if (inventoryGridData[0] == undefined) {

                return (<tr> <td className="aligncenter" colSpan={5}></td>  </tr>)

            }
            else {
                return (

                    <tr>

                        <td className="center text-center" colSpan={5}><img src="/Images/NoRecordsFound.svg"></img>
                            <p className="mt-2">No records found</p></td>

                    </tr>)
            }

        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    const formatFromToDate = (date: any) => {

        try {



            let objDate = new Date(date);

            if (date != null && !isNaN(objDate.getTime())) {

                let strDate = objDate.getDate().toString();

                strDate = strDate[1] ? strDate : "0" + strDate[0];

                let strMonth = (objDate.getMonth() + 1).toString();

                strMonth = strMonth[1] ? strMonth : "0" + strMonth[0];

                //let convertedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

                let convertedDate = `${strMonth}/${strDate}/${objDate.getFullYear()}`;

                return convertedDate;

            }

            else {

                return "NA"

            }

        } catch (error) {

            throw (error);

        }

    }
    const deleteQueueStatus = async (obj: any) => {
        try {
   
            if (clientIDValdidate != undefined && clientToken != undefined) {
                let deleteQueuedGroupChatStatus = (
                    {
                        method: "POST",
                        url: constants.URL.deleteQueuedChannelDataURL,
                        token: clientToken,
                        data:
                        {
                            "clientID": clientID,
                            "migrationSummaryID": parseInt(obj.MigrationSummaryID),
                            "inventorySummaryID": inventorySummaryID,
                            "status":obj.MigrationStatus
                        }
                    }
                );
                var data: any = await GridLoad(deleteQueuedGroupChatStatus);

                if (data.data.statusCode == 200) {
                    loadChannelData(operation)
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    let x = document.getElementById("loaderWithoutContent")
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    enableAlertPopUp(data)
                    let x = document.getElementById("loaderWithoutContent")
                    x?.setAttribute("hidden", "true")

                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error) {
            enableAlertPopUp(data)
            let x = document.getElementById("loaderWithoutContent")
            x?.setAttribute("hidden", "true")


        }
    }
    const keypressMigration = async (e: any) => {
        try {
            if (e.keyCode == 13) {
                let tempOperation = ""
                if(migrationSearchText != ""){
                    tempOperation = "search"
                }
                setMigrationSearchText(e.target.value);
                setOperation(tempOperation)
                await loadChannelData(tempOperation);
            }
        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    const enableAlertPopUp = (data: any) => {
        try {
            $('input[name="checkbox"]').prop('checked', false);
            setArrSelectedValues([]);
            statusText = data.data.data == undefined || data.data.data == 'License Count Exceeded. Kindly Upgrade Your License to proceed the migration.' || data.data.data == 'No available service apps to start the migration' || data.data.data == 'No available service accounts to start the migration' ? data.data.data : 'Something went wrong! Please try again or reach AVAMIGRATRON Support'
            setStatus(statusText)
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        } catch (error) {
            ////console.log(error);

        }
    }

    // To handle the Checking of the Selected CheckBoxes
   const handleCheckedItems = async () => {
    if (arrSelectedValues.length != 0) {
      migrationGridData.forEach((obj:any) => {
        for (let i = 0; i < arrSelectedValues.length + 1; i++) {
          if (arrSelectedValues[i] == parseInt(obj.MigrationSummaryID)){
            let element: any = document.getElementById(obj.MigrationSummaryID);
            if (element != "" && element != null && element != undefined && element.disabled == false) {
              element.checked = "true"
              break;
            }
          }
        }
      });
    }
  };

    const handleSelectAll = async ()=>{
    try{
        let count: any = undefined;
        // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
        if (totalItemsCount == migrationGridData.length) {
        setIsFullSelect(true);
        count = selectAllCount + 2;
        if (count > 2) {
          count = 0;
        }
      } else {
        count = selectAllCount + 1;
        if (count > 2) {
          count = 0;
        }
      }

      // to handle the Select All CheckBox CSS
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if(count==2){
        selectAllelement.checked = "true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      else if(count == 1){
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
      }
      else{
        selectAllelement.checked = "";
      }

      setSelectAllCount(count);

       // To handle the SelectAll when the Checkbox is Unchecked
      if (count == 0) {

        setIsFullSelect(false)

        arrSelectedValues.forEach((obj: any) => {
          const element: any = document.getElementById(obj);
          if (element != "" && element != null && element != undefined) {
            element.checked = "";
          }
        });
        let tempArr: any = [];
        setArrSelectedValues(tempArr);
        selectedCheckbox = tempArr
      }

      // To handle the SelectAll when the Checkbox is PartialSelect
      if (count == 1) {
        let tempArr: any = arrSelectedValues
        migrationGridData.forEach((obj: any) => {
          const element: any = document.getElementById(obj.MigrationSummaryID);
          if (
            element != "" &&
            element != null &&
            element != undefined &&
            element.checked != true &&
            element.disabled == false
          ) {
            element.checked = "true";
            selectedCheckbox.push({
              "migrationSummaryID": parseInt(obj.MigrationSummaryID),
              "inventorySummaryID": inventorySummaryID  
            })
            tempArr.push(parseInt(obj.MigrationSummaryID))
          }

        });
        setArrSelectedValues([...tempArr]);
        // To find whether must be Be in full Select on individual CheckBox Check
        if(tempArr.length == validTotalCount){
            setIsFullSelect(true)
        }
      }

      // To handle the SelectAll when the Checkbox is Full Select
      if(count == 2){

        if (clientIDValdidate != undefined && clientToken != undefined) {
            if (!(totalItemsCount == 0)) {
                setIsFullSelect(true)
                let x = document.getElementById("loaderWithoutContent")
                x?.removeAttribute("hidden")
                let pageIndex = totalItemsCount;
                let loadInventoryChannelData = (
                    {
                        method: "POST",
                        url: constants.URL.getChannelData,
                        token: clientToken,
                        data:
                        {
                            "clientID": clientID,
                            "SearchText": "",
                            "pageIndex": pageIndex,
                            "inventorySummaryID": inventorySummaryID,
                            "SortColumn": `${sortColumn}`,
                            "Sorttype": `${sortType}`,
                            "MigrationType": "Standard",
                            "migrationStatus": "All"

                        }
                    }
                );
                var data: any = await GridLoad(loadInventoryChannelData);
                x?.setAttribute("hidden", "true")

                statusCode = data.data.statusCode
                setCode(statusCode)
                if (data.data.statusCode == 200) {
                    
                    let tempArr: any = [];
                    selectedCheckbox = [];

                    data.data.data[0].forEach((obj: any) => {
                        if( obj.MigrationStatus != "Completed" &&
                            obj.MigrationStatus != "In Progress" &&
                            obj.MigrationStatus != "Scheduled" &&
                            obj.MigrationStatus != "Queue" &&
                            obj.AllStatus != 1){

                            selectedCheckbox.push({
                                "migrationSummaryID": parseInt(obj.MigrationSummaryID),
                                "inventorySummaryID": inventorySummaryID  
                              })
                              
                              tempArr.push(parseInt(obj.MigrationSummaryID))

                        }
                    });

                    setValidTotalCount(tempArr.length)
                    setArrSelectedValues([...tempArr])
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    let x = document.getElementById("loaderWithoutContent")
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    enableAlertPopUp(data)
                    let x = document.getElementById("loaderWithoutContent")
                    x?.setAttribute("hidden", "true")

                }
            }
        }
        else {
            localStorage.clear();
        }

      }

     }
    catch(error){
        // console.log(error);
     }


    }

    const handleCheckbox = async (e: any) => {
        try {
            let tempArr:any = arrSelectedValues
            if (e.target.checked == true) {
                selectedCheckbox.push({
                    "migrationSummaryID": parseInt(e.target.id),
                    "inventorySummaryID": inventorySummaryID
                })

                tempArr = [...arrSelectedValues, parseInt(e.target.id)]

                setArrSelectedValues([...tempArr]);
            }
            else {
                tempArr = [...arrSelectedValues];
                let ind = tempArr.findIndex((id: any) => id == e.target.id)
                tempArr.splice(ind, 1);
                setArrSelectedValues([...tempArr]);
                if (tempArr.length == 0) {
                    let btnStartMigrationElement = document.getElementById("btnStartMigration")
                    btnStartMigrationElement?.setAttribute('disabled', 'disabled');
                    let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
                    btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');
                }
            }
            // To find whether must be Be in full Select on individual CheckBox Check
            if(tempArr.length == validTotalCount){
                setIsFullSelect(true)
            }
        }
        catch (error: any) {
            ////console.log("error", error);
        }
    }
    const handleInventorySearch = async (e: any) => {
        try {

            ////console.log(e.target.value)
            setInventorySearchText(e.target.value);
        }
        catch (error) {
            ////console.log(error);
        }
    }
    const keypressInventory = (e: any) => {
        try {
            if (e.keyCode == 13) {
                setInventorySearchText(e.target.value);
                loadGridPage();
            }
        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    const startMigration = async (e:any) => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {

                let x = document.getElementById("loaderWithContent")
                x?.removeAttribute("hidden")
                loaderContent = "Please wait. We are initiating migration."
                setLoaderContent(loaderContent)
                let selectedChats: { "migrationSummaryID": any, "inventorySummaryID": any }[] = [];
                let tempObj: any = {};
            

                if(e.target.value=='resume'){
                    tempObj["migrationSummaryID"] = Number(e.target.id);
                    tempObj["inventorySummaryID"] = inventorySummaryID;
                    selectedChats.push(tempObj);
                    tempObj = {};
                  }

              else{
            
                for (let i = 0; i < arrSelectedValues.length; i++) {
                    tempObj["migrationSummaryID"] = arrSelectedValues[i];
                    tempObj["inventorySummaryID"] = inventorySummaryID;
                    selectedChats.push(tempObj);
                    tempObj = {};
                }
                var now = new Date();
                if (migrationFromDate != "") {

                    let objFromDate = new Date(migrationFromDate.split('T')[0] + "T00:00:00.000Z");

                    migrationFromDate = new Date(objFromDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();

                }

                if (migrationToDate != "") {

                    let objToDate = new Date(migrationToDate.split('T')[0] + "T23:59:59.999Z");

                    migrationToDate = new Date(objToDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();

                }

            }
                let startMigration = (
                    {
                        method: "POST",
                        url: constants.URL.startChannelMigration,
                        token: clientToken,
                        data:
                            [
                                {
                                    "isScheduleMigration": false,
                                    "isInprogress": false,
                                    "clientID": clientID,
                                    "migrationType": "Standard",
                                    "migratedDateTime": new Date().toISOString(),
                                    "fromDate": migrationFromDate,
                                    "toDate": migrationToDate,
                                    "jsonResponse": selectedChats
                                }
                            ]
                    });

                    debugger;
                if(adminLocal !=undefined && adminLocal !== null){
                    (startMigration.data[0] as any).token = adminToken
                } 
                debugger;
                var data: any = await GridLoad(startMigration);
                if (data.data.statusCode == 200) {
                    setArrSelectedValues([]);
                    let x = document.getElementById("loaderWithContent")
                    x?.setAttribute("hidden", "true")
                    loadChannelData(operation);
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    setArrSelectedValues([]);
                    let x = document.getElementById("loaderWithContent")
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {

                    if (data.data.data != "License Count Exceeded. Kindly Upgrade Your License to proceed the migration." && data.data.data != "Invalid MigrationSummaryID/InventorySummaryID provided" && data.data.data != "Something went wrong! Please try again or reach AVAMIGRATRON Support." && data.data.data != "Something went wrong please reach AVAMIGRATRON Team") {
                        ////console.log("if block");
                        (objClient.isUserBasedLicense)
                            ?
                            createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Channel Provided", "Already Migrated Channel Provided", "Already Queued Channel Provided", "Channel which is In Progress Provided", "Licensed User not present in the channel"])
                            :
                            createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Channel Provided", "Already Migrated Channel Provided", "Already Queued Channel Provided", "Channel which is In Progress Provided"]);

                    }
                    else {

                        statusText = data.data.data == "License Count Exceeded. Kindly Upgrade Your License to proceed the migration." ? "License Count Exceeded.  Kindly Upgrade Your License to proceed the migration." : "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
                        setStatus(statusText)
                        enableAlertPopUp(data)

                    }
                    setArrSelectedValues([]);
                    let x = document.getElementById("loaderWithContent")
                    x?.setAttribute("hidden", "true")
                    $('input[name="checkbox"]').prop('checked', false);

                }
                let btnStartMigrationElement = document.getElementById("btnStartMigration")
                btnStartMigrationElement?.setAttribute('disabled', 'disabled');
                let btnScheduleMigrationElement = document.getElementById("btnScheduleMigration")
                btnScheduleMigrationElement?.setAttribute('disabled', 'disabled');

            }
            else {
                localStorage.clear();
            }
        }

        catch (error: any) {
            enableAlertPopUp(data)
            let x = document.getElementById("loader")
            x?.setAttribute("hidden", "true")
            setArrSelectedValues([]);
            $('input[name="checkbox"]').prop('checked', false);


            ////console.log(error);
        }
    }

    const handleMigrationSearch = async (e: any) => {
        try {
            setMigrationSearchText(e.target.value);
        }
        catch (error) {
            ////console.log(error);
        }
    }
    const gridBodyData = () => {

        try {
            if (!(typeof migrationGridData[0] == "string" || statusCode != 200 || migrationGridData.length == undefined || migrationGridData.length == 0)) {

                return migrationGridData.map((obj: any, index:any) => {
                    let i: number = index
                    let trclassName = i % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";

                    return (
                        <tr className={trclassName} key={obj.MigrationSummaryID} >
                            {
                                (obj.SourceChatName).length > 22 ?
                                    <td className=" py-3 tabel-data-font-level1" >
                                        <input className="form-check-input me-2" name="check-box" id={obj.MigrationSummaryID} onChange={(e) => handleCheckbox(e)} disabled={((obj.MigrationStatus === null && obj.AllStatus === 0) || (obj.MigrationStatus === "Warning" && obj.AllStatus === 0)) ? false : true} type="checkbox" />
                                        <span data-tip={obj.SourceChatName}>{obj.SourceChatName.substring(0, 22)}...</span>
                                        {/* <ReactTooltip /> */}
                                    </td> :
                                    <td data-tip="" className=" py-3 tabel-data-font-level1"><input className="form-check-input me-2" name="check-box" id={obj.MigrationSummaryID} onChange={(e) => handleCheckbox(e)} disabled={((obj.MigrationStatus === null && obj.AllStatus === 0) || (obj.MigrationStatus === "Warning" && obj.AllStatus === 0)) ? false : true} type="checkbox" />{obj.SourceChatName}</td>
                            }


                           {
                                (obj.TargetTeamName).length > 22 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetTeamName} >
                                        {(obj.TargetTeamName).substring(0, 22)}...
                                        {/* <ReactTooltip /> */}
                                    </td> :
                                    <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.TargetTeamName}</td>
                            }
                            {
                                (obj.TargetChannelName).length > 22 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetChannelName} >
                                        {(obj.TargetChannelName).substring(0, 22)}...
                                        {/* <ReactTooltip /> */}
                                    </td> :
                                    <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.TargetChannelName}</td>
                            }

                            {obj.MigrationStatus === 'Queue' ? (
                <td className="py-3 tabel-data-font-level1 text-center"> <span className="queue-status status-block font-14">Queue</span></td>
              ) :
                obj.MigrationStatus === 'In progress' ? (
                  <td className="py-3 tabel-data-font-level1 text-center"> <span className="inprogress-status status-block font-14">In progress</span></td>
                )
                  :
                  obj.MigrationStatus === 'Completed' ? (
                    <td className=" py-3 tabel-data-font-level1 text-center"><span className="completed-status status-block font-14">Completed</span></td>
                  )
                    :
                    obj.MigrationStatus === null || obj.MigrationStatus == 'Yet To Start' ? (
                      <td className=" py-3 tabel-data-font-level1 text-center"><span className="yettostart-status status-block font-14">Yet to Start</span></td>
                    )
                      :
                      obj.MigrationStatus === 'Scheduled' ? (
                        <td className="py-3 tabel-data-font-level1 text-center"><span className="scheduled-status status-block font-14">Scheduled</span></td>
                      )
                        :
                        obj.MigrationStatus === 'Warning' ? (
                          <td className="py-3 tabel-data-font-level1 text-center"><span className="warning-status status-block font-14">Warning</span></td>
                        )
                          :
                          obj.MigrationStatus === 'Cancelled' ? (
                            <td className="py-3 tabel-data-font-level1 text-center"> <span className="yettostart-status status-block font-14">Paused</span></td>
                          )
                            :
                            (
                              <td className="py-3 tabel-data-font-level1 text-center"> <span className="status status-styling font-14 font-semibold">{obj.MigrationStatus}</span></td>
                            )
                            }
                           
                            {obj.MigrationStatus === "Queue" ?
                                <td className=" py-3 tabel-data-font-level1 text-center">
                                <button className="common-btn-class btn-scondary-custom border-0 px-3 py-1 ConMig_Cancel_button_enabled" onClick={() => deleteQueueStatus(obj)}>Cancel</button>
                              </td> 
                              : obj.MigrationStatus === "In progress" ?  
                              <td className=" py-3 tabel-data-font-level1 text-center ">
                                <button className="common-btn-class border-0 px-3 py-1 ConMig_Cancel_button inprogress-status  font-14" onClick={() => deleteQueueStatus(obj)}> Pause</button>
                              </td> 
                              : obj.MigrationStatus === "Cancelled" ? 
                              <td className=" py-3 tabel-data-font-level1 text-center ">
                                <button className="border-0 px-3 py-2 ConMig_Cancel_button yettostart-status font-14" id={obj.MigrationSummaryID} value = 'resume' onClick={(e)=>{startMigration(e)}}>Resume</button>
                              </td>
                              :
                              <td className=" py-3 tabel-data-font-level1 text-center ">
                                <button className="common-btn-class border-0 px-3 py-1 ConMig_Cancel_button_disabled" disabled>Cancel</button>
                              </td> }
        
                            <span>

                                <ReactTooltip />

                            </span>

                        </tr>)
                })


            }
            else if (migrationGridData[0] == undefined) {

                return (<tr> <td className="aligncenter" colSpan={6}></td></tr>)

            }

            else {

                return (

                    <tr>
                        <td className="aligncenter" colSpan={5}><img src="/Images/NoRecordsFound.svg"></img>
                            <p className="mt-2">No records found</p></td>

                    </tr>)
            }


        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    const inventoryNameOnClick = async (obj: any) => {

        try {
            let objInventoryName = JSON.parse(obj)
            inventorySummaryID = parseInt(objInventoryName.InventorySummaryID)
            setInventorySummaryID(inventorySummaryID);
            ////console.log(inventorySummaryID)
            setInventoryName(inventoryName)
            inventoryName = objInventoryName.InventoryName
            setInventoryName(inventoryName)
            
            // To reset the Select All on the Inventory Click
            let selectAllelement:any = document.getElementById("selectAllCheckBox")
            setArrSelectedValues([])
            selectedCheckbox = []
            setSelectAllCount(0);
            selectAllelement.checked = ""
            setIsFullSelect(false)

            loadChannelData(operation)

        }
        catch (error: any) {
            ////console.log(error);
        }
    }
    function deleteChild() {

        try {
            var e: any = document.getElementById("dynamicData");
            var child = e.lastElementChild;
            while (child) {
                e.removeChild(child);
                child = e.lastElementChild;
            }
        } catch (error) {
            ////console.log(error);

        }

    }

    const enableReschedulePopup = async () => {


        try {
            const element: any = document.getElementById("cardDetails");
            setDate("")
            setTimeVal("")
            document.getElementById('picker')?.setAttribute("value", "")
            document.getElementById("errorlabelTime")?.setAttribute("hidden", "true")
            document.getElementById('saveBtn')?.setAttribute("disabled", "true");
            if (element.hasAttribute("aria-modal")) {
                //#region Hide PopUp
                document.getElementById("cardDetails")?.setAttribute("class", "modal fade");
                document.getElementById("cardDetails")?.setAttribute("style", "display:none");
                document.getElementById("cardDetails")?.setAttribute("aria-hidden", "true");
                document.getElementById("cardDetails")?.removeAttribute("aria-modal");
                document.getElementById("cardDetails")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                document.getElementById('timeDD')?.setAttribute('hidden', 'true')
                document.getElementById("picker")?.setAttribute("value", ``)

                updatedDate = ""
                if (timeVal == "" || timeVal == null) {
                    document.getElementById("saveBtn")?.setAttribute("disabled", "true")
                }
                else {
                    document.getElementById("saveBtn")?.removeAttribute("disabled")
                }
                //#endregion
            }
            else {

                document.getElementById("cardDetails")?.removeAttribute("aria-hidden");
                document.getElementById("cardDetails")?.setAttribute("aria-modal", "true");
                document.getElementById("cardDetails")?.setAttribute("role", "dialog");
                document.getElementById("cardDetails")?.setAttribute("class", "modal fade show");
                document.getElementById("cardDetails")?.setAttribute("style", "display:block");
                document.getElementById('fadeDiv')?.removeAttribute("hidden")
                ////console.log(document.getElementById('fadeDiv'));

                document.getElementById("picker")?.setAttribute("value", ``)



            }

        }
        catch (error: any) {
            ////console.log(error)
        }
    }
    const handleDate = (e: any) => {
        try {

            updatedDate = e.target.value
            setUpdatedDate(updatedDate)
            setDate(e.target.value)
            handleErrorLabelPopup()
        }
        catch (error: any) {
            ////console.log(error);
        }
    }

    const handleTimeToggle = () => {
        try {
            document.getElementById('timeDD')?.removeAttribute('hidden')
        } catch (error) {
            ////console.log(error);

        }
    }

    const createDynamicListItem = async (popupText: any, arrReasons: any) => {
        try {

            setStatus(statusText)
            let element = document.getElementById("dynamicData");
            let ulTag = document.createElement("ol");
            ulTag.classList.add('listedAlertPopupBG');
            arrReasons.map((reason: any) => {
                let liTag = document.createElement("li");
                liTag.style.textAlign = "left";
                let liText = document.createTextNode(reason);
                liTag.appendChild(liText);
                ulTag.appendChild(liTag);
            })
            element?.appendChild(ulTag);
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        } catch (error) {
            throw error
        }
    }

    const saveScheduleClick = async () => {
        try {

            if (clientIDValdidate != undefined && clientToken != undefined) {
                let objScheduledDate: any = new Date(updatedDate + "T" + timeVal + "Z");

                let objCurrentDate: any = new Date();

                let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;

                var currentTime = parseInt(new Date().getUTCHours().toString());

                let chosenTime = parseInt((timeVal).substring(0, 2));

                var currentDate: any = new Date().toISOString().split('T')[0];

                let objCurrentDateCheck = new Date(currentDate);

                let objScheduledDateCheck = new Date(updatedDate);



                if (!(objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) && !(objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
                    
                    let scheduledChats: { "MigrationSummaryID": any, "InventorySummaryID": any, "MigrationFromDate": any, "MigrationToDate": any }[] = [];
                    let tempObj: any = {};
                    for (let i = 0; i < arrSelectedValues.length; i++) {
                        var now = new Date();

                        if (migrationFromDate != "") {

                            let objFromDate = new Date(migrationFromDate.split('T')[0] + "T00:00:00.000Z");

                            migrationFromDate = new Date(objFromDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();

                        }

                        if (migrationToDate != "") {

                            let objToDate = new Date(migrationToDate.split('T')[0] + "T23:59:59.999Z");

                            migrationToDate = new Date(objToDate.getTime() + now.getTimezoneOffset() * 60000).toISOString();

                        }
                        tempObj["MigrationSummaryID"] = arrSelectedValues[i];
                        tempObj["InventorySummaryID"] = inventorySummaryID;
                        tempObj["MigrationFromDate"] = migrationFromDate === "" ? null : migrationFromDate;
                        tempObj["MigrationToDate"] = migrationToDate === "" ? null : migrationToDate;
                        scheduledChats.push(tempObj);
                        tempObj = {};
                    }

                    let saveScheduledData = (
                        {
                            method: "POST",
                            url: constants.URL.saveScheduleChannelData,
                            token: clientToken,
                            data:
                            {
                                "clientID": clientID,
                                "createdAt": (new Date()).toISOString().replace("T", " ").replace("Z", ""),
                                "scheduledAt": updatedDate + " " + timeVal,
                                "arrParam": scheduledChats,
                                "type": "Standard"
                            }
                        }
                    );
                    if(adminLocal !=undefined && adminLocal !== null){
                        (saveScheduledData.data as any).token = adminToken
                    }
                    enableReschedulePopup();
                    document.getElementById('loaderWithContent')?.removeAttribute('hidden')
                    loaderContent = "Please wait. We are scheduling your migration."
                    setLoaderContent(loaderContent)
                    var data: any = await GridLoad(saveScheduledData);

                    if (data.data.statusCode == 200) {
                        setArrSelectedValues([]);
                        let x = document.getElementById("loaderWithContent")
                        x?.setAttribute("hidden", "true")
                        loadChannelData(operation);
                    }
                    else if (data.data.data == "Invalid authentication token provided") {
                        let x = document.getElementById("loader")
                        x?.setAttribute("hidden", "true")
                        enableSessionExpiryPopup();
                    }
                    else {
                        if (data.data.data != "License Count Exceeded. Kindly Upgrade Your License to proceed the migration." && data.data.data != "Invalid MigrationSummaryID/InventorySummaryID provided" && data.data.data != "Something went wrong! Please try again or reach AVAMIGRATRON Support." && data.data.data != "Something went wrong please reach AVAMIGRATRON Team") {

                            (objClient.isUserBasedLicense)
                                ?
                                createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Channel Provided", "Already Migrated Channel Provided", "Already Queued Channel Provided", "Channel which is In Progress Provided", "Licensed User not present in the channel"])
                                :
                                createDynamicListItem("Failed to Start Migration due to the below reasons.", ["Invalid From Date & To Date Provided.", "Already Scheduled Channel Provided", "Already Migrated Channel Provided", "Already Queued Channel Provided", "Channel which is In Progress Provided"]);

                        }

                        else {

                            statusText = data.data.data == "License Count Exceeded. Kindly Upgrade Your License to proceed the migration." ? "License Count Exceeded.  Kindly Upgrade Your License to proceed the migration." : "Something went wrong! Please try again or reach AVAMIGRATRON Support.";


                            setStatus(statusText)
                            enableAlertPopUp(data)

                        }
                        let x = document.getElementById("loader")
                        x?.setAttribute("hidden", "true")
                        let Y = document.getElementById("loaderWithContent")
                        Y?.setAttribute("hidden", "true")
                    }


                }
                else {
                    let x = document.getElementById("errorlabelTime")
                    x?.removeAttribute('hidden');
                    document.getElementById('saveBtn')?.setAttribute("disabled", "true");
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            enableReschedulePopup();
            enableAlertPopUp(data)
            let x = document.getElementById("loader")
            x?.setAttribute("hidden", "true")

            ////console.log(error);
        }
    }
    const handleTimePicker = (e: any) => {
        try {

            ////console.log(e.target.value);
            let x = document.getElementById("picker")
            document.getElementById('timeDD')?.setAttribute('hidden', 'true')
            x?.setAttribute("value", `${e.target.id}`)
            setUpdatedTime(e.target.id + "")
            timeVal = e.target.id
            setTimeVal(timeVal);
            handleErrorLabelPopup()
        }
        catch (error: any) {
        }
    }

    const sortClicked = (e: any) => {

        try {



            let sortID = e.target.id;
            let sortval = sortID.split('@');
            sortColumn = sortval[0];
            sortType = sortval[1];
            setsortColumn(sortColumn);
            setsortType(sortType);
            setsorthide(sortID);
            loadChannelData(operation);

        }



        catch (error: any) {

            ////console.log(error);

        }

    }

    const handleMigrationStatus = async (e: any) => {
        try {
            migrationStatus = e.target.value
            setMigrationStatus(migrationStatus);
            let tempOperation = "All"
            if(e.target.value != "All"){
                tempOperation = "statusFilter"
            }
            setOperation(tempOperation)
            await loadChannelData(tempOperation);
            ////console.log("success");
        }
        catch (error) {
            ////console.log(error);
        }
    }
    return (
        <div  >
            <div className="col-md-12">
                <div className="modal fade" id="failedPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Alert!</h5>
                                    <p className="success-text w-100 text-center mb-4">{statusText}</p>
                                    <div id="dynamicData"></div>
                                    <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" onClick={disablePopUp} data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 theme-color-bg py-2 p-3 rounded-2 mb-4 info-background-color width-98 font-14">
                        <label className="font-14 font-bold"><span className="me-2"><img src="/Images/info-icon.svg" alt="info_small" /></span><a href="/ChannelMigration/SA/ConversationMigration/ServiceAccounts" className="theme-color anchorAsPointer">Add Service accounts</a><span className="font-regular ms-2">for parallel migration</span></label><br />
                        <label className="font-14 font-bold mt-2"><span className="me-2"><img src="/Images/info-icon.svg" alt="info_small" /></span><span className="font-regular">With {planType} plan, you can perform {parallelInstance} parallel migrations.</span></label>                    </div>
                    <div className="col-md-12 mb-5">
                        <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <h5 className="inventory-title font-22 font-bold">Schedule Migration</h5>
                                        <button type="button" className="btn-scondary-custom btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={enableReschedulePopup}></button>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="col-md-12 mb-4">
                                            <div className="row">
                                                <label className="form-label popup-label font-14 font-regular">If any migration is in progress, the scheduled channels will be in queue.</label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 float-start mb-5">
                                            <div className="row">
                                                <div className="col-md-6 my-2 float-start">
                                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Date</label>
                                                    <input type="date" className="inventory-date-form-control date-style-cm date-style w-100" id="from-date" min={schedulemaxDate} onKeyDown={(e) => { e.preventDefault() }} value={datePopUp} onChange={(e) => handleDate(e)} />
                                                </div>
                                                <div className="col-md-6 my-2 float-start clk-icon-fix clock-icon-channel">
                                                    <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Time (UTC)</label>
                                                    <img src="\Images\Clock-icon.svg" className="ms-2" onClick={handleTimeToggle} alt="clockicon" />
                                                    <input type="text" className="inventory-date-form-control date-style-cm date-style w-100 input" placeholder="--:--" onClick={handleTimeToggle} onChange={handleTimePicker} value={timeVal=="picker"?"":timeVal} data-bs-toggle="dropdown" id="picker" />
                                                    <ul className="time-dropdown-menu editClickdropdown" id="timeDD" hidden>
                                                        {arrTime.map((obj, index) => {
                                                            return (<li className="dropdown-item" id={obj.UI} onClick={(e) => handleTimePicker(e)}>{obj.UI}</li>)
                                                        })
                                                        }
                                                    </ul>
                                                    <label id="errorlabelTime" className="required" hidden={true} >Please choose future time</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-12 d-flex justify-content-end mb-4">
                                        <button type="button" className="btn-scondary-custom btn-cancel-border common-btn-class  btn btn-second me-2 font-16 font-semibold float-start ps-4 pe-4" onClick={enableReschedulePopup}>Cancel</button>
                                        <button type="button" className="btn-primary-theme common-btn-class btn btn-prime font-16 font-semibold float-start me-3 ps-4 pe-4" id="saveBtn" disabled={(updatedDate != "" && timeVal != "" && timeVal !="picker") ? false:true}  onClick={saveScheduleClick}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label className="font-16 font-bold">Customize Migration Dates</label>
                            </div>
                            <div className="col-md-12 d-lg-flex d-sm-flex d-md-flex">
                                <div className="my-3 me-4">
                                    <label className="font-14 font-bold me-2">From date</label>
                                    <input className="form-control form-control-sm date-form-custom d-inline-flex" id="migrationFromDate" type="date" onKeyDown={(e) => { e.preventDefault() }} placeholder=".form-control-sm" aria-label=".form-control-sm example" onChange={(e) => handleMigrationFromDate(e)} />
                                </div>
                                <div className="my-3">
                                    <label className="font-14 font-bold todate-res-chl-001 datdate-space-btw-res-chl">To date</label>
                                    <input className="form-control form-control-sm date-form-custom d-inline-flex" id="migrationToDate" type="date" onKeyDown={(e) => { e.preventDefault() }} placeholder=".form-control-sm" aria-label=".form-control-sm example" onChange={(e) => handleMigrationToDate(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-4">
                        <label className="font-16 font-bold pb-2">Migrate conversation from Slack to Teams channel</label>
                        <p className="font-regular font-14">Select the required channels to begin with conversation migration. Add service accounts to proceed with parallel migration.</p>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            {/* Inventory List starts here */}
                            <div className="col-lg-3 col-sm-12 col-md-12">
                                <div className="row mx-1 margin-btm-res-mm">
                                    <div className="col-md-12 mb-3 pt-2">
                                        <label className="font-16 font-bold ">Inventory List</label>
                                    </div>
                                    <div className="col-md-12 inventory-card rounded-3  shadow-sm py-3">
                                        <div className="input-group pb-3">
                                            <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onKeyDown={(e) => keypressInventory(e)} onChange={(e) => handleInventorySearch(e)} />
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={loadGridPage}><img src="/Images/search.svg" alt="search-icon" />
                                            </button>
                                        </div>
                                        <div className="list-group widget-min-height inlineScrollManageMigration-res table-responsive p-1 customNoWrapCSS">
                                            {gridInventoryBodyData()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Inventory List ends here */}
                            {/* Inventory grid starts here */}
                            <div className="col-lg-9 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-lg-12 mb-1">
                                        <div className="mobile-group-align-start mb-2">
                                            <label className="font-14 margin-top-customspacing font-bold">Inv. Name : {inventoryName.length > 26 ?
                                                <span data-tip={inventoryName} className="font-regular font-14">{inventoryName.substring(0, 26)}...
                                                    {/* <ReactTooltip /> */}
                                                </span> :
                                                <span data-tip="" className="font-regular font-14">{inventoryName}</span>}</label>
                                        </div>

                                        {/* <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3"># of records: {migrationGridData != "N" ? migrationGridData.length:0} <span className="font-regular font-16">of </span> {totalItemsCount}</p> */}
                                        <p className="mb-0 font-bold font-12 text-nowrap float-start ms-custom-3 me-3" style={{ marginTop: "5px" }}>
                                            # of records: {migrationGridData != "N" && migrationGridData[0] != "No Records Found" ? migrationGridData.length:0}{" "}
                                            <span className="font-regular font-12">of </span> 
                                            { migrationGridData != "N" ?
                                            operation == "search" || operation == "statusFilter" ?  
                                                totalItemsCountFeature: totalItemsCount 
                                            :0}{" ("}{arrSelectedValues.length}{" Selected)"}
                                        </p>

                                        <div className="mobile-group-align-end migration-status-tab d-flex">
                                            <div className="float-start mb-2 migration-status-margin d-flex">
                                                <label className="font-14 margin-top-customspacing font-semibold float-start me-2">Status</label>
                                                <select className="form-select form-select-sm custom-select float-end me-2" onChange={(e) => handleMigrationStatus(e)} aria-label=".form-select-sm example">
                                                    <option selected value={"All"}>All</option>
                                                    <option value={"In Progress"}>Inprogress</option>
                                                    <option value={"Yet to Start"}>Yet to Start</option>
                                                    <option value={"Completed"}>Completed</option>
                                                    <option value={"Scheduled"}>Scheduled</option>
                                                    <option value={"Warning"}>Warning</option>
                                                    <option value={"Queue"}>Queue</option>
                                                </select>

                                            </div>
                                            <div className="input-group custom-search-migration me-2 mb-2 input-group-sm search-container-sa-content float-start d-inline-flex">
                                                <input type="text" className="form-control form-control-style-2 " placeholder="Search" aria-describedby="button-addon1" onKeyDown={(e) => keypressMigration(e)} onChange={(e) => handleMigrationSearch(e)} />
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={(async ()=>{
                                                    let tempOperation = ""
                                                    if(migrationSearchText != ""){
                                                        tempOperation = "search"
                                                    }
                                                    setOperation(tempOperation)
                                                    await loadChannelData(tempOperation)
                                                })}><img src="/Images/search.svg" alt="search-icon" />
                                                </button>
                                            </div>
                                            <button type="button" className="btn-scondary-custom mb-2 btn btn-refresh-cm btn-sm float-start d-inline-flex font-14 font-semibold refresh-color" id="refreshBtn" onClick={loadChannelData}><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> Refresh</button>
                                        </div>


                                    </div>
                                    <div className="col-md-12 float-start mt-2 table-responsive  overflow-y">
                                        <table className="table table-border first-child-wrap">
                                            <thead className="tabel-head-bg">
                                                <tr>
                                                    <th className="table-head font-14 font-bold">
                                                        {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                                                        <input
                                                            className="form-check-input me-2 check-alg-2 intermediate"
                                                            id="selectAllCheckBox"
                                                            name="checkbox"
                                                            type="checkbox"
                                                            onClick={handleSelectAll}
                                                        />
                                                        Slack Channel Name
                                                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="SourceChatNameDesc" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                                                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="SourceChatNameAsc" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                                                    </th>
                                                    {/* <th className="table-head font-14 font-bold">Slack Channel Name
                                                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="SourceChatNameDesc" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                                                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="SourceChatNameAsc" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                                                    </th> */}
                        
                                                    <th className="table-head font-14 font-bold">Team Name
                                                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="TargetTeamNameDesc" id="TargetTeamName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetTeamName@desc" ? true : false} />
                                                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="TargetTeamNameAsc" id="TargetTeamName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetTeamName@desc" ? false : true} />
                                                    </th>
                                                    <th className="table-head font-14 font-bold">Team Channel Name
                                                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="TargetChannelNameDesc" id="TargetChannelName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? true : false} />
                                                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="TargetChannelNameAsc" id="TargetChannelName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? false : true} />
                                                    </th>
                                                    <th className="table-head font-14 font-bold text-center">Migration Status
                                                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="MigrationStatusDesc" id="MigrationStatus@desc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? true : false} />
                                                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="MigrationStatusAsc" id="MigrationStatus@asc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? false : true} />
                                                    </th>
                                                    <th className="table-head font-14 font-bold text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {gridBodyData()}
                                            </tbody>
                                        </table>
                                        {/* channel list table ends here */}

                                    </div>
                                    {/* loadmore button section starts here */}
                                    <div className="col-md-12 float-start mt-3 mb-5 text-center">
                                        {/* {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore} >Load More</button> : ""} */}
                                        {operation != "search" && operation != "statusFilter" ? (
                                        totalItemsCount > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        ) : (
                                        totalItemsCountFeature > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        )}
                                    </div>
                                    {/* loadmore button section ends here */}
                                </div>
                            </div>
                            {/* Inventory grid ends here */}
                        </div>
                    </div>
                </div>
                <footer id="footerDiv" className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                    <div className=" mx-3 d-flex justify-content-end">
                        <button type="button" id="btnScheduleMigration" className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular me-2" disabled={arrSelectedValues.length!=0 ? false:true} onClick={enableReschedulePopup}>Schedule Migration</button>
                        <button type="button" id="btnStartMigration"    className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular"  disabled={arrSelectedValues.length!=0 ? false:true} onClick={(e)=>{startMigration(e)}}>Start Migration</button>
                    </div>
                </footer>
                <div className="overlay" id='loader' hidden={true}>
                    <div className=" Loader-Content-position ">
                        <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                            <span className="visually-hidden"></span>

                        </div>

                    </div>
                </div>
                <div className="overlay" id='loaderWithContent' hidden={true}>
                    <div className=" Loader-Content-position ">
                        <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                            <span className="visually-hidden"></span>

                        </div>
                        <p className="text-center">{loaderContent}</p>
                    </div>
                </div>
                <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

            </div>
        </div>


    )
}