import { BrowserRouter as Router, Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { useState,useEffect } from "react";
import { Source } from './SourceComponent';
import { Target } from "./TargetComponent";


export function SettingMasterComponent(props: any): JSX.Element {

    let history = useHistory();
    let isSettingsSlack = window.location.pathname.toLowerCase() == "/settings/slack" ? true : false;
    let activeTab = "common-navTab-setting px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer active";
    let inActiveTab = "common-navTab-setting px-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer";

    useEffect(() => {
        const helpVideo = document.getElementById('helpVideo') as HTMLVideoElement;
        if (helpVideo) {
            helpVideo.src = "";
        }    }, [])

    const sourceTabClick = async () => {
        try {
            history.push({
                pathname: "/Settings/Slack"
            })
        } catch (error) {
            ////console.log("SlackTabClick: ", error);
        }
    }

    const targetTabClick = async () => {
        try {
            history.push({
                pathname: "/Settings/MSTeams"
            })
        } catch (error) {
            ////console.log("MSTeamsTabClick: ", error);
        }
    }

    const helpIconClick = async () => {
        try {
            const helpVideo = document.getElementById('helpVideo') as HTMLVideoElement;
            if (helpVideo) {
                helpVideo.src = "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Settings.mp4?sp=r&st=2024-06-04T17:06:25Z&se=2024-12-31T01:06:25Z&spr=https&sv=2022-11-02&sr=b&sig=nq24sW0Q3XbdO5cn077GcefGeb7J8NXB2882r3Gy%2Fmo%3D";
                helpVideo.load(); // This ensures the video loads the new source
            }            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade show");
        } catch (error) {
            ////console.log("HelpIconClick: ", error);
        }
    }

    const helpPopUpCloseClick = async () => {
        try {
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            document.getElementById("helpPopUpDiv")?.setAttribute("class", "modal fade");
            const helpVideo = document.getElementById('helpVideo') as HTMLVideoElement;
            if (helpVideo) {
                helpVideo.src = "";
            }        } 
            catch (error) {
            ////console.log("HelpIconClick: ", error);
        }
    }

    return (

        <Router>
            <div className="fluid-container">
            <div className="row justify-content-center newsetting-cardContainer margin-0">
                <div className="col-md-12 col-lg-10 col-sm-12 float-start">
                    <div className="mt-4">
                        <h2 className="mb-3 font-bold font-26">Settings</h2>
                        {/* <!-- Nav tabs starts here --> */}
                        <div className="float-start w-100">
                            <div className="float-start">
                                <ul className="nav nav-pills" role="tablist">
                                    <li className="nav-item">
                                        <a className={isSettingsSlack ? activeTab : inActiveTab} id="sourceTab" onClick={sourceTabClick}>Slack</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={isSettingsSlack ? inActiveTab : activeTab} id="targetTab" onClick={targetTabClick}>MS Teams</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="float-end">
                                <p className="float-end"><span className="required">*</span> Required Fields</p>
                            </div>
                        </div>
                        {/* <!-- Nav tabs ends here --> */}
                        {/* <!-- Tab panes --> */}
                        <div id="tab" className="tab-content">

                            <div id="slack" className="tab-pane active">
                                {isSettingsSlack ? <Source /> : <Target />}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>

            {/*fixed section*/}
            <div className="icon-container" onClick={helpIconClick}>
                <label className="custom-icon-style" id="hidden-section">
                    <img className="fixed-icon" src="/Images/help-web-button.svg" alt="help-icon" />
                    <img className="expand-icon expand-content" src="/Images/help-web-button.svg" alt="help expand-logo" /> <span className="hidden-content expand-content font-bold font-16">Help</span>
                </label>
            </div>
            {/*fixed section*/}

            {/*Items in cart popup*/}
            <div className="modal fade" id="helpPopUpDiv" tabIndex={-1}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content modal-radius">
                        <div className="help-modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help
                                            ?</label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to
                                            learn how to connect Slack and MS Teams!</label>
                                    </div>
                                    <div className="w-100 float-start text-center">
                                    <video id="helpVideo" width="100%" className='videoTag' height="315" controls controlsList="nodownload"/></div>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end mb-4">
                                <button type="button" className="btn-scondary-custom btn-cancel-border common-btn-class btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={helpPopUpCloseClick}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Items in cart popup*/}

            {/* <!--Loader Screen--> */}
            <div className="overlay settingLoaderMainDiv" id='sourceLoader' hidden={true}>
                <div className="settingLoaderSubDiv">
                    <div className="spinner-border loaderImg text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>

            {/* <!--Loader Screen--> */}

            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

        </Router>

    );
}

