import { stat } from "fs";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Insert_Details } from "../../Services/ResgistrationServices";
import constants from "../../Common/Constants";
export default function VerifyMail() {

    const state = useLocation().state as any;
    let [alertMessage, setAlertMessage] = useState("")
    let baseURL = `${process.env.REACT_APP_REGISTRATION_BASEURL}`;
    let avamigratronLink = constants.URL.REACT_APP_AVAMIGRATRON_LINK

    useEffect(() => {
        
     

    }, [])

    const resendMail = async () => {
        
        try {
            
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let config = ({
                method: "POST",
                url: `${baseURL}/accountInfo/resendMail`,// "http://localhost:8000/accountInfo/resendMail",
                data: {
                    "mailID": `${state}`
                },
            }
            )
            
            let responsedata: any = await Insert_Details(config);
            loader?.setAttribute('hidden', 'hidden');
            let alertPopUp = responsedata.data.statusCode == 200 ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp");
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        catch (error: any) {
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

            //#endregion
        } catch (error) {
    
        }
    }

    return (
        <div className="row justify-content-center  width100 ms-0">
            {/* <!-- ava logo starts here --> */}
            <div className="col-lg-12 col-md-12 col-sm-12  my-3">
                <a href="/Login" className='anchorAsPointer'>
                    <img src='/Images/brand_logo.svg' className="ava-logo" alt="logo" />
                </a>
            </div>
            {/* <!-- ava logo ends here --> */}

            {/* <!-- verify email background img starts here --> */}
            <div className="col-12 Verify-email-bg px-0">
                <img src='/Images/verify_email_bg.svg' className="Verify-email" alt="logo" />
            </div>
            {/* <!-- verify email background img ends here --> */}

            <div className="col-12  mt-4">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 col-sm-5 text-center">
                        <h5 className="font-24 responsive-font-20 font-bold">
                            Verify your e-mail to finish signing up for AVAMIGRATRON
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-12  mt-3">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-8 col-sm-8 text-center">
                        <label className="font-16 font-regular verify-email-font2">
                            We have sent a link to your email, <span className="mail-text">{state}</span>. Please click the link on your email to login.
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-12  mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-3 text-center  mt-4">
                        <label className="verify-email-font3 font-16 font-regular">
                            If you didn't receive email, click on <a className="resend-text font-semibold anchorAsPointer" onClick={resendMail}><span >Resend</span></a>
                        </label>
                    </div>
                </div>
            </div>
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4">Mail Sent Successfully</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    )
}