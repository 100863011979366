import { useState, useEffect } from "react";
import { useHistory,useLocation } from 'react-router-dom';
import { Insert_Details } from "../../Services/ResgistrationServices";
import objCryptoJS from "crypto-js";
import constants from "../../Common/Constants";

import intlTelInput from 'intl-tel-input';

export default function RegistrationForm() {
  const userPropState = useLocation().state as any;
  const history = useHistory()
  let dataObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "", CountryCode: "",RegSource:"", licenseType : "", subscriptionID : "", planID: "", status : "", token : "" }


  let [loaderText, setLoaderText] = useState("")
  let [state, setState] = useState(dataObj);
  let [alertMessage, setAlertMessage] = useState("")
  let [successMessafe, setSuccessMessafe] = useState("")
  let [registrationButtonName, setRegistrationButtonName] = useState("SIGN UP");
  let [emailFiledDisabled, setIsEmailFieldDisabled] = useState(false);
  let baseURL = constants.URL.REACT_APP_SRVADMPRTL_BASEURL
  let avamigratronLink = constants.URL.REACT_APP_AVAMIGRATRON_LINK
  let isSSOAccount = false;
  const reactNode_en_K: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  const reactClient_en_K: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let RegSource: any = null;
  let azureToken: any;
  useEffect(() => {
        let reg: any = document.getElementById("main-container-body")
    // reg.classList.remove("body-bg-color")
    reg.classList.add("body-bg-color")
    const params = new URLSearchParams(window.location.search);
    azureToken = params.get('token');
    if(azureToken == null){
      RegSource = userPropState?.RegSource;
    }
    if(RegSource == null){
      if(azureToken == null){
        state = { ...state, ['RegSource']:"Direct" }
      }
      else {   
        verifyAzureToken()    
      }

    }
    else state = { ...state, ['RegSource']:RegSource }
    setState(state);
    const input: any = document.querySelector("#phone-num");
    intlTelInput(input, {
      preferredCountries: ['us'],
      separateDialCode: true,
    });
    LoadData();
  }, [])

  const LoadData = async () => {
    if (userPropState != undefined && userPropState != null && userPropState.type == "SSOReg") {
      isSSOAccount = true;
      const collapseRegistrationText = document.getElementById('collapseRegistrationText');
      collapseRegistrationText?.classList.add('collapsed');
      const preFilledData = { ...state, UserName: userPropState.userName, EmailAddress: userPropState.userEmail};
      setState(preFilledData);
      setRegistrationButtonName("COMPLETE SIGN UP")
      setIsEmailFieldDisabled(true);

    }
    else if (azureToken !=null && azureToken !=undefined){
      setRegistrationButtonName("COMPLETE SIGN UP")
      setIsEmailFieldDisabled(true);
    }
    let submitButton = document.getElementById("signUp");
    submitButton?.setAttribute('disabled', 'disabled');
  }

  const countrycode = async () => {
    try {
      var selectedflag: any = document.getElementsByClassName("iti__flag")
      var selectedCountryCode: any = document.getElementsByClassName("iti__selected-dial-code")
      var title: any = document.getElementsByClassName('iti__selected-flag')

      state.CountryCode = `${selectedCountryCode[0].innerText}~${selectedflag[0].className}`;
    }
    catch (error: any) {

    }
  }

  const OnChangeName = (e: any) => {

    state = { ...state, [e.target.name]: e.target.value }
    setState(state)
  }

  const regexValidation = (e: any) => {
    try {

      OnChangeName(e)

      if (e.target.id == "user-name") {
        if (state.UserName == "") {
          let x = document.getElementById("emptyuserName")
          x?.removeAttribute('hidden')
          let y = document.getElementById("userName")
          y?.setAttribute('hidden', 'hidden')
          let submitButton = document.getElementById("signUp");
          submitButton?.setAttribute('disabled', 'disabled');
        }
        else {
          let x = document.getElementById("emptyuserName")
          x?.setAttribute('hidden', 'hidden')
          let spaceRemoved = state.UserName.replace(/\s\s+/g, ' ')
          let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
          let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
          if (result == false) {
            let x = document.getElementById("userName")
            x?.removeAttribute('hidden')
          }
          else {
            let x = document.getElementById("userName")
            x?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.id == "company-name") {
        if (state.CompanyName == "") {
          let x = document.getElementById("emptycompanyName")
          x?.removeAttribute('hidden')
          let y = document.getElementById("companyName")
          y?.setAttribute('hidden', 'hidden')
          let submitButton = document.getElementById("signUp");
          submitButton?.setAttribute('disabled', 'disabled');
        }
        else {
          let x = document.getElementById("emptycompanyName")
          x?.setAttribute('hidden', 'hidden')

          let spaceRemoved = state.CompanyName.replace(/\s\s+/g, ' ')
          let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
          let result = /^[0-9a-zA-Z ]{1,80}$/.test(trimmed)

          if (result == false) {
            let x = document.getElementById("companyName")
            x?.removeAttribute('hidden')
          }
          else {
            let x = document.getElementById("companyName")
            x?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.id == "email-address") {
        if (state.EmailAddress == "") {
          let x = document.getElementById("emptyEmail")
          x?.removeAttribute('hidden')
          let y = document.getElementById("Email")
          y?.setAttribute('hidden', 'hidden')
          let submitButton = document.getElementById("signUp");
          submitButton?.setAttribute('disabled', 'disabled');
        }
        else {
          let x = document.getElementById("emptyEmail")
          x?.setAttribute('hidden', 'hidden')
          let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.EmailAddress)

          if (result == false || state.EmailAddress.length > 320) {
            let x = document.getElementById("Email")
            x?.removeAttribute('hidden')
          }
          else {
            let x = document.getElementById("Email")
            x?.setAttribute('hidden', 'hidden')
          }
        }
      }
      else if (e.target.id == "phone-num") {
        if (state.PhoneNumber == "") {
          let x = document.getElementById("emptyPhone")
          x?.removeAttribute('hidden')
          let y = document.getElementById("Phone")
          y?.setAttribute('hidden', 'hidden')
        }
        else {
          let x = document.getElementById("emptyPhone")
          x?.setAttribute('hidden', 'hidden')
          let result = /^[0-9]{5,15}$/.test(state.PhoneNumber)
          if (result == false) {
            let x = document.getElementById("Phone")
            x?.removeAttribute('hidden')
          }
          else {
            let x = document.getElementById("Phone")
            x?.setAttribute('hidden', 'hidden')

          }
        }
      }
      if (state.UserName != "" && state.CompanyName != "" && state.EmailAddress != "" && state.PhoneNumber != "") {
        let spaceRemoved = state.UserName.replace(/\s\s+/g, ' ')
        let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
        let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
        let removeSpace = state.CompanyName.replace(/\s\s+/g, ' ')
        let trim = removeSpace.trim().replaceAll(" +", " ");
        let Check2 = /^[0-9a-zA-Z ]{1,80}$/.test(trim)
        let Check3 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(state.EmailAddress)
        let Check4 = /^[0-9]{5,15}$/.test(state.PhoneNumber)

        if (Check1 && Check2 && Check3 && state.EmailAddress.length <= 320 && Check4 == true) {
          let submitButton = document.getElementById("signUp");
          submitButton?.removeAttribute('disabled');
        }
        else {
          let submitButton = document.getElementById("signUp");
          submitButton?.setAttribute('disabled', 'disabled');
        }
      }
      else {
        let submitButton = document.getElementById("signUp");
        submitButton?.setAttribute('disabled', 'disabled');
      }
    }
    catch (error: any) {
    }
  }

  const signUpClick = async () => {

    try {
      await countrycode();
      let config
      if(state.RegSource == "AzureMarket"){
        config = ({
          method: "POST",
          url: `${baseURL}/admin/marketPlace`,
          data: {
            "name": `${state.UserName}`,
            "mailID": `${state.EmailAddress}`,
            "phone": `${state.PhoneNumber}`,
            "countryCode": `${state.CountryCode}`,
            "companyName": `${state.CompanyName}`,
            "subscriptionID": `${state.subscriptionID}`,
            "planID": `${state.planID}`,
            "status": `${state.status}`,
            "token": `${state.token}`
          },
        }
        )
      }
      
      else{
        config = ({
          method: "POST",
          url: `${baseURL}/accountInfo/storeClientDetails`,
          data: {
            "name": `${state.UserName}`,
            "mailID": `${state.EmailAddress}`,
            "phone": `${state.PhoneNumber}`,
            "countryCode": `${state.CountryCode}`,
            "companyName": `${state.CompanyName}`,
            "registeredAt": `${new Date().toISOString().split('T')[0]}`,
            "isSSOAccount": userPropState?.type == 'EmailReg' ? false : true,
            "RegSource":`${state.RegSource}`,
            "isRevokeLicense" : false,
            "isActive" : true
          },
        }
        )
      }
      let submitButton = document.getElementById("signUp");
      submitButton?.setAttribute('disabled', 'disabled');
      let loader = document.getElementById("loader")

      if( state.RegSource == 'AzureMarket'){
        setLoaderText("Please wait while we set up your Migration Environment...")
      }
      loader?.removeAttribute('hidden');
            let responsedata: any = await Insert_Details(config);
      loader?.setAttribute('hidden', 'hidden');
      if (responsedata.data.statusCode == 200) {
        if (userPropState?.type != 'EmailReg' || state.RegSource == 'AzureMarket') {
          let byteValue = objCryptoJS.AES.decrypt(responsedata.data.data, reactNode_en_K);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);

          let enPass = objCryptoJS.AES.encrypt(dePass, reactClient_en_K).toString();
          localStorage.setItem("cid_t", enPass);
          if( state.RegSource == 'AzureMarket'){
            window.location.pathname = '/Settings'
          }
          else window.location.pathname = '/ChoosePlan';
        }
        else{
          history.push({
            pathname: "/VerifyEmail",
            state: `${state.EmailAddress}`
          })

        }
      }
      else {
        if (responsedata.data.statusText == "Mail ID already present") {
          setAlertMessage("Email Address provided is already in use!")
        }
        else {
          setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
        }
        let alertPopUp = document.getElementById("alertPopUp");
        alertPopUp?.removeAttribute("aria-hidden");
        alertPopUp?.setAttribute("aria-modal", "true");
        alertPopUp?.setAttribute("role", "dialog");
        alertPopUp?.setAttribute("class", "modal fade show");
        alertPopUp?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
    }
    catch (error: any) {
      let loader = document.getElementById("loader")
      loader?.setAttribute('hidden', 'hidden');
      let submitButton = document.getElementById("signUp");
      submitButton?.setAttribute('disabled', 'disabled');
      setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }


  const popUpOkClick = (type: string) => {
    try {
      //#region Hide PopUp
      let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
      popup?.setAttribute("class", "modal fade");
      popup?.setAttribute("style", "display:none");
      popup?.setAttribute("aria-hidden", "true");
      popup?.removeAttribute("aria-modal");
      popup?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

      //#endregion
    } catch (error) {
    }
  }

  const verifyAzureToken = async ()=>{
    try{
      let config = {
        method: "POST",
        url: `${baseURL}/admin/verifyAzureToken`,
        data: {
          token:azureToken
        },
      }
      let loader = document.getElementById("loader")
      loader?.removeAttribute('hidden');
      let responsedata: any = await Insert_Details(config);
      loader?.setAttribute('hidden', 'hidden');
      if(responsedata.data.statusCode == 200){
        setState({ 
          ...state, 
          ['EmailAddress']:responsedata.data.data.mailID,
          ['subscriptionID']:responsedata.data.data.subscriptionID,
          ['planID']:responsedata.data.data.planID,
          ['status']:responsedata.data.data.status,
          ['RegSource']:"AzureMarket",
          ['token']: azureToken
        })
      }
      else if (responsedata.data.statusCode = 400 && responsedata.data.statusText == "Mail ID already present"){
        let submitButton = document.getElementById("signUp");
        submitButton?.setAttribute('disabled', 'disabled');
        setAlertMessage("Email Address provided is already in use!")
        let alertPopUp = document.getElementById("alertPopUp");
        alertPopUp?.removeAttribute("aria-hidden");
        alertPopUp?.setAttribute("aria-modal", "true");
        alertPopUp?.setAttribute("role", "dialog");
        alertPopUp?.setAttribute("class", "modal fade show");
        alertPopUp?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if(responsedata.data.statusCode = 400){
        let loader = document.getElementById("loader")
        loader?.setAttribute('hidden', 'hidden');
        let submitButton = document.getElementById("signUp");
        submitButton?.setAttribute('disabled', 'disabled');
        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
        let alertPopUp = document.getElementById("alertPopUp");
        alertPopUp?.removeAttribute("aria-hidden");
        alertPopUp?.setAttribute("aria-modal", "true");
        alertPopUp?.setAttribute("role", "dialog");
        alertPopUp?.setAttribute("class", "modal fade show");
        alertPopUp?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
    }
    catch{
      let loader = document.getElementById("loader")
      loader?.setAttribute('hidden', 'hidden');
      let submitButton = document.getElementById("signUp");
      submitButton?.setAttribute('disabled', 'disabled');
      setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }



  return (
    // <div className="float-start reg-left-border-height width100">

    <div className="float-start  width100">
      <span className="reg-border-left-signup"></span>
      <div className="col-md-12 float-start bg-white px-0">
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 margin-leftlayout float-start pt-4">
          <a href={avamigratronLink} className='anchorAsPointer'>
            <img src='/Images/brand_logo.svg' className="brand-logo icon-responsive mt-4 ms-5" alt="logo"></img>
          </a>

          {/* <!-- Registration form starts here --> */}
          <div className="row justify-content-center mt-3">
            <div className="col-md-6 float-start px-3">

              <h1 className="font-24 font-bold mb-3 reg-header-color">Slack To Teams Migration</h1>

              {/* <!-- main heading starts here --> */}
              <h4 className="font-18 font-bold mb-3">Get started with your account</h4>
              {/* <!-- main heading ends here --> */}

              {/* <!-- top para starts here --> */}
              <p className="font-14 font-regular" id="collapseRegistrationText">Please register to proceed further. Already have an account ?
                <span><a href="/Login" className="sign-in anchorAsPointer"> Sign In</a></span>
              </p>
              {/* <!-- top para ends here --> */}
              <div className="">
                <label className="font-14 font-semibold pb-1" htmlFor="user-name">User Name<span
                  className="required">*</span></label>
                <input type="text" className="form-control registration_form_control" id="user-name" placeholder="Enter User Name" name="UserName" value={state.UserName} onChange={(e) => regexValidation(e)} />
                <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
              </div>
              <div>
                <label className="font-14 font-semibold  pt-3 pb-1" htmlFor="company-name">Company Name<span
                  className="required">*</span></label>
                <input type="text" className="form-control registration_form_control" id="company-name" placeholder="Enter Company Name" name="CompanyName" value={state.CompanyName} onChange={(e) => regexValidation(e)} />
                <label id="companyName" className="required" hidden={true} >Please enter Valid Company Name</label>
                <label id="emptycompanyName" className="required" hidden={true} >Company Name cannot be empty</label>
              </div>
              <div>
                <label className="font-14 font-semibold pt-3 pb-1" htmlFor="email-address">Email Address<span
                  className="required">*</span></label>
                <input type="email" className="form-control registration_form_control" id="email-address" placeholder="Enter Email Address" name="EmailAddress" value={state.EmailAddress} onChange={(e) => regexValidation(e)} disabled={emailFiledDisabled} />
                <label id="Email" className="required" hidden={true}>Please enter Valid Mail Address</label>
                <label id="emptyEmail" className="required" hidden={true}>Mail Address cannot be empty</label>
              </div>
              <div>
                <label className="font-14 font-semibold pt-3 pb-1" htmlFor="phone-num">Phone Number<span
                  className="required">*</span></label>
                <input type="tel" className="form-control registration_form_control" id="phone-num" placeholder="Enter Phone Number" name="PhoneNumber" value={state.PhoneNumber} onChange={(e) => regexValidation(e)} maxLength={15} />
                <label id="Phone" className="required" hidden={true}> Please enter Valid Phone number </label>
                <label id="emptyPhone" className="required" hidden={true}>Phone number cannot be empty </label>
              </div>

              {/* <!-- button starts here --> */}
              <p className="font-12 font-regular pt-4">By signing up, you agree to our&nbsp;
                <span><a href="https://www.avamigratron.com/terms-of-use/" target="_blank" className="sign-in anchorAsPointer">Terms of Use</a></span> and&nbsp;
                <span><a href="https://www.avamigratron.com/privacy-policy/" target="_blank" className="sign-in anchorAsPointer">Privacy Policy</a></span>
              </p>
              <button id="signUp" className="common-btn-class btn-primary-theme btn btn-primary_signup float-end registration_form_button my-4 " onClick={signUpClick}>{registrationButtonName}</button>
              {/* <!-- button ends here --> */}
            </div>
          </div>
          {/* <!-- Registration form ends here --> */}
        </div>
        {/* <!--background image form starts here --> */}
        <div className="col-lg-5 d-lg-block d-md-none d-none d-sm-none float-start p-0 Reg-img-position">
          <img src='/Images/signup_img_bg.png' className="Registration_bg_img" alt="registration-bg-img" />
        </div>
        {/* <!--background image form ends here --> */}
      </div>
      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <!--Alert pop-up--> */}
      {/* <!--loader section--> */}
      <div className="overlay" id='loader' hidden={true}>
        <div className="loader-position loadercss Loader-Content-position">
          <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
          <p className="aligncenter">{loaderText}</p>
        </div>
      </div>
      {/* <!--loader section--> */}
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>
  )
}