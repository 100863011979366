import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import WizardNavigation from './Wizard'

export default function WizardNavigation(wizActive:any) {
    let GridLoadarray: any[] = [];
    const state: any = useLocation().state;
    let [hideImage, setHideImage] = useState(wizActive.wizActive);
    

    let history = useHistory();
    useEffect(() => {

    })


    const handleWizard = (e: any) => {
      
      
      hideImage=e.target.id;
      setHideImage(hideImage);
      let id = e.target.id
      let x = document.getElementById(e.target.id)
      let spanToggle = document.getElementById(`#${id.substring(0,5)}`)
    //   spanToggle?.setAttribute("href", `#step-${id[4]}`)

      if (id.includes("step3")) {
          history.push({
              pathname: "/Conversation/AU/ConversationMigration"
          })
         
      }
      else if (id.includes("step2")) {
          history.push({
              pathname: "/Conversation/AU/ChatMapping"
          })
         
      }
      else {
        history.push({
          pathname: "/Conversation/AU/UserVerification"
      })
      
      }
  }

    return (

    //     <div className="col-md-12 mt-5">
    //     <h4 className="font-24 font-bold">
    //       <a href="#"><span><img src="images/left-arrow.svg" alt="left-arrow" title="Back" /></span></a>
    //       Conversation Migration
    //     </h4>
    //   </div>
      <div className="col-md-12 my-3">
      <div className="row justify-content-center">
        <div className="col-12 py-3">
        <ul className="nav migration-wizard justify-content-center ">
                        <li>
                            <a className="nav-link  font-18 font-bold anchorAsPointer" id="#step1" data-bs-toggle="tab">
                                <span className="px-2">
                                <img src="/Images/step-1-inActive.svg" id="step1InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step1InActive" ? true : false} alt="step-1" />
                                    <img src="/Images/step-1-active.svg" id="step1Active" hidden={hideImage == "step1InActive" ? false : true} alt="step-1" />
                                    </span>
                                <span className={(hideImage=="step1InActive")?"activeTabColour wizard-res-none":"inactiveTabColour wizard-res-none"} id="step1InActive" onClick={(e) => { handleWizard(e) }}>User Verification</span>
                                {/* <span id="step1InActive" onClick={(e) => { handleWizard(e) }}>User Verification</span> */}
                            </a>
                        </li>
                        <li>
                            <a className=" nav-link active font-18 font-bold anchorAsPointer" id="#step2" data-bs-toggle="tab">
                                <span className="px-2">
                                <img src="/Images/step-2-inActive.svg" id="step2InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step2InActive" ? true : false} alt="step-2" />
                                    <img src="/Images/step-2-active.svg" id="step2Active" hidden={hideImage == "step2InActive" ? false : true} alt="step-2" />
                                    </span>
                                <span className={(hideImage=="step2InActive")?"activeTabColour wizard-res-none":"inactiveTabColour wizard-res-none"} id="step2InActive" onClick={(e) => { handleWizard(e) }}>MS Team Chat Creation and Mapping</span>
                                {/* <span id="step2InActive"onClick={(e)=> { handleWizard(e) }}>MS Team Chat Creation and Mapping</span> */}
                            </a>
                        </li>
                        <li>
                            <a className=" nav-link font-18 font-bold anchorAsPointer" id="#step3" data-bs-toggle="tab">
                                <span className="px-2">
                                <img src="/Images/step_3_inActive.svg" id="step3InActive" onClick={(e) => { handleWizard(e) }} hidden={hideImage == "step3InActive" ? true : false} alt="step-3" />
                                <img src="/Images/step-3-active.svg" id="step3Active" hidden={hideImage == "step3InActive" ? false : true} alt="step-3" />
                                    </span>
                                <span className={(hideImage=="step3InActive")?"activeTabColour wizard-res-none":"inactiveTabColour wizard-res-none"} id="step3InActive" onClick={(e) => { handleWizard(e) }}>Content Migration</span>
                                {/* <span id="step3InActive" onClick={(e) => { handleWizard(e) }}>Conversation Migration</span> */}
                            </a>
                        </li>
                    </ul>
        </div>
      </div>

       {/* loader without content starts here */}
      <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className="Loader-Content-position">
                    <div className="spinner-border loaderImg  Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
       </div>
       {/* loader without static content ends here */}

    </div>

    );
}