import {sendRequestToNodeServices} from '../Client/Client';
import constants  from '../Common/Constants';



let baseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/PreMigrationWebApp/`;

export const sendRequestToAPI=( config : any) =>
{
    try {
        //console.log(baseURL+config.endpoint);
        //console.log(config);
        return sendRequestToNodeServices((baseURL+config.endpoint),{method:'POST' , body : config.data},config.token);
        
    } catch (error) {
        throw error;
    }
}
