import React, { useEffect, useState } from 'react';
import { gridLoad } from "../../Services/ProfileServices";
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";


export default function ManageSecondaryAdmins() {

    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let adminToken: any;
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    let GridLoadarray: any[] = [];
    let [gridData, setGridData] = useState(GridLoadarray);
    let [alertMessage, setAlertMessage] = useState("");
    let [enableBtn, setEnableBtn] = useState(false);
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    let [status, setStatus] = useState("");
    let [totalItemsCount, setTotalItemsCount] = useState(2);
    let formObj = { UserName: "", Email: "", IsSSOAccount: "", ID: "" }
    let [formState, setFormState] = useState(formObj);
    const [editMode, setEditMode] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
    let [editedData, setEditedData] = useState(formObj);
    let [enableSaveBtn, setEnableSaveBtn] = useState(false);
    let [isOtherAcc, setisOtherAcc] = useState(false);
    let [isMsftAcc, setisMsftAcc] = useState(false);
    let [isEmailAcc, setisEmailAcc] = useState(false);
    let [editDataIndex, setEditDataIndex] = useState(0);
    let [authMsg, setAuthMsg] = useState("Select Authentication Type");

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }
    }


    useEffect(() => {
        // resetSessionTimer();
        // //#region Event handlers Keypad click and mouse click
        // window.addEventListener('keydown', resetSessionTimer);
        // window.addEventListener('click', resetSessionTimer);
        // //#endregion
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.add("container-bg-withoutFooter")
        loadGridPage("fetch")
        // return () => {
        //     window.removeEventListener('click', resetSessionTimer);
        //     window.removeEventListener('keydown', resetSessionTimer);
        // };
    }, [])

    // //#region Initiate the time for inactive and check the session expiry time duration
    // const resetSessionTimer = () => {
    //     try {
    //         //console.log("eventhit")
    //         clearTimeout(InActiveTimeout);
    //         InActiveTimeout = setTimeout(() => {
    //             enableSessionExpiryPopup();
    //         }, Number(process.env.REACT_APP_TABIDLESCENARIO));
    //         let currentDateTime: any = new Date().toISOString();
    //         let diffInMilliseconds: number;
    //         diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
    //         let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    //         if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
    //             getClientSessionDetails();
    //         }
    //     }
    //     catch (ex: any) {
    //         status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
    //         setStatus(status)

    //         document.getElementById("success")?.removeAttribute("aria-hidden");
    //         document.getElementById("success")?.setAttribute("aria-modal", "true");
    //         document.getElementById("success")?.setAttribute("role", "dialog");
    //         document.getElementById("success")?.setAttribute("class", "modal fade show");
    //         document.getElementById("success")?.setAttribute("style", "display:block");
    //         document.getElementById("fadeDiv")?.removeAttribute("hidden");
    //     }
    // }
    // //#endregion

    // //#region  To fetch the updated sess
    // const getClientSessionDetails = async () => {
    //     try {
    //         if (clientID != undefined) {
    //             let config = {
    //                 url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
    //                 token: clientToken
    //             }

    //             let data: any = await gridLoad(config)
    //             if (data.data.statusCode == 200) {
    //                 //#region Update and Frame Client Session
    //                 localStorage.removeItem("cid_t");
    //                 let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
    //                 var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
    //                 let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
    //                 localStorage.setItem("cid_t", enPass);
    //                 //#endregion

    //                 //#region Update state variables
    //                 //console.log(SessionEpiredDateTime);
    //                 let updatedobjClient: any = jwt(dePass);
    //                 SessionEpiredDateTime = updatedobjClient.CreatedAt;
    //                 setSessionEpiredDateTime(SessionEpiredDateTime);
    //                 clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
    //                 //console.log(SessionEpiredDateTime);
    //                 //#endregion



    //             }
    //             else if (data.data.data == "Invalid authentication token provided") {
    //                 enableSessionExpiryPopup();
    //             }
    //             else {
    //                 enableSessionExpiryPopup();
    //             }
    //         }
    //         else {
    //             localStorage.clear();
    //         }

    //     } catch (error) {
    //         status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
    //         setStatus(status)

    //         document.getElementById("success")?.removeAttribute("aria-hidden");
    //         document.getElementById("success")?.setAttribute("aria-modal", "true");
    //         document.getElementById("success")?.setAttribute("role", "dialog");
    //         document.getElementById("success")?.setAttribute("class", "modal fade show");
    //         document.getElementById("success")?.setAttribute("style", "display:block");
    //         document.getElementById("fadeDiv")?.removeAttribute("hidden");
    //     }
    // }
    // //#endregion

    // //#region Session Expiry POP-UP enable and Session Clear method
    // const enableSessionExpiryPopup = () => {
    //     try {
    //         status = "Your session has expired. Please log in again to continue using the application."
    //         setStatus(status)

    //         //#region Show PopUp
    //         document.getElementById("success")?.removeAttribute("aria-hidden");
    //         document.getElementById("success")?.setAttribute("aria-modal", "true");
    //         document.getElementById("success")?.setAttribute("role", "dialog");
    //         document.getElementById("success")?.setAttribute("class", "modal fade show");
    //         document.getElementById("success")?.setAttribute("style", "display:block");
    //         document.getElementById("fadeDiv")?.removeAttribute("hidden");
    //         //#endregion
    //         localStorage.removeItem("cid_t");
    //         setisSessionEpired(true);
    //     } catch (error) {

    //     }
    // }
    // //#endregion

    //#region Grid Load
    const loadGridPage = async (type: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/ManageSecondaryAdmin`,
                    data: type == "update" ?
                        {
                            "clientID": clientID,
                            "type": type,
                            "userName": editedData[editIndex]['UserName'],
                            "mailID": editedData[editIndex]['Email'],
                            "SecAdminID": Number(editedData[editIndex]['ID']),
                            "authType": editedData[editIndex]['IsSSOAccount']
                        }
                        :
                        {
                            "clientID": clientID,
                            "type": type,
                            "userName": formState.UserName,
                            "mailID": formState.Email,
                            "SecAdminID": Number(formState.ID),
                            "authType": formState.IsSSOAccount
                        },
                }
                )
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                let data: any = await gridLoad(config);
                loader?.setAttribute('hidden', 'hidden');
                formState = { UserName: '', Email: '', IsSSOAccount: '', ID: '' }
                setFormState(formState);
                let dropdownValue: any = document.getElementById("IsSSOAccount")
                dropdownValue.value = "Select Authentication Type"
                if (type == 'update') { setEditIndex(0) };
                if (data.data.data == "Something went wrong! Please try again or reach AVAMIGRATRON Support." || data.data.data == "Email Address provided is already in use!") {
                    setAlertMessage(data.data.data);
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else if (data.data.data == "No Records Found") {
           
                    setGridData([data.data.data]);
                }
                else {
                    gridData = (type == "fetch") ? data.data.data[1] : data.data.data[0];
                    setGridData(gridData);
                    editedData = (type == "fetch") ? data.data.data[1] : data.data.data[0];
                    setEditedData(editedData);
                    totalItemsCount = (type == "fetch") ? Number(data.data.data[0][0].SecondaryAdminLimit) : Number(data.data.data[1][0].SecondaryAdminLimit);
                    setTotalItemsCount(totalItemsCount);
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Handle button Clicks
    const handleEditClick = async (editingRow: any) => {
        try {
            // let dropdownValue: any = document.getElementsByName("IsSSOAccountEdit")
            // dropdownValue.value = editedData[editingRow]['IsSSOAccount'] == true ? "Microsoft" : "Email";
            setAuthMsg(editedData[editingRow]['IsSSOAccount'] == true ? "Microsoft" : "Email")
            setEditMode(true);
            setEditIndex(editingRow)
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const handleEditRegexValidation = (e: any, index: number) => {
        try {
            handleInputChange(e, index)
            //#region validation labels
            let userName = document.getElementById("invalidUserName1");
            let email = document.getElementById("invalidEmail1");
            let authType = document.getElementById('invalidAuthType1') as HTMLInputElement;
            let emptyUserName = document.getElementById("emptyuserName1");
            let emptyEmail = document.getElementById("emptyEmail1");

            let authMsg = authType.value;
            setAuthMsg(authMsg);
            //#endregion
            if (e.target.id == "UserName") {
                if (editedData[index]['UserName'] == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    setEnableSaveBtn(false);
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = editedData[index]['UserName'].replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        //console.log("False", formState.UserName);
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "Email") {
                if (editedData[index]['Email'] == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    setEnableSaveBtn(false);
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(editedData[index]['Email'])

                    if (result == false && editedData[index]['Email'].length <= 320) {
                        //console.log("False", formState.EmailAddress);
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            if (e.target.id == "IsSSOAccount") {
                if (editedData[index]['IsSSOAccount'] == undefined || editedData[index]['IsSSOAccount'] == "Select Authentication Type") {
                    authType?.removeAttribute('hidden');
                    setEnableSaveBtn(false);
                }
                else {
                    authType?.setAttribute('hidden', 'hidden');
                }
            }
            if (editedData[index]['UserName'] != "" && editedData[index]['Email'] != "" && editedData[index]['Email'].length <= 320 && (editedData[index]['IsSSOAccount'].toLowerCase() == "microsoft" || editedData[index]['IsSSOAccount'].toLowerCase() == "email")) {
                let spaceRemoved = editedData[index]['UserName'].replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let Check2 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(editedData[index]['Email'])

                if (Check1 == true && Check2 == true) {
                    setEnableSaveBtn(true);
                }
                else {
                    setEnableSaveBtn(false);
                }
            }
            else {
                setEnableSaveBtn(false);
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    const handleInputChange = (event: any, rowIndex: number) => {
        try {
            let updatedData = { ...editedData };
            if (updatedData[rowIndex]['IsSSOAccount'] == true || updatedData[rowIndex]['IsSSOAccount'] == false) {
                updatedData[rowIndex]['IsSSOAccount'] = (updatedData[rowIndex]['IsSSOAccount'] == true ? "Microsoft" : "Email");
            }
            // let dropdownValue: any = document.getElementsByName("IsSSOAccountEdit")
            // dropdownValue.value = event.target.id == "IsSSOAccount" ? event.target.value : updatedData[rowIndex]['IsSSOAccount'];
            setAuthMsg(editedData[rowIndex]['IsSSOAccount']==true ? "Microsoft" : "Email");
            setEditMode(true);
            setEditIndex(rowIndex);
            updatedData[rowIndex][`${event.target.id}`] = event.target.value;
            setEditedData(updatedData);


        }
        catch (error) {

        }
    };
    const editSaveClick = async (rowIndex: any) => {
        try {
            setEditMode(false)
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            await loadGridPage('update');
            setEditIndex(0);
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const handleAddClick = async (e: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            loadGridPage('add');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const handleDeleteClick = async (e: any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let data = JSON.parse(e.target.id);
            formState = { ...formState, ID: data.ID }
            setFormState(formState);
            loadGridPage('delete');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const editCancelClick = async (editIndex:any) => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            setEditMode(false);
            setEditIndex(0);
           loadGridPage("fetch");
            // loader?.setAttribute('hidden', 'hidden');
        }
        catch (error: any) {
            //console.log("error", error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const OnChangeName = (e: any) => {
        try {
            formState = { ...formState, [e.target.id]: e.target.value }
            setFormState(formState);
        }
        catch (error: any) {
            //console.log(error);

        }
    }
    const regexValidation = (e: any) => {
        try {
            OnChangeName(e)
            //#region validation labels
            let userName = document.getElementById("invalidUserName");
            let email = document.getElementById("invalidEmail");
            let authType = document.getElementById('invalidAuthType') ;
            let emptyUserName = document.getElementById("emptyuserName");
            let emptyEmail = document.getElementById("emptyEmail");


            //#endregion
            if (e.target.id == "UserName") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    setEnableBtn(false);
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        //console.log("False", formState.UserName);
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            else if (e.target.id == "Email") {
                if (formState.Email == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    setEnableBtn(false);
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.Email)

                    if (result == false && formState.Email.length <= 320) {
                        //console.log("False", formState.EmailAddress);
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }
            if (e.target.id == "IsSSOAccount") {
                if (formState.IsSSOAccount == "" || formState.IsSSOAccount == "Select Authentication Type") {
                    authType?.removeAttribute('hidden');
                    setEnableBtn(false);
                }
                else {
                    authType?.setAttribute('hidden', 'hidden');
                }
            }
            if (formState.UserName != "" && formState.Email != "" && formState.Email.length <= 320 && formState.IsSSOAccount != "" && formState.IsSSOAccount != 'Select Authentication Type') {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let Check2 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.Email)

                if (Check1 == true && Check2 == true) {
                    setEnableBtn(true);
                }
                else {
                    setEnableBtn(false);
                }
            }
            else {
                setEnableBtn(false);
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    //#endregion
    //#region bind data in grid
    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && gridData[0].AccountStatus != undefined) {
                return gridData.map((obj, index) => {
                    let rowClass = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let activeClass = (obj.AccountStatus.toLowerCase() == "active") ? "status success-status text-center font-14 font-semibold" : "status status-styling3 text-center font-14 font-semibold"
                    return (
                        <tr className={rowClass} key={index} >
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        <input type="text" placeholder="Enter User Name" id="UserName" className="form-control  font-14 font-regular customManageSec" value={obj.UserName} onChange={(e) => handleEditRegexValidation(e, index)} />
                                        <label id="invalidUserName1" className="required" hidden={true} >Please enter Valid User Name</label>
                                        <label id="emptyuserName1" className="required" hidden={true} >User Name cannot be empty</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.UserName}
                                    </span>
                                }
                            </td>
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        <input type="text" placeholder="Enter Email ID" id="Email" className="form-control  font-14 font-regular customManageSec" value={obj.Email} onChange={(e) => handleEditRegexValidation(e, index)}></input>
                                        <label id="invalidEmail1" className="required" hidden={true} >Please enter Valid Email ID</label>
                                        <label id="emptyEmail1" className="required" hidden={true} >Email ID cannot be empty</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.Email}
                                    </span>
                                }
                            </td>
                            <td className="table-head font-14 font-regular">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        <select  value = {authMsg} className="form-select font-14 font-regular" aria-label="Default select example" name='IsSSOAccountEdit' id='IsSSOAccount' onChange={(e) => handleEditRegexValidation(e, index)}>
                                            <option value='Select Authentication Type' className='grayColor'>Select Authentication Type</option>
                                            <option value="Microsoft">Microsoft SSO</option>
                                            <option value="Email">Email</option>
                                        </select>
                                        <label id="invalidAuthType1" className="required" hidden={true} >Please select any one option</label>
                                    </span>
                                    :
                                    <span>
                                        {obj.IsSSOAccount == false ? "Email" : "Microsoft SSO"}
                                    </span>
                                }
                            </td>
                            <td className="table-head font-14 font-regular">
                                <span className={activeClass}>{obj.AccountStatus}</span>
                            </td>
                            <td className="table-head font-14 font-regular text-center vertical-align text-center ">
                                {(editMode && editIndex == index) ?
                                    <span>
                                        {(enableSaveBtn == false) ?
                                            <span>
                                                <img className="disableDiv icons" src="/Images/check.svg"></img>
                                                <img className="icons anchorAsPointer" src="/Images/cross.svg" id={JSON.stringify(obj)} onClick={e => editCancelClick(index)}></img>
                                            </span>
                                            :
                                            <span>
                                                <img className="icons me-2 anchorAsPointer" src="/Images/check.svg" id={JSON.stringify(obj)} onClick={e => editSaveClick(index)} ></img>
                                                <img className="icons anchorAsPointer" src="/Images/cross.svg" id={JSON.stringify(obj)} onClick={e => editCancelClick(index)} ></img>
                                            </span>
                                        }
                                    </span>
                                    :
                                    <span>
                                        <img className="icons me-2 anchorAsPointer" src="/Images/edit-icon.svg" id={JSON.stringify(obj)} onClick={e => handleEditClick(index)} ></img>
                                        <img className="icons anchorAsPointer" src="/Images/delete-icon.svg" id={JSON.stringify(obj)} onClick={e => handleDeleteClick(e)}></img>
                                    </span>
                                }
                            </td>
                        </tr>
                    )
                })
            }
            else if (gridData[0] == undefined && gridData[0].AccountStatus == undefined) {
                return (<tr> <td className="aligncenter" colSpan={6}></td>  </tr>)
            }
        }
        catch (error: any) {
            //console.log(error);
        }
    }
    //#endregion
    //#region Alret Popup close
    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            let submitButton = document.getElementById("saveButton")
            submitButton?.setAttribute("disabled", "disabled")
            //#endregion
            // loadGridPage("fetch");
        } catch (error) {
            //console.log("Issue Faced");
        }
    }
    //#endregion
    return (
        <div id="invoice-history" className="tab-pane active  pb-5">
            <div className="col-md-12 col-12 col-sm-12 col-md-12 float-start mt-2">
                <div className="w-100  py-2 p-3 rounded-2 mb-4 info-background-color width-98 font-14" style={{ background: "#f3f3f8" }}>
                    <label className="font-14">
                        <span className="me-2">
                            <img src="/Images/info-icon.svg" alt="info_small" className='me-2'></img> Secondary Admins can only manage the migration process
                        </span>
                    </label>
                </div>
                <div className="table-responsive table-border">
                    <table className="table ">
                        <thead className="tabel-head-bg">
                            <tr>
                                <th className="table-head font-14 font-bold">User Name</th>
                                <th className="table-head font-14 font-bold">Email ID</th>
                                <th className="table-head font-14 font-bold">Authentication Type</th>
                                <th className="table-head font-14 font-bold">Account Status</th>
                                <th className="table-head font-14 font-bold text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr className="tabel-body-bg" hidden={(gridData == undefined ? 0 : gridData.length) < totalItemsCount ? false : true}>
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        <input type="text" placeholder="Enter User Name" id="UserName" className="form-control  font-14 font-regular customManageSec" value={formState.UserName} onChange={(e) => regexValidation(e)}></input>
                                        <label id="invalidUserName" className="required" hidden={true} >Please enter Valid User Name</label>
                                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        <input type="text" placeholder="Enter Email ID" id="Email" className="form-control  font-14 font-regular customManageSec" value={formState.Email} onChange={(e) => regexValidation(e)}></input>
                                        <label id="invalidEmail" className="required" hidden={true} >Please enter Valid Email ID</label>
                                        <label id="emptyEmail" className="required" hidden={true} >Email ID cannot be empty</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular">
                                    <span>
                                        <select className="form-select font-14 font-regular" aria-label="Default select example" id='IsSSOAccount' onChange={(e) => regexValidation(e)}>
                                            <option selected value='Select Authentication Type' className='grayColor'>Select Authentication Type</option>
                                            <option value="Microsoft">Microsoft SSO</option>
                                            <option value="Email">Email</option>
                                        </select>
                                        <label id="invalidAuthType" className="required" hidden={true} >Please select any one option</label>
                                    </span>
                                </td>
                                <td className="table-head font-14 font-regular"><span className="status status-styling2 text-center font-14 font-semibold">Invite</span></td>
                                <td className="table-head font-14 font-regular text-center vertical-align text-center ">
                                    {(enableBtn == false) ?
                                        <img className="disableDiv icons" src="/Images/Add-icon.svg"></img>
                                        :
                                        <img className="icons anchorAsPointer" src="/Images/Add-icon.svg" onClick={handleAddClick}></img>}
                                </td>
                            </tr>
                            {gridBodyData()}
                        </tbody>
                    </table>
                </div>
                {/* <!-- channel list table ends here -->  */}
                {/* <!-- loadmore button section starts here --> */}
                {/* <div className="col-md-12 mt-3 mb-5 text-center">
                    {totalItemsCount > countPerPage ? <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={() => handleLoadMore()}>Load More</button> : ""}
                </div> */}
                {/* <!-- loadmore button section ends here --> */}
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>
    );
}