import React, { useEffect, useState } from 'react';
import { GridLoad } from '../../Services/ChannelMigrationServices';
import { DeleteLoadData } from '../../Services/ChannelMigrationServices';
import { AddLoadData } from '../../Services/ChannelMigrationServices';
import CryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import constants from '../../Common/Constants';

export default function GridPage() {
  let GridLoadarray: any[] = [];

  const [gridData, setGridData] = useState(GridLoadarray);
  let [clientID, setclientID] = useState(1);
  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("AzureAppID");
  const [indexPerPage, setIndexPerPage] = useState(1);
  let [statusText, setStatus] = useState("")
  let [appID, setappID] = useState("")
  let [appSecretID, setappSecretID] = useState("");
  const [enableBtn, setenableBtn] = useState(false);
  const [SlackSecretIDEyeOpen, setslackSecretIDEyeOpen] = useState(false);
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;


  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  var decryptedValue: any;
  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let clientIDValdidate: any;
  let clientToken: any;
  if (local != undefined) {
    let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
    decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    objClient = jwt(decryptedValue);
    clientIDValdidate = Math.random();
    clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
    SessionEpiredDateTime = objClient.CreatedAt;

  }


  const [countPerPage, setCoutPerPage] = useState(10);
  useEffect(() => {
    resetSessionTimer();
    deleteChild()
    clientID = clientIDValdidate
    setclientID(clientID)
    loadGridPage();
    //#region Event handlers Keypad click and mouse click
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])


  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      statusText = "Your session has expired. Please log in again to continue using the application."
      setStatus(statusText)

      //#region Show PopUp
      document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
      document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
      document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
      document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
      document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion


  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await DeleteLoadData(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(SessionEpiredDateTime);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {

      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(statusText)
      enableAlertPopUp()
      ////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true")
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("eventhit")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      clear();
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(statusText)
      enableAlertPopUp()
      document.getElementById("loader")?.setAttribute("hidden", "true")
    }
  }
  //#endregion


  const loadGridPage = async () => {
    try {
      clear();
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let x = document.getElementById("loader")
        x?.removeAttribute("hidden")
        let config = (
          {
            method: "POST",
            url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/AzureApp/getAzureApp`,
            token: clientToken,
            data:
            {
              "clientID": clientID,
              "offsetValue": indexPerPage,
              "columnName": `${columnName}`,
              "columnType": `${columnOrder}`
            }
          }
        )

        let data: any = await GridLoad(config);


        if (data.data.statusCode == 200 && gridData.length < 10) {
          setGridData(data.data.data);
          x?.setAttribute("hidden", "true")
        }
        else if (data.data.data == "Invalid authentication token provided") {
          clear();
          x?.setAttribute("hidden", "true")
          enableSessionExpiryPopup();
        }
        else if (data.data.statusCode != 200) {
          clear();
          statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(statusText)
          enableAlertPopUp()
          x?.setAttribute("hidden", "true")
        }

      }
      else {
        localStorage.clear();
      }
    }

    catch (error: any) {
      clear();
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(statusText)
      enableAlertPopUp()
      ////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true")
    }
  }



  const gridBodyData = () => {
    try {
      return gridData.map((obj, index) => {
        let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"
        return (
          <tr className={rowClass} >
            <td className="py-3 tabel-data-font-level1 ">{obj.TargetAppClientID}</td>
            <td className="py-3 tabel-data-font-level1 "><b>.......</b></td>
            <td className={obj.PrimaryAzureApp == false ? " py-3 tabel-data-font-level1 text-center" : " py-3 tabel-data-font-level1 anchorAsPointer text-center"}>
              {/* <img className="icon" src={deleteIcon} alt="DeleteIcon" id="DeleteIcon" onClick={e => sortClicked(e)} /> */}
              {obj.PrimaryAzureApp == null ?
                <img className="icons" src='/Images/delete-icon.svg' id={"delete" + obj.TargetAppClientID} onClick={e => deleteClicked(e)} />
                : <div className='disableDiv'>  <img className="icons" src="/Images/delete_icon.svg" />    </div>
              }
            </td>
          </tr>)
      })
    }
    catch (error: any) {

    }
  }

  const deleteClicked = async (e: any) => {
    try {
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let id = e.target.id.replace('delete', '');
        let config = ({
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/AzureApp/deleteAzureApp`,
          data: {
            "clientID": clientID,
            "AzureAppID": `${id}`
          },
        }
        )
        document.getElementById("loader")?.removeAttribute("hidden")
        let data: any = await DeleteLoadData(config);
        statusText = data.data.statusText
        setStatus(statusText)
        if (data.data.statusCode == 200 && gridData.length < 10) {
          loadGridPage();
        }
        else if (data.data.data == "Invalid authentication token provided") {
          clear();
          document.getElementById("loader")?.setAttribute("hidden", "true")
          enableSessionExpiryPopup();
        }
        else if (data.data.statusCode != 200) {
          clear();

          statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(statusText)
          enableAlertPopUp()
          document.getElementById("loader")?.setAttribute("hidden", "true")

        }


      }
      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      clear();

      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(statusText)
      enableAlertPopUp()
      ////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true")

    }
  }

  let encryptBL = async (content: any, secretKey: any) => {
    try {
      var encryptedText = CryptoJS.AES.encrypt(content, secretKey).toString();
      return encryptedText;
    }
    catch (error) {
      throw (error)
    }
  }

  const addClicked = async () => {
    try {

      if (gridData.length <= 0) {
        setStatus("Please Connect the MS Teams Tenant!")
        enableAlertPopUp()
      }
      else {
        Validation();
        if (gridData.length >= 10) {
          clear();
          enableAlertPopUp()
        }
        else {
          if (clientIDValdidate != undefined && clientToken != undefined) {
            let config = (
              {
                method: "POST",
                token: clientToken,
                url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/AzureApp/addAzureApp`,
                data:
                {
                  "clientID": clientID,
                  "AzureAppID": `${appID}`,
                  "ClientSecretID": `${appSecretID}`
                  // `${await encryptBL(appSecretID,'gDtbomUK0EK9APvv_IPszw')}`
                }
              }

            )


            document.getElementById("loader")?.removeAttribute("hidden")
            let data: any = await AddLoadData(config);
            ////console.log("len", gridData.length);
            if (data.data.statusCode == 200 && gridData.length < 10) {

              loadGridPage();
            }
            else if (data.data.data == "Invalid authentication token provided") {
              clear();
              document.getElementById("loader")?.setAttribute("hidden", "true")
              enableSessionExpiryPopup();
            }
            else if (data.data.statusCode != 200) {
              clear();
              createDynamicListItem("Azure app addition failed due to any of the below mentioned reasons", ["Invalid client credentials provided", "Azure app already exists"])
              enableAlertPopUp()
              document.getElementById("loader")?.setAttribute("hidden", "true")

            }
          }
          else {
            localStorage.clear();
          }
        }
      }
    }
    catch (error: any) {
      clear();
      statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(statusText)
      ////console.log("error", error);
      document.getElementById("loader")?.setAttribute("hidden", "true")

    }
  }

  let clear = () => {
    ;
    const clearappID: any = document.getElementById("Appid");
    const clearAppSecret: any = document.getElementById("slackAppSecretID");
    clearappID.value = "";
    clearAppSecret.value = "";
    setenableBtn(false);
  }

  let Validation = () => {
    //PH_HF_08
    //"Inside the HandleChange method add the validation according 
    //to the requirement we can replace this by a Placeholder   

    // let result = (/(?:^\w+\.?[-+\w]*@\w+(?:[-.]\w+)*\.[a-zA-Z]{2,}$)^.{6,320}$|^\w+\.?[-+\w]*@\w+(?:[-.]\w+)*\.[a-zA-Z]{2,}$/).test(mailID)
    if (appID != "" && appSecretID != "") {
      setenableBtn(true)


    }
  }

  const handleSecret = (e: any) => {
    appSecretID = e.target.value
    setappSecretID(appSecretID)
    if (appSecretID == '') {
      ////console.log("False", appSecretID);
      let x = document.getElementById("appSecretID")
      x?.removeAttribute('hidden')
      setenableBtn(false)
      setslackSecretIDEyeOpen(false)
    }
    else {
      let x = document.getElementById("appSecretID")
      x?.setAttribute('hidden', 'hidden')
      Validation()
    }


  }

  const regexValidation = (e: any) => {
    appID = e.target.value
    setappID(appID)

    if (appID == '' || appID.length == 0) {
      ////console.log("False", appID);
      let x = document.getElementById("appID")
      x?.removeAttribute('hidden')
      setenableBtn(false)
    }
    else {
      let x = document.getElementById("appID")
      x?.setAttribute('hidden', 'hidden')
      Validation()
    }
  }

  const enableAlertPopUp = () => {
    document.getElementById("alertPopUp")?.removeAttribute("aria-hidden");
    document.getElementById("alertPopUp")?.setAttribute("aria-modal", "true");
    document.getElementById("alertPopUp")?.setAttribute("role", "dialog");
    document.getElementById("alertPopUp")?.setAttribute("class", "modal fade show");
    document.getElementById("alertPopUp")?.setAttribute("style", "display:block");
    document.getElementById("fadeDiv")?.removeAttribute("hidden");
  }

  const createDynamicListItem = (popupText: any, arrReasons: any) => {
    try {
      statusText = popupText
      setStatus(statusText)
      let element = document.getElementById("dynamicData");
      let ulTag = document.createElement("ol");
      ulTag.classList.add('listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      throw error
    }
  }
  function deleteChild() {
    var e: any = document.getElementById("dynamicData");
    var child = e.lastElementChild;
    while (child) {
      e.removeChild(child);
      child = e.lastElementChild;
    }
  }




  const alertPopUpOkClick = () => {
    try {
      if (isSessionEpired === false) {
        //#region Hide PopUp
        deleteChild()
        document.getElementById("alertPopUp")?.setAttribute("class", "modal fade");
        document.getElementById("alertPopUp")?.setAttribute("style", "display:none");
        document.getElementById("alertPopUp")?.setAttribute("aria-hidden", "true");
        document.getElementById("alertPopUp")?.removeAttribute("aria-modal");
        document.getElementById("alertPopUp")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        //#endregion
      }
      else {
        window.location.pathname = "/Login";
      }
    } catch (error) {
      ////console.log("Issue Faced");
    }
  }



  return (
    // <div className="container-fluid p-2">
    <div className="row justify-content-center mt-5 card-container mx-0 ms-0 overall-res-padding" id='maindiv'>
      <div className="overlay" id='loader' hidden={true}>
        <div className=" Loader-Content-position ">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>

          </div>
        </div>
      </div>
      {/* <div id="fadeDiv" className="modal-backdrop fade show" hidden> */}
      <div className="col-lg-8 col-sm-12 col-md-12 py-5">
        <div className="row mb-2">
          <div className="col-md-12 col-sm-12 col-lg-12 col-12 mb-3">
            <label className="user-head-font font-26 font-bold" data-bs-toggle="modal"><a className="me-2 anchorAsPointer" href="/ChannelMigration/AU/ConversationMigration" title="Back"><img src="/Images/backarrow.svg" alt="Back arrow" /></a>Add Azure Apps</label>
            <div className="w-100 float-start">
              <label className=" inventory-details-font font-14 margin-top-one-rem font-regular me-2">A maximum of nine Azure Apps can be added to perform parallel migration</label>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-12 float-start mt-2">
          <div className='table-responsive px-1'>
            <table className="table table-border">
              <thead className="tabel-head-bg">
                <tr>
                  <th className="table-head font-14 font-bold">Azure app ID</th>
                  <th className="table-head font-14 font-bold azureAppCustomClass">Azure app secret ID</th>
                  <th className="table-head font-14 font-bold text-center">Action</th>
                </tr>
              </thead>
              <tbody >
                <tr className="tabel-body-bg" hidden={gridData.length >= 9 ? true : false}>
                  <td className=" py-3 tabel-data-font-level1" >
                    <input type="text" className="form-control  font-14" id="Appid" placeholder='Enter App Client ID' onChange={(e) => regexValidation(e)} />
                    <label id="appID" className='color-red' hidden={true}>Azure App ID cannot be empty</label>
                  </td>
                  <td className=" py-3 tabel-data-font-level1">
                    <div >
                      <div className="eyeiconDiv input-group box-shodow-filter">
                        <input type={SlackSecretIDEyeOpen ? "text" : "password"} className="form-control border-right-none  font-14" id="slackAppSecretID" placeholder='Enter App Secret ID'
                          name="slackAppSecretID" onChange={(e) => handleSecret(e)} />
                        <button className="btn btn-outline-secondary-cm">
                          <img src={SlackSecretIDEyeOpen == false && appSecretID != undefined && appSecretID != "" ? "/Images/eyeOpen-icon.svg" : "/Images/eyeClose-icon.svg"} alt="eye-open-icon" className={(SlackSecretIDEyeOpen == false && appSecretID != undefined && appSecretID != "") ? "eyeopeniconImg-cm" : "eyecloseiconImg-cm"} onClick={() => setslackSecretIDEyeOpen(SlackSecretIDEyeOpen ? false : true)} />
                        </button>
                      </div>
                    </div>
                    <label id="appSecretID" className='color-red float-start' hidden={true}>Azure app secret cannot be empty</label>
                  </td>
                  <td className={enableBtn == false ? " py-3 tabel-data-font-level1 text-center" : " py-3 tabel-data-font-level1 anchorAsPointer text-center"} >
                    {
                      (enableBtn == false) ? <div className='disableDiv'>  <img className="icons" src="/Images/Add-icon.svg" alt="add" />    </div> : <img className='anchorAsPointer' id="addbtn" src="/Images/Add-icon.svg" alt="add" data-bs-toggle="modal" data-bs-target="#success" onClick={addClicked} />
                    }
                  </td>
                </tr>
                {gridBodyData()}
              </tbody>


            </table>
          </div>

        </div>




        {/* </div> */}
      </div>



      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{statusText}</p>
                <div id="dynamicData"></div>
                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal" onClick={alertPopUpOkClick}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--success pop-up--> */}
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>



  );
}