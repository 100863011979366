import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/MemberMigrationServices';
import { ExportToCsv } from 'export-to-csv';
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";

export default function MemberMigrationTeams() {
    let GridLoadarray: any[] = [];

    let baseURL = constants.URL.REACT_APP_MEMMIG_BASEURL;
    const state = useLocation().state as any;
    const [gridData, setGridData] = useState(GridLoadarray);
    const [totalItemsCount, setTotalItemsCount] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [columnOrder, setColumnOrder] = useState("");
    const [columnName, setColumnName] = useState("");
    const [sorthide, setsorthide] = useState("");
    const [countPerPage, setCoutPerPage] = useState(10);
    const [clientID, setclientID] = useState(state.clientID);
    const [clientToken, setClientToken] = useState(state.clientToken)
    const [type, setType] = useState("memberMigrationTeams");
    const [sourceId, setSourceId] = useState(state.SourceChatID);
    const [teamId, setTeamId] = useState(state.TargetTeamID);
    const [channelId, setChannelId] = useState("");
    const [indexPerPage, setIndexPerPage] = useState(1);
    const [tmpsearchValue, settmpSearchValue] = useState("");
    const [exportButton, setExportButton] = useState(false);
    let [alertMessage, setAlertMessage] = useState("")
    let [backClick, setBackClick] = useState(false);
    let [exportsearchValue, setexportsearchValue] = useState("");
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout : NodeJS.Timeout;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let byteValue = objCryptoJS.AES.decrypt(state.clientToken, r_n_Key);
    let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
    let objClient: any = jwt(decryptedValue);
    SessionEpiredDateTime = objClient.CreatedAt;


    useEffect(() => {
        resetSessionTimer();
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.add("container-bg-withoutFooter")
        loadGridPage();
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };

    }, [columnName, columnOrder, countPerPage])

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            

            //#region Show PopUp
            if(backClick===false){
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage)
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
            }
        } catch (error) {

        }
    }
    //#endregion


    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                   let token = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                   setClientToken(token);
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            alertMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setAlertMessage(alertMessage)

            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
           InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            alertMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setAlertMessage(alertMessage)
           
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion



    const loadGridPage = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let config = (
                {
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
                    data:
                    {
                        "clientID": clientID,
                        "offsetValue": indexPerPage,
                        "columnName": `${columnName}`,
                        "columnType": `${columnOrder}`,
                        "searchString": `${searchValue}`,
                        "type": `${type}`,
                        "sourceId": `${sourceId}`,
                        "teamId": `${teamId}`,
                        "channelId": `${channelId}`,
                        "export": ''
                    }
                }
            )
            let data: any = await gridLoad(config);
            loader?.setAttribute('hidden', 'hidden');
            if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                setGridData([data.data.data]);
            }
            else if(data.data.data == "Invalid authentication token provided")
                    {
            enableSessionExpiryPopup();
             }
            else {
                setGridData(data.data.data[0]);
            }
            if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                let alertPopUp = document.getElementById("alertPopUp");
                alertPopUp?.removeAttribute("aria-hidden");
                alertPopUp?.setAttribute("aria-modal", "true");
                alertPopUp?.setAttribute("role", "dialog");
                alertPopUp?.setAttribute("class", "modal fade show");
                alertPopUp?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
            setTotalItemsCount(data.data.data[1][0].MemberMigrationCount)

        }
        catch (error: any) {
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }

    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
                return gridData.map((obj, index) => {
                    obj.SourceUserMailID = obj.SourceUserMailID != null ? obj.SourceUserMailID : "-"
                    obj.TargetUserName = obj.TargetUserName != null ? obj.TargetUserName : "-"
                    obj.TargetUserMailID = obj.TargetUserMailID != null ? obj.TargetUserMailID : "-"
                    let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"
                    return (
                        <tr className={rowClass} >
                            {
                                (obj.SourceUserName).length > 20 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.SourceUserName} >{(obj.SourceUserName).substring(0, 17)}...</td>
                                    :
                                    <td className="py-3 tabel-data-font-level1" data-tip={''}>{obj.SourceUserName}</td>
                            }
                            {
                                (obj.SourceUserMailID).length > 25 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.SourceUserMailID} >{(obj.SourceUserMailID).substring(0, 22)}...</td>
                                    :
                                    <td className="py-3 tabel-data-font-level1" data-tip={''} >{obj.SourceUserMailID}</td>
                            }
                            <td className="py-3 tabel-data-font-level1 ">{obj.TeamMigratedStatus}</td>
                            {
                                (obj.TargetUserName).length > 20 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetUserName} >{(obj.TargetUserName).substring(0, 17)}...</td>
                                    :
                                    <td className="py-3 tabel-data-font-level1" data-tip={''}>{obj.TargetUserName}</td>
                            }
                            {
                                (obj.TargetUserMailID).length > 25
                                    ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetUserMailID} >{(obj.TargetUserMailID).substring(0, 22)}...</td>
                                    :
                                    <td className="py-3 tabel-data-font-level1" data-tip={''}>{obj.TargetUserMailID}</td>
                            }
                            <span>
                                <ReactTooltip className='mySepecialClass' />
                            </span>
                        </tr>
                    )
                })
            }
            else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
                return (<tr> <td className="aligncenter" colSpan={5}></td>  </tr>)
            }
            else {
                return (<tr> <td className="aligncenter" colSpan={5}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
                    <p className="mt-2">No records found</p>
                </td>  </tr>)
            }
        }
        catch (error: any) {
        }
    }

    const sortClicked = (e: any) => {
        try {
            let sortID = e.target.id;
            let sortval = sortID.split('@');
            setColumnName(sortval[0])
            setColumnOrder(sortval[1])
            setsorthide(sortID)
        }
        catch (error: any) {
        }
    }

    const handleLoadMore = () => {
        try {
            setCoutPerPage(countPerPage + 10);
            setIndexPerPage(indexPerPage + 1);
        }
        catch (error: any) {
        }
    }

    const exportGrid = async () => {
        try {
            setExportButton(true)
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            let config = (
                {
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/memberMigration/getMemberMigrationGrid`,
                    data:
                    {
                        "clientID": clientID,
                        "offsetValue": indexPerPage,
                        "columnName": '',
                        "columnType": '',
                        "searchString": `${exportsearchValue}`,
                        "type": `${type}`,
                        "sourceId": `${sourceId}`,
                        "teamId": `${teamId}`,
                        "channelId": `${channelId}`,
                        "export": 'true'
                    }
                }
            )
            let data: any = await gridLoad(config);
            loader?.setAttribute('hidden', 'hidden');
            if (data.data.statusCode != 200) {
                setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
                let alertPopUp = document.getElementById("alertPopUp");
                alertPopUp?.removeAttribute("aria-hidden");
                alertPopUp?.setAttribute("aria-modal", "true");
                alertPopUp?.setAttribute("role", "dialog");
                alertPopUp?.setAttribute("class", "modal fade show");
                alertPopUp?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
            }
            else if(data.data.data == "Invalid authentication token provided")
        {
            enableSessionExpiryPopup();
        }
            else {
                if (data.data.data != "No Records Found") {
                    let exportData = data.data.data[0];
                    const options = {
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        showTitle: false,
                        title: 'Member Migrated in Teams Details',
                        useTextFile: false,
                        useBom: true,
                        useKeysAsHeaders: true,
                        filename: "Member Migrated in Teams Details"
                    };
                    const csvExporter = new ExportToCsv(options);
                    csvExporter.generateCsv(exportData);

                    setExportButton(false)
                }
                else {
                    loader?.setAttribute('hidden', 'hidden');
                    setAlertMessage("No Records Found to Export!");
                    let alertPopUp = document.getElementById("alertPopUp");
                    alertPopUp?.removeAttribute("aria-hidden");
                    alertPopUp?.setAttribute("aria-modal", "true");
                    alertPopUp?.setAttribute("role", "dialog");
                    alertPopUp?.setAttribute("class", "modal fade show");
                    alertPopUp?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                }
            }
        }
        catch (error: any) {
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }

    const handleKeyDown = (e: any) => {
        try {
            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    setIndexPerPage(indexPerPage ? indexPerPage : 1);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {
        }
    }

    const handleSearch = (e: any) => {
        try {
            setSearchValue(e.target.value)
        }
        catch (error: any) {

        }
    }

    const handleSearchIcon = (e: any) => {
        try {
            if (searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    setIndexPerPage(indexPerPage ? indexPerPage : 1);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    exportsearchValue = searchValue;
                    setexportsearchValue(exportsearchValue);
                    loadGridPage();
                }
            }
        }
        catch (error: any) {
        }
    }
    const backClicked = () => {
        try {
            let bool:any = true;
            setBackClick(bool);
            window.location.pathname = "/MemberMigration";
           
           
        } catch (error) {
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            //#region Hide PopUp
            if (isSessionEpired === false) {
            let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                window.location.pathname = "/Login";
            }

            //#endregion
        } catch (error) {
        }
    }

    return (
        <div className="row justify-content-center card-container mx-0 ms-0 overall-res-padding">
            <div className="checkcenter checktop">
                <div className="col-md-12 col-12 float-start">
                    <h5 className="channel-header font-26 font-bold mt-4 " ><a onClick={(e) => backClicked()}><img src='/Images/backarrow.svg' className='px-2' alt="back-icon" /></a>Member Migration in Team</h5>
                    <div className="tab-content">
                        <div id="high-level-inventory" className="tab-pane active w-100">
                            <div className="w-100">
                                <div className="col-md-12 col-12 float-start">
                                    <div className="float-start checktop w-100 p-2">
                                        <div className="float-start ">
                                            <label className="font-14 font-bold py-2 pe-3" >Slack Channel Name :
                                                {
                                                    (state.SourceChatName).length > 30 ?
                                                        <span className="channel-font font-14 font-regular" data-tip={state.SourceChatName}> {(state.SourceChatName).substring(0, 27)}...</span>
                                                        :
                                                        <span className="channel-font font-14 font-regular" data-tip={''}> {state.SourceChatName}</span>
                                                }
                                            </label>
                                            <label className="font-14 overall-res-padding font-bold py-2">Team Name :
                                                {
                                                    (state.TargetTeamName).length > 30 ?
                                                        <span className="channel-font font-14 font-regular" data-tip={state.TargetTeamName}> {(state.TargetTeamName).substring(0, 27)}...</span>
                                                        :
                                                        <span className="channel-font font-14 font-regular" data-tip={''}> {state.TargetTeamName}</span>
                                                }
                                            </label>
                                        </div >
                                        <div className="float-lg-end float-sm-start float-md-end">
                                                    <div className="input-group search-margin-membermig mm-search-width box-shodow-filter mb-2 float-start">
                                                        <input type="text" className="form-control form-control-style-2 " placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                                                        <button className="common-btn-class btn btn-outline-secondary " type="button" onClick={handleSearchIcon} id="button-addon1"><img src='/Images/search.svg' alt="search-icon" /></button>
                                                    </div>
                                            <button className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-inventory-export text- px-3 float-start font-semibold font-16 " onClick={() => exportGrid()} ><img src='/Images/export-excel.svg' alt="export-excel" /> Export</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12 float-start mt-2 table-responsive p-1">
                                <table className="table table-border">
                                    <thead className="tabel-head-bg">
                                        <tr>
                                            <th className="table-head font-14 font-bold">Slack User Name<span>
                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="SourceUserName" id="SourceUserName@DESC" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserName@DESC" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="SourceUserName" id="SourceUserName@ASC" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserName@DESC" ? false : true} />
                                            </span></th>

                                            <th className="table-head font-14 font-bold">Slack User Mail Address
                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="SourceUserMailID" id="SourceUserMailID@DESC" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserMailID@DESC" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="SourceUserMailID" id="SourceUserMailID@ASC" onClick={e => sortClicked(e)} hidden={sorthide == "SourceUserMailID@DESC" ? false : true} />
                                            </th>
                                            <th className="table-head font-14 font-bold">Member Migration Status in Team
                                            </th>
                                            <th className="table-head font-14 font-bold">MS Teams Username
                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="TargetUserName" id="TargetUserName@DESC" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserName@DESC" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="TargetUserName" id="TargetUserName@ASC" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserName@DESC" ? false : true} />

                                            </th>
                                            <th className="table-head font-14 font-bold">MS Teams User Mail Address
                                                <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="TargetUserMailID" id="TargetUserMailID@DESC" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserMailID@DESC" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="TargetUserMailID" id="TargetUserMailID@ASC" onClick={e => sortClicked(e)} hidden={sorthide == "TargetUserMailID@DESC" ? false : true} />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {gridBodyData()}
                                    </tbody>
                                </table>
                               
                            </div>
                            <div className="col-md-12  mb-5 text-center">
                                    {totalItemsCount > countPerPage ?
                                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mt-3 mb-5" onClick={() => handleLoadMore()}>Load More</button>
                                        : ""}
                                </div>
                        </div>
                        <div id="inventory-summary" className="tab-pane fade">
                            <div className="row float-end">
                                <div className="col-md-12 mb-3">
                                    2
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="loader-position loadercss Loader-Content-position">
                    <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="align-center">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    );
}