import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MemberMigrationTeams from './MemberMigrationTeams';
import MemberMigrationChannels from './MemberMigrationChannels';
import MemberMigrationGrid from './MemberMigrationGrid';

function MemberMigrationRouting() {
  return (
    <Router>
      <Switch>
        <Route path="/MemberMigration/TeamMembers" component={MemberMigrationTeams}></Route>
        <Route path="/MemberMigration/ChannelMembers" component={MemberMigrationChannels}></Route>
        <Route path="/MemberMigration" component={MemberMigrationGrid}></Route>
        <Route path="/">
          <Redirect to="/MemberMigration" ></Redirect>
        </Route>
      </Switch>
    </Router>
  );
}

export default MemberMigrationRouting;
