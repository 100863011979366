import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/InventoryServices';
import { config } from 'process';
import { ExportToCsv } from 'export-to-csv';
import { useHistory, useLocation } from 'react-router-dom';
import { idText } from 'typescript';
import ReactTooltip from "react-tooltip";
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import constants from '../../Common/Constants'




export function UserGroupMembers() {
  let GridLoadarray: any[] = [];

  const state: any = useLocation().state
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  let [searchValue, setSearchValue] = useState("");
  let [exportsearchValue, setexportsearchValue] = useState("");

  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("UserName");
  const [sorthide, setsorthide] = useState("");
  const [clientID, setclientID] = useState(state.ClientID);
  const [token, settoken] = useState(state.token);
  const [adminToken, setadminToken] = useState(state.adminToken);
  const [InventorySummaryID, setInventorySummaryID] = useState(state.InventorySummaryID);
  const [usergroupname, setUsergroupname] = useState(state.UserGroupName);
  const [type, setType] = useState("Grid");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [groupID, setgroupID] = useState(state.UserGroupID);
  let [ErrorMessage, setErrorMessage] = useState("");
  const [exportbtn, setexportbtn] = useState(false)
  const [norecords, setnorecords] = useState();
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

  let byteValue = objCryptoJS.AES.decrypt(state.token, r_n_Key);
  let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
  let objClient: any = jwt(decryptedValue);
  SessionEpiredDateTime = objClient.CreatedAt;

  const [countPerPage, setCoutPerPage] = useState(10);
  const history = useHistory()
  useEffect(() => {
    resetSessionTimer();
    let removeFooter: any = document.getElementById("MainContainerMFE");
    removeFooter.classList.add("container-bg-withoutFooter")
    loadGridPage();
    //#region Event handlers Keypad click and mouse click
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
     
    ////console.log(state);

  }, [columnName, columnOrder, countPerPage])



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      ErrorMessage = "Your session has expired. Please log in again to continue using the application."
      setErrorMessage(ErrorMessage);

      //#region Show PopUp
      let popup = document.getElementById("alertPopUp")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (state.ClientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: state.token
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(state.token);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          state.token = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(state.token);
          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
      setErrorMessage(ErrorMessage);
      let popup = document.getElementById("alertPopUp")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion


  const exportMethod = async () => {
    try {
      let a: any = document.getElementById("loader");
      a.removeAttribute("hidden");
      let config = (
        {
          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getUserGroupUsers`,
          token: token,

          data: {

            "clientID": clientID,
            "InventorySummaryID": parseInt(InventorySummaryID),
            "type": "Export",
            "sortColumn": columnName,
            "searchText": exportsearchValue,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage,
            "GroupID": `${groupID}`

          }
        }
      )

      if( adminToken !== null){
        (config.data as any).token = adminToken
      }
      let data: any = await gridLoad(config);

      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")

        //#region Show PopUp
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else if (data.data.data == "no records were fetched") {
        ErrorMessage = "No Records Found in the Grid";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else {
         
        let exportData = data.data.data.InventoryUserGroupUsers[0]

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: 'UserGroup Members',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "UserGroup Members"
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(exportData);
      }
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const loadGridPage = async () => {
    try {
      let a: any = document.getElementById("loader");
      a.removeAttribute("hidden");
      let config = (
        {
          method: "POST",
          url: `${process.env.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getUserGroupUsers`,
          token: token,

          data: {

            "clientID": clientID,
            "InventorySummaryID": parseInt(InventorySummaryID),
            "type": `${type}`,
            "sortColumn": `${columnName}`,
            "searchText": `${searchValue}`,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage,
            "GroupID": `${groupID}`

          }
        }
      )
       
      if( adminToken !== null){
        (config.data as any).token = adminToken
      }
      let data: any = await gridLoad(config);
      let b: any = document.getElementById("loader");
      b.setAttribute("hidden", "hidden");
      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")

        //#region Show PopUp
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else {
        //debugger
        if (data.data.data == "no records were fetched" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data == "no records found") {
          setGridData(["No Records Found"]);
          setTotalItemsCount(0);
        }
        else {
          setGridData(data.data.data.InventoryUserGroupUsers[0]);
          setTotalItemsCount(data.data.data.InventoryUserGroupUsers[1][0].UserGroupUsersCount)
        }
      }
    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {

        return gridData.map((obj, index) => {
          let rowindex = (index % 2) == 0 ? "tabel-body-bg" : "tabel-body bgsecondary"
          return (
            <tr className={rowindex} >
              <td className="py-3 tabel-data-font-level1 ">{obj.UserName}</td>
              <td className="py-3 tabel-data-font-level1 ">{obj.UserMailID}</td>


            </tr>)
        })
      }

      else if (gridData[0] == undefined && gridData[0].UserGroupName == undefined) {
        return (<tr> <td className="centertext" colSpan={6}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={8}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
              <p className="mt-2">No records found</p></td>
          </tr>)
      }
    }
    catch (error: any) {
      ////console.log(error);
    }
  }
  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setColumnName(sortval[0])
      setColumnOrder(sortval[1])
      setsorthide(sortID)

    }
    catch (error: any) {

    }
  }
  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value)
    }
    catch (error: any) {

    }
  }
  const handleDownkey = (e: any) => {
    try {
      if (e.keyCode == 13) {
        exportsearchValue = searchValue;
        setexportsearchValue(exportsearchValue);
        setSearchValue(e.target.value);
        loadGridPage();
      }
    }
    catch (error: any) {

    }
  }
  const handleLoadMore = () => {
    try {
       
      setIndexPerPage(indexPerPage + 1);
      setCoutPerPage(countPerPage + 10);

    }
    catch (error: any) {

    }
  }
  const handleExport = () => {
    try {
      exportMethod();
      setexportbtn(true);
    }
    catch (error: any) {

    }
  }
  const handleback = () => {
    try {
      backMethod();
    }
    catch (error: any) {

    }
  }
  const backMethod = () => {

    let ID: any = []
    ID["ID"] = (state.InventorySummaryID)
    ID["InventoryName"] = ((state.InventoryName));
    ID["SlackWorkspaceName"] = (state.SlackWorkspaceName);
    ID["ClientID"] = clientID;
    ID["token"] = token;
    history.push({
      pathname: "/Inventory/Usergroups",
      state: ID
    })
    // window.location.reload();
    // window.location.pathname = '/Inventory/Usergroups';
  }
  const popUpOkClick = (type: string) => {

    try {

      if (isSessionEpired === false) {

        //#region Hide PopUp

        let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")

        popup?.setAttribute("class", "modal fade");

        popup?.setAttribute("style", "display:none");

        popup?.setAttribute("aria-hidden", "true");

        popup?.removeAttribute("aria-modal");

        popup?.removeAttribute("role");

        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

        let migrateButton = document.getElementById("migrateButton")

        migrateButton?.setAttribute('disabled', 'disabled');

        // loadGridPage();



        //#endregion
      }
      else {
        window.location.pathname = "/Login";

      }

    } catch (error) {

      ////console.log("Issue Faced");

    }

  }
  return (
    <div className="row justify-content-center card-container mx-0 ms-0 single-grile-grid-res">

      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

      <div className="overlay" id='loader' hidden={true}>
        <div className="loader-position Loader-Content-position">
          <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>

          </div>
          {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
        </div>
      </div>

      <div className="col-md-11  mt-4">
        <div className="col-md-12 col-12 col-sm-12 col-lg-12 float-start">
          <label className="user-head-font font-26 font-bold mb-2"><a className="me-2 anchorAsPointer" onClick={handleback} title="Back"><img title="Back" src="/Images/backarrow.svg" alt="Back arrow" /></a>Users</label>
          <div className="w-100 float-start">

          </div>
          <div className="tab-content">
            <div id="high-level-inventory" className="tab-pane active w-100">

              <div className="IndividualScreenHeadingcss w-100">
                <div className="col-md-12 col-lg-12 col-sm-12 float-start">
                  <div className="float-start  w-100 ">
                    <div className="col-width float-start Inv_MarginTopContentSearch">
                      <label className="font-14 my-2 pe-2 inventory-single-grid pb-2 font-bold">Inventory :                         {
                        state.InventoryName?.length > 36 ? <span data-tip={state.InventoryName} className="font-regular font-14">{state.InventoryName.substring(0, 33)}...</span> :
                          <span data-tip="" className="font-regular font-14">{state.InventoryName}</span>
                      }
                      </label>
                      <label className="font-14  my-2 pb-2 font-bold ">User Group Name: <span className="channel-font font-14 font-regular">{usergroupname} </span></label>
                    </div >
                    <div className="float-end ">

                      <button className="btn  my-2 btn-inventory-export btn-scondary-custom text- px-3 float-end font-semibold ms-2  font-16" onClick={handleExport}><img src="/Images/export-excel.svg" alt="export-excel" /> Export</button>

                      <div className="row float-end">
                        <div className="col-md-12 my-2 sg-search-res2">
                          <div className="input-group box-shodow-filter">
                            <input type="text" className="form-control form-control-style-2" placeholder="Search" value={searchValue} onKeyDown={handleDownkey} onChange={handleSearch} aria-describedby="button-addon1" />
                            <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={() => loadGridPage()}><img src='/Images/search.svg' alt="search-icon" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <p className='mb-0 font-semibold font-16 text-nowrap float-end mt-2 me-3 pt-2'># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {gridData[0] != "No Records Found"?totalItemsCount:0}</p>

                    </div>

                  </div>
                </div>
              </div>

              <div className="col-md-12 col-12 col-lg-12 col-sm-12 float-start mt-2 table-responsive p-1">
                <table className="table table-border">
                  <thead className="tabel-head-bg">
                    <tr>
                      <th className="table-head font-14 font-bold">User Name
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="UserName@desc" onClick={e => sortClicked(e)}
                          hidden={sorthide == "UserName@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="UserName@asc" onClick={e => sortClicked(e)}
                          hidden={sorthide == "UserName@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">Email Address
                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="UserMailID@desc" onClick={e => sortClicked(e)}
                          hidden={sorthide == "UserMailID@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="UserMailID@asc" onClick={e => sortClicked(e)}
                          hidden={sorthide == "UserMailID@desc" ? false : true} />
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    {gridBodyData()}

                  </tbody>
                </table>




              </div>
              <div className="col-md-12 mt-3 mb-5 text-center">
                {totalItemsCount > countPerPage ?
                  <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={handleLoadMore} >Load More</button>
                  : ""}
              </div>

            </div>

            <div id="inventory-summary" className="tab-pane fade">
              <div className="row float-end">
                <div className="col-md-12 mb-3">

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
              </div>
            </div>
          </div>
        </div>


      </div>
      <span><ReactTooltip /></span>

      {/* <!--Alert pop-up--> */}




    </div>

  );
}