import { ExportToCsv } from "export-to-csv";
import { useState, useEffect } from "react";
import { CreateTeamChannel, saveMapping, DeleteLoadData, GridLoad } from "../../Services/ChannelMigrationServices";
import { SubmitValidation } from '../../Services/ChannelMigrationServices'
import * as XLSX from 'xlsx';
import Enumerable, { from } from "node-enumerable";
import WizardNavigation from './WizardSA'
import BackNavigation from "./BackNavigation";
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import ReactTooltip from "react-tooltip";
import { channel } from "diagnostics_channel";
import { json } from "stream/consumers";
import constants from '../../Common/Constants';


export default function TeamChannelCreationAndMapping() {

  let GridLoadarray: any[] = [];
  let length = 0;
  let mappingDetails: any[] = [];
  let settingsDetails: any = []
  let successdata: any = [];//data for he success sheet downloaded in taem creation
  let teamSuccessData: any = [];
  let channelSuccessData: any = [];
  let failedTeamData: any = [];
  let failedChannelData: any = [];
  let TeamDetails: any = []
  let mappingFailedData: any = [];
  const [gridData, setGridData] = useState<any>(GridLoadarray);
  let [totalItemsCount, setTotalItemsCount] = useState(0);
  let [createStatus, setCreateStatus] = useState(false)
  const [Searchtext, setSearchtext] = useState("");
  const [MigrationType, setMigrationType] = useState("Standard");
  const [exportsuccessdata, setexportsuccessdata] = useState(successdata);
  let [exportTeamCreationSuccessData, setExportTeamCreationSuccessData] = useState(teamSuccessData);
  let [exportChannelCreationSuccessData, setExportChannelCreationSuccessData] = useState(channelSuccessData);
  let [exportTeamsfaileddata, setexportTeamfaileddata] = useState(failedTeamData);
  let [exportChannelfaileddata, setexportChannelfaileddata] = useState(failedChannelData);
  let [status, setStatus] = useState("");
  const [Sorttype, setSorttype] = useState("asc");
  const [SortColumn, setSortColumn] = useState("SourceChannelName");
  const [sorthide, setsorthide] = useState("");
  const [PageIndex, setPageIndex] = useState(1);
  let [clientID, setclientID] = useState(0);
  const [tmpsorthide, settmpsorthide] = useState("");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  const [subVal, setsubVal] = useState(false);
  let [countPerPage, setCoutPerPage] = useState(10);
  const [SlackWorkspaceID, setSlackWorkspaceID] = useState()
  const [file1, setfile1] = useState(false);
  const [isArr, setIsArr] = useState(0);
  const [csvData, setCsvData] = useState([{ "SlackChannelName": "", "MSTeamChannelName": "", "MSTeamName": "" }]);
  let selectedCheckbox: any = [];
  let [selectedCheckBoxArray, setSelectedCheckBoxArray] = useState(selectedCheckbox)
  const [exportChannelCreationStatus, setExportChannelCreationStatus] = useState(0);
  let [isSourceDelayLoader, setIsSourceDelayLoader] = useState(false)
  let [TargetTeamDetails, setTeamDetails] = useState(TeamDetails)
  let [MappingDetails, setMappingDetails] = useState(mappingDetails)
  let [mappingCSV, setMappingCsv] = useState(mappingFailedData)
  let [inventoryCount, setInventoryCount] = useState(0)
  let [settingsDeatils, setSettingsDetails] = useState(settingsDetails)
  let [uploadFile, setUploadFile] = useState(false)
  let [isTeamInvalidTemplate, setIsTeamInvalidTemplate] = useState(false)
  let [loaderContent, setLoaderContent] = useState("")
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);

  // variable Declaration for Select All
  const [selectAllCount, setSelectAllCount] = useState(0);
  const [selectAllShowHide, setSelectAllShowHide] = useState(true)
  const [isFullSelect, setIsFullSelect] = useState(false);
  const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
  const [operation, setOperation ] = useState("")
  const [ disabledCount, setDisabledCount] = useState(0);
  const [ validTotalCount, setValidTotalCount] = useState(0);

  let [adminToken, setAdminToken] = useState("");
  let adminTok : any = localStorage.getItem("aid_t");
  let [isAdmin, setIsAdmin] = useState(false);
  let [isEnableDeleteChecked, setIsEnableDeleteChecked] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;

  let initialLoad: any = false;
  let local: any = localStorage.getItem("cid_t");
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
  let objClient: any;
  let objAdmin : any;
  var decryptedValue: any;
  var decryptedAdminValue : any;

  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  const a_t_Key : any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
  let clientIDValdidate: any;
  let clientToken: any;
  if (local != undefined) {
    try {
      let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
      decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
      objClient = jwt(decryptedValue);
      clientIDValdidate = Math.random();
      clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
      SessionEpiredDateTime = objClient.CreatedAt;

    } catch (error) {
      // console.log("error in Client Token decryption",error)
    }
  }

  useEffect(() => {
    if (subVal == false) {
      resetSessionTimer();
      //#region Event handlers Keypad click and mouse click
      window.addEventListener('keydown', resetSessionTimer);
      window.addEventListener('click', resetSessionTimer);
      //#endregion
      setTotalItemsCount(0)
      deleteChild()
      clientID = clientIDValdidate
      setclientID(clientID)
      loadGridPage(operation);
      document.getElementById('createbtn')?.setAttribute("disabled", "disabled")
      let removeHeight: any = document.getElementById("MainContainerMFE");
      if (adminTok!=undefined){
        let adminByteValue = objCryptoJS.AES.decrypt(adminTok, a_t_Key);
        decryptedAdminValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
        objAdmin = jwt(decryptedAdminValue);
        if(objAdmin!=undefined && objAdmin!=null){
          isAdmin = true;
          setIsAdmin(isAdmin);
          adminToken = objCryptoJS.AES.encrypt(decryptedAdminValue, r_n_Key).toString();
          setAdminToken(adminToken);
          let enableDeleteElement = document.getElementById("enableDeletToggle")
          enableDeleteElement?.removeAttribute("hidden")
        }
      }  
      removeHeight.classList.remove("container-bg-withoutFooter")
      removeHeight.classList.remove("container-bg-withFooter")
      removeHeight.classList.add("channel-container-bg-withFooter")
      return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
      };
    }
  }, [SortColumn, Sorttype, countPerPage])

   // useEffect to handle Select All
   useEffect(() => {
    let selectAllelement:any = document.getElementById("selectAllCheckBox")
    selectAllelement.disabled = false
    let allGridDataDisabled = false
    
      // To Handle the Select All through Individual Checkboxes
      if (gridData.length != 0 && gridData[0] != "No Records Found" && selectAllShowHide !=true){
        // To Get the grid Data Count without Disabled CheckBoxes
          let tempArr:any =[]
          let disabledTempCount = 0
          gridData.forEach((obj:any)=>{
              let element:any = document.getElementById(JSON.stringify(obj))
              if(element != "" && element != null && element != undefined  && element.disabled == true){
                  tempArr.push(obj);
              }
          })
          disabledTempCount = gridData.length - tempArr.length
          setDisabledCount(disabledTempCount)

      // Check Whether all the CheckBoxes in the Grid are Disabled
        const allObjectsPresent = gridData.every((obj1: { SourceChannelID: any; }) =>
        tempArr.some((obj2: { SourceChannelID: any; }) => obj1.SourceChannelID === obj2.SourceChannelID)
          )
          if(allObjectsPresent){
              allGridDataDisabled = true
          }
          else{
              allGridDataDisabled = false
          }

        if (selectAllCount == 0) {
           // Possible Conditions when the SelectAll is UnChecked 
          if(selectAllShowHide == false){
            selectAllelement.disabled = false
          }

          if (selectedCheckBoxArray.length == disabledTempCount || selectedCheckBoxArray.length != disabledTempCount) {
            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj:any)=>{
                if(selectedCheckBoxArray.some((obj1:any)=>{
                    if(obj1.SourceChannelID == obj.SourceChannelID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length == disabledTempCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }

          }
          if(validTotalCount != 0 && (selectedCheckBoxArray.length == validTotalCount)){
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
        }
        if (selectAllCount == 1){
          // Possible Conditions when the SelectAll is Partial Select
          let isAllSelected = false 
            let tempCheckArr:any = []
            
            gridData.forEach((obj:any)=>{
                if(selectedCheckBoxArray.some((obj1:any)=>{
                    if(obj1.SourceChannelID == obj.SourceChannelID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length == disabledTempCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
              if(isFullSelect != true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
              }
              else{
                setSelectAllCount(2);
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
              }
                
            }
            else{
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
            }
        }
        else{
          if (selectAllCount == 1 && selectedCheckBoxArray.length != validTotalCount) {
              setSelectAllCount(0);
              setIsFullSelect(false)
              selectAllelement.checked = ""
          }
          if (selectAllCount == 1 && selectedCheckBoxArray.length == validTotalCount) {
            setSelectAllCount(2);
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
        }    
        }

        if (selectAllCount == 2) {
          // Possible Conditions when the SelectAll is Full Select
          if(operation != "search"){
            setSelectAllCount(2)
            setIsFullSelect(true)
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
        }
        else{
            if (selectedCheckBoxArray.length != validTotalCount){
                setSelectAllCount(0);
                setIsFullSelect(false)
                selectAllelement.checked = ""
            }
          }
        }

        // to handle the pageload of grid for Full Select
        if (validTotalCount == disabledTempCount && gridData.length !=0 && selectedCheckBoxArray.length == disabledTempCount) {
          setIsFullSelect(true);
          setSelectAllCount(2)
          selectAllelement.checked ="true";
          selectAllelement.className = "form-check-input me-2 check-alg-2" 
        }
        handleCheckedItems()
      }
      if(gridData.length == 0 || gridData[0] =="No Records Found"||allGridDataDisabled == true || selectAllShowHide == true ){
        setSelectAllCount(0);
        setIsFullSelect(false)
        selectAllelement.checked = ""
        selectAllelement.disabled = true
      }

  }, [gridData]);

  useEffect(() => {

     // To Handle the Select All through Individual Checkboxes
     let selectAllelement:any = document.getElementById("selectAllCheckBox")
     let allDataBinded = false
     // Condition to Check whether all the Data From the Backend is Binded in the Grid
      if(gridData.length == totalItemsCount){
        allDataBinded = true
      }

     if (gridData.length != 0) {
       if (selectAllCount == 0) {
        // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
         if (selectedCheckBoxArray.length == disabledCount && isFullSelect == false && allDataBinded == false) {
          let isAllSelected = false 
          let tempCheckArr:any = []
  
          gridData.forEach((obj:any)=>{
              if(selectedCheckBoxArray.some((obj1:any)=>{
                  if(obj1.SourceChannelID == obj.SourceChannelID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
          if(isAllSelected){
            setSelectAllCount(1);
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
          }
         }
         if(selectedCheckBoxArray.length == disabledCount && isFullSelect == true  || selectedCheckBoxArray.length == disabledCount && allDataBinded == true){
           setSelectAllCount(2);
           selectAllelement.checked ="true";
           selectAllelement.className = "form-check-input me-2 check-alg-2"
         }
         if(selectedCheckBoxArray.length != disabledCount && isFullSelect == true){
          let isAllSelected = false 
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(selectedCheckBoxArray.some((obj1:any)=>{
                  if(obj1.SourceChannelID == obj.SourceChannelID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
          if(isAllSelected == true){
              setSelectAllCount(2);
              setIsFullSelect(true)
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
         }
         if(selectedCheckBoxArray.length != disabledCount && isFullSelect == false){
          let isAllSelected = false 
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(selectedCheckBoxArray.some((obj1:any)=>{
                  if(obj1.SourceChannelID == obj.SourceChannelID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
          if(isAllSelected == true){
              setSelectAllCount(1);
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
          }
         }
       }
       if (selectAllCount == 1) {
         // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select
          let isAllSelected = false 
          let tempCheckArr:any = []
          
          gridData.forEach((obj:any)=>{
              if(selectedCheckBoxArray.some((obj1:any)=>{
                  if(obj1.SourceChannelID == obj.SourceChannelID){
                      return true
                  }
              })){
                  tempCheckArr.push(obj)
              }

          })
          if(tempCheckArr.length == disabledCount){
              isAllSelected = true
          }
          if(isAllSelected == true){
              setSelectAllCount(1);
              selectAllelement.checked ="true";
              selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
          }
          else{
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
          }
         
       }
       if (selectAllCount == 2) {
         // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
         if (selectedCheckBoxArray.length != validTotalCount) {
           setSelectAllCount(0);
           setIsFullSelect(false)
           selectAllelement.checked = ""
         }
       }
     }

    handleCheckedItems();
}, [selectedCheckBoxArray]);



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      status = "Your session has expired. Please log in again to continue using the application."
      setStatus(status)

      //#region Show PopUp
      document.getElementById("success")?.removeAttribute("aria-hidden");
      document.getElementById("success")?.setAttribute("aria-modal", "true");
      document.getElementById("success")?.setAttribute("role", "dialog");
      document.getElementById("success")?.setAttribute("class", "modal fade show");
      document.getElementById("success")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {
      if (clientIDValdidate != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: clientToken
        }

        let data: any = await GridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          //console.log(SessionEpiredDateTime);
          //#endregion



        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)

      document.getElementById("success")?.removeAttribute("aria-hidden");
      document.getElementById("success")?.setAttribute("aria-modal", "true");
      document.getElementById("success")?.setAttribute("role", "dialog");
      document.getElementById("success")?.setAttribute("class", "modal fade show");
      document.getElementById("success")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("eventhit")
      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)

      document.getElementById("success")?.removeAttribute("aria-hidden");
      document.getElementById("success")?.setAttribute("aria-modal", "true");
      document.getElementById("success")?.setAttribute("role", "dialog");
      document.getElementById("success")?.setAttribute("class", "modal fade show");
      document.getElementById("success")?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
    }
  }
  //#endregion



  /*method for grid loading */
  const loadGridPage = async (operation:any) => {
    try {
      if (selectedCheckBoxArray.length == 0 || selectedCheckBoxArray == undefined) {
        let x = document.getElementById("CreateMappingbtn")
        x?.setAttribute("disabled", "disabled");
      }
      if (uploadFile == false) {
        let z = document.getElementById("submit")
        z?.setAttribute("disabled", "disabled");
      }
      let x = document.getElementById("createbtn")
      x?.setAttribute("disabled", "disabled");
      // let y = document.getElementById("createmappingbtn");
      // y?.setAttribute("disabled", "disabled");
      let loader = document.getElementById("loaderWithoutContent");
      loader?.removeAttribute('hidden');
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let config = {
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/ChannelMappingData`,
          data:
          {

            "clientID": clientID,
            "MigrationType": `${MigrationType}`,
            "SortColumn": `${SortColumn}`,
            "Sorttype": `${Sorttype}`,
            "Searchtext": `${Searchtext}`,
            "pageIndex": PageIndex

          }
        }
        let data: any = await GridLoad(config)
        loader?.setAttribute('hidden', 'true');
        if (data.data.statusCode == 200) {
          if (data.data.data == "No Records Found" || data.data.data[0].length == 0) {
            setGridData(["No Records Found"]);
            // setTotalItemsCount(0)
            settingsDeatils = data.data.data[1]
            setSettingsDetails(settingsDeatils)
            inventoryCount = data.data.data[2][0].InventoryCount
            setInventoryCount(inventoryCount)
          }
          else {

            if(operation == "search"){
              setTotalItemsCountFeature(data.data.data[3][0].ChannelsCount)
            }
            else{
              setTotalItemsCount(data.data.data[3][0].ChannelsCount)
            }

            // setTotalItemsCount(data.data.data[3][0].ChannelsCount)
            setGridData(data.data.data[0])//data.data.data[0]);
            settingsDeatils = data.data.data[1]
            setSettingsDetails(settingsDeatils)
            inventoryCount = data.data.data[2][0].InventoryCount
            setInventoryCount(inventoryCount)
          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          loader?.setAttribute('hidden', 'true');
          enableSessionExpiryPopup();
        }
        else {
          loader?.setAttribute('hidden', 'true');
          status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(status)
          alertpopup()

        }

      }
      else {
        localStorage.clear();
      }
    }
    catch (error) {
      document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }
  /*method for sorting */
  const sortClicked = (e: any) => {
    try {

      let sortID = e.target.id;
      let sortval = sortID.split('@');

      setSortColumn(sortval[0])
      setSorttype(sortval[1])
      setsorthide(sortID)
      if (subVal == false) {
        setPageIndex(PageIndex ? PageIndex : 1);
        setCoutPerPage(countPerPage ? countPerPage : 10);
        loadGridPage(operation);
      }
      else {
        let arr: any[];
        if (Sorttype == "desc") {

          arr = gridData.sort(function (a: any, b: any) {

            var textA = a[SortColumn].toUpperCase();

            var textB = b[SortColumn].toUpperCase();

            return textA == textB ? 0 : textA < textB ? 1 : -1;

          });

        }

        else {

          arr = gridData.sort(function (a: any, b: any) {

            var textA = a[SortColumn].toUpperCase();

            var textB = b[SortColumn].toUpperCase();

            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;

          });

        }
        //setGridData(arr);
        setGridData(arr.slice(0, countPerPage));

         // to Uncheck the Check Boxes after Binding
         arr.forEach((obj:any)=>{
          let element:any = document.getElementById(JSON.stringify(obj))
          if(element != "" && element != undefined && element != null){
            element.checked = false;
          }
      })

      }

    }
    catch (error: any) {
      ////console.log("error", error);
    }
  }
  /*method for delete functionality */
  let array: any[] = [];
  const handledelete = async (e: any) => {
    try {

      let id = e.target.id.replace('delete', '');
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let config = {
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/deleteMapping`,
          data: {

            "clientID": clientID,
            "SourceChannelID": `${id}`,
            "isAdminDelete" : isEnableDeleteChecked,
            "adminToken" : adminToken,
            "deleteType" : "Channel",
            "summaryID" : Number(e.target.dataset.migrationsummaryid)

          }
        }
        if(adminTok !=undefined && adminTok !== null){
          (config.data as any).token = adminToken
        }

        let data: any = await DeleteLoadData(config);
        if (data.data.statusCode == 200) {
          if (subVal == true) {
            gridData.forEach((element: { SourceChannelID: any; IsConversationMigrated: null; }) => {
              if (element.SourceChannelID == id) {
                element.IsConversationMigrated = null
                let z = document.getElementById("submit")
                z?.setAttribute("disabled", "disabled");
              }
            }
            );
            setGridData([]);
            setGridData(gridData);
          }
          else {

            loadGridPage(operation);
          }
        }
        else if (data.data.data == "Invalid authentication token provided") {
          document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
          enableSessionExpiryPopup();
        }
        else {
          document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
          status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(status)
          alertpopup()
        }
      } else {
        localStorage.clear();
      }
    }
    catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);

    }
  }
  /*method for the loadmore functionality */
  const handleLoadMore = () => {

    try {

      if (isArr == 0) {
        let count1 = countPerPage + 10;
        let index1 = PageIndex + 1;
        setPageIndex(index1);
        setCoutPerPage(count1);
      }
      else {
        let count1 = countPerPage + 10;
        let index1 = PageIndex + 1;
        setPageIndex(index1);
        setCoutPerPage(count1);
        setGridData([]);
        setGridData(csvData.slice(0, count1))

        // to Uncheck the Check Boxes after Binding
        csvData.forEach((obj:any)=>{
          let element:any = document.getElementById(JSON.stringify(obj))
          if(element != "" && element != undefined && element != null){
            element.checked = false;
          }
        })
        
        // To handle the Select All When Load More is Clicked
        let selectAllelement:any = document.getElementById("selectAllCheckBox")
        if (selectAllCount == 1) {
          if(isFullSelect == true){
              setSelectAllCount(2);
              selectAllelement.checked = "true";
              selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
          else{
              setSelectAllCount(0);
              selectAllelement.checked = "";
          }
        } 
        else if(selectAllCount == 2) {
                setSelectAllCount(2);
                selectAllelement.checked = "true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
        }
    }
    catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }

  // methods for Select All 

    
  const handleCheckedItems = async () => {
    if (selectedCheckBoxArray.length != 0) {
      gridData.forEach((obj:any) => {
        for (let i = 0; i < selectedCheckBoxArray.length + 1; i++) {
          if (JSON.stringify(selectedCheckBoxArray[i]) == JSON.stringify(obj)) {
            let element: any = document.getElementById(JSON.stringify(obj));
            if (element != "" && element != null && element != undefined && element.disabled == false) {
              element.checked = "true";
              break;
            }
          }
          else{
            let element: any = document.getElementById(JSON.stringify(obj));
            element.checked = "";
          }
        }
      });
    }
  };

  const handleSelectAll = async () =>{
    try{
      // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
      let count: any = undefined;
      if (csvData.length == gridData.length) {
        setIsFullSelect(true);
        count = selectAllCount + 2;
        if (count > 2) {
          count = 0;
        }
      } else {
        count = selectAllCount + 1;
        if (count > 2) {
          count = 0;
        }
      }
      // to handle the Select All CheckBox CSS
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      if(count==2){
        selectAllelement.checked = "true";
        selectAllelement.className = "form-check-input me-2 check-alg-2"
      }
      else if(count == 1){
        selectAllelement.checked ="true";
        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
      }
      else{
        selectAllelement.checked = "";
      }

      setSelectAllCount(count);

       // To handle the SelectAll when the Checkbox is Unchecked
      if (count == 0) {
        setIsFullSelect(false)

        selectedCheckBoxArray.forEach((obj: any) => {
          const element: any = document.getElementById(JSON.stringify(obj));
          if (element != "" && element != null && element != undefined) {
            element.checked = "";
          }
        });
        let tempArr: any = [];
        setSelectedCheckBoxArray(tempArr);
      }

       // To handle the SelectAll when the Checkbox is PartialSelect
      if (count == 1) {
        let tempArr: any = selectedCheckBoxArray;
        gridData.forEach((obj: any) => {
          const element: any = document.getElementById(JSON.stringify(obj));
          if (
            element != "" &&
            element != null &&
            element != undefined &&
            element.checked != true &&
            element.disabled == false
          ) {
            element.checked = "true";
            tempArr.push(obj);
          }
        });
        selectedCheckBoxArray([...tempArr]);
        // To find whether must be Be in full Select on individual CheckBox Check
        if(tempArr.length == validTotalCount){
          setIsFullSelect(true)
        }
     }

     // To handle the SelectAll when the Checkbox is Full Select
     if(count == 2){
        setIsFullSelect(true)
        let loader = document.getElementById("loader");
        loader?.removeAttribute("hidden");
        let tempArr: any = [];

        csvData.forEach((obj: any) => {
          if(obj.IsConversationMigrated == null){
            tempArr.push(obj);
          }
        });
        setValidTotalCount(tempArr.length)
        setSelectedCheckBoxArray([...tempArr]);
        loader?.setAttribute("hidden", "hidden");

     }

    }
    catch(error){

    }
  }


  /*method for handling the checkbox */
  const handlecheckbox = async (e: any) => {


    try {
      let id = e.target.id
      let objState = JSON.parse(id)
      let tempArr:any = selectedCheckBoxArray
      if (e.target.checked) {
        tempArr.push(objState)
        setSelectedCheckBoxArray([...tempArr])
      }

      else {
        let ind = tempArr.findIndex((obj: any) => obj.SourceChannelID == e.target.value)
        tempArr.splice(ind, 1);
        setSelectedCheckBoxArray([...tempArr])

        if (tempArr.length == 0) {
          let x = document.getElementById("createmappingbtn")
          x?.setAttribute("disabled", "disabled");
        }
        else {
          let x = document.getElementById("createmappingbtn")
          x?.removeAttribute("disabled");
        }
      }

      // To find whether must be Be in full Select on individual CheckBox Check
      if(tempArr.length == validTotalCount){
        setIsFullSelect(true)
      }

    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
    }
  }

  /*method to render the data in the grid */
  const gridBodyData = () => {
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && typeof gridData != 'string') {
        return gridData.map((obj:any, index:any) => {

          let rowindex = (index % 2) == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          obj.TargetChannelName = obj.TargetChannelName == null ? "-" : obj.TargetChannelName
          obj.TargetTeamName = obj.TargetTeamName == null ? "-" : obj.TargetTeamName
          return (

            <tr className={rowindex} >

              <td className=" py-3 tabel-data-font-level2"><input className="form-check-input" disabled={obj.IsConversationMigrated == null && obj.TargetChannelName != '-' ? false : true}
                onChange={(e) => handlecheckbox(e)} type="checkbox" value={obj.SourceChannelID} name="checkbox" id={JSON.stringify(obj)} /></td>

              {
                (obj.SourceChannelName).length > 28 ?
                  <td className="py-3 tabel-data-font-level1" data-tip={obj.SourceChannelName} >
                    {(obj.SourceChannelName).substring(0, 28)}...
                    <ReactTooltip />
                  </td> :
                  <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.SourceChannelName}</td>
              }
              {
                (obj.SourceChannelID).length > 28 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.SourceChannelID} >
                  {(obj.SourceChannelID).substring(0, 28)}...
                  <ReactTooltip />
                </td> :
                  <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.SourceChannelID}</td>
              }
              {
                (obj.TargetTeamName).length > 28 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetTeamName} >
                  {(obj.TargetTeamName).substring(0, 28)}...
                  <ReactTooltip />
                </td> :
                  <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.TargetTeamName}</td>
              }
              {
                (obj.TargetChannelName).length > 28 ? <td className="py-3 tabel-data-font-level1" data-tip={obj.TargetChannelName} >
                  {(obj.TargetChannelName).substring(0, 28)}...
                  <ReactTooltip />
                </td> :
                  <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.TargetChannelName}</td>
              }
              <td className=" py-3 tabel-data-font-level1 text-center">

                  {
                 !isEnableDeleteChecked ? <img className={obj.IsConversationMigrated == false ? "icons anchorAsPointer" : "icon-disable"} title="Delete" id={"delete" + obj.SourceChannelID} data-MigrationSummaryID = {obj.MigrationSummaryID} onClick ={obj.IsConversationMigrated == false ? (e) => handledelete(e) : () => {}}  src="/Images/delete-icon.svg" />
                   :  <img className={(obj.IsConversationMigrated == true && obj.TargetChannelName!=null && obj.TargetTeamName!=null ) ? "icons anchorAsPointer" : "icon-disable"} title="Delete" id={"delete" + obj.SourceChannelID} data-MigrationSummaryID= {obj.MigrationSummaryID} onClick ={(obj.IsConversationMigrated == true && obj.TargetChannelName != null && obj.TargetTeamName != null) ? (e) => handledelete(e) : () => {}} src="/Images/delete-icon.svg" />
                  }  
                           
              </td>
              <span>

                <ReactTooltip />

              </span>
            </tr>)
        })
      }
      else if (gridData[0] == undefined && isArr == 0) {

        return (<tr> <td className="aligncenter" colSpan={6}></td>  </tr>)

      }
      else {

        return (<tr> <td className="aligncenter" colSpan={6}><img src="/Images/NoRecordsFound.svg"></img><p className="mt-2">No records found</p></td>  </tr>)

      }
    }
    catch (error: any) {

      ////console.log("error", error);
    }
  }
  /*method to handle the search functionlity */
  const handleSearch = (e: any) => {

    try {
      setSearchtext(e.target.value);
    } catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }

  }
  /*method for the sort functionality when enter is clicked */
  const handleDownKey = async (e: any) => {

    try {
      if (e.key === 'Enter') {
        if (isArr == 0) {
          let tempOperation = ""
          if(Searchtext != ""){
            tempOperation = "search"
          }
          settmpSearchValue(Searchtext);
          setOperation(tempOperation)
          await loadGridPage(tempOperation);
        }
        else {
          handleSearchIcon(e);
        }
      }
    }
    catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }

  /*method for the search functionlity of search icon click */
  const handleSearchIcon = async (e: any) => {
    try {
      if (isArr == 0) {
        gridData.length = 0;
        let tempOperation = ""
          if(Searchtext != ""){
            tempOperation = "search"
          }
        settmpSearchValue(Searchtext);
        setOperation(tempOperation)
        await loadGridPage(tempOperation);
      }

      else {
        let newarr: any = MappingDetails.filter((x) => x.SourceChannelName.toLowerCase().includes(Searchtext.toLowerCase()) ||
          x.TargetChannelName.toLowerCase().includes(Searchtext.toLowerCase()) || x.TargetTeamName.toLowerCase().includes(Searchtext.toLowerCase()));

        if (newarr.length == 0) {
          let tempOperation = ""
          if(Searchtext != ""){
            tempOperation = "search"
          }
          setGridData([]);
          setOperation(tempOperation)
          if(tempOperation == "search"){
            setTotalItemsCountFeature(newarr.length)
          }
          else{
            setTotalItemsCount(csvData.length)
          }
        }
        else {
          let tempOperation = ""
          if(Searchtext != ""){
            tempOperation = "search"
          }
          setGridData([]);
          setOperation(tempOperation)
          // setGridData(newarr);
          setGridData(newarr.slice(0, countPerPage));

          // to Uncheck the Check Boxes after Binding
          newarr.forEach((obj:any)=>{
              let element:any = document.getElementById(JSON.stringify(obj))
              if(element != "" && element != undefined && element != null){
                element.checked = false;
              }
          })
            
          if(tempOperation == "search"){
            setTotalItemsCountFeature(newarr.length)
          }
          else{
            setTotalItemsCount(csvData.length)
          }
        }

      }
    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }

  /*method for the download source button */
  const downloadsource = async () => {
    try {
      if (inventoryCount == 0 || inventoryCount == null || inventoryCount == undefined) {
        status = "Please take Detail level Inventory!"
        setStatus(status)
        alertpopup()
      }
      else {
        if (clientIDValdidate != undefined && clientToken != undefined) {
          let config = {
            method: "POST",
            token: clientToken,
            url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/getSourceChannelList`,
            data: {
              "clientID": clientID
            }
          }
          if(adminTok !=undefined && adminTok !== null){
            (config.data as any).token = adminToken
          }
          setIsSourceDelayLoader(true);
          let loader = document.getElementById("loaderWithoutContent");
          loader?.removeAttribute('hidden');
          let data: any = await GridLoad(config)
          if (data.data.statusCode == 200) {

            const workSheet = XLSX.utils.json_to_sheet(data.data.data);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "Slack Channel Details")
            XLSX.writeFile(workBook, "Slack Channel Details.xlsx")
            document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
          }
          else if (data.data.data == "Invalid authentication token provided") {
            document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
            enableSessionExpiryPopup();
          }
          else {
            document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
            status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(status)
            alertpopup()
          }

        }
        else {
          document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
          localStorage.clear();
        }
      }
    }
    catch (error) {
      document.getElementById("loaderWithoutContent")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }

  /*method for the download target button */
  const downloadtarget = async () => {
    try {
      if (settingsDeatils.length == 0 || settingsDeatils[0].MSTeamsUserID == null || settingsDeatils[0].MSTeamsUserID == undefined) {
        status = "Please Connect the MS Teams Tenant!"
        setStatus(status)
        alertpopup()
      }
      else {
        if (clientIDValdidate != undefined && clientToken != undefined) {

          let config = {
            method: "POST",
            token: clientToken,
            url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/getTeamsChanneLlist`,
            data: {

              "clientID": clientID,
              "MigrationType": `${MigrationType}`

            }
          }
          if(adminTok !=undefined && adminTok !== null){
            (config.data as any).token = adminToken
          }
          setIsSourceDelayLoader(false);
          let loader = document.getElementById("delayloader");
          loader?.removeAttribute('hidden');
          let data: any = GridLoad(config);
          let channelData: any;
           
          while (true) {
             
            const snooze = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
            await snooze(5000);
             
            let config = ({
              method: "POST",
              url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/getDownloadChannelStatus`,
              data: {
                "clientID": clientID,
                "MigrationType": MigrationType
              },
              token: clientToken
            }
            )
            channelData = await GridLoad(config);
            if (channelData.data.statusCode == 200) {
               
              if (channelData.data.data != undefined && channelData.data.data != null && (channelData.data.data.length == 0 || (channelData.data.data[0] != undefined && channelData.data.data[0].currentStatus == undefined))) {
                 
                break;
              }
              else if (channelData.data.data != undefined && channelData.data.data != null && channelData.data.data[0].currentStatus == 'Failed') {
                throw 'Something went wrong';
              }

            }
            else if (channelData.data.data == "Invalid authentication token provided") {
              document.getElementById("delayloader")?.setAttribute('hidden', 'true');
              enableSessionExpiryPopup();
            }
          }
          if (channelData.data.statusCode == 200) {
            ////console.log(channelData.data.data);
            if (channelData.data.data != undefined && channelData.data.data.length == 0) {

              channelData.data.data = [{

                "MSTeamName": "",

                "MSTeamID": "",

                "MSTeamVisibility": "",

                "MSTeamChannelName": "",

                "MSTeamChannelID": "",

                "MSTeamChannelVisibility": ""

              }];

            }

            const workSheet = XLSX.utils.json_to_sheet(channelData.data.data);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "Teams Channel Details")
            XLSX.writeFile(workBook, "Teams Channel Details.xlsx")
            loader?.setAttribute('hidden', 'hidden')
          }

          else {
            document.getElementById("delayloader")?.setAttribute('hidden', 'true');
            status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(status)
            alertpopup()
          }
        }
        else {
          document.getElementById("delayloader")?.setAttribute('hidden', 'true');
          localStorage.clear();
        }
      }
    }
    catch (error) {
      document.getElementById("delayloader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }

  const createDynamicListItem = async (popupText: any, arrReasons: any) => {
    try {

      setStatus(popupText)
      let element = document.getElementById("dynamicData");
      let ulTag = document.createElement("ol");
      ulTag.classList.add('listedAlertPopupBG');
      arrReasons.map((reason: any) => {
        let liTag = document.createElement("li");
        liTag.style.textAlign = "left";
        let liText = document.createTextNode(reason);
        liTag.appendChild(liText);
        ulTag.appendChild(liTag);
      })
      element?.appendChild(ulTag);
    } catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }

  /*method to download channel mapping template */
  const handledownload = async (e: any) => {

    try {
      let loader = document.getElementById("loader");
      loader?.removeAttribute('hidden');
      let exportdata = [{
        "SlackChannelName": "public-channel",
        "SlackChannelID": "CUDEVGYTHYC",
        "MSTeamName": "Dummy Team",
        "MSTeamID": "152d4f68-9a05-4581-ae39-c74a57cb8919",
        "MSTeamChannelName": "Dummy Channel",
        "MSTeamChannelID": "19:vAB2jLCQCXcrbFH_7FN9p4VaJICb5mgQvSlFw_3PVnk1@thread.tacv2"
      }]
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Channel Mapping Template',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Channel Mapping Template",
        //headers: ["TargetTeamName","TargetTeamType","TargetTeamID","TargetChannelName","TargetChannelType"] 
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportdata);
      let enableCreateBtn: any = document.getElementsByName("btnCreate");
      enableCreateBtn.disabled = false;

      loader?.setAttribute('hidden', 'hidden')

    }
    catch (error) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
      ////console.log(error);
    }
  }

  /*method to upload the channel mapping template */

  const handleuploadfile = async (e: any) => {

    try {
       
      const file = e.target.files[0];
      e.target.value = ""
      if (file != undefined) {
        let element: any = document.getElementById("uploadFileSlack");
        element.value = file.name;
        uploadFile = true
        setUploadFile(uploadFile)
        let x = document.getElementById("submit");
        x?.removeAttribute("disabled");

        const reader = new FileReader();
        reader.onload = (evt: any) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: [
              "SlackChannelName",
              "SlackChannelID",
              "MSTeamName",
              "MSTeamID",
              "MSTeamChannelName",
              "MSTeamChannelID",
              "AdditionalColumn"], raw: true, defval: ""
          });

          MappingDetails = data
          // setMappingDetails([])
          setMappingDetails(MappingDetails)
           
          // TargetTeamDetails = data
          // setTeamDetails(TargetTeamDetails)
           

          if ((!file.name.includes('.csv')) || MappingDetails.length < 1 || MappingDetails[0].SlackChannelID == undefined || MappingDetails[0].SlackChannelName == undefined || MappingDetails[0].AdditionalColumn != undefined ||
            MappingDetails[0].MSTeamName == undefined || MappingDetails[0].MSTeamID == undefined || MappingDetails[0].MSTeamChannelName == undefined ||
            MappingDetails[0].MSTeamChannelID == undefined || MappingDetails[0].SlackChannelID !== "SlackChannelID" || MappingDetails[0].SlackChannelName !== "SlackChannelName" ||
            MappingDetails[0].MSTeamName !== "MSTeamName" || MappingDetails[0].MSTeamID !== "MSTeamID" ||
            MappingDetails[0].MSTeamChannelName !== "MSTeamChannelName" || MappingDetails[0].MSTeamChannelID != "MSTeamChannelID"
          ) {
            setStatus('Please provide valid template!')
            alertpopup();

          }

          if (MappingDetails.length <= 1) {
            handleclose();
            setStatus('Please provide valid template!')
            alertpopup();
          }
          MappingDetails.shift()
        };
        reader.readAsBinaryString(file);
      }
      else {
        let x = document.getElementById("submit")
        x?.setAttribute("disabled", "disabled");
      }

    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);

    }
  }

  /*method for the submit button */
  const handlesubmit = async (e: any) => {
    try {
      countPerPage = 10
      setCoutPerPage(countPerPage)
      document.getElementById("uploadFile")?.setAttribute("value", "")
      document.getElementById("uploadChannelFile")?.setAttribute("value", "")
      if (inventoryCount == 0 || inventoryCount == null || inventoryCount == undefined) {
        status = "Please take Detail level Inventory!"
        setStatus(status)
        alertpopup()
      }
      else {
        if (clientIDValdidate != undefined && clientToken != undefined) {
          let config = ({
            method: "POST",
            token: clientToken,
            url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/validateSourceTargetDetails`,
            data: {
              "clientID": clientID,
              "CSV": MappingDetails,
              "MigrationType": MigrationType
            },
          }
          )
          if(adminTok !=undefined && adminTok !== null){
            (config.data as any).token = adminToken
          }
          let loader = document.getElementById("loader");
          loaderContent = "Please wait. We are validating your channels mapping."
          setLoaderContent(loaderContent)
          loader?.removeAttribute('hidden');

          let data: any = await SubmitValidation(config);
           

          if (data.data.statusText == "Bad Request" || data.data.data == "Invalid payload provided. Please refer the API documentation" || data.data.statusCode != 200) {
            createDynamicListItem("Channel Mapping failed! Due to any of the below mentioned reasons", ["Invalid Slack channel or MS Teams channel details", "Slack channel already mapped with another MS Teams channel"])
            alertpopup();
            let z = document.getElementById("submit")
            z?.setAttribute("disabled", "disabled");
            let cleartext: any = document.getElementById("uploadFileSlack");
            cleartext.value = "";
            let clearbrowse: any = document.getElementById("uploadBtnSlack");
            clearbrowse.value = "";
            loader?.setAttribute('hidden', 'hidden');
          }

          if (data.data.statusText == "Valid Source Target Details") {

            let TempMappingDetails: any[] = [];
            for (let i = 0; i < data.data.data[0].length; i++) {

              let objCSVGrid = {
                SourceChannelType: data.data.data[0][i].SlackChannelVisibility,
                IsConversationMigrated: data.data.data[0][i].IsConversationMigrated,
                SourceChannelName: `${data.data.data[0][i].SlackChannelName}`,
                TargetChannelName: `${data.data.data[0][i].MSTeamChannelName}`,
                SourceChannelID: data.data.data[0][i].SlackChannelID,
                TargetChannelID: data.data.data[0][i].MSTeamChannelID,
                TargetTeamID: data.data.data[0][i].MSTeamID,
                TargetTeamName: `${data.data.data[0][i].MSTeamName}`,
                TargetTeamType: data.data.data[0][i].MSTeamVisibility,
                TargetChannelType: data.data.data[0][i].MSTeamChannelVisibility
              }
              TempMappingDetails.push(objCSVGrid)
            }
            MappingDetails = TempMappingDetails
            setMappingDetails(MappingDetails)

              
            setSelectAllShowHide(false)
            let tempArr:any = []
            TempMappingDetails.forEach((obj: any) => {
              if(obj.IsConversationMigrated == null){
                tempArr.push(obj);
              }
            });
            setValidTotalCount(tempArr.length)

            initialLoad = false;
            setGridData(MappingDetails.slice(0, countPerPage));
            setIsArr(1);
            setCsvData(MappingDetails);
    
            if(operation == "search"){
              setTotalItemsCountFeature(data.data.data[0].length)
            }
            else{
              setTotalItemsCount(data.data.data[0].length);
            }

            setSlackWorkspaceID(data.data.data[1].SlackWorkspaceID);
            setsubVal(true)
            loader?.setAttribute('hidden', 'hidden');
          }
          else if (data.data.data == "Invalid authentication token provided") {
            loader?.setAttribute('hidden', 'hidden');
            deleteChild()
            enableSessionExpiryPopup();
          }
          else {
            // let options: any = {
            //   fieldSeparator: ',',
            //   quoteStrings: '"',
            //   decimalSeparator: '.',
            //   showLabels: true,
            //   showTitle: false,
            //   title: 'Invalid CSV Details',
            //   useTextFile: false,
            //   useBom: true,
            //   useKeysAsHeaders: true,
            //   filename: "Invalid CSV Details",
            // };
            mappingCSV = data.data.data[0]
            setMappingCsv(mappingCSV)

          }
          let cleartext: any = document.getElementById("uploadFileSlack");
          cleartext.value = "";

          let clearbrowse: any = document.getElementById("uploadBtnSlack");
          clearbrowse.value = "";

          let z = document.getElementById("submit")
          z?.setAttribute("disabled", "disabled");
          loader?.setAttribute('hidden', 'hidden');
        }
        else {
          localStorage.clear();
        }
      }
    }
    catch (error: any) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  /*method to enable the team channel creation popup */
  const enablecreationpopup = () => {
    try {
      if (settingsDeatils.length == 0 || settingsDeatils[0].MSTeamsUserID == null || settingsDeatils[0].MSTeamsUserID == undefined) {
        status = "Please Connect the MS Teams Tenant!"
        setStatus(status)
        alertpopup()
      }
      else {

        let clear: any = document.getElementById("uploadFile");
        clear.value = "";
        let clearChannel: any = document.getElementById("uploadChannelFile");
        clearChannel.value = "";
        let clearbrowse: any = document.getElementById("uploadBtn");
        clearbrowse.value = "";
        document.getElementById('createbtn')?.setAttribute("disabled", "disabled")
        document.getElementById('createChannelBtn')?.setAttribute("disabled", "disabled")
        const element: any = document.getElementById("teams-creation");

        if (element.hasAttribute("aria-modal")) {
          document.getElementById("teams-creation")?.setAttribute("class", "modal fade");
          document.getElementById("teams-creation")?.setAttribute("style", "display:none");
          document.getElementById("teams-creation")?.setAttribute("aria-hidden", "true");
          document.getElementById("teams-creation")?.removeAttribute("aria-modal");
          document.getElementById("teams-creation")?.removeAttribute("role");
          document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        }

        else {
          document.getElementById("teams-creation")?.removeAttribute("aria-hidden");
          document.getElementById("teams-creation")?.setAttribute("aria-modal", "true");
          document.getElementById("teams-creation")?.setAttribute("role", "dialog");
          document.getElementById("teams-creation")?.setAttribute("class", "modal fade show");
          document.getElementById("teams-creation")?.setAttribute("style", "display:block");
          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
      }
    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);

    }
  }

  /*method to upload the team creation template */

  const uploadfilemethod = async (e: any) => {
    try {
      createStatus = true
      setCreateStatus(createStatus)
      const file = e.target.files[0];
      e.target.value = ""
      if (file != undefined) {
        setfile1(true)
        let x = document.getElementById("createbtn");
        x?.removeAttribute("disabled");

        let element: any = document.getElementById("uploadFile");
        element.value = file.name;

        const reader = new FileReader();
        reader.onload = (evt: any) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: [
              "MSTeamName",
              "MSTeamVisibility",
              "AdditionalColumn"], raw: true, defval: ""
          });

          TargetTeamDetails = data
          setTeamDetails(TargetTeamDetails)
           

          if ((!file.name.includes('.csv')) || TargetTeamDetails.length < 1 || TargetTeamDetails[0].AdditionalColumn != undefined ||
            TargetTeamDetails[0].MSTeamVisibility == undefined || TargetTeamDetails[0].MSTeamName == undefined ||
            TargetTeamDetails[0].MSTeamName !== "MSTeamName" || TargetTeamDetails[0].MSTeamVisibility !== "MSTeamVisibility") {
            handleclose();
            setStatus('Please provide valid template!')
            alertpopup();


          }
          if (TargetTeamDetails.length <= 1) {
            handleclose();
            setStatus('Please provide valid template!')
            alertpopup();
          }
          TargetTeamDetails.shift()
        };
        reader.readAsBinaryString(file);
      }


    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }


  const uploadFileChannelCreation = async (e: any) => {
    try {
       
      createStatus = true
      setCreateStatus(createStatus)
      const file = e.target.files[0];
      e.target.value = ""
      if (file != undefined) {
        setfile1(true)
        let x = document.getElementById("createChannelBtn");
        x?.removeAttribute("disabled");

        let element: any = document.getElementById("uploadChannelFile");
        element.value = file.name;

        const reader = new FileReader();
        reader.onload = (evt: any) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', raw: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: [
              "MSTeamName",
              "MSTeamID",
              "MSTeamChannelName",
              "MSTeamChannelVisibility",
              "AdditionalColumn"], raw: true, defval: ""
          });
          setTeamDetails([])
          TargetTeamDetails = data
          setTeamDetails(TargetTeamDetails)
           

          if ((!file.name.includes('.csv')) || TargetTeamDetails.length < 1 || TargetTeamDetails[0].AdditionalColumn != undefined || TargetTeamDetails[0].MSTeamID == undefined || TargetTeamDetails[0].MSTeamName == undefined || TargetTeamDetails[0].MSTeamChannelName == undefined ||
            TargetTeamDetails[0].MSTeamID !== "MSTeamID" || TargetTeamDetails[0].MSTeamChannelName !== "MSTeamChannelName" ||
            TargetTeamDetails[0].MSTeamName !== "MSTeamName" || TargetTeamDetails[0].MSTeamChannelVisibility !== "MSTeamChannelVisibility") {
            handleclose();
            setStatus('Please provide valid template!')
            alertpopup();

          }
          if (TargetTeamDetails.length <= 1) {
            handleclose();
            setStatus('Please provide valid template!')
            alertpopup();
          }
          TargetTeamDetails.shift()
        };
        reader.readAsBinaryString(file);
      }


    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log("error", error);
    }
  }



  function deleteChild() {
    var e: any = document.getElementById("dynamicData");
    var child = e.lastElementChild;
    while (child) {
      e.removeChild(child);
      child = e.lastElementChild;
    }
  }
  /*method to download the teams channel craetion template */
  const handleteamtemplatedownload = async () => {

    try {
      let exportData = [{
        "MSTeamName": "Dummy Team",
        "MSTeamVisibility": "Public",
      }];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Team Creation Template',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Team Creation Template ",
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportData);
      let enableCreateBtn: any = document.getElementsByName("btnCreate");
      enableCreateBtn.disabled = false;
    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);

    }
  }

  const handleChannelTemplateDownload = async () => {

    try {
      let exportData = [{
        "MSTeamName": "Dummy Team",
        "MSTeamID": "24sadf454fsdv5t365yv",
        "MSTeamChannelName": "DummyChannel",
        "MSTeamChannelVisibility": "Public",
      }];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Channel Creation Template',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Channel Creation Template ",
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportData);
      let enableCreateBtn: any = document.getElementsByName("createChannelBtn");
      enableCreateBtn.disabled = false;
    }
    catch (error: any) {
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);

    }
  }

  /*method to close the teams channel creation popup popup */
  const handleclose = () => {

    try {
      document.getElementById("teams-creation")?.setAttribute("class", "modal fade");
      document.getElementById("teams-creation")?.setAttribute("style", "display:none");
      document.getElementById("teams-creation")?.setAttribute("aria-hidden", "true");
      document.getElementById("teams-creation")?.removeAttribute("aria-modal");
      document.getElementById("teams-creation")?.removeAttribute("role");

      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  /*method for the teams channel creation button */
  const handlecreatechannel = async (e: any) => {

    try {
       
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/createTeamsChannel`,
          data: {
            "clientID": clientID,
            "CSV": TargetTeamDetails,
            "type" : "ChannelCreation",
            "MigrationType": `${MigrationType}`
          }
        })
        if(adminTok !=undefined && adminTok !== null){
          (config.data as any).token = adminToken
        }
        TargetTeamDetails = []
        setTeamDetails(TargetTeamDetails)
        let loader = document.getElementById("loader");
        loaderContent = "Please wait. We are creating your Teams Channel."
        setLoaderContent(loaderContent)
        loader?.removeAttribute('hidden');
        handleclose();
        let data: any = await CreateTeamChannel(config);
        ////console.log(data.data.data);
        let arrayItems = data.data.data
        ////console.log("After assign");




        setexportChannelfaileddata(data.data.data);
         

        if (data.data.data == "Please provide valid details" || data.data.data == "Invalid payload provided. Please refer the API documentation" || data.data.statusText == "Invalid CSV Details") {

          handleclose();
          createDynamicListItem("Team Channel creation failed Due to any of the below mentioned reasons", ["Invalid MS Team details", "Invalid Teams Channel details"])
          alertpopup();

          loader?.setAttribute('hidden', 'hidden')
        }
        else if (data.data.statusText == "Teams Channel Creation Failed") {
          handleclose();
          loader?.setAttribute('hidden', 'hidden')
          setStatus('Teams Channel Creation Failed')
          alertpopup();

        }

        if (data.data.statusText == "Team Channel Created Successfully") {
          loader?.setAttribute('hidden', 'hidden')
          handleclose();
          let csvData: { MSTeamName: any; MSTeamID: any; MSTeamVisibility: any; MSTeamChannelName: any; MSTeamChannelID: any; Status: any; }[] = []
          arrayItems.forEach((a: any) => {
            let csvFormat = {
              MSTeamName: a.MSTeamName,
              MSTeamID: a.MSTeamID,
              MSTeamVisibility: a.MSTeamVisibility,
              MSTeamChannelName: a.MSTeamChannelName,
              MSTeamChannelID: a.MSTeamChannelID,
              MSTeamChannelVisibility: a.MSTeamChannelVisibility,
              Status: a.Status
            }
            csvData.push(csvFormat)
          })
          setExportChannelCreationSuccessData(csvData);
          document.getElementById("successpopup")?.removeAttribute("aria-hidden");

          document.getElementById("successpopup")?.setAttribute("aria-modal", "true");

          document.getElementById("successpopup")?.setAttribute("role", "dialog");

          document.getElementById("successpopup")?.setAttribute("class", "modal fade show");

          document.getElementById("successpopup")?.setAttribute("style", "display:block");

          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          loader?.setAttribute('hidden', 'hidden')
          handleclose();
          status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(status)
          alertpopup()
        }
        else if (data.data.data == "Invalid authentication token provided") {
          loader?.setAttribute('hidden', 'hidden')
          enableSessionExpiryPopup();
        }

      }
      else {
        localStorage.clear();
      }

    }


    catch (error: any) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  const handlecreateteams = async (e: any) => {

    try {
       
      if (clientIDValdidate != undefined && clientToken != undefined) {
        let config = ({
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/createTeamsChannel`,
          data: {
            "clientID": clientID,
            "CSV": TargetTeamDetails,
            "type" : "TeamCreation",
            "MigrationType": `${MigrationType}`
          }
        })
        if(adminTok !=undefined && adminTok !== null){
          (config.data as any).token = adminToken
        }

        let loader = document.getElementById("loader");
        loaderContent = "Please wait. We are creating your Teams."
        setLoaderContent(loaderContent)
        loader?.removeAttribute('hidden');
        handleclose();
        let data: any = await CreateTeamChannel(config);
        TargetTeamDetails = []
        setTeamDetails(TargetTeamDetails)
        let arrayItems = data.data.data



        setexportTeamfaileddata(data.data.data);


        if (data.data.data == "Please provide valid details" || data.data.data == "Invalid payload provided. Please refer the API documentation" || data.data.statusText == "Invalid CSV Details") {

          handleclose();
          isTeamInvalidTemplate = true
          setIsTeamInvalidTemplate(isTeamInvalidTemplate)
          createDynamicListItem("Team Channel creation failed Due to any of the below mentioned reasons", ["Invalid MS Team details", "Invalid Teams Channel details"])
          alertpopup();

          loader?.setAttribute('hidden', 'hidden')
        }
        else if (data.data.statusText == "Teams Channel Creation Failed") {
          isTeamInvalidTemplate = false
          setIsTeamInvalidTemplate(isTeamInvalidTemplate)
          handleclose();
          loader?.setAttribute('hidden', 'hidden')
          setStatus('Teams Channel Creation Failed')
          alertpopup();

        }

        if (data.data.statusText == "Team Channel Created Successfully") {
          loader?.setAttribute('hidden', 'hidden')
          handleclose();
          let csvData: { MSTeamName: any; MSTeamID: any; MSTeamVisibility: any; MSTeamChannelName: any; MSTeamChannelID: any; Status: any; }[] = []
          arrayItems.forEach((a: any) => {
            let csvFormat = {
              MSTeamName: a.MSTeamName,
              MSTeamID: a.MSTeamID,
              MSTeamVisibility: a.MSTeamVisibility,
              MSTeamChannelName: a.MSTeamChannelName,
              MSTeamChannelID: a.MSTeamChannelID,
              MSTeamChannelVisibility: a.MSTeamChannelVisibility,
              Status: a.Status
            }
            csvData.push(csvFormat)
          })
          setExportTeamCreationSuccessData(csvData);
          document.getElementById("successpopup")?.removeAttribute("aria-hidden");

          document.getElementById("successpopup")?.setAttribute("aria-modal", "true");

          document.getElementById("successpopup")?.setAttribute("role", "dialog");

          document.getElementById("successpopup")?.setAttribute("class", "modal fade show");

          document.getElementById("successpopup")?.setAttribute("style", "display:block");

          document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
          loader?.setAttribute('hidden', 'hidden')
          handleclose();
          status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
          setStatus(status)
          alertpopup()
        }
        else if (data.data.data == "Invalid authentication token provided") {
          loader?.setAttribute('hidden', 'hidden')
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    }


    catch (error: any) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  /*method to enable the alert popup */
  const alertpopup = () => {

    try {
      let cleartext: any = document.getElementById("uploadFileSlack");
      cleartext.value = "";

      document.getElementById("uploadFile")?.setAttribute("value", "")

      document.getElementById("uploadChannelFile")?.setAttribute("value", "")

      document.getElementById("success")?.removeAttribute("aria-hidden");

      document.getElementById("success")?.setAttribute("aria-modal", "true");

      document.getElementById("success")?.setAttribute("role", "dialog");

      document.getElementById("success")?.setAttribute("class", "modal fade show");

      document.getElementById("success")?.setAttribute("style", "display:block");

      document.getElementById("fadeDiv")?.removeAttribute("hidden");

      document.getElementById("submit")?.setAttribute("disabled", "disabled");
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }

  /*method to close the alert popup */
  const okbtnclick = () => {
    try {
      if (isSessionEpired === false) {

         
        deleteChild()
        let cleartext: any = document.getElementById("uploadFileSlack");
        cleartext.value = "";
        document.getElementById("success")?.setAttribute("class", "modal fade");
        document.getElementById("success")?.setAttribute("style", "display:none");
        document.getElementById("success")?.setAttribute("aria-hidden", "true");
        document.getElementById("success")?.removeAttribute("aria-modal");
        document.getElementById("success")?.removeAttribute("role");
        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        if (createStatus == true) {
          enablecreationpopup()
          createStatus = false
          setCreateStatus(createStatus)
        }


        if (!(typeof exportChannelfaileddata == 'string') && exportChannelfaileddata.length > 0) {
          exportChannelcreationfailed()
          exportChannelfaileddata = []
          setexportChannelfaileddata(exportChannelfaileddata)
        }
        if (!(typeof exportTeamsfaileddata == 'string') && exportTeamsfaileddata.length > 0) {
           
          exportTeamCreationFailed()
          exportTeamsfaileddata = []
          setexportTeamfaileddata(exportTeamsfaileddata)
        }
        if ((!(typeof mappingCSV == 'string')) && mappingCSV.length > 0) {
          let options: any = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            title: 'Invalid Mapping Details',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: "Invalid Mapping Details",
          }; const csvExporter = new ExportToCsv(options);
          let tempArr: any = []

          mappingCSV.forEach((element: any) => {
            tempArr.push({
              SlackChannelName: element.SlackChannelName == undefined ? "" : element.SlackChannelName,
              SlackChannelID: element.SlackChannelID == undefined ? "" : element.SlackChannelID,
              MSTeamName: element.MSTeamName == undefined ? "" : element.MSTeamName,
              MSTeamID: element.MSTeamID == undefined ? "" : element.MSTeamID,
              MSTeamChannelName: element.MSTeamChannelName == undefined ? "" : element.MSTeamChannelName,
              MSTeamChannelID: element.MSTeamChannelID == undefined ? "" : element.MSTeamChannelID,
              Status: element.Status == undefined ? "" : element.Status,
              Comments: element.Comments == undefined ? "" : element.Comments,


            })
          });
          csvExporter.generateCsv(tempArr);
          mappingCSV = []
          setMappingCsv(mappingCSV)
          let z = document.getElementById("submit")
          z?.setAttribute("disabled", "disabled");
        }
      }
      else {
        window.location.pathname = "/Login";

      }
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }

  /*method for the create channel mapping button */
  const btnCreateMapping = async () => {
    try {
      if (clientIDValdidate != undefined && clientToken != undefined) {
  
        let selectedChannels: any = [];

        await selectedCheckBoxArray.forEach((obj: any) => {
          selectedChannels.push({
            "SourceChannelID": obj.SourceChannelID,
            "SourceChannelName": obj.SourceChannelName,
            "TargetTeamName": obj.TargetTeamName,
            "TargetTeamID": obj.TargetTeamID,
            "TargetChannelName": obj.TargetChannelName,
            "TargetChannelID": obj.TargetChannelID,
            "SourceChannelType": obj.SourceChannelType.toLowerCase() == "public" ? obj.SourceChannelType = 0 : obj.SourceChannelType = 1,
            "TargetTeamType": obj.TargetTeamType.toLowerCase() == "public" ? obj.TargetTeamType = 0 : obj.TargetTeamType = 1,
            "TargetChannelType": obj.TargetChannelType.toLowerCase() == "public" ? obj.TargetChannelType = 0 : (obj.TargetChannelType.toLowerCase() == "private") ? obj.TargetChannelType = 1 : obj.TargetChannelType = 2,
            "SlackworkspaceID": SlackWorkspaceID,
            "MigrationType": MigrationType
  
          });
          
        });

        let config = ({
          method: "POST",
          token: clientToken,
          url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/ChannelMapping/SaveMappedData`,
          data: {
            "clientID": clientID,
            "saveMap": selectedChannels
          },
        }
        );
        if(adminTok !=undefined && adminTok !== null){
          (config.data as any).token = adminToken
        }
        let loader = document.getElementById("loader");
        loaderContent = "Please wait. We are mapping Slack and Teams Channel."
        setLoaderContent(loaderContent)
        loader?.removeAttribute('hidden');
        let data: any = await saveMapping(config);

        let els: any = document.getElementsByName("checkbox");
        if (data.data.statusCode == 200) {
          selectedCheckBoxArray = [];
          setSelectedCheckBoxArray(selectedCheckBoxArray)
          for (let i = 0; i < els.length; i++) {
            if (els[i].checked == true) {
              els[i].checked = false;
              gridData[i].IsConversationMigrated = false;
            }
          }
          setGridData([]);
          setGridData(gridData);
          let x = document.getElementById("createmappingbtn")
          x?.setAttribute("disabled", "disabled");
          loader?.setAttribute('hidden', 'hidden');
        }
        else if (data.data.data == "Invalid authentication token provided") {
          loader?.setAttribute('hidden', 'hidden')
          enableSessionExpiryPopup();
        }
        else if (data.data.statusText == "Bad Request" || data.data.statusCode != 200) {
          selectedCheckBoxArray = [];
          setSelectedCheckBoxArray(selectedCheckBoxArray)
          for (let i = 0; i < els.length; i++) {
            if (els[i].checked == true) {
              els[i].checked = false;
              if (data.data.data[i] != undefined && data.data.data[i].Status != undefined && data.data.data[i].Status == "Success") {
                gridData[i].IsConversationMigrated = false;

              }
              else if (data.data.data[i] != undefined && data.data.data[i].Status != undefined && data.data.data[i].Status == "Failed") {
                gridData[i].IsConversationMigrated = null;
              }
            }
          }
          setGridData([]);
          setGridData(gridData);
          let x = document.getElementById("createmappingbtn")
          x?.setAttribute("disabled", "disabled");
          loader?.setAttribute('hidden', 'hidden')
          createDynamicListItem("Channel Mapping failed! Due to any of the below mentioned reasons", ["Invalid Slack channel or MS Teams channel details", "Slack channel already mapped with another MS Teams channel"])
          alertpopup();
        }

        else {
          mappingCSV = data.data.data[0] == undefined ? [] : data.data.data[0]
          setMappingCsv(mappingCSV)
          setsubVal(false);
          setGridData([]);
          selectedCheckBoxArray = [];
          setSelectedCheckBoxArray(selectedCheckBoxArray)
          loadGridPage(operation);
          loader?.setAttribute('hidden', 'hidden')
        }
      }

      else {
        localStorage.clear();
      }
    }
    catch (error: any) {
      selectedCheckBoxArray = [];
      setSelectedCheckBoxArray(selectedCheckBoxArray)
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }
  }

  const exportcreationsuccess = async () => {

    try {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Team Channel Created Successfully',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Team Channel Created Successfully",
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportsuccessdata);
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  const step1HideShowClick = async () => {
    try {
      if (document.getElementById("panelsStayOpen-collapseOne")?.hidden == true) {
        document.getElementById("panelsStayOpen-collapseOne")?.removeAttribute("hidden");
        document.getElementById("step1Arrow")?.setAttribute("class", "close-dropDown");
      }
      else {
        document.getElementById("panelsStayOpen-collapseOne")?.setAttribute("hidden", "true");
        document.getElementById("step1Arrow")?.setAttribute("class", "open-dropDown-cm");
      }
    } catch (error) {
      ////console.log("Step1HideShowClick Error:", error);
    }
  }
  const step2HideShowClick = async () => {
    try {
      if (document.getElementById("panelsStayOpen-collapseTwo")?.hidden == true) {
        document.getElementById("panelsStayOpen-collapseTwo")?.removeAttribute("hidden");
        document.getElementById("step2Arrow")?.setAttribute("class", "close-dropDown");
      }
      else {
        document.getElementById("panelsStayOpen-collapseTwo")?.setAttribute("hidden", "true");
        document.getElementById("step2Arrow")?.setAttribute("class", "open-dropDown-cm");
      }
    } catch (error) {
      ////console.log("Step2HideShowClick Error:", error);
    }
  }




  const step3HideShowClick = async () => {
    try {
      if (document.getElementById("panelsStayOpen-collapseThree")?.hidden == true) {
        document.getElementById("panelsStayOpen-collapseThree")?.removeAttribute("hidden");
        document.getElementById("step3Arrow")?.setAttribute("class", "close-dropDown");
      }
      else {
        document.getElementById("panelsStayOpen-collapseThree")?.setAttribute("hidden", "true");
        document.getElementById("step3Arrow")?.setAttribute("class", "open-dropDown-cm");
      }
    } catch (error) {
      ////console.log("Step3HideShowClick Error:", error);
    }
  }
  // const exportcreationStatus = async () => {

  //   var options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalSeparator: '.',
  //     showLabels: true,
  //     showTitle: false,
  //     title: 'Invalid CSV Details',
  //     useTextFile: false,
  //     useBom: true,
  //     useKeysAsHeaders: true,
  //     filename: "Invalid CSV Details",
  //   };

  //   const csvExporter = new ExportToCsv(options);
  //   csvExporter.generateCsv(exportChannelCreationStatus);

  // }



  const exportChannelcreationfailed = async () => {

    try {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Invalid Team and Channel details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Invalid Team and Channel details',
      };
      const csvExporter = new ExportToCsv(options);
      let tempArr: any = []
       

      for (let i = 0; i < exportChannelfaileddata.length; i++) {
        let objTeam = {
          MSTeamName: "",
          MSTeamID: "",
          MSTeamVisibility: "",
          MSTeamChannelName: "",
          MSTeamChannelID: "",
          MSTeamChannelVisibility: "",
          Status: "",
          Comments:""
        }
        objTeam.MSTeamName = exportChannelfaileddata[i].MSTeamName == undefined ? "" : exportChannelfaileddata[i].MSTeamName;
        objTeam.MSTeamID = exportChannelfaileddata[i].MSTeamID == undefined ? "" : exportChannelfaileddata[i].MSTeamID;
        objTeam.MSTeamVisibility = exportChannelfaileddata[i].MSTeamVisibility == undefined ? "" : exportChannelfaileddata[i].MSTeamVisibility;
        objTeam.MSTeamChannelName = exportChannelfaileddata[i].MSTeamChannelName == undefined ? "" : exportChannelfaileddata[i].MSTeamChannelName;
        objTeam.MSTeamChannelID = exportChannelfaileddata[i].MSTeamChannelID == undefined ? "" : exportChannelfaileddata[i].MSTeamChannelID;
        objTeam.MSTeamChannelVisibility = exportChannelfaileddata[i].MSTeamChannelVisibility == undefined ? "" : exportChannelfaileddata[i].MSTeamChannelVisibility;
        objTeam.Status = exportChannelfaileddata[i].Status == undefined ? "" : exportChannelfaileddata[i].Status;
        objTeam.Comments = exportChannelfaileddata[i].Comments == undefined ? "" : exportChannelfaileddata[i].Comments;
        tempArr.push(objTeam)
      }
      csvExporter.generateCsv(tempArr);
    } catch (error) {

      ////console.log(error);
    }


  }
  const exportTeamCreationFailed = async () => {

    try {

      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Invalid Team details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'Invalid Team details',
      };


       
      const csvExporter = new ExportToCsv(options);
      let tempArr: any = []



      for (let i = 0; i < exportTeamsfaileddata.length; i++) {
  
    
        
        let objTeam = {
          MSTeamName: "",
          MSTeamID: "",
          MSTeamVisibility: "",
          MSTeamChannelName: "",
          MSTeamChannelID: "",
          MSTeamChannelVisibility: "",
          Status: "", 
          Comments:""
        }
        let objTeamTemplate = {
          MSTeamName: "",
          MSTeamVisibility: "",
          Status: "",
          Comments:""
        }
         
        if (isTeamInvalidTemplate == true) {
          objTeamTemplate.MSTeamName = exportTeamsfaileddata[i].MSTeamName == undefined ? "" : exportTeamsfaileddata[i].MSTeamName;
          objTeamTemplate.MSTeamVisibility = exportTeamsfaileddata[i].MSTeamVisibility == undefined ? "" : exportTeamsfaileddata[i].MSTeamVisibility;
          objTeamTemplate.Status = exportTeamsfaileddata[i].Status == undefined ? "" : exportTeamsfaileddata[i].Status;
          objTeamTemplate.Comments = exportTeamsfaileddata[i].Status == undefined ? "" : exportTeamsfaileddata[i].Comments;
          tempArr.push(objTeamTemplate)
        }
        else {
          objTeam.MSTeamName = exportTeamsfaileddata[i].MSTeamName == undefined ? "" : exportTeamsfaileddata[i].MSTeamName;
          objTeam.MSTeamID = exportTeamsfaileddata[i].MSTeamID == undefined ? "" : exportTeamsfaileddata[i].MSTeamID;
          objTeam.MSTeamVisibility = exportTeamsfaileddata[i].MSTeamVisibility == undefined ? "" : exportTeamsfaileddata[i].MSTeamVisibility;
          objTeam.MSTeamChannelName = exportTeamsfaileddata[i].MSTeamChannelName == undefined ? "" : exportTeamsfaileddata[i].MSTeamChannelName;
          objTeam.MSTeamChannelID = exportTeamsfaileddata[i].MSTeamChannelID == undefined ? "" : exportTeamsfaileddata[i].MSTeamChannelID;
          objTeam.MSTeamChannelVisibility = exportTeamsfaileddata[i].MSTeamChannelVisibility == undefined ? "" : exportTeamsfaileddata[i].MSTeamChannelVisibility;
          objTeam.Status = exportTeamsfaileddata[i].Status == undefined ? "" : exportTeamsfaileddata[i].Status;
          objTeam.Comments = exportTeamsfaileddata[i].Status == undefined ? "" : exportTeamsfaileddata[i].Comments;
          tempArr.push(objTeam)
        }
      }
       
      csvExporter.generateCsv(tempArr);

    } catch (error) {
      ////console.log(error);
    }


  }

  const exportChannelCreationSuccess = async () => {

    try {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Created Channel Details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Created Channel Details",
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportChannelCreationSuccessData);
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }

  const exportTeamCreationSuccess = async () => {

    try {
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Created Team Details',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Created Team Details",
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(exportTeamCreationSuccessData);
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }

  }
  const successokclick = () => {
    try {
      document.getElementById("successpopup")?.setAttribute("class", "modal fade");
      document.getElementById("successpopup")?.setAttribute("style", "display:none");
      document.getElementById("successpopup")?.setAttribute("aria-hidden", "true");
      document.getElementById("successpopup")?.removeAttribute("aria-modal");
      document.getElementById("successpopup")?.removeAttribute("role");
      document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
      createStatus = false
      setCreateStatus(createStatus)
      if (!(typeof exportChannelCreationSuccessData == 'string') && exportChannelCreationSuccessData.length > 0) {
        exportChannelCreationSuccess()
        exportChannelfaileddata = []
        setexportChannelfaileddata(exportChannelfaileddata)
        exportChannelCreationSuccessData = []
        setExportChannelCreationSuccessData(exportChannelCreationSuccessData)
      }
      if (!(typeof exportTeamCreationSuccessData == 'string') && exportTeamCreationSuccessData.length > 0) {
        exportTeamCreationSuccess()
        exportTeamsfaileddata = []
        setexportTeamfaileddata(exportTeamsfaileddata)
        exportTeamCreationSuccessData = []
        setExportTeamCreationSuccessData(exportTeamCreationSuccessData)
      }
    } catch (error) {
      document.getElementById("loader")?.setAttribute('hidden', 'true');
      status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
      setStatus(status)
      alertpopup()
      ////console.log(error);
    }


  }
  const handleCheckboxChange = () => {
    try {
      setIsEnableDeleteChecked(!isEnableDeleteChecked);
    } catch (error) {
      
    }
   
   
  };

  return (
    <div className="row justify-content-center my-5 card-container mx-0 ms-0 overall-res-padding">
      <BackNavigation backNav={"  Migration using Service Account "} link={'/ChannelMigration'} />
      <WizardNavigation wizActive={"step1InActive"} />
      <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>Team Channel Creation and Mapping</p>


      <div className="modal fade" id="teams-creation" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title font-bold font-18 f-sb" >Teams & Channel Creation</h5>
              <button type="button" onClick={handleclose} className="btn-scondary-custom btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>  
            <div className="modal-body ">
              <p className="font-14 info-text-style mb-4">MS Teams Channels created in Public, Private and Shared.</p>
              {/* <div className="col-md-12">
                <h5 className="font-semibold font-16 ">Step 1 : Import Team Creation details  <span className="required">*</span></h5>
              </div> */}
              <div className="toggle accordion font-16 font-bold accordian-label ">
                <h5 className="font-semibold font-16 margin-bottom-20 "><span>Import Team Creation details</span><span className="required"> *</span></h5>
              </div>
              <div id="teamCreation">
                <div className="mb-2">
                  <div className="input-group">
                    <div className="btn-scondary-custom common-btn-class fileUpload btn anchorAsPointer">
                      <span>Browse</span>
                      <input id="uploadBtn" accept=".csv" onChange={uploadfilemethod} type="file" className="upload" />
                    </div>
                    <input id="uploadFile" placeholder="No File Chosen" disabled className=" formcontrol-browse ps-2 ms-1" />
                  </div>
                </div>
                <div className="col-md-12 pt-1">
                  <a id="teamtemplatebtn" className="download-template  anchorAsPointer mt-4" onClick={(e) => handleteamtemplatedownload()}><span className="required" ><img src="/Images/download-template.svg" alt="download-logo" /></span> Download Team Creation Template</a>
                  <p className="font-14 info-text-style my-2">It is recommended to create up to 15 Teams at once</p>
                </div>
                <div className="float-end border-0">
                  <button type="button" id="createbtn" className="btn-primary-theme common-btn-class btn btn-prime classLeft font-16 font-regular ms-2" onClick={(e) => handlecreateteams(e)}>Create Teams</button>
                </div>
              </div>
            </div>
            {/* Channel Creation  */}
            <div className="modal-body pt-0">
              <div className="toggle accordion font-16 font-bold accordian-label ">
                <h5 className="font-semibold font-16 margin-bottom-20 "> <span>Import Channel Creation details</span>  <span className="required"> *</span></h5>
              </div>
              <div id='channelCreation'>
                <div className="mb-2">
                  <div className="input-group">
                    <div className="common-btn-class btn-scondary-custom fileUpload btn anchorAsPointer">
                      <span>Browse</span>
                      <input id="uploadBtn" accept=".csv" onChange={uploadFileChannelCreation} type="file" className="upload" />
                    </div>
                    <input id="uploadChannelFile" placeholder="No File Chosen" disabled className=" formcontrol-browse ps-2 ms-1" />
                  </div>
                </div>
                <div className="col-md-12 pt-1">
                  <a id="teamtemplatebtn" className="download-template anchorAsPointer mt-4" onClick={(e) => handleChannelTemplateDownload()}><span className="required" ><img src="/Images/download-template.svg" alt="download-logo" /></span> Download Channel Creation Template</a>
                  <p className="font-14 info-text-style my-2">It is recommended to create up to 15 Channels at once</p>
                </div>
                <div className="float-end border-0">
                  <button type="button" id="createChannelBtn" className="btn-primary-theme common-btn-class btn btn-prime font-16 font-regular ms-2" onClick={(e) => handlecreatechannel(e)}>Create Channel </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="col-md-12">
        <div className="accordion accordion-flush" id="accordionPanelsStayOpenExample">
          {/* accordian-1 starts here */}
          <div className="accordion mb-5">
            <h2 className="accordion-header" id="panelsStayOpen-headingOne" >
              <div className="toggle accordion accordine-font-size-res font-bold accordian-label " >
                <img onClick={step1HideShowClick} id="step1Arrow" src="/Images/accordian-open.svg" className="close-dropDown" />


                <span onClick={step1HideShowClick} className="accordian-label-grey">Step 1 :</span> <span onClick={step1HideShowClick} className="accordian-label-dark">Team Channel Creation</span>
              </div>
            </h2>
            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
              <div className="accordion-body">
                <label className="font-14 w-100 font-regular accordian-para">Create new MS Teams or channels, if you wish to migrate to new Teams or channels. Teams & channels created will be in normal mode.</label>
                <span> <button type="button" onClick={(e) => enablecreationpopup()} className="custom-button-alignment btn-primary-theme common-btn-class btn btn-prime font-16 font-regular ms-2">Create Teams & Channels</button></span>
              </div>
            </div>
          </div>
          {/* accordian-1 ends here */}
          {/* accordian-2 starts here */}
          <div className="accordion mb-5">
            <h2 className="accordion-header" id="panelsStayOpen-headingTwo" >
              <div className="toggle accordion accordine-font-size-res font-bold accordian-label ">
                <img id="step2Arrow" src="/Images/accordian-open.svg" className="close-dropDown" onClick={step2HideShowClick} />


                <span onClick={step2HideShowClick} className="accordian-label-grey">Step 2 :</span> <span onClick={step2HideShowClick} className="accordian-label-dark">Import Mapped Slack &amp; Teams Channels</span>
              </div>
            </h2>
            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse  show" aria-labelledby="panelsStayOpen-headingTwo">
              <div className="accordion-body ">
                <p className="font-14 font-regular w-100 accordian-para mb-4 ">Export the existing Slack & MS Teams channel details and customize the channel mapping template. Import and “Submit” to verify mapping.
                </p>
                <div className="browse-btn-align-res">
                  <button type="button" className="common-btn-class-tccm-res20 btn-primary-theme btn btn-prime btn font-16 my-2 me-2 font-regular " onClick={downloadsource}><span><img src="/Images/download_white_logo.svg" alt="download-btn-logo" /></span> Download Slack Channels</button>
                  <button type="button" className="common-btn-class-tccm-res20 button-chatcreation-topmargin btn-primary-theme my-2 button-chatcreation-margin btn btn-prime btn font-16 font-regular " onClick={downloadtarget}><span><img src="/Images/download_white_logo.svg" alt="download-btn-logo" /></span> Download Teams Channels</button>
                  {/* <div className=""> */}
                  <div className="d-inline-flex my-2 button-chatcreation-topmargin ">
                    <div className="input-group my-1  pe-2">
                      <div className="common-btn-class fileUpload btn btn-scondary-custom btn-browse px-4  anchorAsPointer">
                        <span>Browse</span>
                        <input id="uploadBtnSlack" onChange={(e) => handleuploadfile(e)} accept=".csv" type="file" className="upload" />
                      </div>
                      <input id="uploadFileSlack" placeholder="No File Chosen" disabled className=" upload-SA ps-2 ms-1 uploadbtn-wid" />
                    </div>
                  </div>
                  <a className="  download-template button-chatcreation-margin my-2 pt-2 pe-2 anchorAsPointer display-anchor" id="templatebtn" onClick={(e) => handledownload(e)}><span className="pe-2"><img src="/Images/download_link.svg" alt="download" /></span>Channel Mapping Template</a>
                  <button type="button" id="submit" className="common-btn-class-tccm-res  my-2 button-chatcreation-topmargin sub-btn-position  btn-primary-theme btn btn-prime font-16 font-regular" onClick={(e) => handlesubmit(e)}>Submit</button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* accordian-2 ends here */}
          {/* accordian-3 starts here */}
          <div className="accordion mb-3">
            <h2 className="accordion-header" id="panelsStayOpen-headingThree" >
              <div className="toggle accordion accordine-font-size-res font-bold accordian-label ">
                <img id="step3Arrow" src="/Images/accordian-open.svg" className="close-dropDown" onClick={step3HideShowClick} />
                <span onClick={step3HideShowClick} className="accordian-label-grey">Step 3 :</span> <span onClick={step3HideShowClick} className="accordian-label-dark">Create Mapping</span>
              </div>
            </h2>
            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse  show" aria-labelledby="panelsStayOpen-headingThree" >
              <div className="accordion-body ">
                <div className="d-block d-lg-flex justify-content-none justify-content-lg-between">
                <p className="font-14 font-regular accordian-para mb-2 mt-2 ">Select the required mappings to create mapping.</p>
                {/* <div className="row float-lg-end float-sm-start float-md-end mb-2"> */}
                {/* <div className=""> */}
                  <div className=" d-block d-md-flex mb-2">
                  <div className="form-check form-switch float-start mt-2 me-3" id="enableDeletToggle" hidden>
                    <input className="form-check-input" type="checkbox" id="enableDelete" checked = {isEnableDeleteChecked} onClick={handleCheckboxChange}></input>
                    <label className="form-check-label font-semibold font-15 text-nowrap" htmlFor="enableDelete">Enable Delete</label>
                    </div>
                    {/* <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3"># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className="font-regular font-16">of </span> {totalItemsCount}</p> */}
                    <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3">
                            # of records: {gridData[0] != "No Records Found" ? gridData.length:0}{" "}
                            <span className="font-regular font-16">of </span> 
                            { gridData[0] != "No Records Found" ?
                            operation == "search"?
                                totalItemsCountFeature: totalItemsCount 
                            :0}{" ("}{selectedCheckBoxArray.length}{" Selected)"}
                            </p>

                      <div className="input-group pt-3 pt-md-0 box-shodow-filter">
                      <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={Searchtext}
                        onKeyDown={(e) => handleDownKey(e)} onChange={handleSearch} />
                      <button className="btn btn-outline-secondary" onClick={(e) => handleSearchIcon(e)} type="button" id="button-addon1"><img src="/Images/search.svg" alt="search-icon" />
                      </button>
                    </div>
                  </div>
                {/* </div> */}
                </div> 
                <div className="col-md-12 float-start mt-2 p-1 col-12">
                  <div className=" table-responsive table-border">
                    <table className="table mb-4">
                      <thead className="tabel-head-bg">
                        <tr>
                          {/* <th className="table-head" /> */}
                          <th className="tabel-head-font-level1 font-bold font-14 channel-table-column" style={{paddingLeft: '30px',paddingTop: '20px'}}>
                          {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                            <input
                              className="form-check-input me-2 check-alg-2 intermediate"
                              id="selectAllCheckBox"
                              type="checkbox"
                              disabled = {selectAllShowHide}
                              onClick={handleSelectAll}
                            />
                        </th>
                          <th className="table-head font-14 font-bold">Slack Channel Name<span>
                            <a className="anchorAsPointer"><img className="ms-2" src="/Images/sort_arrow.svg" alt="sort-arrow" id="SourceChannelName@desc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "SourceChannelName@desc" ? true : false} /></a></span>
                            <a className="anchorAsPointer"><img className="ms-2" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="SourceChannelName@asc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "SourceChannelName@desc" ? false : true}></img></a></th>
                          <th className="table-head font-14 font-bold">Slack Channel ID
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="SourceChannelID@desc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "SourceChannelID@desc" ? true : false}></img>
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="SourceChannelID@asc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "SourceChannelID@desc" ? false : true}></img>
                          </th>
                          <th className="table-head font-14 font-bold">Team Name
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="TargetTeamName@desc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "TargetTeamName@desc" ? true : false}></img>
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="TargetTeamName@asc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "TargetTeamName@desc" ? false : true}></img>
                          </th>
                          <th className="table-head font-14 font-bold">Team Channel Name
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_arrow.svg" alt="sort-arrow" id="TargetChannelName@desc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "TargetChannelName@desc" ? true : false}></img>
                            <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="TargetChannelName@asc" onClick={e => sortClicked(e)}
                              hidden={sorthide == "TargetChannelName@desc" ? false : true}></img>
                          </th>
                          <th className="table-head font-14 font-bold text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gridBodyData()}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-md-12  mt-3 mb-3 text-center">
                    {/* {totalItemsCount > countPerPage ?
                      <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mt-3 mb-2" onClick={() => handleLoadMore()} >Load More</button>
                      : ""} */}
                    {operation == "search" ? (
                      totalItemsCountFeature > countPerPage ? (
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mt-3 mb-2" onClick={handleLoadMore}>
                          Load More
                        </button>
                      ) : ""
                    ) : (
                      totalItemsCount > countPerPage ? (
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mt-3 mb-2" onClick={handleLoadMore}>
                          Load More
                        </button>
                      ) : ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* accordian-3 ends here */}


          <div className="col-md-12 float-start mt-3 mb-5 text-center">

          </div>
        </div>
      </div>


      <body className="body-color">
        <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
          <div className=" mx-3 d-flex justify-content-end">
            <button type="button" id="createmappingbtn" disabled={selectedCheckBoxArray.length != 0 ? false:true} onClick={btnCreateMapping} className="btn-primary-theme btn-primary-theme common-btn-class btn btn-footer font-16 font-regular me-2">Create Mapping</button>
          </div>
        </footer>
        <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
          <div className="modal-dialog modal-md">
            <div className="modal-content content-margin">
              <div className="modal-body">
                <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                <div className="text-center success-content w-100 mt-5">
                  <h5 className="font-semibold font-18">Alert!</h5>
                  <p className="success-text w-100 text-center mb-4">{status}</p>
                  <div id="dynamicData"></div>
                  <button type="button" id="okclickbtn" onClick={okbtnclick} className="btn-primary-theme common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal">Ok</button>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="modal fade" id="successpopup" tabIndex={-1} aria-hidden="true">
          <div className="modal-dialog modal-md">
            <div className="modal-content content-margin">
              <div className="modal-body">
                <img src="/Images/success.svg" className="success-icon" alt="success" />
                <div className="text-center success-content w-100 mt-5">
                  <h5 className="font-semibold font-18">Success!</h5>
                  <p className="success-text w-100 text-center mb-4">Teams Channel created Successfully</p>

                  <button type="button" id="okbtn" className="btn-primary-theme common-btn-class btn btn-ok  text-center btn" data-bs-dismiss="modal" onClick={successokclick}>Ok</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </body>
      <div className="overlay" id='loader' hidden={true}>
        <div className=" Loader-Content-position ">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
          <p className="text-center">{loaderContent}</p>
        </div>
      </div>
      <div className="delayoverlay" id='delayloader' hidden={true}>
        <div className="loader-position Loader-Content-position gif-top-align loadercustom ">
          <img className='delayloader-width' src='/Images/delayloader.gif' />
          <p className='mt-4 font-semibold'>{isSourceDelayLoader ? "Please wait while we're working on getting your complete Slack Channels list!" : "Please wait while we're working on getting your complete MS Teams Channels list!"}</p>
        </div>
      </div>
      <div className="overlay" id='loaderWithoutContent' hidden={true}>
        <div className=" Loader-Content-position ">
          <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      </div>
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
    </div>
  )
}