import React, { useEffect, useState } from 'react';
import { gridLoad } from "../../Services/AdminServices";

import intlTelInput from 'intl-tel-input';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants  from '../../Common/Constants'
export default function AdminAccountInfo() {

    let baseURL: any = constants.URL.REACT_APP_SRVADMPRTL_BASEURL;
    let local: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any ;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = 0;
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

    }

    let formObj = { UserName: "", EmailAddress: ""}
    let [formState, setFormState] = useState(formObj);
    let [alertMessage, setAlertMessage] = useState("")
    // const [clientID, setclientID] = useState(1);

    // AAI_PS_03 - AAI_PS_11
    useEffect(() => {
        let invoiceHeight: any = document.getElementById("MainContainerMFE");
        invoiceHeight.classList.remove("container-bg-withoutFooter")
        let submitButton = document.getElementById("saveButton")
        submitButton?.setAttribute("disabled", "disabled")
        getAccountInfo()
      //#endregion
    }, [])



    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("aid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion  

    // AAI_PS_03 - AAI_PS_11
    const getAccountInfo = async () => {
        try {
            debugger
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (objClient.AID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/accountInfo/getAdminProfile`,
                    data: {
                        "adminID": objClient.AID,
                        "type":"get"
                    },
                }
                )

                let data: any = await gridLoad(config);


                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong. Please reach AVAMIGRATRON team")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }

                else {
                    accountInfo(data.data.data)
                }

            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    //AAI_PS_20 - AAI_PS_29
    const AccountInfoSubmit = async () => {
        try {
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (objClient.AID != undefined && clientToken != undefined) {
                let submitButton = document.getElementById("saveButton")
                submitButton?.setAttribute("disabled", "disabled")
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/accountInfo/getAdminProfile`,
                    data: {
                        "adminID": objClient.AID,
                        "userName": `${formState.UserName}`,
                        "type":"update"
                    },
                }
                )
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');

                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Failed to Update Account Information!");
                }
                else if(data.data.data == "Updation Successfull"){
                    getAccountInfo()
                }

                else {
                    accountInfo(data.data.data)
                    //#region Update and Frame Client Session

                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion


                }
                let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                //#endregion
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const popUpOkClick = (type: string) => {
        try {
            if (isSessionEpired === false) {
                //#region Hide PopUp
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                let submitButton = document.getElementById("saveButton")
                submitButton?.setAttribute("disabled", "disabled")
                if (type != "alert") {
                    window.location.reload();
                }
            }
            else {
                window.location.pathname = "Admin/Login";
            }
            //#endregion
        } catch (error) {

        }
    }

    const accountInfo = async (accDetails: any) => {
        try {
            
            formState = { UserName: accDetails.Name,  EmailAddress: accDetails.MailID  }
            setFormState(formState)

        }
        catch (error: any) {

        }
    }

   

    const OnChangeName =  (e: any) => {
        try {
            formState = { ...formState, [e.target.name]: e.target.value }
            setFormState(formState)
        }
        catch (error: any) {


        }
    }

    //AAI_PS_12 - AAI_PS_19
    const regexValidation = (e: any) => {
        try {
             OnChangeName(e)

            let submitButton = document.getElementById("saveButton");

            let userName = document.getElementById("userName")
            let email = document.getElementById("Email")

            let emptyUserName = document.getElementById("emptyuserName");
            let emptyEmail = document.getElementById("emptyEmail")

            if (e.target.id == "user-name") {
                if (formState.UserName == "") {
                    emptyUserName?.removeAttribute('hidden')
                    userName?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyUserName?.setAttribute('hidden', 'hidden')
                    let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                    let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                    let result = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                    if (result == false) {
                        userName?.removeAttribute('hidden')
                    }
                    else {
                        userName?.setAttribute('hidden', 'hidden')
                    }
                }
            }

            else if (e.target.id == "email") {
                if (formState.EmailAddress == "") {
                    emptyEmail?.removeAttribute('hidden')
                    email?.setAttribute('hidden', 'hidden')
                    submitButton?.setAttribute('disabled', 'disabled');
                }
                else {
                    emptyEmail?.setAttribute('hidden', 'hidden')
                    let result = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)

                    if (result == false && formState.EmailAddress.length <= 320) {
                        email?.removeAttribute('hidden')
                    }
                    else {
                        email?.setAttribute('hidden', 'hidden')
                    }
                }
            }

            if (formState.UserName != ""  && formState.EmailAddress != "" && formState.EmailAddress.length <= 320) {
                let spaceRemoved = formState.UserName.replace(/\s\s+/g, ' ')
                let trimmed = spaceRemoved.trim().replaceAll(" +", " ");
                let Check1 = /^[a-zA-Z ]{1,80}$/.test(trimmed)
                let Check3 = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(formState.EmailAddress)

                if (Check1 && Check3 == true) {
                    submitButton?.removeAttribute('disabled');
                }
                else {
                    submitButton?.setAttribute('disabled', 'disabled');
                }
            }
            else {
                submitButton?.setAttribute('disabled', 'disabled');
            }
        }
        catch (error: any) {

        }
    }

    return(
        <div id="account-information" className="tab-pane active pt-3 pb-5">

            <div className="col-md-12 col-sm-12 col-lg-12 pu-border-none">
                <div className="row">
                    <div className="col-md-12 my-2 col-sm-12 col-lg-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="user-name">User Name<span className="required">*</span></label>
                        <input type="text" className="form-control custom-form" id="user-name" name="UserName" value={formState.UserName} onChange={(e) => regexValidation(e)} />
                        <label id="userName" className="required" hidden={true} >Please enter Valid User Name</label>
                        <label id="emptyuserName" className="required" hidden={true} >User Name cannot be empty</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 my-2 col-sm-12 col-lg-4 pu-form-space">
                        <label className="font-16 font-semibold form-label" htmlFor="email">Email Address<span className="required">*</span></label>
                        <input type="email" className="form-control custom-form" id="email" name="EmailAddress" disabled value={formState.EmailAddress} />
                    </div>
                </div>
            </div>
            {/* <!-- footer starts --> */}
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                <div className=" mx-3 d-flex justify-content-end">
                    <button type="button" id="saveButton" className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2" onClick={(e) => AccountInfoSubmit()} >Submit</button>
                </div>
            </footer>
            {/* <!-- footer ends -->  */}
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--Success Pop-up--> */}
            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='/Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4"> Account Information Updated Successfully!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>
    )
}