import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/ProfileServices';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import constants from "../../Common/Constants";

export default function ContactUs() {
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientToken: any;
    let adminToken: any;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }


    let formObj = { UserName: "", CompanyName: "", EmailAddress: "", PhoneNumber: "" }
    let [formState, setFormState] = useState(formObj);


    // const [clientID, setclientID] = useState(1);


    // const [userName, setuserName] = useState("Sam Abesak");
    // const [companyName, setcompanyName] = useState("Avasoft");
    // const [EmailAddress, setEmailAddress] = useState("sam.a@avasoft.com");
    // const [phoneNumber, setphoneNumber] = useState(9067970699);
    let [Category, setCategory] = useState("");
    let [comments, setcomments] = useState("");
    let [alertMessage, setAlertMessage] = useState("")
    let [boolVal, setBoolVal] = useState(0);

    useEffect(() => {
        resetSessionTimer();
        let Submit: any = document.getElementById("Submit");
        Submit.disabled = true;
        let dropdown: any = document.getElementById("category")
        dropdown.value = "Select"
        loadAccountInfo()
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
      //#endregion
        return () => {
        window.removeEventListener('click', resetSessionTimer);
        window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])




    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                     let updatedobjClient: any = jwt(dePass);
                     SessionEpiredDateTime = updatedobjClient.CreatedAt;
                     setSessionEpiredDateTime(SessionEpiredDateTime);
                     clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                     //console.log(clientToken);
                     //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion


    const loadAccountInfo = async () => {
        try {
            
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/UpdateProfile/GetClientProfileDetails`,
                    data: {
                        "clientID": clientID
                    },
                }
                )
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');
                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    //#endregion
                }

                else {
                    accountInfo(data.data.data)

                }
            }
            else {
                localStorage.clear();
            }

        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const accountInfo = async (accDetails: any) => {
        try {

            formState = { UserName: accDetails.Name, CompanyName: accDetails.CompanyName, EmailAddress: accDetails.MailID, PhoneNumber: accDetails.Phone }
            setFormState(formState)
        }
        catch (error: any) {

        }
    }


    const textchange = (e: any) => {
        try {
            //
            if (e.target.value == "Settings") {
                setCategory(e.target.value)
                boolVal = boolVal + 1;
            }
            else if (e.target.value == "Inventory") {
                setCategory(e.target.value)
                boolVal = boolVal + 1;

            }
            else if (e.target.value == "Migration") {
                setCategory(e.target.value)
                boolVal = boolVal + 1;

            }
            else if (e.target.value == "Plan") {
                setCategory(e.target.value)
                boolVal = boolVal + 1;

            }
            else if (e.target.value == "Other") {
                setCategory(e.target.value)
                boolVal = boolVal + 1;

            }
            if (boolVal != 0) {
                let a: any = document.getElementById("Submit");
                a.disabled = false;
            }
            else {
                let a: any = document.getElementById("Submit");
                a.disabled = true;
            }
        }
        catch (error: any) {

        }
    }

    const btnSubmit = async () => {
        try {
            //
            let loader = document.getElementById("loader")
            loader?.removeAttribute('hidden');
            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/ContactUs/ContactUsSaveDetails`,
                    data: {
                        "clientID": clientID,
                        "Category": `${Category}`,
                        "Comments": `${comments}`
                    },

                }
                )
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }

                // 
                let Submit: any = document.getElementById("Submit");
                Submit.disabled = true;
                let data: any = await gridLoad(config);
                loader?.setAttribute('hidden', 'hidden');
                // if (data.data.statusText == "oK") {
                if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else if (data.data.statusCode != 200) {
                    if (data.data.data == "Your request has reached its limit today. Please try again tomorrow or reach us through Migration.support@avasoft.com") {
                        setAlertMessage("Your request has reached its limit today. Please try again tomorrow or reach us through Migration.support@avasoft.com");
                    }
                    else {
                        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    }
                }

                let popup = (data.data.statusCode == 200) ? document.getElementById("sourceSuccessPopUp") : document.getElementById("alertPopUp")
                //#region Show PopUp
                popup?.removeAttribute("aria-hidden");
                popup?.setAttribute("aria-modal", "true");
                popup?.setAttribute("role", "dialog");
                popup?.setAttribute("class", "modal fade show");
                popup?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");

                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const popUpOkClick = (type: string) => {

        try {
            if (isSessionEpired === false) {

                //#region Hide PopUp
                let dropdown: any = document.getElementById("category");
                dropdown.value = "Select"
                let comments: any = document.getElementById("exampleFormControlTextarea1");
                comments.value = ""
                setcomments("")
                setCategory("")
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                let Submit: any = document.getElementById("Submit");
                Submit.disabled = true;
            }
            else {
                window.location.pathname = "/Login";
            }
            //#endregion
        } catch (error) {

        }
    }

    const commentBtn = (e: any) => {
        try {
            setcomments(e.target.value);
            let comments = document.getElementById("comments")
            let result = /^[\w\s!"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]{1,255}$/.test(e.target.value);
            if (result === false && e.target.value != '') {
                comments?.removeAttribute('hidden')
            }
            else {
                comments?.setAttribute('hidden', 'hidden')
            }
        } catch (error) {

        }
    }

    return (
        <div className="container-fluid  p-2 bg-white contact-bg1">
            <div className="row justify-content-center  mx-0">
                <div className="col-md-10 col-lg-7">
                    <div className="col-md-12 mb-3">
                        <h5 className="text-center contact-us font-bold font-26 mt-4 mb-2">Contact Us</h5>
                        <p className="text-center contact-us-para font-16 font-regular">Feel free to submit your queries here, we will get back to you as soon as possible</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="card col-md-10  mt-2 mb-4 card-box-showdow">
                            <div className="row justify-content-center">
                                <div className="col-md-11 py-5">
                                    <div className="row px-3">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-8  mb-4">
                                                    <h5 className="contact-us-head-font font-semibold font-14">Username</h5>
                                                    <label className="contact-us-label-font font-regular font-14" id='username' >{formState.UserName}</label>
                                                </div>
                                                <div className="col-md-4  mb-4">
                                                    <h5 className="contact-us-head-font font-semibold font-14">Company Name</h5>
                                                    <label className="contact-us-label-font font-regular font-14" id='companyname'  >{formState.CompanyName}</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-8  mb-4">
                                                    <h5 className="contact-us-head-font font-semibold font-14">Email Address</h5>
                                                    <label className="contact-us-label-font font-regular font-14 overflowcss" id='emailaddress' >{formState.EmailAddress}</label>
                                                </div>
                                                <div className="col-md-4  mb-4">
                                                    <h5 className="contact-us-head-font font-semibold font-14">Phone Number</h5>
                                                    <label className="contact-us-label-font font-regular font-14" id='phonenumber'  >{formState.PhoneNumber}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label className="contactus-label contact-us-head-font font-semibold font-14" htmlFor='category' >Category</label>
                                                <select className="form-select form-control custom-form" aria-label="Default select example" id='category' onChange={(e) => textchange(e)}>
                                                    <option selected value="Select">Select</option>
                                                    <option value="Other">Other</option>
                                                    <option value="Inventory">Inventory</option>
                                                    <option value="Migration">Migration</option>
                                                    <option value="Plan">Plan</option>
                                                    <option value="Settings">Settings</option>

                                                </select>
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="contactus-label contact-us-head-font font-semibold font-14" htmlFor="exampleFormControlTextarea1">Comments</label>
                                                <textarea className="form-control textarea-style-resize_none font-14" placeholder="Enter your comments" id="exampleFormControlTextarea1" onChange={(e) => commentBtn(e)} rows={4}></textarea>
                                                <label id="comments" className="required" hidden={true} >Please enter Comments within 255 chars</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button id='Submit' onClick={btnSubmit}>Submit</button> */}
                    <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">

                        <div className=" mx-3 d-flex justify-content-end">

                            <button type="button" id="Submit" onClick={btnSubmit} className="common-btn-class btn-primary-theme btn btn-footer font-16 font-regular me-2">Submit</button>

                        </div>

                    </footer>
                </div>
            </div>
            {/* <!--Success Pop-up--> */}

            <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='../Images/success.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Success!</h5>
                                <p className="success-text w-100 text-center mb-4"> Your queries has been submitted!</p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--success pop-up--> */}
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
            {/* <!--Alert Pop-up--> */}
            <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src='../Images/Alert.svg' className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{alertMessage} </p>
                                <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Alert pop-up--> */}
            {/* <!--loader section--> */}
            <div className="overlay" id='loader' hidden={true}>
                <div className="Loader-Content-position ">
                    <div className="spinner-border Loader Loader-Img-position text-primary aligncenter" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                    {/* <p className="aligncenter">Do not close or refresh the application while the process is in progress...</p> */}
                </div>
            </div>
            {/* <!--loader section--> */}
        </div>


    );
}