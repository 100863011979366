import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// import '../src/styles/commontheme.css'
// import '../src/styles/settings.css'
// import '../src/styles/font.css'
// import '../src/styles/footer.css'
// import '../src/styles/success_alert_popup.css'
// import '../src/styles/Loader.css'
// import '../src/styles/High-level Inventory.css'
// import '../src/styles/header.css'
// import '../src/styles/wizard.css'
// import '../src/styles/customgrid.css'
// import '../src/styles/channel_migration_landing.css'
// import '../src/styles/intlTelInput.css'
// import '../src/styles/help-button.css'
// import '../src/styles/maincontainer.css'

import '../src/styles/commontheme.css'
import '../src/styles/wizard.css'
import '../src/styles/settings.css'
import '../src/styles/font.css'
import '../src/styles/signup.css'
import '../src/styles/customgrid.css'
import '../src/styles/footer.css'
import '../src/styles/Loader.css'
import '../src/styles/invoice_history.css'
import '../src/styles/channel creation - popup.css'
import '../src/styles/inventory-details-popup.css'
import '../src/styles/user-verification.css'
import '../src/styles/conversation_migration.css'
import '../src/styles/ManageScheduleMigration.css'
import '../src/styles/add-azure.css'
import '../src/styles/success_alert_popup.css'
import '../src/styles/channel_migration_landing.css'
import '../src/styles/team_channel_creation.css'
import '../src/styles/High-level Inventory .css'
import '../src/styles/High-level Inventory.css'
import '../src/styles/inventory_details.css'
import '../src/styles/inventory-confirmation-popup.css'
import '../src/styles/member-migration.css'
import '../src/styles/help-button.css'
import '../src/styles/verify_email.css'
import '../src/styles/intlTelInput.css'
import '../src/styles/user_mapping.css'
import '../src/styles/individual-report.css'
import '../src/styles/ManageMigration.css'
import '../src/styles/contact-us.css'
import '../src/styles/plan.css'
import '../src/styles/GroupChatCreationAndMapping.css'
import '../src/styles/card_details_popup.css'
import '../src/styles/maincontainer.css'
import '../src/styles/header.css'
import '../src/styles/stripeElementUI.css'


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
