import React, { useEffect, useState } from 'react';
import { gridLoad } from '../../Services/InventoryServices';
import dateFormat from "dateformat"
import { ExportToCsv } from 'export-to-csv';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import constants from '../../Common/Constants'


export function MembersGrid() {
  let GridLoadarray: any[] = [];
  const state = useLocation().state as any;
  const [gridData, setGridData] = useState(GridLoadarray);
  const [totalItemsCount, setTotalItemsCount] = useState(10);
  let [searchValue, setSearchValue] = useState("");
  let [exportsearchValue, setexportsearchValue] = useState("");

  const [sorthide, setsorthide] = useState("");
  const [ChannelName, setChannelName] = useState(state.ChannelName);

  const [columnOrder, setColumnOrder] = useState("asc");
  const [columnName, setColumnName] = useState("user name");
  const [tmpsearchValue, settmpSearchValue] = useState("");
  const [clientID, setclientID] = useState(state.ClientID);
  const [token, settoken] = useState(state.token);
  const [adminToken, setadminToken] = useState(state.adminToken);
  const [LoadMore, setLoadmore] = useState("")
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let InActiveTimeout: NodeJS.Timeout;


  const [inventoryChannelSummaryID, setInventoryChannelSummaryID] = useState(state.ID);//useState(6); //
  const [type, setType] = useState("Grid");
  const [indexPerPage, setIndexPerPage] = useState(1);
  const [exportbtn, setexportbtn] = useState(false)
  let [ErrorMessage, setErrorMessage] = useState("");
  const [countPerPage, setCoutPerPage] = useState(10);
  let history = useHistory();

  const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
  let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

  let byteValue = objCryptoJS.AES.decrypt(state.token, r_n_Key);
  let decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
  let objClient: any = jwt(decryptedValue);
  SessionEpiredDateTime = objClient.CreatedAt;

  useEffect(() => {
    resetSessionTimer();
    let removeFooter: any = document.getElementById("MainContainerMFE");
    removeFooter.classList.add("container-bg-withoutFooter")
    loadGridPage();
    //#region Event handlers Keypad click and mouse click
    window.addEventListener('keydown', resetSessionTimer);
    window.addEventListener('click', resetSessionTimer);
    //#endregion
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      window.removeEventListener('keydown', resetSessionTimer);
    };
  }, [columnName, columnOrder, countPerPage])



  //#region Session Expiry POP-UP enable and Session Clear method

  const enableSessionExpiryPopup = () => {
    try {
      ErrorMessage = "Your session has expired. Please log in again to continue using the application."
      setErrorMessage(ErrorMessage);

      //#region Show PopUp
      let popup = document.getElementById("alertPopUp")

      popup?.removeAttribute("aria-hidden");
      popup?.setAttribute("aria-modal", "true");
      popup?.setAttribute("role", "dialog");
      popup?.setAttribute("class", "modal fade show");
      popup?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      //#endregion
      localStorage.removeItem("cid_t");
      setisSessionEpired(true);
    } catch (error) {

    }
  }
  //#endregion

  //#region  To fetch the updated sess

  const getClientSessionDetails = async () => {
    try {

      if (clientID != undefined) {
        let config = {
          url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
          token: token
        }

        let data: any = await gridLoad(config)
        if (data.data.statusCode == 200) {
          //#region Update and Frame Client Session
          localStorage.removeItem("cid_t");
          let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
          var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
          let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
          localStorage.setItem("cid_t", enPass);
          //#endregion

          //#region Update state variables
          //console.log(token);
          //console.log(SessionEpiredDateTime);
          let updatedobjClient: any = jwt(dePass);
          SessionEpiredDateTime = updatedobjClient.CreatedAt;
          setSessionEpiredDateTime(SessionEpiredDateTime);
          let clientToken: any = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
          settoken(clientToken);
          //console.log(token);
          //console.log(SessionEpiredDateTime);

          //#endregion
        }
        else if (data.data.data == "Invalid authentication token provided") {
          enableSessionExpiryPopup();
        }
        else {
          enableSessionExpiryPopup();
        }
      }
      else {
        localStorage.clear();
      }

    } catch (error) {
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  //#endregion

  //#region Initiate the time for inactive and check the session expiry time duration
  const resetSessionTimer = () => {
    try {
      //console.log("reset")

      clearTimeout(InActiveTimeout);
      InActiveTimeout = setTimeout(() => {
        enableSessionExpiryPopup();
      }, Number(process.env.REACT_APP_TABIDLESCENARIO));
      let currentDateTime: any = new Date().toISOString();
      let diffInMilliseconds: number;
      diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
      let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
        getClientSessionDetails();
      }
    }
    catch (ex: any) {
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  //#endregion



  const loadGridPage = async () => {
    try {
       
      let a: any = document.getElementById("loader");
      a.removeAttribute("hidden");
      let config = (
        {

          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getChannelMemberDetails`,
          token: token,
          data:
          {

            "clientID": clientID,
            "InventoryChannelSummaryID": Number(inventoryChannelSummaryID),
            "type": `${type}`,
            "sortColumn": `${columnName}`,
            "searchText": `${exportsearchValue}`,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage

          }
        }
      )
      if( adminToken !== null){
        (config.data as any).token = adminToken
      }




       
      let data: any = await gridLoad(config);
      let b: any = document.getElementById("loader");
      b.setAttribute("hidden", "hidden");
      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")

        //#region Show PopUp
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else {
         
        if (data.data.data == "no records were fetched" || data.data.data == "No records were fetched" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team" || data.data.data.length === 0) {
          setGridData(["No Records Found"]);
          setLoadmore(data.data.data);

        }
        else {
          setGridData(data.data.data.ChannelMemberDetails[0]);
          setLoadmore(data.data.data)
          setTotalItemsCount(data.data.data.ChannelMemberDetails[1][0].ChannelMembersCount)
          setChannelName(data.data.data.ChannelMemberDetails[0][0].SourceChannelName)
        }

      }

    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const gridBodyData = () => {
     
    try {
      if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team") {
        return gridData.map((obj, index) => {
          let rowClass = index % 2 == 0 ? "tabel-body-bg" : "tabel-body bgsecondary";
          let isLeftStatus = obj.IsLeft == false ? "status status-styling4 text-center font-14 font-semibold" : "status status-styling2 text-center font-14 font-semibold";
          return (
            <tr className={rowClass} >
              <td className="py-3 tabel-data-font-level1">{obj.SourceUserName}</td>
              <td className="py-3 tabel-data-font-level1">{obj.SourceUserMailID == "" ? "-" : obj.SourceUserMailID}</td>
              <td className="py-3 tabel-data-font-level1">{obj.IsCreator == true ? "Creator" : "Member"}</td>
              <td className="py-3 tabel-data-font-level1 text-center"><span className={isLeftStatus}>{obj.IsLeft == false ? "Active" : "Inactive"}</span></td>
            </tr>
          )
        }
        )
      }
      else if (gridData[0] == undefined && gridData[0].SourceUserName == undefined) {
        return (<tr> <td className="centertext" colSpan={6}></td>  </tr>)
      }
      else {
        return (
          <tr>
            <td className="aligncenter" colSpan={3}><img className="mt-3" src='/Images/NoRecordsFound.svg'></img>
              <p className="mt-2">No records found</p></td>
          </tr>)
      }
    }
    catch (error: any) {
      ////console.log(error)
    }
  }
  const handleExport = () => {
    try {
       
      exportMethod();
      setexportbtn(true)
    }
    catch (error: any) {
      ////console.log("error", error);
    }
  }
  const exportMethod = async () => {
    try {
      let a: any = document.getElementById("loader");
      a.removeAttribute("hidden");
      let config = (
        {
          method: "POST",
          url: `${constants.URL.REACT_APP_Inventory_BASEURL}/Inventory/InventorySummary/getChannelMemberDetails`,
          token: token,
          data:
          {

            "clientID": clientID,
            "InventoryChannelSummaryID": Number(inventoryChannelSummaryID),
            "type": "Export",
            "sortColumn": `${columnName}`,
            "searchText": `${exportsearchValue}`,
            "sortType": `${columnOrder}`,
            "pageIndex": indexPerPage

          }
        }
      )
      if( adminToken !== null){
        (config.data as any).token = adminToken
      }
      let data: any = await gridLoad(config);
      if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
        ErrorMessage = "Something went wrong! Please try again or reach AVAMIGRATRON Support.";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")

        //#region Show PopUp
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "no records were fetched") {
        ErrorMessage = "No Records Found in the Grid";
        setErrorMessage(ErrorMessage);
        let popup = document.getElementById("alertPopUp")
        popup?.removeAttribute("aria-hidden");
        popup?.setAttribute("aria-modal", "true");
        popup?.setAttribute("role", "dialog");
        popup?.setAttribute("class", "modal fade show");
        popup?.setAttribute("style", "display:block");
        document.getElementById("fadeDiv")?.removeAttribute("hidden");
      }
      else if (data.data.data == "Invalid authentication token provided") {
        enableSessionExpiryPopup();
      }
      else {
         
        let exportData = data.data.data.ChannelMemberDetails[0];

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: false,
          title: 'ChannelMember Details',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "ChannelMembers Details"
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(exportData);
      }
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
    }
    catch (error: any) {
      let loader: any = document.getElementById("loader");
      loader?.setAttribute('hidden', 'hidden');
      setErrorMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
      let alertPopUp = document.getElementById("alertPopUp");
      alertPopUp?.removeAttribute("aria-hidden");
      alertPopUp?.setAttribute("aria-modal", "true");
      alertPopUp?.setAttribute("role", "dialog");
      alertPopUp?.setAttribute("class", "modal fade show");
      alertPopUp?.setAttribute("style", "display:block");
      document.getElementById("fadeDiv")?.removeAttribute("hidden");
      document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
    }
  }
  const handleKeyDown = (e: any) => {
    try {
      if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
        if (searchValue !== tmpsearchValue) {
          gridData.length = 0;
          exportsearchValue = searchValue;
          setexportsearchValue(exportsearchValue);
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }
      }
    }
    catch (error: any) {

      ////console.log(error);

    }

  }
  const sortClicked = (e: any) => {
    try {
      let sortID = e.target.id;
            setsorthide(sortID)
      let sortval = sortID.split('@');
      setColumnName(sortval[0])
      setColumnOrder(sortval[1])
      setIndexPerPage(indexPerPage ? indexPerPage : 1);
      setCoutPerPage(countPerPage ? countPerPage : 10);
    }
    catch (error: any) {

    }
  }
  const handleSearch = (e: any) => {
    try {
      setSearchValue(e.target.value)
    }
    catch (error: any) {

    }
  }
  const handleLoadMore = () => {
    try {
       
      setCoutPerPage(countPerPage + 10);
      setIndexPerPage(indexPerPage + 1);
    }
    catch (error: any) {

    }
  }
  const handleSearchIcon = (e: any) => {

    try {

      if (searchValue != null && searchValue != undefined) {

        if (searchValue !== tmpsearchValue) {
          exportsearchValue = searchValue;
          setexportsearchValue(exportsearchValue);
          gridData.length = 0;
          settmpSearchValue(searchValue);
          setIndexPerPage(indexPerPage ? indexPerPage : 1);
          setCoutPerPage(countPerPage ? countPerPage : 10);
          loadGridPage();
        }

      }

    }

    catch (error: any) {

      ////console.log(error);

    }

  }
  const Memberbackbtn = () => {
    try {
       
      let ID: any = []
      ID["ID"] = ((state.InvID));
      ID["SlackWorkspaceName"] = (state.SlackWorkspaceName);
      ID["ClientID"] = clientID
      ID["token"] = token
      history.push({
        pathname: "/Inventory/Channel",
        state: ID
      })
      // window.location.reload();
      // window.location.pathname = '/Inventory/Channel';
    }
    catch (error: any) {

    }
  }
  const popUpOkClick = (type: string) => {

    try {

      if (isSessionEpired === false) {

        //#region Hide PopUp

        let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")

        popup?.setAttribute("class", "modal fade");

        popup?.setAttribute("style", "display:none");

        popup?.setAttribute("aria-hidden", "true");

        popup?.removeAttribute("aria-modal");

        popup?.removeAttribute("role");

        document.getElementById("fadeDiv")?.setAttribute("hidden", "true");

        // $('input[name="checkbox"]').prop('checked', false); // Unchecks it

        let migrateButton = document.getElementById("migrateButton")

        migrateButton?.setAttribute('disabled', 'disabled');

        // loadGridPage();

        //#endregion

      }
      else {
        window.location.pathname = "/Login";

      }

    } catch (error) {

      ////console.log("Issue Faced");

    }

  }

  return (
    <div className="row justify-content-center card-container mx-0 ms-0 single-grile-grid-res">
      <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
      <div className="overlay" id='loader' hidden={true}>
        <div className="loader-position Loader-Content-position">
          <div className="spinner-border Loader  Loader-Img-position text-primary align-center" role="status">
            <span className="visually-hidden"></span>

          </div>
          {/* <p>Do not close or refresh the application while the process is in progress...</p> */}
        </div>
      </div>

      <div className="col-md-12 col-sm-12 col-lg-12 mt-4">



        <div className="col-md-12 col-sm-12 col-lg-12 col-12 float-start">
          <label className="user-head-font font-26 font-bold mb-2 anchorAsPointer"><a className="me-2" onClick={Memberbackbtn} title="Back"><img title="Back" src="/Images/backarrow.svg" alt="Back arrow" /></a>Members</label>


          <div className="w-100 float-start">

          </div>



          <div className="tab-content">
            <div id="high-level-inventory" className="tab-pane active w-100">



              <div className="IndividualScreenHeadingcss w-100">
                <div className="col-md-12 float-start">
                  <div className="float-start  w-100 ">

                    <div className="float-start Inv_MarginTopContentSearch">
                      <label className="font-14 my-2 pe-3 inventory-single-grid pb-2 font-bold">Inventory :
                        {
                          state.InventoryName?.length > 36 ? <span data-tip={state.InventoryName} className="font-regular font-14">{state.InventoryName.substring(0, 33)}...</span> :
                            <span data-tip="" className="font-regular font-14">{state.InventoryName}</span>
                        }
                      </label>
                      <label className="font-14 my-2 font-bold pb-2">ChannelName : <span className="channel-font font-14 font-regular">{ChannelName}</span></label>
                    </div >
                    <div className="float-end">

                      <button className="btn my-2 btn-inventory-export btn-scondary-custom text- px-3 float-end font-semibold ms-2  font-16" onClick={() => handleExport()}><img src='/Images/export-excel.svg' alt="export-excel" onClick={() => handleExport()} /> Export</button>




                      <div className="row float-end">
                        <div className="col-md-12 my-2 sg-search-res2">
                          <div className="input-group box-shodow-filter">
                            <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" onChange={handleSearch} onKeyDown={handleKeyDown} />
                            <button className="btn btn-outline-secondary" onClick={handleSearchIcon} type="button" id="button-addon1"><img src='/Images/search.svg' alt="search-icon" />

                            </button>
                          </div>
                        </div>
                      </div>
                      <p className='mb-0 font-semibold font-16 text-nowrap float-end mt-3 me-3'># of records: {gridData[0] != "No Records Found" ? gridData.length:0} <span className='font-regular font-16'>of </span> {gridData[0] != "No Records Found"?totalItemsCount:0}</p>

                    </div>



                  </div>
                </div>
              </div>

              <div className="col-md-12 col-12 col-sm-12 col-lg-12 float-start mt-2 table-responsive p-1">
                <table className="table table-border">
                  <thead className="tabel-head-bg">
                    <tr>
                      <th className="table-head font-14 font-bold">UserName<span>

                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="user name@desc" onClick={e => sortClicked(e)} hidden={sorthide == "user name@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="user name@asc" onClick={e => sortClicked(e)} hidden={sorthide == "user name@desc" ? false : true} />
                      </span></th>

                      <th className="table-head font-14 font-bold">Email Address

                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="Email Adress@desc" onClick={e => sortClicked(e)} hidden={sorthide == "Email Adress@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="Email Adress@asc" onClick={e => sortClicked(e)} hidden={sorthide == "Email Adress@desc" ? false : true} />
                      </th>
                      <th className="table-head font-14 font-bold">Role


                        <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="role@desc" onClick={e => sortClicked(e)} hidden={sorthide == "role@desc" ? true : false} />
                        <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="role@asc" onClick={e => sortClicked(e)} hidden={sorthide == "role@desc" ? false : true} /></th>

                        <th className="table-head font-14 font-bold text-center">Status

                          <img className="ms-2 anchorAsPointer" src='/Images/arrow.svg' alt="sort-arrow" id="ActiveStatus@desc" onClick={e => sortClicked(e)} hidden={sorthide == "ActiveStatus@desc" ? true : false} />
                          <img className="ms-2 anchorAsPointer" src='/Images/sort_upper_arrow.svg' alt="sort-arrow" id="ActiveStatus@asc" onClick={e => sortClicked(e)} hidden={sorthide == "ActiveStatus@desc" ? false : true} /></th>
                        

                    </tr>
                  </thead>
                  <tbody >

                    {gridBodyData()}

                  </tbody>
                </table>






              </div>
              <div className="col-md-12 mt-3 mb-5 text-center">
                {totalItemsCount > countPerPage && LoadMore != "no records were fetched" ?

                  <button className="btn btn-loadmore font-bold font-16 px-3 my-4 btn-common-loadmore" onClick={() => handleLoadMore()}>Load More</button>
                  : ""}
              </div>

            </div>

            <div id="inventory-summary" className="tab-pane fade">
              <div className="row float-end">
                <div className="col-md-12 mb-3">

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>


      <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content modal-radius">
            <div className="modal-body pt-1">
              <div className="col-md-12 mb-4 mt-4">
                <div className="row">
                  <div className="w-100 float-start text-center mb-3">
                    <label className="form-label popup-label font-24 font-semibold mb-3 w-100">Need Help ?</label>
                    <label className="form-label popup-label2 font-16 font-regular w-100">Watch this video to learn how to take Inventory!</label>
                  </div>

                  <div className="w-100 float-start text-center">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/1PnVor36_40" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex justify-content-end mb-4">
                <button type="button" className="btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Alert Pop-up--> */}
      <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content content-margin">
            <div className="modal-body">
              <img src='/Images/Alert.svg' className="success-icon" alt="success" />
              <div className="text-center success-content w-100 mt-5">
                <h5 className="font-semibold font-18">Alert!</h5>
                <p className="success-text w-100 text-center mb-4">{ErrorMessage}</p>
                <button type="button" className="btn btn-ok  text-center btn-primary-theme common-btn-class" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
              </div>
            </div>
          </div>
        </div>


      </div>
      <span><ReactTooltip /></span>
      {/* <!--Alert pop-up--> */}
    </div>
  );
}