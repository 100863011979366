import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WizardNavigation from "./WizardSA";
import BackNavigation from './BackNavigation'
import ManageScheduleMigration from './ManageScheduleMigrationSA'
import ManageMigrationGrid from "./ManageMigrationGridSA";

export default function ConversationMigration() {
    let history = useHistory()
    let [grid, setGrid]: any = useState("ManageMigration")
    useEffect(() => {
        let x = document.getElementById("loaderWithoutContent")
        x?.removeAttribute("hidden")
    }, [])



    const tabClick = (e: any) => {
        let id = e.target.id
        if (id == "ManageMigration") {
            grid = "ManageMigration"
            setGrid(grid)
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let removeHeight: any = document.getElementById("MainContainerMFE");
            removeHeight.classList.remove("container-bg-withoutFooter")
        }
        else {
            grid = "ManageSchedule"
            setGrid(grid)
            document.getElementById("ManageSchedule")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold active")
            document.getElementById("ManageMigration")?.setAttribute("className", "px-5 py-2 nav-link nav-tab-custom font-16 font-bold")
            let removeHeight: any = document.getElementById("MainContainerMFE");
            removeHeight.classList.remove("channel-container-bg-withFooter")
            removeHeight.classList.add("container-bg-withoutFooter")
           

        }

    }
    return (
        <div>
            <div className="overlay" id='loader' hidden={true}>
            <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                    <p className="text-center">Do not close or refresh the application while the process is in progress...</p>
                </div>
            </div>
            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5 card-container mx-0 ms-0  overall-res-padding">

                <BackNavigation backNav={" Migration using Service Account "} link={"/ChannelMigration"} />
                <WizardNavigation wizActive={"step2InActive"} />
                <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>Content Migration</p>
                <div className="col-md-12 float-start mb-4">
                    <div className="float-start">
                        <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item">
                                <a className={grid == "ManageMigration" ? "common-navTab-mm-channelmig px-5 py-2 anchorAsPointer nav-link active font-16 nav-tab-custom font-bold " : "common-navTab-mm-channelmig px-5 py-2 nav-link anchorAsPointer font-16 nav-tab-custom font-bold "} id="ManageMigration" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Migration</a>
                            </li>
                            <li className="nav-item">
                                <a className={grid == "ManageSchedule" ? "common-navTab-smm-channelmig px-5 anchorAsPointer py-2 nav-link active font-16 nav-tab-custom font-bold " : "common-navTab-smm-channelmig px-5 anchorAsPointer py-2 nav-link  font-16 nav-tab-custom font-bold "} id="ManageSchedule" data-bs-toggle="tab" onClick={(e) => tabClick(e)}>Manage Scheduled Migration</a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Nav tabs ends here */}
                {/* Tab panes starts */}
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div className="tab-content">
                            {
                                grid == "ManageMigration" ? <ManageMigrationGrid /> : <ManageScheduleMigration />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>
    );
}