import { gridLoad } from '../../Services/ProfileServices'
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import objCryptoJS from 'crypto-js'
import jwt from 'jwt-decode'
import dateFormat from 'dateformat';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Enumerable, { from } from "node-enumerable";
import ReactTooltip from "react-tooltip";
import constants from "../../Common/Constants";





import CheckoutForm from "./CheckOutForm";
const stripePubKey: any = constants.URL.REACT_APP_REACT_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(stripePubKey);


export default function UpgradePlan() {
    const [clientSecret, setClientSecret] = useState("");
    const [selectedLicenseType, setSelectedLicenseType] = useState("")
    let baseURL: any = constants.URL.REACT_APP_MICROSERVICE_NODE_HOST;
    let local: any = localStorage.getItem("cid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientID: any;
    let clientName: any;
    let clientToken: any;

    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);

    let InActiveTimeout: NodeJS.Timeout;

    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientID = Math.random();
        clientName = objClient.CName;
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;
    }
    let defaultPageType: any = window.location.pathname;

    let planLandingtype: any = ((objClient.isUserBasedLicense == null && defaultPageType == "/ChoosePlan") ? "UserBased-Chat&Channel" :
        (objClient.isUserBasedLicense == true && defaultPageType == "/ChoosePlan") ? "UserBased" :
            (objClient.isUserBasedLicense == false && defaultPageType == "/ChoosePlan") ? "ChannelChatBased" : "ChannelChatBased");

    let [initialpagetype, setinitialpagetype] = useState(planLandingtype);
    let [isTabSwitch, setisTabSwitch] = useState(false)
    const history = useHistory()
    let [pageType, setPageType] = useState("")
    let [licenseType, setLicenseType] = useState("")
    let [licensePlan, setLicensePlan] = useState("")
    let [alertMessage, setAlertMessage] = useState("Failed to Complete Payment!")
    let [confirmationMessage, setConfirmationMessage] = useState("")
    // const [clientID, setclientID] = useState(1);
    let formObj = { ExpiryDate: "", CardHolderName: "", CardNumber: "", CVV: "" }
    let [formState, setFormState] = useState(formObj);
    let isUpradePlanShow = (objClient != undefined && objClient.IsInitialLogin != undefined && !objClient.IsInitialLogin) ? true : false;
    let billing: any = { ClientName: "", AddressLine1: "", AddressLine2: "", State: "", City: "", ZipCode: "", Country: "" }
    let [billingDetails, setBillingDetails] = useState(billing)
    let [loaderText, setLoaderText] = useState("")
    let [activeClass, setactiveClass] = useState("w-100 common-navTab-setting px-sm-4 px-md-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer text-nowrap  active");
    let [inactiveClass, setainctiveClass] = useState("w-100 common-navTab-setting px-sm-4 px-md-5 py-2 nav-link nav-tab-custom font-16 font-bold anchorAsPointer text-nowrap  ");
    let [userLimitCount, setuserLimitCount] = useState("")
    let [userCount, setuserCount] = useState(0)
    let [licenseCost, setlicenseCost] = useState(0)
    let [ExpiryDate, setExpiryDate] = useState(Date)
    let [planDetails, setplanDetails] = useState()
    let [planType, setplanType] = useState()
    let [lastPurchasedePlan, setlastPurchasedePlan] = useState()
    let [liteplan, setliteplan] = useState("")
    let [plusplan, setplusplan] = useState("")
    let [eliteplan, seteliteplan] = useState("")
    let [proplan, setproplan] = useState("")
    let [resourceUtilization, setresourceUtilization] = useState(0)
    let [totallicenseCost, settotallicenseCost] = useState(0)
    let [enablelicensepopup, setenablelicensepopup] = useState(false)
    let [ChannelCountForPlan, setChannelCountForPlan] = useState(0)
    let [licensepopupContent, setlicensepopupContent] = useState("")
    let [daysDifference, setdaysDifference] = useState(0);



    useEffect(() => {
        if (defaultPageType != "ChoosePlan") {
            initialpagetype = (objClient.isUserBasedLicense) ? "UserBased" : "ChannelChatBased";
            setinitialpagetype(initialpagetype);
        }
        resetSessionTimer();
        checkPlanExpiry();

        // let invoiceHeight: any = document.getElementById("MainContainerMFE");
        // invoiceHeight.classList.add("container-bg-withoutFooter")
        let page = window.location.href.split('/')
        page[3] == "UpgradePlan" ? pageType = "UpgradePlan" : pageType = "ChoosePlan";
        setPageType(pageType);
        let paymentButton = document.getElementById("paymentButton")
        paymentButton?.setAttribute("disabled", "disabled")
        if ((pageType == "ChoosePlan" || pageType == "") && !isUpradePlanShow && !isTabSwitch) {
            document.getElementsByTagName('video')[0]?.setAttribute("src", "https://avaeusavamigstglogdev.blob.core.windows.net/avamigratron/Avamigratron%20Slack%20to%20Team.mp4?sp=r&st=2024-09-18T05:39:24Z&se=2025-05-15T13:39:24Z&sv=2022-11-02&sr=b&sig=GUiB2RrfgqyTZGaqEqea5oqynkV3SHknFR0mP%2B75mP4%3D");
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade show")
            document.getElementById("tourFadeDiv")?.removeAttribute("hidden");
        }
        else {
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade")
            document.getElementById("tourFadeDiv")?.setAttribute("hidden", "true");
        }
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [])
    // $('.userCount').keyup(function (a:any) {
    //     a.value = a.value.replace(/[^0-9\.]/g, '');
    // });

    // const decimalInput = document.getElementById('decimalInput');

    // decimalInput.addEventListener('input', function() {
    //   // Remove any non-digit characters and decimal points
    //   this.value = this.value.replace(/[^0-9]/g, '');
    // });

    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {

            alertMessage = "Your session has expired. Please log in again to continue using the application."
            setAlertMessage(alertMessage);

            //#region Show PopUp
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(clientToken);
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("reset")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion


    const checkPlanExpiry = async () => {
        try {
            if (clientID != undefined) {
                let liteplan1;
                let plusplan1;
                let eliteplan1;
                let proplan1;
                let loader = document.getElementById("loader");
                loader?.removeAttribute('hidden');
                let config = {
                    url: `${baseURL}/upgradeLicense/validateplanDetails`,
                    token: clientToken
                }

                let data: any = await gridLoad(config)
                if (data.data.data != undefined) {
                    setplanDetails(data.data.lastPurchasedePlan);
                    setlastPurchasedePlan(data.data.data);
                    liteplan1 = Enumerable.from(data.data.data).where(a => (a.LicenseType === "Lite")).toArray();
                    plusplan1 = Enumerable.from(data.data.data).where(a => (a.LicenseType === "Plus")).toArray();
                    eliteplan1 = Enumerable.from(data.data.data).where(a => (a.LicenseType === "Elite")).toArray();
                    proplan1 = Enumerable.from(data.data.data).where(a => (a.LicenseType === "Pro")).toArray();

                    liteplan1.length > 0 ? setliteplan(liteplan1[0].LicenseType) : setliteplan("");
                    plusplan1.length > 0 ? setplusplan(plusplan1[0].LicenseType) : setplusplan("");
                    eliteplan1.length > 0 ? seteliteplan(eliteplan1[0].LicenseType) : seteliteplan("");
                    proplan1.length > 0 ? setproplan(proplan1[0].LicenseType) : setproplan("");
                    // if (liteplan1.length > 0 || plusplan1.length > 0 || eliteplan1.length > 0 || proplan1.length > 0) {
                    //     enablelicensepopup = true;
                    //     setenablelicensepopup(enablelicensepopup);
                    // }


                }
                if (data.data.datamessage == 'License will Expiry within 5 days') {
                    ExpiryDate = data.data.ExpiryDate;
                    let inputDate = new Date(ExpiryDate);
                    let month = inputDate.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
                    let day = inputDate.getDate();
                    let Enddate = inputDate.setDate(inputDate.getDate() + 10);
                    let inputEndDate = new Date(Enddate);
                    let endMonth = inputEndDate.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
                    let endday = inputEndDate.getDate();

                    setExpiryDate(data.data.ExpiryDate);
                    setAlertMessage("Your free resource usage will expire on " + month + " " + day + ". If you do not upgrade your plan by " + endMonth + " " + endday + ", your AVAMIGRATRON user details will be deleted, and you will need to register again and start from the beginning.")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                }
                else if (data.data.datamessage == 'License got Expired') {
                    await getClientSessionDetails();
                    setAlertMessage("Your current plan has expired, and to avoid any interruption, please upgrade your plan to continue our resources.")
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    document.getElementById("fadeDiv")?.removeAttribute("hidden");
                    document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                    // enableSessionExpiryPopup();
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                loader?.setAttribute('hidden', 'hidden');
                licensePlan = (objClient.isUserBasedLicense) ? "UserBased" : "ChannelChatBased";
                setLicensePlan(licensePlan);
                let currentDate: any = new Date();
                let futureDate: any = new Date(data.data.ExpiryDate);

                let timeDifference: any = futureDate - currentDate;
                daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
                setdaysDifference(daysDifference);

            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            setAlertMessage("Something went wrong. Please reach AVAMIGRATRON team")
            let popup = document.getElementById("alertPopUp")
            //#region Show PopUp
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    // Method used to trigger the payment intent creation flow for both user and channel license
    const popUp = async (e: any) => {
        try {
            if (daysDifference < 0 && (initialpagetype == "ChannelChatBased" && e.target.innerText == "Upgrade")) {
                e.target.value = (e.target.value == "") ? planType : e.target.value;
                enableLicenseUpdatePopupChannel(e.target.value);
            }
            else {
                licenseType = (licenseType == "") ? e.target.value : licenseType;
                planType = (licensePlan !== "UserBased") ? (licensePlan === "ChannelChatBased") ? licenseType : e.target.value : planType;
                await getClientSecret(planType);
                let cardDetails = document.getElementById("cardDetails")
                cardDetails?.removeAttribute("aria-hidden");
                cardDetails?.setAttribute("aria-modal", "true");
                cardDetails?.setAttribute("role", "dialog");
                cardDetails?.setAttribute("class", "modal fade show");
                cardDetails?.setAttribute("style", "display:block");
                let alertPopUp = document.getElementById("fadeDiv");
                alertPopUp?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");

            }
        }
        catch (error: any) {


        }
    }
    //#endregion

    const close = async () => {
        try {
            if (pageType == "UpgradePlan") {
                window.location.href = '/UpgradePlan'
            }
            else {
                window.location.href = '/ChoosePlan'
            }
            clearState()
            let card = document.getElementById("card")
            let name = document.getElementById("name")
            let date = document.getElementById("date")
            let cvv = document.getElementById("cvv")

            let emptycard = document.getElementById("emptycard")
            let emptyname = document.getElementById("emptyname")
            let emptydate = document.getElementById("emptydate")
            let emptycvv = document.getElementById("emptycvv")

            card?.setAttribute('hidden', 'hidden')
            name?.setAttribute('hidden', 'hidden')
            date?.setAttribute('hidden', 'hidden')
            cvv?.setAttribute('hidden', 'hidden')
            emptycard?.setAttribute('hidden', 'hidden')
            emptyname?.setAttribute('hidden', 'hidden')
            emptydate?.setAttribute('hidden', 'hidden')
            emptycvv?.setAttribute('hidden', 'hidden')

            let cardDiv = document.getElementById("cardDetails")
            cardDiv?.setAttribute("style", "display:none")
            let closePopUp = document.getElementById("fadeDiv");
            closePopUp?.setAttribute("style", "display:none");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
        catch (error: any) {


        }
    }

    const clearState = () => {
        try {

            formState = { ...formState, ExpiryDate: "", CardHolderName: "", CardNumber: "", CVV: "" }
            setFormState(formState)
        }
        catch (error: any) {

        }
    }

    const getClientSecret = async (licenseType: any) => {
        try {
            //
            setSelectedLicenseType(licenseType)
            if (clientID != undefined && clientToken != undefined) {
                btnClosePlanpopupClick();
                btnCancelAddLicensePopup();
                let loader = document.getElementById("loader");
                loader?.removeAttribute('hidden');
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/upgradeLicense/createPaymentIntent`,
                    data: {
                        "clientID": clientID,
                        "LicenseType": `${licenseType}`,
                        "UsersCount": Number(userCount),
                        "resourceUtilization": (resourceUtilization > 0) ? true : false,
                        "planType": licensePlan,
                        "IsAdditionlicensePurchased": (ChannelCountForPlan > 0) ? true : false,
                    },
                }
                )
                let data: any = await gridLoad(config);
                //
                loader?.setAttribute('hidden', 'hidden');
                if (data.data.statusCode == 200 && data.data.data.ClientSecret != undefined && data.data.data.ClientSecret != "") {
                    let paymentDetails = data.data.data;
                    billingDetails = {
                        ...billingDetails, ClientName: paymentDetails.ClientName, AddressLine1: paymentDetails.AddressLine1, AddressLine2: paymentDetails.AddressLine2,
                        State: paymentDetails.State, City: paymentDetails.City, ZipCode: paymentDetails.ZipCode, Country: paymentDetails.Country, licensePlan, userCount, resourceUtilization
                    }
                    setBillingDetails(billingDetails);
                    setClientSecret(data.data.data.ClientSecret)
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    setAlertMessage(data.data.data);
                    let popup: any = document.getElementById("alertPopUp");
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    let fade = document.getElementById("fadeDiv");
                    fade?.removeAttribute("hidden");
                    fade?.setAttribute("style", "display:block");
                }
                //
            }
            else {
                localStorage.clear();
            }
        } catch (error) {
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let popup: any = document.getElementById("alertPopUp");
            popup?.removeAttribute("aria-hidden");
            popup?.setAttribute("aria-modal", "true");
            popup?.setAttribute("role", "dialog");
            popup?.setAttribute("class", "modal fade show");
            popup?.setAttribute("style", "display:block");
            let fade = document.getElementById("fadeDiv");
            fade?.removeAttribute("hidden");
            fade?.setAttribute("style", "display:block");
        }
    }

    const appearance: any = {
        theme: 'stripe',
    };
    const options: any = {
        clientSecret,
        appearance,
    };

    const popUpOkClick = (type: string) => {
        try {
            if (isSessionEpired === false) {

                //#region Hide PopUp
                let closePopUp = document.getElementById("fadeDiv");
                closePopUp?.setAttribute("style", "display:none");
                let popup = (type == "alert") ? document.getElementById("alertPopUp") : document.getElementById("sourceSuccessPopUp")
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                if (type != 'alert' && pageType == "ChoosePlan") {
                    window.location.pathname = "/Settings";
                }

                //#endregion
            }
            else {
                window.location.pathname = "/Login";


            }
        } catch (error) {
        }
    }

    const handleGetStarted = async (e: any) => {
        try {
            OnChange(e)

            if (clientID != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${baseURL}/upgradeLicense/storeLicenseCount`,
                    data: {
                        "clientID": clientID,
                        "LicenseType": `${licenseType}`,
                        "paymentIntent": "Custom",
                        "status": "succeeded",
                        "PlanType": licensePlan,
                        "userCount": ""
                    },
                }
                )
                let loader = document.getElementById("loader");
                setLoaderText("Please wait while we set up your Migration Environment...")
                loader?.removeAttribute('hidden');
                let data: any = await gridLoad(config);

                loader?.setAttribute('hidden', 'hidden');
                setLoaderText("")
                if (data.data.statusCode != 200) {
                    if (data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {
                        setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.")
                    }
                    else if (data.data.data == "Invalid authentication token provided") {
                        enableSessionExpiryPopup();
                    }
                    else {
                        setAlertMessage(data.data.data)
                    }
                    let popup = document.getElementById("alertPopUp")
                    //#region Show PopUp

                    loader?.setAttribute('hidden', 'hidden');
                    popup?.removeAttribute("aria-hidden");
                    popup?.setAttribute("aria-modal", "true");
                    popup?.setAttribute("role", "dialog");
                    popup?.setAttribute("class", "modal fade show");
                    popup?.setAttribute("style", "display:block");
                    let fade = document.getElementById("fadeDiv");
                    fade?.removeAttribute("hidden");
                    fade?.setAttribute("style", "display:block");
                    //#endregion
                }

                else {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    window.location.pathname = "/Settings"
                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {

            setLoaderText("")
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }

    const OnChange = async (e: any) => {
        try {

            licenseType = e.target.value
            setLicenseType(licenseType)
        }
        catch (error: any) {

        }
    }

    const tourGuideOkClick = async () => {
        try {
            //#region Hide PopUp
            document.getElementById("tourVideo")?.setAttribute("class", "modal fade");
            document.getElementById("tourVideo")?.setAttribute("style", "display:none");
            document.getElementById("tourVideo")?.setAttribute("aria-hidden", "true");
            document.getElementById("tourVideo")?.removeAttribute("aria-modal");
            document.getElementById("tourVideo")?.removeAttribute("role");
            document.getElementById("tourFadeDiv")?.setAttribute("hidden", "true");
            document.getElementsByTagName('video')[0]?.setAttribute("src", "");
            //#endregion
        } catch (error) {
        }
    }

    const handleContactUs = () => {
        try {
            window.open('https://forms.office.com/r/qsNMxZ7e9d', "_blank")
        }
        catch (error: any) {

            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    const btnUserbasedtabclick = async () => {
        try {
            licensePlan = "UserBased"
            initialpagetype = "UserBased"
            setinitialpagetype(initialpagetype);
            checkPlanExpiry();
            setLicensePlan(licensePlan);
            isTabSwitch = true;
            setisTabSwitch(isTabSwitch);
        }
        catch (error: any) {

        }
    }
    const btnChatandChannelclick = async () => {
        try {
            licensePlan = "ChannelChatBased"
            initialpagetype = "ChannelChatBased"
            setinitialpagetype(initialpagetype);
            checkPlanExpiry();
            setLicensePlan(licensePlan);
            isTabSwitch = true;
            setisTabSwitch(isTabSwitch);
        }
        catch (error: any) {

        }
    }
    //#region Method used to enable user based plan popup
    const btnBuyNowClick = (e: any) => {
        try {
            let ProceedtoPayUpgrade = document.getElementById("ProceedtoPayUpgrade");
            let ProceedtoPay = document.getElementById("ProceedtoPay");
            ProceedtoPay?.removeAttribute("disabled");
            ProceedtoPayUpgrade?.removeAttribute("disabled");

            if (daysDifference < 0 && e.target.innerText !== "Buy Now") {
                enableLicenseUpdatePopup(e);

            }
            else {
                let userCountPlanbased = 0
                let upgradePlanenable = false;
                ///(e.target.value == "Lite" && liteplan !== "") || (e.target.value == "Plus" && plusplan !== "") || (e.target.value == "Elite" && eliteplan !== "") || (e.target.value == "Pro" && proplan !== "")
                if (e.target.innerText === "Upgrade") {
                    upgradePlanenable = true;


                    licensepopupContent = `You have ${daysDifference} days of free access to use resources. You can purchase additional license if needed.`
                    setlicensepopupContent(licensepopupContent);
                }
                else if (e.target.innerText === "Buy Now" && (liteplan !== "" || plusplan !== "" || eliteplan !== "" || proplan !== "")) {
                    upgradePlanenable = true;
                    licensepopupContent = `Specify the number of user licenses you wish to purchase. Please note that switching to a new plan will replace your current resource usage with the new plan’s usage.`
                    setlicensepopupContent(licensepopupContent);
                }

                if (e.target.value == "Lite") {
                    userLimitCount = "100 - 250";
                    setuserLimitCount(userLimitCount);
                    userCountPlanbased = 100;
                    setuserCount(userCountPlanbased);

                }
                else if (e.target.value == "Plus") {
                    userLimitCount = "251 - 500";
                    setuserLimitCount(userLimitCount);
                    userCountPlanbased = 251;
                    setuserCount(userCountPlanbased);

                }
                else if (e.target.value == "Elite") {
                    userLimitCount = "501 - 1000";
                    setuserLimitCount(userLimitCount);
                    userCountPlanbased = 501;
                    setuserCount(userCountPlanbased);
                }
                else if (e.target.value == "Pro") {
                    userLimitCount = "above 1000";
                    setuserLimitCount(userLimitCount);
                    userCountPlanbased = 1001;
                    setuserCount(userCountPlanbased);
                }

                if (e.target.innerText !== "Upgrade" && !upgradePlanenable) {
                    licensepopupContent = `Specify the number of user licenses you wish to purchase. This plan supports ${userLimitCount} users.`
                    setlicensepopupContent(licensepopupContent);
                }

                let usercost: any = [];
                usercost = Enumerable.from(planDetails).where(a => (a.Type === e.target.value)).toArray();
                licenseCost = (userCountPlanbased) * usercost[0].UserCost;
                setlicenseCost(licenseCost);

                setplanType(e.target.value);
                let alertPopUp = document.getElementById("PlanPopUp");
                alertPopUp?.removeAttribute("aria-hidden");
                alertPopUp?.setAttribute("aria-modal", "true");
                alertPopUp?.setAttribute("role", "dialog");
                alertPopUp?.setAttribute("class", "modal fade show");
                alertPopUp?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");
                document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
                let planpopupvalidationlabel = document.getElementById("planpopupvalidationlabel")
                planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            }
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion
    //#region Method used to enable User based Additional license purchase popup
    const enableLicenseUpdatePopup = (e: any) => {
        try {

            userCount = 0;
            setuserCount(userCount);
            licenseCost = 0;
            setlicenseCost(licenseCost);
            let usercost: any = [];
            usercost = Enumerable.from(planDetails).where(a => (a.Type === e.target.value)).toArray();
            resourceUtilization = usercost[0].ResourceUtilizationCostUserbased;
            settotallicenseCost(resourceUtilization);
            setresourceUtilization(resourceUtilization);
            setplanType(e.target.value);



            if (e.target.value == "Lite") {
                userLimitCount = "100 - 250";
                setuserLimitCount(userLimitCount);

            }
            else if (e.target.value == "Plus") {
                userLimitCount = "251 - 500";
                setuserLimitCount(userLimitCount);

            }
            else if (e.target.value == "Elite") {
                userLimitCount = "501 - 1000";
                setuserLimitCount(userLimitCount);

            }
            else if (e.target.value == "Pro") {
                userLimitCount = "above 1000";
                setuserLimitCount(userLimitCount);

            }

            let alertPopUp = document.getElementById("Additionresourcepopup");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");


        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion
    //#region Event used to enable Addition license purchase popup for channel
    const enableLicenseUpdatePopupChannel = (e: any) => {
        try {

            setlicenseCost(0);
            let channelcost: any = [];
            channelcost = Enumerable.from(planDetails).where(a => (a.Type === e)).toArray();
            setplanType(e);
            setLicenseType(e);
            resourceUtilization = channelcost[0].ResourceUtilizationCostChannelChat;
            setresourceUtilization(resourceUtilization);
            licenseCost = channelcost[0].UserCost;
            settotallicenseCost(resourceUtilization);
            let ProceedtoPayUpgrade = document.getElementById("ProceedtoPayUpgrade");
            ProceedtoPayUpgrade?.removeAttribute("disabled");

            let alertPopUp = document.getElementById("AdditionresourcepopupChannel");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");

            totallicenseCost.toLocaleString("en-US");
        }
        catch (error: any) {
            //console.log(error);
            let loader = document.getElementById("loader")
            loader?.setAttribute('hidden', 'hidden');
            setAlertMessage("Something went wrong! Please try again or reach AVAMIGRATRON Support.");
            let alertPopUp = document.getElementById("alertPopUp");
            alertPopUp?.removeAttribute("aria-hidden");
            alertPopUp?.setAttribute("aria-modal", "true");
            alertPopUp?.setAttribute("role", "dialog");
            alertPopUp?.setAttribute("class", "modal fade show");
            alertPopUp?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            document.getElementById("fadeDiv")?.setAttribute("style", "display:block");
        }
    }
    //#endregion
    //#region  method used to handle user count entered in the plan popup
    const userCountUpdate = (e: any) => {
        try {
            let usercost: any = [];
            e.target.value = e.target.value.replace(/[^0-9]+/g, '');
            usercost = Enumerable.from(planDetails).where(a => (a.Type === planType)).toArray();
            let ProceedtoPay = e.target.id == "updateduserCount" ? document.getElementById("ProceedtoPayUpgrade") : document.getElementById("ProceedtoPay");
            let upgradeplanpopupvalidationlabel = document.getElementById("upgradeplanpopupvalidationlabel")
            let planpopupvalidationlabel = document.getElementById("planpopupvalidationlabel")

            if (userLimitCount === "100 - 250") {
                if (e.target.value < 100 || e.target.value > 250) {
                    setuserCount(e.target.value);
                    planpopupvalidationlabel?.removeAttribute('hidden');
                    upgradeplanpopupvalidationlabel?.removeAttribute('hidden');
                    ProceedtoPay?.setAttribute("disabled", "disabled");
                }
                else {
                    setuserCount(e.target.value);
                    licenseCost = (e.target.value) * usercost[0].UserCost;
                    setlicenseCost(licenseCost);
                    settotallicenseCost(licenseCost + resourceUtilization)
                    planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    ProceedtoPay?.removeAttribute("disabled");

                }
            }
            else if (userLimitCount === "251 - 500") {
                if (e.target.value < 251 || e.target.value > 500) {
                    setuserCount(e.target.value);
                    planpopupvalidationlabel?.removeAttribute('hidden');
                    upgradeplanpopupvalidationlabel?.removeAttribute('hidden');
                    ProceedtoPay?.setAttribute("disabled", "disabled");
                }
                else {
                    setuserCount(e.target.value);
                    licenseCost = (e.target.value) * usercost[0].UserCost;
                    setlicenseCost(licenseCost);
                    settotallicenseCost(licenseCost + resourceUtilization)
                    planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    ProceedtoPay?.removeAttribute("disabled");

                }
            }
            else if (userLimitCount === "501 - 1000") {
                if (e.target.value < 501 || e.target.value > 1000) {
                    setuserCount(e.target.value);
                    planpopupvalidationlabel?.removeAttribute('hidden');
                    upgradeplanpopupvalidationlabel?.removeAttribute('hidden');
                    ProceedtoPay?.setAttribute("disabled", "disabled");

                }
                else {
                    setuserCount(e.target.value);
                    licenseCost = (e.target.value) * usercost[0].UserCost;
                    setlicenseCost(licenseCost);
                    settotallicenseCost(licenseCost + resourceUtilization)

                    planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    ProceedtoPay?.removeAttribute("disabled");

                }
            }
            else if (userLimitCount === "above 1000") {
                if (e.target.value <= 1000) {
                    setuserCount(e.target.value);
                    planpopupvalidationlabel?.removeAttribute('hidden');
                    upgradeplanpopupvalidationlabel?.removeAttribute('hidden');
                    ProceedtoPay?.setAttribute("disabled", "disabled");
                }
                else {

                    setuserCount(e.target.value);
                    licenseCost = (e.target.value) * usercost[0].UserCost;
                    setlicenseCost(licenseCost);
                    settotallicenseCost(licenseCost + resourceUtilization)

                    planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
                    ProceedtoPay?.removeAttribute("disabled");

                }
            }
        }
        catch (error) {
            // console.log(error)
        }
    }
    //#endregion
    //#region Event used to close user based plan popup
    const btnClosePlanpopupClick = () => {
        try {
            setlicenseCost(0);
            setuserCount(0);
            let upgradeplanpopupvalidationlabel = document.getElementById("upgradeplanpopupvalidationlabel")
            let planpopupvalidationlabel = document.getElementById("planpopupvalidationlabel")
            upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            let usercountinput: any = document.getElementById("userCount");
            usercountinput.value = "";



            let popup = document.getElementById("PlanPopUp");
            popup?.setAttribute("class", "modal fade");
            popup?.setAttribute("style", "display:none");
            popup?.setAttribute("aria-hidden", "true");
            popup?.removeAttribute("aria-modal");
            popup?.removeAttribute("role");
            document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
        } catch (error) {

        }
    }
    //#endregion
    //#region Event used to close addition license purchase popup
    const btnCancelAddLicensePopup = () => {
        try {
            setlicenseCost(0);
            let upgradeplanpopupvalidationlabel = document.getElementById("upgradeplanpopupvalidationlabel")
            let planpopupvalidationlabel = document.getElementById("planpopupvalidationlabel")
            upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            if (licensePlan !== "ChannelChatBased") {
                let Userlicensecost: any = document.getElementById("Userlicensecost");
                Userlicensecost?.setAttribute("hidden", "hidden");
                settotallicenseCost(0);
                setlicenseCost(0);
                let Updatedusercountinput: any = document.getElementById("updateduserCount");
                Updatedusercountinput.value = "";
                let UserCountfield: any = document.getElementById("UserCountfield");
                UserCountfield?.setAttribute("hidden", "hidden");
                let buyNewUserselect: any = document.getElementById("buyNewUserselect");
                buyNewUserselect.checked = false;


                let popup = document.getElementById("Additionresourcepopup");
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                enablelicensepopup = false;
                setenablelicensepopup(enablelicensepopup);
                settotallicenseCost(0);
                setlicenseCost(0);
                setChannelCountForPlan(0);
                let Updatedusercountinput: any = document.getElementById("AdditionresourcepopupChannel");
                Updatedusercountinput.value = "";
                let UserCountfield: any = document.getElementById("UserChannelfield");
                UserCountfield?.setAttribute("hidden", "hidden");
                let channellicensecost: any = document.getElementById("channellicensecost");
                channellicensecost?.setAttribute("hidden", "hidden");
                let buyNewUserselect: any = document.getElementById("buyNewChannelselect");
                buyNewUserselect.checked = false;


                let popup = document.getElementById("AdditionresourcepopupChannel");
                popup?.setAttribute("class", "modal fade");
                popup?.setAttribute("style", "display:none");
                popup?.setAttribute("aria-hidden", "true");
                popup?.removeAttribute("aria-modal");
                popup?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
        } catch (error) {

        }
    }
    //#endregion
    //#region Method used to handle checkbox for both channel and user based in Payment popup
    const handleUserLicenseCheckBox = (e: any) => {
        try {
            let channelcost: any = [];
            let UserCount: any = 0;

            channelcost = Enumerable.from(planDetails).where(a => (a.Type === planType)).toArray();
            let upgradeplanpopupvalidationlabel = document.getElementById("upgradeplanpopupvalidationlabel")
            let planpopupvalidationlabel = document.getElementById("planpopupvalidationlabel")
            planpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            upgradeplanpopupvalidationlabel?.setAttribute('hidden', 'hidden');
            let ProceedtoPayUpgrade = document.getElementById("ProceedtoPayUpgrade");
            let ProceedtoPayChannelUpgrade = document.getElementById("ProceedtoPayChannelUpgrade");
            ProceedtoPayChannelUpgrade?.removeAttribute("disabled");
            ProceedtoPayUpgrade?.removeAttribute("disabled");
            if (licensePlan !== "ChannelChatBased") {
                let Userlicensecost: any = document.getElementById("Userlicensecost");
                let UserCountfield: any = document.getElementById("UserCountfield");

                if (e.target.checked) {
                    UserCount = (planType == "Lite") ? 100 : (planType == "Plus") ? 251 : (planType == "Elite") ? 501 : (planType == "Pro") ? "1001" : 0;

                    setuserCount(UserCount);
                    licenseCost = (UserCount) * channelcost[0].UserCost;
                    setlicenseCost(licenseCost);
                    settotallicenseCost(licenseCost + resourceUtilization)
                    UserCountfield?.removeAttribute("hidden");
                    Userlicensecost?.removeAttribute("hidden");
                }
                else {
                    setuserCount(0)
                    UserCountfield?.setAttribute("hidden", "hidden");
                    Userlicensecost?.setAttribute("hidden", "hidden");
                    setlicenseCost(0);
                    settotallicenseCost(resourceUtilization)
                }
            }
            else {
                let channellicensecost: any = document.getElementById("channellicensecost");
                let UserCountfield: any = document.getElementById("UserChannelfield");
                if (e.target.checked) {
                    setlicenseCost(licenseCost);
                    licenseCost = channelcost[0].ChannelCount;
                    resourceUtilization = channelcost[0].ResourceUtilizationCostChannelChat;
                    settotallicenseCost(channelcost[0].TotalCost + resourceUtilization)
                    setlicenseCost(channelcost[0].TotalCost);
                    setChannelCountForPlan(licenseCost)
                    UserCountfield?.removeAttribute("hidden");
                    channellicensecost?.removeAttribute("hidden");
                }
                else {
                    setChannelCountForPlan(0)
                    setlicenseCost(0);
                    settotallicenseCost(resourceUtilization)
                    let UserCountfield: any = document.getElementById("UserChannelfield");
                    UserCountfield?.setAttribute("hidden", "hidden");
                    channellicensecost?.setAttribute("hidden", "hidden");
                }
            }


        } catch (error) {

        }
    }
    //#endregion
    return (
        <div>
            <div className="container-fluid">
                <div className="row justify-content-center card-container plan-bg ">
                    <div className="col-md-12 col-sm-12 col-lg-10 my-4">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 text-center">
                                {pageType == "ChoosePlan" ?
                                    <h4 className="font-bold font-26">Slack to Teams Migration Plan</h4>
                                    :
                                    <h4 className="font-bold font-26">Upgrade Plan</h4>
                                }
                                <p className="font-regular font-14">AVAMIGRATRON helps you assess and migrate all your data from Slack to MS Teams with ease.</p>
                                {/* <!-- <p className="">AVMIGRATRON helps you to assess and to migrate all your data from Slack to MS Teams with ease.</p> --> */}
                            </div>

                            {((pageType === "ChoosePlan") && planLandingtype == "UserBased-Chat&Channel") ?

                                <div className="d-flex justify-content-center my-4">
                                    <ul className="nav nav-pills" role="tablist">
                                        <li className="nav-item">
                                            <span className={(initialpagetype == "UserBased" || (objClient.isUserBasedLicense == true && defaultPageType == "ChoosePlan")) ? activeClass : inactiveClass} onClick={btnUserbasedtabclick} id="userBased">User-based</span></li>
                                        <li className="nav-item">
                                            <span className={(initialpagetype == "ChannelChatBased" || (objClient.isUserBasedLicense == null && defaultPageType == "ChoosePlan") || (objClient.isUserBasedLicense == false && defaultPageType == "ChoosePlan")) ? activeClass : inactiveClass} onClick={btnChatandChannelclick} id="chatChannel">Chat & Channel</span></li>
                                    </ul>
                                </div>

                                : (objClient.isUserBasedLicense || planLandingtype == "UserBased") ?

                                    <div className="d-flex justify-content-center my-4">
                                        <ul className="nav nav-pills" role="tablist">
                                            <li className="nav-item">
                                                <span className={objClient.isUserBasedLicense ? activeClass : inactiveClass} onClick={btnUserbasedtabclick} id="userBased">User-based</span></li>
                                        </ul>
                                    </div>

                                    :
                                    <div className="d-flex justify-content-center my-4">
                                        <ul className="nav nav-pills" role="tablist">
                                            <li className="nav-item">
                                                <span className={!objClient.isUserBasedLicense ? activeClass : inactiveClass} onClick={btnChatandChannelclick} id="chatChannel">Chat & Channel</span></li>
                                        </ul>
                                    </div>
                            }



                            <div className="col-md-12 col-lg-12 col-sm-12 col-12 mb-3">
                                <div className=" contact-badge-bg justify-content-between d-flex align-items-center px-4 py-2 rounded-3 flex-md-col">
                                    <p className="mb-sm-3 mb-md-0 font-14 font-semibold">If you have any further queries or if you are interested in our
                                        premium plans, please feel free to contact us.</p>
                                    <button className="btn custom-outline-radius-btn text-nowrap font-14 font-bold anchorAsPointer" onClick={handleContactUs}>Contact Us</button>
                                </div>
                            </div>



                            {((initialpagetype == "UserBased") || (objClient.isUserBasedLicense == true && defaultPageType == "ChoosePlan")) ?

                                // {/* <!-- plans grid starts here --> */}
                                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                    <div className="table-responsive table-border bg-white ">
                                        <table className="table mt-4 bg-white plan-grid overflow">
                                            <tbody className="font-14 text-center">
                                                <tr>
                                                    <td className=""></td>
                                                    <td className='font-bold font-20'>Free</td>
                                                    <td className='font-bold font-20'>Lite</td>
                                                    <td className='font-bold font-20'>Plus</td>
                                                    <td className='font-bold font-20'>Elite</td>
                                                    <td className='font-bold font-20'>Pro</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">User count</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>100 - 250</td>
                                                    <td className='font-regular'>251 - 500</td>
                                                    <td className='font-regular'>501 - 1000</td>
                                                    <td className='font-regular'><span> Above 1,000</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Channel Count</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'><span>Unlimited</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Conversation Count</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'>Unlimited</td>
                                                    <td className='font-regular'><span>Unlimited</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Total Cost</td>
                                                    <td className="font-semibold font-18 theme-color">$0</td>
                                                    <td className="font-semibold font-18 theme-color">$45 <span className='font-12 font-regular text-dark'>/User</span></td>
                                                    <td className="font-semibold font-18 theme-color">$40 <span className='font-12 font-regular text-dark'>/User</span></td>
                                                    <td className="font-semibold font-18 theme-color">$35 <span className='font-12 font-regular text-dark'>/User</span></td>
                                                    <td className="font-semibold font-18 theme-color">$25 <span className='font-12 font-regular text-dark'>/User</span></td>
                                                </tr>
                                                <tr>
                                                    {/* <td className="border-0"> </td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Get Started</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Contact Us</a></td> */}
                                                    <td className="border-0"> </td>
                                                    {pageType == "ChoosePlan" ?
                                                        <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Free" onClick={(e) => handleGetStarted(e)}>Get Started</button></td>
                                                        :
                                                        <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold " disabled value="Free" >Get Started</button></td>
                                                    }
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold " value="Lite" onClick={(e) => btnBuyNowClick(e)}>{liteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Plus" onClick={(e) => btnBuyNowClick(e)}>{plusplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Elite" onClick={(e) => btnBuyNowClick(e)}>{eliteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Pro" onClick={(e) => btnBuyNowClick(e)}>{proplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Parallel Migration</td>
                                                    <td className="font-regular font-14 text-dark">NA</td>
                                                    <td className="font-regular font-14 text-dark">2</td>
                                                    <td className="font-regular font-14 text-dark">3</td>
                                                    <td className="font-regular font-14 text-dark">4</td>
                                                    <td className="font-regular font-14 text-dark">5</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">No of days free for <br /> resource utilization</td>
                                                    <td className="font-semibold font-14 text-dark">7 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">45 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">60 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">90 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">180 <span className='font-12 font-regular text-dark'>days</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Additional cost for <br /> resource utilization
                                                        <span>
                                                            <img src="/Images/info-icon.svg" className="slack-icon ps-2" alt="info-icon" data-tip="The additional cost is for utilizing <br/> AVAMIGRATRON's cloud resources for <br/>the purpose of migration." data-for='toolTipSlack' data-place='right' />
                                                            <ReactTooltip id="toolTipSlack" class='mySepecialClass resourceTooltip' multiline={true} />

                                                        </span>    </td>
                                                    <td className="font-regular font-14 text-dark">NA</td>
                                                    <td className="font-semibold font-14 text-dark">$100 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$150 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$250 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$500 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                </tr>
                                            </tbody>
                                            <div>

                                            </div>
                                            {/* <!-- plans grid ends here -->

                                        <!-- features grid starts here --> */}
                                            <tbody className="font-14 text-center features">
                                                <tr>
                                                    <td colSpan={6} className="text-start bg-white font-bold font-20 border-0">
                                                        <span className="d-block">Features</span>
                                                        <span className="d-block font-12 font-regular d-flex justify-content-between w-100">By selecting for a free plan, you can only migrate one conversation & one channel. To unlock unlimited conversation & channel migrations, please upgrade your plan.
                                                            <span className="font-semibold font-12">
                                                                <span><span className="rounded-status status-theme mx-2"></span>Unlimited</span>
                                                                <span className='mx-3'><span className="rounded-status status-grey mx-2"></span>Limited</span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-14 text-start ">High-Level Inventory</td>
                                                    <td><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Detailed Channel Inventory</td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Channels Migration</td>
                                                    <td ><span> <img src='Images/limited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>

                                                <tr>
                                                    <td className="text-start ">Migrated Channels Member Migration</td>
                                                    <td ><span> <img src='Images/limited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Detailed Conversation Inventory</td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Conversation Migration</td>
                                                    <td ><span>  <img src="Images/limited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                            </tbody>
                                            {/* <!-- features grid ends here -->  */}
                                        </table>
                                    </div>
                                </div>

                                :


                                <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                                    <div className="table-responsive table-border bg-white ">
                                        <table className="table mt-4 bg-white plan-grid overflow">
                                            <tbody className="font-14 text-center">
                                                <tr>
                                                    <td className=""></td>
                                                    <td className='font-bold font-20'>Free</td>
                                                    <td className='font-bold font-20'>Lite</td>
                                                    <td className='font-bold font-20'>Plus</td>
                                                    <td className='font-bold font-20'>Elite</td>
                                                    <td className='font-bold font-20'>Pro</td>
                                                </tr>

                                                <tr>
                                                    <td className="text-start ">Channel Count</td>
                                                    <td className='font-regular'>2</td>
                                                    <td className='font-regular'>10</td>
                                                    <td className='font-regular'>20</td>
                                                    <td className='font-regular'>50</td>
                                                    <td className='font-regular'><span>100</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Conversation Count</td>
                                                    <td className='font-regular'>2</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'><span>NA</span></td>
                                                </tr>
                                
                                                <tr>
                                                    <td className="text-start ">Conversation as HTML Count</td>
                                                    <td className='font-regular'>1</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'>NA</td>
                                                    <td className='font-regular'><span>NA</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Total Cost</td>
                                                    <td className="font-semibold font-18 theme-color">$0</td>
                                                    <td className="font-semibold font-18 theme-color">$590 <span className='font-12 font-regular text-dark'></span></td>
                                                    <td className="font-semibold font-18 theme-color">$980 <span className='font-12 font-regular text-dark'></span></td>
                                                    <td className="font-semibold font-18 theme-color">$1,950 <span className='font-12 font-regular text-dark'></span></td>
                                                    <td className="font-semibold font-18 theme-color">$3,500 <span className='font-12 font-regular text-dark'></span></td>
                                                </tr>
                                                <tr>
                                                    {/* <td className="border-0"> </td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Get Started</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Buy Now</a></td>
                                                    <td className="text-center  border-0"><a href="#" className="btn-plan">Contact Us</a></td> */}
                                                    <td className="border-0"> </td>
                                                    {pageType == "ChoosePlan" ?
                                                        <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Free" onClick={(e) => handleGetStarted(e)}>Get Started</button></td>
                                                        :
                                                        <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Free" disabled >Get Started</button></td>
                                                    }
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold " value="Lite" onClick={popUp}>{liteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Plus" onClick={popUp}>{plusplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Elite" onClick={popUp}>{eliteplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                    <td className="text-center  border-0"><button type="button" className="common-btn-class btn-primary-theme btn btn-plan rounded-pill font-14 font-semibold" value="Pro" onClick={popUp}>{proplan == "" ? "Buy Now" : "Upgrade"}</button></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Parallel Migration</td>
                                                    <td className="font-regular font-14 text-dark">NA</td>
                                                    <td className="font-regular font-14 text-dark">2</td>
                                                    <td className="font-regular font-14 text-dark">3</td>
                                                    <td className="font-regular font-14 text-dark">4</td>
                                                    <td className="font-regular font-14 text-dark">5</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">No of days free for <br /> resource utilization</td>
                                                    <td className="font-semibold font-14 text-dark">7 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">30 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">45 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">60 <span className='font-12 font-regular text-dark'>days</span></td>
                                                    <td className="font-semibold font-14 text-dark">75 <span className='font-12 font-regular text-dark'>days</span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Additional cost for <br /> resource utilization
                                                        <span>
                                                            <img src="/Images/info-icon.svg" className="slack-icon ps-2" alt="info-icon" data-tip="The additional cost is for utilizing <br/>AVAMIGRATRON's cloud resources for <br/>the purpose of migration." data-for='toolTipSlack' data-place='right' />
                                                            <ReactTooltip id="toolTipSlack" class='mySepecialClass resourceTooltip' multiline={true} />

                                                        </span>    </td>
                                                    <td className="font-regular font-14 text-dark">NA</td>
                                                    <td className="font-semibold font-14 text-dark">$100 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$150 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$200 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                    <td className="font-semibold font-14 text-dark">$300 <span className='font-12 font-regular text-dark'>/month</span></td>
                                                </tr>
                                            </tbody>
                                            <div>

                                            </div>
                                            {/* <!-- plans grid ends here -->

                                        <!-- features grid starts here --> */}
                                            <tbody className="font-14 text-center features">
                                                <tr>
                                                    <span className="text-start bg-white font-bold font-20 border-0 d-block mt-2">Features</span>

                                                </tr>
                                                <tr>
                                                    <td className="font-14 text-start ">High-Level Inventory</td>
                                                    <td><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Detailed Channel Inventory</td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Channels Migration</td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>

                                                <tr>
                                                    <td className="text-start ">Migrated Channels Member Migration</td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td ><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                    <td className="  bg-white"><span> <img src='Images/unlimited-img.svg' alt='plan-check' /></span></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Detailed Conversation Inventory</td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>


                                                </tr>
                                                <tr>
                                                    <td className="text-start ">Conversation Migration</td>
                                                    <td ><span>  <img src="Images/unlimited-img.svg" alt='plan-check' /> </span></td>

                                                </tr>
                                            </tbody>
                                            {/* <!-- features grid ends here -->  */}
                                        </table>
                                    </div>
                                </div>

                            }



                        </div>
                    </div>
                </div>
                {/* <!--Popup--> */}
                {clientSecret != undefined && clientSecret != null && clientSecret != "" ?
                    <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                        <div className="App StripeElementUICustom">
                            <div className="card-modal-content modal-dialog-scrollable">
                                <div className="modal-header border-0">
                                    <h5 className="modal-title font-22 font-bold payment-left-margin">Payment details</h5>
                                    <button type="button" id='closebutton' className="common-btn-class btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => close()}></button>
                                </div>
                                <p className='mandatory-field mb-2'>All the fields are Mandatory</p>
                                {clientSecret && (
                                    <Elements options={options} stripe={stripePromise}>
                                        <CheckoutForm license={selectedLicenseType} pageType={pageType} billingDetails={billingDetails} />
                                    </Elements>
                                )}
                                {/* <div className="modal-title font-14 p-3 font-bold">
                                <a href='' className='underline-remove hyperlink-color anchorAsPointer' id='termsId' >Terms &amp; Conditions</a>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    :
                    <div> </div>}
                {/* <div className="modal-backdrop"></div> */}
                <div id="fadeDiv" className="card-modal-backdrop fade show" hidden></div>
                {/* <!--Alert Pop-up--> */}
                <div className="modal fade" id="alertPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src='/Images/Alert.svg' className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Alert!</h5>
                                    <p className="success-text w-100 text-center mb-4">{alertMessage}</p>

                                    <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('alert')}>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <!--Alert pop-up--> */}
                {/* <!--Success Pop-up--> */}
                <div className="modal fade" id="sourceSuccessPopUp" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content content-margin">
                            <div className="modal-body">
                                <img src='/Images/success.svg' className="success-icon" alt="success" />
                                <div className="text-center success-content w-100 mt-5">
                                    <h5 className="font-semibold font-18">Success!</h5>
                                    <p className="success-text w-100 text-center mb-4"> License Purchased successfully!</p>
                                    <button type="button" className="common-btn-class btn-primary-theme btn btn-ok  text-center" data-bs-dismiss="modal" onClick={(e) => popUpOkClick('success')}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--success pop-up--> */}
                {/* <!--loader section--> */}
                <div className="overlay" id='loader' hidden={true}>
                    <div className="Loader-Content-position ">
                        <div className="spinner-border Loader Loader-Img-position text-primary align-center" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        <p className="aligncenter">{loaderText}</p>
                    </div>
                </div>
                {/* <!--loader section--> */}
            </div >
            {/* TourGuide starts */}
            <div id="tourVideo" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true" >
                <div className="modal-dialog tourGuide modal-lg">
                    <div className="modal-content demo-vedio">
                        <div className="modal-body pt-1">
                            <div className="col-md-12 mb-4 mt-4">
                                <div className="row">
                                    <div className="w-100 float-start text-center mb-3">
                                        <label className="form-label popup-label mb-3 w-100">
                                            <span className="name-style font-24 font-semibold">Welcome,</span><span className="name-style2 font-24 font-semibold"> {clientName}</span></label>
                                        <label className="form-label popup-label2 font-16 font-regular w-100">To get a feel of the
                                            place, take our guided tour, where we'll get you set up and <br />
                                            show you how to migrate your Slack Workspace into MS Teams</label>
                                    </div>
                                    <div className="w-100 float-start text-center">
                                        <video width="100%" height="315" className='videoTagMNC' controls controlsList="nodownload" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mb-4">
                            <button type="button" className="btn-cancel-border btn-scondary-custom common-btn-class btn btn-second me-4 font-15 font-bold float-start ps-4 pe-4" data-bs-dismiss="modal" onClick={tourGuideOkClick}>Skip</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal starts */}
            <div className="modal fade" id="PlanPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font-20 font-bold">{planType} Plan</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={btnClosePlanpopupClick} aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0">
                            <p className="font-regular font-16 grey-v1 mb-4">{licensepopupContent}</p>

                            <div className="row">
                                <div className="mb-3 col-md-4">
                                    <label className="form-label font-semibold font-16">User Count</label>
                                    <input className="form-control font-18" value={userCount} maxLength={7} onChange={(e) => userCountUpdate(e)} id="userCount" />
                                    <label className="required font-12 font-regular" hidden id='planpopupvalidationlabel'> Please enter valid user count</label>
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label  font-semibold font-16">License Cost</label>
                                    <span className="d-block font-18 font-regular"> <span className="pe-2 font-22 font-bold">$</span>{licenseCost.toLocaleString("en-US")}</span>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end">
                                <button type="button" className="btn btn-second me-2 font-16 font-semibold" onClick={btnClosePlanpopupClick} >Cancel</button>
                                <button type="button" className="btn btn-prime font-16 font-semibold" id='ProceedtoPay' onClick={popUp}>Proceed to Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal ends */}
            {/* TourGuide ends */}
            {/* Resource purchase plan popup start */}
            <div className="modal fade" id="Additionresourcepopup" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font-20 font-bold">{planType} Plan</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={btnCancelAddLicensePopup}></button>
                        </div>
                        <div className="modal-body mt-0">
                            <p className="font-regular font-16 grey-v1">Your free resource utilization has been expired, upgrade now to
                                continue your resource utilization and you can also purchase additional license.</p>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" value="" id="buyNewUserselect" onClick={(e) => handleUserLicenseCheckBox(e)} />
                                <label className="form-check-label font-semibold font-14">
                                    Buy new user license
                                </label>
                            </div>
                            <div className="mb-3" id='UserCountfield' hidden>
                                <label className="form-label  font-semibold font-16">User Count</label>
                                <input type="text" className="form-control w-25" value={userCount} maxLength={7} onChange={(e) => userCountUpdate(e)} id="updateduserCount" />
                                <label className="required font-12 font-regular" id='upgradeplanpopupvalidationlabel' hidden> Please enter valid user count</label>
                            </div>
                            <h5 className="modal-title font-18 font-bold mb-2">Price Summary</h5>
                            <div className="table-responsive shadow-sm rounded-3 px-3 price-summary mb-5">
                                <table className="w-100 price-summary-grid ">
                                    <tbody>
                                        <tr>
                                            <span>
                                                <td className="text-start font-semibold font-14">Resource Utilization Cost</td>

                                                <img src="/Images/info-icon.svg" className="slack-icon ps-2 mb-2" alt="info-icon" data-tip="The additional cost is for utilizing <br/> AVAMIGRATRON's cloud resources for <br/>the purpose of migration." data-for='toolTipSlack' data-place='right' />
                                                <ReactTooltip id="toolTipSlack" class='mySepecialClass resourceTooltip' multiline={true} />

                                            </span>
                                            <td className="text-end font-bold font-14">{resourceUtilization.toLocaleString("en-US")}</td>
                                        </tr>
                                        <tr id='Userlicensecost' hidden>
                                            <td className="text-start font-semibold font-14">User License Cost</td>
                                            <td className="text-end font-bold font-14">${licenseCost.toLocaleString("en-US")}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-start font-18 font-bold">Total Cost</td>
                                            <td className="text-end font-18 font-bold theme-color">${totallicenseCost.toLocaleString("en-US")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                                <button type="button" className="btn btn-second me-2 font-16 font-semibold" onClick={btnCancelAddLicensePopup}>Cancel</button>
                                <button type="button" className="btn btn-prime font-16 font-semibold" id='ProceedtoPayUpgrade' onClick={popUp}>Proceed to Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Resource purchase plan popup ends */}

            {/* Resource purchase plan popup start */}
            <div className="modal fade" id="AdditionresourcepopupChannel" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h5 className="modal-title font-20 font-bold">{planType} Plan</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={btnCancelAddLicensePopup}></button>
                        </div>
                        <div className="modal-body mt-0">
                            <p className="font-regular font-16 grey-v1">Your free resource utilization has been expired, upgrade now to
                                continue your resource utilization and you can also purchase additional license.</p>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" value="" id="buyNewChannelselect" onClick={(e) => handleUserLicenseCheckBox(e)} />
                                <label className="form-check-label font-semibold font-14">
                                    Buy new channel license
                                </label>
                            </div>
                            <div className="mb-3" id='UserChannelfield' hidden>
                                <label className="form-label  font-semibold font-16">Channel Count</label>
                                <label className="form-control w-25" >{ChannelCountForPlan}</label>
                            </div>
                            <h5 className="modal-title font-18 font-bold mb-2">Price Summary</h5>
                            <div className="table-responsive shadow-sm rounded-3 px-3 price-summary mb-5">
                                <table className="w-100 price-summary-grid ">
                                    <tbody>
                                        <tr>

                                            <span>
                                                <td className="text-start font-semibold font-14">Resource Utilization Cost</td>

                                                <img src="/Images/info-icon.svg" className="slack-icon ps-2 mb-2" alt="info-icon" data-tip="The additional cost is for utilizing <br/> AVAMIGRATRON's cloud resources for <br/>the purpose of migration." data-for='toolTipSlack' data-place='right' />
                                                <ReactTooltip id="toolTipSlack" class='mySepecialClass resourceTooltip' multiline={true} />

                                            </span>
                                            <td className="text-end font-bold font-14">{resourceUtilization}</td>
                                        </tr>
                                        <tr id='channellicensecost' hidden>
                                            <td className="text-start font-semibold font-14">Channel License Cost</td>
                                            <td className="text-end font-bold font-14">${licenseCost.toLocaleString("en-US")}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-start font-18 font-bold">Total Cost</td>
                                            <td className="text-end font-18 font-bold theme-color">${totallicenseCost.toLocaleString("en-US")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                                <button type="button" className="btn btn-second me-2 font-16 font-semibold" onClick={btnCancelAddLicensePopup}>Cancel</button>
                                <button type="button" className="btn btn-prime font-16 font-semibold" id='ProceedtoPayChannelUpgrade' onClick={popUp}>Proceed to Pay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Resource purchase plan popup ends */}
            <div id="tourFadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>

    );

}