import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridLoad } from '../../Services/ChannelMigrationServices';
import { CompleteTeamChannel } from '../../Services/ChannelMigrationServices';
import WizardNavigation from './Wizard'
import BackNavigation from './BackNavigation'
import '../../styles/channel_migration_landing.css'


import $ from 'jquery'
import constants from '../../Common/Constants';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import ReactTooltip from "react-tooltip";

import { AnyARecord } from 'dns';

export function TeamChannelCompletion(): any {

    let GridLoadarray: any[] = [];
    let selectedCheckbox: any = []

    // Variables Declared for SelectAll
    const [selectedChannelsArray, setSelectedChannelsArray] = useState<any>([]);
    const [selectAllCount, setSelectAllCount] = useState(0);
    const [ disabledCount, setDisabledCount] = useState(0);
    const [isFullSelect, setIsFullSelect] = useState(false);
    const [totalItemsCountFeature, setTotalItemsCountFeature] = useState(0);
    const [operation, setOperation ] = useState("")
    const [ validTotalCount, setValidTotalCount] = useState(0);

    let payLoad: any = []
    let [gridData, setGridData] = useState(GridLoadarray);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [columnOrder, setColumnOrder] = useState("asc");
    const [columnName, setColumnName] = useState("TeamName");
    const [sorthide, setsorthide] = useState("");
    const [loaderHide, setLoaderHide] = useState(false);
    const [indexPerPage, setIndexPerPage] = useState(1);
    const [tmpsearchValue, settmpSearchValue] = useState("");
    let [status, setStatus] = useState("")
    const [countPerPage, setCoutPerPage] = useState(10);
    let [clientId, setClientId] = useState(0);
    let history = useHistory();
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;



    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientIDValdidate: any;
    let clientToken: any;
    let adminToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientIDValdidate = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }
    }


    useEffect(() => {
        clientId = clientIDValdidate;
        setClientId(clientId)
        resetSessionTimer();
        if (selectedChannelsArray.length == 0 || selectedChannelsArray == undefined) {
            let x = document.getElementById("Complete-Btn")

            x?.setAttribute("disabled", "disabled");

        }
        let x = document.getElementById("loaderWithoutContent")
        x?.removeAttribute("hidden")
        setLoaderHide(false)
        loadGridPage(operation);
        let removeHeight: any = document.getElementById("MainContainerMFE");
        removeHeight.classList.remove("container-bg-withoutFooter")
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, [columnName, columnOrder, indexPerPage])


    useEffect(() => {
         // To Handle the Select All through Individual Checkboxes
        let selectAllelement:any = document.getElementById("selectAllCheckBox")
        selectAllelement.disabled = false
        let allGridDataDisabled = false
    
          if (gridData.length != 0 && gridData[0] != "No Records Found") {
            // To get the gridData Count without the Disbled Checkbox
            let tempArr:any =[]
            let disabledTempCount = 0
                gridData.forEach((obj)=>{
                    let element:any = document.getElementById(JSON.stringify(obj))
                    if(element != "" && element != null && element != undefined  && element.disabled == true){
                        tempArr.push(obj);
                    }
                })
                disabledTempCount = gridData.length - tempArr.length
                setDisabledCount(disabledTempCount)

            // Check Whether all the CheckBoxes in the Grid are Disabled
                const allObjectsPresent = gridData.every((obj1: { TargetTeamID: any; }) =>
                    tempArr.some((obj2: { TargetTeamID: any; }) => obj1.TargetTeamID === obj2.TargetTeamID)
                )
                if(allObjectsPresent){
                    allGridDataDisabled = true
                }
                else{
                    allGridDataDisabled = false
                }

            if (selectAllCount == 0) {
                 // Possible Conditions when the SelectAll is UnChecked 
              if (selectedChannelsArray.length ==  disabledTempCount || selectedChannelsArray.length != disabledTempCount) {
              
                let isAllSelected = false
                let tempCheckArr:any = []
                
                gridData.forEach((obj)=>{
                    if(selectedChannelsArray.some((obj1:any)=>{
                        if(obj1.TargetTeamID == obj.TargetTeamID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length ==  disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    setSelectAllCount(1);
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
              }
              if(validTotalCount != 0  && selectedChannelsArray.length == validTotalCount){
                setSelectAllCount(2);
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
              }
            }
            if (selectAllCount == 1) {
                 // Possible Conditions when the SelectAll is Partial Select
                let isAllSelected = false
                let tempCheckArr:any = []
                
                gridData.forEach((obj)=>{
                    if(selectedChannelsArray.some((obj1:any)=>{
                        if(obj1.TargetTeamID == obj.TargetTeamID){
                            return true
                        }
                    })){
                        tempCheckArr.push(obj)
                    }

                })
                if(tempCheckArr.length ==  disabledTempCount){
                    isAllSelected = true
                }
                if(isAllSelected == true){
                    if(isFullSelect != true){
                        setSelectAllCount(1);
                        selectAllelement.checked ="true";
                        selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                    }
                    else{
                        setSelectAllCount(2);
                        setIsFullSelect(true)
                        selectAllelement.checked ="true";
                        selectAllelement.className = "form-check-input me-2 check-alg-2"
                    }
                }
                else{
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
            }
            else{
                if (selectAllCount == 1 && selectedChannelsArray.length != disabledTempCount) {
                    setSelectAllCount(0);
                    setIsFullSelect(false)
                    selectAllelement.checked = ""
                }
                if (selectAllCount == 1 && selectedChannelsArray.length == validTotalCount) {
                    setSelectAllCount(2);
                    setIsFullSelect(true)
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
            }

            if (selectAllCount == 2) {
                 // Possible Conditions when the SelectAll is Full Select
                if(operation != "search"){
                    setSelectAllCount(2)
                    setIsFullSelect(true)
                    selectAllelement.checked ="true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
                else{
                    if (selectedChannelsArray.length != validTotalCount) {
                        setSelectAllCount(0);
                        setIsFullSelect(false)
                        selectAllelement.checked = ""
                      }
                }
            }
            // to handle the pageload of grid for Full Select
            if (validTotalCount == disabledTempCount && gridData.length !=0 && selectedChannelsArray.length == disabledTempCount) {
                setIsFullSelect(true);
                setSelectAllCount(2)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
            }

            handleCheckedItems()
        }
        if(gridData[0] == "No Records Found"|| allGridDataDisabled == true){
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
            selectAllelement.disabled = true
          }
      }, [gridData]);
    
      useEffect(() => {
    // To Handle the Select All through Individual Checkboxes
      let selectAllelement:any = document.getElementById("selectAllCheckBox")
      let allDataBinded = false
      // Condition to Check whether all the Data From the Backend is Binded in the Grid
      if(gridData.length == totalItemsCount){
        allDataBinded = true
      }
      if (gridData.length != 0) {
        if (selectAllCount == 0) {
             // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Uncheck
          if (selectedChannelsArray.length == disabledCount && isFullSelect == false && allDataBinded == false) {
            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj)=>{
                if(selectedChannelsArray.some((obj1:any)=>{
                    if(obj1.TargetTeamID == obj.TargetTeamID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }
            })
            if(tempCheckArr.length ==  disabledCount){
                isAllSelected = true
            }
            if(isAllSelected){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }
          }
          if(selectedChannelsArray.length == disabledCount && isFullSelect == true || selectedChannelsArray.length == disabledCount && allDataBinded == true){
            setSelectAllCount(2);
            selectAllelement.checked ="true";
            selectAllelement.className = "form-check-input me-2 check-alg-2"
          }
          if(selectedChannelsArray.length != disabledCount && isFullSelect == true){
            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj)=>{
                if(selectedChannelsArray.some((obj1:any)=>{
                    if(obj1.TargetTeamID == obj.TargetTeamID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }
            })
            if(tempCheckArr.length ==  disabledCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
                setSelectAllCount(2);
                setIsFullSelect(true)
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2"
            }
          }
          if(selectedChannelsArray.length != disabledCount && isFullSelect == false){
            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj)=>{
                if(selectedChannelsArray.some((obj1:any)=>{
                    if(obj1.TargetTeamID == obj.TargetTeamID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length ==  disabledCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }
          }
        
        }
        if (selectAllCount == 1) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Partial Select
          if (selectedChannelsArray.length != disabledCount) {
            let isAllSelected = false
            let tempCheckArr:any = []
            
            gridData.forEach((obj)=>{
                if(selectedChannelsArray.some((obj1:any)=>{
                    if(obj1.TargetTeamID == obj.TargetTeamID){
                        return true
                    }
                })){
                    tempCheckArr.push(obj)
                }

            })
            if(tempCheckArr.length ==  disabledCount){
                isAllSelected = true
            }
            if(isAllSelected == true){
                setSelectAllCount(1);
                selectAllelement.checked ="true";
                selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
            }
            else{
                setSelectAllCount(0);
                setIsFullSelect(false)
                selectAllelement.checked = ""
            }
          }
        }
        if (selectAllCount == 2) {
            // Possible Conditions to handle the Individual CheckBox Select When SelectAll is in Full Select
          if (selectedChannelsArray.length != validTotalCount) {
            setSelectAllCount(0);
            setIsFullSelect(false)
            selectAllelement.checked = ""
          }
        }
      }

        handleCheckedItems();
    }, [selectedChannelsArray]);



    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            status = "Your session has expired. Please log in again to continue using the application."
            setStatus(status)

            //#region Show PopUp
            document.getElementById("success")?.removeAttribute("aria-hidden");
            document.getElementById("success")?.setAttribute("aria-modal", "true");
            document.getElementById("success")?.setAttribute("role", "dialog");
            document.getElementById("success")?.setAttribute("class", "modal fade show");
            document.getElementById("success")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientIDValdidate != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await GridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(status)

            document.getElementById("success")?.removeAttribute("aria-hidden");
            document.getElementById("success")?.setAttribute("aria-modal", "true");
            document.getElementById("success")?.setAttribute("role", "dialog");
            document.getElementById("success")?.setAttribute("class", "modal fade show");
            document.getElementById("success")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            status = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(status)

            document.getElementById("success")?.removeAttribute("aria-hidden");
            document.getElementById("success")?.setAttribute("aria-modal", "true");
            document.getElementById("success")?.setAttribute("role", "dialog");
            document.getElementById("success")?.setAttribute("class", "modal fade show");
            document.getElementById("success")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden");
        }
    }
    //#endregion


    const loadGridPage = async (operation:any) => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: constants.URL.getMigratedTeamChannel,
                    data: {


                        "clientID": clientId,
                        "type": "Team",
                        "sortType": `${columnOrder}`,
                        "sortColumn": `${columnName}`,
                        "searchText": `${searchValue}`,
                        "teamID": "",
                        "pageIndex": indexPerPage


                    },
                }
                )

                let x = document.getElementById("loaderWithoutContent")
                x?.removeAttribute("hidden")
                // $('input[name="check-box"]').prop('checked', false);
                var data: any = await GridLoad(config);
                if (data.data.statusCode == 200) {
                    x?.setAttribute("hidden", "true")

                    let ototalItemsCount = data.data.data == "No Records Found" ? 0 : data.data.data[1][0].CompletionTeamChannelCount;
                    // setTotalItemsCount(ototalItemsCount);
                    if(operation == "search"){
                        setTotalItemsCountFeature(ototalItemsCount)
                    }
                    else{
                        setTotalItemsCount(ototalItemsCount)
                        setValidTotalCount(data.data.data[2][0].SelectedTotalValue)
                    }
                    setGridData([]);
                    data.data.data == "No Records Found" ? setGridData(["No Records Found"]) : setGridData(data.data.data[0]);
                    
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    gridData = data.data.data == undefined ? ["No Records Found"] : [data.data.data]
                    status = 'Something went wrong! Please try again or reach AVAMIGRATRON Support'
                    setStatus(status)
                    await alertpopup()
                    document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
                    setGridData(gridData)
                    x?.setAttribute("hidden", "true")
                }

            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            gridData = data.data.data == undefined ? ["No Records Found"] : [data.data.data]
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()
            setGridData(gridData)
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);
        }
    }
    const navigation = (e: any) => {
        try {
            let id = JSON.parse(e.target.id).TargetTeamID;
            let teamName = JSON.parse(e.target.id).TeamName
            ////console.log(teamName)
            history.push({
                pathname: constants.URL.completeTeamChannel,
                state: { id: id, teamName: teamName, clientID: clientIDValdidate }
            })
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);
        }
    }
    const alertpopup = async () => {

        try {
            document.getElementById("success")?.removeAttribute("aria-hidden");

            document.getElementById("success")?.setAttribute("aria-modal", "true");

            document.getElementById("success")?.setAttribute("role", "dialog");

            document.getElementById("success")?.setAttribute("class", "modal fade show");

            document.getElementById("success")?.setAttribute("style", "display:block");

            document.getElementById("fadeDiv")?.removeAttribute("hidden");

            document.getElementById("Complete-Btn")?.setAttribute("disabled", "disabled");
        } catch (error) {
            ////console.log(error);

        }

    }


      
  const handleCheckedItems = async () => {
    if (selectedChannelsArray.length != 0) {
      gridData.forEach((obj) => {
        for (let i = 0; i < selectedChannelsArray.length + 1; i++) {
          if (JSON.stringify(selectedChannelsArray[i]) == JSON.stringify(obj)) {
            let element: any = document.getElementById(JSON.stringify(obj));
            if (element != "" && element != null && element != undefined && element.disabled == false) {
              element.checked = "true";
              break;
            }
          }
        }
      });
    }
  };

    const handleSelectAll = async ()=>{

        try{
             // To Handle the Partial Select, Full Select and Unchecked Functionality using Count
                let count: any = undefined;
                if (totalItemsCount == gridData.length) {
                    setIsFullSelect(true);
                  count = selectAllCount + 2;
                  if (count > 2) {
                    count = 0;
                  }
                } else {
                  count = selectAllCount + 1;
                  if (count > 2) {
                    count = 0;
                  }
                }
                // to handle the Select All CheckBox CSS
                let selectAllelement:any = document.getElementById("selectAllCheckBox")
                if(count==2){
                  selectAllelement.checked = "true";
                  selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
                else if(count == 1){
                  selectAllelement.checked ="true";
                  selectAllelement.className = "form-check-input me-2 check-alg-2 intermediate"
                }
                else{
                  selectAllelement.checked = "";
                }
          
                setSelectAllCount(count);

                 // To handle the SelectAll when the Checkbox is Unchecked
                if (count == 0) {
                    setIsFullSelect(false)
            
                    selectedChannelsArray.forEach((obj: any) => {
                      const element: any = document.getElementById(JSON.stringify(obj));
                      if (element != "" && element != null && element != undefined) {
                        element.checked = "";
                      }
                    });
                    let tempArr: any = [];
                    setSelectedChannelsArray([...tempArr]);
                  }

                  // To handle the SelectAll when the Checkbox is PartialSelect
                if (count == 1) {
                    let tempArr: any = selectedChannelsArray;
                    gridData.forEach((obj: any) => {
                      const element: any = document.getElementById(JSON.stringify(obj));
                      if (
                        element != "" &&
                        element != null &&
                        element != undefined &&
                        element.checked != true &&
                        element.disabled == false
                      ) {
                        element.checked = "true";
                        tempArr.push(obj);
                      }
                    });
                    let migrateButton = document.getElementById("migrateButton");
                    migrateButton?.removeAttribute("disabled");
                    setSelectedChannelsArray([...tempArr]);
                    // To find whether must be Be in full Select on individual CheckBox Check
                    if(tempArr.length == validTotalCount){
                        setIsFullSelect(true)
                    }
    
                }

                 // To handle the SelectAll when the Checkbox is Full Select
                if(count == 2){

                    if (clientIDValdidate != undefined && clientToken != undefined) {
                        let indexPerPage = totalItemsCount
                        setIsFullSelect(true)
                        let config = ({
                            method: "POST",
                            token: clientToken,
                            url: constants.URL.getMigratedTeamChannel,
                            data: {
        
        
                                "clientID": clientId,
                                "type": "Team",
                                "sortType": `${columnOrder}`,
                                "sortColumn": `${columnName}`,
                                "searchText": "",
                                "teamID": "",
                                "pageIndex": indexPerPage
        
        
                            },
                        }
                        )
        
                        let x = document.getElementById("loaderWithoutContent")
                        x?.removeAttribute("hidden")
                        var data: any = await GridLoad(config);
                        if (data.data.statusCode == 200) {
        
                            x?.setAttribute("hidden", "true")
                            let tempArr: any = [];
                
                            data.data.data[0].forEach((obj: any) => {
                              if(obj.Status != "Completed"){
                                tempArr.push(obj);
                              }
                              
                            });
                            setValidTotalCount(tempArr.length)
                            setSelectedChannelsArray([...tempArr]);
                        }
                        else if (data.data.data == "Invalid authentication token provided") {
                            x?.setAttribute("hidden", "true")
                            enableSessionExpiryPopup();
                        }
                        else {
                            gridData = data.data.data == undefined ? ["No Records Found"] : [data.data.data]
                            status = 'Something went wrong! Please try again or reach AVAMIGRATRON Support'
                            setStatus(status)
                            await alertpopup()
                            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
                            setGridData(gridData)
                            x?.setAttribute("hidden", "true")
                        }
        
                    }
                    else {
                        localStorage.clear();
                    }

                }
            

        }
        catch(error){

        }
    } 


    const handleCheckbox = (e: any) => {
        try {
            let tempArr:any = selectedChannelsArray
            let id = e.target.id
            let objState = JSON.parse(id)
            if (e.target.checked == true) {
                let x = document.getElementById('Complete-Btn')
                x?.removeAttribute("disabled")
                tempArr.push(objState)
                setSelectedChannelsArray([...tempArr])
            }
            else {
                let ind = tempArr.findIndex((obj: any) => obj.TargetTeamID == e.target.value)
                tempArr.splice(ind, 1);
                setSelectedChannelsArray([...tempArr])
                if (tempArr.length == 0){
                    let x = document.getElementById("Complete-Btn")
                    x?.setAttribute("disabled", "disabled");
                }
                else {
                    let x = document.getElementById("Complete-Btn")
                    x?.removeAttribute("disabled");
                }
            }
            // To find whether must be Be in full Select on individual CheckBox Check
            if(tempArr.length == validTotalCount){
                setIsFullSelect(true)
            }
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }

    const btnComplete = async () => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                let x = document.getElementById("loader")
                x?.removeAttribute("hidden")
                let selectedTeamChannels:any = []
                let payLoad:any = []

                selectedChannelsArray.forEach((obj:any)=>{
                    selectedTeamChannels.push({
                    "teamID": obj.TargetTeamID,
                    "teamName": obj.TeamName,
                    "isPrivateTeam": obj.TeamType.toLowerCase() == "public" ? false : true,
                    "migrationSummaryID": obj.SummaryID
                    })
                })

                payLoad.push({ "clientID": clientId, "type": "Team", "teams": selectedTeamChannels })
                
                let config = ({
                    method: "POST",
                    token: clientToken,
                    url: `${constants.URL.REACT_APP_CHANNELMIGRATION_BASEURL}/TeamChannelCompletion/CompleteTeamChannel`,
                    data: payLoad[0],
                }
                );
                if(adminLocal !=undefined && adminLocal !== null){
                    (config.data as any).token = adminToken
                  }
                var data: any = await CompleteTeamChannel(config);
                if (data.data.statusCode == 200) {
                    x?.setAttribute("hidden", "true")
                    setLoaderHide(false)
                    let y = document.getElementById("Complete-Btn")
                    y?.setAttribute("disabled", "disabled");
                    loadGridPage(operation);
                    $('input[name="checkbox"]').prop('checked', false);
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    document.getElementById("loader")?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    document.getElementById("loader")?.setAttribute("hidden", "true")
                    status = data.data.data != 'The selected channels are already scheduled/In Progress' ? 'Something went wrong! Please try again or reach AVAMIGRATRON Support' : 'The selected channels are already scheduled/In Progress'
                    setStatus(status)
                    alertpopup()
                }

            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            document.getElementById("loader")?.setAttribute("hidden", "true")
            status = 'Something went wrong! Please try again or reach AVAMIGRATRON Support'
            setStatus(status)
            alertpopup()
            document.getElementById("loader")?.setAttribute("hidden", "true")
            ////console.log(error)
        }
    }


    const gridBodyData = () => {
        try {
            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && typeof gridData != 'string') {

                return gridData.map((obj, index) => {
                    let rowClass: any = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"
                    let date: any = (obj.CompletedDateTime == null) ? 'NA' : `${new Date(obj.CompletedDateTime).getDate()}/${new Date(obj.CompletedDateTime).getMonth()}/${new Date(obj.CompletedDateTime).getFullYear()}  ${new Date(obj.CompletedDateTime).getHours() < 10 ? "0" + new Date(obj.CompletedDateTime).getHours() : new Date(obj.CompletedDateTime).getHours()}:${new Date(obj.CompletedDateTime).getMinutes() < 10 ? "0" + new Date(obj.CompletedDateTime).getMinutes() : new Date(obj.CompletedDateTime).getMinutes()}`
                    let cssClass = ""
                    if (obj.Status == "Completed") {
                        cssClass = "status completed-status font-14 font-semibold text-center"
                    }
                    else if (obj.Status == "Failed") {
                        cssClass = "status status-styling3 font-14 font-semibold text-center"
                    }
                    else {
                        cssClass = "status  yettostart-status font-14 font-semibold text-center"
                    }
                    return (
                        <tr className={rowClass}>
                            <td className=" py-3 tabel-data-font-level2"><input className="form-check-input" type="checkbox" value={obj.TargetTeamID}
                                id={JSON.stringify(obj)} onClick={(e) => handleCheckbox(e)} disabled={obj.Status == "Completed" ? true : false} /></td>
                            {
                                (obj.TeamName).length > 43 ?
                                    <td className="py-3 tabel-data-font-level1" data-tip={obj.TeamName} >{(obj.TeamName).substring(0, 43)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td data-tip="" className="py-3 tabel-data-font-level1" >{obj.TeamName}</td>
                            }
                            <td className=" py-3 tabel-data-font-level1 status-block padding-left-10"><span className={cssClass}>{obj.Status == null ? "Yet to Start" : obj.Status}</span></td>
                            <td className=" py-3 tabel-data-font-level1 text-align-center anchorAsPointer " >
                                <span className="float-end pe-5" id={JSON.stringify(obj)} onClick={(e) => navigation(e)}>
                                    {obj.ChannelCount}
                                </span>
                            </td>
                            <td className=" py-3 tabel-data-font-level1  ">{obj.TeamType}</td>
                            <td className=" py-3 tabel-data-font-level1">{date}</td>
                        </tr>
                    )
                })
            }
            else if (gridData[0] == undefined) {

                return (<tr> <td className="aligncenter" colSpan={6}></td></tr>)

            }
            else {
                return (

                    <tr>

                        <td className="aligncenter" colSpan={7}><img src="/Images/NoRecordsFound.svg"></img>
                            <p className="mt-2">No records found</p></td>

                    </tr>)
            }


        }
        catch (error: any) {

            ////console.log(error);

        }
    }



    const sortClicked = (e: any) => {
        try {
            let sortID = e.target.id;
            setsorthide(sortID);
            //if (sorthide !== tmpsorthide) {
            // gridData.length = 0;
            let sortval = sortID.split('@');
            setColumnName(sortval[0]);
            setColumnOrder(sortval[1].toLowerCase());
            // setIndexPerPage(1);
            // setCoutPerPage(10);
            setIndexPerPage(indexPerPage ? indexPerPage : 1);
            setCoutPerPage(countPerPage ? countPerPage : 10);


        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }

    const handleSearch = (e: any) => {
        try {
            setSearchValue(e.target.value)
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }


    const handleSearchIcon = async (e: any) => {
        try {
            if (searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    let tempOperation = ""
                    if(searchValue != ""){
                        tempOperation = "search"
                    }
                    gridData.length = 0;
                    settmpSearchValue(searchValue);
                    setOperation(tempOperation)
                    await loadGridPage(tempOperation)
                }
            }
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }

    const okbtnclick = () => {
        try {
            if (isSessionEpired === false) {
                document.getElementById("success")?.setAttribute("class", "modal fade");
                document.getElementById("success")?.setAttribute("style", "display:none");
                document.getElementById("success")?.setAttribute("aria-hidden", "true");
                document.getElementById("success")?.removeAttribute("aria-modal");
                document.getElementById("success")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                $('input[name="checkbox"]').prop('checked', false);
                loadGridPage(operation)
            }
            else {
                window.location.pathname = "/Login";
            }
        } catch (error) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);
        }
    }

    const handleKeyDown = async (e: any) => {
        try {
          
            if (e.key === 'Enter' && searchValue != null && searchValue != undefined) {
                if (searchValue !== tmpsearchValue) {
                    //gridData.length = 0;
                    let tempOperation = ""
                    if(searchValue != ""){
                        tempOperation = "search"
                    }
                    settmpSearchValue(searchValue);
                    //setIndexPerPage(1);
                    //setCoutPerPage(10);
                    setIndexPerPage(indexPerPage ? indexPerPage : 1);
                    setCoutPerPage(countPerPage ? countPerPage : 10);
                    setOperation(tempOperation)
                    await loadGridPage(tempOperation);
                }
            }
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }

    const handleLoadMore = () => {
        try {
            let oindexPerPage = indexPerPage + 1;
            let ocountPerPage = countPerPage + 10;
            setIndexPerPage(oindexPerPage);
            setCoutPerPage(ocountPerPage);

            // To handle the Select All When Load More is Clicked
            let selectAllelement:any = document.getElementById("selectAllCheckBox")
            if (selectAllCount == 1) {
                if(isFullSelect == true){
                    setSelectAllCount(2);
                    selectAllelement.checked = "true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
                else{
                    setSelectAllCount(0);
                    selectAllelement.checked = "";
                }
            } 
            else if(selectAllCount == 2) {
                    setSelectAllCount(2);
                    selectAllelement.checked = "true";
                    selectAllelement.className = "form-check-input me-2 check-alg-2"
                }
        }
        catch (error: any) {
            status = 'Something went wrong. Please reach AVAMIGRATRON team'
            setStatus(status)
            alertpopup()

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            ////console.log(error);

        }
    }
    return (
        <div className="row justify-content-center my-5 card-container mx-0 ms-0 overall-res-padding">
            <BackNavigation backNav={" Migration using Actual User "} link={"/ChannelMigration"} />
            <WizardNavigation wizActive={"step3InActive"} />
            <p className='font-20 font-bold d-sm-block d-md-block d-lg-none'>Team Channel Completion</p>

            <div className="overlay" id='loader' hidden={true}>
                <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                    <p className="text-center">Please wait. We are completing your Teams migration.</p>
                </div>
            </div>
            <div className="overlay" id='loaderWithoutContent' hidden={true}>
                <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse  show"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlush"
                >
                    <div>
                        <label className="font-13 font-regular accordian-para pe-2">The created MS Teams Channels will be visible in MS Teams app only after performing the Team Channel Completion.<br/>
                            Before Team Channel Completion, if you wish to migrate latest conversations, perform incremental migration,<br/> and then proceed with Team Channel Completion.</label>
                        <div className="row float-end mb-2 mx-0 search-filter-teamchannel-completion">
                            <div className="col-md-12 ps-0">
                                <div className="input-group box-shodow-filter">
                                <p className="mb-0 font-semibold font-16 text-nowrap float-start mt-2 me-3">
                                    # of records: {gridData[0] != "No Records Found" ? gridData.length:0}{" "}
                                    <span className="font-regular font-16">of </span> 
                                    { gridData[0] != "No Records Found" ?
                                    operation == "search"?
                                        totalItemsCountFeature: totalItemsCount 
                                    :0}{" ("}{selectedChannelsArray.length}{" Selected)"}
                                </p>
                                    <input
                                        type="text"
                                        className="form-control form-control-style-2"
                                        placeholder="Search"
                                        aria-describedby="button-addon1"
                                        value={searchValue} onKeyDown={handleKeyDown} onChange={handleSearch}
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        id="button-addon1"
                                        onClick={handleSearchIcon}
                                    >
                                        <img src="/Images/search.svg" alt="search-icon" onClick={handleSearchIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-12 col-12 float-start mt-2 table-responsive p-1">
                    <table className="table table-border mb-4">
                        <thead className="tabel-head-bg">
                            <tr>
                                <th className="table-head">
                                    <th className="tabel-head-font-level1 font-bold font-14 channel-table-column" style={{paddingLeft: '15px'}}>
                                        {/* <input className="form-check-input me-2 check-alg-2" name='checkbox' type="checkbox" /> */}
                                            <input
                                                className="form-check-input me-2 check-alg-2 intermediate"
                                                id="selectAllCheckBox"
                                                name="checkbox"
                                                type="checkbox"
                                                onClick={handleSelectAll}
                                                disabled={gridData[0] == "No Records Found"?true:false}
                                            />
                                    </th>
                                </th>
                                <th className="table-head font-14 font-bold">Team Name
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="TeamName@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "TeamName@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="TeamName@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "TeamName@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 text-center font-bold">Status
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="Status@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="Status@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "Status@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 font-bold text-end">Channel Count
                                    <img className="me-5 ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="ChannelCount@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelCount@Desc" ? true : false} />
                                    <img className="me-5 ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="ChannelCount@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "ChannelCount@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 font-bold" >Team Type
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="TeamType@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "TeamType@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="TeamType@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "TeamType@Desc" ? false : true} />
                                </th>
                                <th className="table-head font-14 font-bold">Completed Date & Time
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/arrow.svg" alt="sort-arrow" id="CompletedDateTime@Desc" onClick={e => sortClicked(e)} hidden={sorthide == "CompletedDateTime@Desc" ? true : false} />
                                    <img className="ms-2 test1 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="sort-arrow" id="CompletedDateTime@Asc" onClick={e => sortClicked(e)} hidden={sorthide == "CompletedDateTime@Desc" ? false : true} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {gridBodyData()}
                        </tbody>
                    </table>
                </div>
                <div className="col-md-12 mt-3 mb-5 text-center">
                    {/* {totalItemsCount > countPerPage ?
                        <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-3" onClick={handleLoadMore} >Load More</button>
                        : ""} */}
                     {operation != "search" ? (
                                        totalItemsCount > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-3" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        ) : (
                                        totalItemsCountFeature > countPerPage ? (
                                            <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 my-3" onClick={handleLoadMore}>
                                            Load More
                                            </button>
                                        ) : ""
                                        )}
                </div>
            </div>
            <footer className="footer mt-auto fixed-bottom py-3 bg-footer shadow-lg">
                <div className=" mx-3 d-flex justify-content-end">
                    <button type="button" id='Complete-Btn' disabled={selectedChannelsArray.length!=0 ? false:true} onClick={btnComplete} className="btn-primary-theme common-btn-class btn btn-footer font-16 font-regular me-2">Complete</button>
                </div>
            </footer>
            <div className="modal fade" id="success" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{status}</p>

                                <button type="button" id="okclickbtn" onClick={okbtnclick} className="btn-primary-theme common-btn-class btn btn-ok  text-center" data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>
        </div>




    )
}