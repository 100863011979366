import {APIService} from '../Client/Client';


// let baseURL = "http://192.168.4.24:3000/PreMigration/";

export const sendRequestToAPI=( config : any) =>
{
    try {
         
        return APIService((config.url),{method:'POST' , body : config.data}, config.token);
    } catch (error) {
         
        throw error;
    }
}
export const gridLoad=( loadGridPostData : any) =>
{
     
    return APIService(loadGridPostData.url,{method:'POST' , body : loadGridPostData.data}, loadGridPostData.token);
}
