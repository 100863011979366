import { APIService } from '../Client/Client';
import constants from '../Common/Constants';

let baseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/Settings/`;
let baseURLUserAuthentication = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication`;
let preMigrationBaseURL = `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}`;


export const sendRequestToNodeServicesApiTS = (config: any) => {
    try {
        let endpoint = ((config.endpoint).includes("ClientSession")) ? baseURLUserAuthentication + config.endpoint : baseURL + config.endpoint;

        return APIService(endpoint, { method: 'POST', body: config.data }, config.token);

    } catch (error) {

        throw error;

    }
}

export const sendRequestToUpdateUsersList= (config: any) => {
    try {
        
        return APIService(config.url, { method: 'POST', body: config.data }, config.token);

    } catch (error) {

        throw error;

    }
}



