import { useState, useEffect } from "react";
import { GridLoad } from "../../Services/ChannelMigrationServices";
import constants from '../../Common/Constants';
import jwt from 'jwt-decode'
import objCryptoJS from "crypto-js";
import ReactTooltip from "react-tooltip";
import $ from 'jquery'

export default function ManageScheduleMigration() {
    let GridLoadarray: any[] = [];
    let [statusText, setStatus] = useState("")
    const [maxMigrationFromDate, setMaxMigrationFromDate] = useState(new Date().toISOString().split('T')[0]);
    let [clientID, setClientID] = useState(0);
    const [pageIndex, setpageIndex] = useState(1);
    const [sortColumn, setsortColumn] = useState("ScheduledAt");
    const [sortType, setsortType] = useState("desc");
    const [searchString, setsearchString] = useState("");
    let [fromDate, setFromDate] = useState("");
    let [toDate, setToDate] = useState("");
    let [migrationStatus, setMigrationStatus] = useState("");
    const [datePopUp, setDate] = useState("")
    let [timeVal, setTimeVal] = useState("")
    //update scheduled date and time
    let [updatedDate, setUpdatedDate] = useState("");
    const [updatedTime, setUpdatedTime] = useState("");
    const [schedulerID, setSchedulerID] = useState(0);
    const [migrationSummaryID, setmigrationSummaryID] = useState(0);
    const [inventorySummaryID, setinventorySummaryID] = useState(0);
    let [gridData, setGridData] = useState(GridLoadarray);
    const [sorthide, setsorthide] = useState("");
    const [countPerPage, setCoutPerPage] = useState(10);
    const [totalItemsCount, setTotalItemsCount] = useState(0);
    let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
    const [isSessionEpired, setisSessionEpired] = useState(false);
    let InActiveTimeout: NodeJS.Timeout;

    const [arrTime, setArrTime] = useState([{ UI: "00:00", value: 0 }, { UI: "01:00", value: 1 }, { UI: "02:00", value: 2 }, { UI: "03:00", value: 3 }, { UI: "04:00", value: 4 }, { UI: "05:00", value: 5 }, { UI: "06:00", value: 6 }, { UI: "07:00", value: 7 }, { UI: "08:00", value: 8 }, { UI: "09:00", value: 9 }, { UI: "10:00", value: 10 },
    { UI: "11:00", value: 11 }, { UI: "12:00", value: 12 }, { UI: "13:00", value: 13 }, { UI: "14:00", value: 14 }, { UI: "15:00", value: 15 }, { UI: "16:00", value: 16 }, { UI: "17:00", value: 17 }, { UI: "18:00", value: 18 }, { UI: "19:00", value: 19 }, { UI: "20:00", value: 20 },
    { UI: "21:00", value: 21 }, { UI: "22:00", value: 22 }, { UI: "23:00", value: 23 }]);

    let local: any = localStorage.getItem("cid_t");
    let adminLocal: any = localStorage.getItem("aid_t");
    let c_t_Key: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    let a_t_Key: any = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY;
    let objClient: any;
    let adminObjClient: any;
    var decryptedValue: any;
    const r_n_Key: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY;
    let clientIDValdidate: any;
    let clientToken: any;
    let adminToken: any;
    if (local != undefined) {
        let byteValue = objCryptoJS.AES.decrypt(local, c_t_Key);
        decryptedValue = byteValue.toString(objCryptoJS.enc.Utf8);
        objClient = jwt(decryptedValue);
        clientIDValdidate = Math.random();
        clientToken = objCryptoJS.AES.encrypt(decryptedValue, r_n_Key).toString();
        SessionEpiredDateTime = objClient.CreatedAt;

        if(adminLocal != undefined){
            let adminByteValue = objCryptoJS.AES.decrypt(adminLocal, a_t_Key);
            let adminDecryptedValue = adminByteValue.toString(objCryptoJS.enc.Utf8);
            adminObjClient = jwt(adminDecryptedValue);
            adminToken = objCryptoJS.AES.encrypt(adminDecryptedValue, r_n_Key).toString();
          }

    }


    useEffect(() => {

        resetSessionTimer();
        clientID = clientIDValdidate
        setClientID(clientID)
        let x = document.getElementById("loaderWithoutContent")
        x?.removeAttribute("hidden")
        loadGridPage();
        document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
        //#region Event handlers Keypad click and mouse click
        window.addEventListener('keydown', resetSessionTimer);
        window.addEventListener('click', resetSessionTimer);
        //#endregion
        return () => {
            window.removeEventListener('click', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
          };

    }, [sortColumn, sortType, countPerPage])


    //#region Session Expiry POP-UP enable and Session Clear method

    const enableSessionExpiryPopup = () => {
        try {
            statusText = "Your session has expired. Please log in again to continue using the application."
            setStatus(statusText)

            //#region Show PopUp
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
            //#endregion
            localStorage.removeItem("cid_t");
            setisSessionEpired(true);
        } catch (error) {

        }
    }
    //#endregion

    //#region  To fetch the updated sess

    const getClientSessionDetails = async () => {
        try {

            if (clientID != undefined) {
                let config = {
                    url: `${constants.URL.REACT_APP_MICROSERVICE_NODE_HOST}/UserAuthentication/ClientSession`,
                    token: clientToken
                }

                let data: any = await GridLoad(config)
                if (data.data.statusCode == 200) {
                    //#region Update and Frame Client Session
                    localStorage.removeItem("cid_t");
                    let byteValue = objCryptoJS.AES.decrypt(data.data.data.clientToken, r_n_Key);
                    var dePass = byteValue.toString(objCryptoJS.enc.Utf8);
                    let enPass = objCryptoJS.AES.encrypt(dePass, c_t_Key).toString();
                    localStorage.setItem("cid_t", enPass);
                    //#endregion

                    //#region Update state variables
                    //console.log(SessionEpiredDateTime);
                    let updatedobjClient: any = jwt(dePass);
                    SessionEpiredDateTime = updatedobjClient.CreatedAt;
                    setSessionEpiredDateTime(SessionEpiredDateTime);
                    clientToken = objCryptoJS.AES.encrypt(dePass, r_n_Key).toString();
                    //console.log(SessionEpiredDateTime);
                    //#endregion
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    enableSessionExpiryPopup();
                }
                else {
                    enableSessionExpiryPopup();
                }
            }
            else {
                localStorage.clear();
            }

        } catch (error) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)

            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion

    //#region Initiate the time for inactive and check the session expiry time duration
    const resetSessionTimer = () => {
        try {
            //console.log("eventhit")
            clearTimeout(InActiveTimeout);
            InActiveTimeout = setTimeout(() => {
                enableSessionExpiryPopup();
            }, Number(process.env.REACT_APP_TABIDLESCENARIO));
            let currentDateTime: any = new Date().toISOString();
            let diffInMilliseconds: number;
            diffInMilliseconds = Math.abs(new Date(SessionEpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            let minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            if (minutes > Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP)) {
                getClientSessionDetails();
            }
        }
        catch (ex: any) {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)

            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        }
    }
    //#endregion



    const loadGridPage = async () => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
                let x = document.getElementById("loaderWithoutContent")
                x?.removeAttribute("hidden")
                gridData = []
                setGridData(gridData);
                let loadGCScheduledData = (
                    {
                        method: "POST",
                        url: constants.URL.getScheduledGroupChatDataURL,
                        token: clientToken,
                        data:
                        {
                            "clientID": clientID,
                            "MigrationType": "Migration",
                            "pageIndex": pageIndex,
                            "SortColumn": `${sortColumn}`,
                            "Sorttype": `${sortType}`,
                            "Searchtext": `${searchString}`,
                            "migrationStatus": migrationStatus,

                            "fromDate": fromDate,

                            "toDate": toDate
                        }
                    }
                );

                var data: any = await GridLoad(loadGCScheduledData);

                if (data.data.statusCode == 200) {
                    x?.setAttribute("hidden", "true")

                    setTotalItemsCount(data.data.data[1][0].ScheduledChatCount);
                    if (data.data.data == "No Records Found" || data.data.data == "Something went wrong. Please reach AVAMIGRATRON team") {

                        setGridData([data.data.data]);

                    }

                    else {

                        setGridData(data.data.data[0])//data.data.data[0]);

                    }

                }
                else if (data.data.data == "Invalid authentication token provided") {
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    x?.setAttribute("hidden", "true")
                    enableAlertPopUp()
                }
            }
            else {
                localStorage.clear();
            }
            document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true")
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const enableReschedulePopup = async () => {
        try {
            const element: any = document.getElementById("cardDetails");
            document.getElementById('picker')?.setAttribute("value", "")
            document.getElementById("errorlabelTime")?.setAttribute("hidden", "true")
            document.getElementById('saveBtn')?.setAttribute("disabled", "true");
            //unCheckAllCheckboxes();
            if (element.hasAttribute("aria-modal")) {
                //#region Hide PopUp
                document.getElementById("cardDetails")?.setAttribute("class", "modal fade");
                document.getElementById("cardDetails")?.setAttribute("style", "display:none");
                document.getElementById("cardDetails")?.setAttribute("aria-hidden", "true");
                document.getElementById("cardDetails")?.removeAttribute("aria-modal");
                document.getElementById("cardDetails")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
                document.getElementById('timeDD')?.setAttribute('hidden', 'true')
                //#endregion
            }
            else {
                document.getElementById("cardDetails")?.removeAttribute("aria-hidden");
                document.getElementById("cardDetails")?.setAttribute("aria-modal", "true");
                document.getElementById("cardDetails")?.setAttribute("role", "dialog");
                document.getElementById("cardDetails")?.setAttribute("class", "modal fade show");
                document.getElementById("cardDetails")?.setAttribute("style", "display:block");
                document.getElementById("fadeDiv")?.removeAttribute("hidden");

            }
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const editClicked = async (obj: any) => {
        try {
            setmigrationSummaryID(parseInt(obj.MigrationSummaryID));
            setinventorySummaryID(parseInt(obj.InventorySummaryID));
            setSchedulerID(parseInt(obj.ID));
            ////console.log(`${new Date(obj.ScheduledAt).getDate()}/${new Date(obj.ScheduledAt).getMonth()}/${new Date(obj.ScheduledAt).getFullYear()}`)
            let timeSplit: any = obj.ScheduledAt.replace(/-/g, "/").substring(0, 16).replace("T", " ")
            setTimeVal(`${timeSplit.split(" ")[1]}`)
            let datePopulate = timeSplit.split(" ")[0].replace(/\//g, "-")
            setUpdatedDate(`${datePopulate}`)
            ////console.log(datePopUp + "Date")
            document.getElementById("")
            enableReschedulePopup();

            // enableDisablePopup();
            //sethideEditPopup(hideEditPopup == "none" ? "block" : "none"); 
            //document.getElementById("popup").style.display = "block";
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }



    const deleteClicked = async (obj: any) => {
        try {
            if (clientIDValdidate != undefined && clientToken != undefined) {
                let x = document.getElementById("loaderWithoutContent")
                x?.removeAttribute("hidden")
                let deleteScheduledData = (
                    {
                        method: "POST",
                        url: constants.URL.deleteScheduledGroupChatDataURL,
                        token: clientToken,
                        data:
                        {
                            "clientID": clientID,
                            "schedulerID": parseInt(obj.ID),
                            "migrationSummaryID": parseInt(obj.MigrationSummaryID),
                            "inventorySummaryID": parseInt(obj.InventorySummaryID),
                            "type" : "Migration"
                        }
                    }
                );
                if(adminLocal !=undefined && adminLocal !== null){
                    (deleteScheduledData.data as any).token = adminToken
                }

                var data: any = await GridLoad(deleteScheduledData);
                if (data.data.statusCode == 200) {

                    loadGridPage();
                }
                else if (data.data.data == "Invalid authentication token provided") {
                    let x = document.getElementById("loaderWithoutContent")
                    x?.setAttribute("hidden", "true")
                    enableSessionExpiryPopup();
                }
                else {
                    x?.setAttribute("hidden", "true")
                    enableAlertPopUp()

                }
            }
            else {
                localStorage.clear();
            }
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error)
        }
    }

    const enableAlertPopUp = () => {
        try {
            statusText = "Something went wrong! Please try again or reach AVAMIGRATRON Support"
            setStatus(statusText)
            document.getElementById("failedPopUp")?.removeAttribute("aria-hidden");
            document.getElementById("failedPopUp")?.setAttribute("aria-modal", "true");
            document.getElementById("failedPopUp")?.setAttribute("role", "dialog");
            document.getElementById("failedPopUp")?.setAttribute("class", "modal fade show");
            document.getElementById("failedPopUp")?.setAttribute("style", "display:block");
            document.getElementById("fadeDiv")?.removeAttribute("hidden")
        } catch (error) {
            ////console.log(error);

        }
    }

    const gridBodyData = () => {
        try {

            if (gridData.length > 0 && gridData[0] != "No Records Found" && gridData[0] != "Something went wrong. Please reach AVAMIGRATRON team" && typeof gridData != 'string') {
                return gridData.map((obj, index) => {
                    let currentDate: any = new Date();
                    let scheduledDate: any = new Date(obj.ScheduledAt);
                    let isEditDisabled: any = (scheduledDate - currentDate) <= 300000 ? true : false;
                    let scheduledTime: any = obj.ScheduledAt.replace(/-/g, "/").substring(0, 16).replace("T", " ");
                    let scheduleSplit = scheduledTime.split("/")
                    ////console.log(scheduleSplit);
                    let timeSplit = scheduleSplit[2].split(" ")
                    scheduledTime = `${scheduleSplit[1]}/${timeSplit[0]}/${scheduleSplit[0]} ${timeSplit[1]}`

                    let rowClass: any = (index % 2 == 0) ? "tabel-body-bg" : "tabel-body bgsecondary"


                    return (
                        <tr className={rowClass} >
                            {
                                // (scheduledTime).length > 16 ?
                                //     <td className="table-data-custom  py-3  tabel-data-font-level1" data-tip={scheduledTime} >{(scheduledTime).substring(0, 16)}...</td> :
                                <td data-tip="" className="table-data-custom py-3 tabel-data-font-level1" >{scheduledTime}</td>
                            }
                            {obj.MigrationStatus === 'Queue' ? (
                                <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"> <span className="queue-status channel_ScheduledStatus font-14">Queue</span></td>
                            ) :
                                obj.MigrationStatus === 'In progress' ? (
                                    <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"> <span className="inprogress-status channel_ScheduledStatus font-14">In progress</span></td>
                                )
                                    :
                                    obj.MigrationStatus === 'Completed' ? (
                                        <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"><span className="completed-status channel_ScheduledStatus font-14">Completed</span></td>
                                    )
                                        :
                                        obj.MigrationStatus === null ? (
                                            <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"><span className="yettostart-status channel_ScheduledStatus font-14">Yet to Start</span></td>
                                        )
                                            :
                                            obj.MigrationStatus === 'Scheduled' ? (
                                                <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"><span className="scheduled-status channel_ScheduledStatus font-14">Scheduled</span></td>
                                            )
                                                :
                                                obj.MigrationStatus === 'Warning' ? (
                                                    <td className="table-data-custom  py-3 tabel-data-font-level1 text-center"><span className="warning-status channel_ScheduledStatus font-14">Warning</span></td>
                                                )
                                                    :
                                                    (
                                                        <td className="table-data-custom py-3 tabel-data-font-level1"> <span className="status status-styling font-14 font-semibold">{obj.MigrationStatus}</span></td>
                                                    )
                            }
                            {
                                (obj.SourceChatName).length > 22 ?
                                    <td className="table-data-custom  py-3 tabel-data-font-level1" data-tip={obj.SourceChatName} >
                                        {(obj.SourceChatName).substring(0, 22)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td data-tip="" className="table-data-custom  py-3 tabel-data-font-level1" >{obj.SourceChatName}</td>
                            }
                            {
                                (obj.TargetTeamName).length > 22 ?
                                    <td className="table-data-custom  py-3 tabel-data-font-level1" data-tip={obj.TargetTeamName} >
                                        {(obj.TargetTeamName).substring(0, 22)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td data-tip="" className="table-data-custom  py-3 tabel-data-font-level1" >{obj.TargetTeamName}</td>
                            }
                            {
                                (obj.TargetChannelName).length > 22 ?
                                    <td className="table-data-custom  py-3 tabel-data-font-level1" data-tip={obj.TargetChannelName} >
                                        {(obj.TargetChannelName).substring(0, 22)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td data-tip="" className="table-data-custom  py-3 tabel-data-font-level1" >{obj.TargetChannelName}</td>
                            }
                            {
                                (obj.InventoryName).length > 22 ?
                                    <td className="table-data-custom  py-3 tabel-data-font-level1" data-tip={obj.InventoryName} >
                                        {(obj.InventoryName).substring(0, 22)}...
                                        <ReactTooltip />
                                    </td> :
                                    <td data-tip="" className="table-data-custom  py-3 tabel-data-font-level1" >{obj.InventoryName}</td>
                            }
                            {/* <td className="leftalign ">{dateFormat(obj.ScheduledAt,"mm/dd/yyyy HH:MM")}</td>  */}


                            <td className="py-3 editdel-btn">
                                {obj.MigrationStatus === 'Scheduled' ?
                                    isEditDisabled === true ?
                                        <div>
                                            <img title="Edit" className="icons icon-disable me-2" src="/Images/edit-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} />
                                            <img title="Delete" className="icons anchorAsPointer" src="/Images/delete-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} />
                                        </div>
                                        : <div>
                                            <img title="Edit" className="icons me-2 anchorAsPointer" src="/Images/edit-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => editClicked(obj)} />
                                            <img title="Delete" className="icons anchorAsPointer" src="/Images/delete-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} />
                                        </div>
                                    : <div className='icon-disable'>
                                        <img title="Edit" className="icons me-2" src="/Images/edit-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} />
                                        <img title="Delete" className="icons" src="/Images/delete-icon.svg" id={`${obj.MigrationSummaryID}@${obj.ID}`} onClick={e => deleteClicked(obj)} />
                                    </div>
                                }

                            </td>
                        </tr>)
                })
            }
            else if (gridData[0] == undefined || gridData == undefined) {

                return (<tr> <td className="aligncenter" colSpan={6}></td></tr>)

            }
            else {
                return (

                    <tr>

                        <td className="aligncenter" colSpan={7}><img src="/Images/NoRecordsFound.svg"></img>
                            <p className="mt-2">No records found</p></td>

                    </tr>)
            }
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")

            ////console.log(error);
        }
    }







    const sortClicked = async (e: any) => {
        try {
            let sortID = e.target.id;
            let sortval = sortID.split('@');

            setsortColumn(sortval[0])
            setsortType(sortval[1])
            setsorthide(sortID);
            await loadGridPage();


        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleSearch = (e: any) => {
        try {
            setsearchString(e.target.value)

        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleFromDate = (e: any) => {
        try {
            fromDate = e.target.value
            setFromDate(fromDate);
            $(function () {

                $('#txtboxFilterToDate').attr('min', e.target.value);

                $('#txtboxFilterFromDate').attr('max', toDate);

            });

        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleTimePicker = (e: any) => {
        try {

            ////console.log(e.target.value);
            //changeDropDownSizetoZero();
            //setUpdatedTime(e.target.value+"")
            let x = document.getElementById("picker")
            document.getElementById('timeDD')?.setAttribute('hidden', 'true')
            x?.setAttribute("value", `${e.target.id}`)
            setUpdatedTime(e.target.id + "")
            timeVal = e.target.id
            setTimeVal(timeVal);
            handleErrorLabelPopup()
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleToDate = (e: any) => {
        try {
            toDate = e.target.value
            setToDate(toDate);
            $(function () {

                $('#txtboxFilterToDate').attr('min', fromDate);

                $('#txtboxFilterFromDate').attr('max', e.target.value);

            });

        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleMigrationStatus = (e: any) => {
        try {
            migrationStatus = e.target.value
            setMigrationStatus(migrationStatus);
            document.getElementById("ApplyFilter")?.removeAttribute("disabled")

        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }




    const handleDate = (e: any) => {
        try {
            updatedDate = e.target.value
            setUpdatedDate(updatedDate)
            setDate(e.target.value)
            handleErrorLabelPopup()
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }




    const saveScheduleClick = async (e: any) => {
        try {
            var currentTime = new Date().getUTCHours().toString();
            var currentDate = new Date().toISOString().split('T')[0]

            let objCurrentDateCheck = new Date(currentDate);
            let objScheduledDateCheck = new Date(updatedDate);
            let objScheduledDate: any = new Date(updatedDate + "T" + timeVal + "Z");
            let objCurrentDate: any = new Date();
            let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;
            if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && timeVal <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && timeVal == currentTime + 1 && isEditDisabled)) {
                //if (updatedDate === currentDate && timeVal <= currentTime) {
                let x = document.getElementById("errorlabelTime");
                x?.removeAttribute('hidden');
                document.getElementById('saveBtn')?.setAttribute("disabled", "true");
            }
            else {
                if (clientIDValdidate != undefined && clientToken != undefined) {
                    let updatedDateandTime = updatedDate + " " + timeVal;
                    let updateScheduledData = (
                        {
                            method: "POST",
                            url: constants.URL.updateScheduledGroupChatDataURL,
                            token: clientToken,
                            data:
                            {
                                "clientID": clientID,
                                "schedulerId": schedulerID,
                                "DateTime": updatedDateandTime,
                                "migrationSummaryID": migrationSummaryID,
                                "inventorySummaryID": inventorySummaryID,
                                "type" : "Migration"
                            }
                        }
                    );
                    if(adminLocal !=undefined && adminLocal !== null){
                        (updateScheduledData.data as any).token = adminToken
                    }

                    let x = document.getElementById("loaderWithoutContent")
                    x?.removeAttribute("hidden")
                    enableReschedulePopup()
                    var data: any = await GridLoad(updateScheduledData);
                    if (data.data.statusCode == 200) {
                        loadGridPage();
                    }
                    else if (data.data.data == "Invalid authentication token provided") {
                        x?.setAttribute("hidden", "true")
                        enableSessionExpiryPopup();
                    }
                    else {
                        x?.setAttribute("hidden", "true")
                        enableAlertPopUp()

                    }


                }
                else {
                    localStorage.clear();
                }
            }

        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const handleLoadMore = () => {
        try {
            setpageIndex(pageIndex + 1);
            setCoutPerPage(countPerPage + 10);
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const disablePopUp = () => {
        try {
            if (isSessionEpired === false) {
                document.getElementById("failedPopUp")?.setAttribute("class", "modal fade");
                document.getElementById("failedPopUp")?.setAttribute("style", "display:none");
                document.getElementById("failedPopUp")?.setAttribute("aria-hidden", "true");
                document.getElementById("failedPopUp")?.removeAttribute("aria-modal");
                document.getElementById("failedPopUp")?.removeAttribute("role");
                document.getElementById("fadeDiv")?.setAttribute("hidden", "true");
            }
            else {
                window.location.pathname = "/Login";
            }
        } catch (error) {

            ////console.log(error);
        }
    }

    const handleErrorLabelPopup = () => {
        try {
            var currentTime = parseInt(new Date().getUTCHours().toString());
            var currentDate = new Date().toISOString().split('T')[0];
            let chosenTime = parseInt((timeVal).substring(0, 2))

            let objCurrentDateCheck = new Date(currentDate);
            let objScheduledDateCheck = new Date(updatedDate);
            let objScheduledDate: any = new Date(updatedDate + "T" + timeVal + "Z");
            let objCurrentDate: any = new Date();
            let isEditDisabled: any = (objScheduledDate - objCurrentDate) <= 300000 ? true : false;
            if ((objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime <= currentTime) || (objCurrentDateCheck.getTime() == objScheduledDateCheck.getTime() && chosenTime == currentTime + 1 && isEditDisabled)) {
                let x = document.getElementById("errorlabelTime")
                x?.removeAttribute('hidden');
                document.getElementById('saveBtn')?.setAttribute("disabled", "true");
            }
            else {
                document.getElementById('errorlabelTime')?.setAttribute("hidden", "true");
                if (updatedDate != "" && timeVal != "") {
                    document.getElementById('saveBtn')?.removeAttribute("disabled");
                }
            }
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }
    const btnClearFilterClick = () => {
        try {
            const migrationStatusElement: any = document.getElementById("ddFilterMigrationStatus");
            const fromDateElement: any = document.getElementById("txtboxFilterFromDate");
            const toDateElement: any = document.getElementById("txtboxFilterToDate");
            document.getElementById("ApplyFilter")?.removeAttribute("disabled")
            migrationStatusElement.value = '';
            setMigrationStatus('')
            fromDateElement.value = '';
            toDateElement.value = ''
            fromDate = ''
            toDate = ''
            migrationStatus = ''
            setFromDate(fromDate)
            setToDate(toDate)
            setMigrationStatus(migrationStatus)
            $(function () {

                $('#txtboxFilterToDate').attr('min', fromDate);

                $('#txtboxFilterFromDate').attr('max', toDate);

            });
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }

    const btnCloseFilterClick = () => {
        try {
            const migrationStatusElement: any = document.getElementById("ddFilterMigrationStatus");
            const fromDateElement: any = document.getElementById("txtboxFilterFromDate");
            const toDateElement: any = document.getElementById("txtboxFilterToDate");
            migrationStatusElement.value = '';
            setMigrationStatus('')
            fromDateElement.value = '';
            toDateElement.value = ''
            document.getElementById("ApplyFilter")?.setAttribute("disabled", "disabled");
            document.getElementById("AdvancedFilterDiv")?.setAttribute("hidden", "true")
            $(function () {

                $('#txtboxFilterToDate').attr('min', fromDate);

                $('#txtboxFilterFromDate').attr('max', toDate);

            });
        }
        catch (error: any) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);
        }
    }
    const handleTimeToggle = () => {
        try {
            document.getElementById('timeDD')?.removeAttribute('hidden')
        } catch (error) {
            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);

        }
    }
    const keypressInventory = (e: any) => {

        try {

            if (e.keyCode == 13) {

                handleSearch(e)

                loadGridPage();

            }

        }

        catch (error: any) {

            document.getElementById("loaderWithoutContent")?.setAttribute("hidden", "true")
            enableAlertPopUp()
            ////console.log(error);

        }

    }


    return (

        <div  >
            {/* <div className="col-md-12 mt-5"> */}
            <div className="modal fade" id="failedPopUp" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content content-margin">
                        <div className="modal-body">
                            <img src="/Images/Alert.svg" className="success-icon" alt="success" />
                            <div className="text-center success-content w-100 mt-5">
                                <h5 className="font-semibold font-18">Alert!</h5>
                                <p className="success-text w-100 text-center mb-4">{statusText}</p>

                                <button type="button" className="btn-primary-theme common-btn-class btn btn-ok  text-center" onClick={disablePopUp} data-bs-dismiss="modal">Ok</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12">
                {/* <!--Slack channel section starts here--> */}
                <div className="col-md-12 col-12 float-start mt-2">

                    {/* <h5 className="channel-header font-18 font-bold">Manage Clients</h5> */}


                    {/* <!--Slack channel section ends here--> */}
                    <div className="tab-content">
                        <div id="high-level-inventory" className="tab-pane active w-100">
                            {/* <!-- channel list table starts here --> */}
                            <div className="row px-2">
                                <div className="col-md-12 float-start">
                                    <div className="float-start w-100 ">
                                        <div className="phn-search-float-s">
                                            {/* <label className=" channel-font font-18 font-bold">Manage Clients</label> */}
                                            <p className="margin-left-10">Here is your scheduled channels for migration. Click on Edit icon to reschedule.</p>
                                        </div >
                                        <div className="phn-search-float-e">

                                            <div className="input-group box-shodow-filter msm-refresh-res search-container-msm float-start">
                                                <input type="text" className="form-control form-control-style-2" placeholder="Search" aria-describedby="button-addon1" value={searchString} onKeyDown={(e) => keypressInventory(e)} onChange={handleSearch} />
                                                <button className="btn btn-outline-secondary" type="button" id="button-addon1" onClick={() => loadGridPage()}><img src="/Images/search.svg" alt="search-icon" />
                                                </button>
                                            </div>

                                            {/* <!-- advanced-filter --> */}
                                            <div className="dropdown filter float-start dropdown-position-actualuser">
                                                <button type="button" className="btn filter-shadow float-end msm-filter-res001 box-shodow-filter p-0" title="Advanced Filter" onClick={() => { document.getElementById("AdvancedFilterDiv")?.removeAttribute("hidden") }}>
                                                    <img src="/Images/filter.svg" alt="filter" />
                                                </button>

                                                {/* <div className="dropdown-menu advance-filter-inventory"> */}
                                                <div className="dropdown-menu show Channeldrop-filter-sty manageschMigrationFilter-sa filter-dropdown-actualuser " id="AdvancedFilterDiv" hidden>
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-12 pb-2 padding-filter-none border-0 mb-1">
                                                                <label className="advance-filter-font font-20 font-bold mt-3">Advanced Filter</label>
                                                                <a className="advancedfilter_close_padding btn float-end anchorAsPointer" id="close-filter" data-bs-dismiss="advanced-filter" aria-label="Close" onClick={btnCloseFilterClick}>
                                                                    <span aria-hidden="true"><img src="/Images/filter-close-icon.svg" alt="filter-close-icon" /></span>
                                                                </a>
                                                            </div>
                                                            <div className="col-md-12 padding-filter-none">

                                                                <label className="semi-bold-popup font-16 ">Scheduled On</label>

                                                            </div>
                                                            <div className="col-md-12 rowTopSpace padding-filter-none">
                                                                <div className="row">
                                                                    <div className="col-md-6 py-2">
                                                                        <label className="date-created w-100 float-start channel-check font-14 font-regular">From</label>
                                                                        {/* <input type="date" placeholder="select date" className="inventory-date-to-control date-style" onChange={handleToDate} id="txtboxFilterFromDate"/> */}
                                                                        <input type="date" placeholder="select date" className="custom-date-dropdown-border form-control" onKeyDown={(e) => { e.preventDefault() }} onChange={handleFromDate} id="txtboxFilterFromDate" />

                                                                    </div>

                                                                    <div className="col-md-6 py-2 filter-date-padding-right">
                                                                        <label className="date-created w-100 float-start channel-check font-14 font-regular">To</label>
                                                                        {/* <input type="date" placeholder="select date" className="inventory-date-to-control date-style" onChange={handleToDate} id="txtboxFilterToDate"/> */}
                                                                        <input type="date" placeholder="select date" className="custom-date-dropdown-border form-control" onChange={handleToDate} onKeyDown={(e) => { e.preventDefault() }} id="txtboxFilterToDate" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 rowTopSpace ">
                                                                <div className="row">
                                                                    <div className="col-md-6 float-start mbl-res-chan-drop">
                                                                        <label className="date-created semi-bold-popup py-2 w-100 float-start channel-check font-16 font-regular">Migration Status</label>
                                                                        <select id="ddFilterMigrationStatus" className="custom-date-dropdown-border form-control form-select" placeholder="Status" onChange={handleMigrationStatus} value={migrationStatus}>
                                                                            <option value="" >All</option>
                                                                            <option value="Scheduled">Scheduled</option>
                                                                            <option value="Queue">Queue</option>
                                                                            <option value="In Progress">In Progress</option>
                                                                            <option value="Warning">Warning</option>
                                                                            <option value="Completed">Completed</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="col-md-12 btn-phn-padd-channel mt-5 mb-3">
                                                                <div className="float-end">
                                                                    <button className="btn-scondary-custom btn-cancel-border-channel btn-cancel-border my-2 common-btn-class  btn btn-second clear-btn-padding font-16 font-semibold px-4 me-2" onClick={btnClearFilterClick} >Clear</button>
                                                                    <button className="btn-primary-theme common-btn-class btn my-2 btn-prime font-16 font-semibold text-light" onClick={loadGridPage}>Apply Filter</button>
                                                                </div>
                                                                {/* <a href="#" className="btn btn-clear font-16 font-semibold px-4 me-3">Clear</a>
                                                <a href="#" className="btn btn-apply font-16 font-semibold text-light">Apply Filter</a> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* <!-- advanced-filter ends--> */}

                                            <button type="button" className="btn-scondary-custom btn padding-six-px btn-refresh-cm chan-ref-btn-h msm-refresh-res  px-3 btn-sm float-start d-inline-flex font-16 font-semibold" onClick={loadGridPage}   ><span className="pe-2"><img src="/Images/refresh-black.svg" alt="refresh" /></span> <span className="refreshTextColor">Refresh</span></button>
                                            {/* <!-- ReSchedule Migration Popup --> */}
                                            {/* Modal starts */}
                                            <div className="modal fade" id="cardDetails" tabIndex={-1} aria-labelledby="cardDetailsLabel" aria-hidden="true">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0">
                                                            <h5 className="inventory-title font-22 font-bold">Reschedule Migration</h5>
                                                            <button type="button" className="btn-scondary-custom btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={enableReschedulePopup}></button>
                                                        </div>
                                                        <div className="modal-body pt-0">
                                                            <div className="col-md-12 mb-4">
                                                                <div className="row">
                                                                    <label className="form-label popup-label font-14 font-regular">If any migration is in progress, the scheduled channels will be in queue.</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 float-start mb-5">
                                                                <div className="row">
                                                                    <div className="col-md-6 my-2 float-start">
                                                                        <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Date</label>
                                                                        <input type="date" className="inventory-date-form-control date-style-cm date-style w-100" min={maxMigrationFromDate} id="from-date" value={updatedDate} onKeyDown={(e) => { e.preventDefault() }} onChange={(e) => handleDate(e)} />
                                                                    </div>
                                                                    <div className="col-md-6 my-2 float-start clk-icon-fix clock-icon-channel">
                                                                        <label className="date-created w-100 float-start channel-check font-16 font-semibold mb-3">Time (UTC)</label>
                                                                        <img src="\Images\Clock-icon.svg" className="ms-2" onClick={handleTimeToggle} alt="clockicon" />
                                                                        <input type="text" className="inventory-date-form-control date-style-cm date-style w-100 input" placeholder="--:--" onClick={handleTimeToggle} onChange={handleTimePicker} value={timeVal=="picker"?"":timeVal}  id="picker" />
                                                                        <ul className="editClickdropdown" id="timeDD" hidden>
                                                                            {arrTime.map((obj, index) => {
                                                                                return (<li className="dropdown-item" id={obj.UI} onClick={(e) => handleTimePicker(e)}>{obj.UI}</li>)
                                                                            })
                                                                            }
                                                                        </ul>
                                                                        <label id="errorlabelTime" className="required" hidden={true} >Please choose future time</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="col-md-12 d-flex justify-content-end mb-4">
                                                            <button type="button" className="btn-scondary-custom btn-cancel-border common-btn-class  btn btn-second me-2 font-16 font-semibold float-start ps-4 pe-4" onClick={enableReschedulePopup}>Cancel</button>
                                                            <button type="button" className="btn-primary-theme common-btn-class btn btn-prime font-16 font-semibold float-start me-3 ps-4 pe-4" id="saveBtn" disabled={(updatedDate != "" && timeVal != "" && timeVal !="picker") ? false:true} onClick={saveScheduleClick}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Modal ends */}
                                            {/* <!-- advanced-filter ends--> */}




                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 float-start col-12 mt-2 p-2 table-responsive">
                                <table className="table table-border wraptd">
                                    <thead className="tabel-head-bg">
                                        <tr>
                                            <th className="table-head-custom table-head font-14 font-bold">Scheduled On
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="ScheduledAt" id="ScheduledAt@desc" onClick={e => sortClicked(e)} hidden={sorthide === "ScheduledAt@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="ScheduledAt" id="ScheduledAt@asc" onClick={e => sortClicked(e)} hidden={sorthide === "ScheduledAt@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Migration Status
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="MigrationStatus" id="MigrationStatus@desc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="MigrationStatus" id="MigrationStatus@asc" onClick={e => sortClicked(e)} hidden={sorthide === "MigrationStatus@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Slack Channel Name
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="SourceChatName" id="SourceChatName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="SourceChatName" id="SourceChatName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "SourceChatName@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Team Name
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="TargetTeamName" id="TargetTeamName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetTeamName@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="TargetTeamName" id="TargetTeamName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetTeamName@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Team Channel Name
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="TargetChannelName" id="TargetChannelName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="TargetChannelName" id="TargetChannelName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "TargetChannelName@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Inventory Name
                                                <img className="ms-2 anchorAsPointer" src="/Images/arrow.svg" alt="InventoryName" id="InventoryName@desc" onClick={e => sortClicked(e)} hidden={sorthide === "InventoryName@desc" ? true : false} />
                                                <img className="ms-2 anchorAsPointer" src="/Images/sort_upper_arrow.svg" alt="InventoryName" id="InventoryName@asc" onClick={e => sortClicked(e)} hidden={sorthide === "InventoryName@desc" ? false : true} />
                                            </th>
                                            <th className="table-head-custom table-head font-14 font-bold">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {gridBodyData()}
                                    </tbody>
                                </table>
                                {/* <!-- channel list table ends here --> */}


                            </div>
                            {/* <!-- loadmore button section starts here --> */}
                            <div className="col-md-12 float-start mt-3 mb-5 text-center">
                                {totalItemsCount >= countPerPage ?
                                    <button className="btn-common-loadmore btn btn-loadmore font-bold font-16 px-3 mb-5" onClick={() => handleLoadMore()}>Load More</button>
                                    : ""}
                            </div>
                            {/* <!-- loadmore button section ends here --> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="overlay" id='loader' hidden={true}>
                <div className=" Loader-Content-position ">
                    <div className="spinner-border loaderImg Loader-Img-position text-primary align-center" role="status">
                        <span className="visually-hidden"></span>

                    </div>
                </div>
            </div>

            <div id="fadeDiv" className="modal-backdrop fade show" hidden></div>

        </div>


    )
}